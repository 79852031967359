import Form from "react-bootstrap/Form";
import styled from "styled-components";

const StyledForm = styled(Form)`
  color: ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.purple};
  padding: 20px;
`;

function BaseForm({ children, ...props }) {
  /**
   * Shared functionality for all of our forms to inherit from.
   *
   */
  return <StyledForm {...props}>{children}</StyledForm>;
}

export default BaseForm;
