import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import LoadingContainer from "components/loading/Container";
import DashboardContainer from "components/containers/Dashboard";
import PremiumContentPaymentTable from "features/payments/tables/PaymentTable";
import DonationTransferTable from "features/payments/tables/DonationTransfers";
import {
  useGetDonationPayoutListQuery,
  useGetPayoutListQuery,
} from "services/projectCity";

function SprintManagementVideosRoute(props) {
  /** Show a premium content user the payments that they've received. */
  const { data, isLoading } = useGetPayoutListQuery();

  const {
    data: donationPayoutData,
    isLoading: donationPayoutsAreLoading,
  } = useGetDonationPayoutListQuery();

  if (isLoading || donationPayoutsAreLoading)
    return <LoadingContainer text="Loading payments" />;

  return (
    <DashboardContainer className="mt-0">
      <Tabs
        variant="pills"
        defaultActiveKey="lessons"
        transition={false}
        id="payment-tabs"
        className="mb-3"
      >
        <Tab eventKey="lessons" title="Lessons">
          <PremiumContentPaymentTable payments={data.results} />
        </Tab>
        <Tab eventKey="animatics" title="Animatics">
          <DonationTransferTable donationTransfers={donationPayoutData} />
        </Tab>
      </Tabs>
    </DashboardContainer>
  );
}

export default SprintManagementVideosRoute;
