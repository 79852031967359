import React, { useState } from "react";
import { func, shape, string } from "prop-types";
import { useForm, Controller } from "react-hook-form";

import FormControl from "components/forms/shared/Control";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import {
  useCreateContestEntryMutation,
  useUpdateContestEntryMutation,
} from "services/projectCity";
import { generateFormData, handleResponseError } from "utils/forms";
import FileUploadFormGroup from "components/forms/fields/FileUploadFormGroup";
import { ContestEntry } from "features/contests/types";

function ContestEntryForm({
  contestEntry,
  contestId,
  closeModal,
  afterSubmit,
  ...props
}) {
  /** Form to submit an entry to a contest. */

  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: contestEntry || {},
  });

  const [createContestEntry] = useCreateContestEntryMutation();
  const [updateContestEntry] = useUpdateContestEntryMutation();

  const isUpdating = contestEntry !== null;

  async function onSubmit(data) {
    /** When we create a project, there are some default values that need to be set. */

    if (!data.thumbnail) delete data.thumbnail;

    if (isUpdating) {
      // Remove the data for file fields if updating and the user hasn't chosen anything.
      if (data.thumbnail === contestEntry.thumbnail) delete data.thumbnail;

      if (data.file === contestEntry.file) delete data.file;
    } else {
      // Set the contest if creating a new entry.
      data.contest = contestId;
    }

    const formData = generateFormData(data);

    const action = isUpdating ? updateContestEntry : createContestEntry;
    const actionPayload = isUpdating
      ? { contestEntryId: contestEntry.id, formData }
      : formData;
    const response = await action(actionPayload);

    if (!isUpdating) afterSubmit(response.data);

    if (response.error) {
      handleResponseError(response, setFormError, setError);
    } else {
      closeModal();
    }
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Title" errors={errors.title}>
        <Controller
          as={FormControl}
          name="title"
          rules={{ required: true }}
          control={control}
          isInvalid={errors.title !== undefined}
        />
      </FormGroup>
      <FormGroup label="Video File" errors={errors.file}>
        <Controller
          render={({ onChange }) => (
            <FormControl
              type="file"
              error={errors.text}
              onChange={(e) => onChange(e.target.files[0])}
            />
          )}
          defaultValue=""
          name="file"
          accept="video/mp4"
          rules={{ required: true }}
          control={control}
          isInvalid={errors.file !== undefined}
        />
      </FormGroup>
      <FileUploadFormGroup
        field="thumbnail"
        label="Thumbnail"
        control={control}
        errors={errors}
      />
      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ContestEntryForm.propTypes = {
  /** Function to close the parent modal. */
  closeModal: func.isRequired,

  /** Action to take after the contest entry has been submitted. */
  afterSubmit: func.isRequired,

  /** The identifier of the contest that the entry belongs to. */
  contestId: string,

  /** If we're updating an instance, then it should be passed in. */
  contestEntry: shape(ContestEntry),
};

ContestEntryForm.defaultProps = {
  contestId: null,
  contestEntry: null,
};

export default ContestEntryForm;
