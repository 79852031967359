import React from "react";
import { shape } from "prop-types";

import { getProjectBaseUrl, getProjectUrl } from "utils/projects.js";
import { Project } from "types";
import ManagementNavigation from "../Management";

function ClassManagementNavigation({ project }) {
  /** Show the navigation for class management. */

  const projectManageBaseUrl = getProjectBaseUrl(project) + "/manage";
  const projectInfoUrl = getProjectUrl(project);
  const navigationData = [
    { to: `${projectManageBaseUrl}/project`, label: "Project" },
    { to: `${projectManageBaseUrl}/tiers`, label: "Tiers" },
    { to: `${projectManageBaseUrl}/students`, label: "Students" },
    { to: `${projectManageBaseUrl}/payments`, label: "Payments" },
  ];

  return (
    <ManagementNavigation
      title={project.title}
      backUrl={projectInfoUrl}
      links={navigationData}
    />
  );
}

ClassManagementNavigation.propTypes = {
  /** The project which we are managing. */
  project: shape(Project).isRequired,
};

export default ClassManagementNavigation;
