export function getKeyByValue(object, value) {
  /** Return the key of an object by searching the value. */
  return Object.keys(object).find((key) => object[key] === value);
}

export function getObjectByValue(object, value) {
  /**
   * Return an object by searching the value.
   *
   * :param object Object: Typically an enum object that we're getting the full object of.
   * :param value: Typically the integer value of an enum object.
   */

  const key = Object.keys(object).find((key) => object[key] === value);
  return { value, label: toTitleCase(key) };
}

export function toTitleCase(str) {
  /** Return a string in title case. */
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertEnumToArray(enumObject) {
  /** Take an enum and converts it to an array of objects with id/label */
  return Object.entries(enumObject).map((obj) => ({
    value: obj[1],
    label: toTitleCase(obj[0]),
  }));
}

export function getErrorMessage(data, defaultMessage) {
  /**
   * Receive response data and parses it to return the error message to show.
   *
   * :param data object: The response data that was returned from server.
   * :param defaultMessage string (optional): A default message if no error message can be determined.
   */

  if (typeof data !== "object") return defaultMessage;

  if ("nonFieldErrors" in data) return data.nonFieldErrors;
  else if ("detail" in data) return data.detail;
  else return defaultMessage;
}

export function openInNewTab(url) {
  /** Send the user to a url in a new tab */
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function appendUrlParameters(url, parameters) {
  /**
   * Append url parameters to a url, used for filtering endpoints.
   *
   * :return: A string url, with the parameters tacked on.
   */

  Object.keys(parameters).forEach((key, index) => {
    const symbol = index === 0 ? "?" : "&";
    url += `${symbol}${camelToSnakeCase(key)}=${parameters[key]}`;
  });

  return url;
}

export function urlify(text) {
  /**
   * Detect urls inside a text and build a tag for those urls works for both normal text
   * and html text.
   *
   * :return new text with a tagged links.
   */

  let result = text;
  const urlRegex = /([^href="]https?:\/\/[^\s|<]+)/g;
  const matches = [...text.matchAll(urlRegex)];

  if (matches.length) {
    matches[0].forEach((match) => {
      result = text.replace(
        match.slice(1),
        `<a href="${match.slice(1)}" target="_blank">${match.slice(1)}</a>`
      );
    });
  }

  const urlRegex2 = /([^https?://|href="|href='|>]www\.[^\s]+\.[^\s|<]+)/g;
  const matches2 = [...result.matchAll(urlRegex2)];

  if (matches2.length) {
    matches2[0].forEach((match) => {
      result = text.replace(
        match.slice(1),
        `<a href="https://${match.slice(1)}" target="_blank">${match.slice(
          1
        )}</a>`
      );
    });
  }

  return result;
}

export function getRandomColor(props) {
  /** Choose a random color, often times to give a random color to titles. */
  const exclude = props?.exclude || [];
  const colors = ["#ffa87d", "#a8d29b", "#807be4", "#ff9fd4"].filter(
    (color) => !exclude.includes(color)
  );
  return colors[Math.floor(Math.random() * colors.length)];
}

export function getRandomTilt() {
  const trans = ["transform: rotate(2deg); ", "transform: rotate(-4deg);"];
  return trans[Math.floor(Math.random() * trans.length)];
}

export function getRandomSmallTilt() {
  /** Random rotate transform for only 1 degree. */
  const trans = ["transform: rotate(1deg); ", "transform: rotate(-1deg);"];
  return trans[Math.floor(Math.random() * trans.length)];
}

export function getRandomColorsForTier() {
  /** Tiers need to colors bg and text color*/
  const stylings = [
    ["#F81958", "#E2EB76"],
    ["#E2EB76", "#F81958"],
  ];
  return stylings[Math.floor(Math.random() * stylings.length)];
}

export function injectString(str, index, stringToAdd) {
  /** Inject a string into another string. */
  return (
    str.substring(0, index) + stringToAdd + str.substring(index, str.length)
  );
}

export function splitTitle(str) {
  /** Takes a text string and returns two parts of the top and bottom of header. */
  const titleArray = str.split(" ");
  const titleSecondLine = titleArray
    .slice(Math.abs(titleArray.length / 2))
    .join(" ");
  const titleFirstLine = titleArray
    .slice(0, Math.abs(titleArray.length / 2))
    .join(" ");
  return [titleFirstLine, titleSecondLine];
}

export function toCurrency(amount) {
  /** Convert a stripe amount to be displayed as currency. */
  const amountString = amount.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return `$${amountString}`;
}

export function secondsToTime(seconds) {
  /** Turn an integer of seconds into a string formatted hh:mm:ss */
  const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  return `${h}:${m}:${s}`;
}

export function openPdf(e, src) {
  e.stopPropagation();

  // Opens the pdf file in new tab.
  const newWindow = window.open(src, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

export function renameFile(filename) {
  /**
   * Rename a file so that it's appropriate for s3 storage.
   *
   * 1) Replace spaces with underscores
   * 2) Replace all symbols
   * 3) If there are multiple periods, we need to remove them, except for the last.
   */
  return filename
    .replaceAll(" ", "_")
    .replace(/[^a-z0-9-_.]/gi, "")
    .replace(/[.](?=.*[.])/g, "");
}
