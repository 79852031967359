import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledCheck = styled.label`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px;
  color: ${(props) => props.color};
  font-size: 21px;
  font-family: Basis Grotesque Mono;
  padding: 3px;

  input[type="radio"] {
    box-sizing: border-box;
    display: none;
  }

  &:before {
    content: "";

    /* create custom radiobutton appearance */
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 4px;
    margin-right: 3px;

    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid ${(props) => props.color};
    background-color: ${(props) =>
      props.checked ? props.color : "transparent"};
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked + label:before {
    background-color: red;
    box-sizing: border-box;
  }
`;

function RadioButton({ onClick, id, checked, color, value, name, label }) {
  return (
    <StyledCheck onClick={onClick} htmlFor={id} checked={checked} color={color}>
      <input type="radio" value={value} name={name} id={id} /> {label}
    </StyledCheck>
  );
}

RadioButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  checked: PropTypes.bool,
  color: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default RadioButton;
