import Image from "react-bootstrap/Image";
import styled from "styled-components";

import TokenImg from "images/icons/token.svg";

const StyledImage = styled(Image)`
  width: 33px;
  padding: 0px;
`;

function TokenIcon(props) {
  return <StyledImage src={TokenImg} {...props} />;
}

export default TokenIcon;
