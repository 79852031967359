import { bool, number, object, oneOfType, string } from "prop-types";

export const ContentUpload = {
  id: number,
  title: string,
  createdBy: oneOfType([object, number]),
  isVisible: bool,
  file: string,
  image: string,
  totalWatchTime: number,
  monthlyWatchTime: number,
  totalPlays: number,
  monthlyPlays: number,
  canReceiveDonation: bool,
  tokensReceived: number,
};

export const ProjectContentUpload = {
  ...ContentUpload,
  order: number,
};
