import React, { useEffect, useRef } from "react";
import { oneOf } from "prop-types";
import styled from "styled-components";

import Body from "components/text/Body";
import BaseContainer from "../components/Base";
import SectionHeader from "../components/SectionHeader";
import Paragraph from "../components/Paragraph";

const Section = styled.section``;

const ListItem = styled(Body)`
  font-size: 32px !important;
  font-weight: bold;
  margin-top: ${(props) => props.theme.vSpacingMd};
`;

const SubListItem = styled(Body)`
  font-size: 28px !important;
  margin-top: ${(props) => props.theme.vSpacingSm};
`;

const ThirdListItem = styled(Body)`
  margin-left: 30px;
`;

const BulletList = styled.ul`
  color: ${(props) => props.theme.lime};
`;
const OrderedList = styled.ol`
  color: ${(props) => props.theme.lime};
`;
const BulletListItem = styled.li`
  font-family: "Basis Grotesque Pro";
`;
const ContentContainer = styled.div`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingSm};
  }
`;

function TermsOfUseDocument({ section }) {
  /**
   * Display the terms of use agreement.
   *
   * There are a few main sections to the agreement:
   *
   * 1) Main section
   * 2) Acceptable Use Policy
   * 3) Copyright and Trademark Policy
   * 4) Creator Agreement
   *
   * Converted docx -> html using https://www.online-convert.com/
   * Converted html -> jsx using https://magic.reactjs.net/htmltojsx.htm
   */

  // HTML hash values don't work well with our setup. Instead we'll pass in a param and
  // scroll to the section if needed.
  const acceptableUsePolicy = useRef(null);
  const copyrightAndTrademarkPolicy = useRef(null);
  const creatorAgreement = useRef(null);

  useEffect(() => {
    /** Route the user to a specific section. */
    switch (section) {
      case "acceptable-use-policy":
        acceptableUsePolicy.current.scrollIntoView();
        break;
      case "copyright-and-trademark-policy":
        copyrightAndTrademarkPolicy.current.scrollIntoView();
        break;
      case "creator-agreement":
        creatorAgreement.current.scrollIntoView();
        break;
      default:
        break;
    }
  });

  const lastUpdatedDate = "6/20/2022";
  const acceptableUsePolicyLink = (
    <a
      href="#acceptable-use-policy"
      onClick={() => acceptableUsePolicy.current.scrollIntoView()}
    >
      Acceptable Use Policy
    </a>
  );
  const copyrightLink = (
    <a
      href="#copyright-and-trademark-policy"
      onClick={() => copyrightAndTrademarkPolicy.current.scrollIntoView()}
    >
      Copyright and Trademark Policy
    </a>
  );

  return (
    <BaseContainer>
      <SectionHeader text="Project City Terms of Use" />
      <ContentContainer>
        <Paragraph>Effective: {lastUpdatedDate}</Paragraph>
        <Paragraph>
          The Project City websites and mobile applications offer products and
          services provided by Project City, Inc. (“<b>Project City,</b>” “
          <b>us,</b>” “<b>we,</b>” or “<b>our</b>”). These Terms of Use (“
          <b>Terms</b>”) govern your use of our websites (including
          www.projectcity.tv and its subdomains) (or a successor website with an
          alternative name and/or domain name), any software programs, other
          applications, content, and services provided by Project City and
          related to such website(s), and other Project City products and
          services (collectively, the “<b>Services</b>”). Please read these
          Terms carefully and contact us at support@projectcity.tv if you have
          any questions. By using any of our Services, you agree to be bound by
          these Terms, including the policies referenced in these Terms.
        </Paragraph>
        <Paragraph>
          Your use of, and participation in, certain Services may be subject to
          additional terms (“<b>Supplemental Terms</b>”) and such Supplemental
          Terms will either be listed in the Terms or will be presented to you
          for your acceptance when you sign up to use the supplemental Service.
          If the Terms herein are inconsistent with the Supplemental Terms, the
          Supplemental Terms shall control with respect to such supplemental
          Service. All such Supplemental Terms are hereby incorporated into the
          Terms by reference.
        </Paragraph>
        <Paragraph>
          IF YOU OPT IN TO A SUBSCRIPTION THROUGH THE SERVICES, THEN THE TERMS
          WILL BE AUTOMATICALLY RENEWED FOR ADDITIONAL PERIODS OF THE SAME
          DURATION AS THE INITIAL TERM AT THE THEN-CURRENT FEE FOR SUCH SERVICES
          UNLESS YOU OPT OUT OF THE AUTO-RENEWAL / DECLINE TO RENEW YOUR
          SUBSCRIPTION IN ACCORDANCE WITH THE SUBSCRIPTION PROVISION BELOW.
        </Paragraph>
        <Paragraph>
          THESE TERMS INCLUDE AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER
          THAT APPLY TO ALL CLAIMS BROUGHT AGAINST PROJECT CITY. PLEASE READ
          THEM CAREFULLY; THEY AFFECT YOUR LEGAL RIGHTS.
        </Paragraph>
        <ListItem>1. Using Project City</ListItem>
        <SubListItem>1.1. The Services</SubListItem>
        <ThirdListItem>a. Classes</ThirdListItem>
        <Paragraph>
          Certain Services are designed to allow teachers or instructors of a
          Class (as defined below) (each, a “<b>Teacher</b>”) to make available
          their Classes online so that users (“<b>Students</b>”) may enroll in
          such Classes. Classes may be purchased a la carte, and we also provide
          access to certain special benefits and exclusive content and services
          on a subscription basis, subject to the terms herein.
        </Paragraph>
        <ThirdListItem>b. Projects</ThirdListItem>
        <Paragraph>
          Certain Services are designed to allow certain users, whether as
          Teachers or independent creators of content or collaborative projects
          (“
          <b>Project Creators,</b>” and together with Teachers and Sprint
          Teachers (as defined below), “<b>Creators</b>”), to upload, create and
          develop their personal creative content, projects or experiences (“
          <b>Projects</b>”) on the Project City website and platform, either as
          a Class or as an independent content offering or educational
          experience, so that Students or any other users of the Services
          (collectively “<b>Content Seekers</b>”) may consume, learn or
          experience the content, Classes (as defined below), or Projects
          offered by such Creators.
        </Paragraph>
        <SubListItem>1.2. Who May Use our Services</SubListItem>
        <Paragraph>
          When we use the term “you”, it refers to the user (whether Student or
          Creator) who creates a Project City account (“<b>Account</b>”) through
          the Services or to the type of user identified in the Section heading
          of the applicable provision. You may use our Services only if you can
          form a binding contract with Project City, and only in compliance with
          these Terms and all applicable laws. When you create your Account, and
          subsequently when you use certain features, you must provide us with
          accurate and complete information, and you agree to update your
          information to keep it accurate and complete. If you provide any
          information that is untrue, inaccurate, not current or incomplete, or
          Project City has reasonable grounds to suspect that any information
          you provide is untrue, inaccurate, not current or incomplete, Project
          City has the right to suspend or terminate your Account and refuse any
          and all current or future use of the Services (or any portion
          thereof).{" "}
          <b>
            Any use or access by anyone under the age of 13 is prohibited, and
            certain Classes or Projects (together with all other goods or
            services provided by Creators for a fee, the “
            <b>Content Offerings</b>”) may have additional eligibility or other
            requirements and/or restrictions, as determined by the Creator. If
            you are under the age of 18, you must use the Services only under
            the supervision of an adult parent or guardian, who will be bound by
            these Terms on your behalf.
          </b>
        </Paragraph>
        <Paragraph>
          If you access the Services through a Third-Party Offering (as defined
          below), you may link your Account with a third-party account, such as
          Google or Facebook (“<b>Third-Party Account</b>”), by allowing Project
          City to access your Third-Party Account, as is permitted under the
          applicable terms and conditions that govern your use of each
          Third-Party Account. You represent that you are entitled to grant
          Project City access to your Third-Party Account (including, but not
          limited to, for use for the purposes described herein) without breach
          by you of any of the terms and conditions that govern your use of the
          applicable Third-Party Account and without obligating Project City to
          pay any fees or making Project City subject to any usage limitations
          imposed by such third-party service providers. By granting Project
          City access to any Third-Party Accounts, you understand that Project
          City may access, make available and store (if applicable) any content
          that you have provided to and stored in your Third-Party Account (“
          <b>Third-Party Account Content</b>”) so that it is available on and
          through the Services via your Account. Depending on the Third-Party
          Accounts you choose and subject to the privacy settings that you have
          set in such Third-Party Accounts, personally identifiable information
          that you post to your Third-Party Accounts may be available on and
          through your Account on the Services. Please note that if a
          Third-Party Account or associated service becomes unavailable or
          Project City’s access to such Third-Party Account is terminated by the
          third-party service provider, then Third-Party Content will no longer
          be available on and through the Services. You have the ability to
          disable the connection between your Account and your Third-Party
          Accounts at any time by accessing the “Settings” section of the
          Services. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY
          SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS
          GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
          PROVIDERS, AND PROJECT CITY DISCLAIMS ANY LIABILITY FOR PERSONALLY
          IDENTIFIABLE INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH THIRD-
          PARTY SERVICE PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS THAT YOU
          HAVE SET IN SUCH THIRD-PARTY ACCOUNTS. Project City makes no effort to
          review any Third-Party Account Content for any purpose, including but
          not limited to, for accuracy, legality or noninfringement, and Project
          City is not responsible for any Third-Party Account Content.
        </Paragraph>
        <SubListItem>1.3. Our License to You</SubListItem>
        <Paragraph>
          Subject to these Terms and our policies (including the{" "}
          {acceptableUsePolicyLink} and other terms), we grant you a limited,
          personal, non-exclusive, non-transferable, and revocable license to
          use our Services. You may download content, including content that is
          not your own, from our Services only for your personal, non-
          commercial use, unless you obtain our written permission (or written
          permission from the applicable User or Content Creator (as defined
          below) to otherwise use the content. You also agree that you will
          create, access, and/or use only one user account, unless expressly
          permitted by Project City, and you will not share access to your
          account or access information for your account with any third party.
          Using our Services does not give you ownership of or any intellectual
          property rights in our Services or the content you access.
        </Paragraph>
        <ListItem>2. Using the Services as a Content Seeker</ListItem>
        <SubListItem>2.1. Classes</SubListItem>
        <Paragraph>
          Project City offers classes, courses, and other content (“
          <b>Classes</b>
          ”) from Teachers.
        </Paragraph>
        <Paragraph>
          The price for each Class is established solely by the Teacher of such
          Class. By enrolling in a Class, you and the Teacher are entering into
          a direct agreement whereby you agree to pay the price established by
          the Teacher for the Class in accordance with the payment terms herein,
          and the Teacher agrees to enroll you in the Class and to provide you
          with all of the privileges and benefits that Teacher makes available
          to students of the Class.
        </Paragraph>
        <Paragraph>
          All payments for a Class are non-refundable, unless the Class is
          cancelled without being rescheduled within a reasonable amount of
          time. Project City will use good faith efforts to facilitate the
          processing of any refund for a cancelled Class, but you understand and
          agree that the Teacher is solely responsible for making determinations
          with respect to refunds. You agree to resolve any dispute with respect
          to a Class directly with the Teacher of the Class. Notwithstanding the
          foregoing, Project City reserves the right to replace a Teacher with a
          new Teacher who has similar experience and skills in the event a
          Teacher cannot fulfill or complete a Class. In such event, you will
          not be entitled to a full or partial refund, but we may grant one in
          our sole discretion.
        </Paragraph>
        <ThirdListItem>a. No Academic Credit</ThirdListItem>
        <Paragraph>
          Project City is not an accredited academic institution and does not
          offer or accept academic credit. Unless otherwise explicitly indicated
          by a Teacher, participation in or completion of a Class does not
          confer any academic credit from any academic institution, regardless
          of the Teacher’s affiliation with any such academic institution. Even
          if credit is awarded by one institution, there is no presumption that
          other institutions will accept that credit. You agree not to accept
          credit for completing a Class unless you have earned a course
          certificate or other equivalent documentation of your completion of
          the Class. Project City and Teachers have no obligation to have
          Content Offerings recognized by any educational institution or
          accreditation organization.
        </Paragraph>
        <ThirdListItem>
          b. Disclaimer of Student-Teacher Relationship
        </ThirdListItem>
        <Paragraph>
          Nothing in these Terms or otherwise with respect to your participation
          as a Student in any Class: (a) establishes any relationship between
          you and any academic institution; (b) enrolls or registers you in any
          academic institution, or in any class offered by any academic
          institution; or (c) entitles you to use the resources of any academic
          institution beyond participation in the Class (as applicable).
        </Paragraph>
        <ThirdListItem>c. Sprint Subscriptions</ThirdListItem>
        <Paragraph>
          As a Content Seeker, you have the option to join Project City on a
          subscription basis to access special benefits, which may include
          access to exclusive content, tutorials, assignments, and other
          exclusive and engaging experiences with certain Sprint Teachers (as
          defined below) and the Project City community (“
          <b>Sprint Subscription</b>”). The details of our current Sprint
          Subscription offerings can be found here and may change at any time in
          Project City’s sole discretion. Subscription fees are subject to
          Section 12.2 below and to the Sprint Revenue Share (as defined below)
          and any and all activities and content performed or created as a
          result of your participation in a Content Offering or benefits
          included in a Sprint Subscription are subject to the terms applicable
          to Students and/or Content Seekers under these Terms. Subject to the
          terms of Section 12.2 below, you may cancel your Sprint Subscription
          at any time. You understand and agree that cancelling your Sprint
          Subscription, or if your payment method fails, may result in your loss
          of access to a Sprint Teacher’s exclusive posts and benefits. You
          further understand and agree that Sprint Teachers may cease their
          Content Offerings or may delete their account at any time, which may
          result in your loss of access to certain Sprint Subscription content.
        </Paragraph>
        <SubListItem>2.2. Projects</SubListItem>
        <Paragraph>
          Project City offers the ability to enroll in, view, or otherwise
          participate in Projects posted by Creators. The price for each Project
          is established solely by the Creator of such Project. By enrolling in,
          viewing, or otherwise participating in a Project, you and the Creator
          are entering into a direct agreement whereby you agree to pay the
          price established by the Creator for the Project in accordance with
          the payment terms herein, and the Creator agrees to enroll You in the
          Project and to provide you with all of the privileges and benefits
          that Creator makes available to Project consumers or participators.
          Any contributor to a Project will be subject to supplemental terms.
        </Paragraph>
        <Paragraph>
          All payments for a Project are non-refundable, unless the Project has
          not yet commenced or is removed from the Services after commencement
          but prior to completion. If a Project has not yet commenced, you will
          be entitled to a full refund. In the event a Project is cancelled
          before it has been completed, you may be entitled to partial refund in
          the Creator’s sole discretion. Project City will use good faith
          efforts to facilitate, through our Payment Processor (as defined
          below), any refund for a Project that has not yet commenced, but you
          understand and agree that the Creator is solely responsible for making
          determinations with respect to refunds for any other reason including,
          without limitation, a Project that has been cancelled or removed after
          it has commenced. You agree to resolve any dispute with respect to a
          Project directly with the Creator of the Project.
        </Paragraph>
        <SubListItem>2.3. Payment and Project City’s Revenue Share</SubListItem>
        <Paragraph>
          As a Student or Content Seeker, in order to enroll in certain Content
          Offerings, you will need to pay the fee established by the Creator for
          the applicable item, service, or tier of such Content Offering.
          Certain Content Offerings may be included in your Sprint Subscription.
          As a Student, Content Seeker or otherwise as a purchaser of a Content
          Offering, you will contract directly with Creators for the purchase of
          Content Offerings through the Services. Project City is not a party to
          any such sales. All payments for Content Offerings are subject to
          Project City’s Revenue Share (as defined below). In other words,
          Project City’s fee for each Content Offering purchase is deducted from
          the purchase price paid by you, with the remainder remitted to the
          Creator.
        </Paragraph>
        <ListItem>3. Using the Services as a Creator</ListItem>
        <SubListItem>3.1. Payment and Project City’s Revenue Share</SubListItem>
        <Paragraph>
          Except for any Content Offering included in a Sprint Subscription
          purchased by a Content Seeker, you have the right to establish the
          price, parameters, per-pay- buttons, and, if applicable, Pay Split (as
          defined below) for and within each Content Offering for which you are
          a Creator. Any Content Offering fees that you set on the Services will
          be subject to Project City’s Revenue Share described in Section 12.3
          below.
        </Paragraph>
        <SubListItem>3.2. Contributions</SubListItem>
        <Paragraph>
          To the extent you, as a Creator, engage a Student, Project
          participant, another Creator, or any other user of the Services to
          provide Contributions (defined below) to your Project, you agree to
          enter into a separate written agreement governing the terms and
          conditions of such Services, in the form provided by Project City (or
          such other form approved by Project City), with such other individual
          (“
          <b>Professional Services Agreement</b>”). You acknowledge and agree
          that Project City shall not be liable for, and you agree to indemnify
          Project City against, any loss or damage caused by any breach or
          dispute related to or arising from such Professional Services
          Agreement.
        </Paragraph>
        <SubListItem>3.3. Confidential Information</SubListItem>
        <Paragraph>
          You agree to keep Project City’s confidential information (“
          <b>Project City Confidential Information</b>”) absolutely
          confidential, except as required or provided by law. Project City
          Confidential Information includes but is not limited to information
          about Project City’s customer base and/or fees for Creators. You also
          agree to use and disclose information about Students or other users of
          the Services only as necessary for the purposes of providing the
          Content Offerings under this Agreement and for no other purpose during
          the term of this Agreement. If you have previously signed a
          non-disclosure or other confidentiality agreement (“<b>NDA</b>”) with
          Project City or any of its affiliates, you agree that the obligations
          in this Section 3.3 supplement and do not supersede the
          confidentiality obligations under such NDA. You agree to comply with
          the terms of any such NDA you have executed, and you acknowledge and
          agree that the scope of Project City's confidential information under
          such NDA includes, but it not limited to, information about Project
          City's customer base and/or fees for Creators. To the extent the terms
          of such NDA conflict with the confidentiality obligations hereunder,
          the terms that provide the greater protection to Project City shall
          prevail.
        </Paragraph>
        <SubListItem>3.4. Sprint Subscriptions</SubListItem>
        <Paragraph>
          Certain Services may be made available to Content Seekers on a
          subscription basis as part of our Sprint Subscription, whereby Content
          Seekers may pay a monthly or annual fee to receive access to special
          benefits and exclusive content. As a Teacher, you may be invited to
          participate in producing or providing exclusive content, services and
          experiences for our Sprint Subscription subscribers (each such
          participating Teacher, a “<b>Sprint Teacher</b>”). If you are invited,
          and should you accept such invitation, you agree to provide to
          subscribers such additional content, access, services, exclusivity,
          experiences and communications that are included in the Sprint
          Subscription services and that may be requested by Project City from
          time to time, which may include, without limitation, short tutorial
          videos (“<b>Sprint Videos</b>”), related assignments, subscriber-only
          communications, long-form videos of previously taught Classes,
          exclusive assignment reviews, and other content and services. The
          obligations and actions required of Sprint Teachers will be
          communicated to you upon your invitation to participate in the Sprint
          Subscription program and may change from time to time in Project
          City’s sole discretion.
        </Paragraph>
        <Paragraph>
          Subject to your satisfactory completion of the agreed to Sprint
          Teacher obligations, all revenue received from subscribers of the
          Sprint Subscription services will be subject to the Sprint Revenue
          Share. Sprint Teachers are engaged on an invitation-only basis, and
          Project City does not guarantee that you will be invited or chosen as
          a Sprint Teacher. If you accept an invitation to participate as a
          Sprint Teacher, you agree to fulfill the applicable Sprint Teacher
          obligations for at least one (1) month. You understand that,
          notwithstanding your prior participation as a Sprint Teacher, you are
          not guaranteed to be invited to participate as a Sprint Teacher in a
          subsequent or later month. Project City reserves the right to remove
          or replace a given Sprint Teacher at any time. Any and all activities
          and content performed or created as a result of your participation as
          a Sprint Teacher are subject to the terms applicable to Teachers under
          these Terms.
        </Paragraph>
        <SubListItem>3.5. Taxes</SubListItem>
        <Paragraph>
          You are solely responsible for all taxes and other governmental fees
          and charges imposed on or with respect to any transactions and
          payments for any Content Offering (excluding any federal or state
          income taxes that may be imposed on us). We may deduct or withhold any
          taxes that we may be legally obligated to deduct or withhold from any
          amounts payable to you under these Terms, and payment to you as
          reduced by such deductions or withholdings will constitute full
          payment and settlement to you of amounts payable under these Terms. At
          our request or if required, you will provide us with any forms,
          documents, or certifications as may be required for us to satisfy any
          information reporting or withholding tax obligations with respect to
          any payments under these Terms.
        </Paragraph>
        <ListItem>4.Video Recordings of Content Offerings</ListItem>
        <Paragraph>
          By enrolling in, participating in or offering a Content Offering, you
          agree that the Content Offering may be recorded and that the Creator
          of the Content Offering and Project City shall have the right to use
          your image, in video or still, and of the likeness and sound of your
          voice, as recorded on any medium, and the Content Offering, in whole
          or in part, without payment or any other consideration to you or any
          third party, for the purposes of providing, improving, and/or
          promoting the Services. Further, you understand and agree that your
          image may be edited, copied, exhibited, published or distributed and
          waive the right to inspect or approve the finished product wherein
          your likeness appears. Additionally, you waive any right to royalties
          or other compensation arising or related to the use of your image or
          recording.
        </Paragraph>
        <ListItem>5. Third-Party Offerings</ListItem>
        <Paragraph>
          We may make available third-party products and/or services for use or
          integration with Project City’s Services (collectively, “
          <b>Third-Party Offerings</b>”). You agree that Project City is not
          responsible for, and does not provide or control, those Third-Party
          Offerings. When you access or use a Third-Party Offering, it is your
          responsibility to read the legal notices and privacy policies of each
          and every provider of such Third-Party Offering. Your use of a
          Third-Party Offering will be subject to the terms of use, privacy
          policy, and any other applicable terms and conditions, required by the
          provider of such Third-Party Offering. Project City provides these
          Third-Party Offerings only as a convenience and does not review,
          approve, monitor, endorse, warrant, or make any representations with
          respect to Third-Party Offerings, and you access and/or use all such
          Third-Party Offerings at your own risk.
        </Paragraph>
        <Paragraph>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREUNDER, YOU UNDERSTAND,
          ACKNOWLEDGE AND AGREE THAT PROJECT CITY IS NOT THE PROVIDER OF THE
          THIRD-PARTY OFFERINGS AND MAKES NO REPRESENTATIONS OR WARRANTIES WITH
          RESPECT TO SUCH THIRD-PARTY OFFERINGS. YOU REPRESENT THAT YOU ARE NOT
          RELYING ON ANY STATEMENTS, SPECIFICATIONS, GRAPHICS OR OTHER
          ILLUSTRATIONS REPRESENTING THIRD-PARTY OFFERINGS OR THAT MAY BE
          PROVIDED BY PROJECT CITY. YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT
          PROJECT CITY HAS NO OBLIGATION OR LIABILITY ARISING FROM THE
          PROVISION, OPERATION AND/OR PERFORMANCE OF THIRD-PARTY OFFERINGS. ANY
          THIRD-PARTY OFFERINGS MADE AVAILABLE UNDER THE TERMS ARE MADE
          AVAILABLE ON AN “AS IS” BASIS. PROJECT CITY HEREBY DISCLAIMS AND YOU
          WAIVE ALL REPRESENTATIONS, CONDITIONS AND WARRANTIES WITH RESPECT TO
          SUCH THIRD-PARTY OFFERINGS (WHETHER EXPRESS, IMPLIED, OR STATUTORY),
          INCLUDING WITHOUT LIMITATION, ANY WARRANTY OR CONDITION: (A) OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE (EVEN IF THE PURPOSE
          IS KNOWN TO PROJECT CITY), NONINFRINGEMENT, TITLE, SATISFACTORY
          QUALITY, QUIET ENJOYMENT, OR ACCURACY; AND (B) ARISING FROM ANY COURSE
          OF DEALING, COURSE OF PERFORMANCE, OR USAGE IN THE INDUSTRY.
        </Paragraph>
        <ListItem>6. Your Content</ListItem>
        <SubListItem>6.1. User Content</SubListItem>
        <Paragraph>
          The Services enable you, as a Student, to share content, such as
          homework, quizzes, exams, projects, other assignments you submit,
          posts you make in the forums, social feeds, and the like (“
          <b>Student Content</b>”), with Project City, Teachers, and/or other
          users. You retain all intellectual property rights in, and are
          responsible for, the Student Content you create and share. Student
          Content does not include course content or any other content or
          materials made available on or placed on to the Project City platform
          by or on behalf of Creators using the Services or instructing Classes
          (“
          <b>Provider Content</b>,” and, together with Student Content, “
          <b>User Content</b>”)). Notwithstanding the foregoing, and for the
          avoidance of doubt, Student Content may include Project content
          created by such Student prior to enrolling in a Class to the extent
          such Project is used and developed in such Class. User Content
          includes all user-generated or uploaded information, data, text,
          software, music, sound, photographs, graphics, video, messages, tags
          and/or other materials that you, as a Student, Content Seeker,
          Creator, or otherwise as a user of the Services, upload, post, e-mail,
          transmit or otherwise make available (“<b>Make Available</b>”) through
          the Services, including, without limitation, any social or public feed
          content, documents, movies, shows, webcomics, or art that you develop
          and post on the platform. You, as a Creator, retain all intellectual
          property rights in, and are responsible for, the Provider Content you
          Make Available through the Services, even if a Student or another user
          contributed in any way to such Provider Content. Each user is
          responsible for the specific content created and uploaded by such user
          (“
          <b>User Content Creator</b>”) and must ensure that all such content
          complies with these Terms, including the {acceptableUsePolicyLink}.
          Project City is not responsible for any such User Content.
        </Paragraph>
        <SubListItem>
          6.2. How Project City and Others May Use User Content
        </SubListItem>
        <Paragraph>
          To the extent that you, whether as a Student or Creator provide User
          Content, including, without limitation, Projects, Classes, and
          Contributions (as defined below), you grant Project City a
          fully-transferable, royalty-free, perpetual, sublicensable,
          non-exclusive, worldwide license to copy, distribute, modify, create
          derivative works based on, publicly perform, publicly display, and
          otherwise use the User Content to provide, improve, and promote the
          Services. This license includes granting Project City the right to
          authorize (a) Teachers to use Student Content with their registered
          students or other students independent of the Services; (b) Students
          to use Class Content for their personal, non-commercial use, including
          independent of the Services; and (c) Creators to use any User Content
          or Contributions in connection with their Content Offerings. To the
          extent you, as a Student, a Project participator, a Creator or
          otherwise as a user of the Services, contribute in any way, including,
          but not limited to, by providing input, ideas, or feedback, (“
          <b>Contributions</b>”) to a Student’s or other user’s original work or
          Student Content or a Creator’s Project, Class, Provider Content, or
          any creation or development by any Creator (each, a “<b>Creation</b>
          ”), you agree that such Student, user, or Creator retains ownership of
          and all intellectual property rights in such project or creation,
          including your Contribution. To the extent necessary to effectuate
          such ownership, you hereby assign, convey and transfer to such
          Student, user, or Creator your entire right, title and interest in and
          to any and all Contributions and all intellectual property and
          proprietary rights therein that you have or may in the future acquire.
          Nothing in these Terms shall restrict other legal rights Project City
          may have to User Content, for example under other licenses. You
          acknowledge that Project City has no obligation to pre-screen content
          (including, but not limited to, User Content), although Project City
          reserves the right in its sole discretion to pre- screen, refuse or
          remove any content. By entering into the Terms, you hereby provide
          your irrevocable consent to such monitoring. In the event that Project
          City pre-screens, refuses or removes any content, you acknowledge that
          Project City will do so for Project City’s benefit, not yours. Without
          limiting the foregoing, we reserve the right to remove or modify User
          Content for any reason, including User Content that we believe
          violates these Terms or other policies including our{" "}
          {acceptableUsePolicyLink}.
        </Paragraph>
        <SubListItem>6.3. Contributions.</SubListItem>
        <Paragraph>
          For the avoidance of doubt, you acknowledge and agree that, pursuant
          to Sections 3.1 and 3.2 above, any Contribution you provide to another
          user’s Creation will be owned, including all intellectual property
          rights therein, by such user.
        </Paragraph>
        <SubListItem>6.4. Feedback</SubListItem>
        <Paragraph>
          We welcome your suggestions, ideas, comments, and other feedback
          regarding the Services (“<b>Feedback</b>”). By submitting any
          Feedback, you grant us the right to use the Feedback without any
          restriction or any compensation to you. By accepting your Feedback,
          Project City does not waive any rights to use similar or related
          Feedback previously known to Project City, developed by our employees,
          contractors, or obtained from other sources.
        </Paragraph>
        <ListItem>7. Investigations</ListItem>
        <Paragraph>
          Project City may, but is not obligated to, monitor or review the
          Services and content at any time. Without limiting the foregoing,
          Project City shall have the right, in its sole discretion, to remove
          any User Content for any reason (or no reason), including if such
          content violates the Terms or any applicable law. Although Project
          City does not generally monitor user activity occurring in connection
          with the Services or content, if Project City becomes aware of any
          possible violations by you of any provision of the Terms, Project City
          reserves the right to investigate such violations, and Project City
          may, at its sole discretion, immediately terminate your license to use
          the Services, or change, alter or remove User Content, in whole or in
          part, without prior notice to you.
        </Paragraph>
        <ListItem>8. Interactions with Other Users</ListItem>
        <Paragraph>
          You are solely responsible for your interactions with other Students,
          Teachers and Project Creators, and any other parties with whom you
          interact; provided, however, that Project City reserves the right, but
          has no obligation, to intercede in such disputes. You agree that
          Project City will not be responsible for any liability incurred as the
          result of such interactions. The Services may contain User Content
          provided by other Students, Teachers, and Project Creators. Project
          City is not responsible for and does not control User Content. Project
          City has no obligation to review or monitor, and does not approve,
          endorse or make any representations or warranties with respect to User
          Content. You use all User Content and interact with other Students,
          Teachers, and/or Project Creators at your own risk.
        </Paragraph>
        <ListItem>9. Security</ListItem>
        <Paragraph>
          While we work to protect the security of your account and related
          information, Project City cannot guarantee that unauthorized third
          parties will not be able to defeat our security measures. Please
          notify us immediately of any compromise or unauthorized use of your
          account by emailing support@projectcity.tv.
        </Paragraph>
        <ListItem>10. Third Party Links</ListItem>
        <Paragraph>
          Through the Services, and subject to the rights and licenses granted
          in Section 2, you will have the ability to access and/or use content
          provided by instructors, other users, and/or other third parties and
          links to websites and services maintained by third parties
          (collectively, the “<b>Third-Party Links</b>”). Project City cannot
          guarantee that such Third-Party Links, in the Services or elsewhere,
          will be free of material you may find objectionable or otherwise
          inappropriate or of malware or other contaminants that may harm your
          computer, mobile device, or any files therein. Project City disclaims
          any responsibility or liability related to your access or use of, or
          inability to access or use, such Third-Party Links. Project City
          provides these Third-Party Links only as a convenience and does not
          review, approve, monitor, endorse, warrant, or make any
          representations with respect to Third-Party Links, or any product or
          service provided in connection therewith. You use all Third-Party
          Links at your own risk.
        </Paragraph>
        <ListItem>11. Copyright and Trademark</ListItem>
        <Paragraph>
          PROJECT CITY HAS DEVELOPED A COPYRIGHT AND TRADEMARK POLICY THAT IS
          DESIGNED TO ADDRESS COPYRIGHT AND TRADEMARK INFRINGEMENT ISSUES.
          Project City respects the intellectual property rights of our users,
          Teachers and Students, Project Creators, and other third parties and
          expects our users to do the same when using the Services. We have
          adopted and implemented the {copyrightLink} in accordance with
          applicable law, including the Digital Millennium Copyright Act.
        </Paragraph>
        <ListItem>12. Payment Terms</ListItem>
        <SubListItem>12.1. Payments</SubListItem>
        <Paragraph>
          Project City facilitates all payments through the Services and
          facilitates the exchange of money as described below. All payments
          must be made through the Services. You understand and agree that
          Project City itself does not process the transmission of funds, and,
          thus, it is not a separate and discrete service that Project City
          provides in addition to the Services. Additionally, other aspects of
          the Services may allow you to make purchases, including purchases for
          merchandise, experiences, tickets to virtual content premiers or
          showings, or to support certain Creators or Content Offerings
          (including by making a “<b>tip</b>” or a “<b>donation</b>”). You
          acknowledge that Project City is not a 501(c)(3) organization and any
          such donation would not be tax- deductible. All matters concerning the
          merchandise, experiences, tickets, and services desired from a
          Creator, including but not limited to purchase terms, payment terms,
          warranties, guarantees, maintenance and delivery, returns and refunds,
          are solely between you and the Creator. Project City makes no
          warranties or representations whatsoever with regard to any goods or
          services provided by Creators. You will not consider Project City, nor
          will Project City be construed as, a party to such transactions,
          whether or not Project City may have facilitated payment or received
          some form of revenue or other remuneration in connection with the
          transaction, nor will Project City be liable for any costs or damages
          arising out of, either directly or indirectly, you or any other person
          involved or related to the transaction.
        </Paragraph>
        <Paragraph>
          Unless otherwise stated, all fees are quoted in U.S. Dollars. You are
          responsible for paying all fees charged by Project City (or a Creator)
          and applicable taxes in a timely manner with an acceptable payment
          mechanism associated with the applicable Content Offering or other
          paid Services. Your payment mechanism agreement governs your use of
          the designated credit card, and you must refer to that agreement and
          not the Terms to determine your rights and liabilities. By providing
          Project City with your payment mechanism and associated payment
          information, you agree that Project City is authorized to immediately
          invoice you for all fees and charges due and payable to Project City
          hereunder and that no additional notice or consent is required. If
          your payment method fails or your account is past due, we may collect
          fees using other collection mechanisms. Fees may vary based on your
          location and other factors, and Project City and Creators reserve the
          right to change any fees at any time at its sole discretion. Any
          change, update, or modification will be effective immediately upon
          posting through the relevant Services.
        </Paragraph>
        <Paragraph>
          All payments made by Students or other users for any Content Offering
          will be made using a third party payment provider for payment services
          (e.g., online payments, automated clearing house (ACH) payouts,
          merchant settlement, and related services) (“<b>Payment Processor</b>
          ”) accessed through the Services. All payment provider services are
          provided by the Payment Processor, and subject to the Payment
          Processor’s terms and conditions. Currently, we use Stripe, Inc.
          (https://stripe.com/) and Checkbook, Inc., (checkbook.io) as our
          Payment Processors. We reserve the right to use any additional or
          alternative Payment Processors in our sole discretion.
        </Paragraph>
        <Paragraph>
          By making a purchase or selling a product or service through the
          Services, you agree to be bound by our Payment Processor’s Privacy
          Policies (currently accessible at https://stripe.com/us/privacy and
          https://checkbook.io/resource/privacy/) and Terms of Service
          (currently accessible at https://stripe.com/us/terms and
          https://checkbook.io/company/terms-individual/) and hereby consent and
          authorize Project City and our Payment Processor to share any
          information and payment instructions you provide with one or more
          Payment Processor(s) to the minimum extent required to complete your
          transactions. We do not process, record or maintain your credit card
          or bank account information and we will share transaction data with
          our Payment Processors only to the extent necessary for the purposes
          of processing your payments, refunding such payments and dealing with
          complaints and queries relating to such payments and refunds. For more
          information on how payments are handled, or to understand the data
          security and privacy afforded such information, please refer to the
          applicable Payment Processor’s Privacy Policy.
        </Paragraph>
        <SubListItem>12.2. Subscriptions</SubListItem>
        <Paragraph>
          If applicable, you will be responsible for payment of a weekly,
          monthly, or annual subscription fee (the “<b>Subscription Fee</b>”).
          Your subscription will continue indefinitely until terminated in
          accordance with the Terms. After your initial monthly or annual
          subscription period, and again after any subsequent Subscription
          period, your subscription will automatically commence on the first day
          following the end of such period (each a “
          <b>Renewal Commencement Date</b>”) and continue for an additional
          equivalent period, at Project City’s (or the applicable Creator’s)
          then-current price for such subscription. You agree that you will be
          subject to this automatic renewal feature unless you cancel your
          subscription at least (a) thirty (30) days prior to the Renewal
          Commencement Date (or in the event that you receive a notice from
          Project City that your subscription will be automatically renewed, you
          will have thirty days from the date of Project City notice). If you do
          not wish your account to renew automatically, or if you want to change
          or terminate your subscription, you can manage your subscription from
          your user profile page or by contacting support@projectcity.tv. If you
          cancel your subscription, you may use your subscription until the end
          of your then-current subscription term; your subscription will not be
          renewed after your then-current term expires. However, you will not be
          eligible for a prorated refund of any portion of the Subscription Fee
          paid for the then-current subscription period. By subscribing to any
          subscription offering, you authorize Project City to charge your
          payment mechanism now, and again at the beginning of any subsequent
          subscription period. Upon renewal of your subscription, if Project
          City does not receive payment from your payment mechanism, (i) you
          agree to pay all amounts due upon demand, and/or (ii) you agree that
          Project City may either terminate or suspend your subscription and
          continue to attempt to charge your payment mechanism until payment is
          received (upon receipt of payment, your Account will be activated and
          for purposes of automatic renewal, your new subscription commitment
          period will begin as of the day payment was received).
        </Paragraph>
        <SubListItem>12.3. Revenue Share</SubListItem>
        <Paragraph>
          In consideration for your use of the Services as a Creator, you, as a
          Creator, agree to pay us a fee equal to 20% of the gross sales of your
          Content Offering and revenue received from the sale of merchandise
          (the “<b>Revenue Share</b>”). Additionally, you agree to pay all fees
          imposed by the Payment Processor in connection with processing
          payments related to your Content Offering. Both we and the Payment
          Processor have the right to deduct our respective fees and Revenue
          Share directly from the gross sales of your Content Offering before
          the remaining balance of such gross sales or payment (the “
          <b>Balance</b>”) is remitted to you.{" "}
          <b>
            By way of example, if you sell a Class to 20 students at $500 per
            student, the total gross sales for the Class would be $10,000.
            Assuming the Payment Processor charges 3%, then $300 of your gross
            sales would be paid to the Payment Processor. Additionally, $2,000
            of your gross sales would be paid to us. After making those
            deductions, you would be entitled to the Balance of $7,700.
          </b>{" "}
          You may direct us, through our Payment Processor, to pay a portion of
          your gross sales from a Content Offering or a particular
          per-pay-button offering within a Content Offering to another Content
          Provider or user of the Services (“<b>Pay Split</b>”). You acknowledge
          and agree that the Pay Split will be applied to the Balance and
          calculated after the Revenue Share and applicable processing fees are
          deducted from your gross sales. Notwithstanding the foregoing, you
          will not receive the Balance or Pay Split for any declined, cancelled,
          expired, or unfulfilled Content Offering or for a Content Offering
          that does not comply with this Agreement or the Terms of Use. The
          Balance and/or Pay Split represents the aggregate compensation due to
          you in consideration of your Content Offerings. You agree to provide
          the Payment Processor with all information required to facilitate the
          processing of the fees, payments and payouts of the Revenue Share and
          Pay Split (“
          <b>Revenue Share Payout</b>”). If you do not provide all required
          information to the Payment Processor, then you may not be able to
          receive the Balance or Pay Split due to you. Any disputes relating to
          the manner in which a Payment Processor allocates such gross sales
          will be resolved directly between you and such Payment Processor and
          you hold us harmless from any and all such disputes. Project City may
          delay or cancel any Revenue Share Payout for purposes of preventing
          unlawful activity or fraud, risk assessment, security, or
          investigation.
        </Paragraph>
        <SubListItem>12.4. Sprint Revenue Share</SubListItem>
        <Paragraph>
          If applicable, in consideration for your services as a Sprint Teacher,
          we agree to pay Sprint Teachers a fee equal to 50% of all monthly
          payments made for Sprint Subscriptions from Sprint Subscription
          subscribers within a given month less a three percent (3%) fee imposed
          by the Payment Processor in connection with processing payments
          related to the Sprint Subscriptions, to be distributed among such
          month’s selected Sprint Teachers in accordance with this Section 12.4
          (“
          <b>Sprint Revenue Share</b>”). A percentage of the Sprint Revenue
          Share will be distributed equally among the applicable month’s Sprint
          Teachers based on the total number of Sprint Videos uploaded according
          to the monthly schedule (“<b>Sprint Video Share</b>”), and the rest
          will be distributed as a bonus among the applicable Sprint Teachers on
          a pro-rata basis based on watch statistics for all uploaded
          subscription videos, including any previous months’ Sprint Videos or
          any other Class recordings (“<b>Viewership Share</b>”). Project City
          reserves the right to adjust the percentage balance between the Sprint
          Video Share and the Viewership Share as needed and in its sole
          discretion provided that any changes will be communicated to you prior
          to the Sprint Revenue Share Payout (as defined herein). You agree to
          provide the Payment Processor with all information required to
          facilitate the processing of the fees, payments and payouts of the
          Sprint Revenue Share (“
          <b>Sprint Revenue Share Payout</b>”). If you do not provide all
          required information to the Payment Processor, then you may not be
          able to receive the balance due to you. Any disputes relating to the
          manner in which a Payment Processor allocates such gross sales will be
          resolved directly between you and such Payment Processor and you hold
          us harmless from any and all such disputes. Project City may delay or
          cancel any Sprint Revenue Share Payout for purposes of preventing
          unlawful activity or fraud, risk assessment, security, or
          investigation.
        </Paragraph>
        <ListItem>13. Modifying or Terminating our Services</ListItem>
        <Paragraph>
          We are constantly changing and improving our Services. We may add or
          remove functions, features, or requirements, and we may suspend or
          stop part of our Services altogether. Accordingly, Project City may
          terminate your use of any Service for any reason. We may not be able
          to deliver the Services to certain regions or countries for various
          reasons, including due to applicable export control requirements or
          internet access limitations and restrictions from governments. None of
          Project City, contributors, sponsors, and other business partners, and
          their employees, contractors, and other agents (the “
          <b>Project City Parties</b>”) shall have any liability to you for any
          such action. You can stop using our Services at any time, although
          we’ll be sorry to see you go.
        </Paragraph>
        <ListItem>14. Disclaimers</ListItem>
        <Paragraph>
          THE SERVICES AND ALL INCLUDED CONTENT ARE PROVIDED ON AN “AS IS” BASIS
          WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THE PROJECT
          CITY PARTIES SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES AND
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-
          INFRINGEMENT, SATISFACTORY QUALITY, QUIET ENJOYMENT, OR ACCURACY, AND
          ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE
          PROJECT CITY PARTIES FURTHER DISCLAIM ANY AND ALL LIABILITY RELATED TO
          YOUR ACCESS OR USE OF THE SERVICES OR ANY RELATED CONTENT. YOU
          ACKNOWLEDGE AND AGREE THAT ANY ACCESS TO OR USE OF THE SERVICES OR
          SUCH CONTENT IS AT YOUR OWN RISK.
        </Paragraph>
        <Paragraph>
          YOU ACKNOWLEDGE AND AGREE THAT PROJECT CITY PARTIES ARE NOT LIABLE,
          AND YOU AGREE NOT TO SEEK TO HOLD PROJECT CITY PARTIES LIABLE, FOR THE
          CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS OF EXTERNAL SITES, AND
          THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH
          YOU.
        </Paragraph>
        <Paragraph>
          YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
          INTERACTIONS WITH OTHER USERS OF THE SERVICES. YOU UNDERSTAND THAT
          PROJECT CITY DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF
          USERS OF THE SERVICES AND AGREE THAT THE RISK OF INJURY FROM CREATORS,
          STUDENTS, CONTENT SEEKERS OR OTHER USERS OF THE SERVICES RESTS
          ENTIRELY WITH YOU. PROJECT CITY MAKES NO WARRANTY THAT THE GOODS OR
          SERVICES PROVIDED BY CREATORS OR OTHER THIRD PARTIES WILL MEET YOUR
          REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR
          ERROR-FREE BASIS. PROJECT CITY MAKES NO WARRANTY REGARDING THE QUALITY
          OF ANY SUCH GOODS OR SERVICES, OR THE ACCURACY, TIMELINESS,
          TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY USER CONTENT OBTAINED
          THROUGH THE SERVICES.
        </Paragraph>
        <Paragraph>
          PROJECT CITY IS NOT OBLIGATED TO BACK UP ANY USER CONTENT; THE USER
          CONTENT CREATOR IS SOLELY RESPONSIBLE FOR CREATING BACKUP COPIES OF
          ANY APPLICABLE USER CONTENT AT ITS SOLE COST AND EXPENSE.
        </Paragraph>
        <ListItem>15. Limitation of Liability</ListItem>
        <Paragraph>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE PROJECT CITY PARTIES SHALL
          NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
          PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
          DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER
          INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR
          INABILITY TO ACCESS OR USE THE SERVICES; (B) ANY CONDUCT OR CONTENT OF
          ANY PARTY OTHER THAN THE APPLICABLE PROJECT CITY PARTY, INCLUDING
          WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT; OR
          (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT OR
          INFORMATION. IN NO EVENT SHALL PROJECT CITY’S AGGREGATE LIABILITY FOR
          ALL CLAIMS RELATED TO THE SERVICES EXCEED ONE HUNDRED U.S. DOLLARS
          ($100) OR THE TOTAL AMOUNT OF FEES RECEIVED BY PROJECT CITY FROM YOU
          FOR THE USE OF PAID SERVICES DURING THE PAST SIX MONTHS, WHICHEVER IS
          GREATER. FURTHER, YOU ACKNOWLEDGE AND AGREE THAT PROJECT CITY SHALL
          HAVE NO LIABILITY OR RESPONSIBILITY WHATSOEVER, INCLUDING FOR INDIRECT
          OR DIRECT DAMAGES, WITH RESPECT TO THIRD-PARTY OFFERINGS.
        </Paragraph>
        <Paragraph>
          EXCEPT FOR PROJECT CITY’S OBLIGATIONS TO PROTECT YOUR PERSONAL DATA AS
          SET FORTH IN THE PROJECT CITY’S PRIVACY POLICY, PROJECT CITY ASSUMES
          NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR
          FAILURE TO STORE ANY CONTENT (INCLUDING, BUT NOT LIMITED TO, USER
          CONTENT), USER COMMUNICATIONS OR PERSONALIZATION SETTINGS.
        </Paragraph>
        <Paragraph>
          YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMERS AND THE LIMITATIONS OF
          LIABILITY SET FORTH IN THIS TERMS OF USE REFLECT A REASONABLE AND FAIR
          ALLOCATION OF RISK BETWEEN YOU AND THE PROJECT CITY PARTIES, AND THAT
          THESE LIMITATIONS ARE AN ESSENTIAL BASIS TO PROJECT CITY’S ABILITY TO
          MAKE THE SERVICES AVAILABLE TO YOU ON AN ECONOMICALLY FEASIBLE BASIS.
        </Paragraph>
        <ListItem>16. Indemnification</ListItem>
        <Paragraph>
          You, as a Student, Content Seeker, Creator, or otherwise as a user of
          the Services, agree to indemnify, defend, and hold harmless the
          Project City Parties from any and all claims, liabilities, expenses,
          and damages, including reasonable attorneys’ fees and costs, made by
          any third party related to: (a) your use or attempted use of the
          Services (including Third-Party Offerings) in violation of these
          Terms; (b) your violation of any law or rights of any third party; or
          (c) User Content, including without limitation any claim of
          infringement or misappropriation of intellectual property or other
          proprietary rights. If you are a Creator, in addition to the
          indemnification obligations above, you also agree to promptly defend,
          indemnify and reimburse, and hold harmless Project City and its
          directors, officers, employees, agents or advisors (“
          <b>Representatives</b>”), to the fullest extent permitted by law, as
          such may be amended from time to time, from any losses, liabilities,
          legal fees or expenses incurred by Project City or its
          Representatives, as a result of: (a) your negligence, intentional
          wrongdoing, or a breach of these Terms or the Creator Code of Conduct;
          or (b) any liability you incur or Project City incurs for the payment
          or non-payment of federal, state, or local taxes, or other
          withholdings, involving you and/or your Content Offerings. Project
          City shall be entitled to the rights of indemnification provided if,
          by reason of your Content Offerings under these Terms, Project City
          is, or is threatened to be made, a party to or participant in any
          proceeding, including any actual or threatened administrative or civil
          action or lawsuit, arbitration, or criminal indictment or case. You
          will cooperate as fully and reasonably as required by Project City in
          the defense of any claim related to any services under these Terms.
        </Paragraph>
        <ListItem>17. Governing Law and Venue</ListItem>
        <Paragraph>
          The Services are managed by Project City, which is located in Los
          Angeles County, California. You agree that any dispute related to
          these Terms will be governed by the laws of the State of California,
          excluding its conflicts of law provisions. In the event of any dispute
          related to these Terms that is not subject to binding arbitration, you
          and Project City will submit to the personal jurisdiction of and
          exclusive venue in the federal and state courts located in and serving
          Los Angeles County, California as the legal forum for any such
          dispute.
        </Paragraph>
        <ListItem>18. Binding Arbitration and Class Action Waiver</ListItem>
        <Paragraph>
          18.1. <u>Applicability of Arbitration Agreement</u>. You agree that
          any dispute or claim relating in any way to your access or use of the
          Services, to any products sold or distributed through the Services, or
          to any aspect of your relationship with Project City, will be resolved
          by binding arbitration, rather than in court, except that (1) you may
          assert claims in small claims court if your claims qualify, so long as
          the matter remains in such court and advances only on an individual
          (non- class, non-representative) basis; and (2) you or Project City
          may seek equitable relief in court for infringement or other misuse of
          intellectual property rights (such as trademarks, trade dress, domain
          names, trade secrets, copyrights, and patents).{" "}
          <b>
            This Arbitration Agreement shall apply, without limitation, to all
            claims that arose or were asserted before the Effective Date of this
            Agreement or any prior version of this Agreement.
          </b>
        </Paragraph>
        <Paragraph>
          <b>
            IF YOU AGREE TO ARBITRATION WITH PROJECT CITY, YOU ARE AGREEING IN
            ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY
            OR OTHER RELIEF IN ANY LAWSUIT FILED AGAINST PROJECT CITY ALLEGING
            CLASS, COLLECTIVE, AND/OR REPRESENTATIVE CLAIMS ON YOUR BEHALF.
            INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING YOUR CLAIMS
            AGAINST THE PROJECT CITY IN AN INDIVIDUAL ARBITRATION PROCEEDING. IF
            SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED MONEY OR OTHER
            RELIEF BY AN ARBITRATOR. YOU ACKNOWLEDGE THAT YOU HAVE BEEN ADVISED
            THAT YOU MAY CONSULT WITH AN ATTORNEY IN DECIDING WHETHER TO ACCEPT
            THIS AGREEMENT, INCLUDING THIS ARBITRATION AGREEMENT.
          </b>
        </Paragraph>
        <Paragraph>
          18.2. <u>Arbitration Rules and Forum</u>. The Federal Arbitration Act
          governs the interpretation and enforcement of this Arbitration
          Agreement. To begin an arbitration proceeding, you must send a letter
          requesting arbitration and describing your claim to our registered
          agent: Cogency Global, Inc., 850 New Burton Road, Suite 201, Dover,
          Delaware 19904. The arbitration will be conducted by JAMS, an
          established alternative dispute resolution provider. Disputes
          involving claims and counterclaims under $250,000, not inclusive of
          attorneys’ fees and interest, shall be subject to JAMS’s most current
          version of the Streamlined Arbitration Rules and procedures available
          at http://www.jamsadr.com/rules-streamlined-arbitration/ ; all other
          claims shall be subject to JAMS’s most current version of the
          Comprehensive Arbitration Rules and Procedures, available at
          http://www.jamsadr.com/rules-comprehensive- arbitration/. JAMS’s rules
          are also available at www.jamsadr.com or by calling JAMS at
          800-352-5267. If JAMS is not available to arbitrate, the parties will
          select an alternative arbitral forum. If the arbitrator finds that you
          cannot afford to pay JAMS’s filing, administrative, hearing and/or
          other fees and cannot obtain a waiver from JAMS, Project City will pay
          them for you. In addition, Project City will reimburse all such JAMS’s
          filing, administrative, hearing and/or other fees for claims totaling
          less than $10,000 unless the arbitrator determines the claims are
          frivolous. In addition, in any arbitration arising out of or related
          to this Agreement, the arbitrator(s) shall award to the prevailing
          party, if any, the costs and attorneys' fees reasonably incurred by
          the prevailing party in connection with the arbitration. If the
          arbitrator(s) determine a party to be the prevailing party under
          circumstances where the prevailing party won on some but not all of
          the claims and counterclaims, the arbitrator(s) may award the
          prevailing party an appropriate percentage of the costs and attorneys'
          fees reasonably incurred by the prevailing party in connection with
          the arbitration.
        </Paragraph>
        <Paragraph>
          You may choose to have the arbitration conducted by telephone, based
          on written submissions, or in person in the country where you live or
          at another mutually agreed location. Any judgment on the award
          rendered by the arbitrator may be entered in any court of competent
          jurisdiction.
        </Paragraph>
        <Paragraph>
          18.3. <u>Authority of Arbitrator</u>. The arbitrator, and not any
          federal, state or local court or agency shall have exclusive authority
          to (a) determine the scope and enforceability of this Arbitration
          Agreement and (b) resolve any dispute related to the interpretation,
          applicability, enforceability or formation of this Arbitration
          Agreement including, but not limited to any claim that all or any part
          of this Arbitration Agreement is void or voidable. The arbitration
          will decide the rights and liabilities, if any, of you and Project
          City. The arbitration proceeding will not be consolidated with any
          other matters or joined with any other cases or parties. The
          arbitrator shall have the authority to grant motions dispositive of
          all or part of any claim. The arbitrator shall have the authority to
          award monetary damages and to grant any non-monetary remedy or relief
          available to an individual under applicable law, the arbitral forum’s
          rules, and the Agreement (including the Arbitration Agreement). The
          arbitrator shall issue a written award and statement of decision
          describing the essential findings and conclusions on which the award
          is based, including the calculation of any damages awarded. The
          arbitrator has the same authority to award relief on an individual
          basis that a judge in a court of law would have. The award of the
          arbitrator is final and binding upon you and us.
        </Paragraph>
        <Paragraph>
          18.4. <u>Waiver of Jury Trial</u>. YOU AND PROJECT CITY HEREBY WAIVE
          ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A
          TRIAL IN FRONT OF A JUDGE OR A JURY. You and Project City are instead
          electing that all claims and disputes shall be resolved by arbitration
          under this Arbitration Agreement, except as specified in the initial
          paragraph of this Arbitration Agreement. An arbitrator can award on an
          individual basis the same damages and relief as a court and must
          follow this Agreement as a court would. However, there is no judge or
          jury in arbitration, and court review of an arbitration award is
          subject to very limited review.
        </Paragraph>
        <Paragraph>
          18.5. <u>Waiver of Class or Consolidated Actions</u>. ALL CLAIMS AND
          DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
          ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, ONLY
          INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER
          OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER
          CUSTOMER OR USER. Notwithstanding anything to the contrary herein, (a)
          representative action for public injunctive relief may be arbitrated
          on a class basis and (b) in the event that the foregoing sentence is
          deemed invalid or unenforceable with respect to a particular class or
          dispute for recovery of damages, neither you nor we are entitled to
          arbitration and instead claims and disputes shall be resolved in a
          court as set forth in the General Provisions below.
        </Paragraph>
        <Paragraph>
          18.6. <u>30-Day Right to Opt Out</u>. You have the right to opt out of
          the provisions of this Arbitration Agreement by sending written notice
          of your decision to opt out to support@projectcity.tv or to the
          following address: Project City, 1223 Wilshire Blvd. #1036, Santa
          Monica, CA 90403 within 30 days after first becoming subject to this
          Arbitration Agreement. Your notice must include your name and address,
          your Project City username (if any), the email address you used to set
          up your Project City account (if you have one), and an unequivocal
          statement that you want to opt out of this Arbitration Agreement. If
          you opt out of this Arbitration Agreement, all other parts of this
          Agreement will continue to apply to you. Opting out of this
          Arbitration Agreement has no effect on any other arbitration
          agreements that you may currently have, or may enter in the future,
          with us.
        </Paragraph>
        <Paragraph>
          18.7. <u>Severability</u>. If any part or parts of this Arbitration
          Agreement are found under the law to be invalid or unenforceable, then
          such specific part or parts shall be of no force and effect and shall
          be severed and the remainder of the Arbitration Agreement shall
          continue in full force and effect.
        </Paragraph>
        <Paragraph>
          18.8. <u>Survival of Agreement</u>. This Arbitration Agreement will
          survive the termination of your relationship with Project City.
        </Paragraph>
        <Paragraph>
          18.9. <u>Modification</u>. Notwithstanding any provision in this
          Agreement to the contrary, we agree that if Project City makes any
          future material change to this Arbitration Agreement, it will not
          apply to any individual claim(s) that you had already provided notice
          to Project City.
        </Paragraph>
        <ListItem>19. General Terms</ListItem>
        <SubListItem>19.1. Revisions to the Terms</SubListItem>
        <Paragraph>
          We reserve the right to revise the Terms at our sole discretion at any
          time. Any revisions to the Terms will be effective immediately upon
          posting by us. For any material changes to the Terms, we will take
          reasonable steps to notify you of such changes, via a banner on the
          website, email notification, another method, or combination of
          methods. In all cases, your continued use of the Services after
          publication of such changes, with or without notification, constitutes
          binding acceptance of the revised Terms.
        </Paragraph>
        <SubListItem>19.2. Relationship of the Parties</SubListItem>
        <Paragraph>
          Neither these Terms, nor your performance under these Terms, shall
          create an association, partnership, joint venture, or relationship of
          principal and agent, master and servant, or employer and employee,
          between Project City and you, whether you are a user, Content Seeker,
          or Creator. Project City and you agree that you will receive no
          Project City-sponsored benefits from Project City where benefits
          include, but are not limited to, paid vacation, sick leave, medical
          insurance and 401k participation. If you are reclassified by a state
          or federal agency or court as Project City’s employee, you will become
          a reclassified employee and will receive no benefits from Project
          City, except those mandated by state or federal law, even if by the
          terms of Project City’s benefit plans or programs of Project City in
          effect at the time of such reclassification, you would otherwise be
          eligible for such benefits. You acknowledge it is your sole
          responsibility for complying with all federal, state and local tax
          filing and payment obligations that pertain to any remuneration
          received from Students, other users of the Services, or as processed
          by Project City in connection with this Agreement or the Creator
          Agreement, including your sole responsibility for all tax withholding,
          Social Security, Worker’s Compensation Insurance, FICA, unemployment
          compensation, medical insurance, life insurance, paid vacations, paid
          holidays, pensions, and other obligations or benefits. You acknowledge
          that Project City is not rendering legal, tax, or investment advice,
          nor is Project City a fiduciary of you. Accordingly, you acknowledge
          that you may seek advice from an appropriate professional to comply
          with any and all applicable federal, state, and local laws or
          ordinances.
        </Paragraph>
        <SubListItem>19.3. Electronic Communications</SubListItem>
        <Paragraph>
          The communications between you and Project City may take place via
          electronic means, whether you visit the Services or send Project City
          e-mails, or whether Project City posts notices on the Services or
          communicates with you via e-mail. For contractual purposes, you (a)
          consent to receive communications from Project City in an electronic
          form; and (b) agree that all terms and conditions, agreements,
          notices, disclosures, and other communications that Project City
          provides to you electronically satisfy any legal requirement that such
          communications would satisfy if it were to be in writing. The
          foregoing does not affect your statutory rights, including but not
          limited to the Electronic Signatures in Global and National Commerce
          Act at 15 U.S.C. §7001 et seq. (“<b>E-Sign</b>”).
        </Paragraph>
        <SubListItem>19.4. Assignment</SubListItem>
        <Paragraph>
          The Terms, and your rights and obligations hereunder, may not be
          assigned, subcontracted, delegated or otherwise transferred by you
          without Project City’s prior written consent, and any attempted
          assignment, subcontract, delegation, or transfer in violation of the
          foregoing will be null and void.
        </Paragraph>
        <SubListItem>19.5. Force Majeure</SubListItem>
        <Paragraph>
          Project City shall not be liable for any delay or failure to perform
          resulting from causes outside its reasonable control, including, but
          not limited to, acts of God, war, terrorism, riots, embargos, acts of
          civil or military authorities, fire, floods, accidents, strikes or
          shortages of transportation facilities, fuel, energy, labor or
          materials.
        </Paragraph>
        <SubListItem>19.6. Notice</SubListItem>
        <Paragraph>
          Where Project City requires that you provide an e-mail address, you
          are responsible for providing Project City with your most current
          e-mail address. In the event that the last e-mail address you provided
          to Project City is not valid, or for any reason is not capable of
          delivering to you any notices required/ permitted by the Terms,
          Project City’s dispatch of the e-mail containing such notice will
          nonetheless constitute effective notice. You may give notice to
          Project City at the following address: 1223 Wilshire Blvd. #1036,
          Santa Monica, CA 90403. Such notice shall be deemed given when
          received by Project City by letter delivered by nationally recognized
          overnight delivery service or first class postage prepaid mail at the
          above address.
        </Paragraph>
        <SubListItem>19.7. Severability; Waiver</SubListItem>
        <Paragraph>
          If it turns out that a particular provision of these Terms is not
          enforceable, this will not affect any other terms. If you do not
          comply with these Terms, and we do not take immediate action, this
          does not indicate that we relinquish any rights that we may have (such
          as taking action in the future).
        </Paragraph>
        <SubListItem>19.8. Notice for California Users</SubListItem>
        <Paragraph>
          Under California Civil Code Section 1789.3, California website users
          are entitled to the following specific consumer rights notice: The
          Complaint Assistance Unit of the Division of Consumer Services of the
          California Department of Consumer Affairs may be contacted in writing
          at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or
          by telephone at (800) 952-5210.
        </Paragraph>
        <SubListItem>19.9. Entire Agreement</SubListItem>
        <Paragraph>
          The Terms are the final, complete and exclusive agreement of the
          parties with respect to the subject matter hereof and supersedes and
          merges all prior discussions between the parties with respect to such
          subject matter.
        </Paragraph>

        <Section ref={acceptableUsePolicy}>
          <SectionHeader text="Acceptable Use Policy" />
          <Paragraph>Effective: {lastUpdatedDate}</Paragraph>
          <Paragraph>
            Project City may remove or edit inappropriate content or activity on
            the Services identified by or reported to us. In the event of a
            violation of this Acceptable Use Policy (or any other provisions of
            the Terms), we may suspend, disable, or terminate a user’s access to
            all or part of the Services.
          </Paragraph>
          <Paragraph>
            You are prohibited from using our Services to share content that:
          </Paragraph>
          <BulletList>
            <BulletListItem>
              Contains illegal content or promotes illegal activities with the
              intent to commit such activities.
            </BulletListItem>
            <BulletListItem>
              Contains credible threats or organizes acts of real-world
              violence. We do not allow content that creates a genuine risk of
              physical injury or property damage, credibly threatens people or
              public safety, or organizes or encourages harm.
            </BulletListItem>
            <BulletListItem>
              Contains overtly sexual or pornographic material, as determined by
              us in our sole discretion.
            </BulletListItem>
            <BulletListItem>
              Harasses, threatens or harms others. We encourage commentary about
              people and macers of public interest, but abusive or otherwise
              inappropriate content directed at private individuals is not
              allowed, including but not limited to defamatory, discriminatory,
              or mean- spirited content, including references or commentary
              about religion, race, sexual orientation, gender, national/ethnic
              origin, or other targeted groups, particularly if the app is
              likely to humiliate, intimidate, or harm a targeted individual or
              group.{" "}
            </BulletListItem>
            <BulletListItem>
              Violates, infringes or misappropriates intellectual property,
              privacy, or other rights. Do not share content that you do not
              have the right to share, claim content that you did not create as
              your own, or otherwise infringe or misappropriate someone else’s
              intellectual property or other rights. Always acribute materials
              used or quoted by you to the original copyright owner.{" "}
            </BulletListItem>
            <BulletListItem>
              Spams others. Do not share irrelevant or inappropriate
              advertising, promotional, or solicitation content.
            </BulletListItem>
            <BulletListItem>
              Otherwise violates the Project City Terms of Use.
            </BulletListItem>
          </BulletList>
          <Paragraph>
            Please note that specific Content Offerings and Third-Party
            Offerings may have additional rules and requirements.
          </Paragraph>
          <Paragraph>You are not allowed to:</Paragraph>
          <BulletList>
            <BulletListItem>
              Do anything that violates local, state, national, or international
              law, regulation or court order, or breaches any of your
              contractual obligations or fiduciary duties.{" "}
            </BulletListItem>
            <BulletListItem>
              Share your password, let anyone besides yourself access your
              account, or do anything that might put your account at risk.{" "}
            </BulletListItem>
            <BulletListItem>
              Attempt to access any other user’s account.{" "}
            </BulletListItem>
            <BulletListItem>
              Reproduce, transfer, sell, resell, or otherwise misuse any content
              from our Services, unless specifically authorized to do so.{" "}
            </BulletListItem>
            <BulletListItem>
              Access, tamper with, or use non-public areas of our systems,
              unless specifically authorized to do so.{" "}
            </BulletListItem>
            <BulletListItem>
              Upload, post, e-mail, transmit or otherwise make available any
              unsolicited or unauthorized advertising, promotional materials,
              “junk mail,” “spam,” “chain lecers,” “pyramid schemes,” or any
              other form of solicitation, including, without limitation, posting
              comments promoting other third-party products, services and
              websites.{" "}
            </BulletListItem>
            <BulletListItem>
              Use the Services to collect, harvest, transmit, distribute, post
              or submit any information concerning any other person or entity,
              including without limitation, photographs of others without their
              permission, personal contact information or credit, debit, calling
              card or account numbers.{" "}
            </BulletListItem>
            <BulletListItem>
              Break or circumvent our authentication or security measures or
              otherwise test the vulnerability of our systems or networks,
              unless specifically authorized to do so.{" "}
            </BulletListItem>
            <BulletListItem>
              Reverse engineer (or attempt to reverse engineer) any portion of
              our Services.
            </BulletListItem>
            <BulletListItem>
              Try to interfere with any user, host, or network, for example by
              sending a virus, overloading, spamming, or mail-bombing.{" "}
            </BulletListItem>
            <BulletListItem>
              Use our Services to distribute malware.{" "}
            </BulletListItem>
            <BulletListItem>
              Use our Services or any functionality of the Project City platform
              for anything other than for completing online courses, for
              pedagogical purposes, for otherwise creating, developing,
              purchasing, viewing, or otherwise participating in the Content
              Offerings through the Services.{" "}
            </BulletListItem>
            <BulletListItem>
              Impersonate or misrepresent your affiliation with any person or
              entity.
            </BulletListItem>
            <BulletListItem>
              Encourage or help anyone do any of the things on this list.
            </BulletListItem>
          </BulletList>
        </Section>
        <Section ref={copyrightAndTrademarkPolicy}>
          <SectionHeader text="Copyright and Trademark Policy" />
          <Paragraph>Effective: {lastUpdatedDate}</Paragraph>
          <Paragraph>
            Project City respects the intellectual property rights of our
            Teachers, Project Creators, Students, and other third parties and
            expects our users to do the same when using the Services. We reserve
            the right to suspend, disable, or terminate the accounts of users
            who repeatedly infringe or are repeatedly charged with infringing
            the copyrights, trademarks, or other intellectual property rights of
            others.
          </Paragraph>
          <Paragraph>
            The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
            recourse for copyright owners who believe that material appearing on
            the Internet infringes their rights under U.S. copyright law. If you
            believe in good faith that materials on the Project City platform
            infringe your copyright, the DMCA provides that you (or your agent)
            may send us a notice requesting that the material be removed or
            access to it blocked.
          </Paragraph>
          <Paragraph>
            The notice must include the following information:
          </Paragraph>
          <OrderedList>
            <BulletListItem>
              the physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed;
            </BulletListItem>
            <BulletListItem>
              identification of the copyrighted work claimed to have been
              infringed (or, if multiple copyrighted works located on the
              platform are covered by a single notification, a representative
              list of such works);
            </BulletListItem>
            <BulletListItem>
              identification of the material that is claimed to be infringing or
              the subject of infringing activity, and information reasonably
              sufficient to allow Project City to locate the material on the
              platform;
            </BulletListItem>
            <BulletListItem>
              the name, address, telephone number, and email address (if
              available) of the complaining party;
            </BulletListItem>
            <BulletListItem>
              a statement that the complaining party has a good faith belief
              that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent, or the law; and
            </BulletListItem>
            <BulletListItem>
              a statement that the information in the notification is accurate
              and, under penalty of perjury, that the complaining party is
              authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed.
            </BulletListItem>
          </OrderedList>
          <Paragraph>
            Notices must meet the then-current statutory requirements imposed by
            the DMCA. Notices and counter-notices with respect to the Services
            can either be sent:
          </Paragraph>
          <OrderedList>
            <BulletListItem>
              via mail: Copyright Agent, 1223 Wilshire Blvd. #1036, Santa Monica
              CA, 90403
            </BulletListItem>
            <BulletListItem>via email: support@projectcity.tv</BulletListItem>
          </OrderedList>
          <Paragraph>
            We suggest that you consult your legal advisor before filing a
            notice. Also, be aware that there can be penalties for false claims
            under the DMCA.
          </Paragraph>
          <Paragraph>
            Project City also respects the trademark rights of others. Accounts
            with any other content that misleads others or violates another’s
            trademark may be updated, suspended, disabled, or terminated by
            Project City in its sole discretion. If you are concerned that
            someone may be using your trademark without proper permission on our
            Services, please email us at support@projectcity.tv and we will
            review your submission. If we deem appropriate, we may remove the
            offending content, warn the individual who posted the content,
            and/or temporarily or permanently suspend or disable the
            individual’s account.
          </Paragraph>
        </Section>
      </ContentContainer>
    </BaseContainer>
  );
}

TermsOfUseDocument.propTypes = {
  /** Allow linking to specific sections of the document. */
  section: oneOf(["copyright-and-trademark-policy", "acceptable-use-policy"]),
};

export default TermsOfUseDocument;
