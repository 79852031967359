import React from "react";
import styled from "styled-components";

import CarouselNavigationButton from "../NavigationButton";
import IconPrev from "images/icons/prev.svg";

const StyledImg = styled.img`
  width: 100px;
`;

function CarouselPreviousButton() {
  return (
    <CarouselNavigationButton>
      <StyledImg src={IconPrev} />
    </CarouselNavigationButton>
  );
}

export default CarouselPreviousButton;
