import React, { useState } from "react";
import { shape } from "prop-types";
import styled from "styled-components";
import Moment from "react-moment";
import { useModal } from "react-modal-hook";

import BucketUploadModal from "components/modals/BucketUpload";
import Caption from "components/text/Caption";
import ProjectModal from "features/projects/modals/Project";
import { Notification as NotificationType } from "types";
import { useUpdateNotificationMutation } from "services/projectCity";

const Content = styled.div`
  font-family: Basis Grotesque Pro;
  opacity: ${(props) => (!props.seen ? "1" : ".5")};
  color: ${(props) => props.theme.black};
  font-size: 20px;
  line-height: 21px;
`;

const StyledCaption = styled(Caption)`
  color: ${(props) => props.theme.gray2};
  font-weight: 200;
  margin-top: 5px;
  margin-bottom: 0;
`;

function NotificationContent({ notification }) {
  /** Render the notification content in a notification. */

  const [modalObject, setModalObject] = useState();
  const [markNotificationAsSeen] = useUpdateNotificationMutation();

  const renderNotificationContent = (notification) => {
    /** Render the main html content for the notification. */

    const { target, verb, actor } = notification.action;
    const actorUsername = actor ? actor.username : "";
    let content = "";

    switch (notification.action.verb) {
      case "made a comment":
        content =
          target &&
          `<b>${actorUsername}</b> ${verb} on <b>‘${
            target.bucket
              ? target.bucket.title
              : target.title
              ? target.title
              : "your post"
          }’</b>`;
        return { __html: content };

      case "mentioned":
        content = `<b>${actorUsername}</b> has ${verb} you`;
        return { __html: content };

      case "liked":
        content = `<b>${actorUsername}</b> has Liked your post ${
          target.text ? target.text : ""
        }.`;
        return { __html: content };

      case "replied to a comment":
        content = `<b>${actorUsername}</b> replied to your comment.`;
        return { __html: content };

      default:
        content = "";
        return { __html: content };
    }
  };

  const [showBucketUploadModal, hideBucketUploadModal] = useModal(() => {
    return (
      <BucketUploadModal
        bucketUpload={modalObject}
        onHide={hideBucketUploadModal}
      />
    );
  }, [modalObject]);

  const [showProjectModal, hideProjectModal] = useModal(() => {
    return <ProjectModal project={modalObject} onHide={hideProjectModal} />;
  }, [modalObject]);

  function handleNotificationClick() {
    /** Handle event when user clicks on a notification, based on the verb.  */

    markNotificationAsSeen({ id: notification.id, seen: true });

    // Set the modal object that we are opening from the notification.
    const { actionObject, target } = notification.action;
    const obj =
      notification.action.verb === "made a comment" ? actionObject : target;
    setModalObject(obj.contentObject);

    switch (notification.action.verb) {
      case "liked":
      case "mentioned":
      case "replied to a comment":
      case "made a comment":
        if (obj.contentObject)
          !obj.contentObject.hasOwnProperty("coverImage")
            ? showProjectModal()
            : showBucketUploadModal();
        break;
      default:
        break;
    }
  }

  return (
    <div onClick={handleNotificationClick}>
      <Content
        dangerouslySetInnerHTML={renderNotificationContent(notification)}
        seen={notification.seen ? "seen" : null}
      />
      <StyledCaption size="sm">
        <Moment fromNow>{notification.action.timestamp}</Moment>
      </StyledCaption>
    </div>
  );
}

NotificationContent.propTypes = {
  /** The notification object that is being rendered. */
  notification: shape(NotificationType).isRequired,
};

export default NotificationContent;
