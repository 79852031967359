import React from "react";
import { arrayOf, bool, oneOf, string } from "prop-types";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import SubHeader from "components/text/SubHeader";
import { textColors } from "styles/constants";
import { Project } from "types";
import ProfileProjectTile from "../ProjectTile";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingXl};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingLg};
  }
`;

const ProjectListHeader = styled(SubHeader)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 38px;
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingSm};
  }
`;

const MobileProjectContainer = styled.div`
  > div {
    // transform: translateX(-80px);
  }
`;

function ProfileProjectSection({ projects, header, isOwnProfile, color }) {
  /** Render a project section in a user's profile. */

  if (projects.length === 0) return null;

  function renderProjectTiles({ withColumn }) {
    /** In the projects section of the profile, the projects should be shown. */

    return projects.map((project, index) => {
      const tile = (
        <ProfileProjectTile
          key={project.id}
          project={project}
          isOwnProfile={isOwnProfile}
          isEven={index % 2 === 0}
          showTeachers={header !== "teaching"}
          color={color}
        />
      );

      return withColumn ? <Col sm={isOwnProfile ? 6 : 4}>{tile}</Col> : tile;
    });
  }

  return (
    <Wrapper>
      <ProjectListHeader italic size="lg" text={header} color={color} />
      {isMobile ? (
        <MobileProjectContainer>
          {renderProjectTiles({ withColumn: false })}
        </MobileProjectContainer>
      ) : (
        <Row>{renderProjectTiles({ withColumn: true })}</Row>
      )}
    </Wrapper>
  );
}

ProfileProjectSection.propTypes = {
  /** The projects that should be rendered */
  projects: arrayOf(Project).isRequired,

  /** Text to show at the top of the section. */
  header: string.isRequired,

  /** Determine if the user is viewing their own profile. */
  isOwnProfile: bool,

  /** The color of the headers in the section. */
  color: oneOf(textColors),
};

export default ProfileProjectSection;
