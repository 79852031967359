import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import React from "react";

function MultipartUploadCompleteModal(props) {
  return (
    <BaseModal {...props}>
      <ModalHeader
        title="Upload complete"
        closeButton
        closeModal={props.onHide}
      />
      <ModalSummaryText>
        Although the upload is complete, it could still take up to a minute to
        fully process your file due to its size. You will need to refresh the
        page to view the uploaded file.
      </ModalSummaryText>
    </BaseModal>
  );
}

export default MultipartUploadCompleteModal;
