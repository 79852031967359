import React from "react";
import styled from "styled-components";
import ListGroup from "react-bootstrap/ListGroup";

const StyledListGroup = styled(ListGroup)`
  > .list-group-item {
    font-family: Basis Grotesque Mono;
    padding-left: ${(props) => props.theme.vSpacingXs};
    background-color: ${(props) => props.theme.purple};
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.yellow};
    transition: padding-left ease ${(props) => props.theme.transitionSuperFast};
    color: ${(props) => props.theme.yellow};

    &:hover {
      cursor: pointer;
      padding-left: ${(props) => props.theme.vSpacingSm};
    }
  }
`;

function ModalActionsListGroup(props) {
  /** Provide a list group to be used in a modal to choose an action. */
  return <StyledListGroup>{props.children}</StyledListGroup>;
}

export default ModalActionsListGroup;
