import React, { useState } from "react";
import { oneOf } from "prop-types";
import styled from "styled-components";

import CarouselNavigationButton from "../NavigationButton";
import CarouselRightIcon from "images/carousels/arrow-right.svg";
import CarouselRightHoverIcon from "images/carousels/arrow-right-hover.svg";
import CarouselLeftIcon from "images/carousels/arrow-left.svg";
import CarouselLeftHoverIcon from "images/carousels/arrow-left-hover.svg";

const StyledIcon = styled.img`
  width: 70px;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    width: 55px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: 35px;
  }

  &:hover {
    width: 70px;
    transform: rotate(5deg) scale(110%);

    @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
      width: 55px;
    }

    @media (max-width: ${(props) => props.theme.smBreakpoint}) {
      width: 35px;
    }
  }
`;

const StyledCarouselNavigationButton = styled(CarouselNavigationButton)`
  border: none !important;
  background: none !important;
  z-index: 100;
  padding: 0px !important;
  right: 0px !important;

  &::before {
    content: none !important;
  }

  &.slick-disabled {
    display: none !important;
  }
`;

function SliderArrow({ direction, ...props }) {
  /** Provide a slider arrow component for our sliders. */
  const [hover, setHover] = useState(false);

  function getSrc() {
    if (direction === "left") {
      return hover ? CarouselLeftHoverIcon : CarouselLeftIcon;
    }

    return hover ? CarouselRightHoverIcon : CarouselRightIcon;
  }

  return (
    <StyledCarouselNavigationButton
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      <StyledIcon src={getSrc()} />
    </StyledCarouselNavigationButton>
  );
}

SliderArrow.propTypes = {
  /** The direction that the arrow is facing. */
  direction: oneOf(["left", "right"]).isRequired,
};

export default SliderArrow;
