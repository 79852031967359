import { number } from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import LoadingContainer from "components/loading/Container";
import DonationTable from "features/donations/tables/DonationTable";
import DonationsBySeriesTable from "features/payments/tables/DonationsBySeries";
import DonationsByAnimaticTable from "features/payments/tables/DonationsByAnimatic";
import { useGetDonationPayoutDetailQuery } from "services/projectCity";

function DonationTransferDetailModal({ donationTransferId, ...props }) {
  /** Show details of what donations were included in a transfer. */

  const { data, isLoading } = useGetDonationPayoutDetailQuery({
    donationTransferId,
  });

  return (
    <BaseModal size="xl" {...props}>
      <ModalHeader
        title="Donation Transfer Details"
        closeModal={props.onHide}
        closeButton
      />
      {isLoading ? (
        <LoadingContainer text="Loading donation data..." />
      ) : (
        <Tabs
          variant="pills"
          defaultActiveKey="donations"
          transition={false}
          id="payment-tabs"
          className="mb-3"
        >
          <Tab eventKey="donations" title="Donations">
            <DonationTable type="received" donations={data.donations} />
          </Tab>
          <Tab eventKey="animatic" title="By animatic">
            <DonationsByAnimaticTable animaticData={data.animatics} />
          </Tab>
          <Tab eventKey="series" title="By series">
            <DonationsBySeriesTable seriesData={data.series} />
          </Tab>
        </Tabs>
      )}
    </BaseModal>
  );
}

DonationTransferDetailModal.propTypes = {
  donationTransferId: number.isRequired,
};

export default DonationTransferDetailModal;
