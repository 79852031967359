import React from "react";
import { bool, shape, string } from "prop-types";
import { useModal } from "react-modal-hook";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import ModifyButton from "components/buttons/Modify";
import ActionSection from "components/containers/Action";
import ActionContainer from "components/containers/Actions";
import FormModal from "components/modals/Form";
import ProjectSectionForm from "features/projects/forms/TextSection";
import { updateProject } from "features/projects/thunks";
import Body from "components/text/Body";
import SubHeader from "components/text/SubHeader";
import { Project } from "types";
import { isProjectAdmin } from "utils/projects";
import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";

const Wrapper = styled.div`
  position: relative;
  height: fit-content;

  ${(props) =>
    props.stagger &&
    `
    margin-top: ${props.theme.vSpacingXl}!important;
  `}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingLg} 0;
  }
`;

function ProjectFooterSection({
  project,
  field,
  shouldViewAdminItems,
  ...props
}) {
  /** Display a text section in a project info page. */

  const titleField = `${field}Title`;
  const textField = `${field}Text`;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.user);
  const [openModifySectionModal, closeModifySectionModal] = useModal(() => {
    return (
      <FormModal
        onHide={closeModifySectionModal}
        title="Modify project section"
      >
        <ProjectSectionForm
          project={project}
          field={field}
          closeModal={closeModifySectionModal}
        />
      </FormModal>
    );
  }, [project]);
  const [openConfirmRemoveModal, closeConfirmRemoveModal] = useModal(() => {
    function removeSection() {
      const payload = {
        [textField]: "",
        [titleField]: "",
      };
      dispatch(updateProject({ payload, projectId: project.id }));
      closeConfirmRemoveModal();
    }

    return (
      <ConfirmActionModal
        onHide={closeConfirmRemoveModal}
        title="Clear section"
        confirmText="Yes, remove"
        confirmAction={removeSection}
      >
        <ModalSummaryText>
          Are you sure you want to remove this section? The section will become
          empty, but you can always fill it again.
        </ModalSummaryText>
      </ConfirmActionModal>
    );
  }, [project]);

  if (project[titleField] === "")
    return isProjectAdmin(user, project) ? (
      <ActionSection
        buttonText="Fill section"
        onClick={openModifySectionModal}
        className="my-5"
      />
    ) : null;

  return (
    <Wrapper {...props}>
      <SubHeader text={project[titleField]} />
      <Body dangerouslySetInnerHTML={{ __html: project[textField] }} />
      {shouldViewAdminItems && (
        <ActionContainer bottom={-20}>
          <ModifyButton type="update" onClick={openModifySectionModal} />
          <ModifyButton type="delete" onClick={openConfirmRemoveModal} />
        </ActionContainer>
      )}
    </Wrapper>
  );
}

ProjectFooterSection.propTypes = {
  /** The project whose footer section is being rendered. */
  project: shape(Project).isRequired,

  /** The field key for the field. */
  field: string.isRequired,

  /** Determine if the user is an admin that should be able to modify the project. */
  shouldViewAdminItems: bool.isRequired,

  /** Give the section a top padding, staggering it with sibling components. */
  stagger: bool,
};

ProjectFooterSection.defaultProps = {
  stagger: false,
};

export default ProjectFooterSection;
