import React from "react";
import { bool, number } from "prop-types";

import theme from "theme";

export function ReplyIcon({ hover, dimension, ...props }) {
  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox={`0 0 ${dimension} ${dimension}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.718 16.859c.807 4.577-.203 9.772-2.683 13.3-2.48 3.528-7.056 6.142-11.647 6.952-4.592.81-9.616-.321-13.153-2.79-3.538-2.47-5.455-6.935-6.262-11.512-.807-4.577-.003-9.055 2.479-12.586 2.481-3.53 6.336-6.351 10.93-7.161 4.593-.81 8.68 1.236 12.22 3.705 3.539 2.47 7.31 5.517 8.116 10.092z"
        fill={hover ? theme.secondary : theme.neonPink}
      />
      <path
        d="M27.212 18.675c-2.257-2.51-5.385-4.041-9.643-4.277-1.443-1.607-.15-2.061-.074-4.22.007-.193-.184.031-.354-.042-.18-.083-.464-.737-.613-.608-4.47 4.169-4.239 4.417-8.709 8.585-.198.176-.142.505.034.694 4.168 4.47 3.85 4.766 8.019 9.236.13.14.495-.434.68-.505.186-.07.381.11.388-.082.075-2.16-1.817-3.571.19-4.357 9.642.481 12.396 4.998 12.423 5.047.081.147.22.296.383.302.039.001-.02-.52.019-.528.203-.05.175.217.192.007.017-.22.59-5.342-2.935-9.252z"
        fill={hover ? theme.neonPink : theme.secondary}
      />
    </svg>
  );
}

ReplyIcon.propTypes = {
  /** Determine if the icon is being hovered. */
  hover: bool,

  /** Sets the height/width of the icon. */
  dimension: number,
};

ReplyIcon.defaultProps = {
  hover: false,
  dimension: 45,
};

export default ReplyIcon;
