import { shape } from "prop-types";
import { useToasts } from "react-toast-notifications";

import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";
import { useDeleteUploadScheduleSectionMutation } from "services/projectCity";
import { UploadScheduleSection } from "features/schedules/types";

function ConfirmDeleteUploadScheduleSectionModal({
  uploadScheduleSection,
  ...props
}) {
  /** Allow the user to confirm deletion of an upload schedule. */

  const [
    deleteUploadScheduleSection,
    result,
  ] = useDeleteUploadScheduleSectionMutation();
  const { addToast } = useToasts();

  async function handleDelete() {
    addToast("Upload Schedule Section deleted.", { appearance: "success" });
    deleteUploadScheduleSection({
      uploadScheduleSectionId: uploadScheduleSection.id,
    });
    props.onHide();
  }

  return (
    <ConfirmActionModal
      title="Delete upload schedule section?"
      confirmAction={handleDelete}
      isLoading={result.isLoading}
      isDanger
      {...props}
    >
      <ModalSummaryText>
        Are you sure you want to delete the upload schedule section? The action
        is irreversible.
      </ModalSummaryText>
    </ConfirmActionModal>
  );
}

ConfirmDeleteUploadScheduleSectionModal.propTypes = {
  /** The content upload object that is being deleted. */
  uploadScheduleSection: shape(UploadScheduleSection).isRequired,
};

export default ConfirmDeleteUploadScheduleSectionModal;
