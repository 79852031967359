import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FETCH_PROJECTS,
  FETCH_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  FETCH_PROJECT_STUDENTS,
  FETCH_PROJECT_ACCOUNTS,
  CREATE_TEACHER,
  SEARCH_PROJECTS,
  DELETE_PROJECT,
  CREATE_PROJECT_SECTION,
  UPDATE_PROJECT_SECTION,
  DELETE_PROJECT_SECTION,
} from "./constants";
import ProjectService from "./services/project";
import SectionService from "./services/section";

const projectService = new ProjectService();
const projectSectionService = new SectionService();

export const fetchProjects = createAsyncThunk(
  FETCH_PROJECTS,
  async (actionPayload, { rejectWithValue }) => {
    // Need to build the url parameters based on the action payload.
    try {
      if (actionPayload?.params?.isPurchased) {
        const response = await projectService.getPurchasedClasses();
        return response.data;
      } else if (actionPayload.fresh) {
        const response = await projectService.list(actionPayload.params);
        return response.data;
      } else {
        const response = await projectService.getNextUrl(actionPayload.nextUrl);
        return response.data;
      }
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const fetchProject = createAsyncThunk(
  FETCH_PROJECT,
  async (projectId) => {
    const response = await projectService.fetch(projectId);
    return response.data;
  }
);

export const createProject = createAsyncThunk(
  CREATE_PROJECT,
  async (actionPayload) => {
    const response = await projectService.create(actionPayload);
    return response.data;
  }
);

export const updateProject = createAsyncThunk(
  UPDATE_PROJECT,
  async ({ projectId, payload }) => {
    // Need to clean the teachers data to only send the ids.
    if (payload.teachers !== undefined)
      payload.teachers = payload.teachers.map((teacher) => teacher.id);

    const response = await projectService.update(projectId, payload);
    return response.data;
  }
);

export const updateProjectImage = createAsyncThunk(
  UPDATE_PROJECT,
  async ({ projectId, formData }) => {
    const response = await projectService.update(projectId, formData);
    return response.data;
  }
);

export const fetchProjectEnrollments = createAsyncThunk(
  FETCH_PROJECT_STUDENTS,
  async (projectId) => {
    const response = await projectService.listEnrollments(projectId);
    return response.data;
  }
);

export const fetchProjectAccounts = createAsyncThunk(
  FETCH_PROJECT_ACCOUNTS,
  async ({ projectId }) => {
    const response = await projectService.listAccounts(projectId);
    return response.data;
  }
);

export const createTeacher = createAsyncThunk(
  CREATE_TEACHER,
  async ({ projectId, payload }, { rejectWithValue }) => {
    try {
      const response = await projectService.createTeacher(projectId, payload);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const searchProjects = createAsyncThunk(
  SEARCH_PROJECTS,
  async ({ payload }) => {
    // Expects the search term in payload `{search: "term"}`.
    const response = await projectService.list(payload);
    return response.data;
  }
);

export const deleteProject = createAsyncThunk(
  DELETE_PROJECT,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await projectService.delete(projectId);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const createProjectSection = createAsyncThunk(
  CREATE_PROJECT_SECTION,
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await projectSectionService.create(payload);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const updateProjectSection = createAsyncThunk(
  UPDATE_PROJECT_SECTION,
  async ({ payload }, { rejectWithValue }) => {
    try {
      const { sectionId } = payload;
      const response = await projectSectionService.update(sectionId, payload);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const updateProjectSectionImage = createAsyncThunk(
  UPDATE_PROJECT_SECTION,
  async ({ sectionId, formData }) => {
    const response = await projectSectionService.update(sectionId, formData);
    return response.data;
  }
);

export const deleteProjectSection = createAsyncThunk(
  DELETE_PROJECT_SECTION,
  async ({ sectionId }, { rejectWithValue }) => {
    try {
      const response = await projectSectionService.delete(sectionId);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);
