import { createSlice } from "@reduxjs/toolkit";
import ReactPixel from "react-facebook-pixel";
import * as Sentry from "@sentry/react";

import { axiosInstance } from "features/api";
import { autoRegister } from "features/tiers/thunks";
import { agreeTos } from "features/users/thunks";
import {
  loginUser,
  loginGoogleUser,
  fetchMe,
  updateMe,
  updateProfileImage,
  refreshToken,
  disableGoogleAccount,
  changeEmailConfirm,
  verifyEmail,
} from "./thunks";

const initialState = {
  token: "",
  user: {},
  isLoading: false,
  isAuthenticated: false,
};

const accountsSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    logoutUser: (state) => {
      // Remove the token and sentry affiliation.
      Sentry.configureScope((scope) => scope.setUser(null));
      delete axiosInstance.defaults.headers.common["Authorization"];
      return initialState;
    },
    projectDeleted: (state, deletedProjectID) => {
      // Remove deleted project
      state.user.projects = state.user.projects.filter(
        (project) => project.id !== deletedProjectID
      );
    },
    registerTour: (state, props) => {
      // Register that a user has completed a given tour.
      state.user.tours.push(props.payload.tourType);
    },
    updateUser: (state, { payload }) => {
      // Perform a general synchronous update of current user data.
      state.user = { ...state.user, ...payload };
    },
  },
  extraReducers: {
    [loginUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      const { accessToken, refreshToken, user } = action.payload;
      state.token = accessToken;
      state.refresh = refreshToken;
      state.user = user;
      state.isAuthenticated = true;
      state.isLoading = false;
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
    },
    [loginGoogleUser.fulfilled]: (state, action) => {
      const { accessToken, refreshToken, user } = action.payload;
      state.token = accessToken;
      state.refresh = refreshToken;
      state.user = user;
      state.isAuthenticated = true;
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
    },
    [loginUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    },

    [refreshToken.fulfilled]: (state, action) => {
      const { access } = action.payload;
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access}`;
      state.token = access;
    },

    [fetchMe.pending]: (state, action) => {
      state.isLoading = true;
    },

    [fetchMe.fulfilled]: (state, action) => {
      state.user = action.payload;

      // Set the user for better sentry reporting.
      const { id, email, username, firstName, lastName } = action.payload;
      Sentry.setUser({ id, email, username });

      // Initiate the facebook pixel tracking;
      const advancedMatching = {
        em: email,
        fn: firstName,
        ln: lastName,
      };

      const options = {
        autoConfig: true,
        debug: true,
      };

      ReactPixel.init(1836841293155804, advancedMatching, options);

      state.isLoading = false;
    },
    [updateMe.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [disableGoogleAccount.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [changeEmailConfirm.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [updateProfileImage.fulfilled]: (state, action) => {
      state.user = action.payload;
    },

    [autoRegister.fulfilled]: (state, action) => {
      // When user is auto-enrolled, then we can add the enrollment to their state.
      state.user.enrollments.push(action.payload);
    },

    [agreeTos.fulfilled]: (state, action) => {
      state.user.hasLatestAgreement = true;
    },

    [verifyEmail.fulfilled]: (state, action) => {
      // Set the user's state with authentication data after they verify email.
      const { user, accessToken } = action.payload;
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
      state.user = user;
      state.token = accessToken;
    },
  },
});

export const {
  logoutUser,
  projectDeleted,
  registerTour,
  updateUser,
} = accountsSlice.actions;

export default accountsSlice.reducer;
