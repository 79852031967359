export const contentUploadStatus = {
  pending: 1,
  uploading: 2,
  uploaded: 3,
  failed: 4,
};

export const contentUploadType = {
  lesson: 1,
  animatic: 2,
};

export const uploadScheduleType = {
  lesson: 1,
  show: 2,
};
