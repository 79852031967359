import React from "react";

import SprintManagementNavigation from "features/sprint/navbars/Management";
import SecondaryDashboardContainer from "components/containers/DashboardSecondary";
import ManagementWrapper from "components/containers/Management";

function SprintManagementBaseRoute(props) {
  /** The base route for all of sprint management sections. */
  return (
    <ManagementWrapper>
      <SprintManagementNavigation />
      <SecondaryDashboardContainer fluid>
        {props.children}
      </SecondaryDashboardContainer>
    </ManagementWrapper>
  );
}

export default SprintManagementBaseRoute;
