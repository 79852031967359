import React, { useEffect } from "react";
import { shape } from "prop-types";
import BucketBase from "features/buckets/components/Base";
import { useSelector, useDispatch } from "react-redux";
import { useModal } from "react-modal-hook";

import BucketUploadImageSetChoiceModal from "components/modals/BucketUploadImageSetChoice";
import BucketUploadContainer from "features/buckets/components/Uploads/Container";
import LoadingContainer from "components/loading/Container";
import useFeedShare from "hooks/FeedShare";
import { bucketUploadType } from "utils/enums";
import { resetUploadSession } from "features/imageUploadSession/slice";
import BucketHeaderSection from "features/buckets/components/BucketHeaderSection";
import { Bucket } from "types";

function UploadsBucket({ bucket }) {
  /** Display the list of uploads for the bucket. */

  const dispatch = useDispatch();
  const bucketUpload = useSelector((state) => {
    const currentBucket = state.buckets.entities.find(
      (_bucket) => _bucket.id === bucket.id
    );
    return currentBucket && currentBucket.stacks
      ? currentBucket.stacks[0]
      : null;
  });
  const uploadSession = useSelector((state) => state.imageUploadSession);

  const showConfirmShareModal = useFeedShare(
    "bucketupload",
    "Upload Successful!",
    bucketUpload ? bucketUpload.id : null
  );

  const [
    showBucketUploadFollowupModal,
    hideBucketUploadFollowupModal,
  ] = useModal(() => {
    dispatch(resetUploadSession());
    return (
      <BucketUploadImageSetChoiceModal
        onHide={hideBucketUploadFollowupModal}
        bucketUpload={bucketUpload}
        showConfirmShareModal={showConfirmShareModal}
      />
    );
  }, [dispatch, bucketUpload]);

  useEffect(() => {
    // Check if we should launch the image stack type modal.
    if (uploadSession.status === "complete") {
      bucketUpload.kind === bucketUploadType.imageStack
        ? showBucketUploadFollowupModal()
        : dispatch(resetUploadSession());
    }
  }, [uploadSession.status, dispatch]);

  if (bucket.stacks === undefined)
    return <LoadingContainer marginTop="150px" />;

  function getDisplayUploads() {
    /**
     * Return an array of uploads data that should be shown at a summary level in the
     * bucket.
     * Depending on the bucket upload kind, there is different logic of how twe should
     * show it. (i.e. image stacks, pdf, video, etc)
     */

    return bucket.stacks.filter((bucketUpload) => {
      /** Check each type to make sure it has the required files to be displayed. */

      return bucketUpload.kind === bucketUploadType.imageStack
        ? bucketUpload.coverImage !== null
        : bucketUpload.uploadFile !== null;
    });
  }

  return (
    <BucketBase fluidHeader dropzone>
      <BucketHeaderSection bucket={bucket} headerText="Post your work" />
      <BucketUploadContainer bucketUploads={getDisplayUploads()} />
    </BucketBase>
  );
}

UploadsBucket.propTypes = {
  /** The bucket object that the uploads belong to. */
  bucket: shape(Bucket).isRequired,
};

export default UploadsBucket;
