import styled from "styled-components";
import { arrayOf, bool, number, shape } from "prop-types";

import { ContentUpload } from "features/sprint/types";
import ContentUploadTile from "../ContentUploadTile";

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.vSpacingMd};

  ${(props) =>
    props.$hasSeries &&
    `
  `}

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 30px;
  }
`;

const StyledContentUploadTile = styled(ContentUploadTile)`
  ${(props) =>
    props.$isActive &&
    `
      padding: 20px;
      border-radius: 5px;
      background-color: rgba(248,25,88,0.5);
    `}
`;

function NextContentUploadList({ contentUploads, hasSeries, activeId }) {
  /** List of next content uploads to be auto played. */

  return (
    <Wrapper $hasSeries={hasSeries}>
      {contentUploads.map((contentUpload) => {
        let className = hasSeries ? "mb-4" : "";

        const isActive = hasSeries && contentUpload.id === activeId;

        return (
          <StyledContentUploadTile
            key={contentUpload.id}
            contentUpload={contentUpload}
            titleFooter={hasSeries}
            className={className}
            $isActive={isActive}
          />
        );
      })}
    </Wrapper>
  );
}

NextContentUploadList.propTypes = {
  /** Array of content uploads to render. */
  contentUploads: arrayOf(shape(ContentUpload)),

  /** Determine if the content uploads should be rendered as a series or general next. */
  hasSeries: bool,

  /** The id of the content upload that is currently being viewed. */
  activeId: number,
};

NextContentUploadList.defaultProps = {
  hasSeries: false,
  activeId: null,
};

export default NextContentUploadList;
