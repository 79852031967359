import { useSelector } from "react-redux";

import { isClassCreator } from "utils/permissions";

function useCanCreateClass() {
  /** Determine if the user can crate a class project. */
  const user = useSelector((state) => state.account.user);

  return isClassCreator(user);
}

export default useCanCreateClass;
