import { shape } from "prop-types";
import Image from "react-bootstrap/Image";

import Video from "components/videos/Video";
import { assignmentEntryType } from "features/assignments/enums";
import { AssignmentEntry } from "features/assignments/types";
import Caption from "components/text/Caption";
import ImageStackCarousel from "components/carousels/GeneralImageStack";

function AssignmentEntryModalContent({ assignmentEntry }) {
  /** The main modal display content for an assignment entry. */

  if (assignmentEntry.files.length === 0)
    return <Caption>There are no files associated with this entry :(</Caption>;

  const lastFile = assignmentEntry.files[assignmentEntry.files.length - 1].file;

  switch (assignmentEntry.type) {
    case assignmentEntryType.video:
      return <Video src={lastFile} />;
    case assignmentEntryType.audio:
      return (
        <audio controls className="w-100">
          <source src={lastFile} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      );
    case assignmentEntryType.imageStack:
      if (assignmentEntry.files.length > 1) {
        const images = assignmentEntry.files.map((file) => file.file);
        return <ImageStackCarousel images={images} />;
      } else return <Image fluid src={lastFile} />;
    default:
      return null;
  }
}

AssignmentEntryModalContent.propTypes = {
  /** The assignment entry instance that is being rendered. */
  assignmentEntry: shape(AssignmentEntry).isRequired,
};

export default AssignmentEntryModalContent;
