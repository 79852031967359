import React from "react";
import { arrayOf, func, number, shape } from "prop-types";
import styled from "styled-components";

import Button from "components/buttons/Button";
import Comment from "features/comments/components/Comment";
import NewCommentContainer from "features/comments/components/NewCommentContainer";
import Caption from "components/text/Caption";
import useAuthenticationModal from "hooks/AuthModal";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { ContentType, Comment as CommentType } from "types";

const MoreCommentsWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.gray2};
  padding: ${(props) => props.theme.postPadding};
`;

function CommentsPreview({
  comments,
  commentCount,
  handleExpandComments,
  contentObjectId,
  contentType,
  postId,
}) {
  /** Shows a preview section for comments, which is shown at a top-level Post component. */

  const showAuthModal = useAuthenticationModal();
  const isAuthenticated = useIsAuthenticated();

  function renderComments() {
    /** Render the comments that should be shown. */

    return (
      <>
        {comments &&
          comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}
        {commentCount > 1 ? (
          <MoreCommentsWrapper>
            <Button
              className="p-0 border-0"
              variant="clear-primary"
              onClick={isAuthenticated ? handleExpandComments : showAuthModal}
              $noTilt
            >
              <Caption bold color="neonPink" className="m-0 py-1">
                See all {commentCount} comments
              </Caption>
            </Button>
          </MoreCommentsWrapper>
        ) : (
          <div className="mb-1" />
        )}
      </>
    );
  }

  return (
    <>
      {renderComments()}
      <NewCommentContainer
        objectId={contentObjectId}
        contentTypeId={contentType.id}
        postId={postId}
        inModal={false}
      />
    </>
  );
}

CommentsPreview.propTypes = {
  /** The initial comments that are to be shown in the preview section. */
  comments: arrayOf(shape(CommentType)).isRequired,

  /** Action to trigger when expanding the comments. */
  handleExpandComments: func.isRequired,

  /** The content type object of the underlying comment object. */
  contentTypeObj: shape(ContentType),

  /** The identifier for the content object that we're adding comments to. */
  contentObjectId: number.isRequired,

  /** The post id that a new comment should be added to. */
  postId: number,
};

CommentsPreview.defaultProps = {
  postId: null,
};

export default CommentsPreview;
