import React, { Children } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.theme.vSpacingMd};
`;

function FormButtonContainer({ children, ...props }) {
  // Provides a container to show the buttons for a form.
  return (
    <Wrapper {...props}>
      {Children.count(children) === 1 ? <div /> : null}
      {children}
    </Wrapper>
  );
}

export default FormButtonContainer;
