import { axiosInstance } from "features/api";
import { newsletterUrl } from "./api";

class NewsletterService {
  /** Provide functionality around our newsletter endpoint(s). */

  register(payload) {
    /** Register an email for our newsletter. */
    return axiosInstance.post(newsletterUrl, payload);
  }
}

export default NewsletterService;
