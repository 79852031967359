import LoadingContainer from "components/loading/Container";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ClassProjectList from "features/projects/components/ClassList";
import { fetchProjects } from "features/projects/thunks";
import { projectType } from "utils/enums";
import Body from "components/text/Body";
import styled from "styled-components";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: ${(props) => props.theme.vSpacingLg};
    padding-right: ${(props) => props.theme.vSpacingLg};
  }
`;

function SprintClassRoute() {
  /** Highlight featured classes that have sprint lessons. */

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { sprintClasses } = useSelector((state) => state.projects.entities);

  async function fetchSprintClasses() {
    const response = await dispatch(
      fetchProjects({
        fresh: true,
        params: {
          isFeatured: true,
          type: projectType.sprintClass,
          ordering: "order",
        },
      })
    );

    setIsLoading(false);
  }

  useEffect(() => {
    fetchSprintClasses();
  }, []);

  if (isLoading) return <LoadingContainer text="Loading sprintClasses..." />;

  if (sprintClasses.length === 0) {
    return (
      <div className="text-center mt-3">
        <Body color="neonPink">
          There are no featured sprint classes at this time.
        </Body>
      </div>
    );
  }

  return (
    <Wrapper>
      <ClassProjectList projects={sprintClasses || []} />
    </Wrapper>
  );
}

export default SprintClassRoute;
