import React from "react";
import { string, number, shape, func } from "prop-types";
import styled from "styled-components";
import { Link } from "@reach/router";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import Thumbnail from "components/text/Thumbnail";
import AuthorContainer from "components/general/AuthorContainer";
import useIsProjectCityAdmin from "hooks/IsProjectCityAdmin";
import { getProjectUrl } from "utils/projects";
import { User } from "types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const PrimaryContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 33%;
`;

const SecondaryContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PostActionIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  color: ${(props) => props.theme.yellow};
  font-size: ${(props) => props.theme.fontSizeSm};

  &:hover {
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.neonPink}!important;

  &:hover {
    text-decoration: underline;
  }
`;

function PostHeader({ user, project, action, ...props }) {
  /**
   * Provide the header component for a post.
   *
   * This post header can show some metadata about the post (such as user and project)
   * and provide additional actions.
   */

  const currentUser = useSelector((state) => state.account.user);
  const isOwner = currentUser.id === user.id;
  const isAdmin = useIsProjectCityAdmin();

  return (
    <Wrapper {...props}>
      <PrimaryContainer>
        <AuthorContainer user={user} />
      </PrimaryContainer>
      <SecondaryContainer>
        {project !== null && (
          <StyledLink to={getProjectUrl(project)}>
            <Thumbnail inline bold color="neonPink" noMargin>
              {project.title}
            </Thumbnail>
          </StyledLink>
        )}
        {(isOwner || isAdmin) && action && (
          <PostActionIcon icon={faEllipsisV} onClick={action} />
        )}
      </SecondaryContainer>
    </Wrapper>
  );
}

const Project = {
  id: number.isRequired,
  title: string.isRequired,
};

PostHeader.propTypes = {
  /** The post object that the header is being rendered for.  */
  user: shape(User).isRequired,

  /** The project that the post belongs to and should link to.  */
  project: shape(Project),

  /** An action to take when hitting the ellipses button. */
  action: func,
};

PostHeader.defaultProps = {
  action: null,
  project: null,
};

export default PostHeader;
