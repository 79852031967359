import StatContainer from "components/general/StatContainer";
import UnorderedList from "components/lists/UnorderedList";
import LoadingContainer from "components/loading/Container";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import DonationTable from "features/donations/tables/DonationTable";
import { useSelector } from "react-redux";
import { useGetDonationListQuery } from "services/projectCity";
import styled from "styled-components";

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

function DonationManagementModal(props) {
  /** Show some general information about a user's sent donations (history, stats, etc) */

  const { user } = useSelector((state) => state.account);

  const {
    data: donationData,
    isLoading: donationsAreLoading,
  } = useGetDonationListQuery({
    from_user: user.id,
  });

  function renderContent() {
    if (donationsAreLoading)
      return <LoadingContainer text="Loading donations..." />;

    return (
      <div className="p-3">
        <DonationTable type="sent" donations={donationData.results} />
      </div>
    );
  }

  function renderStatContainers() {
    /** Render the containers that show the user's token stats. */

    const data = [
      {
        label: "Available Tokens",
        value: user.tokenCount,
      },
      {
        label: "Donated Tokens",
        value: user.donatedTokenCount,
      },
      {
        label: "Purchased Tokens",
        value: user.purchasedTokenCount,
      },
    ];

    const statContainerColorProps = {
      color: "yellow",
      backgroundColor: "neonPink",
    };

    return data.map((stat) => (
      <StatContainer
        top={stat.label}
        bottom={stat.value}
        {...statContainerColorProps}
      />
    ));
  }

  return (
    <BaseModal size="xl" {...props}>
      <ModalHeader
        closeButton
        closeModal={props.onHide}
        title="Token Balance"
      />

      <div className="mb-3">
        <StatsContainer>{renderStatContainers()}</StatsContainer>
        <ModalSummaryText>
          <UnorderedList className="my-4">
            <li>
              As a subscriber, at the beginning of each month you get 20 free
              tokens to donate to your favorite animatics
            </li>
            <li>
              If you have not donated your tokens by the end of each month, then
              we will donate them for you
            </li>
            <li>
              If you want to donate more than you have in your wallet, you can
              purchase additional tokens for $0.10 each at the time of donation
            </li>
          </UnorderedList>
        </ModalSummaryText>
      </div>
      <div>
        <SubHeaderText color="yellow" tilt>
          Donation History
        </SubHeaderText>
      </div>
      {renderContent()}
    </BaseModal>
  );
}

export default DonationManagementModal;
