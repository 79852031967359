import ApiService from "../service";

import { textOnlyMessageUrl } from "./api";
import { axiosInstance } from "../api";

class TextOnlyMessageService extends ApiService {
  listUrl = textOnlyMessageUrl;

  comments(textOnlyID) {
    // Fetches the comments for a given text only bucket item.
    const url = `${this._getDetailUrl(textOnlyID)}comments/`;
    return axiosInstance.get(url);
  }
}

export default TextOnlyMessageService;
