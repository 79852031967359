import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingContainer from "components/loading/Container";

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 25px;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

function StandardInfiniteScroller(props) {
  /** Styled infinite scroller with grid layout for multiple breakpoints. */

  return (
    <StyledInfiniteScroll
      loader={<LoadingContainer text="Loading more..." />}
      {...props}
    >
      {props.children}
    </StyledInfiniteScroll>
  );
}

export default StandardInfiniteScroller;
