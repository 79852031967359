import React from "react";
import { shape } from "prop-types";
import Nav from "react-bootstrap/Nav";
import { useModal } from "react-modal-hook";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { isMobile } from "react-device-detect";

import CreateBucketModal from "features/buckets/modals/Create";
import { setCurrentBucket } from "features/buckets/slice";
import AddBucketIcon from "images/+.svg";
import {
  getProjectBaseUrl,
  getProjectUrl,
  isProjectAdmin,
} from "utils/projects.js";
import { Project, ActAsContext } from "types";
import MobileBucketNavigation from "components/navbars/MobileBucketNavigation";
import MobileNavigationContainer from "components/navigation/MobileNavigationContainer";

const ChildrenWrapper = styled.div`
  pointer-events: auto;
`;

const StyledNav = styled(Nav)`
  transform: rotate(1deg);
  pointer-events: auto;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    display: none;
  }
`;

const AddBucketContainer = styled.div`
  position: absolute;
  height: 100%;
  background-color: ${(props) => props.theme.blue};
  right: 0px;
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    display: none;
  }
`;

const BucketLink = styled(Nav.Link)`
  color: ${(props) => props.theme.yellow};
  font-family: Basis Grotesque Mono;
  font-weight: bold;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.black};
  }

  ${(props) =>
    props.isActive &&
    `
      color: ${props.theme.black};
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 5px;
    `}
`;

function BucketNavigation({ children, project, actAsContext }) {
  /** Provide a navigation to go to the different pages of a project. */

  const { isPublic, registrationTier } = actAsContext;
  const user = useSelector((state) => state.account.user);
  const currentBucket = useSelector((state) => state.buckets.current);
  const dispatch = useDispatch();

  const getViewablePages = () => {
    /**
     * Return an array of the buckets that should be shown to the user.
     *
     * This could be very simple, however we also have a way for a project admin to act
     * as a public user or a given registration tier, which is why we need to have a
     * filter for it here.
     */

    if (!isPublic && !registrationTier) return project.buckets;
    else if (isPublic) return project.buckets.filter((page) => page.isPublic);
    else if (registrationTier)
      return project.buckets.filter((page) => {
        return (
          page.registrationTiers.includes(registrationTier) || page.isPublic
        );
      });
  };

  const [showCreateBucketModal, hideCreateBucketModal] = useModal(() => {
    /** Handle the page modal for creating a new page.  */

    return (
      <CreateBucketModal project={project} hideModal={hideCreateBucketModal} />
    );
  });

  function renderDesktopNavigation() {
    /** Render the project buckets that should be navigable in the project. */

    const info = (
      <Nav.Item>
        <BucketLink
          onClick={() => {
            dispatch(setCurrentBucket({}));
            navigate(`${getProjectUrl(project)}`);
          }}
          isActive={Object.keys(currentBucket).length === 0}
        >
          {project.infoBucketLabel}
        </BucketLink>
      </Nav.Item>
    );

    const buckets = getViewablePages().map((page) => (
      <Nav.Item key={page.id}>
        <BucketLink
          onClick={() => navigate(`${getProjectBaseUrl(project)}/${page.slug}`)}
          isActive={currentBucket.id === page.id}
        >
          {page.title}
        </BucketLink>
      </Nav.Item>
    ));

    return (
      <div className="d-none d-md-flex">
        {info}
        {buckets}
      </div>
    );
  }

  function renderNavigation() {
    // Determine which navigation bar to render.
    return isMobile ? (
      <MobileBucketNavigation />
    ) : getViewablePages().length < 6 ? (
      renderDesktopNavigation()
    ) : (
      <MobileBucketNavigation />
    );
  }

  // Non-admin users don't need to see navigation if there are no pages.
  if (getViewablePages().length === 0 && !isProjectAdmin(user, project))
    return null;

  return (
    <MobileNavigationContainer $hasPrepend={children !== null}>
      {children && (
        <ChildrenWrapper className="pr-3">{children}</ChildrenWrapper>
      )}
      <StyledNav className="pr-4 bg-info position-relative">
        {renderNavigation()}
        {isProjectAdmin(user, project) && (
          <AddBucketContainer onClick={showCreateBucketModal}>
            <img src={AddBucketIcon} />
          </AddBucketContainer>
        )}
      </StyledNav>
    </MobileNavigationContainer>
  );
}

BucketNavigation.propTypes = {
  /** The project that we're viewing the buckets of. */
  project: shape(Project).isRequired,

  /** Context for if the user is acting as another user. */
  actAsContext: shape(ActAsContext),
};

export default BucketNavigation;
