import React, { useState, useRef } from "react";
import styled from "styled-components";
import Image from "react-bootstrap/Image";
import { bool, shape } from "prop-types";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import ModifyButton from "components/buttons/Modify";
import ActionsContainer from "components/containers/Actions";
import LoadingContainer from "components/loading/Container";
import { deleteMediaItem, updateMediaItem } from "features/mediaItems/thunks";
import { ProjectMediaItem } from "types";

const StyledItemContainer = styled.div`
  position: relative;

  ${(props) =>
    props.push === "right" &&
    `
    margin-left: 8.33%!important;
  `}

  ${(props) =>
    props.push === "left" &&
    `
    margin-right: 8.33%!important;
  `}

  transform: ${(props) =>
    props.positiveRotate ? "rotate(1deg)" : "rotate(-2deg)"};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingLg} 0;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) =>
        props.shouldViewAdminItems
          ? props.theme.vSpacingLg
          : props.theme.vSpacingXs}
      0;
  }
`;

function ProjectMediaBlockItem({ item, isEven, shouldViewAdminItems }) {
  /** An individual media item component in a project's media block. */

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();
  const updateImageRef = useRef(null);

  function handleMediaItemDelete() {
    /** Remove the media item from the container. */
    dispatch(deleteMediaItem(item.id));
    addToast("Project media item removed.", { appearance: "success" });
  }

  async function handleMediaItemUpdate(event) {
    /** Update the media item to a different image/video. */
    setIsLoading(true);
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    await dispatch(updateMediaItem({ mediaItemId: item.id, formData }));
    setIsLoading(false);
  }

  return isLoading ? (
    <LoadingContainer />
  ) : (
    <StyledItemContainer
      positiveRotate={isEven}
      push={isEven ? "right" : "left"}
      shouldViewAdminItems={shouldViewAdminItems}
    >
      <Image fluid src={item.image} />
      {shouldViewAdminItems && (
        <ActionsContainer bottom={-70}>
          <input
            id={`update-${item.id}-item`}
            style={{ display: "none" }}
            type="file"
            onChange={handleMediaItemUpdate}
            ref={updateImageRef}
          />
          <ModifyButton onClick={() => updateImageRef.current.click()} />
          <ModifyButton type="delete" onClick={handleMediaItemDelete} />
        </ActionsContainer>
      )}
    </StyledItemContainer>
  );
}

ProjectMediaBlockItem.propTypes = {
  /** The media item that is being rendered. */
  item: shape(ProjectMediaItem).isRequired,

  /** Provide different styling alternating between items. */
  isEven: bool.isRequired,

  /** Determine if the user should see the admin items for editing. */
  shouldViewAdminItems: bool.isRequired,
};

export default ProjectMediaBlockItem;
