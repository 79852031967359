import React from "react";
import styled from "styled-components";
import { string } from "prop-types";

import Header from "components/text/Header";
import BorderLong from "images/border_long.svg";
import { splitTitle } from "utils/general";

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.vSpacingXl};
  margin-bottom: ${(props) => props.theme.vSpacingMd};
`;

function SectionHeader({ text, props }) {
  /** Provide styled section header for our static pages (privacy, terms, etc) */

  const titleParts = splitTitle(text);

  return (
    <Wrapper>
      <Header size="sm" top={titleParts[0]} bottom={titleParts[1]} />
      <img src={BorderLong} alt="" />
    </Wrapper>
  );
}

SectionHeader.propTypes = {
  /** The text to be displayed in the section header. */
  text: string.isRequired,
};

export default SectionHeader;
