import React, { useState } from "react";
import { func, number, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import Quill from "components/controls/Quill";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormHelpText from "components/forms/shared/HelpText";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import {
  createProjectSection,
  updateProjectSection,
} from "features/projects/thunks";
import { ProjectSection } from "types";
import { getErrorMessage } from "utils/general";

function ProjectContentForm({ project, section, closeModal, ...props }) {
  /** Form to update a dynamic text section of a project. */

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm();

  async function onSubmit(payload) {
    const isUpdating = section !== null;
    const actionType = isUpdating ? updateProjectSection : createProjectSection;

    isUpdating
      ? (payload.sectionId = section.id)
      : (payload.project = project.id);

    const action = await dispatch(actionType({ payload }));

    if (action.type.includes("fulfilled")) {
      addToast(`Updated successfully`, {
        appearance: "success",
      });
      reset();
      closeModal();
    } else if (action.type.includes("rejected")) {
      const message = getErrorMessage(
        action.payload.data,
        "Sorry, an error has occurred."
      );
      setFormError(message);
    }

    return action;
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormHelpText>
        Edit or replace the text below to create a description for your class.
      </FormHelpText>
      <FormGroup errors={errors.header} label="Header">
        <Controller
          as={FormControl}
          rules={{
            required: true,
            maxLength: 128,
          }}
          name="header"
          defaultValue={section?.header}
          control={control}
          isInvalid={errors.header !== undefined}
        />
      </FormGroup>
      <FormGroup errors={errors.text} label="Content">
        <Controller
          render={({ onChange, onBlur, ref }) => (
            <Quill
              onBlur={onBlur}
              onChange={onChange}
              inputRef={ref}
              error={errors.text}
              defaultValue={section?.text}
            />
          )}
          rules={{
            required: true,
          }}
          name="text"
          defaultValue={section?.text}
          control={control}
          isInvalid={errors.text !== undefined}
        />
      </FormGroup>

      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Save</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ProjectContentForm.propTypes = {
  /** The project id whose section we're modifying. */
  projectId: number,

  /** The project section object that is being updated.  */
  section: shape(ProjectSection),

  /** Function to close the modal, if the form is in modal. */
  closeModal: func,
};

ProjectContentForm.defaultProps = {
  projectId: null,
  section: null,
  closeModal: () => {},
};

export default ProjectContentForm;
