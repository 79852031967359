import { ContentUpload } from "features/sprint/types";
import { arrayOf, bool, func, shape } from "prop-types";
import React from "react";
import styled from "styled-components";

import StandardInfiniteScroller from "components/scrollers/Standard";
import MyContentUploadTile from "features/sprint/components/MyContentUploadTile";

const StyledStandardInfiniteScroller = styled(StandardInfiniteScroller)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: ${(props) => props.theme.vSpacingLg};
  grid-row-gap: ${(props) => props.theme.vSpacingMd};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: 1fr;
  }
`;

function MyContentUploadList({ hasMore, loadMore, contentUploads }) {
  /** A list of sprint content to show to a user that has uploaded them. */

  return (
    <StyledStandardInfiniteScroller
      dataLength={contentUploads.length}
      next={loadMore}
      hasMore={hasMore}
    >
      {contentUploads.map((contentUpload) => (
        <MyContentUploadTile
          key={contentUpload.id}
          contentUpload={contentUpload}
        />
      ))}
    </StyledStandardInfiniteScroller>
  );
}

MyContentUploadList.propTypes = {
  /** The content uploads that should be rendered. */
  contentUploads: arrayOf(shape(ContentUpload)),

  /** Determine if there are more contest entries to load. */
  hasMore: bool.isRequired,

  /** Function to load the next set of contest entries. */
  loadMore: func.isRequired,
};

export default MyContentUploadList;
