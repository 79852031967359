import React from "react";
import { arrayOf, bool, func, shape } from "prop-types";
import styled from "styled-components";

import Caption from "components/text/Caption";
import StandardInfiniteScroller from "components/scrollers/Standard";
import { ContestEntry } from "features/contests/types";
import ContestEntryTile from "../ContestEntryTile";

const Wrapper = styled.div`
  margin: ${(props) => props.theme.vSpacingLg} 0;
`;

function ContestEntryList({ hasMore, loadMore, contestEntries }) {
  /** Container to render the entries for a contest. */

  if (!contestEntries.length) {
    return (
      <Caption color="neonPink" className="mt-5 text-center">
        There are no content uploads that match your filter.
      </Caption>
    );
  }

  return (
    <Wrapper>
      <StandardInfiniteScroller
        dataLength={contestEntries.length}
        next={loadMore}
        hasMore={hasMore}
      >
        {contestEntries.map((contestEntry, index) => (
          <ContestEntryTile
            key={contestEntry.id}
            contestEntry={contestEntry}
            isEven={index % 2 === 0}
          />
        ))}
      </StandardInfiniteScroller>
    </Wrapper>
  );
}

ContestEntryList.propTypes = {
  /** Array of contest entries to render. */
  contestEntries: arrayOf(shape(ContestEntry)),

  /** Determine if there are more contest entries to load. */
  hasMore: bool.isRequired,

  /** Function to load the next set of contest entries. */
  loadMore: func.isRequired,
};

export default ContestEntryList;
