import React, { useState } from "react";
import { func, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import ErrorText from "components/forms/shared/ErrorText";
import ModalBackButton from "components/modals/BackButton";
import {
  createTierDescription,
  updateTierDescription,
} from "features/tierDescriptions/thunks";
import { RegistrationTierDescription } from "features/tierDescriptions/types";

function TierDescriptionForm({
  description,
  registrationTierId,
  successAction,
  backAction,
  ...props
}) {
  /** Form for updating or creating a tier description. */

  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: description,
  });
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [formError, setFormError] = useState();

  const isUpdating = "id" in description;

  async function onSubmit(data) {
    // Send action to update or create the tier description.
    setFormError(null);

    // Registration tier is not directly selected in the form.
    data.registrationTier = description.registrationTier.id;

    const action = isUpdating
      ? await dispatch(
          updateTierDescription({
            descriptionId: description.id,
            payload: data,
          })
        )
      : await dispatch(createTierDescription(data));

    if (action.type === "UPDATE_TIER_DESCRIPTION/fulfilled") {
      addToast("Description updated successfully", { appearance: "success" });
      successAction();
    } else if (action.type === "CREATE_TIER_DESCRIPTION/fulfilled") {
      addToast("Description created successfully", { appearance: "success" });
      successAction();
    } else {
      let errorMessage = `Error ${
        isUpdating ? "updating" : "creating"
      } tier description.`;

      // Update the error message if we have something more descriptive.
      if (action.payload && action.payload.data) {
        const { detail, nonFieldErrors } = action.payload.data;
        if (detail) errorMessage = detail;
        if (nonFieldErrors) errorMessage = nonFieldErrors;
      }
      setFormError(errorMessage);
    }

    return action;
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Description" errors={errors.description}>
        <Controller
          as={FormControl}
          rules={{ required: true, maxLength: 128 }}
          name="description"
          control={control}
          defaultValue=""
          isInvalid={errors.description !== undefined}
        />
      </FormGroup>
      <ErrorText text={formError} />
      <FormSubmitContainer className="justify-content-between">
        {backAction && <ModalBackButton onClick={backAction} />}
        <FormPrimaryButton isLoading={isSubmitting} type="submit">
          {isUpdating ? "Save" : "Create"}
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

TierDescriptionForm.propTypes = {
  /** The tier description instance that we're updating. */
  description: shape(RegistrationTierDescription).isRequired,

  /** Action to take after the tier description is created/updated. */
  successAction: func.isRequired,

  /** Action to return to the previous section of the modal. */
  backAction: func.isRequired,
};

export default TierDescriptionForm;
