import React from "react";
import { object, string } from "prop-types";
import styled from "styled-components";

import Body from "components/text/Body";
import InfoTooltip from "components/tooltips/Info";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function ProjectSettingControl({
  children,
  label,
  tooltip,
  labelProps,
  ...props
}) {
  /** Control container for a project setting. */
  return (
    <Wrapper {...props}>
      {label && (
        <Body className="mb-1" {...labelProps}>
          {label}{" "}
          {tooltip && (
            <InfoTooltip
              iconProps={{ style: { float: "right" } }}
              text={tooltip}
            />
          )}
        </Body>
      )}
      {children}
    </Wrapper>
  );
}

ProjectSettingControl.propTypes = {
  /** The label to display with the setting control. */
  label: string,

  /** The tooltip to show next to the setting label. */
  tooltip: string,

  /** Extra props to pass into the text component. */
  labelProps: object,
};

ProjectSettingControl.defaultProps = {
  label: null,
  tooltip: null,
  labelProps: {},
};

export default ProjectSettingControl;
