import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";

import AuthorContainer from "components/general/AuthorContainer";
import { User } from "types";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing} ${(props) => props.theme.spacingLg};
`;

function BucketUploadHeader({ user }) {
  return (
    <Wrapper>
      <AuthorContainer user={user} />
    </Wrapper>
  );
}

BucketUploadHeader.propTypes = {
  /** The author user that is being rendered. */
  user: shape(User).isRequired,
};

export default BucketUploadHeader;
