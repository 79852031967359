import React from "react";
import styled from "styled-components";

import Header from "components/text/Header";
import CopyHeader from "components/text/CopyHeader";
import SharedContainer from "components/containers/Shared";
import DashboardContainer from "components/containers/Dashboard";
import ProjectTemplates from "features/projects/components/TemplateSection";

const ProjectWrapper = styled.div`
  margin-top: ${(props) => props.theme.vSpacingSm};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.smallPcBreakpoint}) {
    flex-direction: column;
  }
`;

function ProjectCreateRoute() {
  /** Route for the project creation process. */

  function renderSection() {
    /** Show a different section depending on where the user is in the process. */

    return (
      <DashboardContainer>
        <Header top="choose a" bottom="template" />
        <CopyHeader className="text-center mt-3">
          Pick an option to get started
        </CopyHeader>
        <ProjectWrapper>
          <ProjectTemplates />
        </ProjectWrapper>
      </DashboardContainer>
    );
  }

  return <SharedContainer className="my-5">{renderSection()}</SharedContainer>;
}

export default ProjectCreateRoute;
