import React from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";

const Quill = styled(ReactQuill)`
  color: ${(props) => props.theme.yellow};
  font-weight: 200;

  .ql-editor {
    font-family: "Basis Grotesque Mono";
    font-size: 22px;
    line-height: 32px;
  }

  .ql-container > .ql-editor.ql-blank::before {
    font-style: normal;
  }

  .ql-toolbar,
  .ql-container {
    ${(props) =>
      props.error
        ? "border: 4px solid " + props.theme.neonPink
        : "border: 4px solid " + props.theme.blue}
  }
`;

function CustomReactQuill({ error, ...props }) {
  /** Custom react quill component for our project. */

  return (
    <Quill {...props} error={error} className={error ? "is-invalid" : null} />
  );
}

CustomReactQuill.propTypes = {
  /** An error to display with the quill control. */
  error: PropTypes.object,
};

export default CustomReactQuill;
