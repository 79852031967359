import { useModal } from "react-modal-hook";

import Button from "components/buttons/Button";
import AssignmentModal from "features/assignments/modals/Assignment";

function AddAssignmentButton({ contentUploadId }) {
  /** Adds a new assignment with relation to a given content upload. */

  const [showAssignmentModal, hideAssignmentModal] = useModal(() => (
    <AssignmentModal
      onHide={hideAssignmentModal}
      assignment={{
        useContentUpload: true,
        contentUpload: contentUploadId,
      }}
    />
  ));

  return (
    <Button className="mt-3" onClick={showAssignmentModal}>
      Add new assignment
    </Button>
  );
}

export default AddAssignmentButton;
