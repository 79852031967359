export const registrationTierStatus = {
  pending: 1,
  open: 2,
  closed: 4,
  finished: 5,
};

export const meetScheduleDay = {
  sunday: 1,
  monday: 2,
  tuesday: 3,
  wednesday: 4,
  thursday: 5,
  friday: 6,
  saturday: 7,
};

export const bucketType = {
  images: 1,
  textOnly: 4,
  animatics: 5,
  lessons: 6,
};

export const bucketUploadType = {
  imageStack: 1,
  pdf: 2,
  video: 3,
  audio: 4,
  comic: 5,
  zip: 6,
};

export const stipeAccountType = {
  express: 1,
  standard: 2,
};

export const enrollmentStatus = {
  pending: 1,
  active: 2,
  refunded: 3,
  canceled: 4,
};

export const projectTemplate = {
  liveClass: 1,
  animationProject: 2,
};

export const projectType = {
  defaultProject: 1,
  fundingProject: 2,
  defaultClass: 3,
  sprintClass: 4,
};

export const appPermission = {
  projectCityAdmin: 1,
  classCreator: 2,
  premiumCreator: 3,
  freePremiumUser: 4,
  canReceiveDonation: 7,
};

export const dragTypes = {
  contentUpload: "content upload",
};
