import { number } from "prop-types";
import styled from "styled-components";

import Video from "images/icons/video.svg";
import Audio from "images/icons/audio.svg";
import Pdf from "images/icons/pdf.svg";

import { assignmentEntryType } from "features/assignments/enums";

const StyledImage = styled.img`
  width: 75px;
  height: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

function AssignmentEntryOverlay({ type }) {
  /** Render an overlay icon for the assignment entry. */

  function getSrc() {
    switch (type) {
      case assignmentEntryType.video:
        return Video;
      case assignmentEntryType.audio:
        return Audio;
      case assignmentEntryType.pdf:
        return Pdf;
      default:
        return null;
    }
  }

  return <StyledImage src={getSrc()} type={type} />;
}

AssignmentEntryOverlay.propTypes = {
  /** The type of the assignment entry. */
  type: number.isRequired,
};

export default AssignmentEntryOverlay;
