import React from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import { bool } from "prop-types";

import Caret from "images/icons/caret.svg";
import theme from "theme";

const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: theme.blue,
    border: `4px solid ${theme.blue}`,
    borderRadius: 0,
    color: theme.neonPink,
    fontFamily: "Basis Grotesque Mono",
    fontSize: "21px",
  }),
  singleValue: (styles) => ({ ...styles, color: theme.yellow }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.yellow,
    color: theme.black,
    marginTop: 0,
    borderRadius: 0,
  }),
  placeholder: (styles) => ({ ...styles, color: theme.yellow }),
  indicatorSeparator: () => ({ display: "none" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: theme.yellow,
      color: theme.black,
      textDecoration: isFocused && "underline",
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#FAFF29" : "#FAFF29"),
      },
    };
  },
  input: (styles) => ({ ...styles, color: theme.yellow }),
};

function DropdownIndicator() {
  return <img src={Caret} alt="" className="mr-1" />;
}

function Select({ isAsync, ...props }) {
  return isAsync ? (
    <AsyncSelect
      {...props}
      styles={colorStyles}
      components={{ DropdownIndicator }}
    >
      {props.children}
    </AsyncSelect>
  ) : (
    <ReactSelect
      {...props}
      styles={colorStyles}
      components={{ DropdownIndicator }}
    >
      {props.children}
    </ReactSelect>
  );
}

Select.propTypes = {
  /** Determine if the control should render isAsync select or normal. */
  isAsync: bool,
};

Select.defaultProps = {
  isAsync: false,
};

export default Select;
