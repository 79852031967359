import styled from "styled-components";
import { oneOf, string } from "prop-types";

import CopyHeader from "components/text/CopyHeader";
import { textColors } from "styles/constants";

const Wrapper = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme[props.$backgroundColor]};
  padding: ${(props) => props.theme.vSpacingSm};
  padding: ${(props) => props.theme.vSpacingXs};
`;

function StatContainer({ top, bottom, color, backgroundColor, ...props }) {
  /** Standard container to show some sort of stat */
  return (
    <Wrapper $backgroundColor={backgroundColor} $color={color} {...props}>
      <CopyHeader color={color}>{top}</CopyHeader>
      <CopyHeader color={color}>{bottom}</CopyHeader>
    </Wrapper>
  );
}

StatContainer.propTypes = {
  top: string.isRequired,
  bottom: string.isRequired,
  color: oneOf(textColors),
  backgroundColor: oneOf(textColors),
};

StatContainer.defaultProps = {
  color: "neonPink",
  backgroundColor: "orange",
};

export default StatContainer;
