import React from "react";
import { arrayOf, shape } from "prop-types";

import Reply from "../Reply";

function RepliesList({ replies }) {
  /** Display the replies for a comment. */
  return (
    <div>
      {replies.map((reply) => (
        <Reply reply={reply} key={reply.id} />
      ))}
    </div>
  );
}

RepliesList.propTypes = {
  /** Array of comment replies to display. */
  replies: arrayOf(shape(Reply)).isRequired,
};

export default RepliesList;
