import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";

import BaseForm from "components/forms/Base";
import FormControl from "components/forms/shared/Control";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormGroup from "components/forms/shared/FormGroup";
import FormHelpText from "components/forms/shared/HelpText";
import { setFieldErrors } from "utils/forms";
import { disableGoogleAccount } from "features/accounts/thunks";

function DisableGoogleAccount({ closeModal }) {
  /**
   * Provide a form for an authenticated user to disable login with their social account and enable
   * logging with a regular email instead.
   * */

  const {
    handleSubmit,
    control,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const action = await dispatch(disableGoogleAccount(data));
      if (action.type === "DISABLE_GOOGLE_ACCOUNT/fulfilled") {
        addToast("Switched to regular login!", { appearance: "success" });
        closeModal();
      } else {
        setFieldErrors(action, setError);
      }
    } catch (err) {
      addToast(err, { appearance: "error" });
    }
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <FormHelpText>
        By disabling your Google sign in and switching to a regular email login,
        you're disabling the connection between your account and your
        third-party email account. You won't be able to log in with your Google
        account anymore.
      </FormHelpText>
      <FormGroup label="Password" errors={errors.password}>
        <Controller
          as={FormControl}
          isInvalid={errors.password !== undefined}
          name="password"
          type="password"
          control={control}
          defaultValue=""
        />
      </FormGroup>
      <FormSubmitContainer>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

DisableGoogleAccount.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default DisableGoogleAccount;
