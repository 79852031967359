import { oneOfType, number, string } from "prop-types";

import { useGetContentTypeListQuery } from "services/projectCity";

function useGetContentTypeObj(search) {
  /** Search for a content type id or model and returns the full object. */
  const { data, isLoading } = useGetContentTypeListQuery();

  if (isLoading || data === undefined) return {};

  const type = typeof search;
  if (type === "number") return data.find((ct) => ct.id === search);
  else if (type === "string") return data.find((ct) => ct.model === search);

  return {};
}

useGetContentTypeObj.propTypes = {
  // Either the ct id (int) or the ct model (string) to search for.
  search: oneOfType([number, string]).isRequired,
};

export default useGetContentTypeObj;
