import React from "react";
import { shape } from "prop-types";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import LoadingContainer from "components/loading/Container";
import CommentContainer from "features/comments/components/Container";
import BucketUploadHeader from "features/buckets/components/Uploads/Header";
import useFetchBucketUploadImages from "hooks/FetchBucketUploadImages";
import useGetContentTypeObj from "hooks/GetContentTypeObj";
import { BucketUpload } from "types";

import CommentModal from "features/comments/modals/Comment";

const ImageContainer = styled.div`
  height: 80vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    height: 60vh;
  }
`;

function WebComicModal({ bucketUpload, ...props }) {
  /** Display an image set in the form of a web comic (vertical scroll) */

  const [isLoading, images] = useFetchBucketUploadImages(bucketUpload);
  const contentTypeObj = useGetContentTypeObj("bucketupload");

  function renderImages() {
    if (isLoading) return <LoadingContainer />;

    return images.map((image) => (
      <Image key={image.id} src={image.image} fluid alt="" />
    ));
  }

  return (
    <CommentModal {...props} size="md">
      <BucketUploadHeader user={bucketUpload.createdBy} />
      <ImageContainer>{renderImages()}</ImageContainer>
      <CommentContainer
        contentObject={bucketUpload}
        contentTypeObj={contentTypeObj}
      />
    </CommentModal>
  );
}

WebComicModal.propTypes = {
  /** The bucket upload object that we're displaying. */
  bucketUpload: shape(BucketUpload).isRequired,
};

export default WebComicModal;
