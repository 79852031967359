import React, { useState } from "react";
import { func, oneOf } from "prop-types";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import Body from "components/text/Body";
import EmailVerificationForm from "components/forms/Auth/EmailVerification";
import ForgotPasswordForm from "components/forms/Auth/ForgotPassword";
import LoginForm from "components/forms/Auth/Login";
import RegistrationForm from "components/forms/Auth/Registration";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import classes from "./authentication.module.css";
import ModalSummaryText from "components/text/ModalSummaryText";

const AuthOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  color: ${(props) => props.theme.black};

  > div {
    font-family: "Basis Grotesque";
    font-weight: 400;
    font-size: 18px;
    @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
      font-size: 21px;
    }
    padding: 8px;
  }

  a {
    font-family: "Basis Grotesque";
    font-weight: 200;
    text-decoration: underline;
    color: ${(props) => props.theme.black};
    font-size: 18px;
    @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
      font-size: 22px;
    }
  }
`;

const AuthenticationModal = (props) => {
  /** Modal to handle various different authentication and registration tasks. */

  const [authMode, setAuthMode] = useState(props.mode);
  const [hasRegistered, setHasRegistered] = useState(false);

  if (authMode === "login") {
    return (
      <BaseModal {...props} dialogClassName={classes.loginModal}>
        <ModalHeader title="log in" closeButton closeModal={props.onHide} />
        <Modal.Body className="pt-0">
          <LoginForm closeModal={props.onHide} />
          <AuthOptionsContainer>
            <Body color="dark" className="mb-1">
              No account?
              <a
                href="#"
                onClick={() => setAuthMode("register")}
                className="ml-1"
              >
                Join Us
              </a>
            </Body>
            <Body color="dark" className="mb-1">
              Forgot your password?
              <a
                href="#"
                onClick={() => setAuthMode("forgot")}
                className="ml-1"
              >
                Reset password
              </a>
            </Body>
            <Body color="dark">
              Didn't receive verification email?
              <a
                href="#"
                onClick={() => setAuthMode("resend-verification")}
                className="ml-1"
              >
                Resend Verification
              </a>
            </Body>
          </AuthOptionsContainer>
        </Modal.Body>
      </BaseModal>
    );
  } else if (authMode === "register") {
    return (
      <BaseModal {...props} dialogClassName={classes.registerModal}>
        <ModalHeader
          title={!hasRegistered ? "join us" : "verify email"}
          closeButton
          closeModal={props.onHide}
        />
        <Modal.Body>
          {hasRegistered ? (
            <ModalSummaryText>
              Please check your email inbox to verify your email address.
            </ModalSummaryText>
          ) : (
            <>
              <RegistrationForm
                closeModal={props.onHide}
                showVerification={() => setHasRegistered(true)}
              />
              <AuthOptionsContainer>
                <Body color="dark">
                  Already have an account?&nbsp;
                  <a href="#" onClick={() => setAuthMode("login")}>
                    Log in
                  </a>
                </Body>
              </AuthOptionsContainer>
            </>
          )}
        </Modal.Body>
      </BaseModal>
    );
  } else if (authMode === "forgot") {
    return (
      <BaseModal {...props}>
        <ModalHeader
          title="Forgot Password"
          closeButton
          closeModal={props.onHide}
        />
        <Modal.Body>
          <ForgotPasswordForm closeModal={props.onHide} />
          <AuthOptionsContainer>
            <Body color="dark">
              Back to&nbsp;
              <a href="#" onClick={() => setAuthMode("login")}>
                Log in
              </a>
            </Body>
          </AuthOptionsContainer>
        </Modal.Body>
      </BaseModal>
    );
  } else if (authMode === "resend-verification") {
    return (
      <BaseModal {...props}>
        <ModalHeader
          title="Resend Email Verification"
          closeButton
          closeModal={props.onHide}
        />
        <Modal.Body>
          <EmailVerificationForm closeModal={props.onHide} />
          <AuthOptionsContainer>
            <Body color="dark" className="mt-3">
              Back to{" "}
              <a href="#" onClick={() => setAuthMode("login")}>
                Login
              </a>
            </Body>
          </AuthOptionsContainer>
        </Modal.Body>
      </BaseModal>
    );
  }
};

AuthenticationModal.propTypes = {
  /** Function to hide the modal. */
  hide: func,

  /** Set the modal's content. */
  mode: oneOf(["login", "register", "forgot", "resend-verification"]),
};

AuthenticationModal.defaultProps = {
  mode: "login",
};

export default AuthenticationModal;
