const theme = {
  primary: "#2e30e5",
  secondary: "#faff29",
  success: "#a8d29b",
  dark: "#1e1010",

  black: "#1e1010",
  lime: "#e2eb76",
  green: "#a8d29b",
  pink: "#ff9fd4",
  neonPink: "#f81958",
  yellow: "#faff29",
  blue: "#2e30e5",
  purple: "#807be4",
  purple2: "#6765e4",
  red: "#f81958",
  orange: "#ffa87d",
  white: "#fff",
  brown: "#7c675d",

  gray1: "#676784",
  gray2: "#7c675d",
  gray3: "#b8ac97",
  gray100: "#fafafa",
  gray200: "#f2f2f2",
  gray300: "#ededed",

  borderRadius: "10px",

  fullHdBreakpoint: "1920px",
  smallPcBreakpoint: "1250px",
  tabletBreakpoint: "991px",
  smBreakpoint: "576px",

  transitionSuperFast: "0.1s",
  transitionFast: "0.3s",
  transitionMedium: "0.5s",
  transitionSlow: "0.7s",

  spacingSm: "3px",
  spacing: "5px",
  spacingLg: "8px",

  vSpacingXxs: "5px",
  vSpacingXs: "10px",
  vSpacingSm: "20px",
  vSpacingMd: "40px",
  vSpacingLg: "80px",
  vSpacingXl: "150px",

  shadowLight:
    "0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.05)",
  shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1)",

  fontSizeXs: "15px",
  fontSizeSm: "20px",
  fontSizeMd: "22px",
  fontSizeLg: "28px",
  fontSizeXl: "36px",

  navbarHeight: "72px",
  secondaryNavbarHeight: "56px",
  mobileNavbarHeight: "52px",
  bucketMarginTop: "80px",
  navbarMarginBottom: "220px",
  mobileNavbarMarginBottom: "115px",
  sideGutter: "60px",
  postPadding: "10px",
};

export default theme;
