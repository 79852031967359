import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { Alert } from "react-bootstrap";

import TierBucketsCard from "components/cards/TierBuckets";
import TierBucketCardBody from "components/cards/TierBuckets/Body";
import TierBucketCardTitle from "components/cards/TierBuckets/Title";
import RegistrationTierModal from "features/tiers/modals/RegistrationTier";
import TierBucketSettingButton from "components/buttons/TierBucketSetting";

import TierBucketsModal from "features/buckets/modals/TierBuckets";
import useEditRegistrationTier from "hooks/EditRegistrationTier";
import EditIcon from "images/icons/edit.svg";
import { RegistrationTier } from "types";

import BucketSlot from "../BucketSlot";
import Body from "components/text/Body";

const ImageWrapper = styled.div`
  cursor: pointer;

  img {
    width: 100%;
  }
`;

const EditIconContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

function TierSettingsCard({ registrationTier }) {
  // Displays the card to edit a registration tier and its buckets in the tier settings.
  const setSelectedBucket = useEditRegistrationTier();

  const [showTierModal, hideTierModal] = useModal(() => {
    return (
      <RegistrationTierModal
        onHide={hideTierModal}
        registrationTier={registrationTier}
      />
    );
  }, [registrationTier]);

  const [showTierBucketsModal, hideTierBucketsModal] = useModal(() => {
    return (
      <TierBucketsModal
        onHide={hideTierBucketsModal}
        registrationTier={registrationTier}
      />
    );
  }, [registrationTier]);

  function renderBuckets() {
    return registrationTier.buckets.map((bucket) => (
      <BucketSlot
        key={bucket.id}
        bucket={bucket}
        onClick={() => setSelectedBucket(bucket)}
      />
    ));
  }

  const priceDisplay = registrationTier.price
    ? `$${parseInt(registrationTier.price)}`
    : "Free";

  return (
    <TierBucketsCard>
      <EditIconContainer>
        <ImageWrapper onClick={showTierModal}>
          <img src={EditIcon} alt="" />
        </ImageWrapper>
      </EditIconContainer>
      <TierBucketCardTitle accentText={priceDisplay}>
        {registrationTier.title}
      </TierBucketCardTitle>
      <TierBucketCardBody className="border-top">
        {registrationTier.skipTransfers && (
          <Alert variant="success" className="text-center">
            100% of revenue goes to Project City
          </Alert>
        )}
        {renderBuckets()}
        <TierBucketSettingButton onClick={showTierBucketsModal} />
      </TierBucketCardBody>
    </TierBucketsCard>
  );
}

TierSettingsCard.propTypes = {
  /** The registration tier object that we're modifying buckets for. */
  registrationTier: shape(RegistrationTier).isRequired,
};

export default TierSettingsCard;
