import React from "react";
import { arrayOf, shape } from "prop-types";
import styled from "styled-components";

import { Contest } from "features/contests/types";
import ContestTile from "../Tile";

const Grid = styled.div`
  display: grid;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: repeat(3, 1fr);
    padding: ${(props) => props.theme.vSpacingXl};
    grid-gap: 100px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    padding: ${(props) => props.theme.vSpacingMd};
  }
`;

function ContestList({ contests }) {
  /** Display a list of contests that the user can navigate into. */
  return (
    <Grid>
      {contests.map((contest, index) => (
        <ContestTile
          isEven={index % 2 === 0}
          key={contest.id}
          contest={contest}
        />
      ))}
    </Grid>
  );
}

ContestList.propTypes = {
  /** The list of contests that should be rendered. */
  contests: arrayOf(shape(Contest)),
};

export default ContestList;
