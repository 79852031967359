import React from "react";
import { useSelector } from "react-redux";
import { Link } from "@reach/router";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { setCurrentBucket } from "features/buckets/slice";
import { getProjectBaseUrl, getProjectUrl } from "utils/projects";

const StyledNavDropdown = styled(NavDropdown)`
  > .dropdown-toggle {
    color: ${(props) => props.theme.secondary};
  }
`;

function MobileBucketNavigation() {
  /** Display the bucket navigation to be shown in the header for mobile clients. */

  const project = useSelector((state) => state.projects.detail);
  const currentBucket = useSelector((state) => state.buckets.current);
  const dispatch = useDispatch();

  // Only render bucket selector if we're in a project.
  if (!project || !project.buckets) return null;

  function getBucketTitle() {
    /** Return the title of the current bucket. */
    return currentBucket && Object.keys(currentBucket).length !== 0
      ? project.buckets.find((bucket) => bucket.id === currentBucket.id).title
      : project.infoBucketLabel;
  }

  function renderItems() {
    // Render all the bucket links that should go in the dropdown.
    const projectUrl = getProjectBaseUrl(project);
    return project.buckets.map((bucket) => {
      return (
        <NavDropdown.Item
          key={bucket.id}
          as={Link}
          to={`${projectUrl}/${bucket.slug}`}
        >
          {bucket.title}
        </NavDropdown.Item>
      );
    });
  }

  return (
    <StyledNavDropdown title={getBucketTitle()}>
      <NavDropdown.Item
        as={Link}
        to={getProjectUrl(project)}
        onClick={() => dispatch(setCurrentBucket({}))}
      >
        {project.infoBucketLabel}
      </NavDropdown.Item>
      {renderItems()}
    </StyledNavDropdown>
  );
}

export default MobileBucketNavigation;
