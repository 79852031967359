import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { ModalProvider } from "react-modal-hook";
import { ThemeProvider } from "styled-components";
import { navigate } from "@reach/router";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-datetime/css/react-datetime.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LoadingContainer from "components/loading/Container";
import CustomToast from "components/general/CustomToast";
import { fetchMe, verifyToken, refreshToken } from "features/accounts/thunks";
import { axiosInstance } from "features/api";
import { logoutUser } from "features/accounts/slice";
import Router from "routes/Base";
import GlobalFonts from "styles/fonts/fonts";
import { useGetContentTypeListQuery } from "services/projectCity";
import { useGetCategoryListQuery } from "services/projectCity";
import { googleClientId } from "utils/constants";
import theme from "./theme";

function App() {
  const [isReady, setIsReady] = useState(false);
  const token = useSelector((state) => state.account.token);
  const _refreshToken = useSelector((state) => state.account.refresh);
  const dispatch = useDispatch();

  useGetCategoryListQuery();
  useGetContentTypeListQuery();

  useEffect(() => {
    // Make sure our axios token is valid and loaded.
    async function verify() {
      const action = await dispatch(verifyToken({ token }));
      if (action.type === "VERIFY_TOKEN/rejected") {
        dispatch(logoutUser());
        navigate("/");
      } else {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        dispatch(fetchMe());

        if (_refreshToken && !isReady)
          dispatch(refreshToken({ token: _refreshToken }));
      }

      setIsReady(true);
    }

    if (token !== "" && !isReady) verify();
    else setIsReady(true);
  }, [token, dispatch, _refreshToken, isReady]);

  if (isReady)
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <GlobalFonts />
          <ToastProvider
            components={{ Toast: CustomToast }}
            placement="bottom-center"
            autoDismissTimeout={4000}
            autoDismiss
          >
            <GoogleOAuthProvider clientId={googleClientId}>
              <DndProvider backend={HTML5Backend}>
                <ModalProvider>
                  <Router />
                </ModalProvider>
              </DndProvider>
            </GoogleOAuthProvider>
          </ToastProvider>
        </ThemeProvider>
      </div>
    );
  else return <LoadingContainer />;
}

export default App;
