import React from "react";
import { arrayOf, shape, string } from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { useLocation } from "@reach/router";

import ClassManagementBackground from "images/backgrounds/class_management_nav.svg";
import BackIcon from "images/icons/back.svg";
import BucketNavigationButton from "components/buttons/BucketLink";
import ManagementMobileDropdown from "../ManagementMobileDropdown";

const Wrapper = styled.div`
  top: 0;
  z-index: 1030;
  height: 72px;
  background-image: url("${ClassManagementBackground}");
  background-size: cover;
  background-color: ${(props) => props.theme.purple};
  font-family: Basis Grotesque Mono;
`;

const StyledContainer = styled(Container)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 60px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 20px;
  }
`;

const ProjectTitle = styled.span`
  color: ${(props) => props.theme.yellow};
  padding-bottom: 5px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    font-size: 20px;
    line-height: 22px;
  }
`;

const ImageWrapper = styled.div`
  width: 30px;

  img {
    width: 100%;
  }
`;

function ManagementNavigation({ backUrl, title, links }) {
  /* Navigation bar to be shown in management screens. */
  const location = useLocation();

  function renderDesktopNavigationButtons() {
    return links.map((obj, index) => {
      const { label } = obj;
      const isActive = location.pathname === obj.to;
      return (
        <BucketNavigationButton
          className="m-2"
          active={isActive}
          key={index}
          style={{ fontWeight: "bold" }}
          {...obj}
        >
          {label}
        </BucketNavigationButton>
      );
    });
  }

  return (
    <Wrapper className="fixed-top">
      <StyledContainer fluid>
        <Row noGutters className="align-items-center">
          <Col>
            <div className="d-flex align-items-center">
              <BucketNavigationButton
                to={backUrl}
                className="ml-0 my-2 mr-2"
                noHover
              >
                <ImageWrapper>
                  <img src={BackIcon} />
                </ImageWrapper>
              </BucketNavigationButton>
              {window.innerWidth > 576 && <ProjectTitle>{title}</ProjectTitle>}
            </div>
          </Col>
          <Col className="text-right d-none d-md-block">
            {renderDesktopNavigationButtons()}
          </Col>
          <Col className="d-md-none">
            <ManagementMobileDropdown links={links} />
          </Col>
        </Row>
      </StyledContainer>
    </Wrapper>
  );
}

ManagementNavigation.propTypes = {
  /** Route to take the user back to. */
  backUrl: string.isRequired,

  /** Title to show in the navigation. */
  title: string.isRequired,

  /** Links that should be displayed in the navigation. */
  links: arrayOf(
    shape({
      to: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default ManagementNavigation;
