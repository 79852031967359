import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  total: 0,
  current: 0,
  oneFileOnly: false,
};

const imageUploadSessionSlice = createSlice({
  name: "imageUploadSession",
  initialState,
  reducers: {
    createUploadSession(state, action) {
      // Create a new upload session object for a stack.
      state.status = "running";
      state.total = action.payload.total;
    },
    createUploadSessionForOneFile(state, action) {
      // Create a new upload session object for just one file (video, pdf..etc).
      state.status = "running";
      state.oneFileOnly = true;
    },
    incrementUploadCount(state) {
      /* Let our store know that we've processed an additional upload, and handle if we've finished
         our uploads.
      */
      if (state.current + 1 === state.total) state.status = "complete";

      state.current += 1;
    },
    resetUploadSession() {
      return initialState;
    },
  },
});

export const {
  createUploadSession,
  incrementUploadCount,
  resetUploadSession,
  createUploadSessionForOneFile,
} = imageUploadSessionSlice.actions;

export default imageUploadSessionSlice.reducer;
