import React from "react";
import { shape } from "prop-types";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

import FormModal from "components/modals/Form";
import EnrollmentForm from "features/enrollments/forms/Enrollment";
import EmailForm from "components/forms/Email";
import Tooltip from "components/tooltips/Tooltip";
import BTNEdit from "images/buttons/edit.png";
import BTNMail from "images/buttons/mail.png";
import BTNRefund from "images/buttons/refund.png";
import EnrollmentActionsModal from "features/enrollments/modals/Actions";
import { enrollmentsUrl } from "features/enrollments/api";
import { Enrollment } from "features/enrollments/types";

const ImageWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const ActionButton = styled.div`
  display: flex;
  color: ${(props) => props.theme.yellow};
  cursor: pointer;
`;

function EnrollmentActions({ enrollment, ...props }) {
  /** Render the actions for a given enrollment. */

  const [showEnrollmentActionModal, hideEnrollmentActionModal] = useModal(
    () => {
      return (
        <EnrollmentActionsModal
          enrollment={enrollment}
          onHide={hideEnrollmentActionModal}
        />
      );
    }
  );

  const [showEnrollmentModal, hideEnrollmentModal] = useModal(() => {
    return (
      <FormModal onHide={hideEnrollmentModal} title="Update enrollment">
        <EnrollmentForm
          closeModal={hideEnrollmentModal}
          enrollment={enrollment}
        />
      </FormModal>
    );
  }, [enrollment]);

  const [showEmailModal, hideEmailModal] = useModal(() => {
    return (
      <FormModal title="Send email" onHide={hideEmailModal}>
        <EmailForm
          toDisabled
          apiUrl={`${enrollmentsUrl}${enrollment.id}/email/`}
          email={{ to: enrollment.student.email }}
          closeModal={hideEmailModal}
        />
      </FormModal>
    );
  });

  const actionData = [
    {
      action: showEmailModal,
      img: BTNMail,
      tooltip: "Send email",
    },
    {
      action: showEnrollmentActionModal,
      img: BTNRefund,
      tooltip: "Invoice / Refund",
    },
    {
      action: showEnrollmentModal,
      img: BTNEdit,
      tooltip: "Update enrollment",
    },
  ];

  return (
    <ActionButton {...props}>
      {actionData.map((data) => (
        <ImageWrapper onClick={data.action}>
          <Tooltip text={data.tooltip}>
            <img src={data.img} alt="" />
          </Tooltip>
        </ImageWrapper>
      ))}
    </ActionButton>
  );
}

EnrollmentActions.propTypes = {
  /** The enrollment object that we're basing the actions on. */
  enrollment: shape(Enrollment).isRequired,
};

export default EnrollmentActions;
