import React, { useState } from "react";
import { bool, func, oneOf, number, string } from "prop-types";
import styled from "styled-components";
import Image from "react-bootstrap/Image";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import ModifyButton from "components/buttons/Modify";
import LoadingContainer from "components/loading/Container";
import ActionsContainer from "components/containers/Actions";
import NewImageUploader from "components/images/NewImageUploader";
import { updateProjectImage } from "features/projects/thunks";
import DefaultImage from "images/default-img.jpg";
import { getRandomSmallTilt } from "utils/general";

const ImageWrapper = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin: 50px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin: 20px 0;
    ${(props) => props.push === "left" && "margin-left: 10%"};
    ${(props) => props.push === "right" && "margin-right: 10%"};
  }
`;

const StyledImage = styled(Image)`
  margin-left: auto;
  margin-right: auto;
  border-radius: ${(props) => props.theme.borderRadius};

  ${(props) => props.rotate && getRandomSmallTilt()}
`;

const StyledLabel = styled.label`
  height: 100%;
  display: block;
`;

function ProjectContentImage({
  imgSrc,
  label,
  shouldViewAdminItems,
  projectId,
  setIsLoading,
  ...props
}) {
  /** An uploadable image section for images that are directly related to a project. */

  const [imageLoading, setImageLoading] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  function setLoading(value) {
    /** We need to set the loading state locally and sometimes in parent. */
    setImageLoading(value);
    setIsLoading(value);
  }

  async function handleImageChange(event) {
    // Event handler for when we update the project image.
    setLoading(true);

    const file = event.target.files[0];
    let formData = new FormData();
    formData.append(label, file);

    const action = await dispatch(updateProjectImage({ projectId, formData }));
    setLoading(false);
    if (action.type === "UPDATE_PROJECT/rejected")
      addToast("Error uploading file, please verify that it's a valid image.", {
        appearance: "error",
      });
  }

  return shouldViewAdminItems ? (
    <>
      {imgSrc ? (
        imageLoading ? (
          <LoadingContainer />
        ) : (
          <ImageWrapper {...props}>
            <StyledImage fluid rounded src={imgSrc || DefaultImage} alt="" />
            <StyledLabel htmlFor={label}>
              <ActionsContainer bottom={-70}>
                <ModifyButton />
              </ActionsContainer>
            </StyledLabel>
            <input
              id={label}
              style={{ display: "none" }}
              type="file"
              onChange={handleImageChange}
            />
          </ImageWrapper>
        )
      ) : (
        <ImageWrapper {...props}>
          <NewImageUploader
            action={updateProjectImage}
            actionPayload={{ projectId }}
            imageFieldName={label}
            rotate={props.rotate}
            setIsLoading={setIsLoading}
          />
        </ImageWrapper>
      )}
    </>
  ) : (
    imgSrc && (
      <ImageWrapper {...props}>
        <StyledImage
          fluid
          src={imgSrc || DefaultImage}
          alt={label}
          className="d-md-block"
        />
      </ImageWrapper>
    )
  );
}

ProjectContentImage.propTypes = {
  /** The identifier of the project whose image is being rendered. */
  projectId: number.isRequired,

  /** Determine if the user should see the admin items for editing. */
  shouldViewAdminItems: bool.isRequired,

  /** On mobile we can push the image to the left or right of the screen. */
  push: oneOf(["left", "right"]),

  /** Determine if the container should have a rotate. */
  rotate: bool,

  /** The project info item image. */
  imgSrc: string,

  /** Field item name we use it when updating the item image. */
  label: string,

  /** Action to set the parent component's loading state. */
  setIsLoading: func,
};

ProjectContentImage.defaultProps = {
  push: null,
  rotate: true,
  imgSrc: null,
  label: null,
  setIsLoading: () => {},
};

export default ProjectContentImage;
