import { number, shape } from "prop-types";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import { ContentUpload } from "features/sprint/types";

function DonationCompleteModal({ contentUpload, tokens, ...props }) {
  /** Show the user a confirmation that their donation was accepted. */
  return (
    <BaseModal {...props}>
      <ModalHeader title="Thank you!" closeButton closeModal={props.onHide} />
      <ModalSummaryText>
        Your donation of {tokens} tokens has been received by @
        {contentUpload.createdBy.username}
        &nbsp;for their animatic "{contentUpload.title}".
      </ModalSummaryText>
    </BaseModal>
  );
}

DonationCompleteModal.propTypes = {
  contentUpload: shape(ContentUpload).isRequired,
  tokens: number.isRequired,
};

export default DonationCompleteModal;
