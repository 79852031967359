import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";

function FileUploadProgressBar(props) {
  /** Track the progress of a bucket upload status. */

  const progress = useSelector((state) => state.imageUploadSession);

  const { total, current, status } = progress;

  if (status === "idle") return null;

  return (
    <ProgressBar
      variant="info"
      animated
      now={current + 1}
      max={total + 1}
      {...props}
    />
  );
}

export default FileUploadProgressBar;
