import { arrayOf, shape } from "prop-types";
import styled from "styled-components";

import { ContentUpload } from "features/sprint/types";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import OrderableContentUpload from "../OrderableContentUpload";

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
`;

function OrderableContentUploadList({ contentUploads }) {
  /** List of content objects that can be reordered. */

  function renderContentUploads() {
    return contentUploads.map((contentUpload) => (
      <OrderableContentUpload contentUpload={contentUpload} />
    ));
  }

  return (
    <div>
      <SubHeaderText color="pink" className="text-center mb-5">
        Drag and drop to reorder
      </SubHeaderText>
      <ListWrapper>{renderContentUploads()}</ListWrapper>
    </div>
  );
}

OrderableContentUploadList.propTypes = {
  contentUploads: arrayOf(shape(ContentUpload)).isRequired,
};

export default OrderableContentUploadList;
