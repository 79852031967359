import { useEffect, useState } from "react";

import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import Select from "components/controls/Select";
import {
  useGetCategoryListQuery,
  useUpdateContentUploadMutation,
} from "services/projectCity";

function ChooseCategoryModal({ contentUploadId, ...props }) {
  /**
   * Prompt to choose a category for a content upload.
   *
   * When a user creates a lesson without a category we don't want to require they choose a
   * category, but giving them a little extra nudge to assign a category would be nice.
   */

  const [selectedCategories, setSelectedCategories] = useState([]);
  const { data: categories } = useGetCategoryListQuery();
  const [
    updateContentUpload,
    { isLoading, isSuccess },
  ] = useUpdateContentUploadMutation();

  function handleSave() {
    updateContentUpload({
      contentUploadId,
      formData: { categories: selectedCategories },
    });
  }

  useEffect(() => {
    if (isSuccess) props.onHide();
  }, [isSuccess]);

  return (
    <BaseModal {...props}>
      <ModalHeader title="Lesson created, but..." />
      <ModalSummaryText>
        Assigning categories to lessons helps students find the lessons they're
        looking for. Does your lesson fit into one of these categories?
      </ModalSummaryText>

      <div className="mt-3">
        <Select
          options={categories.map((c) => ({ label: c.title, value: c.id }))}
          isMulti
          closeMenuOnSelect={false}
          onChange={(objects) =>
            setSelectedCategories(objects.map((obj) => obj.value))
          }
          placeholder="Choose categories"
        />
      </div>

      <FormSubmitContainer>
        <FormSecondaryButton onClick={props.onHide}>
          Not now
        </FormSecondaryButton>
        <FormPrimaryButton onClick={handleSave} disabled={isLoading}>
          Save
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseModal>
  );
}

export default ChooseCategoryModal;
