import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Header from "components/text/Header";
import SubHeader from "components/text/SubHeader";
import { isProjectAdmin } from "utils/projects";
import BucketActionsSection from "../Actions";

const StyledCol = styled(Col)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingMd};
  }
`;

const StyledSubHeader = styled(SubHeader)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: 0 !important;
  }
`;

function BucketHeaderSection({ bucket, headerText }) {
  /** Each bucket have a header text along side with some actions like edit, delete. */

  const accountState = useSelector((state) => state.account);
  const project = useSelector((state) => state.projects.detail);
  const { user } = accountState;

  return (
    <Container fluid>
      <Row>
        <StyledCol md={{ span: 5, offset: 1 }}>
          <StyledSubHeader text={bucket.projectTitle} />
          <Header top="post your" bottom="work" />
        </StyledCol>
        <StyledCol md={6}>
          {isProjectAdmin(user, project) && (
            <BucketActionsSection bucket={bucket} />
          )}
        </StyledCol>
      </Row>
    </Container>
  );
}

BucketHeaderSection.propTypes = {
  bucket: PropTypes.object,
  // each bucket kind have a different text
  headerText: PropTypes.string,
};

export default BucketHeaderSection;
