export const CREATE_PROJECT = "CREATE_PROJECT";
export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECT = "FETCH_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const FETCH_PROJECT_STUDENTS = "FETCH_PROJECT_STUDENTS";
export const FETCH_PROJECT_REVENUE_SPLITS = "FETCH_PROJECT_REVENUE_SPLITS";
export const FETCH_PROJECT_ACCOUNTS = "FETCH_PROJECT_ACCOUNTS";
export const CREATE_TEACHER = "CREATE_TEACHER";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";

export const CREATE_PROJECT_SECTION = "CREATE_PROJECT_SECTION";
export const UPDATE_PROJECT_SECTION = "UPDATE_PROJECT_SECTION";
export const DELETE_PROJECT_SECTION = "DELETE_PROJECT_SECTION";
