import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Caption from "components/text/Caption";

const StyledFormText = styled(Caption)`
  margin-bottom: ${(props) => props.theme.spacing};
  text-align: ${(props) => (props.$isField ? "left" : "center")};
  color: ${(props) => props.theme.orange}!important;
`;

function FormErrorText({ text, isField, ...props }) {
  if (!text) return null;

  return (
    <StyledFormText $isField={isField} {...props}>
      {text}
    </StyledFormText>
  );
}

FormErrorText.propTypes = {
  /** The text that should be rendered for the error message. */
  text: PropTypes.string,

  /** Determine if this is for a field, or for the form in general. */
  isField: PropTypes.bool,
};

FormErrorText.defaultProps = {
  isField: false,
};

export default FormErrorText;
