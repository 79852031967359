import { shape } from "prop-types";
import styled from "styled-components";

import VideoMask from "images/masks/video.svg";
import VideoMaskImage from "components/images/VideoMask";
import Rainbow from "images/backgrounds/rainbow_full.png";
import { ContentUpload } from "features/sprint/types";
import { getContentUploadImage } from "features/sprint/utils";
import { useEffect, useRef } from "react";

const VideoPreview = styled.video`
  max-width: 100%;
  mask-image: url(${VideoMask});
  mask-size: 100%;
  mask-repeat: round;
  width: 100%;
`;

const ImageWrapper = styled.div`
  ${(props) =>
    props.$canReceiveDonation &&
    `
    max-width: 100%;
    mask-image: url(${VideoMask});
    mask-size: 100%;
    mask-repeat: round;
    width: 100%;
    background-image: url(${Rainbow});
  `}

  padding: 7px;
  padding-top: 10px;
`;

function ContentUploadImage({ contentUpload, ...props }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current)
      videoRef.current.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
  }, []);

  const image =
    contentUpload.file && !contentUpload.thumbnail ? (
      <VideoPreview
        ref={videoRef}
        preload="metadata"
        src={contentUpload.file?.url || contentUpload.file}
        {...props}
      />
    ) : (
      <VideoMaskImage
        fluid
        src={getContentUploadImage(contentUpload)}
        {...props}
      />
    );

  // Sometimes we have a special background.
  return (
    <ImageWrapper $canReceiveDonation={contentUpload.canReceiveDonation}>
      {image}
    </ImageWrapper>
  );
}

ContentUploadImage.propTypes = {
  /** The content upload object whose thumbnail is being rendered. */
  contentUpload: shape(ContentUpload).isRequired,
};

export default ContentUploadImage;
