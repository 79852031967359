import React from "react";
import { useModal } from "react-modal-hook";
import { navigate } from "@reach/router";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { User } from "types";
import Button from "components/buttons/Button";
import BorderContainer from "components/containers/Border";
import ProfileForm from "components/forms/Profile";
import FormModal from "components/modals/Form";
import Body from "components/text/Body";
import ChangePasswordForm from "components/forms/Auth/ChangePassword";
import ChangeEmailForm from "components/forms/Auth/ChangeEmail";
import EmailForm from "components/forms/Email";
import DisableGoogleAccount from "components/forms/Auth/DisableGoogleAccount";
import { supportEmailUrl } from "features/emails/api";
import { logoutUser } from "features/accounts/slice";
import { clearProjects } from "features/projects/slice";
import { clearBuckets } from "features/buckets/slice";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .btn {
    margin: ${(props) => props.theme.vSpacingXxs} 0;
    font-size: 20px;
    line-height: 22px;
  }

  button:first-of-type {
    margin-top: 0 !important;
  }

  button:last-of-type {
    margin-bottom: 0 !important;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    > .btn {
      font-size: 17px !important;
      line-height: 18px !important;
      margin: ${(props) => props.theme.vSpacingXs} 0;
    }
  }
`;

const Header = styled(Body)`
  margin-bottom: 15px !important;
`;

function ProfileSettingsSection({ user }) {
  /** Section for a user to take admin actions on their account. */

  const { user: fullUser } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [showProfileModal, hideProfileModal] = useModal(() => {
    return (
      <FormModal title="Edit profile" onHide={hideProfileModal}>
        <ProfileForm user={user} closeModal={hideProfileModal} />
      </FormModal>
    );
  }, [user]);

  const [showChangePasswordModal, hideChangePasswordModal] = useModal(() => {
    return (
      <FormModal title="Change password" onHide={hideChangePasswordModal}>
        <ChangePasswordForm closeModal={hideChangePasswordModal} />
      </FormModal>
    );
  });

  const [showDisableGoogleModal, hideDisableGoogleModal] = useModal(() => {
    return (
      <FormModal title="Switch to email login" onHide={hideDisableGoogleModal}>
        <DisableGoogleAccount closeModal={hideDisableGoogleModal} />
      </FormModal>
    );
  });

  const [showChangeEmailModal, hideChangeEmailModal] = useModal(() => {
    return (
      <FormModal title="Change email" onHide={hideChangeEmailModal}>
        <ChangeEmailForm closeModal={hideChangeEmailModal} />
      </FormModal>
    );
  });

  function dispatchUserLogout() {
    /** Unauthenticate the user and clear out any data to reset the entire application. */
    return addToast(
      "You're now logged out, see you soon!",
      {
        appearance: "success",
        autoDismiss: true,
      },
      () => {
        dispatch(logoutUser());
        dispatch(clearBuckets());
        dispatch(clearProjects());
        navigate("/");
      }
    );
  }

  const [showContactModal, hideContactModal] = useModal(() => {
    return (
      <FormModal title="support" onHide={hideContactModal}>
        <EmailForm
          closeModal={hideContactModal}
          email={{ to: "support@projectcity.com" }}
          apiUrl={supportEmailUrl}
          toDisabled
          toSupport
        />
      </FormModal>
    );
  });

  return (
    <BorderContainer paddingSize="sm">
      <Header>Settings:</Header>
      <ButtonContainer>
        <Button onClick={showProfileModal}>Edit Profile</Button>
        {user.hasSocialRegistration ? (
          <Button onClick={() => showDisableGoogleModal()}>
            Change to email login
          </Button>
        ) : (
          <>
            <Button onClick={() => showChangePasswordModal()}>
              Change Password
            </Button>
            <Button onClick={() => showChangeEmailModal()}>Change Email</Button>
          </>
        )}
        {fullUser.subscription !== null && (
          <Button onClick={() => navigate("/subscription")}>
            Subscription
          </Button>
        )}
        <Button onClick={showContactModal}>Support</Button>
        <Button onClick={() => dispatchUserLogout()}>Log Out</Button>
      </ButtonContainer>
    </BorderContainer>
  );
}

ProfileSettingsSection.propTypes = {
  /** The user whose settings we're showing. */
  user: User.isRequired,
};

export default ProfileSettingsSection;
