import { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";
import { loadStripe } from "@stripe/stripe-js";
import styled from "styled-components";
import { bool, string } from "prop-types";

import Button from "components/buttons/Button";
import AuthenticationModal from "features/accounts/modals/Authentication";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { useCreateSubscriptionCheckoutMutation } from "services/projectCity";
import { stripePublishableKey } from "utils/stripe";
import { useCanViewSprintSubscription } from "features/sprint/hooks";

const StyledButton = styled(Button)`
  font-size: 36px !important;
  line-height: 36px;
  height: 100px;
`;

function SubscribeButton({ buttonText, isAnnual, ...props }) {
  /** Button to allow a user to start their Project City subscription. */
  const { addToast } = useToasts();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const hasSubscription = useCanViewSprintSubscription();

  const [
    createSubscriptionCheckout,
    { data: checkoutData, status, isError },
  ] = useCreateSubscriptionCheckoutMutation();

  const [openAuthModal, hideAuthModal] = useModal(
    () => <AuthenticationModal onHide={hideAuthModal} />,
    [isAuthenticated]
  );

  async function redirectToStripeCheckout() {
    const stripeClient = await loadStripe(stripePublishableKey);
    await stripeClient.redirectToCheckout({
      sessionId: checkoutData.id,
    });
    setButtonDisabled(false);
  }

  useEffect(() => {
    if (status === "pending") setButtonDisabled(true);
    else if (status === "fulfilled") redirectToStripeCheckout();
  }, [status]);

  useEffect(() => {
    if (isError) {
      addToast("Error creating payment session.", { appearance: "error" });
      setButtonDisabled(false);
    }
  }, [isError]);

  function handleExistingSubscription() {
    addToast("You already have an existing subscription", {
      appearance: "warning",
    });
  }

  return (
    !hasSubscription && (
      <StyledButton
        disabled={buttonDisabled}
        variant="info"
        onClick={
          isAuthenticated
            ? hasSubscription
              ? handleExistingSubscription
              : () => createSubscriptionCheckout({ isAnnual })
            : openAuthModal
        }
        {...props}
      >
        {buttonDisabled ? "Loading ..." : buttonText}
      </StyledButton>
    )
  );
}

SubscribeButton.propTypes = {
  buttonText: string,
  isAnnual: bool,
};

SubscribeButton.defaultProps = {
  buttonText: "Subscribe",
  isAnnual: false,
};

export default SubscribeButton;
