import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ErrorText from "components/forms/shared/ErrorText";

import BaseForm from "components/forms/Base";
import Caption from "components/text/Caption";
import FormControl from "components/forms/shared/Control";
import FormGroup from "components/forms/shared/FormGroup";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import { changeEmail } from "features/accounts/thunks";
import { setFieldErrors } from "utils/forms";

function ChangeEmailForm({ closeModal }) {
  /** Provide a form for an authenticated user to change their emails. */

  const {
    handleSubmit,
    control,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm();
  const { addToast } = useToasts();
  const [formErrorText, setFormErrorText] = useState("");
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const action = await dispatch(changeEmail(data));
      if (action.type === "CHANGE_EMAIL/fulfilled") {
        addToast(action.payload, { appearance: "success" });
        closeModal();
      } else {
        setFieldErrors(action, setError);
      }
    } catch (err) {
      addToast(err, { appearance: "error" });
      for (let fieldKey in err.response.data) {
        setFormErrorText(err.response.data[fieldKey]);
      }
    }
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} className="py-0">
      <Caption className="mb-3">
        Are you sure you want to change your email? if so please enter new email
        and click Submit.
      </Caption>
      <FormGroup label="New Email" errors={errors.email}>
        <Controller
          as={FormControl}
          isInvalid={errors.email !== undefined}
          name="email"
          type="email"
          control={control}
          defaultValue=""
        />
      </FormGroup>
      <FormSubmitContainer>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
      <ErrorText text={formErrorText} />
    </BaseForm>
  );
}

ChangeEmailForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ChangeEmailForm;
