import React from "react";

import Caption from "components/text/Caption";

function ModalSubheader(props) {
  /** A secondary header for a modal to give some extra context to the user. */
  return <Caption className="mt-3 ml-4">{props.children}</Caption>;
}

export default ModalSubheader;
