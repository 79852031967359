import React from "react";
import PropTypes from "prop-types";

import TextOnlyForm from "components/forms/TextOnlyMessage";
import FormModal from "components/modals/Form";

const TextOnlyModal = ({ hide, ...props }) => {
  return (
    <FormModal onHide={hide} title="Add text only message" {...props}>
      <TextOnlyForm closeModal={props.onHide} />
    </FormModal>
  );
};

TextOnlyModal.propTypes = {
  hide: PropTypes.func,
};

export default TextOnlyModal;
