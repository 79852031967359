import {
  bool,
  func,
  string,
  number,
  arrayOf,
  shape,
  object,
  array,
  oneOf,
} from "prop-types";

import { textColors } from "styles/constants";

export const Project = {
  /** Identifier of the project. */
  id: number.isRequired,

  /** Display title fo the project. */
  title: string.isRequired,
};

export const ProjectMediaItem = {
  /** Identifier of the project. */
  id: number.isRequired,

  /** Identifier of the media block that the item belongs to. */
  block: number.isRequired,

  /** Url of the image that is uploaded (if media item is an image.) */
  image: string,

  /** Url of the video that is uploaded (if media item is a video.) */
  video: string,
};

export const ProjectSection = {
  /** Identifier of the project section. */
  id: number.isRequired,

  /** The project id that the section belongs to. */
  project: number,

  /** The content text to be shown. */
  text: string.isRequired,

  /** Header text to display at the top of the section content. */
  header: string,

  /** The image to be shown in the project section */
  image: string,
};

export const User = {
  /** Identifier for the user. */
  id: number.isRequired,

  /** The user's first name. */
  firstName: string.isRequired,

  /** The user's last name. */
  lastName: string.isRequired,

  /** The user's biography, providing extra info about the user. */
  bio: string,

  /** Amount of students that the teacher has in their classes. */
  studentCount: number,

  /** Array of projects that the user teaches. */
  teachesProjects: arrayOf(shape(Project)),

  /** Array of projects that the user is enrolled in. */
  registeredProjects: arrayOf(shape(Project)),
};

export const Comment = {
  /** Identifier for the comment. */
  id: number.isRequired,

  /** Text of the comment. */
  text: string.isRequired,

  /** User instance of the author of the comment. */
  author: shape(User).isRequired,

  /** The amount of replies that are made on the comment. */
  repliesCount: number.isRequired,
};

export const Reply = {
  /** Identifier for the reply. */
  id: number.isRequired,

  /** The comment id that the reply belongs to. */
  comment: number.isRequired,

  /** The user instance of the author who wrote the reply. */
  author: shape(User).isRequired,

  /** Date string of when the reply was created. */
  created: string.isRequired,

  /** The content of the reply. */
  text: string.isRequired,
};

export const TextOnlyMessage = {
  /** Identifier for the text only message. */
  id: number.isRequired,

  /** HTML text of the message. */
  text: string.isRequired,

  /** Date string when the text message was created. */
  created: string,
};

export const ActAsContext = {
  /** Shows if the user is acting as a public user. */
  isPublic: bool,

  /** Id of a specific registration tier that the user is acting as. */
  registrationTier: number,

  /** Sets the value for if the user is acting as a public user. */
  setPublic: func,

  /** Sets the value for if the user is acting as a specific registration tier. */
  setRegistrationTier: func,
};

export const Post = {
  /** Identifier for the post. */
  id: number.isRequired,

  /** The type of object that the post is made from (message, upload, project, etc). */
  contentType: number.isRequired,

  /** The object that the post is made from (message, upload, project, etc). */
  contentObject: object.isRequired,

  /** The user instance that created the post. */
  createdBy: shape(User).isRequired,

  /** Date string when the post was made. */
  created: string.isRequired,
};

export const BucketUpload = {
  /** Identifier for the bucket upload. */
  id: number.isRequired,

  /** The type of upload object. */
  kind: number.isRequired,

  /** The bucket identifier for the bucket that the bucket upload belongs to. */
  bucket: number.isRequired,

  /** Date string when the upload was created. */
  created: string.isRequired,

  /** User instance that created the upload. */
  createdBy: shape(User).isRequired,

  /** Used for single file uploads (video, pdf, etc) */
  uploadFile: string,

  /** A display image to use for the bucket upload. */
  coverImage: object,

  /** An image to use for any bucket upload type. */
  thumbnail: string,

  /** Determine if the uploader's name should be shown with the bucket upload. */
  isAnonymous: bool,
};

export const Bucket = {
  /** Identifier for the bucket. */
  id: number.isRequired,

  /** Determine if the bucket is viewable by all, or if it's restricted. */
  isPublic: bool.isRequired,

  /** Determine if normal users can create new objects in the bucket. */
  allowUserCreate: bool.isRequired,

  /** The type of bucket (uploads, text, etc). */
  kind: number.isRequired,

  /** Determine if only the admin can create new objects. */
  onlyAdminCreate: bool.isRequired,

  /** Order in which the bucket should appear in relation to other project buckets. */
  order: number.isRequired,

  /** Project identifier that the bucket belongs to. */
  project: number.isRequired,

  /** Title of the project that the bucket belongs to. */
  projectTitle: string.isRequired,

  /** Array of registration tier ids that the bucket is restricted to. */
  registrationTiers: arrayOf(number).isRequired,

  /** Navigation slug for the bucket. */
  slug: string.isRequired,

  /** Array of bucket upload data that are in the bucket. */
  stacks: arrayOf(shape(BucketUpload)),

  /** Display title for the bucket. */
  title: string.isRequired,
};

export const RegistrationTier = {
  /** Identifier for the registration tier. */
  id: number.isRequired,

  /** Project that the registration tier belongs to. */
  project: number.isRequired,

  /** Array of buckets that belong to the tier. */
  buckets: arrayOf(shape(Bucket)).isRequired,

  /** Descriptive title for the registration tier. */
  title: string.isRequired,

  /** Shows the validity of the tier to receive registration payments. */
  canReceivePayments: bool.isRequired,

  /** Determine if the registration tier has availability for new signup. */
  hasAvailability: bool,

  /** Determine if the registration tier should be visible to non-owners. */
  isVisible: bool,

  /** Maximum amount of students that can register. */
  maximumEnrollments: number,

  /** Price that a student will pay to register. */
  price: string,

  /** Datetime tht the registration period starts. */
  registrationStartDate: string,

  /** Datetime tht the registration period ends. */
  registrationEndDate: string,

  /** Some tiers can require other tiers for a student to be able to register. */
  prereqTiers: arrayOf(number),

  /** Text descriptions of the registration tier. */
  descriptions: array,
};

export const Notification = {
  /** The identifier for the notification. */
  id: number.isRequired,

  /** The action that the notification is based upon. */
  action: object.isRequired,
};

export const ContentType = {
  /** The identifier for the content type. */
  id: number.isRequired,

  /** A descriptive label for the model of the content type. */
  model: string.isRequired,
};

// Shared props for text.
export const TextTypes = {
  /** The size of the caption. */
  size: oneOf(["sm", "md", "lg"]),

  /** The text color for the caption. */
  color: oneOf(textColors),

  /** Convert the text to be inline instead of block. */
  inline: bool,

  /** Let the text carry some extra weight. */
  bold: bool,
};

export const RevenueSplit = {
  /** Unique identifier for the revenue split. */
  id: number.isRequired,

  /*** The registration tier object of which the split is associated with. */
  registrationTier: shape(RegistrationTier).isRequired,

  /** The percent integer amount that that the user will receive. */
  splitPercent: number.isRequired,

  /** The user object who is receiving the split. */
  user: shape(User).isRequired,
};

export const ModalActionProps = shape({
  navigateEdit: func.isRequired,
  navigateDeleteConfirm: func.isRequired,
  setSelectedRevenueSplit: func.isRequired,
});
