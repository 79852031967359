import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";

import SocialItem from "components/social/Item";
import { User } from "types";

const Wrapper = styled.div`
  display: flex;

  justify-content: flex-end;

  :first-child {
    margin-left: 0;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingSm};
  }
`;

function SocialContainer({ user }) {
  /** Return the social container for a given user. */

  const { twitter, linkedin, facebook, instagram } = user;

  if (!twitter && !linkedin && !facebook && !instagram) return null;

  function renderSocialIcons() {
    const socialData = [];
    const socialApps = [
      "facebook",
      "instagram",
      "twitter",
      "linkedin",
      "discord",
    ];

    socialApps.forEach((app) => {
      if (user[app] !== "") socialData.push({ type: app, handle: user[app] });
    });

    return socialData.map((data) => <SocialItem key={data.type} {...data} />);
  }

  return <Wrapper>{renderSocialIcons()}</Wrapper>;
}

SocialContainer.propTypes = {
  // The user for which we're displaying the social container.
  user: shape(User).isRequired,
};

export default SocialContainer;
