import React, { useEffect } from "react";
import { shape } from "prop-types";
import Image from "react-bootstrap/Image";
import { useModal } from "react-modal-hook";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Alert from "react-bootstrap/Alert";

import ContentUploadModal from "features/sprint/modals/ContentUploadModal";
import { contentUploadType } from "features/sprint/enums";
import BucketBase from "features/buckets/components/Base";
import ProjectContentUploadList from "features/sprint/components/ProjectContentUploadList";
import useIsAuthenticated from "hooks/IsAuthenticated";
import UploadIcon from "images/buttons/upload.png";
import { useCanViewSprintSubscription } from "features/sprint/hooks";
import { Bucket } from "types";
import { bucketType } from "utils/enums";
import { isProjectAdmin } from "utils/projects";
import BucketActions from "../Actions";
import ProjectCityButton from "components/buttons/Button";
import { navigate } from "@reach/router";
import { setContentUploads } from "features/sprint/slice";
import SubscriptionRequiredContainer from "components/containers/SubscriptionRequired";
import OrderableContentUploadList from "features/sprint/components/OrderableContentUploadList";

const StyledUploadIcon = styled(Image)`
  position: fixed;
  bottom: 100px;
  left: 50px;
  width: 150px;
  cursor: pointer;
`;

function ContentUploadBucket({ bucket }) {
  /**
   * Show a list of content uploads that are associated with the project page.
   */

  const { user } = useSelector((state) => state.account);
  const { isReordering } = useSelector((state) => state.contentUploads);
  const project = useSelector((state) => state.projects.detail);
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  // Only allow user to choose in sprint if they have subscription access.
  const hasSubscriptionAccess = useCanViewSprintSubscription();

  // For now we only have animatics or lessons for content upload types.
  const isAnimatic = bucket.kind === bucketType.animatics;

  const canUpload =
    isAuthenticated &&
    hasSubscriptionAccess &&
    !isReordering &&
    (!bucket.allowUserCreate ? isProjectAdmin(user, project) : true);

  const shouldShowSubscriptionBanner = !hasSubscriptionAccess;
  const showPaywall = !hasSubscriptionAccess && project.owner !== user.id;

  const [showAnimaticsModal, hideAnimaticsModal] = useModal(() => (
    <ContentUploadModal
      title={`Create ${isAnimatic ? "animatic" : "lesson"}`}
      contentUpload={{
        type: isAnimatic
          ? contentUploadType.animatic
          : contentUploadType.lesson,
        isShown: false,
        bucket: bucket.id,
      }}
      onHide={hideAnimaticsModal}
      chooseType={false}
      chooseInSprint={hasSubscriptionAccess}
    />
  ));

  useEffect(() => {
    // Set the content uploads that should be used as next videos.
    dispatch(setContentUploads(bucket.contentUploads));
  }, [bucket.contentUploads]);

  function renderListComponent() {
    // Return different lists depending on if we're reordering or not.
    return isReordering ? (
      <OrderableContentUploadList contentUploads={bucket.contentUploads} />
    ) : (
      <ProjectContentUploadList contentUploads={bucket.contentUploads} />
    );
  }

  return showPaywall ? (
    <BucketBase>
      <SubscriptionRequiredContainer
        body={`To view the videos in "${bucket.title}", it is required to have a Story Sprint subscription.`}
      />
      {renderListComponent()}
    </BucketBase>
  ) : (
    <BucketBase>
      {isAuthenticated && isProjectAdmin(user, project) && (
        <BucketActions bucket={bucket} pushRight />
      )}
      {shouldShowSubscriptionBanner && (
        <Alert
          variant="primary"
          className="d-flex justify-content-between align-items-center mt-3"
        >
          <div>
            To create new {isAnimatic ? "animatic" : "lesson"}s you will need to
            resubscribe to the Story Sprint
          </div>
          <div className="d-flex justify-content-end">
            <ProjectCityButton
              variant="outline-primary"
              onClick={() => navigate("/sprint/content")}
            >
              Learn more
            </ProjectCityButton>
          </div>
        </Alert>
      )}
      {renderListComponent()}

      {canUpload && (
        <StyledUploadIcon src={UploadIcon} onClick={showAnimaticsModal} />
      )}
    </BucketBase>
  );
}

ContentUploadBucket.propTypes = {
  bucket: shape(Bucket).isRequired,
};
export default ContentUploadBucket;
