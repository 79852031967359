import React from "react";
import { bool } from "prop-types";
import styled from "styled-components";

import { TextTypes } from "types";

const StyledText = styled.p`
  font-family: ${(props) =>
    props.mono ? "Basis Grotesque Mono" : "Basis Grotesque Pro"};
  font-size: 22px;
  line-height: 32px;
  display: ${(props) => (props.inline ? "inline" : "block")};
  color: ${(props) => props.theme[props.color]}!important;
  margin-bottom: 0;
  font-weight: ${(props) => (props.bold ? "bold" : "200")};

  // Sometimes there can be a p tag from rich text editor.
  > p {
    margin-bottom: 0;
  }

  ${(props) =>
    props.size === "sm" &&
    `
    font-size: 18px;
    line-height: 26px;
  `}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    font-size: 18px;
    line-height: 26px;
  }
`;

function Body(props) {
  return <StyledText {...props}>{props.children}</StyledText>;
}

Body.propTypes = {
  /** Set the font to be mono spaced. */
  mono: bool,
  ...TextTypes,
};

Body.defaultProps = {
  size: "md",
  color: "lime",
  mono: false,
  inline: false,
  bold: false,
};

export default Body;
