import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CREATE_TEXT_ONLY,
  UPDATE_TEXT_ONLY,
  DELETE_TEXT_ONLY,
} from "./constants";
import TextOnlyMessageService from "./service";

const service = new TextOnlyMessageService();

export const createTextOnlyMessage = createAsyncThunk(
  CREATE_TEXT_ONLY,
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await service.create(payload);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const updateTextOnlyMessage = createAsyncThunk(
  UPDATE_TEXT_ONLY,
  async ({ textOnlyId, payload }, { rejectWithValue }) => {
    try {
      const response = await service.update(textOnlyId, payload);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const deleteTextOnlyMessage = createAsyncThunk(
  DELETE_TEXT_ONLY,
  async ({ textOnlyId }, { rejectWithValue }) => {
    try {
      const response = await service.delete(textOnlyId);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);
