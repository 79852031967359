import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import styled from "styled-components";

import DashboardContainer from "components/containers/Dashboard";
import ProjectSearch from "components/controls/ProjectSearch";
import LoadingContainer from "components/loading/Container";
import ProjectTile from "features/projects/components/Tile";
import Body from "components/text/Body";
import Sticker from "components/icons/Sticker";

const NoResultsImgWrapper = styled.div`
  width: 42vh;
  position: fixed;
  bottom: 0px;
  right: 40%;
  transform: translateX(50%);

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    display: none;
  }

  img {
    width: 100%;
  }
`;

const SearchRow = styled(Row)`
  margin-bottom: 140px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingXl};
  }
`;

const ResultsRow = styled(Row)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    div:nth-of-type(5n + 7),
    div:nth-of-type(5) {
      margin-left: 200px;
    }

    div:nth-of-type(7) {
      margin-left: 0;
    }
  }
`;

const TileCol = styled(Col)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }
`;

const StyledSticker = styled(Sticker)`
  position: absolute;
  bottom: 250px;
  right: -50px;
`;

function ProjectSearchRoute() {
  /** Perform searches for projects. */

  const projectSearchState = useSelector((state) => state.projects.search);
  const [searched, setSearched] = useState(false);

  const { results, isLoading } = projectSearchState;

  const showSticker = results.length > 0 && (results.length - 4) % 7 !== 0;

  function renderProjects() {
    if (results.length > 0) {
      return results.map((project, index) => {
        // Push the tiles on mobile.
        const position = index % 2 === 0 ? "left" : "right";

        return (
          <TileCol xs={12} sm={4} md={3} key={project.id} className="mt-4">
            <ProjectTile
              project={project}
              push={position}
              isEven={index % 2 === 0}
            />
          </TileCol>
        );
      });
    } else if (searched) {
      return (
        <Col>
          <Body className="text-center" size="lg">
            That's weird. No results found.
          </Body>
          <NoResultsImgWrapper>
            <Sticker type="monster" />
          </NoResultsImgWrapper>
        </Col>
      );
    }
  }

  return (
    <DashboardContainer fluid>
      <SearchRow>
        <Col xs={12} sm={{ span: 8, offset: 2 }}>
          <ProjectSearch searched={searched} setSearched={setSearched} />
        </Col>
      </SearchRow>
      {results && results.length === 0 && isLoading ? (
        <LoadingContainer text="Finding projects..." />
      ) : (
        <ResultsRow>{renderProjects()}</ResultsRow>
      )}
      {showSticker > 0 && <StyledSticker type="strawberry" />}
    </DashboardContainer>
  );
}

export default ProjectSearchRoute;
