import React from "react";
import styled from "styled-components";
import Moment from "react-moment";

const StyledMoment = styled(Moment)`
  color: ${(props) => props.theme.gray2};
  display: block;
  width: max-content;
  padding-top: 4px;
`;

function CommentTimestamp(props) {
  return (
    <StyledMoment fromNow {...props}>
      {props.children}
    </StyledMoment>
  );
}

export default CommentTimestamp;
