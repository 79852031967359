import React, { useState } from "react";
import { func, shape } from "prop-types";
import styled from "styled-components";
import reactStringReplace from "react-string-replace";

import Loading from "components/loading/Loading";
import MentionsDropDown from "components/dropdowns/mentions";
import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import useAuthenticationModal from "hooks/AuthModal";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { useUpdateCommentMutation } from "services/projectCity";
import { Comment } from "types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacingLg};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ControlWrapper = styled.div`
  background-color: #604d46;
  margin-right: 10px;
  border-radius: ${(props) => props.theme.borderRadius};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    width: 70%;
  }
`;

const ActionsWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    width: 30%;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }
`;

function EditCommentContainer({ comment, setEdit, ...props }) {
  /** Provide a container to update an existing comment.  */

  const isAuthenticated = useIsAuthenticated();
  const showAuthModal = useAuthenticationModal();
  const [updateComment, { isLoading }] = useUpdateCommentMutation();

  const initText = renderText();
  const [newComment, setNewComment] = useState(initText);
  const [mentionWord, setMentionWord] = useState("");
  const [mentions, setMentions] = useState(comment.mentions);

  function renderText() {
    // Replace any mentions with a link to the user's profile, and proper representation.
    let renderedText = comment.text;
    comment.mentions.forEach((mention) => {
      renderedText = reactStringReplace(
        renderedText,
        `<@${mention.id}>`,
        (match, i) => `@${mention.username}`
      );
    });

    return renderedText;
  }

  async function editComment() {
    /** Edit the rendered comment text. */
    let text = newComment;
    mentions.forEach((mention) => {
      text = text.replace(`@${mention.username}`, `<@${mention.id}>`);
    });
    const payload = { text };
    await updateComment({ commentId: comment.id, ...payload });
    setEdit(false);
  }

  function handleChange(e) {
    setNewComment(e.target.value);
    const matches = [...e.target.value.matchAll(/@[^\s]*[^\s]$/g)];
    if (matches.length) {
      const word = matches[matches.length - 1][0].slice(1);
      setMentionWord(word);
    } else {
      setMentionWord("");
    }
  }

  function handleKeyDown(e) {
    // Submit the comment if the user hits enter.
    if (e.key === "Enter") isAuthenticated ? editComment() : showAuthModal();
  }

  return (
    <>
      <Wrapper {...props}>
        <ControlWrapper>
          <FormControl
            autoFocus
            variant="secondary"
            className="mr-2"
            placeholder="Update comment..."
            value={newComment}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </ControlWrapper>
        <ActionsWrapper>
          <FormSecondaryButton
            size="sm"
            className="mr-1"
            onClick={() => setEdit(false)}
          >
            Cancel
          </FormSecondaryButton>
          <FormPrimaryButton
            size="sm"
            onClick={isAuthenticated ? editComment : showAuthModal}
            disabled={newComment === ""}
          >
            {isLoading ? <Loading size="sm" /> : "Save"}
          </FormPrimaryButton>
        </ActionsWrapper>
      </Wrapper>
      {mentionWord && (
        <MentionsDropDown
          isShown
          username={mentionWord}
          setMentionWord={setMentionWord}
          setNewComment={setNewComment}
          newComment={newComment}
          setMentions={setMentions}
          mentions={mentions}
        />
      )}
    </>
  );
}

EditCommentContainer.propTypes = {
  /** The comment which we're updating. */
  comment: shape(Comment).isRequired,

  /** Action to hide the edit comment component.  */
  setEdit: func.isRequired,
};

export default EditCommentContainer;
