import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "@reach/router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPixel from "react-facebook-pixel";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";
import { parse as queryStringParse } from "query-string";

import ModifyButton from "components/buttons/Modify";
import ActionContainer from "components/containers/Actions";
import Sticker from "components/icons/Sticker";
import ProjectSingleFieldForm from "features/projects/forms/SingleField";
import FormModal from "components/modals/Form";
import ContainerDescriptionText from "components/text/ContainerDescription";
import Header from "components/text/Header";
import ActionButton from "components/buttons/Action";
import useIsAuthenticated from "hooks/IsAuthenticated";
import BucketHeader from "features/buckets/components/Header";
import RegistrationSection from "features/projects/components/registration/Section";
import ProjectSettingsContainer from "features/projects/components/SettingsContainer";
import ProjectContentImage from "features/projects/components/UploadableImage";
import ProjectMediaBlock from "features/projects/components/MediaBlockSection";
import { isProjectAdmin, isSprintClass } from "utils/projects";
import { getRandomSmallTilt, splitTitle } from "utils/general";
import { isClass } from "utils/projects";
import { projectType } from "utils/enums";

import ProjectDescriptionSection from "./DescriptionSection";
import ClassInfoHeader from "./ClassInfoHeader";
import ProjectSectionsContainer from "./ProjectSections";
import ProjectFooterSection from "./ProjectFooterSection";
// import ProjectComingSoon from "./ProjectComingSoon";

// import SubHeader from "components/text/SubHeader";
// import FundingProgress from "features/phases/components/Progress";
// import FundedProjectWaitlist from "features/projects/components/FundedProjectWaitlist";

const Wrapper = styled.div`
  padding: 0 5%;
`;

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20vh;
`;

const HeaderSection = styled(Row)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
  }
`;

const SubInfo = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-left: 10%;
  }
`;

const DescriptionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const HeroImageContainer = styled.div`
  margin: 35px -25px 0px -25px;
  position: relative;
  margin-top: 120px;
  margin-bottom: 180px;
  text-align: center;
  ${getRandomSmallTilt()}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: 50px;
  }
`;

const RegistrationSectionWrapper = styled.section`
  margin-top: ${(props) => props.theme.vSpacingLg};
`;

const FooterSection = styled.section`
  display: flex;
  position: relative;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    > div {
      padding: 40px;
      margin: 40px;
    }

    > div {
      flex: 0 0 40%;
    }
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    margin: ${(props) => props.theme.vSpacingMd} 0;
  }
`;

const StickerContainer = styled.section`
  position: absolute;
  top: -400px;
  left: -200px;
`;

// const FundingProgressContainer = styled.div`
//   margin: ${(props) => props.theme.vSpacingXl} 12%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     margin: ${(props) => props.theme.vSpacingLg} 0;
//   }
// `;

const WaitlistActionButton = styled(ActionButton)`
  float: right;

  margin-top: 10%;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: 90%;
  }
`;

function ProjectInfoSection() {
  /** Display the main info section for a project. */
  const project = useSelector((state) => state.projects.detail);
  const tiersRef = useRef(null);
  const [heroIsLoading, setHeroIsLoading] = useState(false);
  const projectIsClass = isClass(project);
  const projectIsSprintClass = isSprintClass(project);
  const isAuthenticated = useIsAuthenticated();
  const actAs = useSelector((state) => state.projects.actAs);

  const [showEditTitleModal, hideEditTitleModal] = useModal(() => {
    return (
      <FormModal
        title={projectIsClass ? "class name" : "project name"}
        onHide={hideEditTitleModal}
      >
        <ProjectSingleFieldForm
          project={project}
          fieldName="title"
          closeModal={hideEditTitleModal}
        />
      </FormModal>
    );
  }, [project]);

  const location = useLocation();
  const { addToast } = useToasts();
  const user = useSelector((state) => state.account.user);
  const queryStrings = queryStringParse(location.search, {
    parseBooleans: true,
  });

  // Determine if the user is currently in act as mode.
  const isActingAs = actAs.isPublic || actAs.registrationTier;

  // Determine if the user should be able to see the special admin edit items.
  const shouldViewAdminItems = isProjectAdmin(user, project) && !isActingAs;
  const isFundingProject = project.type === projectType.fundingProject;
  const isFundingComingSoon = isFundingProject && !project.fundingAvailable;

  useEffect(() => {
    function _trackPixelPurchase() {
      /** When a user has a successful payment, we track it with facebook pixel. */

      const purchasedTier = project.tiers.find((tier) => {
        return tier.id === parseInt(queryStrings.registrationTier);
      });

      if (purchasedTier)
        ReactPixel.track("Purchase", {
          currency: "USD",
          value: parseFloat(purchasedTier.price),
        });
    }

    function handleStripeRedirect() {
      /**
       * Handle when a user is redirected from stripe.
       *
       * Stripe sends users to a redirect url with either success or canceled, we need
       * to handle that accordingly and notify the user.
       */
      if (queryStrings.success === true) {
        addToast("Payment accepted", { appearance: "success" });
        _trackPixelPurchase(queryStrings);
      } else if (queryStrings.canceled === true)
        addToast("Payment canceled", { appearance: "warning" });
    }

    handleStripeRedirect();
  }, []);

  const titleParts = splitTitle(project.title);

  function handleTiersScroll() {
    /** Scroll the user to the tiers section of project info. */
    return tiersRef.current.scrollIntoView();
  }

  return (
    <Wrapper>
      <BucketHeader fluid project={project} />
      <StyledContainer fluid>
        <HeaderSection>
          <Col className="position-relative" sm={isFundingComingSoon ? 6 : 9}>
            <Header
              top={titleParts[0]}
              bottom={titleParts[1]}
              style={{ margin: 0 }}
              color={projectIsClass ? "green" : "orange"}
            />
            {shouldViewAdminItems && (
              <ActionContainer bottom={-40}>
                <ModifyButton onClick={showEditTitleModal} />
              </ActionContainer>
            )}
          </Col>

          {isFundingComingSoon && (
            <>
              {/* <Col sm={shouldViewAdminItems ? 3 : 4}>
                <ProjectComingSoon />
              </Col> */}
              {!shouldViewAdminItems && (
                <Col>
                  <WaitlistActionButton startTilt uppercase>
                    Join waitlist
                  </WaitlistActionButton>
                </Col>
              )}
            </>
          )}

          {isAuthenticated && isProjectAdmin(user, project) && (
            <Col sm={isFundingComingSoon ? 3 : isFundingProject ? 4 : 3}>
              <ProjectSettingsContainer
                actAsContext={actAs}
                project={project}
              />
            </Col>
          )}
        </HeaderSection>
        <SubInfo>
          <DescriptionSection>
            <ProjectDescriptionSection
              shouldViewAdminItems={shouldViewAdminItems}
              project={project}
              className="my-3"
            />
          </DescriptionSection>

          <ClassInfoHeader
            project={project}
            shouldViewAdminItems={shouldViewAdminItems}
            scrollToTierSection={handleTiersScroll}
          />
        </SubInfo>
        <HeroImageContainer>
          <ProjectContentImage
            label="hero_image"
            imgSrc={project.heroImage}
            shouldViewAdminItems={shouldViewAdminItems}
            projectId={project.id}
            rotate={false}
            setIsLoading={setHeroIsLoading}
          />
          {!project.heroImage && !heroIsLoading && shouldViewAdminItems && (
            <ContainerDescriptionText text="1200x500px" />
          )}
        </HeroImageContainer>
        <ProjectSectionsContainer
          project={project}
          shouldViewAdminItems={shouldViewAdminItems}
        />
        {projectIsClass && (
          <FooterSection>
            <StickerContainer>
              <Sticker type="pc" />
            </StickerContainer>
            {["footerSection1", "footerSection2"].map((field, index) => (
              <ProjectFooterSection
                key={project.id}
                project={project}
                field={field}
                shouldViewAdminItems={shouldViewAdminItems}
                stagger={index === 0}
              />
            ))}
          </FooterSection>
        )}
        {!isFundingProject && !projectIsSprintClass && (
          <RegistrationSectionWrapper ref={tiersRef}>
            <RegistrationSection
              project={project}
              shouldViewAdminItems={shouldViewAdminItems}
              actAsRegistrationTier={actAs.registrationTier}
            />
          </RegistrationSectionWrapper>
        )}
        <ProjectMediaBlock
          project={project}
          shouldViewAdminItems={shouldViewAdminItems}
        />
      </StyledContainer>
    </Wrapper>
  );
}

export default ProjectInfoSection;
