import { bool } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px dashed ${(props) => props.theme.neonPink};
  padding: ${(props) => props.theme.vSpacingSm};

  ${(props) =>
    props.$isSelected &&
    `
    background-color: ${props.theme.neonPink};
    border-radius: 10px;
  `}
`;

function ChoiceItem({ isSelected, ...props }) {
  /** Individual choice in a choice section. */
  return (
    <Wrapper $isSelected={isSelected} {...props}>
      {props.children}
    </Wrapper>
  );
}

ChoiceItem.propsTypes = {
  /** Highlight the box if it's selected. */
  isSelected: bool,
};

export default ChoiceItem;
