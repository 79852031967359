import React from "react";
import { bool, shape } from "prop-types";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { useDispatch } from "react-redux";

import ModifyButton from "components/buttons/Modify";
import ActionsContainer from "components/containers/Actions";
import FormModal from "components/modals/Form";
import ConfirmActionModal from "components/modals/ConfirmAction";
import Body from "components/text/Body";
import ModalSummaryText from "components/text/ModalSummaryText";
import SubHeader from "components/text/SubHeader";
import ProjectSectionForm from "features/projects/forms/ContentSection";
import BulletDash from "images/icons/bullet-point.svg";
import ProjectSectionImage from "../Image";
import { deleteProjectSection } from "features/projects/thunks";
import { ProjectSection as ProjectSectionType } from "types";

// Get random numbers for offsets.
const randomOffsetSm = Math.random() * 8 + 2;
const randomOffset = Math.random() * 15 + 5;

const Wrapper = styled.section`
  display: flex;
  margin: ${(props) => props.theme.vSpacingLg} 0;
  align-items: center;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: row;

    ${(props) =>
      props.staggered &&
      `
      margin-left: ${randomOffset}%;
    `}

    ${(props) =>
      props.staggeredEnd &&
      `
      margin-right: ${randomOffsetSm}%;
    `}

    > div {
      flex-basis: 50%;
    }
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
  }
`;

const ContentSection = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 50px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    max-width: 100%;
    margin: 25px 0;
    padding-bottom: 50px;
    flex-direction: column;
  }
`;

const ContentBody = styled(Body)`
  li {
    list-style-image: url(${BulletDash});
  }
`;

function ProjectSection({
  section,
  contentFirst,
  shouldViewAdminItems,
  ...props
}) {
  /** An individual dynamic project content section with text/image. */

  const dispatch = useDispatch();

  const [showEditSectionModal, hideEditSectionModal] = useModal(() => {
    return (
      <FormModal onHide={hideEditSectionModal}>
        <ProjectSectionForm
          section={section}
          closeModal={hideEditSectionModal}
        />
      </FormModal>
    );
  }, [section]);

  const [showRemoveSectionModal, hideRemoveSectionModal] = useModal(() => {
    function handleDelete() {
      dispatch(deleteProjectSection({ sectionId: section.id }));
      hideRemoveSectionModal();
    }

    return (
      <ConfirmActionModal
        title="Remove project section"
        onHide={hideRemoveSectionModal}
        confirmAction={handleDelete}
      >
        <ModalSummaryText>
          Are you sure you want to <b>permanently</b> remove this section?
        </ModalSummaryText>
      </ConfirmActionModal>
    );
  });

  function renderImageSection() {
    /** The image section is rendered dynamically to modify its placement. */

    return (
      <ProjectSectionImage
        section={section}
        shouldViewAdminItems={shouldViewAdminItems}
        positiveRotate={!props.staggered}
      />
    );
  }

  return (
    <Wrapper {...props}>
      {!contentFirst && renderImageSection()}
      <ContentSection>
        <SubHeader text={section.header} />
        <ContentBody dangerouslySetInnerHTML={{ __html: section.text }} />
        {shouldViewAdminItems && (
          <ActionsContainer bottom={-20}>
            <ModifyButton onClick={showEditSectionModal} />
            <ModifyButton type="delete" onClick={showRemoveSectionModal} />
          </ActionsContainer>
        )}
      </ContentSection>
      {contentFirst && renderImageSection()}
    </Wrapper>
  );
}

ProjectSection.propTypes = {
  /** The project section object that is being rendered. */
  section: shape(ProjectSectionType).isRequired,

  /** Determine if the user is an admin of the project. */
  shouldViewAdminItems: bool.isRequired,

  /** Determine if the content should go before or after the image. */
  contentFirst: bool,

  /** Stagger the section so it's not inline with other sections. */
  staggered: bool,

  /** Stagger the end of the section so the end of the element doesn't go full. */
  staggeredEnd: bool,
};

ProjectSection.defaultProps = {
  contentFirst: true,
  staggered: false,
  staggeredEnd: false,
};

export default ProjectSection;
