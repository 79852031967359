import React, { useState, useRef } from "react";
import { bool, func, object, string } from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import LoadingContainer from "components/loading/Container";
import Button from "components/buttons/Button";

const LabelContainer = styled.label`
  background: rgba(46, 48, 229, 0.2);
  border: 4px dashed #2e30e5;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  .disable {
    pointer-events: fill;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover {
    background: rgba(250, 255, 41, 0.2);
    cursor: pointer;
  }
`;

function ImageUploader({
  action,
  actionPayload,
  imageFieldName,
  setIsLoading,
}) {
  /** Provide an empty space to allow user upload images. */
  const buttonRef = useRef();
  const dispatch = useDispatch();
  const [imageLoading, setImageLoading] = useState(false);

  function setLoading(value) {
    /** We need to set the loading state locally and sometimes in parent. */
    setImageLoading(value);
    setIsLoading(value);
  }

  async function handleImageChange(event) {
    // Event handler for when we update the project image.
    setLoading(true);
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append(imageFieldName, file);
    await dispatch(action({ ...actionPayload, formData }));
    setLoading(false);
  }

  if (imageLoading) return <LoadingContainer />;

  return (
    <>
      <LabelContainer
        htmlFor="image-input"
        onMouseOver={() => {
          if (buttonRef && buttonRef.current) {
            buttonRef.current.focus();
          }
        }}
        onMouseOut={() => {
          if (buttonRef && buttonRef.current) {
            buttonRef.current.blur();
          }
        }}
      >
        <Button ref={buttonRef} className="disable">
          Upload File
        </Button>
      </LabelContainer>

      <input
        id="image-input"
        type="file"
        onChange={handleImageChange}
        className="d-none"
      />
    </>
  );
}

ImageUploader.propTypes = {
  /** The action to dispatch when picking a new image. */
  action: func.isRequired,

  /** The payload to send with the action. */
  actionPayload: object,

  /** The name of the image field that will be added. */
  imageFieldName: string,

  /** Determine if the control should have a rotate tilt. */
  rotate: bool,
};

ImageUploader.defaultProps = {
  imageSrc: null,
  actionPayload: {},
  rotate: true,
};

export default ImageUploader;
