import React, { useEffect, useState } from "react";
import { number, shape, string } from "prop-types";

import BaseModal from "components/modals/Base";
import LoadingContainer from "components/loading/Container";
import ModalSummaryText from "components/text/ModalSummaryText";
import ModalHeader from "components/modals/Header";
import AccountEarningsTable from "features/accounts/tables/ProjectEarnings";
import { Account } from "features/accounts/types";
import { AccountResourceService } from "features/accounts/service";

function AccountProjectEarningsModal({ project, user, ...props }) {
  /** Modal to show the earnings for an account on a given project */

  const [isLoading, setIsLoading] = useState(true);
  const [earningsData, setEarningsData] = useState(null);

  const name = `${user.firstName} ${user.lastName}`;
  const service = new AccountResourceService();

  async function fetchEarnings() {
    const response = await service.getEarnings(
      user.account.accountId,
      project.id
    );
    setEarningsData(response.data);
    setIsLoading(false);
  }

  useEffect(() => fetchEarnings(), []);

  return isLoading ? (
    <BaseModal {...props}>
      <LoadingContainer text={`Loading earnings for ${name}...`} />
    </BaseModal>
  ) : (
    <BaseModal {...props} size="lg">
      <ModalHeader
        title="Project Earnings"
        closeButton
        closeModal={props.onHide}
      />
      <ModalSummaryText className="m-2">
        The following earnings are for {name} on the "{project.title}" project.
      </ModalSummaryText>
      <AccountEarningsTable earnings={earningsData} />
    </BaseModal>
  );
}

AccountProjectEarningsModal.propTypes = {
  /** The project that is being used to link to. */
  project: shape({
    id: number.isRequired,
    title: string.isRequired,
  }).isRequired,

  /** The user that we're viewing the earnings of. */
  user: shape({
    account: shape(Account).isRequired,
    firstName: string.isRequired,
    lastName: string.isRequired,
  }).isRequired,
};

export default AccountProjectEarningsModal;
