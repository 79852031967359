import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

import BucketContent from "features/projects/components/DetailContent";
import UploadsBucket from "features/buckets/components/Uploads";
import FeedBucket from "features/buckets/components/Feed";
import TextOnlyBucket from "features/buckets/components/TextOnly";
import FileUploader from "features/buckets/components/Uploads/FileUploader";
import BucketNavigation from "components/navbars/Buckets";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { fetchBucket } from "features/buckets/thunks";
import { setCurrentBucket } from "features/buckets/slice";
import ContentUploadBucket from "features/buckets/components/ContentUploads";
import { bucketType } from "utils/enums";
import { isProjectAdmin, getProjectUrl } from "utils/projects.js";

function BucketRoute({ bucketSlug }) {
  /**
   * Route to display the content of a project bucket.
   *
   * The main goal of this component is to load the full data for the bucket and then pass that data
   * down into the proper bucket component, determined by its `kind` field. We also handle the
   * project info section, which isn't technically a bucket but it is presented to the user as such.
   */

  const dispatch = useDispatch();
  const bucketState = useSelector((state) => state.buckets);
  const [errorMessage, setErrorMessage] = useState(null);
  const accountState = useSelector((state) => state.account);
  const isAuthenticated = useIsAuthenticated();
  const actAs = useSelector((state) => state.projects.actAs);

  // Get the active bucket from the url string.
  const activeBucket = useSelector((state) => {
    return state.projects.detail.buckets.find(
      (bucket) => bucket.slug === bucketSlug
    );
  });

  // Need to get the project since the bucket slug is not unique by itself, and we need the id.
  const project = useSelector((state) => state.projects.detail);

  useEffect(() => {
    async function fetchData() {
      // Fetch the bucket data and handles errors.
      setErrorMessage(null);
      const bucket = project.buckets.find(
        (bucket) => bucket.slug === bucketSlug
      );
      if (bucket !== undefined) {
        try {
          // Get the bucket data and then perform any follow-up actions.
          const action = await dispatch(fetchBucket(bucket.id));
          if (action.type === "FETCH_BUCKET/rejected") {
            setErrorMessage("Error fetching the bucket data.");
          } else {
            // Set the current bucket so that we know where we are for other actions.
            dispatch(
              setCurrentBucket({
                id: action.payload.id,
                title: action.payload.title,
              })
            );
          }
        } catch (e) {
          setErrorMessage("Error fetching the bucket data.");
        }
      }
    }

    fetchData();
  }, [project, dispatch, bucketSlug]);

  function renderBucketByType() {
    /**
     * Return the bucket type component depending on the bucket's "kind" data.
     *
     * Also we'll be adding the bucket extra data before sending it to the Component.
     */

    if (bucketState.isLoading) {
      // Need to render the loading container inside of the `BucketRouteContainer`.
      return null;
    }

    let extraBucketData = [];
    if (activeBucket !== undefined)
      extraBucketData = bucketState.entities.find(
        (bucket) => bucket.id === activeBucket.id
      );

    const fullBucketData = {
      ...activeBucket,
      ...extraBucketData,
    };

    if (activeBucket === undefined) {
      // If the user goes to a bucket url that no longer exists, send them to info.
      navigate(getProjectUrl(project));
      return null;
    }

    if (activeBucket.kind === bucketType.images) {
      return <UploadsBucket bucket={fullBucketData} />;
    } else if (activeBucket.kind === bucketType.feed) {
      return <FeedBucket bucket={fullBucketData} />;
    } else if (activeBucket.kind === bucketType.textOnly) {
      return <TextOnlyBucket bucket={fullBucketData} />;
    } else if (activeBucket.kind === bucketType.animatics) {
      return <ContentUploadBucket bucket={fullBucketData} />;
    } else if (activeBucket.kind === bucketType.lessons) {
      return <ContentUploadBucket bucket={fullBucketData} />;
    } else return <div>Unrecognized bucket type "{activeBucket.kind}"</div>;
  }

  if (errorMessage)
    return (
      <div className="w-100 d-flex align-items-center justify-content-center">
        <p className="p-3 m-5 text-center bg-white w-50 text-danger">
          {errorMessage}
        </p>
      </div>
    );

  function renderUploadButton() {
    /** Determine whether we should render the upload button. */
    if (!isAuthenticated) return <div />;

    const component = <FileUploader bucket={activeBucket} />;

    // Some buckets only allow the admin, whereas others allow all users with access.
    return !activeBucket.allowUserCreate
      ? (isProjectAdmin(accountState.user, project) && component) || <div />
      : component;
  }

  function renderNavigationPrepend() {
    /** Certain buckets should have an additional component added to the navigation. */
    if (!activeBucket || activeBucket.kind !== bucketType.images) return null;

    return renderUploadButton();
  }

  return (
    <BucketContent className="bucket-route">
      <BucketNavigation project={project} actAsContext={actAs}>
        {renderNavigationPrepend()}
      </BucketNavigation>
      {renderBucketByType()}
    </BucketContent>
  );
}

BucketRoute.propTypes = {
  bucketSlug: PropTypes.string,
};

export default BucketRoute;
