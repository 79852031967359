import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import ProgressBar from "react-bootstrap/ProgressBar";
import { shape } from "prop-types";

import FormModal from "components/modals/Form";
import ModalSummaryText from "components/text/ModalSummaryText";
import { Assignment } from "features/assignments/types";
import AssignmentForm from "features/assignments/forms/Assignment";
import {
  useCompleteAssignmentMultipartUploadMutation,
  useCreateAssignmentMultipartUploadMutation,
  useUploadAssignmentMultipartPartMutation,
} from "services/projectCity";
import { renameFile } from "utils/general";

function AssignmentModal({ assignment, ...props }) {
  /** Special modal that can go from uploading an assignment to multipart status. */

  const [
    createAssignmentMultipartUpload,
  ] = useCreateAssignmentMultipartUploadMutation();
  const [
    createAssignmentMultipartPart,
  ] = useUploadAssignmentMultipartPartMutation();
  const [
    completeAssignmentMultipartUpload,
  ] = useCompleteAssignmentMultipartUploadMutation();

  const { addToast } = useToasts();

  // For multipart uploads we'll receive back some data from the form.
  const [createdAssignment, setCreatedAssignment] = useState(null);
  const [multipartFile, setMultipartFile] = useState(null);
  const [totalParts, setTotalParts] = useState(null);
  const [uploadedParts, setUploadedParts] = useState(null);

  useEffect(() => {
    const uploadObject = assignment || createdAssignment;
    if (uploadObject?.id && multipartFile?.size > 0 && totalParts === null) {
      setUploadedParts(0);
      uploadMultipart(multipartFile);
    }
  }, [assignment, createdAssignment, multipartFile]);

  const mb = 1048576;
  const partMb = 5;
  const partSize = mb * partMb;
  const isMultipartUpload = uploadedParts !== null;

  async function uploadMultipart(file) {
    /** Full process for uploading a content multipart upload. */

    setTotalParts(Math.ceil(file.size / partSize));
    let startBytes = 0;
    let part;
    let partNumber = 1;

    try {
      const initialResponse = await createAssignmentMultipartUpload({
        assignment: createdAssignment?.id || assignment?.id,
        filename: renameFile(file.name),
      });
      if (initialResponse.error) {
        addToast("Oops! There was an error creating your upload.", {
          appearance: "error",
        });
        return props.onHide();
      }

      const { uuid } = initialResponse.data;

      // Now that we've created the multipart instance we can start uploading the chunks.
      while (startBytes < file.size) {
        part = file.slice(
          startBytes,
          Math.min(startBytes + partSize, file.size)
        );
        let formData = new FormData();
        formData.append("part", part);
        formData.append("part_number", partNumber);

        const partResponse = await createAssignmentMultipartPart({
          uuid,
          formData,
        });
        if (partResponse.error) {
          addToast("Oops! There was an error appending parts your file.", {
            appearance: "error",
          });
          return props.onHide();
        }

        setUploadedParts(partNumber);
        startBytes += partSize;
        partNumber += 1;
      }

      // Finish the multipart upload.
      await completeAssignmentMultipartUpload({ uuid });
      addToast("Assignment upload complete!", { appearance: "success" });
      props.onHide();
    } catch (e) {
      addToast(`An error has occurred.`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  return (
    <FormModal
      title={isMultipartUpload ? "uploading assignment..." : "assignment"}
      closeButton={!isMultipartUpload}
      onHide={isMultipartUpload ? () => {} : props.onHide}
      {...props}
    >
      {!isMultipartUpload ? (
        <AssignmentForm
          closeModal={props.onHide}
          assignment={assignment}
          setMultipartFile={setMultipartFile}
          setAssignment={setCreatedAssignment}
        />
      ) : (
        <>
          <ModalSummaryText className="my-2 text-center">
            Uploading assignment video, do not close...
          </ModalSummaryText>
          <ProgressBar
            now={(uploadedParts / totalParts) * 100}
            variant="info"
            striped
            animated
          />
        </>
      )}
    </FormModal>
  );
}

AssignmentModal.propTypes = {
  /** Optionally pass in an assignment object to modify. */
  assignment: shape(Assignment),
};

AssignmentModal.defaultProps = {
  assignment: null,
};

export default AssignmentModal;
