import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";

import { TextOnlyMessage } from "types";

const StyledText = styled.div`
  p {
    // Basically we need to replicate Body's style.
    font-family: Basis Grotesque Pro;
    font-size: 22px;
    line-height: 32px;
    color: ${(props) => props.theme.lime};
  }
`;

function TextOnlyMessageDisplay({ message }) {
  /** Display the text only message. */
  return (
    <StyledText
      className="p-3"
      dangerouslySetInnerHTML={{ __html: message.text }}
    />
  );
}

TextOnlyMessageDisplay.propTypes = {
  /** The text only message object that we're displaying. */
  message: shape(TextOnlyMessage).isRequired,
};

export default TextOnlyMessageDisplay;
