import React from "react";
import { Link, useLocation } from "@reach/router";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import { arrayOf, shape, string } from "prop-types";

import MobileNavigationContainer from "components/navigation/MobileNavigationContainer";
import useGetClassLinks from "features/classes/hooks/ClassLinks";

const StyledDropdown = styled(Dropdown)`
  background-color: ${(props) => props.theme.neonPink}!important;
  transform: rotate(1deg);

  > .dropdown-toggle {
    color: ${(props) => props.theme.secondary}!important;
    background-color: ${(props) => props.theme.neonPink}!important;
    border-color: ${(props) => props.theme.neonPink}!important;
    box-shadow: none !important;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  background-color: ${(props) => props.theme.yellow}!important;
  transform: rotate(1deg);

  > .dropdown-toggle {
    color: ${(props) => props.theme.secondary};
  }
`;

function ClassMobileNavigation() {
  /** Display the secondary mobile navigation for class section. */

  const { pathname } = useLocation();
  const classLinks = useGetClassLinks();

  function renderItems() {
    // Render all the bucket links that should go in the dropdown.

    return classLinks.map((link) => {
      return (
        <Dropdown.Item key={link.label} as={Link} to={link.to} {...link}>
          {link.label}
        </Dropdown.Item>
      );
    });
  }

  function renderDropdownTitle() {
    const activeLink = classLinks.find((link) => link.to === pathname);
    return activeLink.label;
  }

  return (
    <MobileNavigationContainer width={92}>
      <StyledDropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {renderDropdownTitle()}
        </Dropdown.Toggle>

        <DropdownMenu>{renderItems()}</DropdownMenu>
      </StyledDropdown>
    </MobileNavigationContainer>
  );
}

ClassMobileNavigation.propTypes = {
  /** Data which tells us which links to generate. */
  linkData: arrayOf(
    shape({
      label: string.isRequired,
      to: string,
    })
  ),
};

export default ClassMobileNavigation;
