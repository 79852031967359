import React, { useState } from "react";
import { shape } from "prop-types";
import ReactPixel from "react-facebook-pixel";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { loadStripe } from "@stripe/stripe-js";

import Button from "components/buttons/Button";
import AuthenticationModal from "features/accounts/modals/Authentication";
import { axiosInstance, baseUrl } from "features/api";
import { stipeAccountType } from "utils/enums";
import { RegistrationTier } from "types";
import { stripePublishableKey } from "utils/stripe";

function StripeCheckout({ tier, ...props }) {
  /**
   * Stripe checkout component
   *
   * https://stripe.com/docs/checkout/integration-builder
   */

  const { addToast } = useToasts();
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const isAuthenticated = useSelector((state) => state.account.token !== "");
  const [showAuthModal, hideAuthModal] = useModal(() => (
    <AuthenticationModal show={true} onHide={hideAuthModal} />
  ));

  // Check if we should use a connected account or the platform account.
  let stripePromiseOptions = {};
  const { account } = tier.project;
  if (
    account &&
    account.accountId &&
    account.type === stipeAccountType.standard
  ) {
    stripePromiseOptions["stripeAccount"] = account.accountId;
  }
  const stripePromise = loadStripe(stripePublishableKey, stripePromiseOptions);

  const handleError = (errorMessage) => {
    setRegistrationLoading(false);
    addToast(errorMessage, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const handleClick = async () => {
    /* Creates the checkout session with stripe, and then takes the user to checkout or shows them
       some error message if the checkout session was not able to be created.
    */
    if (!isAuthenticated) return showAuthModal();

    setRegistrationLoading(true);
    const stripeSessionUrl = `${baseUrl}registration-tiers/${tier.id}/create_payment_session/`;

    let stripe;
    try {
      stripe = await stripePromise;
    } catch (e) {
      handleError("Error processing payment, please try again later.");
    }

    try {
      const response = await axiosInstance.post(stripeSessionUrl);

      ReactPixel.track("InitiateCheckout", {
        currency: "USD",
        value: parseFloat(tier.price),
      });

      const redirectResult = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });

      if (redirectResult.error) {
        handleError(redirectResult.error);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.detail) {
        handleError(e.response.data.detail);
      } else {
        handleError(e.toString());
      }
    }
  };

  return (
    <Button isLoading={registrationLoading} onClick={handleClick} {...props}>
      {props.children}
    </Button>
  );
}

StripeCheckout.propTypes = {
  /** Registration tier that the button is for. */
  tier: shape(RegistrationTier).isRequired,
};

export default StripeCheckout;
