import React from "react";
import Body from "components/text/Body";

function ListItem({ bodyProps, ...props }) {
  /** A list item component that can go into our shared unordered list component. */
  return (
    <li>
      <Body {...bodyProps}>{props.children}</Body>
    </li>
  );
}

export default ListItem;
