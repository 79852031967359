import { axiosInstance } from "features/api";

import ApiService from "../service";

import {
  changePasswordUrl,
  resendVerification,
  disableGoogleAcc,
  changeEmailURL,
  changeEmailConfirmURL,
  verifyEmailUrl,
  accountsUrl,
} from "./api";

export default class AccountService {
  /**
   * Service to handle requests to our generic account authentication resource.
   *
   * In hindsight, this should have been an `UserAuthService` as it conflicts with our
   * accounts resource.
   */

  changePassword(payload) {
    return axiosInstance.post(changePasswordUrl, payload);
  }

  resendVerification(payload) {
    return axiosInstance.post(resendVerification, payload);
  }

  disableGoogleAccount(payload) {
    return axiosInstance.post(disableGoogleAcc, payload);
  }

  changeEmail(payload) {
    return axiosInstance.post(changeEmailURL, payload);
  }

  changeEmailConfirm({ uid, token, email }) {
    return axiosInstance.get(
      `${changeEmailConfirmURL}${uid}/${token}/${email}/`
    );
  }

  verifyEmail(payload) {
    return axiosInstance.post(verifyEmailUrl, payload);
  }
}

export class AccountResourceService extends ApiService {
  /** Service for the accounts endpoint. */
  listUrl = accountsUrl;

  getEarnings(accountId, projectId) {
    /** Get the earnings for an account on a given project. */
    const url = `${this._getDetailUrl(
      accountId
    )}earnings/?project_id=${projectId}`;
    return axiosInstance.get(url);
  }
}
