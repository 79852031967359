import React from "react";

import Body from "components/text/Body";

function ErrorFallback() {
  /** Provide a component to show when there is a frontend error. */
  return (
    <div className="m-4 text-center">
      <Body>
        Oops, an error has occurred. We're looking into the issue and are
        working on a solution.
      </Body>
    </div>
  );
}

export default ErrorFallback;
