import React, { useState } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { navigate } from "@reach/router";
import styled from "styled-components";

import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import PrependControl from "components/forms/shared/PrependControl";
import { updateMe } from "features/accounts/thunks";
import { setFieldErrors } from "utils/forms";
import { getUserImage } from "utils/users";
import { updateProfileImage } from "features/accounts/thunks";
import EditProfileIcon from "images/edit-profile.png";
import LoadingContainer from "components/loading/Container";

const StyledImage = styled(Image)`
  width: 140px;
  padding: 10px;
`;

const ImageWrapper = styled(Col)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledInputLabel = styled.label`
  width: 45px;
  border: 2px dashed ${(props) => props.theme.blue};
  border-radius: 50%;
  cursor: pointer;
`;

const StyledEditProfileImageIcon = styled.img`
  width: 100%;
`;

const PrependControlWrapper = styled.div`
  .focused {
    .input-group {
      .input-group-prepend {
        .input-group-text {
          background-color: ${(props) => props.theme.black};
        }
      }
    }
  }
`;

function ProfileForm({ user, closeModal, ...props }) {
  // Form to update an user record.
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [imageLoading, setImageLoading] = useState(false);
  const [inputName, setInputName] = useState("");

  const {
    handleSubmit,
    control,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: user,
  });

  async function onSubmit(payload) {
    // Dispatch action to update the user.
    try {
      const action = await dispatch(updateMe(payload));
      if (action.type === "UPDATE_ME/fulfilled") {
        addToast("Updated successfully", { appearance: "success" });
        closeModal();

        // If the user has changed their username we need to navigate to the new url.
        if (action.payload.username !== user.username) {
          navigate(`/users/${action.payload.username}`);

          // Reload the page if the user is updating their username.
          if (user.username !== payload.username) window.location.reload();
        }
      } else {
        setFieldErrors(action, setError);
      }

      return action;
    } catch (err) {
      addToast("Error updating user", { appearance: "error" });
    }
  }

  async function changeUserProfileImage(e) {
    setImageLoading(true);
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    await dispatch(updateProfileImage({ formData }));
    setImageLoading(false);
  }

  const socialFieldRules = {
    maxLength: {
      value: 64,
      message: "Maximum length of 64",
    },
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <Form.Row className="align-items-end">
        <Col sm>
          <ImageWrapper>
            <StyledImage fluid roundedCircle src={getUserImage(user)} alt="" />
            {imageLoading ? (
              <LoadingContainer />
            ) : (
              <StyledInputLabel htmlFor="profile-uploader">
                <StyledEditProfileImageIcon src={EditProfileIcon} />
              </StyledInputLabel>
            )}

            <input
              id="profile-uploader"
              style={{ display: "none" }}
              type="file"
              onChange={changeUserProfileImage}
            />
          </ImageWrapper>
        </Col>
        <Col sm>
          <FormGroup label="Username" errors={errors.username}>
            <Controller
              as={FormControl}
              rules={{
                maxLength: 128,
              }}
              name="username"
              defaultValue=""
              control={control}
              isInvalid={errors.username !== undefined}
            />
          </FormGroup>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm>
          <FormGroup label="First name" errors={errors.firstName}>
            <Controller
              as={FormControl}
              rules={{
                required: true,
                maxLength: 128,
              }}
              name="firstName"
              defaultValue=""
              control={control}
              isInvalid={errors.firstName !== undefined}
            />
          </FormGroup>
        </Col>
        <Col sm>
          <FormGroup label="Last name" errors={errors.lastName}>
            <Controller
              as={FormControl}
              rules={{
                maxLength: 128,
              }}
              name="lastName"
              defaultValue=""
              control={control}
              isInvalid={errors.lastName !== undefined}
            />
          </FormGroup>
        </Col>
      </Form.Row>

      <FormGroup label="Bio" errors={errors.bio}>
        <Controller
          as={<FormControl as="textarea" />}
          name="bio"
          rules={{ maxLength: 2000 }}
          defaultValue=""
          control={control}
          isInvalid={errors.bio !== undefined}
        />
      </FormGroup>

      <Form.Row>
        <Col sm>
          <FormGroup label="Instagram" errors={errors.instagram}>
            <Controller
              render={(fieldProps, _) => (
                <PrependControlWrapper>
                  <div className={inputName === "instagram" && "focused"}>
                    <PrependControl
                      errorField={errors.instagram}
                      prependText="@"
                      fieldProps={fieldProps}
                      onFocus={() => setInputName("instagram")}
                      onBlur={() => setInputName("")}
                    />
                  </div>
                </PrependControlWrapper>
              )}
              name="instagram"
              defaultValue=""
              rules={socialFieldRules}
              control={control}
            />
          </FormGroup>
        </Col>
        <Col sm>
          <FormGroup label="Twitter" errors={errors.twitter}>
            <Controller
              render={(fieldProps, _) => (
                <PrependControlWrapper>
                  <div className={inputName === "twitter" && "focused"}>
                    <PrependControl
                      errorField={errors.twitter}
                      prependText="@"
                      fieldProps={fieldProps}
                      onFocus={() => setInputName("twitter")}
                      onBlur={() => setInputName("")}
                    />
                  </div>
                </PrependControlWrapper>
              )}
              name="twitter"
              defaultValue=""
              rules={socialFieldRules}
              control={control}
            />
          </FormGroup>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col sm>
          <FormGroup label="Facebook" errors={errors.facebook}>
            <Controller
              render={(fieldProps, _) => (
                <PrependControlWrapper>
                  <div className={inputName === "facebook" && "focused"}>
                    <PrependControl
                      errorField={errors.facebook}
                      prependText="@"
                      fieldProps={fieldProps}
                      onFocus={() => setInputName("facebook")}
                      onBlur={() => setInputName("")}
                    />
                  </div>
                </PrependControlWrapper>
              )}
              name="facebook"
              defaultValue=""
              rules={socialFieldRules}
              control={control}
            />
          </FormGroup>
        </Col>
        <Col sm>
          <FormGroup label="Linkedin" errors={errors.linkedin}>
            <Controller
              render={(fieldProps, _) => (
                <PrependControlWrapper>
                  <div className={inputName === "linkedin" && "focused"}>
                    <PrependControl
                      errorField={errors.linkedin}
                      prependText="@"
                      fieldProps={fieldProps}
                      onFocus={() => setInputName("linkedin")}
                      onBlur={() => setInputName("")}
                    />
                  </div>
                </PrependControlWrapper>
              )}
              name="linkedin"
              defaultValue=""
              rules={socialFieldRules}
              control={control}
            />
          </FormGroup>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col sm>
          <FormGroup label="Discord" errors={errors.discord}>
            <Controller
              render={(fieldProps, _) => (
                <PrependControlWrapper>
                  <div className={inputName === "discord" && "focused"}>
                    <PrependControl
                      errorField={errors.discord}
                      prependText="@"
                      fieldProps={fieldProps}
                      onFocus={() => setInputName("discord")}
                      onBlur={() => setInputName("")}
                    />
                  </div>
                </PrependControlWrapper>
              )}
              name="discord"
              defaultValue=""
              rules={socialFieldRules}
              control={control}
            />
          </FormGroup>
        </Col>
        <Col sm></Col>
      </Form.Row>

      <FormSubmitContainer>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Save</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ProfileForm.propTypes = {
  // The user object that we're updating.
  user: PropTypes.object.isRequired,

  // Function to close the modal the form is in.
  closeModal: PropTypes.func.isRequired,
};

export default ProfileForm;
