import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { Link } from "@reach/router";
import { useLocation } from "@reach/router";
import { isMobile } from "react-device-detect";
import { useScrollDirection } from "react-use-scroll-direction";
import { useModal } from "react-modal-hook";

import AccountDropdown from "components/dropdowns/Account";
import CreateButton from "components/buttons/Create";
import NotificationsButton from "components/buttons/Notifications";
import NotificationDrawer from "features/notifications/modals/Drawer";
import useIsAuthenticated from "hooks/IsAuthenticated";
import ProjectCity from "images/project_city.svg";
import NavBackgroundSm from "images/backgrounds/main_nav_sm.svg";
import NavBackground from "images/backgrounds/main_nav.svg";
import MenuIcon from "images/mobile_nav.svg";
import CloseIcon from "images/icons/close.svg";

const RightNavbar = styled(Navbar)`
  background-color: transparent !important;
  padding: 0px;
  border: 0;
  height: ${({ theme }) =>
    isMobile ? theme.mobileNavbarHeight : theme.navbarHeight};
  background-image: url("${isMobile ? NavBackgroundSm : NavBackground}");
  background-repeat: round;
  transition: all ${(props) => props.theme.transitionSuperFast} ease;

  ${(props) =>
    !props.$showNavbar &&
    `
    transform: translateY(-${props.theme.mobileNavbarHeight});
  `}

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    background-repeat: repeat-x;
  }
`;

const RightNav = styled(Nav)`
  // Inner nav that's on the right side of the navbar.

  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    order: 4;
    margin-left: auto;

    &.not-collapsed {
      height: max-content;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      margin: auto;
    }
  }
`;

const StyledCollapse = styled(Navbar.Collapse)`
  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    margin-top: ${(props) => props.theme.spacing};
    order: 3;
  }
`;

const StyledBrand = styled(Navbar.Brand)`
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    order: 2;

    img {
      margin-left: 100px;
      max-height: 35px;
    }
  }
`;

const AddProjectLink = styled(Nav.Link)`
  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingMd};
  }
`;

const StyledLink = styled(Nav.Link)`
  font-family: "Basis Grotesque Mono";
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  position: relative;
  margin: 0 15px;
  text-align: center;
  color: ${(props) =>
    props.$isActive ? props.theme.black : props.theme.neonPink};

  &:hover {
    color: ${(props) => props.theme.neonPink};
    text-decoration: none;

    &:after {
      border-bottom: 3px solid ${(props) => props.theme.black};
    }
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: auto;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 24px;
    margin-bottom: 20px;
  }

  @media (min-width: ${(props) => props.theme.smallPcBreakpoint}) {
    margin: 0 30px;
  }

  &:after {
    content: "";
    border-bottom: ${(props) =>
      props.$isActive ? "3px solid " + props.theme.neonPink : "none"};
    text-align: center;
    width: 100%;
    display: block;
    transform: rotate(${(props) => (props.$isEven ? "-" : "")}2deg);
    position: absolute;
    bottom: -9px;
  }
`;

const MiddleNav = styled.div`
  margin: auto;
  display: flex;

  @media (min-width: ${(props) => props.theme.smallPcBreakpoint}) {
    padding-left: 95px;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    height: calc(100vh - ${(props) => props.theme.mobileNavbarHeight} + 3px);
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledContainer = styled(Container)`
  background-color: ${(props) =>
    props.$isExpanded ? props.theme.lime : "transparent"};
  ${(props) => props.$isExpanded && "margin-top: 3px;"}

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    padding-left: 74px;
    padding-right: 54px;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    justify-content: flex-start !important;
  }
`;

const StyledCreateButton = styled(CreateButton)`
  width: 45px;
`;

const NotificationButtonContainer = styled.div`
  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    position: fixed;
    right: 10px;
    top: 10px;
  }
`;

const StyledToggle = styled(Navbar.Toggle)`
  width: 55px;
`;

function CreateNewProject(props) {
  // Button that allows the user to create a new project.

  const [isHovered, setNotificationsHover] = useState(false);

  return (
    <AddProjectLink to="/projects/create" as={Link} {...props}>
      <StyledCreateButton
        onClick={props.onClick}
        onMouseEnter={() => setNotificationsHover(true)}
        onMouseLeave={() => setNotificationsHover(false)}
        hover={isHovered}
      />
    </AddProjectLink>
  );
}

function MainNavbar() {
  /** The navbar that is across the top of the main application. */

  const accountState = useSelector((state) => state.account);
  const [expanded, setExpanded] = useState(false);
  const rightNav = useRef(null);
  const [showNavbar, setMobileShowNavbar] = useState(true);
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [notificationsHover, setNotificationsHover] = useState(false);

  const [showNotificationDrawer, hideNotificationDrawer] = useModal(() => {
    return <NotificationDrawer onHide={hideNotificationDrawer} />;
  });

  function onToggleClicked() {
    setExpanded(!expanded);
    rightNav.current.classList.toggle("not-collapsed");
  }

  function disableExpanded() {
    setExpanded(false);
    rightNav.current.classList.toggle("not-collapsed");
  }

  useEffect(() => {
    /** Check if we should hide/show the navbar on mobile. */
    if (isScrollingDown && isMobile && showNavbar && window.pageYOffset > 30)
      setMobileShowNavbar(false);

    if (isScrollingUp && isMobile && !showNavbar) setMobileShowNavbar(true);
  }, [isScrollingUp, isScrollingDown]);

  const linkData = [
    {
      to: "/classes",
      label: "Classes",
      id: "classes-navitem",
    },
    {
      to: "/projects",
      label: "Projects",
      id: "projects-navitem",
    },
    {
      to: "/sprint/content",
      label: "Story Sprint",
      id: "sprint-navitem",
    },
    {
      to: "/feed",
      label: "Feed",
      id: "feed-navitem",
    },
    {
      to: "/about",
      label: "About",
    },
    {
      to: "/search",
      label: "Search",
    },
  ];

  function isActiveNav(to) {
    /** Determine if the nav link should be active. */
    if (to === "/sprint/content")
      return location.pathname.startsWith("/sprint");

    if (to === "/classes") return location.pathname.startsWith("/classes");

    return location.pathname === to;
  }

  return (
    <RightNavbar
      fixed="top"
      expand="lg"
      expanded={expanded}
      $showNavbar={showNavbar}
    >
      <StyledContainer fluid $isExpanded={expanded}>
        <StyledBrand onClick={() => navigate("/")}>
          <img src={ProjectCity} alt="Logo" />
        </StyledBrand>
        <StyledToggle onClick={onToggleClicked}>
          <img src={expanded ? CloseIcon : MenuIcon} alt="" />
        </StyledToggle>
        <StyledCollapse id="basic-navbar-nav">
          <MiddleNav>
            {linkData.map((link, index) => (
              <StyledLink
                key={index}
                as={Link}
                onClick={disableExpanded}
                $isActive={isActiveNav(link.to)}
                $isEven={index % 2 === 0}
                {...link}
              >
                {link.label}
              </StyledLink>
            ))}

            {isAuthenticated && isMobile && (
              <CreateNewProject onClick={disableExpanded} />
            )}
          </MiddleNav>
        </StyledCollapse>

        <RightNav ref={rightNav}>
          <AccountDropdown
            account={accountState}
            setExpanded={setExpanded}
            expanded={expanded}
          />
          {isAuthenticated && !isMobile && (
            <CreateNewProject onClick={disableExpanded} />
          )}
          {isAuthenticated && (
            <NotificationButtonContainer>
              <NotificationsButton
                onClick={showNotificationDrawer}
                onMouseEnter={() => setNotificationsHover(true)}
                onMouseLeave={() => setNotificationsHover(false)}
                hover={notificationsHover}
              />
            </NotificationButtonContainer>
          )}
        </RightNav>
      </StyledContainer>
    </RightNavbar>
  );
}

export default MainNavbar;
