import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormGroup from "components/forms/shared/FormGroup";
import FormControl from "components/forms/shared/Control";
import { useApplyPromotionCodeMutation } from "services/projectCity";
import { useSelector } from "react-redux";
import { func } from "prop-types";

function ApplyCouponModal({ refetchSubscription, ...props }) {
  /** Allow user to enter a coupon code. */

  const { user } = useSelector((state) => state.account);
  const [errorText, setErrorText] = useState(null);
  const [couponValue, setCouponValue] = useState("");
  const [applyDiscount, result] = useApplyPromotionCodeMutation();

  function handleApply() {
    setErrorText("");
    applyDiscount({
      subscriptionId: user.subscription.subscriptionId,
      couponCode: couponValue,
    });
  }

  useEffect(() => {
    if (result.isSuccess) {
      refetchSubscription();
      props.onHide();
    }
  }, [result.isSuccess]);

  useEffect(() => {
    if (result.error) {
      const error =
        result.error?.data?.detail || "Error applying discount code";
      setErrorText(error);
    }
  }, [result.error]);

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title="Apply Coupon Code"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        <div>
          <FormGroup label="Coupon code">
            <FormControl
              onChange={(e) => setCouponValue(e.target.value)}
              value={couponValue}
            />
          </FormGroup>

          <FormSubmitContainer errorText={errorText}>
            <FormSecondaryButton
              onClick={props.onHide}
              disabled={result.isLoading}
            >
              Cancel
            </FormSecondaryButton>
            <FormPrimaryButton
              onClick={handleApply}
              disabled={result.isLoading}
            >
              Apply
            </FormPrimaryButton>
          </FormSubmitContainer>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}

ApplyCouponModal.propTypes = {
  refetchSubscription: func.isRequired,
};

export default ApplyCouponModal;
