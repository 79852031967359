import React from "react";

import Sticker from "components/icons/Sticker";
import BaseProjectTileBanner from "../Base";

function PrivateBanner(props) {
  /** Mark that a project is private. */
  return (
    <BaseProjectTileBanner position="center" {...props}>
      <Sticker type="private" />
    </BaseProjectTileBanner>
  );
}

export default PrivateBanner;
