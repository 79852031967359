import React, { useEffect, useState } from "react";
import { arrayOf, func, number, shape } from "prop-types";

import { Teacher } from "features/users/types";
import LoadingContainer from "components/loading/Container";
import RevenueSplitsTable from "components/tables/RevenueSplits";
import { ModalActionProps } from "types";
import RevenueSplitForm from "components/forms/RevenueSplit";
import {
  useCreateRevenueSplitMutation,
  useDeleteRevenueSplitMutation,
  useGetRevenueSplitListQuery,
} from "services/projectCity";

function SetupTierRevenueSplitsSection({
  registrationTierId,
  teachers,
  setShowFormSubmitContainer,
}) {
  /** Section component to choose the initial payment split for a registration tier. */

  const [isEditMode, setIsEditMode] = useState(false);
  const [hasCreatedSplits, setHasCreatedSplits] = useState(false);
  const [selectedRevenueSplit, setSelectedRevenueSplit] = useState(null);
  const revenueSplits = useGetRevenueSplitListQuery({
    registrationTier: registrationTierId,
  });
  const [deleteRevenueSplit] = useDeleteRevenueSplitMutation();
  const [createRevenueSplit] = useCreateRevenueSplitMutation();

  useEffect(() => {
    /** When we're in edit mode, we should hide the parent submit container. */
    setShowFormSubmitContainer(!isEditMode);
  }, [isEditMode]);

  useEffect(() => {
    /** Create the initial revenue splits. */
    const totalTeachers = teachers.length;
    const equalSplit = Math.floor(100 / totalTeachers);

    teachers.forEach((teacher) => {
      const payload = {
        user: teacher.id,
        registrationTier: registrationTierId,
        splitPercent: equalSplit,
      };
      createRevenueSplit({ registrationTierId, ...payload });
    });
  }, []);

  useEffect(() => {
    if (revenueSplits.length >= teachers.length) setHasCreatedSplits(true);
  }, [revenueSplits]);

  // Once all the splits are created, we can continue.
  if (revenueSplits.length !== teachers.length && !hasCreatedSplits)
    return <LoadingContainer text="Creating initial splits..." />;

  function handleDelete() {
    /** Handle delete action of a revenue split. */
    deleteRevenueSplit({ id: selectedRevenueSplit.id });
  }

  function handleReset() {
    /** Reset the modal back to list view. */
    setIsEditMode(false);
    setSelectedRevenueSplit(null);
  }

  return isEditMode ? (
    <div>
      <RevenueSplitForm
        revenueSplit={selectedRevenueSplit}
        backAction={handleReset}
        successAction={handleReset}
      />
    </div>
  ) : (
    <div>
      <RevenueSplitsTable
        revenueSplits={revenueSplits}
        modalActionProps={{
          setSelectedRevenueSplit,
          navigateEdit: () => setIsEditMode(true),
          navigateDeleteConfirm: handleDelete,
        }}
      />
    </div>
  );
}

SetupTierRevenueSplitsSection.propTypes = {
  /** Identifier of the registration tier that is being modified. */
  registrationTierId: number.isRequired,

  /** Teachers that we're choosing splits for. */
  teachers: arrayOf(shape(Teacher)),

  /** Actions that are needed to show revenue split sections in the modal. */
  modalActionProps: ModalActionProps.isRequired,

  /** Action in parent modal to determine if the submit container is shown. */
  setShowFormSubmitContainer: func.isRequired,
};

export default SetupTierRevenueSplitsSection;
