import styled from "styled-components";
import { useModal } from "react-modal-hook";

import SubHeaderText from "components/text/SubHeader/TextOnly";
import CopyHeader from "components/text/CopyHeader";
import EditIcon from "components/icons/Edit";
import FormModal from "components/modals/Form";
import DeleteIcon from "components/icons/Delete";
import ProjectCityButton from "components/buttons/Button";
import UploadScheduleForm from "features/schedules/forms/UploadSchedule";
import UploadScheduleSectionForm from "features/schedules/forms/UploadScheduleSection";
import ConfirmDeleteUploadScheduleModal from "features/schedules/modals/UploadScheduleConfirmDelete";
import useIsProjectCityAdmin from "hooks/IsProjectCityAdmin";
import BulletDash from "images/icons/bullet-point.svg";
import UploadScheduleSection from "../ScheduleSection";

const Wrapper = styled.div`
  white-space: pre-line;
  margin-bottom: 120px;

  li {
    list-style-image: url(${BulletDash});
  }

  ${(props) =>
    props.$isPrivate &&
    `
    opacity: 0.6;
  `}

  ${(props) => (props.$isEven ? "padding-left: 25%;" : "padding-right: 25%;")}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    ${(props) => (props.$isEven ? "padding-left: 5%;" : "padding-right: 5%;")}
  }
`;

const TitleContainer = styled(SubHeaderText)``;

function UploadSchedule({ schedule, isEven }) {
  /** Display a full upload schedule component. */

  // Only allow project city admins to modify schedules.
  const allowEdit = useIsProjectCityAdmin();

  const [showUploadScheduleModal, hideUploadScheduleModal] = useModal(
    () => (
      <FormModal
        onHide={hideUploadScheduleModal}
        title="Update Upload Schedule"
      >
        <UploadScheduleForm
          closeModal={hideUploadScheduleModal}
          uploadSchedule={schedule}
        />
      </FormModal>
    ),
    [schedule]
  );

  const [
    showDeleteUploadScheduleModal,
    hideDeleteUploadScheduleModal,
  ] = useModal(
    () => (
      <ConfirmDeleteUploadScheduleModal
        uploadSchedule={schedule}
        onHide={hideDeleteUploadScheduleModal}
      />
    ),
    [schedule]
  );

  const [
    showUploadScheduleSectionModal,
    hideUploadScheduleSectionModal,
  ] = useModal(() => (
    <FormModal
      onHide={hideUploadScheduleSectionModal}
      title="Create Upload Schedule Section"
    >
      <UploadScheduleSectionForm
        closeModal={hideUploadScheduleSectionModal}
        uploadScheduleSection={{ uploadSchedule: schedule.id }}
      />
    </FormModal>
  ));

  if (!schedule.isPublic && !allowEdit) return null;

  return (
    <Wrapper $isEven={isEven} $isPrivate={!schedule.isPublic}>
      <TitleContainer color="purple" className="mb-2">
        {schedule.title}{" "}
        {allowEdit && (
          <>
            <EditIcon role="button" onClick={showUploadScheduleModal} />
            <DeleteIcon
              className="ml-1"
              role="button"
              onClick={showDeleteUploadScheduleModal}
            />
          </>
        )}
      </TitleContainer>
      {schedule.theme && (
        <CopyHeader color="lime">Theme: {schedule.theme}</CopyHeader>
      )}
      {schedule.contest && (
        <CopyHeader className="mb-3" color="lime">
          Contest: {schedule.contest}
        </CopyHeader>
      )}
      {schedule.description && (
        <CopyHeader className="mb-3">{schedule.description}</CopyHeader>
      )}
      {schedule.sections.map((section) => (
        <UploadScheduleSection key={section.id} section={section} />
      ))}
      <ProjectCityButton noTilt onClick={showUploadScheduleSectionModal}>
        Create New Date Section
      </ProjectCityButton>
    </Wrapper>
  );
}

export default UploadSchedule;
