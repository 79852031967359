import Col from "react-bootstrap/Col";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

import DashboardContainer from "components/containers/Dashboard";
import DashboardLoadingContainer from "components/loading/DashboardContainer";
import LoadingContainer from "components/loading/Container";
import Post from "components/posts/Post";
import Caption from "components/text/Caption";
import Sticker from "components/icons/Sticker";
import projectCityApi, { useGetPostListQuery } from "services/projectCity";

const LeftSticker = styled(Sticker)`
  position: absolute;
  left: -30px;
`;

const RightSticker = styled(Sticker)`
  position: absolute;
  right: -30px;
`;

function SiteFeedRoute() {
  /** Provide the main static site feed that users can view new shared posts. */

  const { data, isLoading } = useGetPostListQuery();
  const [loadNextPage] = projectCityApi.useLazyGetNextPostPageQuery();
  const posts = data?.results || [];
  const next = data?.next;

  function fetchNextPosts() {
    /** Shared function to use for initial fetching and pagination of posts.  */
    loadNextPage({ next });
  }

  function renderItems() {
    return posts.map((post) => (
      <Col md={{ span: 10, offset: 1 }} key={post.id}>
        <Post post={post} />
      </Col>
    ));
  }

  if (isLoading) return <DashboardLoadingContainer text="Loading feed" />;

  if (posts.length === 0)
    return (
      <Caption className="mt-5 pt-5 text-center" color="orange">
        There are no posts yet.
      </Caption>
    );

  return (
    <DashboardContainer>
      <LeftSticker />
      <InfiniteScroll
        dataLength={posts.length}
        next={fetchNextPosts}
        hasMore={next !== null}
        loader={<LoadingContainer text="Loading more..." />}
      >
        {renderItems()}
      </InfiniteScroll>
      <RightSticker type="random" />
    </DashboardContainer>
  );
}

export default SiteFeedRoute;
