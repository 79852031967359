import React from "react";
import styled from "styled-components";

import BaseTable from "../Base";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 80px;
    margin-right: 80px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    // Remove the container gutter spacing to make table full screen width.
    margin-left: -20px;
    margin-right: -20px;
  }
`;

function ProjectAdminTable({ containerProps, ...props }) {
  /** A shared table to inherit for tables in the project admin. */

  return (
    <Wrapper {...containerProps}>
      <BaseTable {...props}>{props.children}</BaseTable>
    </Wrapper>
  );
}

export default ProjectAdminTable;
