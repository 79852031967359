import { useState } from "react";
import { object } from "prop-types";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import { updateProject } from "features/projects/thunks";
import { useToasts } from "react-toast-notifications";
import Select from "components/controls/Select";
import { isClass } from "utils/projects";

function ProjectConfigurationForm({ project, ...props }) {
  /** Update general data about a project. */

  const dispatch = useDispatch();
  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm();

  const _isClass = isClass(project);

  const { addToast } = useToasts();

  async function onSubmit(data) {
    /** When we create a project, there are some default values that need to be set. */
    setFormError(null);

    let payload = data;

    // Clean the data to send the data that is needed.
    if (payload.defaultPage) payload.defaultPage = data.defaultPage.value;

    const action = await dispatch(
      updateProject({ projectId: project.id, payload })
    );
    if (action.type === "UPDATE_PROJECT/fulfilled") {
      addToast("Project updated!", { appearance: "success" });
    } else setFormError("Error updating project");
  }

  function getDefaultPageOptions() {
    /** Return the options for the default page select control. */
    return project.buckets.map((bucket) => ({
      label: bucket.title,
      value: bucket.id,
    }));
  }

  function getDefaultValue() {
    // Take the default page id and return the option object.
    const defaultPageId = project.defaultPage;

    if (defaultPageId) {
      const defaultPage = project.buckets.find(
        (bucket) => bucket.id === defaultPageId
      );
      return { label: defaultPage.title, value: defaultPage.id };
    } else {
      return null;
    }
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup
        label="Default page"
        isCustomControl
        tooltipText={`If the user has access, they will be shown the chosen page/screen first when viewing the ${
          _isClass ? "class" : "project"
        }`}
      >
        <Controller
          render={(props) => (
            <Select
              options={getDefaultPageOptions()}
              placeholder="Info page"
              defaultValue={getDefaultValue()}
              isClearable
              {...props}
            />
          )}
          control={control}
          name="defaultPage"
        />
      </FormGroup>
      <FormSubmitContainer errorText={formError}>
        <FormPrimaryButton isLoading={isSubmitting}>Save</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ProjectConfigurationForm.propTypes = {
  /** The project that we're updating.  */
  project: object.isRequired,
};

export default ProjectConfigurationForm;
