import React from "react";
import { shape } from "prop-types";
import { navigate } from "@reach/router";
import styled from "styled-components";

import Button from "components/buttons/Button";
import { getProjectDescriptor, getProjectManagementUrl } from "utils/projects";
import { Project } from "types";

const Wrapper = styled.div`
  position: absolute;

  // A few pixels less than navbar height, so that it covers the wavy background image
  top: 66px;
  right: ${(props) => props.theme.sideGutter};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    right: 5px;
  }
`;

const StyledButton = styled(Button)`
  padding: 16px;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    font-size: 20px;
    line-height: 22px;
  }
`;

function ClassManagementButton({ project, ...props }) {
  /** Navigation button to go to class management. */

  return (
    <Wrapper>
      <StyledButton
        onClick={() => navigate(getProjectManagementUrl(project))}
        noTilt
        {...props}
      >
        {getProjectDescriptor(project)} Management
      </StyledButton>
    </Wrapper>
  );
}

ClassManagementButton.propTypes = {
  /** The project that we're rendering the class management button for. */
  project: shape(Project).isRequired,
};

export default ClassManagementButton;
