import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import ProjectActAsContext from "context/ProjectActAs";
import ProjectInfoSection from "features/projects/components/InfoSection";
import BucketNavigation from "components/navbars/Buckets";

const Wrapper = styled.div`
  padding-top: 69px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-top: ${(props) => props.theme.mobileNavbarHeight};
  }
`;

function ProjectInfoRoute() {
  /** Page to view general information about a project. */
  const project = useSelector((state) => state.projects.detail);

  return (
    <Wrapper>
      <ProjectInfoSection project={project} />
      <ProjectActAsContext.Consumer>
        {(props) => (
          <BucketNavigation project={project} actAsContext={props}>
            <div />
          </BucketNavigation>
        )}
      </ProjectActAsContext.Consumer>
    </Wrapper>
  );
}

export default ProjectInfoRoute;
