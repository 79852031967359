import { useDispatch, useSelector } from "react-redux";
import { func, shape } from "prop-types";
import { useToasts } from "react-toast-notifications";

import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";
import { ContentUpload } from "features/sprint/types";
import { removeContentUpload } from "features/buckets/slice";
import { useDeleteContentUploadMutation } from "services/projectCity";

function ConfirmDeleteContentUploadModal({
  contentUpload,
  followupAction,
  ...props
}) {
  /** Allow the user to confirm deletion of their content upload. */

  const [deleteContentUpload] = useDeleteContentUploadMutation();
  const dispatch = useDispatch();
  const currentBucketId = useSelector((state) => state.buckets.current.id);
  const { addToast } = useToasts();

  async function handleDelete() {
    await deleteContentUpload({ contentUploadId: contentUpload.id });
    dispatch(
      removeContentUpload({
        bucketId: currentBucketId,
        contentUploadId: contentUpload.id,
      })
    );
    props.onHide();
    addToast("Content upload video deleted", { appearance: "success" });
    followupAction();
  }

  return (
    <ConfirmActionModal
      title="Delete upload?"
      confirmAction={handleDelete}
      {...props}
    >
      <ModalSummaryText>
        Are you sure you want to delete the "{contentUpload.title}" content
        upload? The action is irreversible.
      </ModalSummaryText>
    </ConfirmActionModal>
  );
}

ConfirmDeleteContentUploadModal.propTypes = {
  /** The content upload object that is being deleted. */
  contentUpload: shape(ContentUpload).isRequired,

  /** Action to take after deleltion occurs. */
  followupAction: func,
};

ConfirmDeleteContentUploadModal.defaultProps = {
  followupAction: () => {},
};

export default ConfirmDeleteContentUploadModal;
