import React from "react";
import styled from "styled-components";
import { shape } from "prop-types";
import { isMobile } from "react-device-detect";

import StickerImage from "components/images/Sticker";
import SocialContainer from "components/social/Container";
import Header from "components/text/Header";
import Body from "components/text/Body";
import CopyHeader from "components/text/CopyHeader";
import ProfileImageStar from "images/Star.svg";
import { getUserImage } from "utils/users";
import { User } from "types";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: inline-flex;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StickerContainer = styled.div`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingXs};
  }
`;

const FooterContainer = styled.div`
  margin-bottom: ${(props) => props.theme.vSpacingMd};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 200px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Basis Grotesque Mono;

  font-size: 21px;
  line-height: 30px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    align-items: flex-start;
    flex-direction: column;
    font-size: 17px;
    line-height: 27px;
  }
`;

const StyledHeader = styled(Header)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-left: ${(props) => props.theme.vSpacingMd};
  }
`;

function ProfileHeader({ user }) {
  /** Provide a header for the profile page which displays some info about the user. */

  const {
    firstName,
    lastName,
    teachesProjects,
    registeredProjects,
    bio,
    studentCount,
  } = user;

  return (
    <>
      <HeaderContainer>
        <StickerContainer>
          <StickerImage
            dimension={isMobile ? 80 : 200}
            imageProps={{
              roundedCircle: true,
              src: getUserImage(user),
            }}
            sticker={teachesProjects.length ? ProfileImageStar : null}
          />
        </StickerContainer>
        <StyledHeader top={firstName} bottom={lastName} noMargin={isMobile} />
      </HeaderContainer>
      <FooterContainer>
        <CopyHeader className="mt-3">{bio || null}</CopyHeader>
        <StatsContainer className="mt-3">
          {teachesProjects?.length > 0 && (
            <div>
              <Body mono color="green" className="mb-0">
                <strong>
                  {teachesProjects?.length + registeredProjects?.length}
                </strong>
                &nbsp;classes
              </Body>
              <Body mono color="green" className="mb-0">
                <strong>{studentCount}</strong> students
              </Body>
            </div>
          )}
          <SocialContainer user={user} />
        </StatsContainer>
      </FooterContainer>
    </>
  );
}

ProfileHeader.propTypes = {
  /** The user whose profile we're viewing. */
  user: shape(User).isRequired,
};

export default ProfileHeader;
