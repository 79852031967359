import React from "react";

const SearchIcon = ({ btnColor, iconColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="27.44 29.64 131.02 129"
    >
      <path
        d="M138.854 48.3348C151.637 60.9593 157.917 78.2509 158.421 95.1383C158.925 112.026 154.033 129.893 142.034 143.039C130.035 156.184 113.019 158.179 96.7725 158.525C80.5261 158.871 61.976 159.187 49.1923 146.563C36.4086 133.939 27.9715 114.819 27.455 97.9317C26.9385 81.0445 38.118 65.4245 50.1229 52.2726C62.1278 39.1207 76.6385 29.9952 92.885 29.6494C109.131 29.3037 126.076 35.7167 138.854 48.3348Z"
        fill={btnColor}
      />
      <path
        d="M90.0377 114.327C78.0071 114.894 68.3721 104.908 67.7832 92.4C67.194 79.8867 75.8388 68.8429 87.8694 68.2764C99.8999 67.71 111.944 77.7796 112.533 90.2929C113.122 102.801 102.068 113.761 90.0377 114.327ZM117.808 110.518C121.803 104.499 122.227 97.7408 121.856 89.854C120.955 70.7243 105.81 57.7189 87.413 58.5851C69.0213 59.4511 53.2197 73.9136 54.1205 93.0433C55.0212 112.173 72.2084 127.139 90.6002 126.273C98.1828 125.916 103.086 124.902 108.481 120.197C117.75 128.966 116.665 130.316 128.533 135.039C134.019 136.859 136.149 133.66 135.576 127.71C129.793 118.633 127.072 119.293 117.808 110.518Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default SearchIcon;
