import { projectSectionsUrl } from "../api";

import ApiService from "features/service";

class ProjectSectionService extends ApiService {
  /** Handle requests to the project section api endpoint. */

  listUrl = projectSectionsUrl;
}

export default ProjectSectionService;
