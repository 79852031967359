import { shape } from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import TileFooter from "components/tiles/Footer";
import { ContentUpload } from "features/sprint/types";
import { reorderContentUpload } from "features/buckets/slice";
import DefaultBackground from "images/bg.jpg";
import { useReorderContentUploadMutation } from "services/projectCity";
import { dragTypes } from "utils/enums";
import ContentUploadImage from "../ContentUploadImage";

const Wrapper = styled.div`
  cursor: move;
  opacity: ${(props) => (props.$isDragging ? 0.3 : 1)};
  background-image: url(${DefaultBackground});
  border: 0;
  padding: 5px;

  ${(props) =>
    props.$isOver &&
    `
    background: ${props.theme.neonPink};
    border-radius: 5px;
  `}
`;

function OrderableContentUpload({ contentUpload }) {
  // Individual content upload which can be reordered.

  const [reorder] = useReorderContentUploadMutation();
  const dispatch = useDispatch();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: dragTypes.contentUpload,
      item: contentUpload,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [contentUpload]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dragTypes.contentUpload,
      drop: handleDrop,
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [contentUpload]
  );

  function handleDrop(item, monitor) {
    reorder({ contentUploadId: item.id, order: contentUpload.order });
    dispatch(
      reorderContentUpload({
        contentUploadId: item.id,
        originalOrder: item.order,
        newOrder: contentUpload.order,
      })
    );
  }

  function attachRef(el) {
    // Use the same element for drag and drop.
    drag(el);
    drop(el);
  }

  return (
    <Wrapper ref={attachRef} $isDragging={isDragging} $isOver={isOver}>
      <ContentUploadImage contentUpload={contentUpload} />
      <TileFooter top={contentUpload.title} />
    </Wrapper>
  );
}

OrderableContentUpload.propTypes = {
  contentUpload: shape(ContentUpload).isRequired,
};

export default OrderableContentUpload;
