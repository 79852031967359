import styled from "styled-components";

import Body from "components/text/Body";

const StyledParagraph = styled(Body)`
  margin-bottom: 10px;
`;

function Paragraph(props) {
  return <StyledParagraph {...props}>{props.children}</StyledParagraph>;
}

export default Paragraph;
