import React, { useState } from "react";
import { func } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import BaseForm from "components/forms/Base";
import FormControl from "components/forms/shared/Control";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import SocialAuthenticationContainer from "components/authentication/SocialAuthenticationContainer";
import AlternativeDivider from "components/forms/shared/AlternativeDivider";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import { loginUser } from "features/accounts/thunks";

function LoginForm({ closeModal }) {
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [formErrorText, setFormErrorText] = useState();

  const onSubmit = async (data) => {
    setFormErrorText(null);
    const response = await dispatch(loginUser(data));

    if (response.type === "LOGIN_USER/fulfilled") {
      closeModal();
      addToast("Successfully logged in", {
        appearance: "success",
        autoDismiss: true,
      });
    } else if (response.type === "LOGIN_USER/rejected") {
      // Display the error message on the login form.
      try {
        setFormErrorText(response.payload.data.nonFieldErrors[0]);
      } catch {
        setFormErrorText("There was an error logging in.");
      }
    }

    return response;
  };

  return (
    <>
      <SocialAuthenticationContainer closeModal={closeModal} />
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <AlternativeDivider />
        <FormGroup label="Email/Username" errors={errors.username}>
          <Controller
            as={FormControl}
            rules={{ required: true }}
            name="username"
            control={control}
            defaultValue=""
            isInvalid={errors.username !== undefined}
          />
        </FormGroup>

        <FormGroup label="Password" errors={errors.password}>
          <Controller
            as={FormControl}
            rules={{ required: true }}
            isInvalid={errors.password !== undefined}
            name="password"
            type="password"
            control={control}
            defaultValue=""
          />
        </FormGroup>

        <FormSubmitContainer errorText={formErrorText} withBottomDivider>
          <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
          <FormPrimaryButton isLoading={isSubmitting}>Log in</FormPrimaryButton>
        </FormSubmitContainer>
      </BaseForm>
    </>
  );
}

LoginForm.propTypes = {
  closeModal: func.isRequired,
};

export default LoginForm;
