import React from "react";
import styled from "styled-components";
import { getRandomColor } from "utils/general";

const StyledHeader = styled.div`
  color: ${() => getRandomColor()};
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.06em;
  position: relative;
`;

function SectionHeader({ children, ...props }) {
  /* Returns a header to separate sections, used in various text-based components. */
  return (
    <div {...props}>
      <StyledHeader>{children}</StyledHeader>
    </div>
  );
}

export default SectionHeader;
