import { number, object, string } from "prop-types";

export const Donation = {
  uuid: string.isRequired,
  fromUser: object.isRequired,
  toUser: object.isRequired,
  amount: number.isRequired,
  created: string.isRequired,
};

export const DonationGroup = {
  title: string.isRequired,
  sum: number.isRequired,
};

export const DonationTransfer = {
  id: number.isRequired,
  created: string.isRequired,
  user: number.isRequired,
  amount: number.isRequired,
  transferId: string,
};
