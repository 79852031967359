import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications/dist/ToastProvider";
import moment from "moment";

import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import BaseForm from "components/forms/Base";
import FormControl from "components/forms/shared/Control";
import FormGroup from "components/forms/shared/FormGroup";
import HorizontalContainer from "components/forms/shared/HorizontalContainer";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import DatePicker from "components/forms/datetime/DatePicker";
import { updateProject } from "features/projects/thunks";
import { isClass } from "utils/projects";

function ProjectScheduleForm({ project, closeModal, ...props }) {
  /**
   * Form to update the schedule dates and description of a project.
   *
   * Different for classes vs projects. For projects we do not have start/end date that
   * is shown on this form. For classes we combine both the schedule and the description
   * into a single form, since they are displayed together.
   */

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [formError, setFormError] = useState(null);
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm();

  async function onSubmit(data) {
    if (moment.isMoment(data.startDate))
      data.startDate = data.startDate.format("YYYY-MM-DD");
    else if (data.startDate === "") data.startDate = null;

    if (moment.isMoment(data.endDate))
      data.endDate = data.endDate.format("YYYY-MM-DD");
    else if (data.endDate === "") data.endDate = null;

    // Validate that the end date is after the start date.
    if (data.startDate !== null && data.endDate !== null)
      if (data.startDate > data.endDate) {
        return setFormError("End date must be after start date.");
      }

    try {
      setFormError(null);
      const action = await dispatch(
        updateProject({ projectId: project.id, payload: data })
      );

      if (action.type === "UPDATE_PROJECT/rejected") {
        setFormError("Error saving schedule");
      } else {
        closeModal();
        addToast("Project updated", { appearance: "success" });
      }
    } catch (err) {
      setFormError("Error saving schedule");
    }
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      {isClass(project) && (
        <HorizontalContainer>
          <FormGroup label="Start Date" errors={errors.startDate}>
            <Controller
              as={DatePicker}
              name="startDate"
              control={control}
              isInvalid={errors.startDate !== undefined}
              initialValue={project.startDate}
              defaultValue={project.startDate}
              utc
            />
          </FormGroup>
          <FormGroup label="End Date" errors={errors.endDate}>
            <Controller
              as={DatePicker}
              name="endDate"
              control={control}
              initialValue={project.endDate}
              defaultValue={project.endDate}
              isInvalid={errors.endDate !== undefined}
              utc
            />
          </FormGroup>
        </HorizontalContainer>
      )}
      <FormGroup
        label={isClass(project) && "Description"}
        errors={errors.description}
      >
        <Controller
          as={<FormControl as="textarea" />}
          rules={{ required: true }}
          name="description"
          control={control}
          defaultValue={
            project.description ||
            "Live class meets every Monday 1-2pm PDT. Review sessions meet every Monday 2-4pm."
          }
          isInvalid={errors.description !== undefined}
        />
      </FormGroup>
      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Save</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ProjectScheduleForm.propTypes = {
  /** The template value, if there was one selected.  */
  template: PropTypes.number,

  /** Function to close the parent modal. */
  closeModal: PropTypes.func,
};

ProjectScheduleForm.defaultProps = {
  template: null,
  closeModal: () => {},
};

export default ProjectScheduleForm;
