import React from "react";
import { number } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

function ActionsContainer(props) {
  /**
   * An absolute container to have controls that offer modification to data.
   *
   * This is used when we want to append a container to a parent relative element that
   * allows the user to perform actions on that data.
   */

  return <Wrapper {...props}>{props.children}</Wrapper>;
}

ActionsContainer.propTypes = {
  /** Placement of the container. */
  bottom: number,
};

ActionsContainer.defaultProps = {
  bottom: -50,
};

export default ActionsContainer;
