import React from "react";
import styled from "styled-components";
import { shape } from "prop-types";
import { useModal } from "react-modal-hook";
import Moment from "react-moment";

import ModifyButton from "components/buttons/Modify";
import ActionContainer from "components/containers/Actions";
import CopyHeader from "components/text/CopyHeader";
import FormModal from "components/modals/Form";
import ProjectScheduleForm from "features/projects/forms/Schedule";
import { Project } from "types";
import { isClass } from "utils/projects";

const StyledDescriptionSection = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    flex-basis: 50%;
  }
`;

function ProjectDescriptionSection({
  shouldViewAdminItems,
  project,
  ...props
}) {
  /** A section to handle displaying and updating the project description. */

  const [showProjectScheduleModal, hideProjectScheduleModal] = useModal(() => {
    return (
      <FormModal
        onHide={hideProjectScheduleModal}
        title={isClass(project) ? "class schedule" : "logline"}
      >
        <ProjectScheduleForm
          project={project}
          closeModal={hideProjectScheduleModal}
        />
      </FormModal>
    );
  }, [project]);

  function renderDateRange() {
    /** Show the dates that the class is active. */
    return (
      <p className="mb-0">
        <Moment format="MMMM D">{project.startDate}</Moment> to&nbsp;
        <Moment format="MMMM D YYYY">{project.endDate}</Moment>
      </p>
    );
  }

  return (
    <StyledDescriptionSection {...props}>
      {project.description ? (
        <div>
          <CopyHeader>
            {project.startDate && project.endDate && renderDateRange()}
          </CopyHeader>
          <CopyHeader className="position-relative">
            {project.description}
          </CopyHeader>
        </div>
      ) : shouldViewAdminItems ? (
        <CopyHeader>Your project description is currently empty.</CopyHeader>
      ) : (
        <CopyHeader>Project owner didn't provide a description yet.</CopyHeader>
      )}

      {shouldViewAdminItems && (
        <ActionContainer>
          <ModifyButton onClick={showProjectScheduleModal} />
        </ActionContainer>
      )}
    </StyledDescriptionSection>
  );
}

ProjectDescriptionSection.propTypes = {
  /** The project whose description we're rendering. */
  project: shape(Project).isRequired,
};

export default ProjectDescriptionSection;
