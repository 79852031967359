import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BaseModal from "components/modals/Base";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormConfirmDangerButton from "components/buttons/forms/FormConfirmDanger";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";

const StyledModalHeader = styled.div`
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.06em;
  color: ${(props) => props.theme.yellow};
  transform: matrix(1, 0.03, -0.06, 1, 0, 0);
  text-align: start !important;
  padding: 1.5rem 1.5rem 0px 1.5rem;
  margin-top: 1rem;
`;

const StyledFormSubmitContainer = styled(FormSubmitContainer)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 10px;
  }
`;

function ConfirmActionModal({
  confirmAction,
  title,
  isLoading,
  confirmText,
  confirmIcon,
  declineText,
  actionContainerProps,
  confirmButtonProps,
  errorMessage,
  isDanger,
  ...props
}) {
  // A generic modal for confirming an action, usually .
  const ConfirmButton = isDanger ? FormConfirmDangerButton : FormPrimaryButton;
  return (
    <BaseModal {...props}>
      {title && <StyledModalHeader> {title} </StyledModalHeader>}
      <div className="p-4">{props.children}</div>
      <StyledFormSubmitContainer
        errorText={errorMessage}
        {...actionContainerProps}
      >
        <FormSecondaryButton onClick={props.onHide} className="mr-auto">
          {declineText}
        </FormSecondaryButton>
        <ConfirmButton
          onClick={confirmAction}
          isLoading={isLoading}
          {...confirmButtonProps}
        >
          {confirmIcon && <FontAwesomeIcon icon={confirmIcon} />} {confirmText}
        </ConfirmButton>
      </StyledFormSubmitContainer>
    </BaseModal>
  );
}

ConfirmActionModal.propTypes = {
  // The action that should be sent if the user is wanting to confirm the deletion of data.
  confirmAction: PropTypes.func.isRequired,

  // Since the action happens outside of the modal, we need to pass in if it's loading.
  isLoading: PropTypes.bool.isRequired,

  // The title shown for the modal.
  title: PropTypes.string,

  // Additional props for the action container.
  actionContainerProps: PropTypes.object,

  // Additional props to be passed to the confirm action button.
  confirmButtonProps: PropTypes.object,

  // Text to display on the confirm button.
  confirmText: PropTypes.string,

  // Text to display for declining the action.
  declineText: PropTypes.string,

  // An error message to show in case something goes wrong.
  errorMessage: PropTypes.string,

  // Optionally pass in a fontawesome icon to render.
  confirmIcon: PropTypes.object,

  // Determine if the modal should be treated as a "danger" modal, which has different styling.
  isDanger: PropTypes.bool,
};

ConfirmActionModal.defaultProps = {
  confirmText: "Yes, delete",
  declineText: "Cancel",
  errorMessage: null,
  isDanger: false,
};

export default ConfirmActionModal;
