import React from "react";
import { useSelector } from "react-redux";

import ProjectSecondaryNavigation from "features/projects/components/SecondaryNavigation";

function ProjectDashboardRoute(props) {
  /** Base route for the user-facing project dashboard.  */

  const project = useSelector((state) => state.projects.detail);

  return (
    <div>
      <ProjectSecondaryNavigation
        project={project}
        activePathname={props.location.pathname}
      />
      {props.children}
    </div>
  );
}

export default ProjectDashboardRoute;
