import React from "react";
import { string } from "prop-types";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import DefaultProjectPoster from "images/thumbnail_project.png";
import PosterMask from "images/masks/project.svg";

const StyledImage = styled(Image)`
  mask-image: url(${PosterMask});
  mask-size: 100%;
  mask-repeat: round;

  // Need a little bit of margin or else the rotate cuts off the image.
  margin-top: 3px;
`;

function TileImage(props) {
  /** Thumbnail image for a project, which also might have stickers. */

  const src = props.src === null ? DefaultProjectPoster : props.src;

  return <StyledImage fluid {...props} src={src} />;
}

TileImage.propTypes = {
  src: string,
};

TileImage.defaultProps = {
  src: DefaultProjectPoster,
};

export default TileImage;
