import React from "react";
import styled from "styled-components";

import CarouselNavigationButton from "../NavigationButton";
import IconNext from "images/icons/next.svg";

const StyledImg = styled.img`
  width: 100px;
`;

function CarouselNextButton() {
  return (
    <CarouselNavigationButton>
      <StyledImg src={IconNext} />
    </CarouselNavigationButton>
  );
}

export default CarouselNextButton;
