export function setFieldErrors(action, setError) {
  /* Renders the field errors from the action response from server.

    :param action: The redux action returned.
    :param setError: The set error action for the react hook form.
  */
  if (typeof action.payload.data === "object" && action.payload.data !== null) {
    for (let fieldKey in action.payload.data) {
      setError(fieldKey, {
        type: "manual",
        message: action.payload.data[fieldKey],
      });
    }
  }
}

export function handleResponseError(response, setFormError, setFieldError) {
  /**
   * Check the response and set the form error appropriately.
   *
   * This is the handler used for rtk-query mutations, however if there is an action not using
   * rtk-query it generally uses just `setFieldErrors`.
   *
   * setFormError: Set a general form error which isn't associated with an individual field.
   * setFieldError: Set an individual field error.
   */

  const { error } = response;

  if (error) {
    const { data: errorData } = error;

    if (typeof errorData !== "string") {
      if (errorData?.detail) setFormError(errorData.detail);

      for (let fieldKey in errorData) {
        setFieldError(fieldKey, {
          type: "manual",
          message: errorData[fieldKey],
        });
      }
    } else {
      setFormError("An error has occurred.");
    }

    return true;
  }

  return false;
}

export function generateFormData(data) {
  /** Convert data of a form to be form data. Used when we have a file field in a form. */
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      for (const a of data[key]) {
        formData.append(key, a);
      }
    } else formData.append(key, data[key]);
  });
  return formData;
}

export function isOverThreshold(size) {
  /** Determine if a file should be uploaded through multipart. */
  const mb = 1048576;
  const maxMb = 10;
  const thresholdFileSize = mb * maxMb;
  return size > thresholdFileSize;
}
