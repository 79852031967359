import React, { useState } from "react";
import { bool, func, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { navigate } from "@reach/router";

import BaseForm from "components/forms/Base";
import FormControl from "components/forms/shared/Control";
import FormGroup from "components/forms/shared/FormGroup";
import Select from "components/controls/Select";
import Checkbox from "components/controls/Checkbox";
import InfoTooltip from "components/tooltips/Info";
import ModalBackButton from "components/modals/BackButton";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import useRegistrationTierChoices from "hooks/RegistrationTierChoices";
import useSetFieldErrors from "hooks/SetFieldErrors";
import { createBucket, updateBucket } from "features/buckets/thunks";
import { Bucket } from "types";
import { bucketType } from "utils/enums";
import { canUploadPremiumContent } from "utils/permissions";
import { getProjectBaseUrl } from "utils/projects";

function BucketForm({
  bucket,
  backAction,
  closeModal,
  showAllFields,
  navigateAfterCreate,
  ...props
}) {
  /**
   * Form to create or update a bucket.
   *
   * When creating a new bucket, some of the initial values will be passed in through the "bucket"
   * prop, and then the user will fill in the other data which isn't derived before opening the
   * form. Attributes such as public or registration tiers are typically "chosen" indirectly before
   *  opening up the form.
   */

  const dispatch = useDispatch();
  const project = useSelector((state) => state.projects.detail);
  const { user } = useSelector((state) => state.account);
  const { addToast } = useToasts();
  const {
    handleSubmit,
    control,
    errors,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: bucket,
  });
  const tierChoices = useRegistrationTierChoices();
  const setFieldErrors = useSetFieldErrors();
  const [formError, setFormError] = useState();
  const canUploadLesson = canUploadPremiumContent(user);

  const watchIsPublic = watch("isPublic", true);
  const watchKind = watch("kind", null);

  const isUpdating = "id" in bucket;

  const contentUploadTypes = [bucketType.lessons, bucketType.animatics];
  const isContentUpload = isUpdating
    ? contentUploadTypes.includes(bucket.kind)
    : contentUploadTypes.includes(watchKind);

  // Determine if the switch for allowing user create should be shown.
  const shouldShowAllowUserCreate = bucket.kind !== bucketType.lessons;

  async function onSubmit(data) {
    /**
     * Here we'll submit the bucket data for creation/updating.
     * There is a bit of non-standard-ness here as the "bucket" data passed in when creating isn't
     * actually on the form. The registration tiers and isPublic are passed in from previous
     * decisions, rather than deciding that here on the bucket form.
     */
    setFormError(null);
    data.project = project.id;

    // Extra check that content upload buckets always have certain properties.
    if (isContentUpload) {
      data.registrationTiers = [];
    }

    // When a bucket is saved as public, it can't be limited to tier.
    if (data.isPublic) data.registrationTiers = [];

    const payload = {
      ...bucket,
      ...data,
    };
    let actionPayload = { payload };
    if (isUpdating) actionPayload.bucketId = bucket.id;

    const _action = isUpdating ? updateBucket : createBucket;
    const action = await dispatch(_action(actionPayload));
    if (action.type.includes("rejected")) {
      setFormError(`Error ${isUpdating ? "updating" : "creating"} bucket.`);
      setFieldErrors();
    } else if (action.type.includes("fulfilled")) {
      addToast(`Page ${isUpdating ? "updated" : "created"}`, {
        appearance: "success",
      });
      const url = `${getProjectBaseUrl(project)}/${action.payload.slug}`;
      if (navigateAfterCreate) navigate(url);
      closeModal();
    }

    return action;
  }

  function renderTypeField() {
    // A bucket's type should not be changed after creation due to the connected data.
    if (isUpdating) return null;

    let choices = [
      {
        label: "Text Only (Feed)",
        value: bucketType.textOnly,
      },
      {
        label: "Uploads (Grid)",
        value: bucketType.images,
      },
      {
        label: "Sprint Animatics",
        value: bucketType.animatics,
      },
    ];

    if (canUploadLesson)
      choices.push({
        label: "Sprint Lessons",
        value: bucketType.lessons,
      });

    return (
      <FormGroup
        label="Type"
        errors={errors.kind}
        tooltipText="Upload bucket allows uploads of images or other files (video, pdf, etc) and the feed bucket type allows for messages to be shared."
      >
        <Controller
          render={({ onChange }) => (
            <Select
              options={choices}
              defaultValue={
                bucket.kind &&
                choices.find((choice) => choice.value === bucket.kind)
              }
              onChange={(selected) => onChange(selected.value)}
            />
          )}
          rules={{ required: true }}
          name="kind"
          control={control}
          isInvalid={errors.kind !== undefined}
        />
      </FormGroup>
    );
  }

  function getAllowUserCreateLabel() {
    // Returns the label to show depending on the bucket type and if the setting is enabled.
    switch (bucket.kind) {
      case bucketType.images:
        return "Allow users to create uploads";
      case bucketType.textOnly:
        return "Allow users to create new messages";
      default:
        return "Allow users to upload content to this page";
    }
  }

  function renderExtraFields() {
    /**
     * There are certain fields that should not be available when editing or creating
     * through the tier settings, because the registration tier and public are already
     * set through where you're choosing to edit/add the bucket in the admin section.
     */

    if (!showAllFields) return null;

    function renderDefaultValues() {
      return bucket.registrationTiers.map((tierId) => {
        const fullTier = project.tiers.find((tier) => tier.id === tierId);
        return { value: fullTier.id, label: fullTier.title };
      });
    }

    const publicToggleLabel = (
      <>
        <span className="mr-2">Allow public viewing</span>
        <InfoTooltip text="By making the page public, anyone with access to the project will see it." />
      </>
    );

    const isPublicLabel = (
      <>
        <span>Grant access to story sprint subscribers</span>
        <InfoTooltip
          text="Non-subscribers will see a preview, but can't play videos without subscribing"
          iconProps={{
            opacity: 1,
            className: "ml-1",
          }}
        />
      </>
    );

    const subscriptionFormGroup = (
      <FormGroup
        errors={errors.isPublic}
        tooltipText="In addition to other permissions, any Story Sprint subscriber will explicitly have access to the page as well."
      >
        <Controller
          as={
            <Checkbox
              label={isPublicLabel}
              defaultChecked={false}
              defaultValue={bucket.isPublic || false}
            />
          }
          name="isPublic"
          control={control}
        />
      </FormGroup>
    );

    // Content upload buckets should only have the subscription checkbox.
    if (isContentUpload) return subscriptionFormGroup;

    return (
      <>
        <FormGroup errors={bucket.isPublic.errors}>
          <Controller
            render={(props) => (
              <Checkbox
                label={publicToggleLabel}
                defaultChecked={bucket.isPublic}
                defaultValue={bucket.isPublic}
                name="isPublic"
                {...props}
              />
            )}
            name="isPublic"
            control={control}
          />
        </FormGroup>
        {!watchIsPublic && (
          <>
            {subscriptionFormGroup}
            <FormGroup
              label="Registration tiers"
              errors={bucket.registrationTiers.errors}
            >
              <Controller
                render={({ onChange, ref }) => (
                  <Select
                    options={tierChoices}
                    isMulti
                    ref={ref}
                    closeMenuOnSelect={false}
                    onChange={(objects) =>
                      onChange(objects.map((obj) => obj.value))
                    }
                    defaultValue={renderDefaultValues()}
                    placeholder="Select which tiers to limit viewing to"
                  />
                )}
                control={control}
                name="registrationTiers"
              />
            </FormGroup>
          </>
        )}
      </>
    );
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Name" errors={errors.title}>
        <Controller
          autoFocus
          as={FormControl}
          name="title"
          control={control}
          isInvalid={errors.title !== undefined}
          rules={{ required: true, maxLength: 32 }}
        />
      </FormGroup>
      {renderTypeField()}

      {shouldShowAllowUserCreate && (
        <FormGroup errors={errors.allowUserCreate}>
          <Controller
            as={
              <Checkbox
                containerProps={{ className: "mt-3" }}
                label={getAllowUserCreateLabel()}
                defaultChecked={bucket.allowUserCreate}
                defaultValue={bucket.allowUserCreate}
              />
            }
            name="allowUserCreate"
            control={control}
          />
        </FormGroup>
      )}

      {renderExtraFields()}

      <FormSubmitContainer errorText={formError}>
        {backAction ? <ModalBackButton onClick={backAction} /> : <div />}
        <FormPrimaryButton isLoading={isSubmitting}>
          {isUpdating ? "Save" : "Create"}
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

BucketForm.propTypes = {
  /** Not necessarily a full bucket object, but does provide default values for the bucket form. */
  bucket: shape(Bucket).isRequired,

  /** Function to close the modal the form is in. */
  closeModal: func.isRequired,

  /** Sometimes we supply the registration tier & public previous to the form. */
  showAllFields: bool,

  /** Determines if the user should be navigated to the newly created bucket. */
  navigateAfterCreate: bool,

  /** Action to take the user to the previous section of a modal, if applicable. */
  backAction: func,
};

BucketForm.defaultProps = {
  closeModal: () => {},
  showAllFields: false,
  navigateAfterCreate: false,
};

export default BucketForm;
