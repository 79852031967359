import styled from "styled-components";
import Image from "react-bootstrap/Image";

import { string } from "prop-types";

const StyledImage = styled(Image)`
  mask-image: url(${(props) => props.mask});
  mask-size: 100%;
  mask-repeat: round;
  max-width: 100%;
`;

function MaskImage(props) {
  /** Provide a general image that has a masked shape. */
  return <StyledImage fluid {...props} />;
}

MaskImage.propTypes = {
  /** Source of the image that should be masked. */
  src: string.isRequired,

  /** The image shape that should be used as a mask. */
  mask: string.isRequired,
};

export default MaskImage;
