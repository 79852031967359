import React from "react";
import { bool } from "prop-types";

import FormButton from "../Base";

function FormConfirmDangerButton({ children, isLoading, ...props }) {
  /** Button used on a form to confirm a destructive action. */

  return (
    <FormButton variant="danger" type="submit" disabled={isLoading} {...props}>
      {children}
    </FormButton>
  );
}

FormConfirmDangerButton.propTypes = {
  /** Shows if the button is currently handling an async action. */
  isLoading: bool,
};

FormConfirmDangerButton.defaultProps = {
  isLoading: false,
};

export default FormConfirmDangerButton;
