import React from "react";
import { func, shape } from "prop-types";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import ModalListGroup from "components/listGroups/ModalActions";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import DeleteIcon from "components/icons/Delete";
import EditIcon from "components/icons/Edit";
import ReplyIcon from "components/icons/Reply";

import BaseModal from "components/modals/Base";
import { Comment } from "types";

const StyledListGroupItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledReplyIcon = styled(ReplyIcon)`
  padding: 2px;
`;

function CommentOptionsModal({
  comment,
  handleEdit,
  handleDelete,
  handleReply,
  ...props
}) {
  /** Allow the user to choose which action they'd like to take on a comment. */
  return (
    <BaseModal {...props}>
      <Modal.Body>
        <ModalListGroup>
          <StyledListGroupItem onClick={handleEdit}>
            Edit <EditIcon includeSurround={false} />
          </StyledListGroupItem>
          <StyledListGroupItem onClick={handleDelete}>
            Delete <DeleteIcon includeSurround={false} />
          </StyledListGroupItem>
          <StyledListGroupItem onClick={handleReply}>
            Reply <StyledReplyIcon />
          </StyledListGroupItem>
        </ModalListGroup>
      </Modal.Body>
    </BaseModal>
  );
}

CommentOptionsModal.propTypes = {
  /** The comment that the user is acting on. */
  comment: shape(Comment).isRequired,

  /** Action to hide the modal. */
  onHide: func.isRequired,

  /** Action to take when the user wants to edit the comment. */
  handleEdit: func.isRequired,

  /** Action to take when the user wants to edit the delete the comment. */
  handleDelete: func.isRequired,

  /** Action to take when the user wants to reply to the comment. */
  handleReply: func.isRequired,
};

export default CommentOptionsModal;
