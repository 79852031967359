import { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";
import LoadingContainer from "components/loading/Container";
import FormErrorText from "components/forms/shared/ErrorText";
import {
  useCancelSubscriptionMutation,
  useGetSubscriptionDataQuery,
} from "services/projectCity";

function CancelSubscriptionModal(props) {
  const user = useSelector((state) => state.account.user);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const lengthErrorMessage = "Max length 100 characters";

    if (reason && reason.length >= 100 && error !== lengthErrorMessage) {
      setError(lengthErrorMessage);
    } else if (reason && reason.length < 100 && error === lengthErrorMessage) {
      setError(null);
    }
  }, [reason]);

  const {
    data,
    isLoading: isSubscriptionLoading,
  } = useGetSubscriptionDataQuery({
    subscriptionId: user.subscription.subscriptionId,
  });

  const nextBillingDateFormatted = moment
    .unix(data?.currentPeriodEnd)
    .format("ll");

  const [
    cancelSubscription,
    { isLoading: isCancelLoading },
  ] = useCancelSubscriptionMutation();

  const { addToast } = useToasts();

  async function handleCancelSubscription() {
    setError(null);

    if (!reason)
      return setError("Please select a reason to continue cancellation.");

    const response = await cancelSubscription({
      subscriptionId: user.subscription.subscriptionId,
      reason,
    });
    if (response.error) {
      setError(
        "An error has occurred. Please try again later or contact support."
      );
    } else {
      props.onHide();
      addToast(
        `Success! Your subscription will cancel on ${nextBillingDateFormatted}.`,
        {
          appearance: "success",
        }
      );
    }
  }

  const isDisabled = isCancelLoading || reason === "" || reason === null;

  return (
    <ConfirmActionModal
      isDanger
      title="Cancel subscription"
      confirmAction={handleCancelSubscription}
      confirmText="Cancel subscription"
      declineText="Nevermind"
      isLoading={isDisabled}
      size="lg"
      {...props}
    >
      {isSubscriptionLoading ? (
        <LoadingContainer />
      ) : (
        <>
          <ModalSummaryText className="mb-3">
            Are you sure you want to cancel your subscription? Your subscription
            will remain active until the end of your billing cycle on{" "}
            <b>{nextBillingDateFormatted}</b>, however you will not be charged
            again.
          </ModalSummaryText>
          <FormControl
            className="mt-2"
            maxLength={100}
            placeholder="Please enter cancellation reason"
            onChange={(e) => setReason(e.target.value)}
          />
          <FormErrorText className="mt-3" text={error} />
        </>
      )}
    </ConfirmActionModal>
  );
}

export default CancelSubscriptionModal;
