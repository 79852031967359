import React from "react";
import Container from "react-bootstrap/Container";
import { useModal } from "react-modal-hook";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Body from "components/text/Body";
import TextOnlyMessage from "components/textOnlyMessages/Card";
import Button from "components/buttons/Button";
import BucketActionsSection from "features/buckets/components/Actions";
import BucketBase from "features/buckets/components/Base";
import TextOnlyModal from "components/textOnlyMessages/modals/TextOnlyMessage";
import { isProjectAdmin } from "utils/projects";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.$isAdmin ? "space-between" : "flex-end"};
  margin-bottom: ${(props) => props.theme.vSpacingSm};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingSm};
  }
`;

function TextOnlyBucket({ bucket }) {
  /** Display list of text only messages in a project bucket.  */

  const project = useSelector((state) => state.projects.detail);
  const user = useSelector((state) => state.account.user);

  const [showModal, hideModal] = useModal(() => (
    <TextOnlyModal onHide={hideModal} />
  ));

  function renderItems() {
    /** Display the text messages. */

    if (!bucket.messages?.length) {
      return <Body className="text-center">There are no messages yet.</Body>;
    }

    return bucket?.messages.map((textOnlyMessage) => {
      const messageObject = {
        text: textOnlyMessage.message,
        ...textOnlyMessage,
      };
      return <TextOnlyMessage message={messageObject} />;
    });
  }

  const isAdmin = isProjectAdmin(user, project);

  return (
    <BucketBase>
      <Container>
        <HeaderContainer $isAdmin={isAdmin}>
          {isAdmin && (
            <BucketActionsSection
              pushRight={false}
              bucket={bucket}
              className="mr-1"
            />
          )}
          {(bucket.allowUserCreate || isAdmin) && (
            <Button onClick={showModal}>New Message</Button>
          )}
        </HeaderContainer>
        <div>{renderItems()}</div>
      </Container>
    </BucketBase>
  );
}

export default TextOnlyBucket;
