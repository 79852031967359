import { string, number, shape, arrayOf } from "prop-types";

export const Contest = {
  /** The individual id of the contest. */
  id: number.isRequired,

  /** The descriptive title of the contest. */
  title: string.isRequired,

  /** The image to show for the contest. */
  thumbnail: string.isRequired,

  /** The datetime that the contest will start on. */
  startDate: string.isRequired,

  /** The datetime that the contest will end on. */
  endDate: string.isRequired,

  /** A short description to give a headline for a contest. */
  description: string.isRequired,

  /** A short description to show with the contest rules. */
  rulesDescription: string.isRequired,

  /** List of rules for the contest. */
  rules: arrayOf(
    shape({
      id: number.isRequired,
      text: string.isRequired,
    })
  ).isRequired,
};

export const ContestEntry = {
  /** Identifier for the contest entry. */
  id: number.isRequired,

  /** A title given to the contest entry. */
  title: string.isRequired,

  /** User object that submitted the contest entry. */
  createdBy: shape({
    id: number.isRequired,
    name: string.isRequired,
    username: string.isRequired,
  }).isRequired,

  /** The file location for the contest submission. */
  file: string.isRequired,

  /** Image to show for the contest submission. */
  thumbnail: string,
};
