import React from "react";
import styled from "styled-components";

import Button from "components/buttons/Button";

const StyledButton = styled(Button)`
  text-transform: uppercase;
  padding: 14px;
  margin-top: ${(props) => props.theme.vSpacingMd};
`;

function TierBucketSettingButton(props) {
  // Styled button for usage in the tier bucket settings containers.
  return (
    <StyledButton block {...props}>
      Add/Remove Pages
    </StyledButton>
  );
}

export default TierBucketSettingButton;
