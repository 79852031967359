import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import moment from "moment";

import Header from "components/text/Header";
import CopyHeader from "components/text/CopyHeader";
import LoadingContainer from "components/loading/Container";
import FormModal from "components/modals/Form";
import ProjectCityButton from "components/buttons/Button";
import ContentUploadIcon from "features/sprint/components/UploadIcon";
import SprintHeaderContainer from "features/sprint/components/HeaderContainer";
import ContestEntryList from "features/contests/components/ContestEntryList";
import ContestEntryForm from "features/contests/forms/ContestEntry";
import useIsAuthenticated from "hooks/IsAuthenticated";
import useFeedShare from "hooks/FeedShare";
import projectCityApi, {
  useGetContestQuery,
  useGetContestEntriesListQuery,
} from "services/projectCity";
import AuthenticationModal from "features/accounts/modals/Authentication";
import ContestRulesModal from "features/contests/modals/Rules";

const ListContainer = styled.div`
  padding: ${(props) => props.theme.vSpacingLg};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingMd};
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingLg};
  }
`;

function ContestDetailRoute({ id: contestId }) {
  /** Route to show detail about a single contest. */

  const { data: contest, isLoading: isLoadingContest } = useGetContestQuery({
    contestId,
  });
  const {
    data: contestEntries,
    isLoading: isContestEntriesLoading,
  } = useGetContestEntriesListQuery({ contest: contestId });
  const isAuthenticated = useIsAuthenticated();
  const [contestEntry, setContestEntry] = useState(null);
  const showConfirmShareModal = useFeedShare(
    "contestentry",
    "Contest entry submitted!",
    contestEntry && contestEntry.id
  );

  const [
    loadNextPage,
    { isFetching: isPageLoading },
  ] = projectCityApi.endpoints.getNextContestEntryPage.useLazyQuery();

  function handleAfterSubmit(contestEntry) {
    /** Action to run after the contest entry has been created. */
    setContestEntry(contestEntry);
    showConfirmShareModal();
  }

  const [showContestEntryModal, hideContestEntryModal] = useModal(() => (
    <FormModal title="Submit contest entry" onHide={hideContestEntryModal}>
      <ContestEntryForm
        contestId={contestId}
        closeModal={hideContestEntryModal}
        afterSubmit={handleAfterSubmit}
      />
    </FormModal>
  ));
  const [showAuthModal, hideAuthModal] = useModal(() => (
    <AuthenticationModal show={true} onHide={hideAuthModal} />
  ));
  const [showRulesModal, hideRulesModal] = useModal(
    () => <ContestRulesModal onHide={hideRulesModal} contest={contest} />,
    [contest]
  );

  if (isLoadingContest) return <LoadingContainer text="loading contest..." />;

  const canUpload =
    new Date(contest.endDate) > new Date() &&
    new Date(contest.startDate) <= new Date();

  function handleSubmitEntryClick() {
    if (isAuthenticated) showContestEntryModal();
    else showAuthModal();
  }

  return (
    <Wrapper>
      <SprintHeaderContainer>
        {canUpload ? (
          <ContentUploadIcon onClick={handleSubmitEntryClick} />
        ) : (
          <div />
        )}
        <div className="text-center">
          <Header top={contest.title} bottom="contest" />
          {contest.description && (
            <CopyHeader className="mt-3">{contest.description}</CopyHeader>
          )}
          <CopyHeader className="mt-1" size="sm" color="lime">
            Contest submissions will be accepted until{" "}
            <b>{moment(contest.endDate).format("ll")} @ midnight</b>
          </CopyHeader>
        </div>
        <div className="pt-3">
          {contest?.rules.length > 0 && (
            <ProjectCityButton
              variant="info"
              startTilt
              onClick={showRulesModal}
            >
              View rules
            </ProjectCityButton>
          )}
        </div>
      </SprintHeaderContainer>

      <ListContainer>
        {isContestEntriesLoading ? (
          <LoadingContainer text="Loading contest entries..." />
        ) : (
          <ContestEntryList
            contestEntries={contestEntries.results}
            hasMore={contestEntries?.next !== null && !isPageLoading}
            loadMore={() =>
              loadNextPage({
                next: contestEntries?.next,
                contestId,
              })
            }
          />
        )}
      </ListContainer>
    </Wrapper>
  );
}

export default ContestDetailRoute;
