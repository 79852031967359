import { useSelector } from "react-redux";
import { canUploadPremiumContent, canReceiveDonation } from "utils/permissions";

import ManagementNavigation from "components/navbars/Management";
import { usePrefetch } from "services/projectCity";

function SprintManagementNavigation() {
  /** Render the secondary navigation for sprint content management. */

  const { user } = useSelector((state) => state.account);

  const prefetchPayments = usePrefetch("getPayoutList");
  const prefetchVideos = usePrefetch("getContentUploadList");

  // Provide data for the different navigation links.
  let navigationData = [
    {
      to: `/sprint-management/videos`,
      label: "Videos",
      onMouseEnter: () => prefetchVideos({ created_by: user.id }),
    },
  ];

  if (canUploadPremiumContent(user)) {
    navigationData.push({
      to: `/sprint-management/payments`,
      label: "Payments",
      onMouseEnter: () => prefetchPayments(),
    });
  }

  if (canReceiveDonation(user)) {
    navigationData.unshift({
      to: `/sprint-management/donations`,
      label: "Donations",
    });
  }

  return (
    <ManagementNavigation
      title="Manage Content"
      backUrl="/sprint/content"
      links={navigationData}
      activeRoute=""
    />
  );
}

export default SprintManagementNavigation;
