import React from "react";

import Body from "components/text/Body";

function EmptyDataDisplay(props) {
  /* Displays a message to the user letting them know that the section they're looking at does not
     have any data.
  */
  return (
    <Body mono color="lime" className="m-4 text-center w-100">
      {props.children}
    </Body>
  );
}

export default EmptyDataDisplay;
