import styled from "styled-components";
import BulletDash from "images/icons/bullet-point.svg";

const StyledList = styled.ul`
  list-style-image: url(${BulletDash});
  display: inline-block;
  text-align: left;
`;

function UnorderedList(props) {
  /** A general styled list. */
  return <StyledList {...props}>{props.children}</StyledList>;
}

export default UnorderedList;
