import React, { useState, useMemo } from "react";
import { bool, oneOf, shape } from "prop-types";
import { Link } from "@reach/router";
import { getProjectUrl } from "utils/projects.js";
import styled from "styled-components";
// import Image from "react-bootstrap/Image";
import moment from "moment";

import Splat from "components/images/Splat";
// import FractionalIp from "images/icons/f-ip.svg";
import { Project } from "types";
import TileFooter from "components/tiles/Footer";
import TileImageContainer from "components/tiles/ImageContainer";
import TileImage from "components/tiles/Image";
import TileOverlay from "components/tiles/Overlay";

const Wrapper = styled.div`
  ${(props) =>
    props.stagger &&
    `
    margin-top: 15px;
  `};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 26px;
    padding-right: 26px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 18px;
    padding-right: 18px;

    ${(props) =>
      props.push === "left" &&
      `
      margin-right: 25%;
      padding-left: 8px;
    `}

    ${(props) =>
      props.push === "right" &&
      `
      margin-left: 25%;
      padding-right: 8px;
    `}
  }
`;

function ProjectTile({
  project,
  fip,
  isEven,
  withRainbowBorder,
  showTeachers,
  horizontalStagger,
  ...props
}) {
  /** An individual project tile. */
  const [isHover, setIsHover] = useState(false);

  function renderProjectFooter() {
    /** Show the project owner's name or username. */
    let teacherDisplay = "";

    project.teachers
      ? project.teachers.forEach((teacher) => {
          const prefix = teacherDisplay === "" ? "" : " + ";
          teacherDisplay += `${prefix} ${teacher.name}`;
        })
      : (teacherDisplay = project.owner.name);

    const bottom =
      showTeachers && project.teachers.length > 0
        ? `by ${teacherDisplay}`
        : null;

    return <TileFooter hover={isHover} top={project.title} bottom={bottom} />;
  }

  const { earliestStartDate } = project;
  const isComingSoon = useMemo(() => {
    const daysUntilStart = parseInt(
      moment.duration(moment(earliestStartDate).diff(moment())).asDays()
    );
    return daysUntilStart > 0 && daysUntilStart < 14;
  });

  function renderBanner() {
    let banner = null;
    let position = "bottom-right";
    if (fip) banner = null;
    //<Image fluid src={FractionalIp} alt="" />;
    else if (isComingSoon) {
      banner = (
        <Splat
          positiveRotate={isEven}
          top={moment(earliestStartDate).format("MMM")}
          bottom={moment(earliestStartDate).format("DD")}
        />
      );
      position = "top-right";
    }

    return banner && <TileOverlay position={position}>{banner}</TileOverlay>;
  }

  return (
    <Wrapper
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      stagger={isEven === false}
      push={props.push}
      {...props}
    >
      <Link to={getProjectUrl(project)}>
        <TileImageContainer
          withRainbowBorder={withRainbowBorder}
          hover={isHover}
          isEven={isEven}
        >
          <TileImage src={project.image} alt={project.title} />
          {renderBanner()}
        </TileImageContainer>
        {renderProjectFooter()}
      </Link>
    </Wrapper>
  );
}

ProjectTile.propTypes = {
  /** The project that we're rendering. */
  project: shape(Project).isRequired,

  /** Push the tile to the left or the right on mobile. */
  push: oneOf(["left", "right"]),

  /** The project is a fractional ip project. */
  fip: bool,

  /** Alternating styling for the project tiles. */
  isEven: bool,

  /** Apply a special rainbow border. */
  withRainbowBorder: bool,

  /** Determine if the teachers should be shown in the project footer. */
  showTeachers: bool,

  /** Stagger the tile to the left or right, depending on the `isEven` prop. */
  horizontalStagger: bool,
};

ProjectTile.defaultProps = {
  push: null,
  fip: false,
  isEven: false,
  withRainbowBorder: false,
  showTeachers: true,
  horizontalStagger: false,
};

export default ProjectTile;
