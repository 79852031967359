import { useSelector } from "react-redux";

function useIsAuthenticated() {
  /** Determine if the user is currently authenticated. */
  const accountState = useSelector((state) => state.account);

  return accountState.token !== "";
}

export default useIsAuthenticated;
