import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useModal } from "react-modal-hook";

import Button from "components/buttons/Button";
import StripeDashboardButton from "components/buttons/StripeDashboard";
import ConnectPreviewModal from "features/accounts/modals/ConnectPreview";
import ProjectEarningsModal from "features/accounts/modals/ProjectEarnings";

import { getFullName } from "utils/users";
import IconCheck from "images/icons/check.png";
import IconError from "images/icons/error.png";

const ImgWrapper = styled.div`
  width: 35px;

  img {
    width: 100%;
  }
`;

const AmountCell = styled.td`
  text-decoration: underline;
  text-underline-offset: 5px;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.lime};
  }
`;

function PaymentAccountRow({ user }) {
  // Returns a row for the user table.
  const authenticatedUser = useSelector((state) => state.account.user);
  const project = useSelector((state) => state.projects.detail);
  const { account } = user;

  // Determine if user is viewing their own row.
  const isUserRow = user.id === authenticatedUser.id;

  const [showConnectModal, hideConnectModal] = useModal(() => (
    <ConnectPreviewModal project={project} onHide={hideConnectModal} />
  ));

  const [showEarningsModal, hideEarningsModal] = useModal(() => (
    <ProjectEarningsModal
      project={project}
      user={user}
      onHide={hideEarningsModal}
    />
  ));

  function renderStripeAction() {
    const buttonProps = {
      size: "sm",
      project,
    };

    if (isUserRow) {
      return account && account.payoutsEnabled ? (
        <StripeDashboardButton {...buttonProps}>
          Stripe Dashboard <FontAwesomeIcon icon={faExternalLinkAlt} />
        </StripeDashboardButton>
      ) : (
        <Button size="sm" onClick={showConnectModal}>
          Connect Bank Account
        </Button>
      );
    }
  }

  return (
    <tr>
      <td>
        <b>{getFullName(user)}</b>
      </td>
      {user.amount ? (
        <AmountCell onClick={showEarningsModal}>
          {user.amount ? `$${(user.amount / 100).toFixed(2)}` : null}
        </AmountCell>
      ) : (
        <td />
      )}

      <td>
        {account && account.payoutsEnabled ? (
          <ImgWrapper>
            <img src={IconCheck} alt="" />
          </ImgWrapper>
        ) : (
          <ImgWrapper>
            <img src={IconError} alt="" />
          </ImgWrapper>
        )}
      </td>
      <td>{renderStripeAction()}</td>
    </tr>
  );
}

PaymentAccountRow.propTypes = {
  // The user instance that we're rendering a row for.
  user: PropTypes.object.isRequired,
};

export default PaymentAccountRow;
