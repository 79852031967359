import React, { useState } from "react";
import { shape } from "prop-types";

import { useSelector } from "react-redux";
import styled from "styled-components";
import useAuthenticationModal from "hooks/AuthModal";
import { useToasts } from "react-toast-notifications";

import FormControl from "components/forms/shared/Control";
import Button from "components/buttons/Button";
import MentionsDropDown from "components/dropdowns/mentions";
import Loading from "components/loading/Loading";
import RepliesList from "components/replies/List";
import CommentTextFull from "features/comments/components/CommentTextFull";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { useCreateCommentReplyMutation } from "services/projectCity";
import { Comment as CommentType } from "types";

import CommentActionContainer from "../ActionContainer";
import CommentTimestamp from "../Timestamp";
import EditCommentInput from "../EditCommentContainer";

const Wrapper = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  padding: ${(props) => props.theme.postPadding};
  border-top: 1px solid ${(props) => props.theme.gray2};
`;

const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ReplyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

const ReplyControl = styled(FormControl)`
  background-color: ${(props) => props.theme.gray4} !important;
`;

const ReplySubmitButton = styled(Button)`
  background-color: ${(props) => props.theme.brown};
  border-color: ${(props) => props.theme.brown};
  border-radius: 5px;
  padding: 11px;
`;

function Comment({ comment, ...props }) {
  /** Display a full individual comment. */

  const { addToast } = useToasts();
  const isAuthenticated = useIsAuthenticated();
  const loggedInUser = useSelector((state) => state.account.user);
  const showAuthModal = useAuthenticationModal();
  const [createCommentReply, { isLoading }] = useCreateCommentReplyMutation();

  const [hideReplyInput, setHideReplyInput] = useState(true);
  const [replyText, setReplyText] = useState("");
  const [edit, setEdit] = useState(false);
  const [mentionWord, setMentionWord] = useState("");
  const [mentions, setMentions] = useState([]);

  function handleChange(e) {
    /** Update the value of the reply text. */
    setReplyText(e.target.value);
    const matches = [...e.target.value.matchAll(/@[^\s]*[^\s]$/g)];
    if (matches.length) {
      const word = matches[matches.length - 1][0].slice(1);
      setMentionWord(word);
    } else {
      setMentionWord("");
    }
  }

  function handleKeyDown(e) {
    // Submit the reply if the user hits enter.
    if (e.key === "Enter") isAuthenticated ? createReply() : showAuthModal();
  }

  async function createReply() {
    if (replyText) {
      createCommentReply({
        commentId: comment.id,
        contentType: comment.contentType,
        contentObject: comment.contentObject,
        text: replyText,
      });
      setHideReplyInput(true);
      setReplyText("");
    } else {
      addToast("Provide reply message please!", { appearance: "error" });
    }
  }

  const isCommentAuthor = comment.author.id === loggedInUser.id;

  // Props that should be passed along to the action component.
  const actionProps = {
    comment,
    setHideReplyInput,
    hideReplyInput,
    setEdit,
    edit,
    isCommentAuthor,
  };

  return (
    <Wrapper {...props}>
      <CommentContainer>
        <div className="w-100">
          {edit ? (
            <EditCommentInput comment={comment} setEdit={setEdit} />
          ) : (
            <>
              <CommentTextFull
                author={comment.author}
                text={comment.text}
                mentions={comment.mentions}
              />
              <CommentTimestamp className="pt-0">
                {comment.created}
              </CommentTimestamp>
            </>
          )}
        </div>
        {!edit && <CommentActionContainer {...actionProps} />}
      </CommentContainer>
      <div className="reply-container">
        <RepliesList replies={comment.replies} />
        {!hideReplyInput && (
          <ReplyContainer {...props}>
            <ReplyControl
              autoFocus
              variant="secondary"
              className="ml-4 mr-1"
              placeholder="Write your reply..."
              value={replyText}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <ReplySubmitButton
              onClick={isAuthenticated ? createReply : showAuthModal}
            >
              {isLoading ? <Loading size="sm" /> : "Submit"}
            </ReplySubmitButton>
          </ReplyContainer>
        )}
        {mentionWord && (
          <MentionsDropDown
            isShown={true}
            username={mentionWord}
            setMentionWord={setMentionWord}
            setNewComment={setReplyText}
            newComment={replyText}
            setMentions={setMentions}
            mentions={mentions}
          />
        )}
      </div>
    </Wrapper>
  );
}

Comment.propTypes = {
  /** The comment which we're rendering.  */
  comment: shape(CommentType).isRequired,
};

export default Comment;
