import React from "react";
import styled from "styled-components";

import { TextTypes } from "types";

const StyledThumbnail = styled.h6`
  font-family: "Basis Grotesque Mono";
  font-size: 21px;
  line-height: 21px;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: ${({ theme, color }) => theme[color]};

  ${(props) =>
    props.inline &&
    `
    display: inline;
    margin-bottom: 0;
  `}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    font-size: 17px;
    line-height: 18px;
  }
`;

function Thumbnail({ children, ...props }) {
  /** A thumbnail text to show with images. */

  return <StyledThumbnail {...props}>{children}</StyledThumbnail>;
}

Thumbnail.propTypes = TextTypes;

Thumbnail.defaultProps = {
  color: "dark",
  bold: true,
  inline: false,
};

export default Thumbnail;
