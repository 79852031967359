import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { navigate } from "@reach/router";

import BaseForm from "components/forms/Base";
import FormControl from "components/forms/shared/Control";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import { axiosInstance } from "features/api";
import { resetPasswordUrl } from "features/accounts/api";
import useSetFieldErrors from "hooks/SetFieldErrors";

function ResetPasswordForm({ uid, token }) {
  const {
    handleSubmit,
    control,
    errors,
    getValues,
    formState: { isSubmitting },
    setError,
  } = useForm();
  const { addToast } = useToasts();
  const [errorText, setErrorText] = useState(null);
  const setFieldErrors = useSetFieldErrors(setError);

  const onSubmit = async (data) => {
    data.uid = uid;
    data.token = token;

    try {
      const response = await axiosInstance.post(resetPasswordUrl, data);
      addToast("Your password has been reset, you may now login.", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate("/");
      return response;
    } catch (err) {
      let text = "There was an error resetting your password.";

      if (err?.response?.data) setFieldErrors(err.response.data);

      setErrorText("There was an error resetting your password.");
    }
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Password" errors={errors.newPassword1}>
        <Controller
          as={FormControl}
          rules={{
            required: {
              value: true,
              message: "Password is required",
            },
            minLength: {
              value: 8,
              message: "Password must have at least 8 characters",
            },
            validate: {
              value: () =>
                getValues("newPassword1") === getValues("newPassword2") ||
                "Passwords do not match",
            },
          }}
          isInvalid={errors.newPassword1 !== undefined}
          name="newPassword1"
          type="password"
          control={control}
          defaultValue=""
        />
      </FormGroup>

      <FormGroup label="Password (repeat)" errors={errors.newPassword2}>
        <Controller
          as={FormControl}
          isInvalid={errors.newPassword2 !== undefined}
          name="newPassword2"
          type="password"
          control={control}
          defaultValue=""
        />
      </FormGroup>

      <FormSubmitContainer isLoading={isSubmitting} errorText={errorText}>
        <FormPrimaryButton>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ResetPasswordForm.propTypes = {
  /* A secret value that determines the user. */
  uid: PropTypes.string,

  /* A secret token to hash the password request. */
  token: PropTypes.string,
};

export default ResetPasswordForm;
