import React from "react";
import { func } from "prop-types";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  & .modal-content {
    background-color: ${(props) => props.theme.purple};
    border: 2px solid ${(props) => props.theme.purple};
    border-radius: 0px;

    @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
      padding: 30px;
    }
  }
`;

const BaseModal = ({ rounded, onHide, ...props }) => {
  /** A base modal that all of our modals should inherit from. */

  return (
    <StyledModal centered show={true} onHide={onHide} {...props}>
      {props.children}
    </StyledModal>
  );
};

BaseModal.propTypes = {
  onHide: func,
};

export default BaseModal;
