import React from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledLink = styled.a`
  font-size: 17px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.02em;
  color: ${(props) => (props.active ? props.theme.pink : props.theme.yellow)};
  border-bottom: ${(props) =>
    props.active ? "2px solid " + props.theme.yellow : ""};
  padding-bottom: 4px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.pink};
    border-bottom: ${(props) =>
      props.noHover ? "" : "2px solid " + props.theme.yellow};
  }
`;

function BucketNavigationButton({ children, to, ...props }) {
  // Button component for navigating to buckets.

  return (
    <StyledLink
      className={`${props.className} my-2`}
      onClick={() => navigate(to)}
      {...props}
    >
      {children}
    </StyledLink>
  );
}

BucketNavigationButton.propTypes = {
  /* Url that the button will navigate to. */
  to: PropTypes.string,

  /* Determines the styling of an active location button. */
  active: PropTypes.bool,
  noHover: PropTypes.bool,
};

export default BucketNavigationButton;
