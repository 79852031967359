import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import FormSecondaryButton from "components/buttons/forms/FormSecondary";

function ModalBackButton(props) {
  // A button used to go back to the previous section of a modal.
  return (
    <FormSecondaryButton {...props}>
      <FontAwesomeIcon icon={faChevronLeft} /> Back
    </FormSecondaryButton>
  );
}

ModalBackButton.propTypes = {
  // The action to take the user back to the previous modal section.
  onClick: PropTypes.func.isRequired,
};

export default ModalBackButton;
