import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingLg};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingSm};
  }
`;

function SprintWrapper(props) {
  /** Shared wrapper for sprint pages. */
  return <Wrapper {...props}>{props.children}</Wrapper>;
}

export default SprintWrapper;
