import slugify from "slugify";

import DefaultVideoImage from "images/default-video-thumbnail.jpg";
import JoinLiveImage from "images/join_live.png";

export function getContentUploadUrl(contentUpload) {
  /** Return the application url for a given content upload. */
  return `/sprint/content/${contentUpload.id}/${slugify(contentUpload.title, {
    lower: true,
  })}`;
}

export function getContentUploadImage(contentUpload) {
  if (contentUpload.url && !contentUpload.file && !contentUpload.thumbnail)
    return JoinLiveImage;

  return (
    contentUpload.thumbnail?.url || contentUpload.thumbnail || DefaultVideoImage
  );
}
