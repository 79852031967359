import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import DashboardContainer from "components/containers/Dashboard";
import Body from "components/text/Body";
import Header from "components/text/Header";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import Sticker from "components/icons/Sticker";
import StarLeft from "images/star-left.svg";
import theme from "theme";

// import NewsletterSignup from "features/newsletter/components/About";
// import StarLeft2 from "images/star-left-2.svg";
// import StarRight from "images/star-right.svg";
// import StarAlt from "images/star-alt.svg";
// import StarAlt2 from "images/star-alt-2.svg";
// import ArrowDown from "images/arrow-down-squiggly.svg";
// import FipLogo from "images/icons/f-ip.svg";
// import EggLady from "images/egg-lady.svg";

const IntroRow = styled(Row)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: -${(props) => props.theme.vSpacingXl};
  }
`;

const HighlightSection = styled.div`
  background-color: ${(props) => props.theme.green};
  transform: rotate(2deg);
  padding: ${(props) => props.theme.vSpacingXs};
  text-align: center;
  margin-top: ${(props) => props.theme.vSpacingMd};

  > p {
    font-weight: bold;
    letter-spacing: -0.03em;
  }
`;

const StyledBody = styled(Body)`
  font-size: 36px;
  line-height: 48px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    font-size: 21px;
    line-height: 26px;
  }
`;

const RowSection = styled(Row)`
  margin-top: ${(props) => props.theme.vSpacingXl};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingLg};
  }
`;

const ProjectBasedPlatformRow = styled(Row)`
  margin-top: ${(props) => props.theme.vSpacingLg};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingMd};
  }
`;

// const DreamRowSection = styled(RowSection)`
//   @media (min-width: ${(props) => props.theme.smBreakpoint}) {
//     margin-top: 500px;
//   }
// `;

const StyledStarLeft = styled(Image)`
  position: absolute;
  left: 0;
  bottom: 10%;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    display: none;
  }
`;

// const StyledStarLeft2 = styled(Image)`
//   position: absolute;
//   left: 0;
//   bottom: -400px;

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     display: none;
//   }
// `;

// const ComingSoonRow = styled(RowSection)`
//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     margin-top: 100%;
//   }
// `;

// const StyledStarRight = styled(Image)`
//   position: absolute;
//   right: 0;
//   top: -350px;

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     height: 50%;
//     bottom: -500px;
//   }
// `;

const StarSticker = styled(Sticker)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: 20%;
    margin-top: ${(props) => props.theme.vSpacingSm}!important;
    margin-bottom: ${(props) => props.theme.vSpacingLg}!important;
    float: right;
  }
`;

// const ArrowDownContainer = styled.div`
//   @media (min-width: ${(props) => props.theme.smBreakpoint}) {
//     padding-left: 100px;
//   }
// `;

// const ArrowDownImage = styled(Image)`
//   max-width: 50vw;
// `;

// const StyledStarAlt = styled(Image)`
//   position: absolute;
//   left: 0;
//   bottom: -200px;

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     width: 40vw;
//     bottom: -80px;
//     left: -40px;
//   }
// `;

// const StyledStarAlt2 = styled(Image)`
//   position: absolute;
//   right: 20px;
//   bottom: 50%;
// `;

const HighlightText = styled.span`
  ${(props) =>
    props.mono &&
    `
    font-family: Basis Grotesque Mono;
    font-weight: bold;
  `}

  color: ${(props) => theme[props.color]}
`;

// const FIPSection = styled(Col)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const FIPText = styled.h5`
//   font-family: GT Alpina;
//   font-style: italic;
//   font-size: 120px;
//   line-height: 136px;
//   letter-spacing: -0.02em;
//   color: ${(props) => props.theme.orange};

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     font-size: 54px;
//     line-height: 62px;
//     flex: 1 0 50%;
//   }
// `;

// const FIPTag = styled(Image)`
//   position: absolute;
//   bottom: -120px;
//   right: 0;
//   width: 10%;

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     width: 20%;
//     margin-right: 10%;
//     bottom: -80px;
//   }
// `;

// const FIPExplainCol = styled(Col)`
//   @media (min-width: ${(props) => props.theme.smBreakpoint}) {
//     padding: 100px;
//   }

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     margin-top: 250px;
//   }
// `;

// const FIPHighlightCol = styled(Col)`
//   @media (min-width: ${(props) => props.theme.smBreakpoint}) {
//     padding-left: 70px;
//     padding-right: 70px;
//   }
// `;

// const StyledPieSticker = styled(Sticker)`
//   position: absolute;
//   left: 0;
//   top: 0;
// `;

// const EggLadyDescriptionCol = styled(Col)`
//   @media (min-width: ${(props) => props.theme.smBreakpoint}) {
//     padding-right: 100px;
//   }
// `;

// const StyledSubHeaderText = styled(SubHeaderText)`
//   transform: rotate(-2deg);
//   text-align: center;

//   @media (min-width: ${(props) => props.theme.smBreakpoint}) {
//     line-height: 48px;
//   }
// `;

// const EmailSignupRow = styled(Row)`
//   @media (min-width: ${(props) => props.theme.smBreakpoint}) {
//     margin-top: 300px;
//     padding: 0 100px;
//   }

//   @media (max-width: ${(props) => props.theme.smBreakpoint}) {
//     margin-top: 150px;
//   }
// `;

const NameChangeSticker = styled(Sticker)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: -${(props) => props.theme.vSpacingLg};
  }
`;

const ProjectCityHeader = styled(Header)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: 280px;
  }
`;

function AboutRoute() {
  /** Provide general information about project city. */

  return (
    <DashboardContainer fluid>
      <IntroRow noGutters>
        <Col sm={{ span: 3, offset: 2 }}>
          <NameChangeSticker type="name-change" showMobile />
        </Col>
        <Col className="ml-5">
          <ProjectCityHeader top="project" bottom="city" positiveRotate />
          <SubHeaderText
            color="lime"
            className="pl-4"
            size="lg"
            positiveRotate={false}
          >
            what is it?
          </SubHeaderText>
        </Col>
      </IntroRow>

      <ProjectBasedPlatformRow>
        <Col sm={{ span: 6, offset: 3 }}>
          <HighlightSection className="mx-3">
            <StyledBody mono color="blue">
              A project based platform for learning how to create animation.
            </StyledBody>
          </HighlightSection>
        </Col>
      </ProjectBasedPlatformRow>

      <RowSection className="position-relative">
        <Col sm={{ span: 4, offset: 2 }} className="mt-5">
          <StyledBody>
            Whether you're trying to build your portfolio to get a job at a
            studio, or looking to produce your own independent project—
          </StyledBody>
          <SubHeaderText
            positiveRotate={false}
            className="mt-3"
            color="pink"
            size="lg"
          >
            there's no better way to learn than by doing.
          </SubHeaderText>
        </Col>
        <Col sm={4}>
          <Sticker type="hammer" />
        </Col>
        <Col sm={2}>
          <StarSticker type="star" className="mt-5" showMobile />
        </Col>
        <StyledStarLeft src={StarLeft} alt="" />
      </RowSection>

      <Row>
        {/* <Col sm={{ span: 4, offset: 2 }}>
          <Sticker type="binoculars" showMobile />
        </Col> */}
        <Col sm={12} md={{ span: 6, offset: 4 }}>
          <StyledBody>
            Watch top industry professionals make their projects while they
            teach <HighlightText color="neonPink">you</HighlightText> how to
            make yours.
          </StyledBody>
        </Col>
      </Row>

      {/* <ComingSoonRow className="position-relative">
        <Col sm={{ offset: 2, span: 5 }}>
          <SubHeaderText
            positiveRotate
            color="orange"
            className="pl-5 mb-2"
            size="lg"
          >
            coming soon
          </SubHeaderText>
          <StyledBody>a new way to get your project funded...</StyledBody>
          <ArrowDownContainer className="text-center mt-5">
            <ArrowDownImage src={ArrowDown} alt="" />
          </ArrowDownContainer>
        </Col>
        <StyledStarRight src={StarRight} alt="" />
        <StyledStarAlt src={StarAlt} alt="" />
      </ComingSoonRow>

      <RowSection>
        <Col>
          <FIPSection>
            <div>
              <Image fluid src={FipLogo} alt="Project City Fractional IP" />
            </div>
            <FIPText className="ml-3">= F-IP</FIPText>
          </FIPSection>
          <SubHeaderText
            positiveRotate={false}
            color="lime"
            className="w-100 text-center mt-3"
            size="lg"
          >
            what is it?
          </SubHeaderText>
        </Col>
      </RowSection>

      <RowSection className="position-relative">
        <FIPExplainCol sm={{ span: 6, offset: 6 }}>
          <StyledBody color="pink">
            As artists and fans of animation, <br /> all we want to do is make
            cool projects—{" "}
          </StyledBody>
          <StyledBody color="green">
            but it's extremely difficult to do that in
            <br /> the current studio system.
          </StyledBody>
        </FIPExplainCol>
        <StyledPieSticker type="pie" showMobile />
      </RowSection>

      <DreamRowSection>
        <Col sm={{ span: 4, offset: 2 }}>
          <StyledBody color="pink">
            <HighlightText mono color="lime">
              Project City
            </HighlightText>{" "}
            is launching a new way for artists to fund their dream projects, by
            splitting up the <HighlightText color="green">I</HighlightText>
            ntellectual <HighlightText color="orange">P</HighlightText>roperty
            into <HighlightText color="neonPink">F</HighlightText>ractional
            shares{" "}
          </StyledBody>
          <SubHeaderText size="lg" color="lime" className="mt-2">
            that <HighlightText color="orange">anyone</HighlightText> can buy
          </SubHeaderText>
        </Col>
        <Col>
          <Sticker type="dream" showMobile />
        </Col>
      </DreamRowSection>

      <Row className="position-relative align-items-center justify-content-start">
        <Col sm={5}>
          <Image fluid src={EggLady} alt="OMG SO CRAZY" />
        </Col>
        <EggLadyDescriptionCol sm={{ span: 5, offset: 1 }}>
          <StyledBody color="pink" className="mb-3">
            This way, fans fund the projects that they want to see happen and
            artists get to create their vision.
          </StyledBody>
          <StyledBody color="lime">
            And all the future profits that would go to the IP holder, get
            divided amongst investors and creators, according to their ownership
            percentages.
          </StyledBody>
        </EggLadyDescriptionCol>
        <StyledStarAlt2 src={StarAlt2} alt="" />
      </Row>

      <RowSection className="position-relative">
        <FIPHighlightCol sm={{ span: 8, offset: 2 }}>
          <StyledSubHeaderText color="lime" size="lg" positiveRotate>
            F-IP lets artists and fans own and profit from their creations,{" "}
            <HighlightText color="pink">together</HighlightText>.
          </StyledSubHeaderText>
          <FIPTag src={FipLogo} alt="" />
        </FIPHighlightCol>
        <StyledStarLeft2 src={StarLeft2} alt="" />
      </RowSection>

      <EmailSignupRow>
        <Col sm={{ offset: 2, span: 8 }}>
          <NewsletterSignup />
        </Col>
      </EmailSignupRow> */}
    </DashboardContainer>
  );
}

export default AboutRoute;
