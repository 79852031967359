import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

import { searchProjects } from "features/projects/thunks";
import SearchIcon from "images/SearchIcon";

const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
  }
`;

const StyledFormControl = styled(Form.Control)`
  border: 4px solid ${(props) => props.theme.gray2};
  border-radius: 15px;
  font-size: 80px;
  padding-left: 34px;
  color: ${(props) => props.theme.lime};

  &::placeholder {
    color: ${(props) => props.theme.lime};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    border: 2px solid ${(props) => props.theme.gray2};
    font-size: 42px;
  }
`;

const StyledSubmitButton = styled.button`
  width: 200px;
  display: block;
  background: transparent;
  border: none;
  padding: 0 ${(props) => props.theme.vSpacingXs};

  &:focus,
  &:hover &:active {
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: 150px;
    padding: ${(props) => props.theme.vSpacingSm};
  }
`;

function ProjectSearchControl({ searched, setSearched }) {
  /** Provide the input for searching projects. */

  const [searchTerm, setSearchTerm] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const searchControlRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function onSearch(e) {
    e.preventDefault();
    if (!searched) setSearched(true);

    dispatch(
      searchProjects({
        payload: {
          search: searchTerm,
        },
      })
    );
  }

  useEffect(() => searchControlRef.current.focus(), []);

  return (
    <StyledForm onSubmit={onSearch}>
      <StyledFormControl
        ref={searchControlRef}
        defaultValue=""
        placeholder="search me..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <StyledSubmitButton
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        type="submit"
      >
        <SearchIcon
          btnColor={isHovered ? "#FAFF29" : "#F81958"}
          iconColor={isHovered ? "#F81958" : "#FAFF29"}
        />
      </StyledSubmitButton>
    </StyledForm>
  );
}

ProjectSearchControl.propTypes = {
  // did the user used the search after the search component has mounted?
  searched: PropTypes.bool,
  setSearched: PropTypes.func,
};

export default ProjectSearchControl;
