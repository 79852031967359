import React from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

const StyledCard = styled(Card)`
  background-color: transparent;
  border: 4px solid ${(props) => props.theme.gray2};
  border-radius: 4px;
  position: relative;
`;

function TransparentCard({ children, ...props }) {
  return <StyledCard {...props}>{children}</StyledCard>;
}

export default TransparentCard;
