import { useState } from "react";
import { func, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";

import FormControl from "components/forms/shared/Control";
import BaseForm from "components/forms/Base";
import ToggleBox from "components/controls/ToggleBox";
import FormGroup from "components/forms/shared/FormGroup";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import {
  useCreateAssignmentMutation,
  useGetContentUploadListQuery,
  useUpdateAssignmentMutation,
} from "services/projectCity";
import {
  generateFormData,
  handleResponseError,
  isOverThreshold,
} from "utils/forms";
import FileUploadFormGroup from "components/forms/fields/FileUploadFormGroup";
import { Assignment } from "features/assignments/types";
import { useSelector } from "react-redux";
import LoadingContainer from "components/loading/Container";
import Select from "components/controls/Select";
import { useToasts } from "react-toast-notifications";

function AssignmentForm({
  assignment,
  setAssignment,
  setMultipartFile,
  closeModal,
  ...props
}) {
  /** Form to submit create/modify an assignment. */

  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    setError,
    watch,
  } = useForm({
    defaultValues: assignment || {},
  });

  const watchContentUpload = watch("contentUpload", null);

  const { id: userId } = useSelector((state) => state.account.user);
  const {
    data: contentUploads,
    isLoading: contentUploadsLoading,
  } = useGetContentUploadListQuery({
    created_by: userId,
    page_size: 1000,
  });

  const { addToast } = useToasts();
  const [createAssignment] = useCreateAssignmentMutation();
  const [updateAssignment] = useUpdateAssignmentMutation();

  const isUpdating = assignment?.id !== undefined;

  if (contentUploadsLoading) return <LoadingContainer />;

  async function onSubmit(data) {
    /** When we create a project, there are some default values that need to be set. */

    if (data.contentUpload) {
      // Update the data to send for the content upload data from the object to the id.
      if (!Number.isInteger(data.contentUpload)) {
        data.contentUpload = data.contentUpload.value;
      }
    } else {
      data.contentUpload = "";
      data.useContentUploadVideo = false;
    }

    const fileFields = ["thumbnail", "video"];
    const overThreshold = isOverThreshold(data.video?.size);
    let videoFile = null;

    if (overThreshold) {
      // Temp store the video file, but don't submit it in normal form because it needs to be
      // uploaded in multipart.
      videoFile = data.video;
      delete data.video;
    }

    fileFields.forEach((field) => {
      // Don't submit null for the file fields, or it will show error message as it's not a file.
      if (data[field] === null) delete data[field];
    });

    if (isUpdating) {
      // Remove the data for file fields if updating and the user hasn't chosen anything.
      fileFields.forEach((field) => {
        if (data[field] === assignment[field]) delete data[field];
      });
    }

    const formData = generateFormData(data);
    const action = isUpdating ? updateAssignment : createAssignment;
    const actionPayload = isUpdating
      ? { assignmentId: assignment.id, formData }
      : formData;

    const response = await action(actionPayload);

    if (response.error) {
      handleResponseError(response, setFormError, setError);
    } else {
      if (!overThreshold) {
        closeModal();
        const message = isUpdating
          ? "Assignment updated"
          : "Assignment created";
        addToast(message, { appearance: "success" });
      } else {
        setMultipartFile(videoFile);
        if (!isUpdating) setAssignment(response.data);
      }
    }
  }

  // Only allow the content uploads the user has created.
  const contentUploadOptions = contentUploads.results.map((contentUpload) => ({
    value: contentUpload.id,
    label: contentUpload.title,
  }));

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Title" errors={errors.title}>
        <Controller
          as={FormControl}
          name="title"
          rules={{ required: true }}
          control={control}
          isInvalid={errors.title !== undefined}
        />
      </FormGroup>
      <FormGroup label="Description" errors={errors.description}>
        <Controller
          as={FormControl}
          name="description"
          control={control}
          isInvalid={errors.description !== undefined}
          defaultValue=""
        />
      </FormGroup>
      <FileUploadFormGroup
        field="video"
        label="Instructional Video"
        control={control}
        errors={errors}
      />
      <FileUploadFormGroup
        field="thumbnail"
        label="Thumbnail"
        control={control}
        errors={errors}
      />
      <FormGroup
        label="Linked content video"
        errors={errors.contentUpload}
        tooltipText="Assignment will show up in the 'Assignments' section of a content upload."
      >
        <Controller
          render={({ ...options }) => (
            <Select
              options={contentUploadOptions}
              placeholder="Choose content video (optional)"
              isClearable
              defaultValue={
                assignment?.contentUpload &&
                contentUploadOptions.find(
                  (option) => option.value === assignment.contentUpload
                )
              }
              onChange={options.onChange}
            />
          )}
          control={control}
          name="contentUpload"
          defaultValue={assignment?.contentUpload || ""}
        />
      </FormGroup>
      {watchContentUpload && (
        <ToggleBox
          label="Use content upload video?"
          controllerProps={{
            name: "useContentUploadVideo",
            control,
            defaultValue: assignment?.useContentUploadVideo || false,
          }}
          tooltipText="Use the content upload video as the assignment's instructional header video."
        />
      )}
      <ToggleBox
        label="Is Visible?"
        controllerProps={{
          name: "isPublic",
          control,
          defaultValue: assignment?.isPublic || true,
        }}
        tooltipText="Either allow subscribers to see the assignment, or keep it to yourself for now."
      />

      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

AssignmentForm.propTypes = {
  /** Function to close the parent modal. */
  closeModal: func.isRequired,

  /** If we're updating an instance, then it should be passed in. */
  assignment: shape(Assignment),

  /** Sets a video up for being uploaded via multipart. */
  setMultipartFile: func.isRequired,

  /** Sets the created assignment in parent component. */
  setAssignment: func,

  /** Action to take after the contest entry has been submitted. */
  afterSubmit: func,
};

AssignmentForm.defaultProps = {
  contestId: null,
  assignment: null,
  afterSubmit: () => {},
};

export default AssignmentForm;
