import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    ${(props) =>
      props.$hasExplosionMargin &&
      `
      margin-top: 320px;
    `};
  }
`;

function HeaderSection(props) {
  /** Section container to display a header at the top of page route. */
  return <Wrapper {...props} />;
}

export default HeaderSection;
