import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormGroup from "components/forms/shared/FormGroup";
import BaseForm from "components/forms/Base";
import { forgotPassword } from "features/accounts/thunks";

function ForgotPasswordForm({ closeModal }) {
  const [formErrorText, setFormErrorText] = useState();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const onSubmit = async (data) => {
    const response = await dispatch(forgotPassword(data));
    if (response.type === "FORGOT_PASSWORD/fulfilled") {
      closeModal();
      addToast("Reset email has been sent.", {
        appearance: "success",
        autoDismiss: true,
      });
    } else if (response.type === "FORGOT_PASSWORD/rejected") {
      // Display the error message on the login form.
      try {
        setFormErrorText(response.payload.data.nonFieldErrors[0]);
      } catch {
        setFormErrorText("Could not reset account email.");
      }
    }

    return response;
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Email" errors={errors.email}>
        <Controller
          as={FormControl}
          rules={{
            required: {
              value: true,
              message: "Email is required",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          name="email"
          control={control}
          defaultValue=""
          isInvalid={errors.email !== undefined}
        />
      </FormGroup>
      <FormSubmitContainer withBottomDivider errorText={formErrorText}>
        <FormPrimaryButton isLoading={isSubmitting}>Send</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ForgotPasswordForm.propTypes = {
  closeModal: PropTypes.func,
};

export default ForgotPasswordForm;
