import React from "react";
import { object, number, string } from "prop-types";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

const StyledImage = styled(Image)`
  width: ${(props) => props.dimension}px;
  height: ${(props) => props.dimension}px;
`;

const Sticker = styled(Image)`
  position: absolute;
  width: 40%;
  bottom: -15px;
  left: 0;
`;

function StickerImage({ sticker, imageProps, dimension, ...props }) {
  /**
   * An image that has a sticker attached to it.
   *
   * TODO: This is only used with profile star. Should we use components/icons/Sticker
   * instead?
   */

  return (
    <Container {...props}>
      <StyledImage alt="" dimension={dimension} {...imageProps} />
      {sticker && <Sticker src={sticker} />}
    </Container>
  );
}

StickerImage.propTypes = {
  /** The sticker component that should be appended to the image. */
  sticker: string,

  /** Extra props to pass into the image component. */
  imageProps: object,

  /** The absolute width/height the image should be.  */
  dimension: number,
};

StickerImage.defaultProps = {
  sticker: null,
  imageProps: {},
  dimension: 150,
};

export default StickerImage;
