import { useState } from "react";
import { bool, shape } from "prop-types";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";

import TileFooter from "components/tiles/Footer";
import TileStatsContainer from "components/containers/TileStats";
import ActionOverlayFull from "components/containers/ActionOverlayFull";
import { AssignmentEntry } from "features/assignments/types";
import AssignmentEntryDisplayModal from "features/assignments/modals/AssignmentEntryDisplay";
import { assignmentEntryType } from "features/assignments/enums";
import AssignmentEntryModal from "features/assignments/modals/AssignmentEntry";
import ConfirmDeleteAssignmentEntryModal from "features/assignments/modals/AssignmentEntryConfirmDelete";
import { isProjectCityAdmin } from "utils/permissions";
import { openPdf } from "utils/general";
import AssignmentEntryTileImage from "../EntryTileImage";
import AssignmentEntryOverlay from "../EntryTileOverlay";

const Wrapper = styled.div`
  transition: transform 0.2s ease;
  overflow: visible;
  margin: 20px;

  &:hover {
    transform: ${(props) => (props.$isEven ? "rotate(2deg)" : "rotate(-1deg)")};
    cursor: pointer;
  }
`;

function AssignmentEntryTile({ assignmentEntry, ...props }) {
  /** Tile for showing preview data of an assignment entry.*/

  const [isHover, setIsHover] = useState(false);
  const { user } = useSelector((state) => state.account);

  const [
    showUpdateAssignmentEntryModal,
    hideUpdateAssignmentEntryModal,
  ] = useModal(
    () => (
      <AssignmentEntryModal
        assignmentEntry={assignmentEntry}
        onHide={hideUpdateAssignmentEntryModal}
      />
    ),
    [assignmentEntry]
  );

  const [
    showDeleteAssignmentEntryModal,
    hideDeleteAssignmentEntryModal,
  ] = useModal(
    () => (
      <ConfirmDeleteAssignmentEntryModal
        assignmentEntry={assignmentEntry}
        onHide={hideDeleteAssignmentEntryModal}
      />
    ),
    [assignmentEntry]
  );

  function handleEditClick(e) {
    e.stopPropagation();
    showUpdateAssignmentEntryModal();
  }

  function handleDeleteClick(e) {
    e.stopPropagation();
    showDeleteAssignmentEntryModal();
  }

  function renderOverlay() {
    /** Show the action container, if the user is supposed to see it. */
    const canModify =
      assignmentEntry.createdBy.id === user.id || isProjectCityAdmin(user);

    if (canModify)
      return (
        <ActionOverlayFull
          hasHover={isHover}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      );
  }

  const [showAssignmentEntryModal, hideAssignmentEntryModal] = useModal(
    () => (
      <AssignmentEntryDisplayModal
        assignmentEntry={assignmentEntry}
        onHide={hideAssignmentEntryModal}
      />
    ),
    [assignmentEntry]
  );

  function handleClick(e) {
    if (assignmentEntry.type === assignmentEntryType.pdf)
      openPdf(e, assignmentEntry.files[0].file);

    showAssignmentEntryModal();
  }

  return (
    <Wrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClick}
      {...props}
    >
      <div className="position-relative">
        <AssignmentEntryTileImage assignmentEntry={assignmentEntry} />
        <AssignmentEntryOverlay type={assignmentEntry.type} />
      </div>
      <TileStatsContainer object={assignmentEntry} />
      <TileFooter
        hover={isHover}
        top={assignmentEntry.title}
        bottom={`by ${assignmentEntry.createdBy.username}`}
      />
      {renderOverlay()}
    </Wrapper>
  );
}

AssignmentEntryTile.propTypes = {
  assignmentEntry: shape(AssignmentEntry).isRequired,
  $isEven: bool,
};

AssignmentEntryTile.defaultProps = {
  $isEven: true,
};

export default AssignmentEntryTile;
