import React from "react";
import Modal from "react-bootstrap/Modal";
import { shape } from "prop-types";

import BaseModal from "components/modals/Base";
import UnorderedList from "components/lists/UnorderedList";
import ModalSummaryText from "components/text/ModalSummaryText";
import Body from "components/text/Body";
import ModalHeader from "components/modals/Header";
import { Contest } from "features/contests/types";

function ContestRulesModal({ contest, ...props }) {
  /** Modal to display rules for a contest. */

  return (
    <BaseModal size="xl" {...props}>
      <ModalHeader
        title="Contest Rules"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        {contest.rulesDescription && (
          <ModalSummaryText color="dark" className="mb-3">
            {contest.rulesDescription}
          </ModalSummaryText>
        )}
        <UnorderedList>
          {contest?.rules.map((rule) => (
            <li key={rule.id}>
              <Body className="mt-1" color="yellow">
                {rule.text}
              </Body>
            </li>
          ))}
        </UnorderedList>
      </Modal.Body>
    </BaseModal>
  );
}

ContestRulesModal.propTypes = {
  /** The contest object that rules are being shown for. */
  contest: shape(Contest).isRequired,
};

export default ContestRulesModal;
