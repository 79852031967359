import React from "react";
import { useSelector } from "react-redux";

import MyContentUploadList from "features/sprint/components/MyContentUploadList";
import LoadingContainer from "components/loading/Container";
import { useGetContentUploadListQuery } from "services/projectCity";
import projectCityApi from "services/projectCity";
import Caption from "components/text/Caption";

function SprintManagementVideosRoute(props) {
  /** Show a premium content user the videos that they've uploaded. */

  const { id: userId } = useSelector((state) => state.account.user);
  const { data, isLoading } = useGetContentUploadListQuery({
    created_by: userId,
  });

  const [
    loadNextPage,
  ] = projectCityApi.endpoints.getNextContentUploadPage.useLazyQuery();

  if (isLoading) return <LoadingContainer text="Loading videos..." />;

  if (data.results.length === 0)
    return (
      <div className="text-center px-5">
        <Caption color="pink">You do not have any uploads to manage.</Caption>
      </div>
    );

  return (
    <div>
      <MyContentUploadList
        hasMore={data?.next !== null}
        loadMore={() =>
          loadNextPage({
            next: data?.next,
            created_by: userId,
          })
        }
        contentUploads={data.results}
      />
    </div>
  );
}

export default SprintManagementVideosRoute;
