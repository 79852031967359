import DefaultUserImage from "images/profile-generic.svg";

export function getUserImage(user) {
  if (!user) return DefaultUserImage;

  // The google images are by default smaller, however we can replace and get larger.
  return (
    user.image || user.socialImage?.replace("=s96", "=s256") || DefaultUserImage
  );
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getFullName(user) {
  return `${user.firstName} ${user.lastName}`;
}
