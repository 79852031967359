import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Masonry from "components/masonry/Masonry";
import BucketUpload from "features/buckets/components/Uploads/BucketUpload";
import EmptyData from "components/general/EmptyData";

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.vSpacingMd};
  margin-bottom: ${(props) => props.theme.vSpacingXl};
`;

function BucketUploadContainer({ bucketUploads }) {
  /**
   * Render the top-layer container for the image bucket, to display the first image in
   * each bucket upload.
   */

  function renderBucketUploads() {
    if (bucketUploads.length === 0)
      return (
        <EmptyData>
          Hit the plus to add image stacks, pdfs, audio, or videos
        </EmptyData>
      );

    return bucketUploads.map((bucketUpload) => (
      <BucketUpload key={bucketUpload.id} bucketUpload={bucketUpload} />
    ));
  }

  return (
    <Wrapper fluid className="mt-3">
      <Masonry>{renderBucketUploads()}</Masonry>
    </Wrapper>
  );
}

BucketUploadContainer.propTypes = {
  bucketUploads: PropTypes.arrayOf(PropTypes.object),
};

export default BucketUploadContainer;
