// Colors that we allow for text.
export const textColors = [
  "dark",
  "lime",
  "pink",
  "red",
  "neonYellow",
  "neonPink",
  "green",
  "gray1",
  "gray2",
  "gray3",
  "yellow",
  "blue",
  "orange",
];
