import React from "react";
import { bool, number } from "prop-types";

import theme from "theme";

function OptionsIcon({ dimension, hover }) {
  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox={`0 0 ${dimension} ${dimension}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1998 50.7189C19.7739 49.8684 14.4445 46.658 11.5976 42.3872C8.75071 38.1163 7.82031 31.7592 8.89863 26.0911C9.97695 20.4231 13.3947 15.0098 17.6574 11.8534C21.9201 8.69696 27.6993 8.20917 33.1253 9.05971C38.5512 9.91025 43.1734 12.6001 46.0224 16.8743C48.8715 21.1484 50.3414 26.7445 49.2625 32.4154C48.1837 38.0863 44.1539 42.0483 39.8907 45.2076C35.6275 48.3668 30.623 51.569 25.1998 50.7189Z"
        fill={hover ? theme.primary : theme.secondary}
      />
      <path
        d="M28.6091 16.5452C29.3649 17.1223 29.7967 18.0024 29.916 18.907C30.0352 19.8115 29.8698 20.812 29.2967 21.6151C28.7237 22.4182 27.8201 22.6616 26.949 22.81C26.0778 22.9584 25.0827 23.1237 24.3268 22.5466C23.571 21.9695 23.0133 21.0083 22.8934 20.1038C22.7735 19.1994 23.289 18.2696 23.8624 17.4661C24.4357 16.6625 25.1657 16.0556 26.0369 15.9072C26.908 15.7588 27.8536 15.9684 28.6091 16.5452Z"
        fill={hover ? theme.secondary : theme.primary}
      />
      <path
        d="M31.5382 28.368C31.5996 29.316 31.2224 30.2382 30.6228 30.9502C30.0232 31.6622 29.1659 32.2337 28.1901 32.3727C27.2143 32.5116 26.442 32.0235 25.7619 31.4933C25.0819 30.9631 24.3082 30.3545 24.2468 29.4064C24.1854 28.4584 24.5411 27.3887 25.1402 26.6763C25.7394 25.9639 26.7724 25.6956 27.7486 25.5566C28.7249 25.4175 29.6562 25.5282 30.3363 26.0584C31.0164 26.5886 31.4769 27.4205 31.5382 28.368Z"
        fill={hover ? theme.secondary : theme.primary}
      />
      <path
        d="M31.5532 41.7701C30.6536 42.1966 29.6494 42.2105 28.7828 41.9382C27.9163 41.6659 27.1095 41.1024 26.6864 40.2613C26.2634 39.4202 26.5075 38.521 26.8196 37.6899C27.1318 36.8589 27.4921 35.9114 28.3917 35.4849C29.2913 35.0584 30.432 34.9672 31.2988 35.2389C32.1656 35.5105 32.7308 36.3536 33.1541 37.1952C33.5774 38.0367 33.7457 38.9345 33.4335 39.7655C33.1214 40.5966 32.4523 41.3439 31.5532 41.7701Z"
        fill={hover ? theme.secondary : theme.primary}
      />
    </svg>
  );
}

OptionsIcon.propTypes = {
  /** Change styling when hovering over the icon. */
  hover: bool,

  /** Sets the height/width of the icon. */
  dimension: number,
};

OptionsIcon.defaultProps = {
  hover: false,
  dimension: 40,
};

export default OptionsIcon;
