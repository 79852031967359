import React from "react";
import { func, shape } from "prop-types";

import Button from "components/buttons/Button";
import ModalBackButton from "components/modals/BackButton";
import TierDescriptionTable from "components/tables/TierDescriptions";
import { ModalActionProps, RegistrationTier } from "types";

function RegistrationTierDescriptionSection({
  registrationTier,
  setContentSection,
  modalActionProps,
}) {
  /** Render the section to modify the descriptions for a registration tier. */

  const { descriptions } = registrationTier;

  return (
    <div>
      <div>
        <TierDescriptionTable
          descriptions={descriptions}
          className="mb-3"
          modalActionProps={modalActionProps}
        />
      </div>
      <div className="d-flex justify-content-between">
        <ModalBackButton
          onClick={() => setContentSection("editRegistrationTier")}
        />
        <Button onClick={() => setContentSection("createTierDescription")}>
          Create new description
        </Button>
      </div>
    </div>
  );
}

RegistrationTierDescriptionSection.propTypes = {
  /** The registration tier whose descriptions are being rendered. */
  registrationTier: shape(RegistrationTier).isRequired,

  /** Set the content section of the parent modal */
  setContentSection: func.isRequired,

  /** Properties passed down from the parent modal which go to the action button. */
  modalActionProps: ModalActionProps.isRequired,
};

export default RegistrationTierDescriptionSection;
