import styled from "styled-components";
import moment from "moment-timezone";

import UnorderedList from "components/lists/UnorderedList";
import CopyHeader from "components/text/CopyHeader";
import useIsProjectCityAdmin from "hooks/IsProjectCityAdmin";
import EditIcon from "components/icons/Edit";
import DeleteIcon from "components/icons/Delete";
import UploadScheduleSectionForm from "features/schedules/forms/UploadScheduleSection";
import FormModal from "components/modals/Form";
import { useModal } from "react-modal-hook";
import ConfirmDeleteUploadScheduleSectionModal from "features/schedules/modals/UploadScheduleSectionConfirmDelete";
import UploadScheduleDate from "../ScheduleDate";
import ProjectCityButton from "components/buttons/Button";
import UploadScheduleDateForm from "features/schedules/forms/UploadScheduleDate";

const SectionWrapper = styled.div`
  margin-top: 10px;
  padding-top: 20px;
  border-top: 2px dashed ${(props) => props.theme.pink};

  ${(props) =>
    props.$isPrivate &&
    `
    opacity: 0.6;
  `}
`;

function UploadScheduleSection({ section }) {
  /** Display an individual schedule section. */

  // Only allow project city admins to modify schedules.
  const allowEdit = useIsProjectCityAdmin();

  const [showUploadScheduleModal, hideUploadScheduleModal] = useModal(
    () => (
      <FormModal
        onHide={hideUploadScheduleModal}
        title="Update Upload Schedule"
      >
        <UploadScheduleSectionForm
          closeModal={hideUploadScheduleModal}
          uploadScheduleSection={section}
        />
      </FormModal>
    ),
    [section]
  );

  const [
    showDeleteUploadScheduleSectionModal,
    hideDeleteUploadScheduleSectionModal,
  ] = useModal(
    () => (
      <ConfirmDeleteUploadScheduleSectionModal
        uploadScheduleSection={section}
        onHide={hideDeleteUploadScheduleSectionModal}
      />
    ),
    [section]
  );

  const [showUploadScheduleDateModal, hideUploadScheduleDateModal] = useModal(
    () => (
      <FormModal
        onHide={hideUploadScheduleDateModal}
        title="Create Upload Schedule Date"
      >
        <UploadScheduleDateForm
          closeModal={hideUploadScheduleDateModal}
          uploadScheduleDate={{ section: section.id }}
        />
      </FormModal>
    )
  );

  if (!section.isPublic && !allowEdit) return null;

  return (
    <SectionWrapper $isPrivate={!section.isPublic}>
      <CopyHeader
        className="mb-0 d-flex align-items-center"
        size="lg"
        color="lime"
      >
        {moment(section.startDate).format("MMMM Do")} to{" "}
        {moment(section.endDate).format("MMMM Do")}
        {allowEdit && (
          <span className="ml-2">
            <EditIcon role="button" onClick={showUploadScheduleModal} />
            <DeleteIcon
              className="ml-1"
              role="button"
              onClick={showDeleteUploadScheduleSectionModal}
            />
          </span>
        )}
      </CopyHeader>
      <br />
      <UnorderedList>
        {section.dates.map((date) => (
          <UploadScheduleDate uploadScheduleDate={date} allowEdit={allowEdit} />
        ))}
      </UnorderedList>
      <div>
        {allowEdit && (
          <ProjectCityButton
            size="sm"
            variant="outline-secondary"
            className="mb-3 ml-3"
            onClick={showUploadScheduleDateModal}
          >
            Add date
          </ProjectCityButton>
        )}
      </div>
    </SectionWrapper>
  );
}

export default UploadScheduleSection;
