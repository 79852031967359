import { bool, func } from "prop-types";

import DeleteIcon from "components/icons/Delete";
import EditIcon from "components/icons/Edit";
import ActionOverlayContainer from "../ActionOverlay";

function ActionOverlayFull({ hasHover, handleEditClick, handleDeleteClick }) {
  /** Provides a standard action overlay with optional edit and delete actions. */

  return (
    <ActionOverlayContainer show={hasHover}>
      {handleEditClick && <EditIcon onClick={handleEditClick} />}
      {handleDeleteClick && <DeleteIcon onClick={handleDeleteClick} />}
    </ActionOverlayContainer>
  );
}

ActionOverlayFull.propTypes = {
  /** Only show the overlay when parent is being hovered. */
  hasHover: bool.isRequired,

  /** Action to take when clicking the edit button. */
  handleEditClick: func.isRequired,

  /** Action to take when clicking the delete button. */
  handleDeleteClick: func.isRequired,
};

export default ActionOverlayFull;
