import styled from "styled-components";

import Header from "components/text/Header";
import CopyHeader from "components/text/CopyHeader";
import { useGetUploadSchedulesQuery } from "services/projectCity";
import Sticker from "components/icons/Sticker";
import LoadingContainer from "components/loading/Container";
import SprintUploadSchedule from "features/schedules/components/Schedule";
import SubscribeButton from "features/sprint/components/SubscribeButton";
import ProjectCityButton from "components/buttons/Button";
import { uploadScheduleType } from "features/sprint/enums";
import { useState } from "react";
import useIsProjectCityAdmin from "hooks/IsProjectCityAdmin";
import { useModal } from "react-modal-hook";
import FormModal from "components/modals/Form";
import UploadScheduleForm from "features/schedules/forms/UploadSchedule";

const HeaderSection = styled.div`
  display: flex;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    justify-content: space-evenly;
    align-items: flex-end;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.theme.vSpacingMd};
  }
`;

const SectionContainer = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingSm} 25%;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingMd} 10%;
  }
`;

const StyledSticker = styled(Sticker)`
  position: absolute;
  left: 0;
  top: 55px;
  max-width: 40%;
`;

const ScheduleSticker = styled(Sticker)`
  position: absolute;
  right: -30px;
  bottom: 10%;
  max-width: 40%;
`;

const TabButtonContainer = styled.div`
  display: flex;
  margin: ${(props) => props.theme.vSpacingLg};
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingMd} 10%;
  }
`;

function TabButton(props) {
  return (
    <ProjectCityButton
      variant={props.isActive ? "secondary" : "outline-secondary"}
      className="m-3 p-2"
      size="xl"
      noTilt
      {...props}
    >
      {props.children}
    </ProjectCityButton>
  );
}

function SprintScheduleRoute() {
  /** Show the Story Sprint release schedules for lessons and shows. */

  const { data, isLoading } = useGetUploadSchedulesQuery();
  const [activeSection, setActiveSection] = useState("lessons");

  // Only allow project city admins to modify schedules.
  const allowCreate = useIsProjectCityAdmin();

  const [showUploadScheduleModal, hideUploadScheduleModal] = useModal(() => (
    <FormModal onHide={hideUploadScheduleModal} title="Create Upload Schedule">
      <UploadScheduleForm closeModal={hideUploadScheduleModal} />
    </FormModal>
  ));

  if (isLoading) return <LoadingContainer text="Loading schedule" />;

  function renderSections() {
    const selectedType =
      activeSection === "lessons"
        ? uploadScheduleType.lesson
        : uploadScheduleType.show;

    return data
      .filter((schedule) => schedule.type === selectedType)
      .map((schedule, index) => {
        const isEven = index % 2 !== 0;
        return (
          <SprintUploadSchedule
            key={index}
            schedule={schedule}
            isEven={isEven}
          />
        );
      });
  }

  return (
    <>
      <div className="text-center p-relative">
        <StyledSticker />
        <HeaderSection>
          <div />
          <div>
            <Header top="monthly" bottom="schedule" />
            <CopyHeader className="mt-3">Updated every month</CopyHeader>
          </div>
          <div />
        </HeaderSection>
      </div>
      <SectionContainer>
        <TabButtonContainer>
          <TabButton
            isActive={activeSection === "lessons"}
            onClick={() => setActiveSection("lessons")}
          >
            Lessons
          </TabButton>
          <TabButton
            isActive={activeSection === "shows"}
            onClick={() => setActiveSection("shows")}
          >
            Shows
          </TabButton>
        </TabButtonContainer>
        {allowCreate && (
          <div className="text-center">
            <ProjectCityButton
              noTilt
              className="mb-3"
              onClick={showUploadScheduleModal}
            >
              Create New Schedule
            </ProjectCityButton>
          </div>
        )}
        {renderSections()}
        <div className="text-center my-3">
          <SubscribeButton className="px-5" />
        </div>
        <ScheduleSticker />
      </SectionContainer>
    </>
  );
}

export default SprintScheduleRoute;
