import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import LoadingContainer from "components/loading/Container";
import { useToasts } from "react-toast-notifications";

import { changeEmailConfirm } from "features/accounts/thunks";

const InnerContainer = styled.div`
  margin-top: 3rem;
`;

function ChangeEmailRoute({ uid, token, email }) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  async function confirmNewEmail() {
    try {
      const action = await dispatch(changeEmailConfirm({ uid, token, email }));
      if (action.type === "CHANGE_EMAIL_CONFIRM/fulfilled") {
        addToast("Your email has been rest successfully", {
          appearance: "success",
        });
      } else {
        addToast(action.payload.data, { appearance: "error" });
      }
      navigate("/classes");
    } catch (err) {
      addToast(err, { appearance: "error" });
      navigate("/classes");
    }
  }

  useEffect(() => confirmNewEmail(), []);

  return (
    <Container className="d-flex justify-content-center">
      <InnerContainer className="p-3 m-5">
        <LoadingContainer text="Reseting your email..." />
      </InnerContainer>
    </Container>
  );
}

ChangeEmailRoute.propTypes = {
  /* A secret value that determines the user. */
  uid: PropTypes.string,

  /* A secret token to hash the password request. */
  token: PropTypes.string,

  /* New user email. */
  email: PropTypes.string,
};

export default ChangeEmailRoute;
