import React from "react";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

import FormModal from "components/modals/Form";
import Thumbnail from "components/text/Thumbnail";
import useCanCreateClass from "hooks/CanCreateClass";
import CreateProjectForm from "features/projects/forms/CreateProject";
import NeedsApprovalModal from "features/projects/modals/NeedsApproval";
import ClassImage from "images/class.svg";
import ProjectImage from "images/project.svg";
import { projectTemplate, projectType } from "utils/enums";

const TemplatesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TemplateWrapper = styled.div`
  flex-basis: 50%;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${(props) => props.theme.smallPcBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingMd};

    > img {
      width: 220px;
      height: 220px;
    }
  }

  @media (max-width: ${(props) => props.theme.smallPcBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingSm};

    > img {
      width: 100%;
    }
  }
`;

function ProjectTemplateSection() {
  /** Section for the user to choose which project template to use. */

  const canCreateClass = useCanCreateClass();

  const templates = [
    {
      title: "Class",
      value: projectTemplate.liveClass,
      img: ClassImage,
    },
    {
      title: "Project",
      value: projectTemplate.animationProject,
      img: ProjectImage,
    },
  ];

  const [showCreateLiveClass, hideCreateLiveClass] = useModal(() => {
    // Handle the bucket modal for creating a new bucket..
    return (
      <FormModal title="create a class" onHide={hideCreateLiveClass}>
        <CreateProjectForm
          closeModal={hideCreateLiveClass}
          type={projectType.defaultClass}
          template={projectTemplate.liveClass}
        />
      </FormModal>
    );
  });

  const [showProjectModal, hideProjectModal] = useModal(() => {
    // Handle the bucket modal for creating a new bucket..
    return (
      <FormModal title="create a project" onHide={hideProjectModal}>
        <CreateProjectForm
          template={projectTemplate.animationProject}
          type={projectType.defaultProject}
          closeModal={hideProjectModal}
        />
      </FormModal>
    );
  });

  const [showNotApprovedModal, hideNotApprovedModal] = useModal(() => (
    <NeedsApprovalModal onHide={hideNotApprovedModal} />
  ));

  function onClickTemplate(template) {
    if (template.title === "Class") {
      canCreateClass ? showCreateLiveClass() : showNotApprovedModal();
    } else showProjectModal();
  }

  function renderTemplates() {
    /** Render each of the templates that the user can choose from. */
    return templates.map((template) => (
      <TemplateWrapper
        key={template.title}
        onClick={() => onClickTemplate(template)}
      >
        <img src={template.img} alt="" />
        <Thumbnail color="lime" className="text-center mt-1">
          {template.title}
        </Thumbnail>
      </TemplateWrapper>
    ));
  }

  return <TemplatesContainer>{renderTemplates()}</TemplatesContainer>;
}

export default ProjectTemplateSection;
