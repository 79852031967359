import PropTypes from "prop-types";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { useModal } from "react-modal-hook";

import ConfirmActionModal from "components/modals/ConfirmAction";
import FormModal from "components/modals/Form";
import PostForm from "components/forms/Post";
import FormHelpText from "components/forms/shared/HelpText";
import useGetContentTypeObj from "hooks/GetContentTypeObj";

function useFeedShare(modelName, title, objectId) {
  /**
   * Provide shared functionality for sharing an object to the site feed.
   *
   * There are a couple parts involved here, first the user confirms they want to share,
   * and then they create the post by adding a description.
   */

  const contentType = useGetContentTypeObj(modelName);

  const [showCreatePostModal, hideCreatePostModal] = useModal(() => {
    return (
      <FormModal title="Write a caption..." onHide={hideCreatePostModal}>
        <PostForm
          closeModal={hideCreatePostModal}
          post={{ contentType: contentType.id, objectId }}
        />
      </FormModal>
    );
  }, [objectId]);

  const [showConfirmShareModal, hideConfirmShareModal] = useModal(() => {
    function handleShareAction() {
      hideConfirmShareModal();
      showCreatePostModal();
    }

    let message = null;

    switch (modelName) {
      case "project":
        const descriptor = title.includes("class") ? "class" : "project";
        message = `Your ${descriptor} is now public. Do you want to share your poster to the feed with a caption?`;
        break;
      case "contestentry":
        message =
          "Your contest entry has been submitted successfully. Would you also like to share to the main site feed?";
        break;
      case "assignmententry":
        message =
          "Your assignment entry has been submitted successfully. Would you also like to share to the main site feed?";
        break;
      default:
        message =
          "Now you can share your work to the feed, with all members on the site!";
    }

    return (
      <ConfirmActionModal
        title={title}
        confirmText="Yes, share"
        confirmAction={handleShareAction}
        declineText="No"
        confirmIcon={faShare}
        onHide={hideConfirmShareModal}
      >
        <FormHelpText>{message}</FormHelpText>
      </ConfirmActionModal>
    );
  });

  return showConfirmShareModal;
}

useFeedShare.propTypes = {
  // The model name that we're checking if the user wants to share.
  modelName: PropTypes.string.isRequired,

  // The object id that we're checking if the user wants to share.
  objectId: PropTypes.number.isRequired,

  // The title that should be shown on the initial modal.
  title: PropTypes.string.isRequired,
};

export default useFeedShare;
