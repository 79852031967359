import React from "react";
import styled from "styled-components";
import { bool, oneOf, string } from "prop-types";

import RainbowBackground from "images/backgrounds/rainbow_full.png";
import { textColors } from "styles/constants";
import { injectString } from "utils/general";

const Container = styled.div`
  transform: rotate(${(props) => (props.positiveRotate ? "2deg" : "-2deg")});
  position: relative;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) =>
      !props.noMargin &&
      `
      0 10%
    `};
  }
`;

const TextContainer = styled.div`
  color: ${(props) => props.theme[props.color]};

  // The rotate can sometimes cutoff the top of letters
  padding-top: 5px;

  ${(props) =>
    props.color === "rainbow" &&
    `
    color: ${props.theme.orange}; /* Fallback: assume this color ON TOP of image */

    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
      // Rainbow background does not work in Safari, so we only apply in chrome.
      background: url(${RainbowBackground}) repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    `}

  > h1 {
    text-transform: lowercase;
    letter-spacing: -0.04em;

    ${(props) => {
      switch (props.size) {
        case "xs":
          return `
            font-size: 35px;
            line-height: 28px;
          `;
        case "sm":
          return `
            font-size: 54px;
            line-height: 36px;
          `;
        default:
          return `
            font-size: 120px;
            line-height: 80px;
          `;
      }
    }}

    ${(props) =>
      props.size === "xs" &&
      `
      margin-bottom: 0;
    `}
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    > h1 {
      font-size: 54px;
      line-height: 36px;
      letter-spacing: -0.03em;
    }
  }
`;

const TopText = styled.h1`
  font-family: "GT Alpina";
  font-style: italic;

  ${(props) => {
    switch (props.size) {
      case "xs":
        return `
          padding-right: 0px;
        `;
      case "sm":
        return `
          padding-right: 40px;
        `;
      default:
        return `
          padding-right: 150px;
        `;
    }
  }}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-right: 40px;
  }
`;

const BottomText = styled.h1`
  font-family: "SuisseIntl";
  display: inline-block;

  ${(props) => {
    switch (props.size) {
      case "xs":
        return `
          padding-left: 10px;
        `;
      case "sm":
        return `
          padding-left: 40px;
        `;
      default:
        return `
          padding-left: 150px;
        `;
    }
  }}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 40px;
  }
`;

function Header({ top, bottom, size, color, ...props }) {
  /** A main header, typically used at the top of a page. */

  // Append half spaces in the words after first letter and before last.

  const cleanedTop = injectString(top, 1, "&thinsp;");

  // Temp remove last space as it causes line breaks.
  // const cleanedBottom = injectString(bottom, bottom.length - 1, "&thinsp;");
  const cleanedBottom = bottom;

  return (
    <Container {...props}>
      <TextContainer color={color} size={size}>
        <TopText size={size} dangerouslySetInnerHTML={{ __html: cleanedTop }} />
        <BottomText
          size={size}
          dangerouslySetInnerHTML={{ __html: cleanedBottom }}
        />
      </TextContainer>
    </Container>
  );
}

Header.propTypes = {
  /** The text to show in the top section of the header. */
  top: string.isRequired,

  /** The text to show in the bottom section of the header. */
  bottom: string.isRequired,

  /** Choose the size of the header. */
  size: oneOf(["sm", "md", "lg"]),

  /** Specify a color that the text should be. */
  color: oneOf([...textColors, "rainbow"]),

  /** Set the header to have positive rotation. */
  positiveRotate: bool,

  /** Do not apply margin to the component. */
  noMargin: bool,
};

Header.defaultProps = {
  color: "pink",
  positiveRotate: false,
  noMargin: false,
};

export default Header;
