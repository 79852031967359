import LoadingContainer from "components/loading/Container";
import DonationTable from "features/donations/tables/DonationTable";
import DonationSummaryTable from "features/donations/tables/DonationSummaryTable";
import {
  useGetDonationAggregateQuery,
  useGetDonationListQuery,
} from "services/projectCity";
import styled from "styled-components";
import { useSelector } from "react-redux";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import InfoTooltip from "components/tooltips/Info";

const TableContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: 1fr;
    grid-gap: 100px;
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: 1fr 2fr;
    grid-gap: 50px;
    margin-left: 10%;
    margin-right: 10%;
  }
`;

function SprintManagementDonationsRoute() {
  /** Show the history and summary of donations received per animatic. */

  const { user } = useSelector((state) => state.account);

  const {
    data: donationData,
    isLoading: donationsAreLoading,
  } = useGetDonationListQuery({
    to_user: user.id,
  });

  const {
    data: summaryData,
    isLoading: summaryIsLoading,
  } = useGetDonationAggregateQuery();

  if (donationsAreLoading || summaryIsLoading) {
    return <LoadingContainer text="Loading donation data..." />;
  }

  return (
    <div>
      <TableContainer>
        <div>
          <SubHeaderText color="pink" tilt className="mb-3 text-center">
            Total earnings per animatic
          </SubHeaderText>
          <DonationSummaryTable donationGroups={summaryData} />
        </div>
        <div className="text-center">
          <SubHeaderText color="orange" tilt className="mb-3">
            <InfoTooltip
              iconProps={{ className: "mr-1" }}
              text="Donations are received at any time and then are distributed monthly on the 1st of each month for $0.07 per token."
            />
            Individual donations
          </SubHeaderText>
          <DonationTable type="received" donations={donationData.results} />
        </div>
      </TableContainer>
    </div>
  );
}

export default SprintManagementDonationsRoute;
