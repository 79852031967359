import Video from "components/videos/Video";
import { ContestEntry } from "features/contests/types";
import { shape } from "prop-types";

function ContestEntryVideo({ contestEntry, ...props }) {
  /** Render the video component for a contest entry. */

  return <Video src={contestEntry.file} type="video/mp4" {...props} />;
}

ContestEntryVideo.propTypes = {
  /** The contest entry object whose video is being shown. */
  contestEntry: shape(ContestEntry).isRequired,
};

export default ContestEntryVideo;
