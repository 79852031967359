import Modal from "react-bootstrap/Modal";
import { shape } from "prop-types";
import styled from "styled-components";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import LikeContainer from "features/likes/components/Container";
import AssignmentEntryModalContent from "features/assignments/components/ModalContent";
import { AssignmentEntry } from "features/assignments/types";
import CommentContainer from "features/comments/components/Container";
import useGetContentTypeObj from "hooks/GetContentTypeObj";
import { useToggleAssignmentEntryLikeMutation } from "services/projectCity";
import Caption from "components/text/Caption";
import { navigate } from "@reach/router";

const ActionBar = styled.div`
  display: flex;
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

function AssignmentEntryDisplayModal({ assignmentEntry, ...props }) {
  /** Modal for displaying the data of an assignment entry. */

  const contentTypeObj = useGetContentTypeObj("assignmententry");
  const [toggleLike] = useToggleAssignmentEntryLikeMutation();

  function renderContent() {
    /**
     * Render the main content of the assignment entry, which may be a variety of things based on
     * its type (image, image stack, video, audio, etc).
     */
    return <AssignmentEntryModalContent assignmentEntry={assignmentEntry} />;
  }

  const { username } = assignmentEntry.createdBy;

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title={assignmentEntry.title}
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        {renderContent()}
        <ActionBar>
          <LikeContainer
            count={assignmentEntry.likesCount}
            likeActive={assignmentEntry.isLikedByCurrentUser}
            onClick={() =>
              toggleLike({
                assignmentEntryId: assignmentEntry.id,
                assignmentId: assignmentEntry.assignment,
              })
            }
          />
          <Caption
            role="button"
            color="yellow"
            onClick={() => navigate(`/users/${username}`)}
          >
            @{username}
          </Caption>
        </ActionBar>
        <CommentContainer
          className="mt-3"
          contentObject={assignmentEntry}
          contentTypeObj={contentTypeObj}
        />
      </Modal.Body>
    </BaseModal>
  );
}

AssignmentEntryDisplayModal.propTypes = {
  /** The assignment entry instance that is being rendered. */
  assignmentEntry: shape(AssignmentEntry).isRequired,
};

export default AssignmentEntryDisplayModal;
