import React from "react";

import Caption from "components/text/Caption";

function ModalSummaryText(props) {
  /** Summary text to show in a modal. */

  return (
    <Caption className="mb-0" {...props}>
      {props.children}
    </Caption>
  );
}

export default ModalSummaryText;
