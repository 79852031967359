import React from "react";
import { number, shape, string } from "prop-types";

import BaseTable from "components/tables/Base";
import ColumnHeader from "components/tables/ColumnHeader";

function AccountProjectEarningsTable({ earnings }) {
  /** Table that shows the earnings for an account on a project. */

  function renderRows() {
    return earnings.map((earnings) => (
      <tr>
        <td>{earnings.registrationTier.title}</td>
        <td>${(earnings.total / 100).toFixed(2)}</td>
      </tr>
    ));
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <ColumnHeader>Registration Tier</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </BaseTable>
  );
}

AccountProjectEarningsTable.propTypes = {
  /** The registration tier that is being shown. */
  registrationTier: shape({
    id: number.isRequired,
    title: string.isRequired,
  }).isRequired,

  /** The total earned on the registration tier. */
  total: number.isRequired,
};

export default AccountProjectEarningsTable;
