import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";

import Button from "components/buttons/Button";
import BorderContainer from "../Border";

const StyledBorderContainer = styled(BorderContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ${(props) => props.theme.transitionFast} ease;
  z-index: 2;

  &:hover {
    background-color: rgba(250, 255, 41, 0.2);
    cursor: pointer;

    button {
      color: ${(props) => props.theme.blue}!important;
      background-color: ${(props) => props.theme.yellow};
      border-color: ${(props) => props.theme.yellow};
    }
  }
`;

function ActionSection({ buttonText, ...props }) {
  /** A reusable container used to perform an action. */

  return (
    <StyledBorderContainer fill {...props}>
      {props.children}
      <Button>{buttonText}</Button>
    </StyledBorderContainer>
  );
}

ActionSection.propTypes = {
  /** The text to display in central button. */
  buttonText: string.isRequired,

  /** Determine if the section should be full height. */
  fullHeight: bool,
};

ActionSection.defaultProps = {
  fullHeight: true,
};

export default ActionSection;
