import React from "react";
import { Link, useLocation } from "@reach/router";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import { arrayOf, shape, string } from "prop-types";

import MobileNavigationContainer from "components/navigation/MobileNavigationContainer";

const StyledDropdown = styled(Dropdown)`
  background-color: ${(props) => props.theme.neonPink}!important;
  transform: rotate(1deg);

  > .dropdown-toggle {
    color: ${(props) => props.theme.secondary}!important;
    background-color: ${(props) => props.theme.neonPink}!important;
    border-color: ${(props) => props.theme.neonPink}!important;
    box-shadow: none !important;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  background-color: ${(props) => props.theme.yellow}!important;
  transform: rotate(1deg);

  > .dropdown-toggle {
    color: ${(props) => props.theme.secondary};
  }
`;

function SprintMobileNavigation({ linkData, ...props }) {
  /** Display the sprint secondary navigation to be shown on mobile for.. */

  const { pathname } = useLocation();

  function renderItems() {
    // Render all the bucket links that should go in the dropdown.

    return linkData.map((link) => {
      return (
        <Dropdown.Item key={link.label} as={Link} to={link.to} {...link}>
          {link.label}
        </Dropdown.Item>
      );
    });
  }

  function renderDropdownTitle() {
    if (["/sprint/content", "/sprint/animatics"].includes(pathname))
      return "Content";
    else if (pathname === "/sprint/schedule") return "Schedule";
    else if (pathname.includes("/sprint/contests")) return "Contests";
    else return "Select...";
  }

  return (
    <MobileNavigationContainer width={92}>
      <StyledDropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {renderDropdownTitle()}
        </Dropdown.Toggle>

        <DropdownMenu>{renderItems()}</DropdownMenu>
      </StyledDropdown>
    </MobileNavigationContainer>
  );
}

SprintMobileNavigation.propTypes = {
  /** Data which tells us which links to generate. */
  linkData: arrayOf(
    shape({
      label: string.isRequired,
      to: string,
    })
  ),
};

export default SprintMobileNavigation;
