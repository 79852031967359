import { shape } from "prop-types";

import { useToasts } from "react-toast-notifications";
import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";
import { AssignmentEntry } from "features/assignments/types";
import { useDeleteAssignmentEntryMutation } from "services/projectCity";

function ConfirmDeleteAssignmentEntryModal({ assignmentEntry, ...props }) {
  /** Confirm deletion of an assignment entry. */

  const { addToast } = useToasts();
  const [performDelete, result] = useDeleteAssignmentEntryMutation();

  async function handleDelete() {
    const response = await performDelete({
      assignmentEntryId: assignmentEntry.id,
    });
    props.onHide();
    if (response.error)
      addToast("Error deleting assignment.", { appearance: "error" });
    else addToast("Assignment has been deleted.", { appearance: "success" });
  }

  return (
    <ConfirmActionModal
      title="Delete assignment entry"
      confirmText="Yes, delete"
      isDanger
      confirmAction={handleDelete}
      isLoading={result.isLoading}
      {...props}
    >
      <ModalSummaryText>
        Are you sure you want to delete the "<b>{assignmentEntry.title}</b>"
        assignment entry? The action is permanent and can't be undone.
      </ModalSummaryText>
    </ConfirmActionModal>
  );
}

ConfirmDeleteAssignmentEntryModal.propTypes = {
  assignmentEntry: shape(AssignmentEntry).isRequired,
};

export default ConfirmDeleteAssignmentEntryModal;
