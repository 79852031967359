import styled from "styled-components";
import { Link } from "@reach/router";

import Sticker from "components/icons/Sticker";
import CopyHeader from "components/text/CopyHeader";
import Header from "components/text/Header";
import { useCanViewSprintSubscription } from "features/sprint/hooks";
import AssignmentList from "features/assignments/components/AssignmentList";
import { useGetAssignmentListQuery } from "services/projectCity";
import LoadingContainer from "components/loading/Container";
import ContentUploadIcon from "features/sprint/components/UploadIcon";
import { useModal } from "react-modal-hook";
import AssignmentModal from "features/assignments/modals/Assignment";
import { canCreateAssignments } from "utils/permissions";
import { useSelector } from "react-redux";
import SubscriptionButtonsContainer from "features/subscriptions/components/ButtonContainer";

const Wrapper = styled.div`
  text-align: center;
`;

const StyledCopyHeader = styled(CopyHeader)`
  transform: rotate(3deg);
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.vSpacingMd};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingXl};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingMd};
  }
`;

const SubscriptionSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    > div {
      padding: 0 50px;
    }
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
  }
`;

const StyledSticker = styled(Sticker)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const HeaderRow = styled.div`
  display: flex;

  > div {
    flex: 1 1 33%;
  }
`;

function SprintAssignmentsRoute() {
  /** Allow students to upload and review weekly assignments. */

  const { user } = useSelector((state) => state.account);
  const canView = useCanViewSprintSubscription();
  const { data, isLoading } = useGetAssignmentListQuery(
    {},
    {
      skip: !canView,
    }
  );

  const [showAssignmentModal, hideAssignmentModal] = useModal(() => (
    <AssignmentModal onHide={hideAssignmentModal} />
  ));

  if (!canView) {
    return (
      <Wrapper>
        <Header top="participate in" bottom="weekly assignments" />
        <div className="p-relative">
          <StyledSticker type="rainbow" />
          <StyledCopyHeader className="bg-success text-primary text-center p-2">
            Upload weekly assignments to share with the community
          </StyledCopyHeader>
        </div>
        <SubscriptionSection>
          <div className="mb-5">
            <SubscriptionButtonsContainer />
            <Link to="/sprint/content">Learn more</Link>
          </div>
        </SubscriptionSection>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div>
        {isLoading ? (
          <LoadingContainer text="Loading assignments" />
        ) : (
          <>
            <HeaderRow>
              <div>
                {canCreateAssignments(user) && (
                  <ContentUploadIcon onClick={showAssignmentModal} />
                )}
              </div>
              <Header top="weekly" bottom="assignments" />
              <div />
            </HeaderRow>
            <AssignmentList assignments={data} />
          </>
        )}
      </div>
    </Wrapper>
  );
}

export default SprintAssignmentsRoute;
