import { shape } from "prop-types";

import SquareMask from "images/masks/square.svg";
import { Contest } from "features/contests/types";
import MaskImage from "components/images/Mask";

function ContestImage({ contest, ...props }) {
  /** Thumbnail image for a contest. */

  return <MaskImage mask={SquareMask} {...props} src={contest.thumbnail} />;
}

ContestImage.propTypes = {
  /** The contest whose image is being shown. */
  contest: shape(Contest),
};

export default ContestImage;
