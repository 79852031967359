import React from "react";
import { useSelector } from "react-redux";
import { useModal } from "react-modal-hook";

import TierBucketSettingButton from "components/buttons/TierBucketSetting";
import BucketSlot from "features/tiers/components/BucketSlot";
import TierBucketsCard from "components/cards/TierBuckets";
import TierBucketCardBody from "components/cards/TierBuckets/Body";
import TierBucketCardTitle from "components/cards/TierBuckets/Title";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import InfoTooltip from "components/tooltips/Info";
import PublicBucketsModal from "features/buckets/modals/PublicBuckets";
import useEditRegistrationTier from "hooks/EditRegistrationTier";

function PublicBucketCard() {
  /** Display the public bucket settings for a project. */

  const project = useSelector((state) => state.projects.detail);
  const publicBuckets = project.buckets.filter((bucket) => bucket.isPublic);
  const setSelectedBucket = useEditRegistrationTier();

  const [showPublicTierModal, hidePublicTierModal] = useModal(() => {
    return (
      <PublicBucketsModal project={project} onHide={hidePublicTierModal} />
    );
  }, [project]);

  function renderBuckets() {
    /**
     * For the public view section, we need to return all the buckets that are public
     * as well as the main project info "bucket", which is not actually a bucket but is
     * displayed as such.
     */

    let publicBucketSlots = publicBuckets
      .filter((bucket) => bucket.isPublic)
      .map((bucket) => {
        return (
          <BucketSlot
            key={bucket.id}
            bucket={bucket}
            onClick={() => setSelectedBucket(bucket)}
          />
        );
      });

    return publicBucketSlots;
  }

  return (
    <TierBucketsCard className="bg-transparent">
      <TierBucketCardTitle className="m-3">
        <SubHeaderText inline color="lime" accentText="(free)">
          Public Tier
        </SubHeaderText>
        <InfoTooltip
          id="public view"
          text="Public pages can be seen by anyone."
          iconProps={{
            className: "ml-1 pb-1",
          }}
        />
      </TierBucketCardTitle>
      <TierBucketCardBody className="border-top">
        {renderBuckets()}
        <TierBucketSettingButton onClick={showPublicTierModal} />
      </TierBucketCardBody>
    </TierBucketsCard>
  );
}

export default PublicBucketCard;
