import { shape } from "prop-types";
import { useToasts } from "react-toast-notifications";

import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";
import { useDeleteUploadScheduleDateMutation } from "services/projectCity";
import { UploadScheduleDate } from "features/schedules/types";

function ConfirmDeleteUploadScheduleDateModal({
  uploadScheduleDate,
  ...props
}) {
  /** Allow the user to confirm deletion of an upload schedule date. */

  const [
    deleteUploadScheduleDate,
    result,
  ] = useDeleteUploadScheduleDateMutation();
  const { addToast } = useToasts();

  async function handleDelete() {
    addToast("Upload Schedule deleted.", { appearance: "success" });
    deleteUploadScheduleDate({ uploadScheduleDateId: uploadScheduleDate.id });
    props.onHide();
  }

  return (
    <ConfirmActionModal
      title="Delete upload schedule date?"
      confirmAction={handleDelete}
      isLoading={result.isLoading}
      isDanger
      {...props}
    >
      <ModalSummaryText>
        Are you sure you want to delete the upload schedule date? The action is
        irreversible.
      </ModalSummaryText>
    </ConfirmActionModal>
  );
}

ConfirmDeleteUploadScheduleDateModal.propTypes = {
  /** The content upload object that is being deleted. */
  uploadScheduleDate: shape(UploadScheduleDate).isRequired,
};

export default ConfirmDeleteUploadScheduleDateModal;
