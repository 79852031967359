import { useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormControl from "components/forms/shared/Control";
import DatePicker from "components/forms/datetime/DatePicker";
import ToggleBox from "components/controls/ToggleBox";
import { UploadSchedule } from "features/schedules/types";
import {
  useCreateUploadScheduleMutation,
  useUpdateUploadScheduleMutation,
} from "services/projectCity";
import { handleResponseError } from "utils/forms";

function UploadScheduleForm({ uploadSchedule, closeModal, ...props }) {
  /** Form to create or update an Upload Schedule. */

  const [formError, setFormError] = useState(null);

  const [
    updateUploadSchedule,
    updateResult,
  ] = useUpdateUploadScheduleMutation();
  const [
    createUploadSchedule,
    createResult,
  ] = useCreateUploadScheduleMutation();

  const isUpdating = uploadSchedule.id !== undefined;

  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: uploadSchedule || {},
  });

  const { addToast } = useToasts();

  function onSubmit(data) {
    // Clean the data that should be sent to server.
    if (moment.isMoment(data.endDate))
      data.endDate = data.endDate.format("YYYY-MM-DD");
    else if (data.endDate === "") data.endDate = null;

    // Either update or create the upload schedule.
    isUpdating
      ? updateUploadSchedule({ uploadScheduleId: uploadSchedule.id, ...data })
      : createUploadSchedule(data);
  }

  useEffect(() => {
    // Handle result of modifying the upload schedule.

    const result = isUpdating ? updateResult : createResult;
    const verb = isUpdating ? "updated" : "created";

    if (result.isSuccess) {
      closeModal();
      addToast(`Schedule ${verb}`, { appearance: "success" });
    } else if (result.isError) {
      handleResponseError(result, setFormError, setError);
    }
  }, [updateResult, createResult]);

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Title" errors={errors.title}>
        <Controller
          as={FormControl}
          name="title"
          rules={{ required: true }}
          control={control}
          isInvalid={errors.title !== undefined}
        />
      </FormGroup>
      <FormGroup label="Theme" errors={errors.theme}>
        <Controller
          as={FormControl}
          name="theme"
          control={control}
          isInvalid={errors.theme !== undefined}
        />
      </FormGroup>
      <FormGroup label="Contest" errors={errors.contest}>
        <Controller
          as={FormControl}
          name="contest"
          control={control}
          isInvalid={errors.contest !== undefined}
        />
      </FormGroup>
      <FormGroup label="Description" errors={errors.description}>
        <Controller
          as={FormControl}
          name="description"
          control={control}
          isInvalid={errors.description !== undefined}
        />
      </FormGroup>
      <FormGroup label="End Date" errors={errors.endDate}>
        <Controller
          as={DatePicker}
          name="endDate"
          control={control}
          isInvalid={errors.endDate !== undefined}
          initialValue={uploadSchedule.endDate}
          defaultValue={uploadSchedule.endDate}
        />
      </FormGroup>

      <ToggleBox
        label="Is Public?"
        onLabel="Yes"
        offLabel="No"
        controllerProps={{ name: "isPublic", control, defaultValue: true }}
      />

      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

UploadScheduleForm.propTypes = {
  closeModal: func.isRequired,
  uploadSchedule: shape(UploadSchedule),
};

UploadScheduleForm.defaultProps = {
  uploadSchedule: {},
};

export default UploadScheduleForm;
