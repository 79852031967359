import { number, string } from "prop-types";

export const Teacher = {
  id: number.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  image: string.isRequired,
  socialImage: string.isRequired,
  username: string.isRequired,
};
