import React from "react";
import { oneOf } from "prop-types";
import styled from "styled-components";

const StyledSpinner = styled(LoadingIcon)`
  ${({ size }) =>
    size === "sm" &&
    `
    width: 1rem;
    height: 1rem;
  `}

  ${({ size }) =>
    size === "md" &&
    `
    width: 3rem;
    height: 3rem;
  `}

  ${({ size }) =>
    size === "lg" &&
    `
    width: 5rem;
    height: 5rem;
  `}

  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

function LoadingSpinner({ size, ...props }) {
  return <StyledSpinner size={size} {...props}></StyledSpinner>;
}

LoadingSpinner.propTypes = {
  // The size of the loading spinner.
  size: oneOf(["sm", "md", "lg"]),
};

LoadingSpinner.defaultProps = {
  size: "md",
};

export default LoadingSpinner;

function LoadingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="30.45 33.76 173.31 170.64"
      {...props}
    >
      <path
        d="M177.832 58.49c16.91 16.7 25.216 39.573 25.883 61.912.666 22.338-5.804 45.974-21.676 63.362-15.872 17.389-38.381 20.028-59.872 20.485-21.491.458-46.029.877-62.939-15.823-16.91-16.7-28.07-41.991-28.754-64.329-.683-22.338 14.105-43 29.985-60.397 15.88-17.398 35.075-29.469 56.566-29.926 21.49-.458 43.905 8.026 60.807 24.717z"
        fill="#A8D29B"
      />
      <path
        d="M88.785 153.18c-3.35 12.018-2.757 7.223-5.627 19.366.774.205 19.889 6.288 42.114 6.288 22.224 0 39.3-5.593 40.063-5.81-2.882-12.132-2.175-7.291-5.513-19.309-.148.034-15.789 5.81-34.562 5.81-18.75.011-30.76-2.025-36.475-6.345z"
        fill="#FAFF29"
      />
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M88.511 137.034c13.653 0 24.72-11.068 24.72-24.72s-11.067-24.72-24.72-24.72c-13.652 0-24.719 11.068-24.719 24.72s11.067 24.72 24.72 24.72z"
          fill="#fff"
        />
        <path
          d="M91.986 128.547c9.506 0 17.212-7.706 17.212-17.212 0-9.507-7.706-17.213-17.212-17.213s-17.213 7.706-17.213 17.213c0 9.506 7.707 17.212 17.213 17.212z"
          fill="#FD7FBF"
        />
        <path
          d="M71.322 105.935s7.632-1.64 16.358 2.187l.831-20.527-17.19 18.34zM139.966 131.27c13.653 0 24.72-11.067 24.72-24.72 0-13.652-11.067-24.719-24.72-24.719-13.652 0-24.719 11.067-24.719 24.719 0 13.653 11.067 24.72 24.719 24.72z"
          fill="#fff"
        />
        <path
          d="M140.285 119.366c9.507 0 17.213-7.706 17.213-17.212s-7.706-17.213-17.213-17.213c-9.506 0-17.212 7.707-17.212 17.213s7.706 17.212 17.212 17.212z"
          fill="#FD7FBF"
        />
        <path
          d="M117.093 100.319s7.29-2.813 16.506-.398l-4.135-14.183-12.371 14.581z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(63.792 81.831)"
            d="M0 0h100.894v55.203H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
