import React, { useState } from "react";
import styled from "styled-components";
import { shape } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useModal } from "react-modal-hook";

import FormButton from "components/buttons/forms/Base";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormModal from "components/modals/Form";
import TextOnlyMessageForm from "components/forms/TextOnlyMessage";
import { removePreviewMessage } from "features/buckets/slice";
import { createTextOnlyMessage } from "features/textOnly/thunks";
import { TextOnlyMessage } from "types";

const FormSubmitWrapper = styled.div`
  > div {
    padding: 40px;
    padding-top: 0;
  }
`;

function TextOnlyMessagePreviewSubmitContainer({ message }) {
  /** Render a section for a user to perform actions on their previewed message. */

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const currentBucket = useSelector((state) => state.buckets.current);
  const [isSaving, setIsSaving] = useState(false);

  const [showUpdateModal, hideUpdateModal] = useModal(() => (
    <FormModal onHide={hideUpdateModal}>
      <TextOnlyMessageForm message={message} closeModal={hideUpdateModal} />
    </FormModal>
  ));

  function handleRemove() {
    /** Remove the preview message and it will not be saved. */
    dispatch(removePreviewMessage(message));
  }

  async function saveMessage() {
    /** Finish the preview process and save the message. */
    setIsSaving(true);
    const action = await dispatch(
      createTextOnlyMessage({
        payload: {
          message: message.message,
          isHidden: message.isHidden,
          bucket: currentBucket.id,
        },
      })
    );

    if (action.type.includes("fulfilled")) {
      addToast("Message has been saved", { appearance: "success" });
    }

    setIsSaving(false);
  }

  return (
    <FormSubmitWrapper>
      <FormSubmitContainer withTopDivider>
        <FormSecondaryButton onClick={handleRemove}>Remove</FormSecondaryButton>
        <div>
          <FormButton className="mr-2" onClick={showUpdateModal}>
            Update
          </FormButton>
          <FormPrimaryButton disabled={isSaving} onClick={saveMessage}>
            Finish
          </FormPrimaryButton>
        </div>
      </FormSubmitContainer>
    </FormSubmitWrapper>
  );
}

TextOnlyMessagePreviewSubmitContainer.propTypes = {
  /** The message object that we're previewing. */
  message: shape(TextOnlyMessage),
};

export default TextOnlyMessagePreviewSubmitContainer;
