import React from "react";
import { arrayOf, number, shape, string } from "prop-types";

import BaseTable from "components/tables/Base";
import ColumnHeader from "components/tables/ColumnHeader";
import { getTokenEarnedDollarAmount } from "features/donations/utils";

function DonationsBySeriesTable({ seriesData }) {
  /** Show aggregated doantions grouped by series (using project buckets as "series"). */

  function renderRows() {
    return seriesData.map(({ id, title, amount }) => (
      <tr key={id}>
        <td>{title || "without series"}</td>
        <td>{getTokenEarnedDollarAmount(amount)}</td>
      </tr>
    ));
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <ColumnHeader>Title</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </BaseTable>
  );
}

DonationsBySeriesTable.propTypes = {
  series: arrayOf(
    shape({
      id: number.isRequired,
      title: string.isRequired,
      amount: number.isRequired,
    }).isRequired
  ).isRequired,
};

export default DonationsBySeriesTable;
