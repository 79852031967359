import React from "react";
import styled from "styled-components";
import DashboardContainer from "../Dashboard";

const StyledDashboardContainer = styled(DashboardContainer)`
  padding-top: ${(props) => props.theme.vSpacingXl};
`;

function SecondaryDashboardContainer(props) {
  /** Container to use with dashboards that contain a secondary dashboard navigation. */

  return (
    <StyledDashboardContainer noMargin {...props}>
      {props.children}
    </StyledDashboardContainer>
  );
}

export default SecondaryDashboardContainer;
