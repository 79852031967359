import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";

const StyledModalHeader = styled(ModalHeader)`
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 22px;
  letter-spacing: 0.06em;
  color: ${(props) => props.theme.yellow};
  transform: matrix(1, 0.03, -0.06, 1, 0, 0);
`;

const StyledBaseModal = styled(BaseModal)`
  & .modal-dialog {
    max-width: 690px;
  }
`;

function FormModal({ title, subtitle, onHide, ...props }) {
  /* Provides a general modal designed to receive a Form in the modal body which handles the action,
     closing modal, and followup actions.
  */

  return (
    <StyledBaseModal {...props} onHide={onHide}>
      {title && (
        <StyledModalHeader
          title={title}
          closeButton={props.closeButton}
          closeModal={onHide}
        />
      )}
      {subtitle && <small className="text-center">{subtitle}</small>}
      <Modal.Body>{props.children}</Modal.Body>
    </StyledBaseModal>
  );
}

FormModal.propTypes = {
  // The title of the modal.
  title: PropTypes.string,

  // Extra text to put under the title.
  subtitle: PropTypes.string,
};

FormModal.defaultProps = {
  title: null,
  subtitle: null,
  closeButton: true,
  onHide: () => {},
};

export default FormModal;
