import React, { useState } from "react";
import { func, shape, string } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import Quill from "components/controls/Quill";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import { updateProject } from "features/projects/thunks";
import { Project } from "types";
import { getErrorMessage } from "utils/general";

function ProjectTextSectionForm({ project, field, closeModal, ...props }) {
  /** Form to update a static text section of a project (such as the footer). */

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm();

  async function onSubmit(payload) {
    const action = await dispatch(
      updateProject({ projectId: project.id, payload })
    );
    if (action.type.includes("fulfilled")) {
      addToast(`Updated successfully`, {
        appearance: "success",
      });
      reset();
      closeModal();
    } else if (action.type.includes("rejected")) {
      const message = getErrorMessage(
        action.payload.data,
        "Sorry, an error has occurred."
      );
      setFormError(message);
    }

    return action;
  }

  const titleField = `${field}Title`;
  const textField = `${field}Text`;

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup errors={errors[titleField]} label="Header">
        <Controller
          as={FormControl}
          rules={{
            required: true,
            maxLength: 128,
          }}
          name={titleField}
          defaultValue={project[titleField]}
          control={control}
          isInvalid={errors[titleField] !== undefined}
        />
      </FormGroup>
      <FormGroup errors={errors[titleField]} label="Content">
        <Controller
          render={({ onChange, onBlur, ref }) => (
            <Quill
              onBlur={onBlur}
              onChange={onChange}
              inputRef={ref}
              error={errors[textField]}
              defaultValue={project[textField]}
            />
          )}
          rules={{
            required: true,
          }}
          name={textField}
          defaultValue={project[textField]}
          control={control}
          isInvalid={errors[textField] !== undefined}
        />
      </FormGroup>

      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Save</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ProjectTextSectionForm.propTypes = {
  /** The id of the project whose section we're modifying. */
  project: shape(Project).isRequired,

  /** The base field name (which has its text/title derived from) */
  field: string.isRequired,

  /** Function to close the modal, if the form is in modal. */
  closeModal: func,
};

ProjectTextSectionForm.defaultProps = {
  closeModal: () => {},
};

export default ProjectTextSectionForm;
