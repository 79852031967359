import HeaderSection from "components/containers/HeaderSection";
import Sticker from "components/icons/Sticker";
import LoadingContainer from "components/loading/Container";
import Header from "components/text/Header";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import ContentUploadTile from "features/sprint/components/ContentUploadTile";
import { useGetMonthlyWinnerListQuery } from "services/projectCity";
import styled from "styled-components";
import { getMonthDisplay } from "utils/datetime";

const StyledSticker = styled(Sticker)`
  position: absolute;
  left: 0;
  top: 55px;
  max-width: 40%;
`;

const WinnerContainer = styled.div`
  display: grid;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: repeat(1, 1fr);
    margin: ${(props) => props.theme.vSpacingXs};
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: repeat(3, 1fr);
    margin: ${(props) => props.theme.vSpacingXl};
    grid-gap: 40px;
  }
`;

const WinnerTile = styled(ContentUploadTile)``;

const MonthHeader = styled(SubHeaderText)`
  grid-column: span 3;
  text-align: left;
  margin-top: ${(props) => props.theme.vSpacingMd};
  font-size: 4rem;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-column: span 1;
    margin-bottom: ${(props) => props.theme.vSpacingSm};
    text-align: center;
    font-size: 2.5rem;
  }
`;

function AnimaticWinnersRoute() {
  const { data, isLoading } = useGetMonthlyWinnerListQuery();

  if (isLoading) return <LoadingContainer />;

  function renderWinners() {
    /** Take the monthly winner data and render all the components. */

    let currentMonth = null;

    return data.map((winner) => {
      // Render the month header if it's a new month.
      const tile = (
        <WinnerTile key={winner.id} contentUpload={winner.contentUpload} />
      );

      if (winner.month !== currentMonth) {
        currentMonth = winner.month;
        return (
          <>
            <MonthHeader key={winner.id} text={winner.month}>
              {getMonthDisplay(winner.month)} {winner.year}
            </MonthHeader>
            {tile}
          </>
        );
      }

      return tile;
    });
  }

  return (
    <div>
      <div className="text-center p-relative">
        <StyledSticker />
        <HeaderSection>
          <div />
          <div>
            <Header top="monthly animatic" bottom="top 3" color="lime" />
          </div>
          <div />
        </HeaderSection>
        <div>
          <WinnerContainer>{renderWinners()}</WinnerContainer>
        </div>
      </div>
    </div>
  );
}

export default AnimaticWinnersRoute;
