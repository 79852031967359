import React, { useState } from "react";
import { shape } from "prop-types";
import { useModal } from "react-modal-hook";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import Moment from "react-moment";

import MasonryFooter from "components/masonry/Footer";
import BucketUploadModal from "components/modals/BucketUpload";
import ImageStackCarousel from "components/carousels/ImageStack";
import DeleteIcon from "components/icons/Delete";
import FormModal from "components/modals/Form";
import EditIcon from "components/icons/Edit";
import ConfirmActionModal from "components/modals/ConfirmAction";
import WebComicModal from "components/modals/WebComic";
import ModalSummaryText from "components/text/ModalSummaryText";
import {
  deleteBucketUpload,
  fetchBucketUpload,
} from "features/bucketUploads/thunks";
import BucketUploadForm from "features/bucketUploads/forms/BucketUpload";
import { removeBucketUpload } from "features/buckets/slice";
import { BucketUpload as BucketUploadShape } from "types";
import { bucketUploadType } from "utils/enums";

import StackImage from "../Display/StackImages";
import FileUploadDisplay from "../Display/FileUploadDisplay";
import ActionOverlayContainer from "components/containers/ActionOverlay";

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const CreatedDate = styled(Moment)`
  color: ${(props) => props.theme.gray3};
`;

function BucketUpload({ bucketUpload }) {
  /**
   * Render an individual bucket upload component, which also leads to functionality
   * depending on the bucket upload type.
   *
   * 1) An image stack will open up a modal to view the images in the stack
   * 2) A pdf bucket upload will expand the pdf to view it
   * 3) A video upload will create a video player to view the video player
   * 4) An audio upload will create an audio player to listen to the file
   */
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const userState = useSelector((state) => state.account);
  const [showActions, setShowActions] = useState(false);
  const projectState = useSelector((state) => state.projects.detail);

  const [showBucketUploadModal, hideBucketUploadModal] = useModal(() => {
    return (
      <BucketUploadModal
        bucketUpload={bucketUpload}
        onHide={hideBucketUploadModal}
      >
        <ImageStackCarousel bucketUpload={bucketUpload} />
      </BucketUploadModal>
    );
  }, [bucketUpload]);

  const getFiletypeDescription = function () {
    switch (bucketUpload.kind) {
      case bucketUploadType.video:
        return "video";
      case bucketUploadType.pdf:
        return "pdf";
      case bucketUploadType.imageStack:
        return "upload";
      default:
        return "file";
    }
  };

  async function handleDeleteBucketUpload() {
    setIsDeleting(true);
    const action = await dispatch(deleteBucketUpload(bucketUpload.id));
    setIsDeleting(false);
    if (action.type === "DELETE_BUCKET_UPLOAD/rejected") {
      addToast("Error deleting stack", {
        autoDismiss: true,
        appearance: "error",
      });
    } else if (action.type === "DELETE_BUCKET_UPLOAD/fulfilled") {
      addToast(`${getFiletypeDescription()} deleted successfully`, {
        autoDismiss: true,
        appearance: "success",
      });
      dispatch(removeBucketUpload({ bucketUploadId: bucketUpload.id }));
    }

    hideDeleteConfirmationModal();
  }

  const [
    showDeleteConfirmationModal,
    hideDeleteConfirmationModal,
  ] = useModal(() => {
    return (
      <ConfirmActionModal
        isDanger
        confirmAction={() => handleDeleteBucketUpload(bucketUpload.id)}
        onHide={hideDeleteConfirmationModal}
        bucketUpload={bucketUpload}
        isLoading={isDeleting}
        title="Confirm delete"
      >
        <ModalSummaryText>
          Are you sure you want to delete this {getFiletypeDescription()}?
        </ModalSummaryText>
      </ConfirmActionModal>
    );
  }, [bucketUpload]);

  const [showEditBucketUpdateModal, hideEditBucketUpdateModal] = useModal(
    () => (
      <FormModal
        title="Update Bucket Upload"
        onHide={hideEditBucketUpdateModal}
      >
        <BucketUploadForm
          bucketUpload={bucketUpload}
          closeModal={hideEditBucketUpdateModal}
        />
      </FormModal>
    ),
    [bucketUpload]
  );

  const [showWebComicModal, hideWebComicModal] = useModal(() => {
    return (
      <WebComicModal onHide={hideWebComicModal} bucketUpload={bucketUpload} />
    );
  });

  function launchImageStackCarousel() {
    /** Open the carousel to view the sorted images in the bucket upload. */
    dispatch(fetchBucketUpload(bucketUpload.id));
    showBucketUploadModal(bucketUpload);
  }

  function renderOverlaySection() {
    // Only show the delete section if the user has access to delete the bucket upload.
    const isAuthenticated = userState.token !== "";

    if (!isAuthenticated) return null;

    const isTeacher = projectState.teachers.some(
      (teacher) => teacher.id === userState.user.id
    );
    const isCreator = bucketUpload.createdBy.id === userState.user.id;

    if (isCreator || isTeacher) {
      return (
        <ActionOverlayContainer
          show={showActions}
          className="overlay-icon-container"
        >
          <EditIcon onClick={showEditBucketUpdateModal} />
          <DeleteIcon onClick={showDeleteConfirmationModal} />
        </ActionOverlayContainer>
      );
    }

    return null;
  }

  function renderDisplayContent() {
    // Returns the content to display for the bucket upload.
    switch (bucketUpload.kind) {
      case bucketUploadType.imageStack:
        return (
          <StackImage
            bucketUpload={bucketUpload}
            onClick={launchImageStackCarousel}
          />
        );
      case bucketUploadType.comic:
        return (
          <StackImage bucketUpload={bucketUpload} onClick={showWebComicModal} />
        );
      case bucketUploadType.pdf:
      case bucketUploadType.video:
      case bucketUploadType.audio:
      case bucketUploadType.zip:
        return <FileUploadDisplay bucketUpload={bucketUpload} />;
      default:
        return null;
    }
  }

  return (
    <Wrapper
      className="my-4"
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      {renderOverlaySection()}
      {renderDisplayContent()}
      <MasonryFooter
        left={
          !bucketUpload.isAnonymous
            ? `by ${bucketUpload.createdBy.username}`
            : ""
        }
        right={<CreatedDate fromNow>{bucketUpload.created}</CreatedDate>}
      />
    </Wrapper>
  );
}

BucketUpload.propTypes = {
  /** Bucket upload object which contains data for an image stack, pdf, video, etc. */
  bucketUpload: shape(BucketUploadShape),
};

export default BucketUpload;
