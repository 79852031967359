import React from "react";

import InfoTooltip from "components/tooltips/Info";

function FormLabelTooltip(props) {
  // An info tooltip designed to go next to a form label.
  return <InfoTooltip {...props} iconProps={{ className: "mb-1" }} />;
}

export default FormLabelTooltip;
