import React, { useCallback, useState, useRef } from "react";
import { bool, oneOf, shape } from "prop-types";
import styled from "styled-components";
import Image from "react-bootstrap/Image";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useDropzone } from "react-dropzone";

import ModifyButton from "components/buttons/Modify";
import LoadingContainer from "components/loading/Container";
import AbsoluteActionContainer from "components/containers/Actions";
import ActionContainer from "components/containers/Action";
import {
  updateProjectSectionImage,
  updateProjectSection,
} from "features/projects/thunks";
import DefaultImage from "images/default-img.jpg";
import { ProjectSection } from "types";

const ImageWrapper = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin: 50px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin: 20px 0;
    ${(props) => props.push === "left" && "margin-left: 10%"};
    ${(props) => props.push === "right" && "margin-right: 10%"};
  }
`;

const StyledImage = styled(Image)`
  margin-left: auto;
  margin-right: auto;
  border-radius: ${(props) => props.theme.borderRadius};

  ${(props) =>
    props.positiveRotate !== null &&
    `
    transform: ${props.positiveRotate ? "rotate(2deg);" : "rotate(-2deg)"};
  `}
`;

const StyledLabel = styled.label`
  height: 100%;
  display: flex;
  padding-top: 8px;
`;

const StyledActionContainer = styled(ActionContainer)`
  padding: 100px 0;
`;

function ProjectSectionImage({
  section,
  shouldViewAdminItems,
  push,
  positiveRotate,
}) {
  /** Render an image with uploader for a project */

  const [imageLoading, setImageLoading] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const sectionImage = useRef(null);
  const onDrop = useCallback((acceptedFiles) => {
    handleImageChange(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const { image } = section;

  async function handleImageChange(file) {
    /**
     * Event handler for when we update the project section image.
     *
     * This event is used both when a use drops a file or if they manually select.
     */

    setImageLoading(true);
    let formData = new FormData();
    formData.append("image", file);

    const actionPayload = {
      sectionId: section.id,
      formData,
    };
    const action = await dispatch(updateProjectSectionImage(actionPayload));

    setImageLoading(false);
    if (action.type === "UPDATE_PROJECT_SECTION/rejected")
      addToast("Error uploading file, please verify that it's a valid image.", {
        appearance: "error",
      });
  }

  function handleImageSelect(event) {
    /** Handle when the user manually selects a file to upload. */
    handleImageChange(event.target.files[0]);
  }

  if (!image && shouldViewAdminItems) {
    // Upload a new image to the section.
    return (
      <div>
        {imageLoading ? (
          <LoadingContainer />
        ) : (
          <div {...getRootProps()}>
            <input
              id="section-image"
              style={{ display: "none" }}
              type="file"
              onChange={handleImageSelect}
              {...getInputProps()}
              ref={sectionImage}
            />
            <StyledActionContainer
              onClick={() => {
                sectionImage.current.click();
              }}
              buttonText={isDragActive ? "Drop it!" : "Upload or drag image"}
              htmlFor="section-image"
            />
          </div>
        )}
      </div>
    );
  }

  function handleRemoveImage() {
    /** Remove an image from the section. */
    dispatch(
      updateProjectSection({
        payload: {
          sectionId: section.id,
          image: null,
        },
      })
    );
  }

  // Render what should be shown when there is an existing image.
  return shouldViewAdminItems ? (
    <>
      {image ? (
        imageLoading ? (
          <LoadingContainer />
        ) : (
          <ImageWrapper>
            <StyledImage
              fluid
              rounded
              src={image || DefaultImage}
              alt=""
              positiveRotate={positiveRotate}
            />
            <AbsoluteActionContainer bottom={-80}>
              <StyledLabel htmlFor="image">
                <ModifyButton />
              </StyledLabel>
              <ModifyButton type="delete" onClick={handleRemoveImage} />
            </AbsoluteActionContainer>
            <input
              id="image"
              style={{ display: "none" }}
              type="file"
              onChange={handleImageSelect}
            />
          </ImageWrapper>
        )
      ) : (
        <ActionContainer
          buttonText="Upload image"
          onClick={handleImageSelect}
        />
      )}
    </>
  ) : (
    <ImageWrapper push={push}>
      {image && (
        <StyledImage
          fluid
          src={image}
          alt="Project City"
          className="d-md-block"
          positiveRotate={positiveRotate}
        />
      )}
    </ImageWrapper>
  );
}

ProjectSectionImage.propTypes = {
  /** The section whose image we're rendering. */
  section: shape(ProjectSection).isRequired,

  /** Determine if the user should see the admin items for editing. */
  shouldViewAdminItems: bool.isRequired,

  /** On mobile we can push the image to the left or right of the screen. */
  push: oneOf(["left", "right"]),

  /** Give the image a positive or negative rotation. */
  positiveRotate: bool,
};

ProjectSectionImage.defaultProps = {
  push: null,
  positiveRotate: null,
};

export default ProjectSectionImage;
