import React from "react";
import styled from "styled-components";

import SubHeaderText from "components/text/SubHeader/TextOnly";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function TierBucketCardTitle(props) {
  /** Shared component for a tier bucket title. */

  return (
    <Wrapper>
      <SubHeaderText
        tilt
        color="lime"
        className={`m-3 mr-4 ${props.className}`}
        {...props}
      >
        {props.children}
      </SubHeaderText>
    </Wrapper>
  );
}

export default TierBucketCardTitle;
