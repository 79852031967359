import React from "react";
import { useModal } from "react-modal-hook";

import FormModal from "components/modals/Form";
import EmailForm from "components/forms/Email";
import { supportEmailUrl } from "features/emails/api";

function usePayButtonModal() {
  /** Bring up a support modal for the users to send contact email to support. */
  const [showPayButtonModal, hideSupportModal] = useModal(() => {
    return (
      <FormModal title="support" onHide={hideSupportModal}>
        <EmailForm
          closeModal={hideSupportModal}
          email={{
            to: "support@projectcity.tv",
            subject: "Pay button approval",
          }}
          helpText="Send us a note to request access to this feature."
          messagePlaceholder="Tell us what you would like to use pay buttons for"
          apiUrl={supportEmailUrl}
          toDisabled
        />
      </FormModal>
    );
  });

  return showPayButtonModal;
}

export default usePayButtonModal;
