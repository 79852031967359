import { bool, number, string } from "prop-types";

export const Account = {
  /** The stripe identifier for the account. */
  accountId: string.isRequired,

  /** Determine if the user has their account activated for payouts. */
  payoutsEnabled: bool.isRequired,

  /** The type of connected account the user has (only express currently) */
  type: number.isRequired,

  /** Identifier for the user's local account. */
  user: number.isRequired,
};
