import { number, shape, string } from "prop-types";

import { RegistrationTier, User } from "types";

export const Enrollment = {
  /** Identifier for the enrollment. */
  id: number.isRequired,

  /** Date string when the enrollment was created. */
  created: string.isRequired,

  /** The registration tier that the enrolment is for. */
  registrationTier: shape(RegistrationTier).isRequired,

  /** The status of the enrollment. */
  status: number.isRequired,

  /** The student that the enrollment belongs to. */
  student: shape(User).isRequired,
};
