import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import DashboardLoadingContainer from "components/loading/DashboardContainer";
import ContentSecondaryNavigation from "features/sprint/navbars/SecondaryNavbar";

const ContentContainer = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-top: 200px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-top: ${(props) => props.theme.mobileNavbarMarginBottom};
  }
`;

function ContentBaseRoute(props) {
  /**
   * Base route for all content routes.
   *
   * Perform any functions that are needed by all sprint routes.
   */

  const { isLoading } = useSelector((state) => state.account);

  if (isLoading) return <DashboardLoadingContainer text="Loading account..." />;

  return (
    <div>
      <ContentSecondaryNavigation activePathname={props.location.pathname} />
      <ContentContainer>{props.children}</ContentContainer>
    </div>
  );
}

export default ContentBaseRoute;
