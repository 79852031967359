import React from "react";
import styled from "styled-components";

import SuccessIcon from "images/success_not.png";
import FailIcon from "images/fail_not.PNG";
import InfoIcon from "images/info.png";
import CloseIcon from "images/icons/close_x.png";

const StyledToast = styled.div`
  font-family: Basis Grotesque Pro;
  display: flex;
  align-items: center;
  min-width: 50%;
  background-color: ${(props) =>
    props.appearance === "error"
      ? "#FF9FD4"
      : props.appearance === "success"
      ? "#A8D29B"
      : "#FFA87D"};
  padding: 0.4rem 1rem;
  padding-right: 3rem;
  position: relative;

  font-weight: 200;
  font-size: 22px;
  line-height: 26px;
`;

const ImageWrapper = styled.div`
  width: 35px;
  margin-right: 1rem;

  img {
    width: 100%;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  width: 15px;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

function CustomToast({ children, ...props }) {
  function getImageIcon() {
    switch (props.appearance) {
      case "success":
        return SuccessIcon;
      case "error":
        return FailIcon;
      default:
        return InfoIcon;
    }
  }

  return (
    <StyledToast appearance={props.appearance} {...props}>
      <ImageWrapper>
        <img src={getImageIcon()} alt="" />
      </ImageWrapper>
      <CloseBtn onClick={() => props.onDismiss()}>
        <img src={CloseIcon} alt="" />
      </CloseBtn>
      {children}
    </StyledToast>
  );
}

export default CustomToast;
