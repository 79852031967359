import React from "react";
import { arrayOf, shape, string } from "prop-types";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SubHeader from "components/text/SubHeader";
import SlickSlider from "components/carousels/SlickSlider";
import { Project } from "types";
import ProjectTile from "../Tile";

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.vSpacingLg};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: 60px;
  }
`;

const StyledSubHeader = styled(SubHeader)`
  width: 608px;
  height: 41px;
`;

const ClassProjectListWrapper = styled(Row)`
  > div {
    margin-bottom: ${(props) => props.theme.vSpacingSm};
  }
`;

function ClassProjectList({ projects, title, fip, ...props }) {
  /** Display a list of classes. */

  // Only show section if there are projects in it.
  if (!projects.length) return null;

  function renderSliderClasses() {
    /** Render the projects that are shown in the list. */
    return projects.map((project, index) => (
      <ProjectTile
        key={project.id}
        isEven={index % 2 === 0}
        project={project}
      />
    ));
  }

  function renderRowClasses() {
    return projects.map((project, index) => (
      <Col sm={3} key={project.id}>
        <ProjectTile isEven={index % 2 === 0} project={project} />
      </Col>
    ));
  }

  return (
    <Wrapper {...props}>
      <StyledSubHeader text={title} />
      {isMobile ? (
        <SlickSlider slidesToShow={isMobile ? 1.3 : 4} arrows={false}>
          {renderSliderClasses()}
        </SlickSlider>
      ) : (
        <ClassProjectListWrapper>{renderRowClasses()}</ClassProjectListWrapper>
      )}
    </Wrapper>
  );
}

ClassProjectList.propTypes = {
  /** The projects that should be displayed in the list. */
  projects: arrayOf(shape(Project)).isRequired,

  /** A label that describes the list of projects. */
  title: string.isRequired,
};

export default ClassProjectList;
