import React from "react";
import { bool } from "prop-types";
import styled from "styled-components";
import Badge from "react-bootstrap/Badge";
import { isMobile } from "react-device-detect";

import { useGetNotificationListQuery } from "services/projectCity";
import theme from "theme";

const Wrapper = styled.div`
  position: relative;
`;

const StyledSvg = styled.svg`
  ${(props) => props.clickable && "cursor: pointer;"}

  margin-left: 20px;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${(props) => props.theme.yellow};
  background-color: ${(props) => props.theme.dark};
  border-radius: 50%;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    top: -3px;
    right: -3px;
  }
`;

function NotificationButton({ hover, clickable, ...props }) {
  /** Button to open the notifications drawer. */

  const notifications = useGetNotificationListQuery();
  const notificationCount = notifications.data
    ? notifications.data.results.filter((notification) => !notification.seen)
        .length
    : 0;
  const dimension = isMobile ? 38 : 50;

  return (
    <Wrapper>
      {notificationCount > 0 && <StyledBadge>{notificationCount}</StyledBadge>}
      <StyledSvg
        width={dimension}
        height={dimension}
        viewBox="0 0 47 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        clickable={clickable}
        {...props}
      >
        <path
          d="M44.9991 23.4182C44.6888 29.3377 40.8093 33.8752 36.7263 37.5514C32.6433 41.2277 27.6862 45.3524 21.7667 45.0421C15.8472 44.7318 11.0532 40.3746 7.3768 36.2946C3.70035 32.2146 1.65436 27.082 1.96463 21.1625C2.27489 15.243 3.70288 9.08259 7.78273 5.40913C11.8626 1.73567 18.1503 0.792429 24.0698 1.1027C29.9893 1.41297 34.7801 4.23066 38.4564 8.31365C42.1327 12.3966 45.3092 17.5016 44.9991 23.4182Z"
          fill={hover ? theme.dark : theme.neonPink}
        />
        <g clipPath="url(#clip0_9_70)">
          <path
            d="M32.0464 32.9911C32.0657 33.8413 31.5517 34.0423 30.7054 33.9959C26.8835 33.7834 21.5042 33.138 17.6977 32.2569C15.92 31.8434 12.2952 31.3526 10.7339 30.6609C9.95719 30.317 9.8142 29.2426 10.2161 28.4968C11.1822 26.696 13.9067 25.2854 14.753 23.4537C16.3606 19.9757 17.5315 15.7209 20.5728 13.2941C21.8404 12.2854 24.1513 12.2738 25.4188 12.1541C25.0904 12.0072 25.2643 11.5435 25.187 11.3348C24.8083 10.3068 25.6855 8.51374 26.4816 8.17367C28.7152 7.22302 29.3219 10.3841 28.5413 12.212C28.4215 12.498 28.835 12.9424 28.7191 13.1549C28.7886 13.1975 28.692 13.2593 28.7461 13.3057C31.3933 15.5238 31.942 19.0444 31.5208 22.3794C31.2928 24.1879 30.8445 25.9463 30.4929 27.7123C30.1798 29.2967 32.0077 31.3526 32.0464 32.9911Z"
            fill={theme.yellow}
          />
        </g>
        <defs>
          <clipPath id="clip0_9_70">
            <rect
              width="22.0505"
              height="26"
              fill="white"
              transform="translate(10 8)"
            />
          </clipPath>
        </defs>
      </StyledSvg>
    </Wrapper>
  );
}

NotificationButton.propTypes = {
  /** Style changes for when the button is being hovered. */
  hover: bool.isRequired,

  /** Determine if the button is clickable, or should just be shown. */
  clickable: bool,
};

NotificationButton.defaultProps = {
  clickable: true,
  dimension: 50,
};

export default NotificationButton;
