import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import { NewsItem } from "features/news/types";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import { navigate } from "@reach/router";

const ModalBodyContainer = styled.div`
  font-family: Basis Grotesque Pro;
  color: ${(props) => props.theme.yellow};
  white-space: pre-line;

  p {
    font-size: 22px;
  }

  img {
    width: 100%;
    max-width: 100% !important;
    height: auto !important;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    p {
      font-size: 17px;
    }

    padding-left: 10px;
    padding-right: 10px;
  }
`;

function NewsItemModal({ newsItem, ...props }) {
  /** Display a news item for a user to view updated news of the site. */

  function handleClick() {
    navigate(newsItem.actionLink);
    props.onHide();
  }

  return (
    <BaseModal {...props} size="lg">
      <ModalHeader
        title={newsItem.title}
        closeModal={props.onHide}
        closeButton
      />
      <ModalBodyContainer>
        <div dangerouslySetInnerHTML={{ __html: newsItem.body }} />
      </ModalBodyContainer>
      {newsItem.actionLink && (
        <FormSubmitContainer>
          <FormPrimaryButton onClick={handleClick}>
            {newsItem.actionText || "OK"}
          </FormPrimaryButton>
        </FormSubmitContainer>
      )}
    </BaseModal>
  );
}

NewsItemModal.propTypes = {
  /** The news item object that is being displayed. */
  newsItem: shape(NewsItem).isRequired,
};

export default NewsItemModal;
