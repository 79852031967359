import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const StyledContainer = styled(Container)`
  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: ${(props) => props.theme.fullHdBreakpoint}) {
    width: 1490px;
  }
`;

const SharedContainer = ({ children }) => {
  return <StyledContainer fluid>{children}</StyledContainer>;
};

export default SharedContainer;
