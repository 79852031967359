import React from "react";
import { func, shape } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "components/buttons/Button";
import { useToasts } from "react-toast-notifications";

import { getFullName } from "utils/users";
import ButtonContainer from "components/forms/shared/ButtonContainer";
import ModalBackButton from "components/modals/BackButton";
import ModalSummaryText from "components/text/ModalSummaryText";
import { RevenueSplit } from "types";
import { useDeleteRevenueSplitMutation } from "services/projectCity";

function RevenueSplitConfirmDelete({ revenueSplit, successAction }) {
  /**
   * Return a section of the revenue split modal that will allow the user to delete a
   * revenue split.
   */

  const { addToast } = useToasts();
  const [deleteRevenueSplit] = useDeleteRevenueSplitMutation();

  function handleDelete() {
    // Delete the instance and navigate the user.
    deleteRevenueSplit({ id: revenueSplit.id });
    addToast("Revenue split removed", { appearance: "success" });
    successAction();
  }

  return (
    <div>
      <ModalSummaryText>
        Are you sure you want to remove the split for
        <b>&nbsp;{getFullName(revenueSplit.user)}?</b>
        &nbsp;They will no longer receive payments for new registrations.
      </ModalSummaryText>

      <ButtonContainer className="justify-content-between">
        <ModalBackButton onClick={successAction} />
        <Button color="#FAFF29" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashAlt} /> Yes, remove
        </Button>
      </ButtonContainer>
    </div>
  );
}

RevenueSplitConfirmDelete.propTypes = {
  /** The revenue split instance that will be deleted. */
  revenueSplit: shape(RevenueSplit).isRequired,

  /** Action to take after the instance is deleted. */
  successAction: func.isRequired,
};

export default RevenueSplitConfirmDelete;
