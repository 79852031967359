import React, { useState } from "react";
import { bool } from "prop-types";

import FormButton from "../Base";

function FormPrimaryButton({ children, isLoading, ...props }) {
  /** Primary button that is generally used to submit a form. */

  const [isHovered, setIsHovered] = useState(false);
  const variant = isHovered ? "secondary" : "primary";

  return (
    <FormButton
      type="submit"
      disabled={isLoading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      variant={variant}
      {...props}
    >
      {children}
    </FormButton>
  );
}

FormPrimaryButton.propTypes = {
  /** Shows if the button is currently handling an async action. */
  isLoading: bool,
};

FormPrimaryButton.defaultProps = {
  isLoading: false,
};

export default FormPrimaryButton;
