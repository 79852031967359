import React from "react";
import PropTypes from "prop-types";

import Button from "components/buttons/Button";
import FormModal from "components/modals/Form";

function ExternalLinkRedirectModal({ url, ...props }) {
  // If user click external url show him warning and ask him
  // if he wants to continue.

  function onConfirm() {
    window.open(url);
    props.onHide();
  }

  return (
    <FormModal
      {...props}
      title="This is an external link. You're leaving our site, no guarantees! Do you want to proceed?"
    >
      <Button onClick={onConfirm} className="ml-auto">
        Yes
      </Button>
    </FormModal>
  );
}

ExternalLinkRedirectModal.propTypes = {
  // external url to redirect user to.
  url: PropTypes.object.isRequired,
};

export default ExternalLinkRedirectModal;
