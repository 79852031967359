import React from "react";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";

import Button from "components/buttons/Button";
import usePayButtonModal from "hooks/PayButtonModal";
import ProjectManagementHeader from "features/projects/components/ManagementHeader";
import PublicSettingsCard from "features/tiers/components/PublicSettingsCard";
import TierSettingsCard from "features/tiers/components/TierSettingsCard";
import BucketSettingsCol from "features/tiers/components/BucketSettingsCol";
import RegistrationTierSetupModal from "features/tiers/modals/RegistrationTierSetup";
import AddTierSection from "features/tiers/components/AddTierSection";
import useCanCreateClass from "hooks/CanCreateClass";

const TierBucketSettingsContainer = styled(Row)`
  display: flex;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: 30px;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const ActionButton = styled(Button)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-right: 180px;
  }
`;

function ManageTiersRoute() {
  /** Manage the the tiers and buckets of a project. */

  const canCreateClass = useCanCreateClass();
  const project = useSelector((state) => state.projects.detail);
  const [showTierModal, hideTierModal] = useModal(() => {
    return (
      <RegistrationTierSetupModal onHide={hideTierModal} project={project} />
    );
  });

  function renderTierCards() {
    return project.tiers.map((tier) => {
      return (
        <BucketSettingsCol key={tier.id}>
          <TierSettingsCard registrationTier={tier} />
        </BucketSettingsCol>
      );
    });
  }

  const showSupportModal = usePayButtonModal();

  return (
    <>
      <HeaderContainer className="w-100 header">
        <ProjectManagementHeader
          title="Payment Tiers"
          tooltipText={`Payment Tiers allow you to create different levels of page
            access for different prices. Keep the default payment tiers and pages,
            make adjustments or create your own. You can also choose to make certain
            pages public (no purchase required)”.`}
        />
        <ActionButton
          onClick={canCreateClass ? showTierModal : showSupportModal}
          className="px-3"
        >
          ADD TIER
        </ActionButton>
      </HeaderContainer>
      <TierBucketSettingsContainer>
        <BucketSettingsCol>
          <PublicSettingsCard />
        </BucketSettingsCol>
        {renderTierCards()}
        <BucketSettingsCol>
          <AddTierSection
            onClick={canCreateClass ? showTierModal : showSupportModal}
          />
        </BucketSettingsCol>
      </TierBucketSettingsContainer>
    </>
  );
}

export default ManageTiersRoute;
