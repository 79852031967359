import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";

import { navigate } from "@reach/router";
import { ContentUpload } from "features/sprint/types";
import { getContentUploadUrl } from "features/sprint/utils";
import { useCanViewSprintSubscription } from "features/sprint/hooks";

const Wrapper = styled.div`
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.vSpacingMd};
  position: relative;
`;

function ContentUploadTileWrapper({ contentUpload, ...props }) {
  /** A wrapper to use for multiple different content upload tiles. */

  const hasSubscription = useCanViewSprintSubscription();

  function handleClick() {
    if (!hasSubscription) {
      // Non-subscribers should always go to the content upload page (will show subscribe info)
      navigate(getContentUploadUrl(contentUpload));
    } else if (contentUpload.file) {
      navigate(getContentUploadUrl(contentUpload));
    } else if (contentUpload.url) {
      const newWindow = window.open(
        contentUpload.url,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  }

  return (
    <Wrapper onClick={handleClick} {...props}>
      {props.children}
    </Wrapper>
  );
}

ContentUploadTileWrapper.propTypes = {
  content: shape(ContentUpload),
};

export default ContentUploadTileWrapper;
