import React from "react";
import { arrayOf, shape } from "prop-types";

import TableColumnHeader from "components/tables/ColumnHeader";
import BaseTable from "components/tables/Base";
import { DonationGroup } from "features/donations/types";
import { getTokenEarnedDollarAmount } from "features/donations/utils";

function DonationSummaryTable({ donationGroups }) {
  /** Table to show aggregated summaries of donations received for animatics. */

  function renderDonationGroupRows() {
    return donationGroups.map((donationGroup) => (
      <tr key={donationGroup.title}>
        <td>{donationGroup.title}</td>
        <td className="text-right">{donationGroup.sum}</td>
        <td className="text-right">
          {getTokenEarnedDollarAmount(donationGroup.sum)}
        </td>
      </tr>
    ));
  }

  return (
    <BaseTable withHeader>
      <thead>
        <tr>
          <TableColumnHeader>Animatic</TableColumnHeader>
          <TableColumnHeader className="text-right">
            Tokens Received
          </TableColumnHeader>
          <TableColumnHeader className="text-right">
            Dollars Received
          </TableColumnHeader>
        </tr>
      </thead>
      <tbody>{renderDonationGroupRows()}</tbody>
    </BaseTable>
  );
}

DonationSummaryTable.propTypes = {
  /** Donation groups (animatics) to be shown in the table. */
  donationGroups: arrayOf(shape(DonationGroup)).isRequired,
};

export default DonationSummaryTable;
