import React from "react";
import { shape } from "prop-types";
import Image from "react-bootstrap/Image";
import { BucketUpload } from "types";

function BucketUploadImage({ bucketUpload, ...props }) {
  /* Returns the image for a bucket upload with its proper overlay and styling. */

  // Sometimes the cover image might not be ready yet.
  if (bucketUpload.coverImage === null) return null;

  return (
    <div className="p-relative">
      <Image
        fluid
        className="w-100"
        src={bucketUpload.thumbnail || bucketUpload.coverImage.imageThumbnail}
        alt=""
        {...props}
      />
    </div>
  );
}

BucketUploadImage.propTypes = {
  bucketUpload: shape(BucketUpload).isRequired,
};

export default BucketUploadImage;
