import React, { useState } from "react";
import { bool, func, shape } from "prop-types";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";
import { isMobile } from "react-device-detect";

import DeleteIcon from "components/icons/Delete";
import EditIcon from "components/icons/Edit";
import OptionsIcon from "components/icons/Options";
import ReplyIcon from "components/icons/Reply";
import DeleteCommentModal from "features/comments/modals/DeleteComment";
import CommentOptionsModal from "features/comments/modals/Options";
import { useDeleteCommentMutation } from "services/projectCity";
import { Comment } from "types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
  }
`;

const DeleteWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.red};
`;

const ActionIconWrapper = styled.div`
  cursor: pointer;
  transition: transform ${(props) => props.theme.transitionSuperFast} ease;

  & img {
    width: 100%;
  }

  &:hover {
    transform: scale(1.1) rotate(-6deg);
  }
`;

function CommentActionContainer({
  comment,
  hideReplyInput,
  setHideReplyInput,
  edit,
  setEdit,
  isCommentAuthor,
}) {
  /** Container to show and handle actions on a given comment. */

  const { addToast } = useToasts();
  const [deleteComment] = useDeleteCommentMutation();

  const [isHoverEdit, setIsHoverEdit] = useState(false);
  const [isHoverReply, setIsHoverReply] = useState(false);
  const [isHoverDelete, setIsHoverDelete] = useState(false);

  const [showOptionsModal, hideOptionsModal] = useModal(() => {
    setEdit(false);
    setHideReplyInput(true);

    return (
      <CommentOptionsModal
        comment={comment}
        onHide={hideOptionsModal}
        handleDelete={() => {
          hideOptionsModal();
          showDeleteCommentModal();
        }}
        handleEdit={() => {
          hideOptionsModal();
          setEdit(true);
        }}
        handleReply={() => {
          hideOptionsModal();
          setHideReplyInput(false);
        }}
      />
    );
  }, [comment]);

  const [showDeleteCommentModal, hideDeleteCommentModal] = useModal(() => {
    /** Show a modal to confirm the deletion of a comment. */

    function handleDeleteComment() {
      deleteComment({
        commentId: comment.id,
        objectId: comment.contentObject.id,
        contentType: comment.contentType,
      });
      addToast("Comment has been deleted.", {
        appearance: "success",
      });
      hideDeleteCommentModal();
    }

    return (
      <DeleteCommentModal
        onHide={hideDeleteCommentModal}
        comment={comment}
        handleDelete={handleDeleteComment}
      />
    );
  }, [comment]);

  // Various situations of which buttons to show.
  const shouldShowReply = !(isMobile && isCommentAuthor);
  const shouldShowDelete = !isMobile && isCommentAuthor;
  const shouldShowEdit = shouldShowDelete;
  const shouldShowOptions = isMobile && isCommentAuthor;

  return (
    <Wrapper>
      {shouldShowReply && (
        <ActionIconWrapper
          onClick={() => setHideReplyInput(!hideReplyInput)}
          onMouseOver={() => setIsHoverReply(true)}
          onMouseOut={() => setIsHoverReply(false)}
        >
          <ReplyIcon hover={isHoverReply} dimension={43} />
        </ActionIconWrapper>
      )}

      {shouldShowDelete && (
        <DeleteWrapper position="50px">
          <ActionIconWrapper
            onClick={showDeleteCommentModal}
            onMouseOver={() => setIsHoverDelete(true)}
            onMouseOut={() => setIsHoverDelete(false)}
          >
            <DeleteIcon
              hover={isHoverDelete}
              includeSurround={false}
              dimension={50}
            />
          </ActionIconWrapper>
        </DeleteWrapper>
      )}

      {shouldShowEdit && (
        <ActionIconWrapper onClick={() => setEdit(!edit)}>
          <EditIcon includeSurround={false} dimension={50} />
        </ActionIconWrapper>
      )}

      {shouldShowOptions && (
        <ActionIconWrapper
          onClick={showOptionsModal}
          onMouseOver={() => setIsHoverEdit(true)}
          onMouseOut={() => setIsHoverEdit(false)}
        >
          <OptionsIcon
            hover={isHoverEdit}
            includeSurround={false}
            dimension={50}
          />
        </ActionIconWrapper>
      )}
    </Wrapper>
  );
}

CommentActionContainer.propTypes = {
  /** The comment that we're viewing the actions of. */
  comment: shape(Comment).isRequired,

  /** Hide the reply in parent component. */
  setHideReplyInput: func.isRequired,

  /** Determine if the reply is currently hidden. */
  hideReplyInput: bool.isRequired,

  /** Set the edit state in parent component. */
  setEdit: func.isRequired,

  /** Determine if the comment is currently being edited. */
  edit: bool.isRequired,

  /** The author has extra actions to modify comment. */
  isCommentAuthor: bool.isRequired,
};

export default CommentActionContainer;
