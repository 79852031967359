import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faSave } from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";

import FormControl from "components/forms/shared/Control";
import ModalBackButton from "components/modals/BackButton";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import {
  useCreatePostMutation,
  useUpdatePostMutation,
} from "services/projectCity";
import FormGroup from "../shared/FormGroup";
import FormSubmitContainer from "../shared/SubmitContainer";
import BaseForm from "../Base";

function PostForm({ post, closeModal, backAction, ...props }) {
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: post,
  });
  const [formError, setFormError] = useState(null);
  const { addToast } = useToasts();
  const [updatePost] = useUpdatePostMutation();
  const [createPost] = useCreatePostMutation();

  const isUpdating = post && post.id;

  async function onSubmit(data) {
    setFormError(null);
    let payload = {
      ...post,
      ...data,
    };

    const apiCall = isUpdating
      ? updatePost({ id: post.id, ...data })
      : createPost(payload);
    const verb = isUpdating ? "updating" : "creating";
    const successMessage = isUpdating
      ? "Your post has been updated!"
      : "Your upload has been shared!";

    try {
      await apiCall;
      closeModal();
      addToast(successMessage, { appearance: "success" });
    } catch (err) {
      setFormError(
        (err.response && err.response.data && err.response.data.detail) ||
          `Error ${verb} post`
      );
    }
  }

  function renderButtonText() {
    // Different text based on if we're creating or saving.
    return isUpdating ? (
      <span>
        <FontAwesomeIcon icon={faSave} /> Save
      </span>
    ) : (
      <span>
        <FontAwesomeIcon icon={faShare} /> Share
      </span>
    );
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Text" errors={errors.text}>
        <Controller
          autoFocus
          as={FormControl}
          name="text"
          placeholder="What are you sharing?"
          control={control}
          isInvalid={errors.text !== undefined}
          rules={{ required: true, maxLength: 1000 }}
        />
      </FormGroup>

      <FormSubmitContainer withTopDivider errorText={formError}>
        {backAction ? <ModalBackButton onClick={backAction} /> : <div />}
        <FormPrimaryButton isLoading={isSubmitting}>
          {renderButtonText()}
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

PostForm.propTypes = {
  /** The post for which we'll be creating or updating.  */
  post: PropTypes.object.isRequired,

  /** Action to close the modal after the post is created. */
  closeModal: PropTypes.object.isRequired,
};

export default PostForm;
