import { string } from "prop-types";
import React from "react";
import styled from "styled-components";

const BannerContainer = styled.div`
  position: absolute;
  max-width: 50%;

  ${(props) => {
    switch (props.position) {
      case "top-right":
        return `
          top: 0;
          right: -3%;
        `;
      default:
        return `
          bottom: 0;
          right: 0;
        `;
    }
  }}
`;

function TileOverlay({ position, ...props }) {
  /** An image overlay that should go over a tile image. */
  return <BannerContainer {...props} />;
}

TileOverlay.propTypes = {
  position: string,
};

TileOverlay.defaultProps = {
  position: "bottom-right",
};

export default TileOverlay;
