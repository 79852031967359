import React from "react";
import styled from "styled-components";

import Caption from "components/text/Caption";
import theme from "theme";

const Wrapper = styled.th`
  border: 0 !important;
`;

function ColumnHeader(props) {
  /** The table column header component. */

  return (
    <Wrapper {...props}>
      <Caption color={theme.lime}>{props.children}</Caption>
    </Wrapper>
  );
}

export default ColumnHeader;
