import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import ErrorText from "components/forms/shared/ErrorText";
import Select from "components/controls/Select";
import ModalBackButton from "components/modals/BackButton";
import { getFullName } from "utils/users";
import {
  useCreateRevenueSplitMutation,
  useUpdateRevenueSplitMutation,
} from "services/projectCity";

function RevenueSplitForm({
  revenueSplit,
  successAction,
  backAction,
  ...props
}) {
  // Form for updating or creating a revenue split.

  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: revenueSplit,
  });
  const { addToast } = useToasts();
  const project = useSelector((state) => state.projects.detail);
  const [formError, setFormError] = useState();
  const [createRevenueSplit, createResult] = useCreateRevenueSplitMutation();
  const [updateRevenueSplit, updateResult] = useUpdateRevenueSplitMutation();

  const isUpdating = "id" in revenueSplit;

  function handleResultChange(result) {
    /** Handle all state changes when creating or updating the revenue split. */
    const verb = isUpdating ? "updating" : "creating";

    if (result.isLoading) {
      setFormError(null);
    }

    if (result.isSuccess) {
      addToast(`Split percent ${verb} successfully`, { appearance: "success" });
      successAction();
    }

    if (result.isError) {
      let errorMessage = `Error ${verb} revenue split.`;
      const { payload } = result;
      if (payload && payload.data) {
        const { detail, nonFieldErrors } = payload.data;
        if (detail) errorMessage = detail;
        if (nonFieldErrors) errorMessage = nonFieldErrors;
      }
      setFormError(errorMessage);
    } else {
      if (formError !== null) setFormError(null);
    }
  }

  useEffect(() => {
    handleResultChange(createResult);
  }, [createResult]);

  useEffect(() => {
    handleResultChange(updateResult);
  }, [updateResult]);

  async function onSubmit(data) {
    // Send action to update or create the revenue split.
    setFormError(null);

    // Registration tier is not directly selected in the form.
    data.registrationTier = revenueSplit.registrationTier.id;

    if (isUpdating) {
      updateRevenueSplit({
        id: revenueSplit.id,
        ...data,
      });
    } else {
      createRevenueSplit({
        registrationTier: revenueSplit.registrationTier.id,
        ...data,
      });
    }
  }

  const teacherChoices = project.teachers.map((teacher) => ({
    label: getFullName(teacher),
    value: teacher.id,
  }));

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Percent split" errors={errors.splitPercent}>
        <Controller
          as={<FormControl type="number" min="0" max="100" step="1" />}
          rules={{ required: true }}
          name="splitPercent"
          control={control}
          defaultValue=""
          isInvalid={errors.splitPercent !== undefined}
        />
      </FormGroup>
      {!isUpdating && (
        <FormGroup label="User">
          <Controller
            render={({ onChange }) => (
              <Select
                options={teacherChoices}
                onChange={(selected) => onChange(selected.value)}
              />
            )}
            name="user"
            control={control}
          />
        </FormGroup>
      )}
      <ErrorText text={formError} />
      <FormSubmitContainer className="justify-content-between">
        {backAction && <ModalBackButton onClick={backAction} />}
        <FormPrimaryButton isLoading={isSubmitting} type="submit">
          {isUpdating ? "Save" : "Create"}
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

RevenueSplitForm.propTypes = {
  // The revenue split instance that we're updating.
  revenueSplit: PropTypes.object.isRequired,

  // Action to take after the revenue split is created/updated.
  successAction: PropTypes.func.isRequired,

  // Action to return to the previous section of the modal.
  backAction: PropTypes.func.isRequired,
};

export default RevenueSplitForm;
