import React from "react";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import ComingSoon from "images/stickers/coming_soon.svg";
import { bool, string } from "prop-types";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  transform: rotate(${(props) => (props.positiveRotate ? "" : "-")}20deg);
`;

const InnerText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;

  > p {
    margin: 0;
  }
`;

const Top = styled.p`
  font-family: Basis Grotesque Mono;
  text-transform: uppercase;
  font-size: 1.5vw;
  line-height: 4vh;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    line-height: 30px;
    font-size: 30px;
  }
`;

const Bottom = styled.p`
  font-family: Cooper Std;
  font-size: 2.5vw;
  line-height: 4vh;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    line-height: 35px;
    font-size: 40px;
  }
`;

function Splat({ top, bottom, positiveRotate }) {
  /** Display some text in the middle of a splat icon. */

  return (
    <Wrapper positiveRotate={positiveRotate}>
      <Image fluid src={ComingSoon} alt="" />
      <InnerText>
        <Top>{top}</Top>
        <Bottom>{bottom}</Bottom>
      </InnerText>
    </Wrapper>
  );
}

Splat.propTypes = {
  /** Text to show on the top row of the splat. */
  top: string,

  /** Text to show on the bottom row of the splat. */
  bottom: string,

  /** Determine which direction the rotate should be. */
  positiveRotate: bool,
};

Splat.defaultProps = {
  top: "",
  bottom: "",
  positiveRotate: true,
};

export default Splat;
