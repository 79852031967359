import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "images/icons/close_x.svg";

const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 0px !important;

  .modal-title {
    width: 100%;
  }
`;

const StyledCloseButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  position: absolute;
  transition: transform ease-in 100ms;

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    top: 30px;
    right: 30px;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    right: 30px;
    top: 30px;
  }

  &:hover {
    transform: rotate(-35deg);
  }

  &:hover path {
    fill: ${(props) => props.theme.yellow};
  }
`;

const StyledModalTitle = styled(Modal.Title)`
  color: ${(props) => props.theme.yellow};
  font-family: "SuisseIntl";
  font-weight: 900;
  font-size: 34px;
  transform: matrix(1, 0.03, -0.06, 1, 0, 0);

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 28px;
    line-height: 28px;
    padding-left: 20px;
    padding-right: 20px;

    ${(props) =>
      props.$hasTopMargin &&
      `
      margin-top: 50px;
    `}
  }
`;

function ModalHeader({ title, closeButton, closeModal, ...props }) {
  if (!title) return null;

  return (
    <StyledModalHeader {...props}>
      <StyledModalTitle $hasTopMargin={closeButton !== null}>
        {title}
      </StyledModalTitle>
      {closeButton && (
        <StyledCloseButton onClick={closeModal}>
          <CloseIcon />
        </StyledCloseButton>
      )}
    </StyledModalHeader>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  closeButton: PropTypes.bool,
  closeModal: PropTypes.func,
};

ModalHeader.defaultProps = {
  title: null,
  closeButton: null,
  closeModal: () => {},
};

export default ModalHeader;
