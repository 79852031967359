import React, { useState } from "react";
import { Accordion, Button, Card, Container } from "react-bootstrap";
import styled from "styled-components";

import Body from "components/text/Body";
import SubHeader from "components/text/SubHeader";
import faqBanner from "images/faq_banner.png";
import DownArrowImg from "./images/down_arrow.svg";

const StyledCardHeader = styled(Card.Header)`
  padding: 0;
  border-radius: 3px;
  font-size: 17px;
  line-height: 18px;
  font-weight: 300;

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 21px;
    line-height: 21px;
  }
`;

const SectionHeader = styled(SubHeader)`
  margin-top: ${(props) => props.theme.vSpacingLg};
`;

const StyledCard = styled(Card)`
  margin-top: 20px;
  border-radius: 3px !important;
  background: transparent;
  border-width: ${(props) => (props.active ? "4px" : "0")};
  border-color: ${(props) =>
    props.active ? props.theme.yellow : "transparent"};
`;

const StyledToggle = styled(Button)`
  padding: 20px;
  width: 100%;
  text-align: left;
  background: transparent;
  font-size: 21px;
  text-decoration: none;
  color: ${(props) =>
    props.active ? props.theme.neonPink : props.theme.yellow};
  background: ${(props) =>
    props.active ? props.theme.yellow : props.theme.neonPink};
  font-family: "Basis Grotesque";
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  border-radius: 3px;
  display: flex;

  &:hover {
    background-color: ${(props) => props.theme.yellow};
    color: ${(props) => props.theme.neonPink};
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &.active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 17px;
    line-height: 18px;
  }
`;

const StyledDownArrowImg = styled.img`
  display: block;
  margin-left: 10px;
  margin-left: auto;
  transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0deg)")};
`;

const StyledBanner = styled.img`
  width: 100%;
`;

const StyledContainer = styled(Container)`
  padding-top: 136px;
  padding-bottom: 300px;
  max-width: 885px;
`;

const StyledCardBody = styled(Body)`
  color: ${(props) => props.theme.pink};
  padding: 22px;
  border-color: ${(props) =>
    props.active ? props.theme.yellow : "transparent"};
  border-width: 4px;
`;

const StyledAccordionCollapse = styled(Accordion.Collapse)`
  border: 4px solid ${(props) => props.theme.yellow};
`;

function PrivacyPolicyRoute() {
  const [generalActive, setGeneralActive] = useState(null);
  const [classActive, setClassActive] = useState(null);
  const [projectActive, setProjectActive] = useState(null);
  const [teacherActive, setTeacherActive] = useState(null);
  return (
    <StyledContainer>
      <StyledBanner
        src={faqBanner}
        alt="FAQ banner"
        className="d-block mx-auto"
      />
      <SectionHeader text="general" />
      <Accordion onSelect={(e) => setGeneralActive(e)}>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="0"
              active={generalActive === "0"}
            >
              What is this site for?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={generalActive === "0"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="0">
            <StyledCardBody active={generalActive === "0"}>
              Project City lets you learn how to make animation by working on
              your own projects! We offer classes that walk you through the
              different stages of the production process, from writing, to
              storyboarding, to editing. Joining our community lets you share
              your homework to the feed to get feedback on your art.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="1"
              active={generalActive === "1"}
            >
              Where’s the community at?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={generalActive === "1"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="1">
            <StyledCardBody>
              <a
                href="https://discord.com/invite/aajS4qwSfz"
                target="_blank"
                rel="noreferrer"
              >
                Join us on Discord
              </a>
              &nbsp; to share your art, make new friends, and hang out with
              fellow animators and artists!
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="2"
              active={generalActive === "2"}
            >
              How do I edit my Profile?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={generalActive === "2"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="2">
            <StyledCardBody>
              Click your profile photo in the top right section of the nav bar
              &#x3e; Profile &#x3e; Edit Profile.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
      </Accordion>
      <SectionHeader text="classes" />
      <Accordion onSelect={(e) => setClassActive(e)}>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="0"
              active={classActive === "0"}
            >
              Where are the classes I bought?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={classActive === "0"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="0">
            <StyledCardBody>
              Access class recordings and links to live classes by clicking the
              gray drop down button in the top left corner of your class. We’re
              aware that this can be a little tricky to find and are working on
              making this more clear.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="1"
              active={classActive === "1"}
            >
              How long can I access recordings for?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={classActive === "1"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="1">
            <StyledCardBody>
              You can access class recordings as long as your account is active,
              unless stated otherwise by the teacher.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="2"
              active={classActive === "2"}
            >
              How do I contact my teacher?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={classActive === "2"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="2">
            <StyledCardBody>
              If you are enrolled in a class, you'll see a "Teacher" button near
              the top of the class page.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
      </Accordion>
      <SectionHeader text="projects" />
      <Accordion onSelect={(e) => setProjectActive(e)}>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="0"
              active={projectActive === "0"}
            >
              How do I create a project?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={projectActive === "0"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="0">
            <StyledCardBody>
              Click the plus button in the nav bar, choose a project template,
              and follow the steps to complete the process.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="2"
              active={projectActive === "2"}
            >
              What are "Pages"?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={projectActive === "2"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="2">
            <StyledCardBody>
              A "Page" is a space for you to share content inside a project.
              This content can be uploaded by teachers, students, creators, fans
              - whoever the creator of the project has granted access to. Each
              "Page" can be expressed in different organizational styles. Only
              creators of a particular project can create and edit the pages for
              that class.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="3"
              active={projectActive === "3"}
            >
              What files can I upload to my project?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={projectActive === "3"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="3">
            <StyledCardBody>
              We support photos, pdfs, mp3s, and videos up to 10 mb.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="4"
              active={projectActive === "4"}
            >
              How do I upload files to my project?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={projectActive === "4"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="4">
            <StyledCardBody>
              You first need to create a page as a container to store your
              content. Click the gray dropdown in the top left corner of your
              project, then the blue "Create Page" link
              <br />
              Add files to this page by clicking the big plus button in the
              bottom left corner of the page.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="5"
              active={projectActive === "5"}
            >
              How do I delete a project?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={projectActive === "5"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="5">
            <StyledCardBody>
              Select your project from your profile, click the blue "Project
              Management" button on the top right, then the red "Delete Project"
              button on the top left.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
      </Accordion>
      <SectionHeader text="for teachers" />
      <Accordion onSelect={(e) => setTeacherActive(e)}>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="0"
              active={teacherActive === "0"}
            >
              What is the process for creating and selling a class?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={teacherActive === "0"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="0">
            <StyledCardBody>
              Step 1 - Create Class
              <p style={{ marginBottom: "0.14in" }}>
                <ol>
                  <li>Click the plus button in the nav bar. </li>
                  <li>Select the Live Class template. </li>
                </ol>
              </p>
              <p style={{ marginBottom: "0.14in" }}></p>
              Step 2 - Fill out Details
              <p style={{ marginBottom: "0.14in", marginLeft: "40px" }}>
                Add your class name, headline, poster image, and description.
              </p>
              Step 3 - Connect your Payment Info
              <p style={{ marginBottom: "0.14in", marginLeft: "40px" }}>
                In class Management, select Payments, then the yellow "Connect"
                button to connect your Stripe account.
              </p>
              <p style={{ marginBottom: "0.14in" }}>Step 4 - Get Approved</p>
              <p style={{ marginBottom: "0.14in" }}>Step 5 - Make Public</p>
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="1"
              active={teacherActive === "1"}
            >
              What are "Tiers"?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={teacherActive === "1"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="1">
            <StyledCardBody>
              "Tiers" create different levels of access to a project or class.
              As a teacher, you can create different tiers of access to your
              class.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="2"
              active={teacherActive === "2"}
            >
              How do I find a student in my class?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={teacherActive === "2"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="2">
            <StyledCardBody>
              <ol>
                <li>In your class, click "Class Management". </li>
                <li>Click "Students". </li>
                <li>Click "Filter". </li>
                <li>
                  In the "Name" field, enter the username or email associated
                  with the student you’re looking for.
                </li>
              </ol>
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="3"
              active={teacherActive === "3"}
            >
              How do I email all of my students?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={teacherActive === "3"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="3">
            <StyledCardBody>
              <ol>
                <li>In your class, click "Class Management" </li>
                <li>Click "Students". </li>
                <li>
                  Check the white box on the top left part of the table
                  underneath "Students" to select all of your students.
                </li>
                <li>Click the blue "Actions" button, then "Email".</li>
              </ol>
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="4"
              active={teacherActive === "4"}
            >
              How do I manually add a student?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={teacherActive === "4"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="4">
            <StyledCardBody>
              <ol>
                <li>In your class, click "Class Management". </li>
                <li>Click "Students". </li>
                <li>
                  Click the blue "Actions" button, then "+Student". The student
                  needs to already have created an account for you to add them
                </li>
              </ol>
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader>
            <Accordion.Toggle
              as={StyledToggle}
              variant="link"
              eventKey="5"
              active={teacherActive === "5"}
            >
              How do I refund a student?
              <StyledDownArrowImg
                src={DownArrowImg}
                alt="Down Arrow"
                active={teacherActive === "5"}
              />
            </Accordion.Toggle>
          </StyledCardHeader>
          <StyledAccordionCollapse eventKey="5">
            <StyledCardBody>
              <ol>
                <li>In your class, click "Class Management". </li>
                <li>Click "Students". </li>
                <li>Click "Filter". </li>
                <li>
                  In the "Name" field, enter the username or email associated
                  with the student you’re looking for.
                </li>
                <li>
                  Click the three vertical dots to the right of the student
                  underneath "Status".
                </li>
                <li>Confirm you want to refund the student. </li>
              </ol>
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
        <StyledCard>
          <StyledAccordionCollapse eventKey="6">
            <StyledCardBody>
              Click the gray drop down button on the top left part of your class
              page.
            </StyledCardBody>
          </StyledAccordionCollapse>
        </StyledCard>
      </Accordion>
    </StyledContainer>
  );
}

export default PrivacyPolicyRoute;
