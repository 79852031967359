import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Checkbox from "components/controls/Checkbox";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import SubmitContainer from "components/forms/shared/SubmitContainer";
import FormHelpText from "components/forms/shared/HelpText";
import Body from "components/text/Body";
import { updateBucketUpload } from "features/bucketUploads/thunks";
import GenericImage from "images/webcomic.svg";
import GenericImageSet from "images/tapthrough.svg";
import { bucketUploadType } from "utils/enums";

import BaseModal from "../Base";
import ModalHeader from "../Header";

const ChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${(props) => props.theme.blue};
  padding: ${(props) => props.theme.vSpacingSm};
  margin: ${(props) => props.theme.vSpacingSm} 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 50%;

  > h5 {
    margin: 5px 0 0 0;
  }
`;

const CheckboxInner = styled.div`
  padding-bottom: ${(props) => props.theme.vSpacingSm};
`;

function BucketUploadImageSetChoiceModal({
  bucketUpload,
  showConfirmShareModal,
  ...props
}) {
  // Gives the user a choice of what layout they'd like their image set to be displayed in.
  const dispatch = useDispatch();
  const [isTapThrough, setIsTapThrough] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  function transitionToShare() {
    props.onHide();
    showConfirmShareModal();
  }

  async function handleSave() {
    // If the user wants a webcomic version, then we'll need to update the bucketUpload instance.
    if (isTapThrough) return transitionToShare();

    setIsUpdating(true);

    await dispatch(
      updateBucketUpload({
        bucketUploadId: bucketUpload.id,
        payload: {
          kind: bucketUploadType.comic,
        },
      })
    );

    setIsUpdating(false);
    transitionToShare();
  }

  return (
    <BaseModal {...props}>
      <ModalHeader title="image stack" closeButton closeModal={props.onHide} />
      <FormHelpText>Which format would you like to use?</FormHelpText>
      <ChoiceContainer>
        <CheckboxContainer>
          <CheckboxInner>
            <Checkbox
              value={isTapThrough}
              defaultChecked={true}
              containerProps={{
                onClick: () => setIsTapThrough(true),
              }}
            />
          </CheckboxInner>
          <Body>Tap through</Body>
        </CheckboxContainer>
        <Image src={GenericImageSet} />
      </ChoiceContainer>
      <ChoiceContainer>
        <CheckboxContainer>
          <CheckboxInner>
            <Checkbox
              value={!isTapThrough}
              defaultChecked={false}
              containerProps={{
                onClick: () => setIsTapThrough(false),
              }}
            />
          </CheckboxInner>
          <Body>Web comic</Body>
        </CheckboxContainer>
        <Image src={GenericImage} />
      </ChoiceContainer>
      <SubmitContainer className="p-3">
        <FormPrimaryButton onClick={handleSave} isLoading={isUpdating}>
          Continue <FontAwesomeIcon icon={faChevronRight} />
        </FormPrimaryButton>
      </SubmitContainer>
    </BaseModal>
  );
}

BucketUploadImageSetChoiceModal.propTypes = {
  // The bucket upload that will be updated.
  bucketUpload: PropTypes.object.isRequired,

  // Transition to the confirm share modal, which needs to be called from outside of this modal.
  showConfirmShareModal: PropTypes.func.isRequired,
};

export default BucketUploadImageSetChoiceModal;
