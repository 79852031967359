import React, { forwardRef, useRef, useState, useEffect } from "react";
import styled from "styled-components";

import Body from "components/text/Body";
import useCombinedRefs from "hooks/CombinedRefs";

const Label = styled(Body)`
  line-height: 22px;
  color: ${(props) => props.theme.yellow}!important;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
  }

  input ~ .checkmark {
    border: 3px solid ${(props) => props.theme.blue};
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.yellow};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 9px;
    border: solid ${(props) => props.theme.blue};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const CombinedRefCheckbox = forwardRef(
  (
    {
      label,
      name,
      value,
      onChange,
      defaultChecked,
      labelProps,
      containerProps,
      ...rest
    },
    forwardedRef
  ) => {
    /**
     * A custom checkbox compatible with react-hook-form.
     *
     * https://www.alltimepower.com/blog/react-hook-forms-custom-checkbox/
     */

    const [checked, setChecked] = useState(defaultChecked);
    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);

    useEffect(() => {
      const setCheckedInput = (checked) => {
        if (innerRef.current.checked !== checked) {
          // just emulate an actual click on the input element
          innerRef.current.click();
        }
      };

      setCheckedInput(checked);
      if (onChange) onChange(checked);
    }, [onChange, checked]);

    return (
      <Wrapper onClick={() => setChecked(!checked)} {...containerProps}>
        <input
          ref={combinedRef}
          type="checkbox"
          name={name}
          value={value}
          checked={value}
          onChange={(e) => setChecked(e.target.checked)}
          {...rest}
        />
        <span class="checkmark"></span>
        <Label variant="primary" {...labelProps}>
          {label}
        </Label>
      </Wrapper>
    );
  }
);

export default CombinedRefCheckbox;
