import { useEffect, useState } from "react";
import { func, number, shape } from "prop-types";
import { useToasts } from "react-toast-notifications";

import FormModal from "components/modals/Form";
import AssignmentEntryForm from "features/assignments/forms/Entry";
import { AssignmentEntry } from "features/assignments/types";
import ModalSummaryText from "components/text/ModalSummaryText";
import projectCityApi, {
  useCreateAssignmentEntryFileMutation,
} from "services/projectCity";
import { generateFormData } from "utils/forms";

function AssignmentEntryModal({
  assignmentId,
  assignmentEntry,
  afterCreate,
  ...props
}) {
  /**
   * Modal for assignment entry creation and updating.
   *
   * There needs to be multiple steps in case of multiple files being uploaded to the entry.
   */
  const [files, setFiles] = useState([]);
  const [shareToGlobalFeed, setShareToGlobalFeed] = useState(false);
  const [createdAssignmentEntry, setAssignmentEntry] = useState(null);
  const { addToast } = useToasts();
  const [createAssignmentEntryFile] = useCreateAssignmentEntryFileMutation();
  const [
    reloadEntries,
  ] = projectCityApi.endpoints.getAssignmentEntryList.useLazyQuery();

  // Determine if we're uploading files, or creating/updating an assignment entry.
  const isUploadingFiles = files.length > 0 && createdAssignmentEntry !== null;

  useEffect(() => {
    /** Upload the file objects after the entry has been created/updated. */
    if (isUploadingFiles) {
      handleFileUpload();
    }
  }, [files, createdAssignmentEntry]);

  async function handleFileUpload() {
    const fileArray = [...files];
    const promiseArray = fileArray.map((file) => {
      const formData = generateFormData({
        file,
        upload: createdAssignmentEntry.id,
      });
      return createAssignmentEntryFile(formData);
    });

    await Promise.all(promiseArray).then((values) => {
      setFiles([]);
      setAssignmentEntry(null);
      addToast("Assignment entry submitted!", { appearance: "success" });
      afterCreate(createdAssignmentEntry, shareToGlobalFeed);
      props.onHide();
      reloadEntries({ assignment__id: assignmentId });
    });
  }

  function getTitle() {
    if (isUploadingFiles) return "Finishing upload...";
    else
      return assignmentId
        ? "Create assignment entry"
        : "Update assignment entry";
  }

  return (
    <FormModal onHide={props.onHide} title={getTitle()}>
      {isUploadingFiles ? (
        <ModalSummaryText>
          Just one moment, uploading {files.length} files...
        </ModalSummaryText>
      ) : (
        <AssignmentEntryForm
          closeModal={props.onHide}
          assignmentId={assignmentId}
          assignmentEntry={assignmentEntry}
          setFiles={setFiles}
          setAssignmentEntry={setAssignmentEntry}
          setShareToGlobalFeed={setShareToGlobalFeed}
        />
      )}
    </FormModal>
  );
}

AssignmentEntryModal.propTypes = {
  /** Assignment entry object if updating one. */
  assignmentEntry: shape(AssignmentEntry),

  /** Function to close the modal. */
  onHide: func.isRequired,

  /** The identifier of the assignment, if creating a new entry. */
  assignmentId: number,

  /** Action to take after creating an assignment entry. */
  afterCreate: func,
};

AssignmentEntryModal.defaultProps = {
  assignmentEntry: null,
  assignmentId: null,
  afterCreate: () => {},
};

export default AssignmentEntryModal;
