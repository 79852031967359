import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Container from "react-bootstrap/Container";

import LoadingContainer from "components/loading/Container";
import PaymentAccountTable from "components/tables/PaymentAccounts";
import { fetchProjectAccounts } from "features/projects/thunks";
import ProjectManagementHeader from "features/projects/components/ManagementHeader";

const Wrapper = styled.div`
  min-height: 94vh;
`;

function ManagePaymentsRoute() {
  /** Manage the project's payment settings and view their payment data. */

  const project = useSelector((state) => state.projects.detail);
  const [accountsAreLoading, setAccountsAreLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    /* When the project has an account but is not yet marked as payments enabled, we need to get the
       data from stripe to see if it is now enabled.
    */
    async function _fetchProjectAccounts() {
      // Fetch the account revenue split data.
      setAccountsAreLoading(true);
      await dispatch(fetchProjectAccounts({ projectId: project.id }));
      setAccountsAreLoading(false);
    }

    _fetchProjectAccounts();
  }, [dispatch, project.id]);

  // TODO: Need to check if there are invalid revenue splits
  const showUnconnectedWarning = false;

  // const showUnconnectedWarning = revenueSplits.some((revenueSplit) => {
  //   return (
  //     !revenueSplit.user.account || !revenueSplit.user.account.payoutsEnabled
  //   );
  // });

  return (
    <Wrapper className="my-4">
      {accountsAreLoading ? (
        <LoadingContainer text="Loading account data" />
      ) : (
        <Container>
          <ProjectManagementHeader title="payments" />
          {showUnconnectedWarning && (
            <Alert variant="warning">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
              All teachers must connect their account before registrations can
              be received.
            </Alert>
          )}
          <PaymentAccountTable project={project} />
        </Container>
      )}
    </Wrapper>
  );
}

export default ManagePaymentsRoute;
