import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FacebookIcon from "images/icons/social/v1/facebook.svg";
import LinkedinIcon from "images/icons/social/v1/linkedIn.svg";
import TwitterIcon from "images/icons/social/v1/twitter.svg";
import InstagramIcon from "images/icons/social/v1/instagram.svg";
import DiscordIcon from "images/icons/social/v1/discord.png";

const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 60px;
  margin-right: ${(props) => props.theme.vSpacingMd};
  transition: transform ${(props) => props.theme.transitionSuperFast};

  &:hover {
    cursor: pointer;
    transform: scale(1.15) rotate(20deg);
  }

  > img {
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    > img {
      width: 50px;
      height: 50px;
    }
  }
`;

function SocialItem({ type, handle }) {
  /** Return a single social item. */

  function getFullUrl() {
    /** Return the full url to the application. */

    switch (type) {
      case "twitter":
      case "facebook":
      case "instagram":
        return `https://${type}.com/${handle}`;
      case "linkedin":
        return `https://linkedin.com/in/${handle}`;
      default:
        return null;
    }
  }

  function openInNewTab() {
    const url = getFullUrl();
    const newWindow = url && window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  function getDisplayedSocial() {
    switch (type) {
      case "twitter":
        return (
          <IconBackground onClick={openInNewTab}>
            <img src={TwitterIcon} alt="" />
          </IconBackground>
        );
      case "facebook":
        return (
          <IconBackground onClick={openInNewTab}>
            <img src={FacebookIcon} alt="" />
          </IconBackground>
        );
      case "linkedin":
        return (
          <IconBackground onClick={openInNewTab}>
            <img src={LinkedinIcon} alt="" />
          </IconBackground>
        );
      case "instagram":
        return (
          <IconBackground onClick={openInNewTab}>
            <img src={InstagramIcon} alt="" />
          </IconBackground>
        );
      case "discord":
        return (
          <IconBackground onClick={openInNewTab}>
            <img src={DiscordIcon} alt="" />
          </IconBackground>
        );
      default:
        return null;
    }
  }

  return getDisplayedSocial();
}

SocialItem.propTypes = {
  // The type of social icon that we should display.
  type: PropTypes.string.isRequired,

  // The handle of the user for the given social type.
  handle: PropTypes.string.isRequired,
};

export default SocialItem;
