import React from "react";
import { bool, shape } from "prop-types";
import Row from "react-bootstrap/Row";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

import ModifyButton from "components/buttons/Modify";
import ActionContainer from "components/containers/Actions";
import EmptyActionSection from "components/general/EmptyActionSection";
import SectionHeader from "components/general/SectionHeader";
import FormModal from "components/modals/Form";
import Caption from "components/text/Caption";
import TeacherDisplay from "features/projects/components/InfoSection/TeacherDisplay";
import TeachersContainer from "features/projects/components/TeachersContainer";
import { projectTemplate } from "utils/enums";
import { getProjectType } from "utils/projects";
import { Project } from "types";
import { isClass } from "utils/projects";

const UpperHeader = styled(Caption)`
  color: ${(props) => props.theme.lime};
  font-size: 15px;
  font-weight: 200;
  font-family: "Basis Grotesque";
  margin-bottom: 10px;
  display: flex;
`;

const TeacherWrapper = styled.div`
  display: block;

  .parent-position {
    position: relative;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: ${(props) => props.theme.vSpacingXs};
    margin-bottom: 30px;
  }
`;

const StyledFormModal = styled(FormModal)`
  .modal-dialog {
    width: 450px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    .modal-dialog {
      width: 100%;
    }
  }
`;

function TeacherDisplaySection({ shouldViewAdminItems, project }) {
  /** Display the teachers for a project, and allows the owner to edit. */

  const userDescription = isClass(project) ? "teacher" : "collaborator";

  const [showTeachersModal, hideTeachersModal] = useModal(() => {
    return (
      <StyledFormModal
        onHide={hideTeachersModal}
        title={`${userDescription} name(s)`}
      >
        <TeachersContainer
          project={project}
          canEdit={shouldViewAdminItems}
          closeModal={hideTeachersModal}
        />
      </StyledFormModal>
    );
  }, [project, shouldViewAdminItems]);

  function renderUsers() {
    // Temp workaround to hide certain "teachers" from being shown publicly. This is
    // used mainly for affiliates.
    const excludeTeachers = ["toniko", "samdoesarts"];

    const teachers = project.teachers.map((teacher) => {
      return (
        !excludeTeachers.includes(teacher.username) && (
          <TeacherDisplay
            teacher={teacher}
            key={teacher.id}
            className="px-2"
            isClass={isClass(project)}
          />
        )
      );
    });

    if (!shouldViewAdminItems) return teachers;

    // Allow the owner to edit the teachers.
    return (
      <>
        <Row className="parent-position">
          {teachers}
          <ActionContainer>
            <ModifyButton
              onClick={showTeachersModal}
              className="d-none d-md-inline"
            />
          </ActionContainer>
        </Row>
      </>
    );
  }

  if (!project.teachers.length) {
    return (
      shouldViewAdminItems && (
        <EmptyActionSection
          text={`There are no ${userDescription}s for this project.`}
          buttonAction={showTeachersModal}
          buttonText={`Add ${userDescription}(s)`}
          className="mt-3"
        />
      )
    );
  }

  return (
    <>
      <SectionHeader>
        <UpperHeader>
          {getProjectType(project) === projectTemplate.liveClass
            ? "Taught by"
            : "Collaborators"}
        </UpperHeader>
      </SectionHeader>
      <TeacherWrapper>{renderUsers()}</TeacherWrapper>
    </>
  );
}

TeacherDisplaySection.propTypes = {
  /** The project for which we're displaying the teachers. */
  project: shape(Project).isRequired,

  /** Determine if the user is the owner of the project. */
  shouldViewAdminItems: bool.isRequired,
};

export default TeacherDisplaySection;
