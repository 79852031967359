import { appPermission } from "utils/enums";

function checkPermissions(user, permissions) {
  /** Allow access if the user has any of the passed in permissions. */
  if (!user.appPermissions) return false;

  return user.appPermissions.some((permission) =>
    permissions.includes(permission.code)
  );
}

export function isProjectCityAdmin(user) {
  /** Determine if the user is an admin of the entire Project City application. */
  const value = checkPermissions(user, [appPermission.projectCityAdmin]);
  return value;
}

export function isClassCreator(user) {
  /** Determine if the user is able to create a new class. */
  return checkPermissions(user, [
    appPermission.classCreator,
    appPermission.projectCityAdmin,
  ]);
}

export function hasFreeSubscription(user) {
  /** Determine if the user should automatically have access to premium content without a sub. */
  return checkPermissions(user, [
    appPermission.freePremiumUser,
    appPermission.premiumCreator,
    appPermission.projectCityAdmin,
  ]);
}

export function canUploadPremiumContent(user) {
  /** Determine if the user is able to upload new premium content. */
  return checkPermissions(user, [
    appPermission.premiumCreator,
    appPermission.projectCityAdmin,
  ]);
}

export function canReceiveDonation(user) {
  /** Determine if the user is able to upload new premium content. */
  return checkPermissions(user, [appPermission.canReceiveDonation]);
}

export function canCreateAssignments(user) {
  /** Determine if the user is able to upload new premium content. */
  return canUploadPremiumContent(user);
}

export function shouldCreateVideoSession(user) {
  /** Creators and admins should not create video sessions. */
  return !canUploadPremiumContent(user);
}
