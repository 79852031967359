import React, { useState } from "react";
import { shape, string } from "prop-types";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";

import ProjectService from "features/projects/services/project";
import Button from "components/buttons/Button";
import { openInNewTab } from "utils/general";
import { Project } from "types";

const ConnectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledConnectButton = styled(Button)`
  // Make same height as the select control.
  height: 53px;
  font-size: 15px !important;
`;

function StripeConnectButton({ project, country, ...props }) {
  /** Provide a button component to connect a project to a stripe account. */

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { addToast } = useToasts();

  async function connectAccount() {
    // Connects the account and then sends the user to Stripe to fill out their data.
    setErrorMessage();
    try {
      setIsLoading(true);
      const projectService = new ProjectService();
      const response = await projectService.connectAccount(
        project.id,
        country.value
      );
      openInNewTab(response.data.url);
    } catch (err) {
      addToast("Error connecting stripe account, try refreshing.", {
        appearance: "error",
      });
    }

    setIsLoading(false);
  }

  return (
    <ConnectWrapper>
      <StyledConnectButton
        onClick={connectAccount}
        isLoading={isLoading}
        {...props}
      >
        {props.children}
      </StyledConnectButton>
      {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
    </ConnectWrapper>
  );
}

StripeConnectButton.propTypes = {
  /** The project that we're going to be connecting. */
  project: shape(Project).isRequired,

  /** The country that the user will be connecting to. */
  country: string.isRequired,
};

export default StripeConnectButton;
