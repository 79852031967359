import React from "react";
import styled from "styled-components";
import { oneOf, string } from "prop-types";

import Body from "components/text/Body";
import LoadingSpinner from "components/loading/Loading";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  color: inherit;
`;

function LoadingContainer({ text, loaderSize, ...props }) {
  return (
    <Wrapper {...props}>
      {text && <Body className="mb-2">{text}</Body>}
      <LoadingSpinner size={loaderSize} />
    </Wrapper>
  );
}

LoadingContainer.propTypes = {
  /** Optional text to show with the loading spinner. */
  text: string,

  /** The size of the loading icon. */
  loaderSize: oneOf(["sm", "md", "lg"]),
};

LoadingContainer.defaultProps = {
  text: "",
  loaderSize: "lg",
};

export default LoadingContainer;
