import React from "react";
import styled from "styled-components";
import PinkNavBackground from "images/backgrounds/nav_pink.svg";

const Wrapper = styled.div`
  background-image: url(${PinkNavBackground});
  height: ${(props) => props.theme.secondaryNavbarHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 20;
  margin-top: 69px;

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    padding-left: 74px;
    padding-right: 54px;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    display: none;
  }
`;

function SecondaryNavbar(props) {
  /** A generic secondary navbar which can go below our main navbar. */
  return <Wrapper>{props.children}</Wrapper>;
}

export default SecondaryNavbar;
