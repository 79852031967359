import { useSelector } from "react-redux";

import { hasFreeSubscription } from "utils/permissions";

export const useCanViewSprintSubscription = () => {
  /** Determine if the user is currently authenticated. */

  const account = useSelector((state) => state.account);
  const { subscription } = account?.user;

  const allowedStatus = ["active", "past_due"];

  if (!account?.user?.id) return false;

  const canViewSubscriptionData =
    (subscription && allowedStatus.includes(subscription.status)) ||
    (account?.user?.id && hasFreeSubscription(account.user));

  return canViewSubscriptionData;
};
