import React from "react";
import styled from "styled-components";
import { bool, func, object } from "prop-types";
import { navigate } from "@reach/router";
import { useModal } from "react-modal-hook";

import ActionButton from "components/buttons/Action";
import AuthenticationModal from "features/accounts/modals/Authentication";
import useProfileImage from "hooks/ProfileImage";

const JoinUsButton = styled(ActionButton)`
  transform: rotate(4deg);

  &:hover {
    background-color: ${(props) => props.theme.dark};
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-right: 33px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 3px;
    font-size: 16px !important;
    line-height: 22px !important;
  }

  ${(props) =>
    props.$expanded &&
    `
    @media (max-width: ${props.theme.smBreakpoint}) {
      font-size: 48px!important;
      line-height: 48px!important;
      padding: 25px;
      margin: 35px 0;
    }
  `}
`;

const StyledLoginLink = styled.a`
  color: ${(props) => props.theme.neonPink};
  font-size: 21px;
  transition: transform ${(props) => props.theme.transitionSuperFast} ease;

  &:hover {
    color: ${(props) => props.theme.neonPink};
    cursor: pointer;
    transform: scale(1.2) rotate(-5deg);
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: 20px;

    ${(props) =>
      !props.expanded &&
      `
        display: none;
      `}
  }
`;

const ProfileStyling = styled.div`
  margin-left: 10px;
  margin-right: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-family: "Basis Grotesque";

  img {
    width: 42px !important;
    height: 42px;
  }

  & span {
    color: ${(props) => props.theme.neonPink};
    padding-bottom: 3px;
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin-left: 8px;
  }

  &:hover {
    color: ${(props) => props.theme.neonPink};
    text-decoration: none;

    &:after {
      border-bottom: 3px solid ${(props) => props.theme.black};
    }
  }

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    &:after {
      content: "";

      text-align: center;
      width: 50%;
      display: block;
      transform: rotate(2deg);
      position: absolute;
      left: 53px;
      bottom: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    position: fixed;
    top: 10px;
    right: 30px;

    img {
      width: 35px !important;
      height: 35px;
    }
  }
`;

const StyledUsername = styled.span`
  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    padding-right: 20px;
    flex-direction: row;
  }
`;

function AccountDropdown({ account, expanded, setExpanded }) {
  /**
   * The account options for the user to select various actions at the account level.
   *
   * TODO: This should no longer be a dropdown component...
   */

  const isAuthenticated = account.token !== "";

  const [showAuthModal, hideAuthModal] = useModal(() => (
    <AuthenticationModal show={true} onHide={hideAuthModal} mode="login" />
  ));

  const [showRegisterAuthModal, hideRegisterAuthModal] = useModal(() => (
    <AuthenticationModal
      mode="register"
      show={true}
      onHide={hideRegisterAuthModal}
    />
  ));

  const profileImage = useProfileImage({ roundedCircle: true });

  if (isAuthenticated)
    return (
      <ProfileStyling
        onClick={() => {
          setExpanded(false);
          navigate(`/users/${account.user.username}`);
        }}
      >
        {profileImage}
        <StyledUsername> {account.user.username} </StyledUsername>
      </ProfileStyling>
    );
  else
    return (
      <Wrapper>
        <JoinUsButton
          startTilt
          onClick={showRegisterAuthModal}
          $expanded={expanded}
          size="lg"
          isWavy
        >
          Join Us
        </JoinUsButton>
        <StyledLoginLink expanded={expanded} onClick={showAuthModal}>
          Log In
        </StyledLoginLink>
      </Wrapper>
    );
}

AccountDropdown.propTypes = {
  /** The account state of the user. */
  account: object.isRequired,

  /** Action to fire to let the navbar know that the dropdown is expanded. */
  setExpanded: func.isRequired,

  /** Determine if the account dropdown is expanded. */
  expanded: bool.isRequired,
};

export default AccountDropdown;
