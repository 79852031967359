import React from "react";
import { shape, string } from "prop-types";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, navigate } from "@reach/router";
import { useSelector } from "react-redux";
import { useModal } from "react-modal-hook";

import Button from "components/buttons/Button";
import CreateBucketModal from "features/buckets/modals/Create";
import { Project } from "types";
import {
  getProjectBaseUrl,
  getProjectManagementUrl,
  getProjectUrl,
  isClass,
  isProjectAdmin,
} from "utils/projects";
import SecondaryFullNavigation from "components/navbars/SecondaryFull";

const StyledDropdownItem = styled(Dropdown.Item)`
  color: ${(props) => props.theme.blue};
  background-color: transparent !important;
  text-align: center;
  font-size: 20px;

  &.active {
    color: ${(props) => props.theme.yellow}!important;
  }

  &:hover {
    color: ${(props) => props.theme.blue};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }
`;

const TopLevelItem = styled(Nav.Item)`
  flex: 0 1 min-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 20px;
  color: ${(props) =>
    props.$isActive ? props.theme.yellow : props.theme.blue}!important;
`;

const SecondaryLink = styled(Nav.Link)`
  font-size: 20px;
  color: ${(props) =>
    props.$isActive ? props.theme.yellow : props.theme.blue}!important;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  font-size: 20px;
  color: ${(props) => props.theme.blue};
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0;

  &:active,
  &:hover,
  &:focus {
    color: ${(props) => props.theme.yellow}!important;
  }
`;

const CreatePageDropdownItem = styled(StyledDropdownItem)`
  background-color: ${(props) => props.theme.blue}!important;
  color: ${(props) => props.theme.yellow}!important;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 10px;
`;

const StyledMenu = styled(Dropdown.Menu)`
  background-color: ${(props) => props.theme.pink};
`;

function ProjectSecondaryNavigation({ project, activePathname }) {
  /** Secondary navigation bar that should be shown for a project. */

  const user = useSelector((state) => state.account.user);
  const currentBucket = useSelector((state) => state.buckets.current);
  const { isPublic, registrationTier } = useSelector(
    (state) => state.projects.actAs
  );

  const [showBucketModal, hideBucketModal] = useModal(() => (
    <CreateBucketModal project={project} hideModal={hideBucketModal} />
  ));

  const isAdmin = isProjectAdmin(user, project);
  const _isClass = isClass(project);
  const desiredMaximum = 4;
  const staticLength = getStaticLinks().length;

  function getBucketUrl(bucket) {
    /** Return the url for a given bucket. */
    return `${getProjectBaseUrl(project)}/${bucket.slug}`;
  }

  function getStaticLinks() {
    /** Return the data for the static pages that should be displayed first in top-level. */
    return [
      {
        to: getProjectUrl(project),
        isActive: getProjectUrl(project) === activePathname,
        label: project.infoBucketLabel,
      },
    ];
  }

  function getExtraTopLevelLinks() {
    /** Return an array of the bucket data that should be shown as top-level links. */
    const topLevelBuckets = getViewableBuckets().slice(
      0,
      desiredMaximum - staticLength
    );

    // Clean the bucket data so that they can be rendered as top-level links.
    const cleanedLinkData = topLevelBuckets.map((bucket) => {
      const bucketUrl = getBucketUrl(bucket);
      return {
        to: bucketUrl,
        isActive: bucketUrl === activePathname,
        label: bucket.title,
      };
    });

    return cleanedLinkData;
  }

  function renderTopLevelLinks() {
    /**
     * On the top-level of the navigation, we should return any static pages as well dynamic pages
     * showing up to 4 links in the navigation, and having the rest be in the "more" dropdown.
     */
    const staticLinks = getStaticLinks();
    const extraTopLevelLinks = getExtraTopLevelLinks();

    return staticLinks
      .concat(extraTopLevelLinks)
      .map(({ to, isActive, label }) => (
        <TopLevelItem className="mr-3" $isActive={isActive} key={to}>
          <SecondaryLink to={to} as={Link} $isActive={isActive}>
            {label}
          </SecondaryLink>
        </TopLevelItem>
      ));
  }

  function getViewableBuckets() {
    /**
     * Return an array of the buckets that should be shown to the user.
     *
     * This could be very simple, however we also have a way for a project admin to act
     * as a public user or a given registration tier, which is why we need to have a
     * filter for it here.
     */

    if (!isPublic && !registrationTier) return project.buckets;
    else if (isPublic)
      return project.buckets.filter((bucket) => bucket.isPublic);
    else if (registrationTier)
      return project.buckets.filter((bucket) => {
        return (
          bucket.registrationTiers.includes(registrationTier) || bucket.isPublic
        );
      });
  }

  function getDropdownBuckets() {
    /**
     * Return the bucket objects that should be shown in the "more" dropdown, for those that don't
     * fit in the top-level.
     */
    const viewableBuckets = getViewableBuckets();
    return viewableBuckets.slice(
      desiredMaximum - staticLength,
      viewableBuckets.length
    );
  }

  function renderPagesDropdown() {
    /** Don't show the pages dropdown if there are no pages and user is not an admin. */
    const dropdownBuckets = getDropdownBuckets();
    if (!isAdmin && dropdownBuckets.length === 0) return null;

    return (
      <Dropdown as={Nav.Item}>
        <StyledDropdownToggle>More</StyledDropdownToggle>
        <StyledMenu className="pb-0" align="end">
          {dropdownBuckets.map((bucket) => (
            <StyledDropdownItem
              onClick={(e) => {
                e.target.blur();
                navigate(`${getProjectBaseUrl(project)}/${bucket.slug}`);
              }}
              active={bucket.id === currentBucket.id}
              key={bucket.id}
            >
              {bucket.title}
            </StyledDropdownItem>
          ))}
          {isAdmin && (
            <CreatePageDropdownItem onClick={showBucketModal}>
              Create new page
            </CreatePageDropdownItem>
          )}
        </StyledMenu>
      </Dropdown>
    );
  }

  return (
    <SecondaryFullNavigation
      label={project.title.toLowerCase()}
      center={
        <>
          {renderTopLevelLinks()}
          {renderPagesDropdown()}
        </>
      }
      right={
        isAdmin && (
          <Button
            onClick={() => navigate(getProjectManagementUrl(project))}
            size="sm"
            variant="primary"
            className="float-right"
            noTilt
          >
            {_isClass ? "Class Management" : "Project Management"}
          </Button>
        )
      }
    />
  );
}

ProjectSecondaryNavigation.propTypes = {
  /** The project whose secondary navigation is being rendered. */
  project: shape(Project).isRequired,

  /** The active path we're on, to check which link should be active. */
  activePathname: string.isRequired,
};

export default ProjectSecondaryNavigation;
