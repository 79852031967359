import React from "react";

import Caption from "components/text/Caption";

function LastUpdatedText(props) {
  return (
    <Caption {...props} color="green" style={{ fontWeight: 100 }}>
      {props.children}
    </Caption>
  );
}

export default LastUpdatedText;
