import React from "react";
import { arrayOf, number } from "prop-types";
import styled from "styled-components";

import { Step as StepType } from "components/steps/types";
import Step from "../Step";
import StepDivider from "../Divider";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20%;
`;

function StepContainer({ steps, activeStep, ...props }) {
  /** Provide a series of steps to display. */
  return (
    <Wrapper {...props}>
      {steps.map((step, index) => (
        <>
          <Step
            step={step}
            isEven={index % 2 === 0}
            isActive={activeStep === step.content}
          />
          {index !== steps.length - 1 && (
            <StepDivider isComplete={activeStep > step.content} />
          )}
        </>
      ))}
    </Wrapper>
  );
}

StepContainer.propTypes = {
  /** The steps that should be rendered in the container. */
  steps: arrayOf(StepType),

  /** The step that is currently active in the modal. */
  activeStep: number.isRequired,
};

StepContainer.defaultProps = {
  activeStep: null,
};

export default StepContainer;
