import { createGlobalStyle } from "styled-components";
import BasisGrotesqueRegularWoff from "styles/fonts/basis-grotesque-regular.woff";
import BasisGrotesqueRegularWoff2 from "styles/fonts/basis-grotesque-regular.woff2";
import SuisseIntlBlack from "styles/fonts/SuisseIntl-Black.otf";

import GTAlpinaRegularWoff from "styles/fonts/GT-Alpina-Standard-Regular-Italic.woff";
import GTAlpinaRegularWoff2 from "styles/fonts/GT-Alpina-Standard-Regular-Italic.woff2";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'Basis Grotesque Regular';
  src: url(${BasisGrotesqueRegularWoff2}) format('woff2'),
       url(${BasisGrotesqueRegularWoff}) format('woff');

  font-family: "SuisseIntl";
  src: url(${SuisseIntlBlack}) format('opentype');

  font-family:'GT Alpina';
  src: url(${GTAlpinaRegularWoff2}) format('woff2'),
       url(${GTAlpinaRegularWoff}) format('woff');
}


`;

export default FontStyles;
