import React from "react";
import styled from "styled-components";

import Caption from "components/text/Caption";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.theme.vSpacingXs};
`;

function MasonryFooter({ left, right }) {
  /** A footer component meant to be used at the bottom of a masonry block. */
  return (
    <Wrapper>
      <Caption size="sm" color="lime">
        {left}
      </Caption>
      <Caption size="sm" color="gray3">
        {right}
      </Caption>
    </Wrapper>
  );
}

export default MasonryFooter;
