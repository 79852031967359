import React from "react";
import { arrayOf, shape } from "prop-types";
import moment from "moment";

import BaseTable from "components/tables/Base";
import ColumnHeader from "components/tables/ColumnHeader";
import { Payment } from "features/payments/types";
import { convertEnumToArray, toCurrency } from "utils/general";
import { paymentStatus } from "features/payments/enums";

function PremiumContentPaymentTable({ payments }) {
  const statusChoices = convertEnumToArray(paymentStatus);

  function renderRows() {
    return payments.map((payment) => (
      <tr>
        <td>{moment(payment.created).format("ll")}</td>
        <td>{toCurrency(payment.viewershipAmount / 100)}</td>
        <td>{toCurrency(payment.baselineAmount / 100)}</td>
        <td>{toCurrency(payment.totalAmount / 100)}</td>
        <td>
          {statusChoices.find((obj) => payment.status === obj.value).label}
        </td>
      </tr>
    ));
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <ColumnHeader>Date</ColumnHeader>
          <ColumnHeader>Viewership Amount</ColumnHeader>
          <ColumnHeader>Baseline Amount</ColumnHeader>
          <ColumnHeader>Total Amount</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </BaseTable>
  );
}

PremiumContentPaymentTable.propTypes = {
  payments: arrayOf(shape(Payment)).isRequired,
};

export default PremiumContentPaymentTable;
