import { arrayOf, bool, number, object, string } from "prop-types";

export const Assignment = {
  /** Identifier for the assignment. */
  id: number,

  /** Title of the assignment. */
  title: string,

  /** Determine if the assignment is viewable to subscribers, or only to creator and PC admins. */
  isPublic: bool,

  /** src of the thumbnail to show for the assignment. */
  thumbnail: string,

  /** Src of the instructional video. */
  video: string,

  /** User object who created the assignment. */
  createdBy: object,

  /** Determine if the user has completed the assignment. */
  userHasCompleted: bool,
};

export const AssignmentEntry = {
  /** Identifier for the assignment entry. */
  id: number.isRequired,

  /** Title of the assignment. */
  title: string.isRequired,

  /** src of the thumbnail to show for the assignment. */
  thumbnail: string,

  /** Src of the files associated with the entry. */
  files: arrayOf(object),

  /** User object who created the assignment. */
  createdBy: object,

  /** Datetime string of when the entry was created. */
  created: string,

  /** Show if the user has submitted an entry for the assignment. */
  userHasSubmitted: bool,
};
