import React from "react";
import { bool, oneOf } from "prop-types";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import BinocularsSticker from "images/stickers/binoculars.svg";
import BulbSticker from "images/stickers/bulb.svg";
import DraftSticker from "images/stickers/draft.svg";
import DreamSticker from "images/stickers/dream.svg";
import FireSticker from "images/fire.svg";
import EyesSticker from "images/stickers/eyes.svg";
import HammerSticker from "images/stickers/hammer.svg";
import MonsterSticker from "images/stickers/monster.svg";
import NameChangeSticker from "images/stickers/name-change-base.svg";
import PcSticker from "images/stickers/pc.svg";
import PcStickSticker from "images/stickers/pc-stick.svg";
import PieSticker from "images/stickers/pie.svg";
import PreRecordedSticker from "images/stickers/pre-recorded.svg";
import PrivateSticker from "images/stickers/private.svg";
import RainbowSticker from "images/stickers/rainbow.svg";
import StarSticker from "images/stickers/star.svg";
import StrawberrySticker from "images/stickers/strawberry.svg";
import TurtleSticker from "images/stickers/turtle.svg";

const StyledImage = styled(Image)`
  ${(props) =>
    props.$showMobile === false &&
    `
    @media (max-width: ${props.theme.smBreakpoint}) {
      display: none;
    }
  `}
`;

const stickerTypes = [
  "binoculars",
  "bulb",
  "draft",
  "dream",
  "eyes",
  "fire",
  "hammer",
  "monster",
  "name-change",
  "pc",
  "pc-stick",
  "pie",
  "pre-recorded",
  "private",
  "rainbow",
  "star",
  "strawberry",
  "turtle",
  "random",
];

const randomStickerTypes = [
  "bulb",
  "eyes",
  "fire",
  "pc",
  "pc-stick",
  "rainbow",
  "star",
  "strawberry",
  "turtle",
];

function Sticker({ type, showMobile, ...props }) {
  /** A custom image file which can be used in random places. */

  if (type === "random")
    type =
      randomStickerTypes[Math.floor(Math.random() * randomStickerTypes.length)];

  function getSrc() {
    switch (type) {
      case "binoculars":
        return BinocularsSticker;
      case "bulb":
        return BulbSticker;
      case "draft":
        return DraftSticker;
      case "dream":
        return DreamSticker;
      case "eyes":
        return EyesSticker;
      case "fire":
        return FireSticker;
      case "hammer":
        return HammerSticker;
      case "monster":
        return MonsterSticker;
      case "name-change":
        return NameChangeSticker;
      case "pc":
        return PcSticker;
      case "pc-stick":
        return PcStickSticker;
      case "pie":
        return PieSticker;
      case "pre-recorded":
        return PreRecordedSticker;
      case "private":
        return PrivateSticker;
      case "rainbow":
        return RainbowSticker;
      case "star":
        return StarSticker;
      case "strawberry":
        return StrawberrySticker;
      case "turtle":
        return TurtleSticker;
      default:
        return FireSticker;
    }
  }

  return (
    <StyledImage
      fluid
      src={getSrc()}
      alt={`Project City ${type}`}
      className={`${props?.className + " sticker"}`}
      $showMobile={showMobile}
      {...props}
    />
  );
}

Sticker.propTypes = {
  /** Determine which image should be loaded. */
  type: oneOf(stickerTypes),

  /** Determine if the sticker should be shown on mobile. */
  showMobile: bool,
};

Sticker.defaultProps = {
  showMobile: false,
  type: "random",
};

export default Sticker;
