import React, { useMemo } from "react";
import { arrayOf, bool, object, string } from "prop-types";
import styled from "styled-components";

import SubHeaderText from "./TextOnly";

const Wrapper = styled.div`
  display: flex;

  // We need special styling if the text is rotated.
  margin-bottom: ${({ positiveRotate, tilt }) => {
    return tilt ? (positiveRotate ? "11px" : "34px") : null;
  }};

  > h3 {
    transform: ${({ positiveRotate, tilt }) => {
      const trans = ["rotate(3deg); ", "rotate(-3deg);"];
      return tilt && trans[positiveRotate ? 0 : 1];
    }};
  }
`;

const EmptyBlock = styled.div`
  // Need to have an empty block so that we don't rotate the entire h3 block. Can't make
  // the h3 inline either or else it doesn't rotate.
`;

function SubHeader({ text, tilt, containerProps, ...props }) {
  /** Subheader that separates a presentational section. */

  const positiveRotate = useMemo(
    () => props.positiveRotate || Math.random() >= 0.5,
    []
  );

  return (
    <Wrapper positiveRotate={positiveRotate} tilt={tilt} {...containerProps}>
      <SubHeaderText tilt={tilt} {...props}>
        {text || props.children}
      </SubHeaderText>
      <EmptyBlock />
    </Wrapper>
  );
}

SubHeader.propTypes = {
  /** The string to show as the subheader. */
  text: string,

  /** The color that the subheader should be. */
  color: string,

  /** Give the text a tilt. */
  tilt: bool,

  /** Set the text to be italic. */
  italic: bool,

  /** Sometimes we need to exclude certain colors from being rendered. */
  excludeColors: arrayOf(string),

  /** Extra props to pass into the subheader container. */
  containerProps: object,

  /** Manually set the rotate to be positive or negative */
  positiveRotate: bool,
};

SubHeader.defaultProps = {
  text: null,
  color: null,
  tilt: true,
  italic: false,
  excludeColors: [],
  containerProps: {},
  positiveRotate: null,
};

export default SubHeader;
