import React from "react";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledTable = styled(Table)`
  background-color: transparent;
  margin-bottom: 0;
  font-family: Basis Grotesque Pro;
  color: ${(props) => props.theme.yellow};

  ${(props) =>
    props.$withHeader &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}

  td {
    vertical-align: middle;
    border-top: none;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.04em;
  }

  tr {
    border-bottom: 4px solid #6765e4;

    &:hover {
      background-color: transparent !important;
      cursor: inherit;
    }
  }

  th {
    border-top: none;
  }
`;

function BaseTable({ withHeader, withMultiSelect, ...props }) {
  /**
   * A base table component for all of our tables to inherit, mostly for global table
   * styling.
   */
  return <StyledTable $withHeader={withHeader} {...props} />;
}

BaseTable.propTypes = {
  // Determines if the table has a header above it, which affects the styling.
  withHeader: PropTypes.bool,
};

BaseTable.defaultProps = {
  withHeader: false,
};

export default BaseTable;
