import { bool, oneOf, shape } from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

import Button from "components/buttons/Button";
import ModifyButton from "components/buttons/Modify";
import RainbowBackground1 from "images/backgrounds/blue_green.png";
import RainbowBackground2 from "images/backgrounds/pink_blue.png";
import RegistrationTierModal from "features/tiers/modals/RegistrationTier";
import RegistrationButton from "features/projects/components/registration/Button";
import Caption from "components/text/Caption";
import Body from "components/text/Body";
import {
  getRandomColor,
  getRandomColorsForTier,
  getRandomSmallTilt,
} from "utils/general";
import { RegistrationTier } from "types";

const Wrapper = styled.div`
  position: relative;
  padding: ${(props) => props.theme.vSpacingMd};
  background-color: ${(props) =>
    props.variant === "primary"
      ? `${props.theme.lime}`
      : `${props.theme.neonPink}`};
`;

const Header = styled(Body)`
  font-family: "GT Alpina";
  font-style: italic;
  font-size: 54px;
  line-height: 54px;
  text-transform: lowercase;

  color: ${(props) =>
    props.variant === "primary"
      ? `${props.theme.neonPink}`
      : `${props.theme.lime}`};
  margin-bottom: 20px;
  ${getRandomSmallTilt()};
`;

const Description = styled(Caption)`
  padding-bottom: 15px;
  padding-top: 15px;
  color: ${(props) => props.theme.black};
  border-bottom: 1px solid ${() => getRandomColor()};
`;

const TierPrice = styled(Body)`
  text-align: center;
  padding: 10px 0px;
  color: white; /* Fallback: assume this color ON TOP of image */
  background: url(${(props) =>
      props.variant === "primary" ? RainbowBackground2 : RainbowBackground1})
    no-repeat; /* Set the background image */
  font-weight: bold;
  font-size: 48px;
  line-height: 26px;
  -webkit-background-clip: text; /* clip the background to the text inside the tag*/
  -webkit-text-fill-color: transparent; /* make the text transparent so
                                        * the background shows through*/

  ${getRandomSmallTilt()};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    font-size: 36px;
    line-height: 26px;
  }
`;

function RegistrationTierPaymentSection({
  tier,
  isEnrolled,
  canEdit,
  variant,
  ...props
}) {
  /** Display a payment button, title, and management items. */

  const colors = getRandomColorsForTier();
  const [showTierModal, hideTierModal] = useModal(() => {
    return (
      <RegistrationTierModal onHide={hideTierModal} registrationTier={tier} />
    );
  }, [tier]);

  // If the tier is invalid and user should not see it, then return null.
  if (!tier.canReceivePayments && !canEdit) return null;

  function renderDescriptions() {
    /** Render the extra descriptions about a registration tier. */
    return tier.descriptions.map((description) => (
      <Description key={description.id}>{description.description}</Description>
    ));
  }

  return (
    <Wrapper key={tier.id} variant={variant} {...props}>
      <div>
        <Header
          color={variant === "secondary" ? "yellow" : "neonPink"}
          size="lg"
        >
          {tier.title}
        </Header>
        <div className="my-2">{renderDescriptions()}</div>
      </div>
      {isEnrolled ? (
        <Button disabled block variant="secondary">
          <Body bold noMargin color="neonPink">
            Enrolled
          </Body>
        </Button>
      ) : (
        <Row className="align-items-center">
          <Col>
            {tier.price && (
              <TierPrice variant={variant} noMargin size="lg">
                ${parseInt(tier.price)}
              </TierPrice>
            )}
          </Col>
          <Col className="mt-3 pl-5">
            <RegistrationButton
              tier={tier}
              variant={variant}
              showInvalid={!canEdit}
              colors={colors}
            />
          </Col>
        </Row>
      )}
      {canEdit && <ModifyButton onClick={showTierModal} bottom="-40px" />}
    </Wrapper>
  );
}

RegistrationTierPaymentSection.propTypes = {
  /** The registration tier that we're rendering the payment section for. */
  tier: shape(RegistrationTier).isRequired,

  /** Determine styling of the section. */
  variant: oneOf(["primary", "secondary"]).isRequired,

  /** Determine if the user is currently enrolled in the tier. */
  isEnrolled: bool,

  /** Determine if the user is able to edit the registration tier. */
  canEdit: bool,
};

RegistrationTierPaymentSection.defaultProps = {
  isEnrolled: false,
  canEdit: false,
};

export default RegistrationTierPaymentSection;
