import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.purple};
  height: 100%;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-top: 90px;
    padding-bottom: ${(props) => props.theme.navbarMarginBottom};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-bottom: ${(props) => props.theme.mobileNavbarMarginBottom};
  }
`;

function ManagementWrapper(props) {
  /** Wrapper container for the management views. */
  return <Wrapper>{props.children}</Wrapper>;
}

export default ManagementWrapper;
