import SecondaryDashboardContainer from "components/containers/DashboardSecondary";
import ClassMobileNavigation from "features/classes/navigation/Mobile";
import ClassSecondaryNavigation from "features/classes/navigation/Secondary";
import { isMobile } from "react-device-detect";

function ClassesBaseRoute(props) {
  return (
    <div>
      <ClassSecondaryNavigation activePathname={props.location.pathname} />
      {isMobile && <ClassMobileNavigation />}
      <SecondaryDashboardContainer className="mt-5" fluid>
        {props.children}
      </SecondaryDashboardContainer>
    </div>
  );
}

export default ClassesBaseRoute;
