import React from "react";
import { func, shape } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";

import FormConfirmDangerButton from "components/buttons/forms/FormConfirmDanger";
import { useDeletePostMutation } from "services/projectCity";
import { Post } from "types";

function RemovePostButton({ post, closeModal }) {
  /** Button to remove a post from the feed.  */

  const { addToast } = useToasts();
  const [deletePost] = useDeletePostMutation();

  function removePost() {
    /** Perform actions related to removing a post from the feed. */
    deletePost({ id: post.id });
    addToast("Your post has been removed", { appearance: "success" });
    closeModal();
  }

  return (
    <FormConfirmDangerButton onClick={removePost}>
      <FontAwesomeIcon icon={faTrashAlt} /> Yes, remove
    </FormConfirmDangerButton>
  );
}

RemovePostButton.propTypes = {
  /** The post for which we'll be removing. */
  post: shape(Post).isRequired,

  /** Action to trigger when a post is deleted. */
  closeModal: func.isRequired,
};

export default RemovePostButton;
