import React from "react";
import styled from "styled-components";

import { Step as StepType } from "components/steps/types";
import theme from "theme";
import { bool } from "prop-types";

const Wrapper = styled.div`
  position: relative;

  transform: rotate(${(props) => (props.isEven ? 5 : -5)}deg);

  > svg {
    max-width: 50px;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) =>
    props.$isActive ? props.theme.blue : props.theme.yellow};
  font-size: ${(props) => (props.$isActive ? "30px" : "22px")};
`;

function Step({ step, isEven, isActive }) {
  return (
    <Wrapper isEven={isEven}>
      <svg
        width="132"
        height="130"
        viewBox="0 0 132 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M111.854 19.335C124.637 31.9594 130.917 49.251 131.421 66.1385C131.925 83.0259 127.033 100.894 115.034 114.039C103.035 127.184 86.019 129.18 69.7725 129.525C53.5261 129.871 34.976 130.188 22.1923 117.563C9.40855 104.939 0.971503 85.819 0.455021 68.9319C-0.0614603 52.0447 11.118 36.4247 23.1229 23.2728C35.1278 10.1208 49.6385 0.995303 65.885 0.649588C82.1314 0.303873 99.0764 6.71688 111.854 19.335Z"
          fill={isActive ? theme.yellow : theme.blue}
        />
      </svg>
      <ContentContainer $isActive={isActive}>{step.content}</ContentContainer>
    </Wrapper>
  );
}

Step.propTypes = {
  /** The step that is being rendered. */
  step: StepType.isRequired,

  /** Change styling alternative styling. */
  isEven: bool,

  /** Change styling if the step is active. */
  isActive: bool,
};

Step.defaultProps = {
  isEven: false,
  isActive: false,
};

export default Step;
