import React from "react";
import { arrayOf, shape } from "prop-types";

import { RegistrationTierDescription } from "features/tierDescriptions/types";
import { ModalActionProps } from "types";
import BaseTable from "../Base";
import ColumnHeader from "../ColumnHeader";
import TierDescriptionRow from "./Row";

function TierDescriptionTable({ descriptions, modalActionProps, ...props }) {
  function renderRows() {
    return descriptions.map((description) => (
      <TierDescriptionRow
        description={description}
        modalActionProps={modalActionProps}
      />
    ));
  }

  return (
    <BaseTable {...props}>
      <thead>
        <tr>
          <ColumnHeader>Description</ColumnHeader>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </BaseTable>
  );
}

TierDescriptionTable.propTypes = {
  // The registration tier that we're showing the split for.
  descriptions: arrayOf(shape(RegistrationTierDescription)).isRequired,

  // Properties passed down from the parent modal which go to the action button.
  modalActionProps: ModalActionProps.isRequired,
};

export default TierDescriptionTable;
