import { useSelector } from "react-redux";

import useIsProjectCityAdmin from "hooks/IsProjectCityAdmin";

function useCanModifyContentUpload(contentUpload) {
  /** Determine if the user can crate a class project. */
  const user = useSelector((state) => state.account.user);
  const isAdmin = useIsProjectCityAdmin(user);

  if (!contentUpload?.createdBy) return false;

  const canModify =
    isAdmin ||
    contentUpload.createdBy.id === user.id ||
    contentUpload.createdBy === user.id;

  return canModify;
}

export default useCanModifyContentUpload;
