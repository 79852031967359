import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { bool, oneOf } from "prop-types";

import { getRandomTilt } from "utils/general";
import ButtonMask from "images/buttons/btn-mask.svg";

/**
 * Create the general overrides for our main button class.
 *
 * In general what we do here is create any styles that are not supported out of the box by setting
 * a variable in bootstrap's theme.scss file.
 */

const StyledButton = styled(Button)`
  transition: transform ${(props) => props.theme.transitionSuperFast} ease;
  font-family: Basis Grotesque Mono;
  font-weight: ${(props) => (props.fontWeight === "bold" ? "bold" : "normal")};

  ${(props) =>
    props.$isWavy &&
    `
    mask-image: url(${ButtonMask});
    mask-size: cover;
  `}

  ${(props) =>
    props.$uppercase &&
    `
    text-transform: uppercase;
  `}

  color: ${(props) => {
    switch (props.variant) {
      case "primary":
      case "info":
      case "danger":
      case "outline-secondary":
      case "clear-primary":
        return props.theme.yellow;
      case "secondary":
      case "outline-primary":
        return props.theme.blue;
      case "outline-danger":
        return props.theme.red;
      case "link":
        return props.theme.pink;
      default:
        return props.theme.black;
    }
  }}!important;

  ${(props) =>
    props.variant.includes("clear") &&
    `
    background-color: transparent;
  `}

  &:hover {
    ${(props) => !props.disabled && !props.$noTilt && getRandomTilt()}

    ${(props) =>
      props.variant.includes("outline") &&
      `
      background-color: rgba(0,0,0,0);
    `}

    ${(props) =>
      props.variant === "outline-danger" &&
      `
      background-color: ${props.theme.red};
      color: ${props.theme.yellow}!important;
    `}

    border-color: ${(props) => {
      switch (props.variant) {
        case "outline-secondary":
          return props.theme.yellow;
        case "outline-primary":
          return props.theme.blue;
        default:
          return "rgba(0,0,0,0)";
      }
    }};

    ${(props) =>
      props.variant === "primary" &&
      `
      background-color: ${props.theme.yellow};
      color: ${props.theme.blue}!important;
    `}
  }

  ${(props) =>
    props.$startTilt &&
    `
    transform: rotate(-5deg);

    &:hover {
      transform: rotate(0deg) scale(1.1) !important;
    }
  `}

  // Handle button sizing styling.
  ${() => {
    return `
      font-size: 21px!important;
      line-height: 21px!important;

      @media (max-width: ${(props) => props.theme.smBreakpoint}) {
        font-size: 17px!important;
        line-height: 18px!important;
      }
    `;
  }}

  ${(props) => props.$clickThrough && `pointer-events: none;`}
`;

function ProjectCityButton({
  children,
  isLoading,
  noTilt,
  clickThrough,
  startTilt,
  uppercase,
  isWavy,
  ...props
}) {
  /** A main button class for us to inherit all our button children from. */
  return (
    <StyledButton
      disabled={isLoading}
      $noTilt={noTilt}
      $clickThrough={clickThrough}
      $startTilt={startTilt}
      $uppercase={uppercase}
      $isWavy={isWavy}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

ProjectCityButton.propTypes = {
  /** Used to show if the button is doing an asynchronous action. */
  isLoading: bool,

  /** The button should have its text be all upper cased. */
  uppercase: bool,

  /** Gives the button a tilt on the non-hover default state. */
  startTilt: bool,

  /** Button should not tilt on hover. */
  noTilt: bool,

  /** The bootstrap variant to apply to the button. */
  variant: oneOf([
    "primary",
    "secondary",
    "info",
    "outline-primary",
    "outline-secondary",
    "outline-danger",
    "clear-primary",
  ]),

  /** The font weight to apply to the button. */
  fontWeight: oneOf(["light", "normal", "bold"]),

  /** Allow click events to go through the button, passing the action down. */
  clickThrough: bool,

  /** Make the edges of the button wavy instead of straight. */
  isWavy: bool,
};

ProjectCityButton.defaultProps = {
  isLoading: false,
  uppercase: false,
  startTilt: false,
  clickThrough: false,
  noTilt: false,
  variant: "primary",
  fontWeight: "bold",
  isWavy: false,
};

export default ProjectCityButton;
