import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { navigate } from "@reach/router";
import Container from "react-bootstrap/Container";

import ManagementWrapper from "components/containers/Management";
import DashboardLoadingContainer from "components/loading/DashboardContainer";
import { fetchProjectEnrollments } from "features/projects/thunks";
import ClassManagementNavigation from "components/navbars/ClassManagement";
import { isProjectAdmin } from "utils/projects";

const ChildrenContainer = styled(Container)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 100px 80px 0 80px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingLg} 20px 0 20px;
  }
`;

function ProjectManageRoute(props) {
  /**
   * Base route that allows a user to manage their settings and data of a project.
   *
   * Each of the sections in the management dashboard are sub-routes of this parent one.
   */

  const dispatch = useDispatch();
  const project = useSelector((state) => state.projects.detail);
  const user = useSelector((state) => state.account.user);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchEnrollments() {
      setLoading(true);
      await dispatch(fetchProjectEnrollments(project.id));
      setLoading(false);
    }

    // Check that the user is authenticated and is an admin.
    if (!isProjectAdmin(user, project)) navigate("/");

    fetchEnrollments();
  }, [dispatch, project.id]);

  if (isLoading) return <DashboardLoadingContainer />;

  return (
    <ManagementWrapper>
      <ClassManagementNavigation project={project} />
      <ChildrenContainer fluid>{props.children}</ChildrenContainer>
    </ManagementWrapper>
  );
}

export default ProjectManageRoute;
