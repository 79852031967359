import { number, oneOf } from "prop-types";

import { paymentStatus } from "./enums";

export const Payment = {
  /** The identifier for the payment */
  id: number.isRequired,

  /** Amount of the payment that comes from total user viewership. */
  viewershipAmount: number.isRequired,

  /** Amount of the payment that has been arranged for contributing to the Sprint videos. */
  baselineAmount: number.isRequired,

  /** Total amount that will or has been paid. */
  totalAmount: number.isRequired,

  /** The status of the payment. */
  status: oneOf(paymentStatus).isRequired,
};
