import React from "react";
import { number, shape, string } from "prop-types";
import { navigate } from "@reach/router";
import { Modal } from "react-bootstrap";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import { getProjectManagementBaseUrl } from "utils/projects";
import UserService from "features/users/service";

function ConnectAccountReminderModal({ project, ...props }) {
  /** Provide a reminder to users that need to connect their account to Stripe. */
  function navigateProject() {
    navigate(`${getProjectManagementBaseUrl(project)}/payments`);
    props.onHide();
  }

  function handleDontShow() {
    const service = new UserService();
    service.hideConnectAccount();
    props.onHide();
  }

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title="Connect your account"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        <ModalSummaryText>
          You are listed as a teacher for "{project.title}", however you have
          not yet connected a payment account. The project will not be able to
          receive enrollments until all teachers have connected their payment
          accounts.
        </ModalSummaryText>
        <FormSubmitContainer>
          <FormSecondaryButton
            noTilt
            variant="link"
            color="pink"
            onClick={handleDontShow}
          >
            Don't show again
          </FormSecondaryButton>
          <FormPrimaryButton onClick={navigateProject}>
            Connect now
          </FormPrimaryButton>
        </FormSubmitContainer>
      </Modal.Body>
    </BaseModal>
  );
}

ConnectAccountReminderModal.propTypes = {
  /** The project that is being used to link to. */
  project: shape({
    id: number.isRequired,
    title: string.isRequired,
    startDate: string.isRequired,
  }).isRequired,
};

export default ConnectAccountReminderModal;
