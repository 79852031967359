import React from "react";
import { bool, shape } from "prop-types";
import styled from "styled-components";
import Container from "react-bootstrap/Container";

import ClassManagementButton from "components/buttons/ClassManagement";
import useIsProjectAdmin from "hooks/IsProjectAdmin";
import { Project } from "types";

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  // Needed to give standard padding even when the user does not see the header.
  min-height: 160px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    min-height: 120px;
  }
`;

function BucketHeader({ fluid, project, ...props }) {
  /** Provide a header to apply to all buckets. */

  const isProjectAdmin = useIsProjectAdmin();

  return (
    <Wrapper fluid={fluid} {...props}>
      <div />
      <div>{isProjectAdmin && <ClassManagementButton project={project} />}</div>
    </Wrapper>
  );
}

BucketHeader.propTypes = {
  /** The project that the bucket belongs to. */
  project: shape(Project).isRequired,

  /** Determine if the header should be full width or normal container width. */
  fluid: bool,
};

BucketHeader.defaultProps = {
  fluid: false,
};

export default BucketHeader;
