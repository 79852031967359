import React from "react";
import { arrayOf, oneOf, shape } from "prop-types";
import moment from "moment";

import TableColumnHeader from "components/tables/ColumnHeader";
import BaseTable from "components/tables/Base";
import { Donation } from "features/donations/types";
import { getTokenEarnedDollarAmount } from "features/donations/utils";

function DonationTable({ donations, type }) {
  /** Table to show the all animatic donations received for a user. */

  function renderDonationRows() {
    return donations.map((donation) => (
      <tr key={donation.uuid}>
        <td className="d-none d-md-table-cell text-nowrap">
          {moment(donation.created).format("ll")}
        </td>
        {type === "received" ? (
          <td>@{donation.fromUser.username}</td>
        ) : (
          <td>@{donation.toUser.username}</td>
        )}
        <td className="text-nowrap">
          {donation.amount} ({getTokenEarnedDollarAmount(donation.amount)})
        </td>
        <td className="d-none d-md-table-cell">{donation.contentUpload}</td>
      </tr>
    ));
  }

  return (
    <BaseTable withHeader>
      <thead>
        <tr>
          <TableColumnHeader className="d-none d-md-table-cell">
            Date
          </TableColumnHeader>
          {type === "received" ? (
            <TableColumnHeader>From</TableColumnHeader>
          ) : (
            <TableColumnHeader>To</TableColumnHeader>
          )}
          <TableColumnHeader>Tokens</TableColumnHeader>
          <TableColumnHeader className="d-none d-md-table-cell">
            Animatic
          </TableColumnHeader>
        </tr>
      </thead>
      <tbody>{renderDonationRows()}</tbody>
    </BaseTable>
  );
}

DonationTable.propTypes = {
  /** Donations to be shown in the table. */
  donations: arrayOf(shape(Donation)).isRequired,

  /** Show either sent or received donations. */
  type: oneOf(["sent", "received"]).isRequired,
};

export default DonationTable;
