import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import LoadingContainer from "components/loading/Container";
import { createMediaItem } from "features/mediaItems/thunks";
import ActionContainer from "components/containers/Action";

const StyledActionContainer = styled(ActionContainer)`
  transform: rotate(1deg);

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-top: 40vh;
    padding-bottom: 40vh;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
`;

function NewImageUpload({ project, ...props }) {
  /** Uploads a new image to the image upload block. */
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const imageRef = useRef();

  async function handleImageUpload(event) {
    /** Event handler for when we update the project image. */
    setIsLoading(true);
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    formData.append("block", project.imageBlocks[0].id);
    await dispatch(createMediaItem(formData));
    setIsLoading(false);
  }

  return (
    <div {...props}>
      {isLoading ? (
        <LoadingContainer text="Uploading new image..." />
      ) : (
        <>
          <input
            id="section-image"
            style={{ display: "none" }}
            type="file"
            onChange={handleImageUpload}
            ref={imageRef}
          />
          <StyledActionContainer
            onClick={() => imageRef.current.click()}
            buttonText="Upload image"
            htmlFor="project-image-upload"
          />
        </>
      )}
    </div>
  );
}

export default NewImageUpload;
