import React from "react";
import { array, object, string, oneOfType } from "prop-types";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";

import SecondaryNavbar from "components/navbars/Secondary";

const NavigationLabel = styled.span`
  font-size: 30px;
  line-height: 30px;
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 900;
  color: ${(props) => props.theme.purple2};
  letter-spacing: -0.03em;
`;

const StyledNav = styled(Nav)`
  height: ${(props) => props.theme.secondaryNavbarHeight};
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

const SideContainer = styled.div`
  padding-top: 3px;
  flex: 1 0 20%;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;

  // Match the "PageTitle" style.
  font-size: 20px;
  line-height: 20px;
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 900;
  color: ${(props) => props.theme.purple2};
`;

function SecondaryFullNavigation({ label, center, right }) {
  /** Secondary navigation where we can pass in the different components to show in sections. */

  return (
    <SecondaryNavbar>
      <SideContainer className="pr-2">
        <NavigationLabel>{label}</NavigationLabel>
      </SideContainer>
      <StyledNav variant="pills">{center}</StyledNav>
      <SideContainer>{right}</SideContainer>
    </SecondaryNavbar>
  );
}

SecondaryFullNavigation.propTypes = {
  /** The label to display in the navigation bar on the left side. */
  label: oneOfType([object, string]),

  /** Components to render in the center of the navigation, usually links. */
  center: oneOfType([object, array]),

  /** Component to show on the right side of the navigation bar. */
  right: object,
};

SecondaryFullNavigation.defaultProps = {
  label: null,
  center: null,
  right: null,
};

export default SecondaryFullNavigation;
