import React, { useState } from "react";
import styled from "styled-components";

import NewsletterInput from "../RegisterInput";
import NewsletterButton from "../RegisterButton";

const Wrapper = styled.div`
  display: flex;
  margin-right: 20px;
`;

const StyledNewsletterButton = styled(NewsletterButton)`
  transform: rotate(0) !important;
  background-color: ${(props) => props.theme.lime}!important;
  border-color: ${(props) => props.theme.lime};
  color: ${(props) => props.theme.blue}!important;
  text-transform: uppercase;
  font-size: 18px !important;
`;

const StyledNewsletterInput = styled(NewsletterInput)`
  font-size: 18px !important;
`;

function FooterNewsletter() {
  /** Render the newsletter signup in the footer. */
  const [email, setEmail] = useState("");

  return (
    <Wrapper>
      <StyledNewsletterInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        borderColor="lime"
        placeholder="Join our mailing list!"
      />
      <StyledNewsletterButton email={email} clearValue={() => setEmail("")} />
    </Wrapper>
  );
}

export default FooterNewsletter;
