import { shape } from "prop-types";
import { useModal } from "react-modal-hook";
import styled from "styled-components";
import { useSelector } from "react-redux";

import TokenIcon from "components/icons/Icon";
import Thumbnail from "components/text/Thumbnail";
import { ContentUpload } from "features/sprint/types";
import ContentUploadDonationModal from "features/sprint/modals/Donation";
import DonationAgreementModal from "features/donations/modals/Agreement";
import DonationCompleteModal from "features/donations/modals/Complete";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

function DonationContainer({ contentUpload, ...props }) {
  /** Component to initiate donations to a content upload. */

  const { user } = useSelector((state) => state.account);

  // Need to save the purchased tokens to pass into the complete modal.
  const [purchasedTokens, setPurchasedTokens] = useState(0);

  useEffect(() => {
    if (purchasedTokens > 0) {
      showDonationCompleteModal();
      setPurchasedTokens(0);
    }
  }, [purchasedTokens]);

  const [
    showDonationCompleteModal,
    hideDonationCompleteModal,
  ] = useModal(
    () => (
      <DonationCompleteModal
        onHide={hideDonationCompleteModal}
        contentUpload={contentUpload}
        tokens={purchasedTokens}
      />
    ),
    [purchasedTokens]
  );

  const [showDonationModal, hideDonationModal] = useModal(
    () => (
      <ContentUploadDonationModal
        contentUpload={contentUpload}
        onHide={hideDonationModal}
        setPurchasedTokens={setPurchasedTokens}
      />
    ),
    [contentUpload]
  );

  const [
    showDonationAgreementModal,
    hideDonationAgreementModal,
  ] = useModal(() => (
    <DonationAgreementModal
      onHide={hideDonationAgreementModal}
      followAction={showDonationModal}
    />
  ));

  function handleClick(e) {
    e.stopPropagation();

    if (user.hasDonationAgreement) showDonationModal();
    else showDonationAgreementModal();
  }

  return (
    <Wrapper
      className="d-flex align-items-center"
      onClick={handleClick}
      {...props}
    >
      <TokenIcon fluid />
      <Thumbnail color="yellow" inline className="ml-1">
        {contentUpload.tokensReceived}
      </Thumbnail>
    </Wrapper>
  );
}

DonationContainer.propTypes = {
  contentUpload: shape(ContentUpload).isRequired,
};

export default DonationContainer;
