import React, { useState } from "react";
import { func, number, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";

import FormControl from "components/forms/shared/Control";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FileUploadFormGroup from "components/forms/fields/FileUploadFormGroup";
import { AssignmentEntry } from "features/assignments/types";
import {
  useCreateAssignmentEntryMutation,
  useUpdateAssignmentEntryMutation,
} from "services/projectCity";
import { getUploadType } from "utils/uploads";
import { generateFormData, handleResponseError } from "utils/forms";
import { useToasts } from "react-toast-notifications";
import { bucketUploadType } from "utils/enums";
import ToggleBox from "components/controls/ToggleBox";

function AssignmentEntryForm({
  assignmentEntry,
  assignmentId,
  setAssignment,
  setMultipartFile,
  closeModal,
  setFiles,
  setAssignmentEntry,
  setShareToGlobalFeed,
  ...props
}) {
  /** Form to submit create/modify an assignment entry. */

  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: assignmentEntry || {},
  });

  const isUpdating = assignmentEntry !== null;

  const [createAssignmentEntry] = useCreateAssignmentEntryMutation();
  const [updateAssignmentEntry] = useUpdateAssignmentEntryMutation();
  const { addToast } = useToasts();

  async function onSubmit(data) {
    // Files are uploaded separately
    const files = data.files;
    delete data.files;

    // Let the parent modal know if the user will be sharing to global feed.
    setShareToGlobalFeed(data.shareToGlobalFeed === true);

    // We're either uploading files, or maybe they are already there as data if updating.
    const hasFiles = !Array.isArray(files);

    if (!isUpdating) data.assignment = assignmentId;

    // Remove file fields if they don't actually have files.
    if (data.thumbnail === null || typeof data.thumbnail === "string")
      delete data.thumbnail;

    // Either get the upload type, or remove the files data if there are none.
    if (hasFiles) {
      data.type = getUploadType(files);
    } else {
      delete data.files;
    }

    if (
      !data.thumbnail &&
      files.length > 0 &&
      data.type === bucketUploadType.imageStack &&
      !isUpdating
    ) {
      // Set the thumbnail to be teh first file.
      data.thumbnail = files[0];
    }

    const formData = generateFormData(data);
    const action = isUpdating ? updateAssignmentEntry : createAssignmentEntry;
    const extraPayload = isUpdating
      ? { assignmentEntryId: assignmentEntry.id }
      : {};
    const response = await action({ formData, ...extraPayload });

    if (response.error) {
      handleResponseError(response, setFormError, setError);
      setFormError("There was an error submitting your assignment entry.");
    } else {
      // Upload the files if there are any, or else just close the modal.
      if (!Array.isArray(files)) {
        setAssignmentEntry(response.data);
        setFiles(files);
      } else {
        closeModal();
      }

      if (isUpdating) {
        addToast("Assignment entry has been updated.", {
          appearance: "success",
        });
      }
    }
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Title" errors={errors.title}>
        <Controller
          as={FormControl}
          name="title"
          rules={{ required: true, maxLength: 150 }}
          control={control}
          isInvalid={errors.title !== undefined}
        />
      </FormGroup>
      <FileUploadFormGroup
        field="files"
        label="Files"
        tooltipText="You may upload single files such as video, audio, image or a series of images."
        control={control}
        errors={errors}
        controlProps={{ multiple: true }}
        controllerProps={{
          rules: { required: true },
        }}
      />
      <FileUploadFormGroup
        field="thumbnail"
        label="Thumbnail"
        tooltipText="A cover image for your assignment entry submission."
        control={control}
        errors={errors}
      />

      {!isUpdating && (
        <ToggleBox
          label="Share to global feed?"
          onLabel="Yes"
          offLabel="No"
          controllerProps={{
            name: "shareToGlobalFeed",
            control,
            defaultValue: true,
          }}
          tooltipText="In addition to the assignment, your entry will also appear on the global feed."
        />
      )}

      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

AssignmentEntryForm.propTypes = {
  /** Function to close the parent modal. */
  closeModal: func.isRequired,

  /** If we're updating an instance, then it should be passed in. */
  assignmentEntry: shape(AssignmentEntry),

  /** Pass in the assignment that the entry belongs to, if creating new. */
  assignmentId: number,

  /** Pass the files to the parent modal to be uploaded. */
  setFiles: func,

  /** Pass the assignment entry to the parent modal to upload files. */
  setAssignmentEntry: func,

  /** Choose whether or not to give the option to share to global feed. */
  setShareToGlobalFeed: func,
};

AssignmentEntryForm.defaultProps = {
  assignmentEntry: null,
  assignmentId: null,
  setShareToGlobalFeed: () => {},
};

export default AssignmentEntryForm;
