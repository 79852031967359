import React from "react";
import { shape } from "prop-types";

import { RegistrationTierDescription } from "features/tierDescriptions/types";
import { ModalActionProps } from "types";
import ActionButton from "../ActionButton";

function TierDescriptionRow({ description, modalActionProps }) {
  /** Render a row in the tier description table. */

  return (
    <tr>
      <td>{description.description}</td>
      <td>
        <ActionButton
          description={description}
          modalActionProps={modalActionProps}
        />
      </td>
    </tr>
  );
}

TierDescriptionRow.propTypes = {
  /** The revenue split instance that we're rendering a row for. */
  description: shape(RegistrationTierDescription).isRequired,

  /** Properties passed down from the parent modal. */
  modalActionProps: ModalActionProps.isRequired,
};

export default TierDescriptionRow;
