import Caption from "components/text/Caption";
import { arrayOf, func, object, shape } from "prop-types";
import styled from "styled-components";

import ChoiceItem from "../Choice";
import { Choice } from "../types";

const Wrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(${(props) => props.length}, 1fr);
  align-items: center;
  justify-content: space-around;
  margin-top: ${(props) => props.theme.vSpacingMd};
`;

const StyledCaption = styled(Caption)`
  padding-bottom: 0;
  margin-bottom: 0;
`;

function ChoiceContainer({ choices, selected, setSelected, ...props }) {
  /** Container to hold various choice selections. */

  return (
    <Wrapper length={choices.length} {...props}>
      {choices.map((choice) => (
        <ChoiceItem
          key={choice.id}
          isSelected={selected === choice.id}
          onClick={() => setSelected(choice.id)}
        >
          <StyledCaption color={selected === choice.id ? "secondary" : "dark"}>
            {choice.label}
          </StyledCaption>
        </ChoiceItem>
      ))}
    </Wrapper>
  );
}

ChoiceContainer.propTypes = {
  selected: object,
  setSelected: func,
  choices: arrayOf(shape(Choice)),
};

export default ChoiceContainer;
