import { Modal } from "react-bootstrap";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import { useSelector } from "react-redux";

function GiftPurchaseCompleteModal(props) {
  /** Show the user information about their gift purchase.  */

  const { user } = useSelector((state) => state.account);

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title="Gift purchase complete"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        <ModalSummaryText>
          Your subscription gift purchase is complete. Please check your email (
          {user.email}), as you will be receiving the gift code with further
          instructions.
        </ModalSummaryText>
      </Modal.Body>
    </BaseModal>
  );
}

export default GiftPurchaseCompleteModal;
