import React from "react";

import theme from "theme";

function CreateButton({ hover, ...props }) {
  /** Styled button to generally create an object. */
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M80.9909 38.9947C80.9909 50.4126 76.0827 60.5919 68.5994 68.0694C61.1162 75.5469 50.9368 80 39.5247 80C28.1126 80 18.2502 75.2301 10.7669 67.7526C3.28365 60.2751 0 50.4126 0 38.9947C0 27.5769 1.22129 15.652 8.70454 8.17455C16.1878 0.697055 28.1069 0 39.5247 0C50.9426 0 62.9078 0.650969 70.391 8.13423C77.8743 15.6175 80.9909 27.5826 80.9909 38.9947Z"
        fill={hover ? theme.dark : theme.neonPink}
      />
      <g clipPath="url(#clip0_13_104)">
        <path
          d="M63.8764 34.9783C63.8764 38.9694 64.0225 38.9694 64.0225 42.9527C64.0225 43.6448 63.5995 44.2523 62.8921 44.2523C54.81 44.2523 54.81 44.5291 46.728 44.5291C46.0282 44.5291 45.5822 44.7829 45.5822 45.475C45.5822 53.4878 45.1285 53.4878 45.1285 61.5006C45.1285 62.1927 44.8901 62.6233 44.1904 62.6233C40.1686 62.6233 40.1686 62.9924 36.1468 62.9924C35.447 62.9924 35.2009 62.1927 35.2009 61.4929C35.2009 53.4801 35.0625 53.4801 35.0625 45.4673C35.0625 44.7752 34.3089 44.2215 33.6014 44.2215C25.5194 44.2215 25.5194 44.26 17.4297 44.26C16.7299 44.26 15.984 43.6448 15.984 42.9527C15.984 38.9617 16.1532 38.9617 16.1532 34.9783C16.1532 34.2862 16.7222 33.9863 17.4297 33.9863C25.5117 33.9863 25.5117 34.094 33.6014 34.094C34.3012 34.094 35.2394 33.1558 35.2394 32.4638C35.2394 24.4509 34.778 24.4509 34.778 16.4458C34.778 15.7537 35.4393 15.0078 36.1391 15.0078C40.1609 15.0078 40.1609 15.523 44.1827 15.523C44.8824 15.523 45.3977 15.746 45.3977 16.4381C45.3977 24.4509 45.0978 24.4509 45.0978 32.4561C45.0978 33.1482 46.0282 33.5173 46.728 33.5173C54.81 33.5173 54.81 33.5019 62.8921 33.5019C63.5995 33.5019 63.8764 34.2786 63.8764 34.9783Z"
          fill="#FAFF29"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_104">
          <rect
            width="48.0308"
            height="48"
            fill="white"
            transform="translate(16 15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CreateButton;
