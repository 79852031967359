import React, { useEffect, useRef, useState } from "react";
import { func, string } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  useCreateWatchSessionMutation,
  useGetContentUploadDetailQuery,
  useUpdateContentUploadMutation,
  useUpdateWatchSessionMutation,
} from "services/projectCity";
import { shouldCreateVideoSession } from "utils/permissions";
import useIsProjectCityAdmin from "hooks/IsProjectCityAdmin";

const Video = styled.video`
  width: 100%;
  height: auto;
`;

function SprintVideo({ contentUploadId, src, onEnded, ...props }) {
  /** Sprint video component. */
  const [hasCreatedSession, setHasCreatedSession] = useState(false);
  const [createSession, result] = useCreateWatchSessionMutation();
  const [updateSession] = useUpdateWatchSessionMutation();
  const { data: contentUpload } = useGetContentUploadDetailQuery({
    contentUploadId,
  });
  const [updateContentUpload] = useUpdateContentUploadMutation();
  const user = useSelector((state) => state.account.user);
  const videoRef = useRef(null);
  const isProjectCityAdmin = useIsProjectCityAdmin();

  function getTotalTimeWatched(played) {
    let total = 0;
    for (let i = 0; i < played.length; i++) {
      total += played.end(i) - played.start(i);
    }

    return Math.floor(total);
  }

  async function handlePlay(e) {
    /** Set the duration of the video if it's not set properly yet. */
    const duration = Math.round(videoRef?.current?.duration);
    if (
      (isProjectCityAdmin || user.id === contentUpload.createdBy.id) &&
      duration !== contentUpload.duration
    ) {
      updateContentUpload({
        contentUploadId,
        formData: { duration },
      });
    }

    /** Create the watch session so we can track how much the user watches. */
    if (!hasCreatedSession && shouldCreateVideoSession(user)) {
      setHasCreatedSession(true);
      createSession({ contentUpload: contentUploadId });
    }
  }

  useEffect(() => {
    // Remove ability to right click video.
    videoRef.current.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (result?.data?.id) {
      const interval = setInterval(() => {
        updateSession({
          videoWatchSessionId: result.data.id,
          duration: getTotalTimeWatched(videoRef.current.played),
        });
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [result.data]);

  useEffect(() => {
    // Reset the user's watch session when changing videos
    setHasCreatedSession(false);
  }, [contentUploadId]);

  // TODO: Ideally we use components/videos/Video, however passing the ref gives issues.
  return (
    <Video
      controlsList="nodownload"
      ref={videoRef}
      onPlay={handlePlay}
      onEnded={onEnded}
      playsInline={true}
      controls
      src={src}
      type="video/mp4"
      {...props}
    />
  );
}

SprintVideo.propTypes = {
  /** The id of the content upload object the video is from. */
  contentUploadId: string.isRequired,

  /** The source url of the video file. */
  src: string.isRequired,

  /** Action to take when the video ends. */
  onEnded: func,
};

SprintVideo.defaultProps = {
  onEnded: () => {},
};

export default SprintVideo;
