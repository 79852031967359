// General functions for datetimes.

import moment from "moment";

export function militaryToDisplay(timeStr) {
  // Receives a string time (i.e. 22:00:00) and returns the display in am/pm.
  return moment(timeStr, "HH:mm:ss").format("h:mm a");
}

export function formatTimeStamp(timestamp, format = "DD/MM/YYYY") {
  // turn a timestamp to a formatted date
  const date = new Date(timestamp);
  return moment(date).format(format);
}

export function getMonthDisplay(monthInteger) {
  // Turn a month integer (i.e. 1) into a display string (i.e. "January").
  return moment()
    .month(monthInteger - 1)
    .format("MMMM");
}

export function formatTimeStampUnix(timestamp, format = "DD/MM/YYYY") {
  // turn a timestamp to a formatted date
  const date = new Date(timestamp);
  return moment.unix(date).format(format);
}
