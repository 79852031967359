import { baseUrl } from "../api";

const authBase = `${baseUrl}auth/`;

export const accountsUrl = `${baseUrl}accounts/`;

export const loginUrl = `${authBase}login/`;
export const registrationUrl = `${authBase}registration/`;
export const resendVerification = `${authBase}registration/resend-email/`;
export const loginGoogleUrl = `${authBase}google/`;
export const verifyUrl = `${authBase}token/verify/`;
export const refreshTokenUrl = `${authBase}token/refresh/`;
export const forgotPasswordUrl = `${authBase}password/reset/`;
export const resetPasswordUrl = `${authBase}password/reset/confirm/`;
export const changePasswordUrl = `${authBase}password/change/`;
export const meUrl = `${authBase}user/`;
export const disableGoogleAcc = `${authBase}disable-google/`;
export const changeEmailURL = `${authBase}change-email/`;
export const changeEmailConfirmURL = `${authBase}change-email-confirm/`;
export const verifyEmailUrl = `${authBase}confirm-email/`;
