import { createAsyncThunk } from "@reduxjs/toolkit";
import { AGREE_TOS } from "./constants";
import UserService from "./service";

const service = new UserService();

export const agreeTos = createAsyncThunk(
  AGREE_TOS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.agreeTos();
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);
