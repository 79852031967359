import React from "react";
import styled from "styled-components";

import Caption from "../Caption";

const Wrapper = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align-center;
`;

function ContainerDescriptionText({ text, ...props }) {
  /** Place some text at the bottom of a container giving the user extra context. */
  return (
    <Wrapper>
      <Caption color="pink">{text}</Caption>
    </Wrapper>
  );
}

export default ContainerDescriptionText;
