import React, { useMemo } from "react";
import { bool, oneOf, string } from "prop-types";
import styled from "styled-components";

import { getRandomColor } from "utils/general";
import theme from "theme";

const StyledSubHeader = styled.h3`
  font-family: ${(props) => (props.italic ? "GT Alpina" : "SuisseIntl")};

  ${(props) =>
    props.italic &&
    `
    letter-spacing: -0.03em;
  `}

  color: ${(props) => props.color};
  margin-bottom: 0;

  ${(props) => {
    switch (props.size) {
      case "sm":
        return `
          font-size: 30px;
          line-height: 30px;
        `;
      case "lg":
        return `
          font-size: 54px;
          line-height: 36px;

          @media (max-width: ${(props) => props.theme.smBreakpoint}) {
            font-size: 30px;
            line-height: 30px;
          }
        `;
      case "xl":
        return `
          font-size: 120px;
          line-height: 80px;
        `;
      default:
        return `
          font-size: 34px;
          line-height: 34px;
        `;
    }
  }}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    line-height: 28px;
    font-size: ${(props) => (props.italic ? "42px" : "28px")};
  }

  font-style: ${(props) => (props.italic ? "italic" : "none")};

  ${(props) =>
    (props.tilt || props.positiveRotate !== null) &&
    `
    transform: rotate(${props.positiveRotate ? "" : "-"}2deg);
  `};

  display: ${(props) => (props.inline ? "inline" : "block")};

  ${(props) =>
    props.tilt === false &&
    `
    transform: rotate(0deg);
  `}
`;

const AccentText = styled.span`
  font-family: Basis Grotesque Mono;
  font-weight: 200;
`;

function SubHeaderText({ accentText, children, ...props }) {
  /** Text to apply in the subheader component, and some other places as well. */

  // We need to memoize the color or else it can change color on re-render.
  const color = useMemo(
    () =>
      theme[props.color] || getRandomColor({ exclude: props.excludeColors }),
    [props.color]
  );

  const positiveRotate = useMemo(
    () =>
      props.positiveRotate !== null
        ? props.positiveRotate
        : Math.random() >= 0.5,
    []
  );

  return (
    <StyledSubHeader {...props} color={color} positiveRotate={positiveRotate}>
      {children} {accentText && <AccentText>{accentText}</AccentText>}
    </StyledSubHeader>
  );
}

SubHeaderText.propTypes = {
  /** Specify a color to apply to the text, or else choose random. */
  color: string,

  /** Force a tilt on the subheader text (sometimes it's on the container) */
  tilt: bool,

  /** Extra text to display at the end that is thinner. */
  accentText: string,

  /** Make the text inline instead of block. */
  inline: bool,

  /** Manually set the rotate to be positive or negative */
  positiveRotate: bool,

  /** Set the size of the text */
  size: oneOf(["sm", "md", "lg", "xl"]),
};

SubHeaderText.defaultProps = {
  color: null,
  accentText: null,
  tilt: false,
  inline: false,
  positiveRotate: null,
};

export default SubHeaderText;
