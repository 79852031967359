import React, { useState } from "react";
import { func, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import BaseForm from "components/forms/Base";
import Checkbox from "components/controls/Checkbox";
import Select from "components/controls/Select";
import FormGroup from "components/forms/shared/FormGroup";
import FormErrorText from "components/forms/shared/ErrorText";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import InfoTooltip from "components/tooltips/Info";
import MetadataContainer from "components/forms/shared/MetadataContainer";
import {
  updateEnrollment,
  createEnrollment,
  sendEnrollmentInvoice,
} from "features/enrollments/thunks";
import { Enrollment } from "features/enrollments/types";
import UserService from "features/users/service";
import { enrollmentStatus } from "utils/enums";
import { convertEnumToArray, getObjectByValue } from "utils/general";
import { getErrorMessage } from "utils/general";

const StyledSelect = styled(Select)`
  > * {
    font-size: 21px;
  }
`;

function EnrollmentForm({ enrollment, closeModal, ...props }) {
  /** Form to update or create an enrollment record. */

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    handleSubmit,
    control,
    errors,
    setError,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      ...enrollment,
      registrationTier:
        enrollment.registrationTier && enrollment.registrationTier.id,
    },
  });
  const [search, setSearch] = useState("");

  // Extra data is sent to stripe as the metadata of the invoice which is shown at the
  // top when we need to provide extra data on the invoice.
  const registrationTiers = useSelector((state) => state.projects.detail.tiers);
  const watchSendInvoice = watch("sendInvoice");
  const isUpdating = enrollment.id !== undefined;
  const [extraData, setExtraData] = useState({ "": "" });

  async function onSubmit(data) {
    /* Dispatch the create or update action for enrollment.

       If we send the `sendInvoice` then we'll create the enrollment record and then send a
       follow-up action to generate and send an invoice.
    */
    const dispatchAction = isUpdating ? updateEnrollment : createEnrollment;
    const { sendInvoice } = data;
    delete data.sendInvoice;
    let actionPayload = {
      payload: data,
    };

    if (isUpdating) actionPayload.enrollmentId = enrollment.id;

    const action = await dispatch(dispatchAction(actionPayload));

    if (action.type.includes("fulfilled")) {
      let message = `Enrollment ${isUpdating ? "updated" : "created"}`;
      delete extraData[""];
      if (sendInvoice) {
        dispatch(
          sendEnrollmentInvoice({
            enrollmentId: action.payload.id,
            payload: {
              customFields: extraData,
            },
          })
        );
        message += " and the invoice has been sent.";
      }

      addToast(message, { appearance: "success" });
      closeModal();
    } else if (action.type.includes("rejected")) {
      const message = getErrorMessage(
        action.payload.data,
        `Error ${isUpdating ? "updating" : "creating"} enrollment.`
      );
      setError("form", { message });
    }

    return action;
  }

  // Get the tier choices to use in a dropdown.
  const tierChoices = registrationTiers.map((registrationTier) => {
    return { value: registrationTier.id, label: registrationTier.title };
  });

  async function getOptions() {
    const userService = new UserService();
    const results = await userService.search(search);
    return results.data.length === 0
      ? []
      : results.data.map((user) => ({ value: user.id, label: user.username }));
  }

  function renderStudentSelect() {
    /** Only allow selecting the student if adding a new enrollment. */

    return enrollment.id !== undefined ? null : (
      <FormGroup label="Student (existing user)" errors={errors.student}>
        <Controller
          render={({ onChange, ref }) => (
            <StyledSelect
              isAsync
              noOptionsMessage={() =>
                "User not found - enter full username/email"
              }
              onInputChange={(value) => setSearch(value)}
              onChange={(obj) => onChange(obj.value)}
              inputRef={ref}
              loadOptions={getOptions}
              placeholder="Enter email or handle"
            />
          )}
          control={control}
          name="student"
          defaultValue={enrollment.student || ""}
        />
        <FormErrorText text={errors.student && errors.student.message} />
      </FormGroup>
    );
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      {renderStudentSelect()}
      <Form.Row>
        <Col sm={4}>
          <FormGroup label="Status" errors={errors.status}>
            <Controller
              render={({ onChange }) => (
                <Select
                  options={convertEnumToArray(enrollmentStatus)}
                  defaultValue={
                    enrollment?.status &&
                    getObjectByValue(enrollmentStatus, enrollment.status)
                  }
                  onChange={(selected) => onChange(selected.value)}
                />
              )}
              control={control}
              name="status"
            />
          </FormGroup>
        </Col>
        <Col sm={8}>
          <FormGroup label="Tier" errors={errors.registrationTier}>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  options={tierChoices}
                  defaultValue={tierChoices.find(
                    (tier) => tier.value === value
                  )}
                  onChange={(selected) => onChange(selected.value)}
                />
              )}
              rules={{ required: true }}
              name="registrationTier"
              control={control}
              isInvalid={errors.registrationTier !== undefined}
            />
          </FormGroup>
        </Col>
      </Form.Row>

      {!isUpdating && (
        <>
          <FormGroup errors={errors.sendInvoice} className="mt-2">
            <Controller
              as={Checkbox}
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}
              isInvalid={errors.sendInvoice !== undefined}
              label={
                <>
                  <span>Send invoice </span>
                  <InfoTooltip text="Send an invoice to the student's email. When the invoice is paid, the pending enrollment will become active." />
                </>
              }
              name="sendInvoice"
              defaultValue={true}
              control={control}
            />
          </FormGroup>
          {watchSendInvoice && (
            <MetadataContainer state={extraData} setState={setExtraData} />
          )}
        </>
      )}

      <FormSubmitContainer
        withTopDivider
        errorText={errors.form && errors.form.message}
      >
        <FormPrimaryButton isLoading={isSubmitting}>Save</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

EnrollmentForm.propTypes = {
  /** The enrollment object that we're updating. */
  enrollment: shape(Enrollment).isRequired,

  /** Function to close the modal the form is in. */
  closeModal: func.isRequired,
};

EnrollmentForm.defaultProps = {
  enrollment: {
    registrationTier: {},
  },
};

export default EnrollmentForm;
