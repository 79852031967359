import React from "react";
import Modal from "react-bootstrap/Modal";
import { func, shape } from "prop-types";
import styled from "styled-components";

import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormConfirmDangerButton from "components/buttons/forms/FormConfirmDanger";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import BaseModal from "components/modals/Base";
import Body from "components/text/Body";
import { Comment } from "types";

const StyledBaseModal = styled(BaseModal)`
  box-shadow: 4px 4px 4px ${(props) => props.theme.gray2};
  border-radius: 5px;
`;

const StyledModalBody = styled(Modal.Body)`
  color: ${(props) => props.theme.yellow};
  font-size: 21px;
`;

function DeleteCommentModal({ comment, handleDelete, ...props }) {
  /** Confirm deletion of a comment. */

  return (
    <StyledBaseModal {...props} size="md">
      <StyledModalBody>
        <Body>
          Deleting comments is permanent. Are you sure you want to delete this
          comment?
        </Body>
        <Body className="mt-2">
          <b>{comment.text}</b>
        </Body>
        <FormSubmitContainer withTopDivider>
          <FormSecondaryButton onClick={props.onHide}>
            No, cancel
          </FormSecondaryButton>
          <FormConfirmDangerButton onClick={handleDelete}>
            Delete
          </FormConfirmDangerButton>
        </FormSubmitContainer>
      </StyledModalBody>
    </StyledBaseModal>
  );
}

DeleteCommentModal.propTypes = {
  /** The comment which we're deleting. */
  comment: shape(Comment).isRequired,

  /** Action to handle the deletion of the comment. */
  handleDelete: func.isRequired,
};

export default DeleteCommentModal;
