import { number, string } from "prop-types";

export const NewsItem = {
  /** The identifier of the news item. */
  id: number.isRequired,

  /** The title of the news item. */
  title: string.isRequired,

  /** The rich-text (html) body of the news. */
  body: string.isRequired,

  actionLink: string,
  actionText: string,
};
