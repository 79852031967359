import React from "react";
import { func } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Body from "components/text/Body";
import BaseForm from "components/forms/Base";
import FormControl from "components/forms/shared/Control";
import Checkbox from "components/controls/Checkbox";
import ErrorText from "components/forms/shared/ErrorText";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import SocialAuthenticationContainer from "components/authentication/SocialAuthenticationContainer";
import AlternativeDivider from "components/forms/shared/AlternativeDivider";
import { registerUser } from "features/accounts/thunks";

const StyledToU = styled.a`
  font-family: Basis Grotesque Pro;
  color: ${({ error, theme }) => (error ? theme.red : theme.dark)};
  text-underline-offset: 5px;
  text-decoration: underline;
`;

const StyledFormGroup = styled(FormGroup)`
  p {
    color: ${(props) => props.theme.dark}!important;
  }
`;

function RegistrationForm({ showVerification, closeModal }) {
  const {
    handleSubmit,
    control,
    setError,
    errors,
    getValues,
    formState: { isSubmitting },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const response = await dispatch(registerUser(data));

    if (response.type === "REGISTER_USER/rejected") {
      for (let fieldKey in response.payload.data) {
        setError(fieldKey, {
          type: "manual",
          message: response.payload.data[fieldKey],
        });
      }
    }

    if (response.type === "REGISTER_USER/fulfilled") {
      showVerification();
    }

    return response;
  };

  const checkLabel = (
    <Body
      color={errors.agreement ? "red" : "dark"}
      style={{ lineHeight: "22px" }}
    >
      I agree with the&nbsp;
      <StyledToU
        href="/terms-of-use"
        target="_blank"
        rel="noreferrer"
        error={errors.agreement ? true : false}
      >
        Terms of Use
      </StyledToU>
    </Body>
  );

  return (
    <>
      <SocialAuthenticationContainer
        showVerification={showVerification}
        closeModal={closeModal}
      />
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <AlternativeDivider />
        <Form.Row>
          <Col xs={12} md={6}>
            <FormGroup label="First name" errors={errors.firstName}>
              <Controller
                as={FormControl}
                rules={{ required: true }}
                name="firstName"
                control={control}
                defaultValue=""
                isInvalid={errors.firstName !== undefined}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup label="Last name" errors={errors.lastName}>
              <Controller
                as={FormControl}
                rules={{ required: true }}
                name="lastName"
                control={control}
                defaultValue=""
                isInvalid={errors.lastName !== undefined}
              />
            </FormGroup>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} md={6}>
            <FormGroup label="Email" errors={errors.email}>
              <Controller
                as={FormControl}
                rules={{
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                name="email"
                control={control}
                defaultValue=""
                isInvalid={errors.email !== undefined}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup label="Username" errors={errors.username}>
              <Controller
                as={FormControl}
                rules={{ required: true }}
                name="username"
                control={control}
                defaultValue=""
                isInvalid={errors.username !== undefined}
              />
            </FormGroup>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} md={6}>
            <FormGroup label="Password" errors={errors.password1}>
              <Controller
                as={FormControl}
                rules={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  validate: {
                    value: () =>
                      getValues("password1") === getValues("password2") ||
                      "Passwords do not match",
                  },
                }}
                isInvalid={errors.password1 !== undefined}
                name="password1"
                type="password"
                control={control}
                defaultValue=""
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup label="Password (repeat)" errors={errors.password2}>
              <Controller
                as={FormControl}
                isInvalid={errors.password2 !== undefined}
                name="password2"
                type="password"
                control={control}
                defaultValue=""
              />
            </FormGroup>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <StyledFormGroup errors={errors.mailingList} className="mt-2">
              <Controller
                as={<Checkbox label="Yes, I'd like email updates" />}
                name="mailingList"
                defaultChecked={true}
                defaultValue={true}
                control={control}
              />
            </StyledFormGroup>
          </Col>
          <Col>
            <FormGroup errors={errors.agreement} className="mt-2">
              <Controller
                as={<Checkbox label={checkLabel} />}
                name="agreement"
                defaultChecked={false}
                defaultValue={false}
                control={control}
                rules={{
                  required: true,
                  message: "You must agree to the terms",
                }}
              />
            </FormGroup>
          </Col>
        </Form.Row>

        <FormSubmitContainer withBottomDivider>
          <FormSecondaryButton onClick={showVerification}>
            Cancel
          </FormSecondaryButton>
          <FormPrimaryButton isLoading={isSubmitting}>
            Join us
          </FormPrimaryButton>
        </FormSubmitContainer>

        <ErrorText text={errors.form && errors.form.message} />
      </BaseForm>
    </>
  );
}

RegistrationForm.propTypes = {
  /** Action to transition the parent modal to show verification state. */
  showVerification: func.isRequired,

  /** Action to close the parent modal. */
  closeModal: func.isRequired,
};

export default RegistrationForm;
