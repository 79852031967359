import BaseTour from "components/tours/Base";

import { tourType } from "features/tours/enums";

function NewSubscriberTour() {
  /** Show a new user some introduction to our main site features. */

  const steps = [
    {
      target: "#sprint-content-navitem",
      disableBeacon: true,
      content:
        "Check here for links to live sprint classes, all previous sprint recordings, and to share your animatics with the community",
    },
    {
      target: "#sprint-schedule-navitem",
      content: "View the schedule of upcoming live and recorded classes",
    },
    {
      target: "#sprint-contests-navitem",
      content: "Monthly contests and prizes",
    },
    {
      target: "#sprint-assignments-navitem",
      content:
        "Homework assignments that you can do at your own pace, receive a check mark for each assignment you complete",
    },
  ];

  return <BaseTour steps={steps} tourType={tourType.newSubscription} />;
}

export default NewSubscriberTour;
