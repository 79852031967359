import React, { useEffect } from "react";
import { Router, Redirect, Location } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "react-modal-hook";

import NewAgreementModal from "components/modals/NewAgreement";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { updateMe } from "features/accounts/thunks";
import ConnectAccountReminderModal from "features/accounts/modals/ConnectAccountReminder";
import NewsItemModal from "features/news/modals/NewsModal";
import DashboardRoute from "routes/Dashboard";
import SiteFeedRoute from "routes/SiteFeed";
import AboutRoute from "routes/About";
import SubscriptionRoute from "routes/Subscription";
import ProjectListRoute from "routes/Projects/ListAll";
import ClassesBaseRoute from "routes/Classes/index.js";
import LiveClassesRoute from "routes/Classes/Live";
import ClassRecordingsRoute from "routes/Classes/Recordings";
import SprintClassesRoute from "routes/Classes/Sprint";
import PurchasedClassesRoute from "routes/Classes/Purchased";
import ProjectDashboardRoute from "routes/Projects/Dashboard";
import ProjectInfoRoute from "routes/Projects/Info";
import ProjectCreateRoute from "routes/Projects/Create";
import ProjectSearchRoute from "routes/Projects/Search";
import ProjectDetailRoute from "routes/Projects/Detail";
import ProjectManageRoute from "routes/Projects/Manage";
import ManageTiersRoute from "routes/Projects/Manage/Tiers";
import ManageStudentsRoute from "routes/Projects/Manage/Students";
import ManagePaymentsRoute from "routes/Projects/Manage/Payments";
import ManageProjectRoute from "routes/Projects/Manage/Project";
import BucketRoute from "routes/Projects/Bucket";
import VerifyPaymentRoute from "routes/Projects/VerifyPayment";
import SprintBaseRoute from "routes/Sprint";
import SprintScheduleRoute from "routes/Sprint/Schedule";
import AnimaticWinnersRoute from "routes/Sprint/Winners";
import SprintAssignmentRoute from "routes/Sprint/Assignments";
import SprintAssignmentDetailRoute from "routes/Sprint/AssignmentDetail";
import ContestListRoute from "routes/Sprint/Contests";
import ContestDetailRoute from "routes/Sprint/ContestDetail";
import SprintDetailRoute from "routes/Sprint/Detail";
import SprintListRoute from "routes/Sprint/List";
import SprintManagementBaseRoute from "routes/Sprint/Management";
import SprintManagementVideosRoute from "routes/Sprint/Management/Videos";
import SprintManagementPaymentsRoute from "routes/Sprint/Management/Payments";
import SprintManagementDonationsRoute from "routes/Sprint/Management/Donations";
import ResetPasswordRoute from "routes/Authentication/ResetPassword";
import ChangeEmailConfirmRoute from "routes/Authentication/ConfirmChangeEmail";
import ConfirmEmailRoute from "routes/Authentication/ConfirmEmail";
import TermsOfUseRoute from "routes/General/TermsOfUse";
import PrivacyPolicyRoute from "routes/General/PrivacyPolicy";
import UserProfileRoute from "routes/UserProfile";
import FAQRoute from "routes/General/FAQ";

class OnRouteChangeWorker extends React.Component {
  /* Scrolls the window to the top, preventing loading new routes scrolled down.

     https://stackoverflow.com/questions/53058110/stop-reach-router-scrolling-down-the-page-after-navigating-to-new-page
  */

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action();
    }
  }

  render() {
    return null;
  }
}

const OnRouteChange = ({ action }) => (
  <Location>
    {({ location }) => (
      <OnRouteChangeWorker location={location} action={action} />
    )}
  </Location>
);

export default function BaseRouter() {
  /** Main router for our entire application. */
  const accountState = useSelector((state) => state.account);
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  const {
    unconnectedProjects,
    showConnectAccount,
    hasLatestAgreement,
    unseenLatestNewsItem,
  } = accountState.user;

  const { isLoading } = accountState;

  const [showAgreementModal, hideAgreementModal] = useModal(() => (
    <NewAgreementModal onHide={hideAgreementModal} />
  ));

  const [showConnectModal, hideConnectModal] = useModal(
    () => (
      <ConnectAccountReminderModal
        project={unconnectedProjects[0]}
        onHide={hideConnectModal}
      />
    ),
    [unconnectedProjects]
  );

  const [showNewsModal, hideNewsModal] = useModal(() => {
    function closeModal() {
      dispatch(updateMe({ hasLatestNews: true }));
      hideNewsModal();
    }

    return (
      <NewsItemModal newsItem={unseenLatestNewsItem} onHide={closeModal} />
    );
  }, [unseenLatestNewsItem]);

  useEffect(() => {
    /**
     * If the user has unconnected accounts, then we should show them a prompt to
     * connect.
     */

    if (!isLoading)
      unconnectedProjects &&
        unconnectedProjects.length > 0 &&
        showConnectAccount &&
        showConnectModal();
  }, [unconnectedProjects, showConnectAccount, isLoading]);

  useEffect(() => {
    if (!hasLatestAgreement && isAuthenticated) showAgreementModal();
  }, [hasLatestAgreement, isAuthenticated]);

  useEffect(() => {
    /** Display news item in modal if the user has the latest unseen. */
    if (!isLoading && unseenLatestNewsItem) showNewsModal();
  }, [isLoading, unseenLatestNewsItem]);

  useEffect(() => {
    if (!hasLatestAgreement && isAuthenticated) showAgreementModal();
  }, [hasLatestAgreement, isAuthenticated]);

  return (
    <>
      <Router primary={false}>
        <DashboardRoute path="/">
          <Redirect noThrow from="/" to="sprint/content" />
          <PrivacyPolicyRoute path="privacy-policy" />
          <FAQRoute path="faq" />
          <TermsOfUseRoute path="terms-of-use" />
          <SiteFeedRoute path="feed" />
          <Redirect noThrow from="classes" to="live" />
          <ClassesBaseRoute path="classes">
            <LiveClassesRoute path="live" />
            <ClassRecordingsRoute path="recordings" />
            <SprintClassesRoute path="sprint" />
            <PurchasedClassesRoute path="purchased" />
          </ClassesBaseRoute>
          <AboutRoute path="about" />
          <SubscriptionRoute path="subscription" />
          <ProjectListRoute path="projects" />
          <ProjectCreateRoute path="projects/create" />
          <ProjectSearchRoute path="search" />
          <UserProfileRoute path="users/:username" />
          <VerifyPaymentRoute path="tiers/:registrationTierId/verify-payment" />
          <SprintBaseRoute path="sprint">
            <SprintListRoute path="content" exact />
            <SprintDetailRoute path="content/:id/:slug" />
            <SprintScheduleRoute path="schedule" />
            <AnimaticWinnersRoute path="winners" />
            <SprintAssignmentRoute path="assignments" />
            <SprintAssignmentDetailRoute path="assignments/:id/:slug" />
            <ContestListRoute path="contests" exact />
            <ContestDetailRoute path="contests/:id/:slug" />
          </SprintBaseRoute>
          <SprintManagementBaseRoute path="sprint-management">
            <SprintManagementVideosRoute path="videos" />
            <SprintManagementPaymentsRoute path="payments" />
            <SprintManagementDonationsRoute path="donations" />
          </SprintManagementBaseRoute>
          <Redirect
            noThrow
            from="projects/:projectId/:date/:projectSlug/info"
            to="/projects/:projectId/:date/:projectSlug"
          />
          <Redirect
            noThrow
            from="projects/:projectId/:date/:projectSlug/class-info"
            to="/projects/:projectId/:date/:projectSlug"
          />
          <ProjectDetailRoute path="projects/:projectId/:date/:projectSlug">
            <ProjectManageRoute path="manage">
              <ManageProjectRoute path="project" />
              <ManageTiersRoute path="tiers" />
              <ManageStudentsRoute path="students" />
              <ManagePaymentsRoute path="payments" />
            </ProjectManageRoute>
            <ProjectDashboardRoute path="/">
              <ProjectInfoRoute path="/" exact />
              <ProjectInfoRoute path="class-info" exact />
              <BucketRoute path=":bucketSlug" />
            </ProjectDashboardRoute>
          </ProjectDetailRoute>
          <ResetPasswordRoute path="password-reset/confirm/:uid/:token/" />
          <ChangeEmailConfirmRoute path="auth/change-email-confirm/:uid/:token/:email/" />
        </DashboardRoute>
        <ConfirmEmailRoute path="confirm-email/:emailKey" />
      </Router>
      <OnRouteChange action={() => window.scrollTo(0, 0)} />
    </>
  );
}
