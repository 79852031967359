import React from "react";
import { bool } from "prop-types";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import styled from "styled-components";

import BucketUploadDropzone from "../Uploads/BucketUploadDropzone";
import BucketHeader from "../Header";

const StyledContainer = styled(Container)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 60px;
  }
`;

function BucketBase({ dropzone, fluidHeader, children, ...props }) {
  /**
   * Shared component for all the buckets to inherit from, providing base functionality
   * and style.
   */

  const project = useSelector((state) => state.projects.detail);

  // We're either going to render just the inner portion of the bucket base, or we're
  // going to wrap it with the dropzone.
  const inner = (
    <StyledContainer fluid className="h-100 position-relative" {...props}>
      <BucketHeader fluid={fluidHeader} project={project} />
      {children}
    </StyledContainer>
  );

  if (dropzone) {
    return (
      <BucketUploadDropzone className="h-100">{inner}</BucketUploadDropzone>
    );
  }

  return inner;
}

BucketBase.propTypes = {
  /** Determine if we should render a dropzone over the entire bucket area. */
  dropzone: bool,

  /** Set the header to be full width. */
  fluidHeader: bool,
};

BucketBase.defaultProps = {
  dropzone: false,
  fluidHeader: false,
};

export default BucketBase;
