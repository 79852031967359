import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useModal } from "react-modal-hook";

import { updateProject } from "features/projects/thunks";
import UploadPosterImageModal from "features/projects/modals/UploadPosterImage";
import StyledRadioButton from "components/controls/RadioButton";
import theme from "theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFormGroup = styled(Form.Group)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${(props) => props.theme.blue};
  margin-bottom: 0px;

  > label {
    flex-basis: 50%;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 14px;
  }
`;

function PublicToggle({ project, setPublicAction }) {
  /** Provide a component to toggle if a project is public or private. */

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [showUploadPosterModal, hideUploadPosterModal] = useModal(() => (
    <UploadPosterImageModal onHide={hideUploadPosterModal} project={project} />
  ));

  async function handleToggle(isPublic) {
    /** Toggle the project's is public flag.  */

    // Verify that the project has a poster image uploaded.
    if (isPublic && !project.image) return showUploadPosterModal();

    if (isLoading) return;

    setIsLoading(true);

    const actionPayload = {
      projectId: project.id,
      payload: {
        isPublic,
      },
    };
    await dispatch(updateProject(actionPayload));

    setIsLoading(false);

    // After the project has been made public, we should ask the user if they want to
    // share the project if they haven't already.
    if (!project.isPublic) setPublicAction();
  }

  return (
    <Wrapper>
      <StyledFormGroup>
        <StyledRadioButton
          color={theme.pink}
          onClick={() => handleToggle(false)}
          active={!project.isPublic ? "true" : null}
          type="radio"
          label="DRAFT"
          name="is-draft"
          checked={!project.isPublic}
        />
        <StyledRadioButton
          color={theme.yellow}
          onClick={() => handleToggle(true)}
          active={project.isPublic ? "true" : null}
          type="radio"
          label="PUBLIC"
          name="is-public"
          checked={project.isPublic}
        />
      </StyledFormGroup>
    </Wrapper>
  );
}

PublicToggle.propTypes = {
  // The project for which we're toggling.
  project: PropTypes.object.isRequired,

  // Action to take when setting the project as public.
  setPublicAction: PropTypes.func,
};

PublicToggle.defaultProps = {
  setPublicAction: () => {},
};

export default PublicToggle;
