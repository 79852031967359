import { number } from "prop-types";
import styled from "styled-components";

const StyledGrid = styled.div`
  display: grid;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: repeat(${(props) => props.$lgColumns}, 1fr);
    padding: ${(props) => props.theme.vSpacingXl};
    grid-gap: 100px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    padding: ${(props) => props.theme.vSpacingMd};
  }
`;

function Grid(props) {
  /** Standard reusable grid layout. */
  return <StyledGrid {...props}>{props.children}</StyledGrid>;
}

Grid.propTypes = {
  $lgColumns: number,
};

Grid.defaultProps = {
  $lgColumns: 4,
};

export default Grid;
