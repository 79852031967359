import React from "react";

import Button from "components/buttons/Button";

function ActionButton({ children, ...props }) {
  return (
    <Button variant="info" isWavy {...props}>
      {children}
    </Button>
  );
}

export default ActionButton;
