import React from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

const StyledCard = styled(Card)`
  position: relative;
  height: 100%;
  background-color: transparent;
  border: 4px solid ${(props) => props.theme.blue};
  padding: 8px;
`;

function TierBucketsCard(props) {
  /** Parent card component for displaying a registration tier. */

  return <StyledCard {...props}>{props.children}</StyledCard>;
}

export default TierBucketsCard;
