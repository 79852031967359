import { oneOf } from "prop-types";
import Joyride from "react-joyride";
import { useDispatch } from "react-redux";

import { tourType } from "features/tours/enums";
import { registerTour as _registerTour } from "features/accounts/slice";
import { useRegisterTourMutation } from "services/projectCity";

function BaseJoyride({ tourType, ...props }) {
  /** A base joyride class for our other tools to inherit from. */

  const [registerTour] = useRegisterTourMutation();
  const dispatch = useDispatch();

  function handleCallback(props) {
    /** Default action to take on the tour callback event. */
    const { type } = props;
    if (type === "tour:end") {
      registerTour({ type: tourType });
      dispatch(_registerTour({ tourType }));
    }
  }

  return (
    <Joyride
      continuous
      showSkipButton
      showProgress
      callback={handleCallback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1031,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1031,
        },
      }}
      {...props}
    />
  );
}

BaseJoyride.propTypes = {
  tourType: oneOf(tourType),
};

export default BaseJoyride;
