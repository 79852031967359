import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Moment from "react-moment";

import Body from "components/text/Body";
import LikeContainer from "features/likes/components/Container";
import { useTogglePostLikeMutation } from "services/projectCity";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.vSpacingXs} 0;
  margin-top: -10px;
  height: 50px;
`;

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

function PostActionBar({ post, ...props }) {
  /** Render the actions for a Post (likes, shares ...etc) */

  const { isLikedByCurrentUser, likesCount } = post;
  const [toggleLike] = useTogglePostLikeMutation();

  function handleToggleLike() {
    toggleLike({ id: post.id });
  }

  return (
    <Wrapper $isLiked={isLikedByCurrentUser}>
      <ActionBar>
        <LikeContainer
          count={likesCount}
          onClick={handleToggleLike}
          likeActive={isLikedByCurrentUser}
        />
        <Body size="sm" inline className="pt-1">
          <Moment fromNow>{post.created}</Moment>
        </Body>
      </ActionBar>
    </Wrapper>
  );
}

PostActionBar.prototype = {
  /** The post object that we're rendering.  */
  post: PropTypes.object.isRequired,
};

export default PostActionBar;
