import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

import BaseModal from "components/modals/Base";
import ModalSummaryText from "components/text/ModalSummaryText";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import useSupportModal from "hooks/SupportModal";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";

const Support = styled.span`
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.neonPink};
  }
`;

function NeedsApprovalModal(props) {
  /** Show the user that they need approval before they can crate a class. */

  const showContactModal = useSupportModal();

  function handleSupportEmail() {
    showContactModal();
  }

  return (
    <BaseModal {...props}>
      <Modal.Body>
        <ModalSummaryText>
          Interested in teaching a class? Hit us up via our{" "}
          <Support onClick={handleSupportEmail}>support email</Support> for
          approval.
        </ModalSummaryText>
        <FormSubmitContainer>
          <FormSecondaryButton onClick={props.onHide}>
            Cancel
          </FormSecondaryButton>
          <FormPrimaryButton onClick={props.onHide}>Ok</FormPrimaryButton>
        </FormSubmitContainer>
      </Modal.Body>
    </BaseModal>
  );
}

export default NeedsApprovalModal;
