import React, { useState } from "react";
import { shape } from "prop-types";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import Body from "components/text/Body";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import PostForm from "components/forms/Post";
import SubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import RemovePostButton from "components/posts/RemoveButton";
import { Post } from "types";
import ModalSummaryText from "components/text/ModalSummaryText";

const PostActionsListGroup = styled(ListGroup)`
  > .list-group-item {
    padding-left: 0px;
    background-color: ${(props) => props.theme.purple};
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.gray300};
    transition: padding-left ease ${(props) => props.theme.transitionSuperFast};

    &:hover {
      cursor: pointer;
      padding-left: ${(props) => props.theme.vSpacingXs};
    }
  }
`;

function PostActions({ post, ...props }) {
  /** Provide the user with actions to take on a post. */

  const [modalSection, setModalSection] = useState("actions");

  function renderActionsSection() {
    /** The main section that allows the user to choose which action to perform. */
    return (
      <PostActionsListGroup>
        {post.text !== "" && (
          <ListGroup.Item onClick={() => setModalSection("editPost")}>
            <Body inline>
              <FontAwesomeIcon icon={faPencilAlt} /> Edit Post
            </Body>
          </ListGroup.Item>
        )}
        <ListGroup.Item onClick={() => setModalSection("removePost")}>
          <Body inline>
            <FontAwesomeIcon icon={faTrashAlt} /> Remove Post
          </Body>
        </ListGroup.Item>
        <ListGroup.Item onClick={props.onHide}>
          <Body inline>
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </Body>
        </ListGroup.Item>
      </PostActionsListGroup>
    );
  }

  function renderEditPostSection() {
    return (
      <PostForm
        post={post}
        closeModal={props.onHide}
        backAction={() => setModalSection("actions")}
      />
    );
  }

  function renderDeletePostSection() {
    return (
      <div>
        <ModalSummaryText>
          Deleting the post will remove it from the feed, but will <b>not</b>
          delete the underlying post from class/project page.
        </ModalSummaryText>
        <ModalSummaryText className="mt-3">
          Are you sure you want to remove this post?
        </ModalSummaryText>
        <SubmitContainer withTopDivider>
          <FormSecondaryButton onClick={() => setModalSection("actions")}>
            <FontAwesomeIcon icon={faChevronLeft} /> Back
          </FormSecondaryButton>
          <RemovePostButton post={post} closeModal={props.onHide} />
        </SubmitContainer>
      </div>
    );
  }

  function getModalTitle() {
    switch (modalSection) {
      case "editPost":
        return "Update post";
      case "removePost":
        return "Remove post from global feed";
      default:
        return null;
    }
  }

  function renderSection() {
    /** Choose which section to render based on the component state. */
    switch (modalSection) {
      case "actions":
        return renderActionsSection();
      case "editPost":
        return renderEditPostSection();
      case "removePost":
        return renderDeletePostSection();
      default:
        return renderActionsSection();
    }
  }

  return (
    <BaseModal {...props}>
      <ModalHeader title={getModalTitle()} />
      <Modal.Body className="p-0">{renderSection()}</Modal.Body>
    </BaseModal>
  );
}

PostActions.propTypes = {
  /** The post object that is to be rendered. */
  post: shape(Post).isRequired,
};

export default PostActions;
