import React from "react";
import { shape } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Project } from "types";
import { setActAs } from "features/projects/slice";
import Select from "../Select";

function ProjectActAsSelect({ project }) {
  /** Provide a select component so that a project admin can view the project as a user type. */

  const dispatch = useDispatch();
  const { isPublic, registrationTier } = useSelector(
    (state) => state.projects.actAs
  );

  let options = project.tiers.map((tier) => {
    return { value: tier.id, label: tier.title };
  });
  const publicObject = { value: "public", label: "Public" };
  options.unshift(publicObject);
  const yourselfObject = { value: null, label: "Yourself" };
  options.unshift(yourselfObject);

  function handleChange(obj) {
    // Set the value for selected and clear the other values appropriately.
    if (obj === null) {
      dispatch(
        setActAs({
          isPublic: false,
          registrationTier: null,
        })
      );
    } else if (obj.value === "public") {
      dispatch(
        setActAs({
          isPublic: true,
          registrationTier: null,
        })
      );
    } else {
      dispatch(
        setActAs({
          isPublic: false,
          registrationTier: obj.value,
        })
      );
    }
  }

  function getDefaultValue() {
    // Check if there's a value already selected.
    if (isPublic) {
      return publicObject;
    } else if (registrationTier) {
      const registrationTierObject = project.tiers.find(
        (tier) => tier.id === registrationTier
      );
      return {
        value: registrationTierObject.id,
        label: registrationTierObject.title,
      };
    } else {
      return yourselfObject;
    }
  }

  return (
    <Select
      options={options}
      onChange={handleChange}
      defaultValue={getDefaultValue()}
    />
  );
}

ProjectActAsSelect.propTypes = {
  /** The project that we're rendering the act as dropdown for. */
  project: shape(Project).isRequired,
};

export default ProjectActAsSelect;
