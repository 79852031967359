import React, { useState } from "react";
import { arrayOf, bool, oneOf, string } from "prop-types";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import SubHeader from "components/text/SubHeader";
import { textColors } from "styles/constants";
import { ContentUpload } from "features/sprint/types";
import ProjectCityButton from "components/buttons/Button";
import ContentUploadTile from "features/sprint/components/ContentUploadTile";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingXl};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingLg};
  }
`;

const ContentUploadListHeader = styled(SubHeader)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 38px;
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingSm};
  }
`;

const MobileProjectContainer = styled.div`
  > div {
    // transform: translateX(-80px);
  }
`;

function ProfileContentUploadSection({
  contentUploads,
  header,
  isOwnProfile,
  color,
}) {
  /** Render a section showing the user's content uploads in their profile. */

  // Collapse by default so that users don't have to scroll past.
  const [isExpanded, setIsExpanded] = useState(false);

  if (contentUploads.length === 0) return null;

  const showExpandButton = contentUploads.length > 2 && !isExpanded;

  function renderContentUploadTiles({ withColumn }) {
    /** In the contentUploads section of the profile, the contentUploads should be shown. */

    const uploadData = contentUploads.map((contentUpload, index) => {
      const tile = (
        <ContentUploadTile
          contentUpload={contentUpload}
          allowChooseBucket
          allowChooseInSprint
          titleFooter
        />
      );

      return withColumn ? <Col sm={isOwnProfile ? 6 : 4}>{tile}</Col> : tile;
    });

    return isExpanded ? uploadData : uploadData.slice(0, 3);
  }

  return (
    <Wrapper>
      <ContentUploadListHeader italic size="lg" text={header} color={color} />
      {isMobile ? (
        <MobileProjectContainer>
          {renderContentUploadTiles({ withColumn: false })}
        </MobileProjectContainer>
      ) : (
        <Row>{renderContentUploadTiles({ withColumn: true })}</Row>
      )}
      {showExpandButton && (
        <div className="text-center">
          <ProjectCityButton onClick={() => setIsExpanded(true)}>
            View all uploads
          </ProjectCityButton>
        </div>
      )}
    </Wrapper>
  );
}

ProfileContentUploadSection.propTypes = {
  /** The content uploads that should be rendered */
  contentUploads: arrayOf(ContentUpload).isRequired,

  /** Text to show at the top of the section. */
  header: string.isRequired,

  /** Determine if the user is viewing their own profile. */
  isOwnProfile: bool,

  /** The color of the headers in the section. */
  color: oneOf(textColors),
};

export default ProfileContentUploadSection;
