import React from "react";
import { arrayOf, func, shape, string } from "prop-types";

import FormControl from "components/forms/shared/Control";
import Button from "components/buttons/Button";

function DescriptionSection({ setDescriptions, descriptions }) {
  /** Section to add multiple descriptions to a registration tier. */

  function handleAddMore() {
    /** Create a new description section for the user to add text. */
    setDescriptions([
      ...descriptions,
      { position: descriptions.length, text: "" },
    ]);
  }

  function handleUpdate(position, text) {
    /** Set the new description state given the position and text of the control. */
    let newDescriptions = descriptions.map((description) => {
      return description.position === position
        ? { position, text }
        : description;
    });

    setDescriptions(newDescriptions);
  }

  function handleKeyDown(e) {
    /** Submit the comment if the user hits enter.  */
    if (e.key === "Enter") handleAddMore();
  }

  function renderDescriptions() {
    const currentDescriptions = descriptions.map((description, index) => (
      <FormControl
        className="mt-2"
        value={description.text}
        onChange={(e) => handleUpdate(index, e.target.value)}
        onKeyDown={handleKeyDown}
        autoFocus
      />
    ));

    return (
      <>
        {currentDescriptions}
        <div className="text-center">
          <Button className="mt-2" onClick={handleAddMore}>
            Add more
          </Button>
        </div>
      </>
    );
  }

  return <div>{renderDescriptions()}</div>;
}

DescriptionSection.propTypes = {
  /** Action to set the description state in the parent component. */
  setDescriptions: func.isRequired,

  /** Current value of the descriptions in parent component. */
  descriptions: arrayOf(shape({ text: string })),
};

export default DescriptionSection;
