import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import DrawerBackground from "images/backgrounds/drawer.svg";

const ModalWrapper = styled(Modal)`
  padding-right: 0 !important;

  &.fade {
    .modal-dialog {
      transform: translate(500px, 0) !important;
      transition: transform ${(props) => props.theme.transitionSuperFast} linear;
    }
  }

  &.show {
    .modal-dialog {
      transform: translate(0, 0) !important;
    }
  }

  .modal-dialog {
    height: 100%;
    margin: 0;
    width: 500px;
    float: right;
    background-color: transparent;
  }

  .modal-content {
    border: none;
    height: 100%;
    margin: 0;
    background-color: transparent;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    .modal-dialog {
      width: 90%;
    }
  }
`;

const ModalInner = styled.div`
  background-image: url(${DrawerBackground});
  background-size: cover;
  color: ${(props) => props.theme.dark};
  height: 100%;
  overflow-y: scroll;
`;

function DrawerModal(props) {
  /** Provide a reusable drawer modal that is shown on the right side of the screen. */
  return (
    <ModalWrapper show={true} {...props}>
      <ModalInner>{props.children}</ModalInner>
    </ModalWrapper>
  );
}

export default DrawerModal;
