import { useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import DatePicker from "components/forms/datetime/DatePicker";
import ToggleBox from "components/controls/ToggleBox";
import { UploadScheduleSection } from "features/schedules/types";
import {
  useCreateUploadScheduleSectionMutation,
  useUpdateUploadScheduleSectionMutation,
} from "services/projectCity";
import { handleResponseError } from "utils/forms";

function UploadScheduleSectionForm({
  uploadScheduleSection,
  closeModal,
  ...props
}) {
  /** Form to create or update an Upload Schedule Section. */

  const [formError, setFormError] = useState(null);

  const [
    updateUploadScheduleSection,
    updateResult,
  ] = useUpdateUploadScheduleSectionMutation();
  const [
    createUploadScheduleSection,
    createResult,
  ] = useCreateUploadScheduleSectionMutation();

  const isUpdating = uploadScheduleSection.id !== undefined;

  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: uploadScheduleSection || {},
  });

  const { addToast } = useToasts();

  function onSubmit(data) {
    // Clean the data that should be sent to server.
    const dates = ["startDate", "endDate"];

    dates.forEach((field) => {
      const dateObj = data[field];
      if (moment.isMoment(dateObj)) data[field] = dateObj.format("YYYY-MM-DD");
      else if (data[field] === "") data[field] = null;
    });

    // Add in initial data...
    const payload = { ...uploadScheduleSection, ...data };

    // Either update or create the upload schedule.
    isUpdating
      ? updateUploadScheduleSection({
          uploadScheduleSectionId: uploadScheduleSection.id,
          ...payload,
        })
      : createUploadScheduleSection(payload);
  }

  useEffect(() => {
    // Handle result of modifying the upload schedule.
    const result = isUpdating ? updateResult : createResult;
    const verb = isUpdating ? "updated" : "created";

    if (result.isSuccess) {
      closeModal();
      addToast(`Schedule ${verb}`, { appearance: "success" });
    } else if (result.isError) {
      handleResponseError(result, setFormError, setError);
    }
  }, [updateResult, createResult]);

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Start Date" errors={errors.startDate}>
        <Controller
          as={DatePicker}
          name="startDate"
          control={control}
          isInvalid={errors.startDate !== undefined}
          initialValue={uploadScheduleSection.startDate}
          defaultValue={uploadScheduleSection.startDate}
        />
      </FormGroup>

      <FormGroup label="End Date" errors={errors.endDate}>
        <Controller
          as={DatePicker}
          name="endDate"
          control={control}
          isInvalid={errors.endDate !== undefined}
          initialValue={uploadScheduleSection.endDate}
          defaultValue={uploadScheduleSection.endDate}
        />
      </FormGroup>

      <ToggleBox
        label="Is Public?"
        onLabel="Yes"
        offLabel="No"
        controllerProps={{ name: "isPublic", control, defaultValue: true }}
      />

      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

UploadScheduleSectionForm.propTypes = {
  closeModal: func.isRequired,
  uploadScheduleSection: shape(UploadScheduleSection),
};

UploadScheduleSectionForm.defaultProps = {
  uploadScheduleSection: {},
};

export default UploadScheduleSectionForm;
