import React, { useState } from "react";
import { shape, string } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";

import Button from "components/buttons/Button";
import { autoRegister } from "features/tiers/thunks";
import { enrollmentStatus } from "utils/enums";
import useAuthenticationModal from "hooks/AuthModal";
import { RegistrationTier } from "types";

const StyledButton = styled(Button)`
  background-color: ${(props) =>
    props.variant === "primary" ? props.theme.neonPink : props.theme.yellow};
  color: ${(props) =>
    props.variant === "primary"
      ? props.theme.yellow
      : props.theme.neonPink}!important;

  ${(props) =>
    props.variant === "primary" &&
    `
    border-color: ${props.theme.neonPink};

    &:hover {
      color: ${props.theme.yellow}!important;
      background-color: ${props.theme.neonPink};
    }
  `}
`;

function AutoRegistrationButton({ registrationTier, variant }) {
  // Automatically registers a user for a given registration tier.

  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useSelector((state) => state.account.token !== "");
  const user = useSelector((state) => state.account.user);
  const showAuthModal = useAuthenticationModal();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  async function handleClick() {
    if (!isAuthenticated) return showAuthModal();

    setIsLoading(true);

    try {
      const action = await dispatch(
        autoRegister({ registrationTierId: registrationTier.id })
      );
      if (action.type === "AUTO_REGISTER/fulfilled") {
        addToast("Registered successfully!", { appearance: "success" });
      } else {
        let errorMessage = "Error registering, try again later.";
        if (action.payload && action.payload.data && action.payload.data.detail)
          errorMessage = action.payload.data.detail;
        addToast(errorMessage, { appearance: "error" });
      }
    } catch (err) {
      addToast("Error during registration", { appearance: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  // Check if  the user is currently enrolled in this free tier.
  const activeEnrollments = !user.enrollments
    ? []
    : user.enrollments.filter(
        (enrollment) => enrollment.status === enrollmentStatus.active
      );
  const isEnrolled = activeEnrollments
    .map((enrollment) => {
      return enrollment.registrationTier.id;
    })
    .includes(registrationTier.id);
  const buttonDisabled = isEnrolled || !registrationTier.hasAvailability;

  function getTierButtonText() {
    if (isEnrolled) return "Enrolled";

    if (!registrationTier.hasAvailability) return "Sold out";

    return "Free";
  }

  return (
    <StyledButton
      block
      isLoading={isLoading}
      onClick={handleClick}
      disabled={buttonDisabled}
      variant={variant}
      startTilt
    >
      {getTierButtonText()}
    </StyledButton>
  );
}

AutoRegistrationButton.propTypes = {
  /** The registration tier that the user would be registered for. */
  registrationTier: shape(RegistrationTier).isRequired,

  /** Determine the custom style of the button. */
  variant: string.isRequired,
};

export default AutoRegistrationButton;
