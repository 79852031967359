import React from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";

import theme from "theme";
import { object } from "prop-types";

const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;

  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
`;

function CustomMasonry({ breakpointCols, ...props }) {
  /** Reusable masonry component for our project. */

  const breakpointColumnsObj = {
    default: 3,
    [theme.tabletBreakpoint]: 2,
    [theme.smBreakpoint]: 1,
    ...breakpointCols,
  };

  return (
    <StyledMasonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {props.children}
    </StyledMasonry>
  );
}

CustomMasonry.propTypes = {
  /** Additional props to pass into the `breakpointCols` property. */
  breakpointCols: object,
};

CustomMasonry.defaultProps = {
  breakpointCols: {},
};

export default CustomMasonry;
