import React from "react";
import styled from "styled-components";
import FormControl from "components/forms/shared/Control";

const EmailSignupControl = styled(FormControl)`
  border-color: ${(props) => props.theme[props.$borderColor]};
  color: ${(props) => props.theme.lime};
  border-radius: 0;

  &::placeholder {
    color: ${(props) => props.theme.lime};
  }

  &:focus {
    border-color: ${(props) => props.theme.orange};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: 20px;
  }
`;

function NewsletterRegisterInput({ borderColor, ...props }) {
  /** The input control for registering an email for the newsletter. */
  return <EmailSignupControl $borderColor={borderColor} {...props} />;
}

NewsletterRegisterInput.defaultProps = {
  placeholder: "your email",
  borderColor: "lime",
};

export default NewsletterRegisterInput;
