export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_GOOGLE = "LOGIN_GOOGLE";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const FETCH_ME = "FETCH_ME";
export const UPDATE_ME = "UPDATE_ME";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const DISABLE_GOOGLE_ACCOUNT = "DISABLE_GOOGLE_ACCOUNT";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_EMAIL_CONFIRM = "CHANGE_EMAIL_CONFIRM";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
