import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import NewCommentContainer from "features/comments/components/NewCommentContainer";
import Comments from "features/comments/components/Comments";

const StyledContainer = styled.div`
  overflow-y: scroll;
  max-height: 300px;
  height: 300px;

  & .infinite-scroll-component {
    max-height: 300px;
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

function CommentContainer({ contentObject, contentTypeObj }) {
  /** Provide a full container for all comment functionality. */

  return (
    <>
      <StyledContainer id="comments-container">
        <Comments
          contentObject={contentObject}
          contentTypeObj={contentTypeObj}
        />
      </StyledContainer>
      <NewCommentContainer
        objectId={contentObject.id}
        contentTypeId={contentTypeObj.id}
        inModal={true}
      />
    </>
  );
}

CommentContainer.propTypes = {
  /** The object that the comments belong to. */
  contentObject: PropTypes.object.isRequired,

  /** The content type object for the content object. */
  contentTypeObj: PropTypes.object.isRequired,
};

export default CommentContainer;
