import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import LoadingContainer from "components/loading/Container";
import ClassProjectList from "features/projects/components/ClassList";
import { fetchProjects } from "features/projects/thunks";
import { projectType } from "utils/enums";
import Body from "components/text/Body";
import ProjectCityButton from "components/buttons/Button";
import Caption from "components/text/Caption";
import useIsAuthenticated from "hooks/IsAuthenticated";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: ${(props) => props.theme.vSpacingLg};
    padding-right: ${(props) => props.theme.vSpacingLg};
  }
`;

function PurchasedClassesRoute() {
  /** Show the user a list of classes that they have purchased. */

  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { purchased } = useSelector((state) => state.projects.entities);

  async function fetchClasses() {
    await dispatch(
      fetchProjects({
        fresh: true,
        params: {
          isPurchased: true,
        },
      })
    );

    setIsLoading(false);
  }

  useEffect(() => {
    fetchClasses();
  }, []);

  if (isLoading)
    return <LoadingContainer text="Loading purchased classes..." />;

  if (!isAuthenticated) {
    return (
      <div className="text-center mt-3">
        <Body>Please login and/or create an account to purchase classes.</Body>
      </div>
    );
  }

  if (!purchased || purchased.length === 0) {
    return (
      <div className="text-center mt-3">
        <Body color="lime">You have not yet purchased any classes yet!</Body>
        <div className="m-4">
          <ProjectCityButton noTilt>Browse live classes</ProjectCityButton>
          <Caption inline color="pink" className="m-3">
            OR
          </Caption>
          <ProjectCityButton noTilt>Browse recorded classes</ProjectCityButton>
        </div>
      </div>
    );
  }

  return (
    <Wrapper>
      <ClassProjectList projects={purchased || []} />
    </Wrapper>
  );
}

export default PurchasedClassesRoute;
