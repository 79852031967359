import React from "react";
import { arrayOf, shape } from "prop-types";

import { Enrollment } from "features/enrollments/types";
import ProjectAdminTable from "../ProjectAdmin";
import TableColumnHeader from "../ColumnHeader";
import EnrollmentRow from "./Row";

function EnrollmentsTable({ enrollments }) {
  /** Table to show the enrollments for a project. */

  function renderEnrollmentRows() {
    return enrollments.map((enrollment) => (
      <EnrollmentRow key={enrollment.id} enrollment={enrollment} />
    ));
  }

  return (
    <ProjectAdminTable withHeader>
      <thead>
        <tr>
          <TableColumnHeader>Name</TableColumnHeader>
          <TableColumnHeader className="d-none d-md-block">
            Tier
          </TableColumnHeader>
          <TableColumnHeader>Actions</TableColumnHeader>
        </tr>
      </thead>
      <tbody>{renderEnrollmentRows()}</tbody>
    </ProjectAdminTable>
  );
}

EnrollmentsTable.propTypes = {
  /** Enrollments to be shown in the table. */
  enrollments: arrayOf(shape(Enrollment)).isRequired,
};

export default EnrollmentsTable;
