import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import Body from "components/text/Body";
import EnrollmentActionButton from "components/tables/EnrollmentActionButton";
import { Enrollment } from "features/enrollments/types";
import theme from "theme";
import { getUserImage } from "utils/users";

const ProfileImage = styled(Image)`
  width: 35px;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    display: none;
  }
`;

const NameDisplay = styled.span`
  font-weight: bold;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 18px;
  }
`;

const NameCell = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    max-width: 200px;
  }
`;

function EnrollmentRow({ enrollment, isSelected, toggleSelected }) {
  /** Render a row of data for the enrollment table. */

  return (
    <tr key={enrollment.id} className={isSelected ? "is-selected" : ""}>
      <NameCell>
        <ProfileImage
          roundedCircle
          className="mr-1"
          src={getUserImage(enrollment.student)}
          alt=""
        />
        <NameDisplay>{enrollment.student.name}</NameDisplay>{" "}
        <Body inline color={theme.yellow} mono>
          @{enrollment.student.username}
        </Body>
      </NameCell>
      <td className="d-none d-md-block mt-1">
        {enrollment.registrationTier.title}
      </td>
      <td>
        <EnrollmentActionButton enrollment={enrollment} />
      </td>
    </tr>
  );
}

EnrollmentRow.propTypes = {
  /** The enrollment object that we're rendering. */
  enrollment: shape(Enrollment).isRequired,
};

export default EnrollmentRow;
