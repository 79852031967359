import { string } from "prop-types";

import VideoMask from "images/masks/video.svg";
import MaskImage from "../Mask";

function VideoMaskImage(props) {
  /** Reusable mask for video aspect images */
  return <MaskImage mask={VideoMask} {...props} />;
}

VideoMaskImage.propTypes = {
  /** Source of the image that should be masked. */
  src: string.isRequired,
};

export default VideoMaskImage;
