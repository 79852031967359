import Slider from "react-slick";

import SliderArrow from "components/carousels/SliderArrow";

function SlickSlider({ children, ...props }) {
  /** Netflix-like scroller to view projects in a side scroller. */

  const defaultSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SliderArrow direction="right" />,
    prevArrow: <SliderArrow direction="left" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 592,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: false,
        },
      },
    ],
  };

  return (
    <Slider {...defaultSettings} {...props}>
      {children}
    </Slider>
  );
}

export default SlickSlider;
