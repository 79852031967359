import styled from "styled-components";
import Image from "react-bootstrap/Image";
import { bool, func, number } from "prop-types";
import { useSelector } from "react-redux";

import Thumbnail from "components/text/Thumbnail";
import useAuthenticationModal from "hooks/AuthModal";
import HotFire from "images/hot_fire.svg";
import FireCircle from "images/buttons/fire.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;
`;

const StyledFirePostWrapper = styled.div`
  width: 30px;
  color: ${(props) => props.theme.neonPink};
  margin-right: 10px;
  transition: transform 0.1s ease;

  ${(props) =>
    props.$actionable &&
    `
    cursor: pointer;

    &:hover {
      transform: scale(1.3) rotate(2deg);
    }
  `}

  > img {
    width: 100%;
    height: auto;
  }
`;

function LikeContainer({ actionable, onClick, count, likeActive }) {
  const showAuthModal = useAuthenticationModal();
  const isAuthenticated = useSelector((state) => state.account.token !== "");

  function handleClick(e) {
    if (actionable) {
      onClick();
    } else {
      e.stopPropagation();
    }
  }

  return (
    <Wrapper>
      <StyledFirePostWrapper
        onClick={isAuthenticated ? handleClick : showAuthModal}
        $actionable={actionable}
      >
        <Image fluid src={likeActive ? HotFire : FireCircle} alt="" />
      </StyledFirePostWrapper>
      <Thumbnail color="neonPink" inline>
        {count}
      </Thumbnail>
    </Wrapper>
  );
}

LikeContainer.propTypes = {
  /** Amount of times the object has been liked. */
  count: number.isRequired,

  /** Action to take when clicking ont he icon. */
  onClick: func,

  /** Determine if the object is currently liked. */
  likeActive: bool,

  /** Determine if the like button should trigger some action. */
  actionable: bool,
};

LikeContainer.defaultProps = {
  likeActive: false,
  onClick: () => {},
  actionable: true,
};

export default LikeContainer;
