import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import DashboardContainer from "components/containers/Dashboard";
import DashboardLoadingContainer from "components/loading/DashboardContainer";
import ProfileProjectSection from "features/profile/components/ProjectSection";
import ProfileHeader from "features/profile/components/Header";
import ProfileSettingsSection from "features/profile/components/Settings";
import UserService from "features/users/service";
import Sticker from "components/icons/Sticker";
import { logoutUser } from "features/accounts/slice";
import ContentUploadSection from "features/profile/components/ContentUploadSection";

const SettingsCol = styled(Col)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingMd};
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }
`;

const ProfileProjectsWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 5%;
  }
`;

const ContentUploadsSection = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-right: 25%;
  }
`;

const TeachingSection = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-left: 12.6%;
  }
`;

const ProjectsSection = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    position: relative;
    margin-left: 12.6%;
  }
`;

const ClassesSection = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    position: relative;
    margin-right: 12.6%;
  }
`;

const BulbSticker = styled(Sticker)`
  position: absolute;
  top: -100px;
  left: -400px;
`;

const TurtleSticker = styled(Sticker)`
  position: absolute;
  top: -100px;
  right: -550px;
`;

function UserProfile({ username }) {
  /** Display a user's profile and allows them to edit it, if they are the user.  */

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const user = useSelector((state) => state.account.user);
  const isOwnProfile = username === user.username;

  const dispatch = useDispatch();

  // Any time the user is updated, we will need to refresh the user data. This is needed
  // because the data in profile and auth user are different, so we need to refresh
  // manually instead of relying on the updated redux data.
  useEffect(() => {
    async function fetchUser() {
      const userService = new UserService();
      try {
        setIsLoading(true);
        setErrorMessage(null);
        const response = await userService.fetch(username);
        setUserData(response.data);
        setIsLoading(false);
      } catch (err) {
        setErrorMessage(`Error finding user with username "${username}"`);
        dispatch(logoutUser());
        setIsLoading(false);
      }
    }

    fetchUser();
  }, [username, user]);

  if (errorMessage)
    return (
      <Alert className="m-5" variant="danger">
        {errorMessage}
      </Alert>
    );

  if (isLoading || !userData || !user)
    return <DashboardLoadingContainer text="Loading user profile" />;

  return (
    <DashboardContainer fluid className="px-3">
      <Row>
        <Col xl={2} lg={3}></Col>
        <Col xl={7} lg={6} className="px-0">
          <ProfileHeader user={userData} />
        </Col>
        <SettingsCol
          xs={{ offset: 2, span: 10 }}
          xl={{ offset: 1, span: 2 }}
          lg={{ offset: 1, span: 2 }}
        >
          {isOwnProfile && <ProfileSettingsSection user={userData} />}
        </SettingsCol>
      </Row>

      <ProfileProjectsWrapper>
        {userData.contentUploads.length > 0 && (
          <ContentUploadsSection>
            <ContentUploadSection
              header="uploads"
              contentUploads={userData.contentUploads}
              isOwnProfile={isOwnProfile}
              color="purple"
            />
          </ContentUploadsSection>
        )}
        {userData.teachesProjects.length > 0 && (
          <TeachingSection>
            <ProfileProjectSection
              header="teaching"
              color="pink"
              projects={userData.teachesProjects}
              isOwnProfile={isOwnProfile}
            />
          </TeachingSection>
        )}
        {userData.registeredProjects.length > 0 && (
          <ClassesSection>
            <ProfileProjectSection
              header="member of"
              color="green"
              projects={userData.registeredProjects}
              isOwnProfile={isOwnProfile}
            />
            <TurtleSticker type="turtle" />
          </ClassesSection>
        )}
        {userData.adminProjects.length > 0 && (
          <ProjectsSection>
            <ProfileProjectSection
              header="projects"
              color="orange"
              projects={userData.adminProjects}
              isOwnProfile={isOwnProfile}
            />
            <BulbSticker type="bulb" />
          </ProjectsSection>
        )}
      </ProfileProjectsWrapper>
    </DashboardContainer>
  );
}

export default UserProfile;
