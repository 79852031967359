import { useState } from "react";
import { func, shape } from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import BaseModal from "components/modals/Base";
import ModalSummaryText from "components/text/ModalSummaryText";
import ModalHeader from "components/modals/Header";
import FormControl from "components/forms/shared/Control";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import Body from "components/text/Body";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormLabel from "components/forms/shared/FormLabel";
import InfoIcon from "images/icons/info.svg";
import { ContentUpload } from "features/sprint/types";
import {
  useDonateContentUploadMutation,
  useCreateContentUploadTokenPurchaseMutation,
} from "services/projectCity";
import { updateUser } from "features/accounts/slice";
import { toCurrency } from "utils/general";

function ContentUploadDonationModal({
  contentUpload,
  setPurchasedTokens,
  ...props
}) {
  const [donateAmount, setDonateAmount] = useState(5);
  const [errorText, setErrorText] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [donate] = useDonateContentUploadMutation();
  const [createCheckoutSession] = useCreateContentUploadTokenPurchaseMutation();

  const availableTokens = user.tokenCount;
  const missingTokens =
    donateAmount - availableTokens > 0 ? donateAmount - availableTokens : 0;

  function handleOnChange(e) {
    setDonateAmount(parseInt(e.target.value));
  }

  function handleError(error) {
    if (error?.data?.detail) setErrorText(error.data.detail);
    else setErrorText("There was an error submitting your donation.");
  }

  async function handleSubmit() {
    /**
     * Submit the user's donation.
     *
     * If the user has the requested tokens, we just credit their token amount, however if they
     * want to donate more then we need to charge them.
     */
    setErrorText(null);
    setIsSubmitting(true);

    if (missingTokens > 0) {
      // The user needs to first purchase the difference in tokens, since they're donating more than
      // they have.
      const difference = donateAmount - availableTokens;
      const checkoutResponse = await createCheckoutSession({
        contentUploadId: contentUpload.id,
        amount: difference,
      });

      if (checkoutResponse.error) handleError(checkoutResponse.error);
      else window.location.href = checkoutResponse.data.url;
    } else {
      const response = await donate({
        contentUploadId: contentUpload.id,
        amount: donateAmount,
      });

      if (response.error) {
        handleError(response.error);
      } else {
        const newAvailableTokens = availableTokens - donateAmount;
        dispatch(
          updateUser({
            tokenCount: newAvailableTokens,
            donatedTokenCount: user.donatedTokenCount + donateAmount,
          })
        );
        setPurchasedTokens(donateAmount);
        props.onHide();
      }
    }

    setIsSubmitting(false);
  }

  const modalTitle =
    contentUpload.series !== null && contentUpload.series.length > 1
      ? "Support this animatic series"
      : "Support this animatic";

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader title={modalTitle} closeButton closeModal={props.onHide} />
      <ModalSummaryText>
        You currently have {availableTokens} tokens available, however you may
        donate more with additional payment.
      </ModalSummaryText>
      <div className="p-3">
        <FormLabel>Donate token amount</FormLabel>
        <FormControl
          onChange={handleOnChange}
          autoFocus
          onKeyPress={(event) => {
            if (event.key === "Enter") handleSubmit();
          }}
          value={donateAmount}
          type="number"
          min="1"
        />
        {missingTokens > 0 && (
          <Body className="text-center mt-2">
            <img src={InfoIcon} /> You will be taken to purchase {missingTokens}{" "}
            additional tokens for {toCurrency(missingTokens / 10)}. Any
            additional tokens purchased will be immediately given to this
            animatic.
          </Body>
        )}
      </div>
      <FormSubmitContainer withTopDivider errorText={errorText}>
        <FormSecondaryButton onClick={props.onHide}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting} onClick={handleSubmit}>
          Submit
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseModal>
  );
}

ContentUploadDonationModal.propTypes = {
  contentUpload: shape(ContentUpload).isRequired,
  setPurchasedTokens: func.isRequired,
};

export default ContentUploadDonationModal;
