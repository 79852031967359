import React from "react";
import { shape } from "prop-types";

import InfoTooltip from "components/tooltips/Info";
import BucketForm from "features/buckets/forms/Bucket";
import FormModal from "components/modals/Form";
import { Project } from "types";

function CreateBucketModal({ project, hideModal }) {
  /** Modal to specifically create a new bucket in a project. */

  return (
    <FormModal
      title={
        <span>
          Create Page
          <InfoTooltip
            iconProps={{ className: "ml-1" }}
            text="Pages are the tabs in your project that group content together."
          />
        </span>
      }
      onHide={hideModal}
    >
      <BucketForm
        bucket={{
          project: project.id,
          registrationTiers: [],
          isPublic: true,
        }}
        closeModal={hideModal}
        showAllFields
        navigateAfterCreate
      />
    </FormModal>
  );
}

CreateBucketModal.propTypes = {
  /** The project that the bucket will be created in. */
  project: shape(Project).isRequired,
};

export default CreateBucketModal;
