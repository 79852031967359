import React from "react";
import { bool, number, shape } from "prop-types";
import { useModal } from "react-modal-hook";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import FormModal from "components/modals/Form";
import ActionContainer from "components/containers/Action";
import ProjectSectionForm from "features/projects/forms/ContentSection";
import ProjectSectionImage from "images/content_section.svg";
import { Project } from "types";
import ProjectSection from "./Section";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    section:first-of-type {
      margin-left: 7%;
    }

    section:nth-of-type(even) {
      margin-left: 0%;
    }

    section:nth-of-type(3) {
      margin-left: 14%;
    }

    section:nth-of-type(5) {
      margin-left: 10%;
    }
  }
`;

const StyledActionContainer = styled(ActionContainer)`
  justify-content: space-around;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    margin: 0 !important;

    > img {
      margin: 20px;
    }
  }
`;

function ProjectSectionsContainer({
  project,
  maxSections,
  shouldViewAdminItems,
}) {
  /** Render sections of a project that display content and images. */

  const [showCreateSectionModal, hideCreateSectionModal] = useModal(() => {
    return (
      <FormModal onHide={hideCreateSectionModal}>
        <ProjectSectionForm
          project={project}
          closeModal={hideCreateSectionModal}
        />
      </FormModal>
    );
  }, [project]);

  return (
    <Wrapper>
      {project.sections.map((section, index) => (
        <ProjectSection
          key={section.id}
          contentFirst={index % 2 === 0}
          staggered={index % 2 !== 0}
          staggeredEnd={index % 2 === 0}
          section={section}
          shouldViewAdminItems={shouldViewAdminItems}
          className="project-section"
        />
      ))}
      {shouldViewAdminItems && project.sections.length < maxSections && (
        <StyledActionContainer
          buttonText="Create new section"
          className="py-5 mx-5"
          onClick={showCreateSectionModal}
        >
          <Image fluid src={ProjectSectionImage} alt="Project City Section" />
        </StyledActionContainer>
      )}
    </Wrapper>
  );
}

ProjectSectionsContainer.propTypes = {
  /** The project whose sections we're rendering. */
  project: shape(Project).isRequired,

  /** Determine if the user is an admin of the project. */
  shouldViewAdminItems: bool.isRequired,

  /** Set the maximum amount of sections that can be added. */
  maxSections: number,
};

ProjectSectionsContainer.defaultProps = {
  maxSections: 5,
};

export default ProjectSectionsContainer;
