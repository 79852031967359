import { number } from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: ${(props) => props.width}%;
  color: ${(props) => props.theme.yellow};
  margin: ${(props) => props.theme.vSpacingMd};
  position: fixed;
  bottom: 50px;
  z-index: 300;
  justify-content: ${(props) =>
    props.$hasPrepend ? "space-between" : "flex-end"};
`;

function MobileNavigationContainer(props) {
  /** Container used to wrap a secondary mobile navigation dropdown and file uploader. */
  return <Wrapper {...props}>{props.children}</Wrapper>;
}

MobileNavigationContainer.propTypes = {
  width: number,
};

MobileNavigationContainer.defaultProps = {
  width: 95,
};

export default MobileNavigationContainer;
