import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { shape } from "prop-types";

import BaseModal from "components/modals/Base";
import StepContainer from "components/steps/components/StepsContainer";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import ModalSummaryText from "components/text/ModalSummaryText";
import RegistrationTierForm from "features/tiers/forms/RegistrationTier";
import { createDescriptions } from "features/tiers/thunks";
import ErrorText from "components/forms/shared/ErrorText";
import ModalHeader from "components/modals/Header";
import DescriptionSection from "features/tierDescriptions/components/MultipleSection";
import PaymentSplitSection from "features/revenueSplits/components/SetupTierSection";
import { Project } from "types";

const StepSummary = styled(ModalSummaryText)`
  width: 50%;
  margin-left: 25%;
  margin-bottom: ${(props) => props.theme.vSpacingMd};
`;

function RegistrationTierSetupModal({ project, ...props }) {
  /** Multi-step modal for setting up a registration tier and its associated data. */

  const [createdTierId, setCreatedTierId] = useState(null);
  const [showFormSubmitContainer, setShowFormSubmitContainer] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [isCreatingDescriptions, setIsCreatingDescriptions] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [descriptionState, setDescriptionState] = useState([
    { position: 0, text: "" },
  ]);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const hasMultipleTeachers = project.teachers.length > 1;
  let steps = [
    {
      content: 1,
      label: "Create tier",
    },
    {
      content: 2,
      label: "Descriptions",
    },
  ];

  // Only add payment split section if there are multiple teachers.
  if (hasMultipleTeachers) {
    steps.push({
      content: 3,
      label: "Payment splits",
    });
  }

  function getModalHeader() {
    switch (activeStep) {
      case 1:
        return "Create new registration tier";
      case 2:
        return "Set tier descriptions";
      case 3:
        return "Set payment splits";
      default:
        return "Create new registration tier";
    }
  }

  function sendCompleteNotification() {
    props.onHide();
    addToast("Registration tier created!", { appearance: "success" });
  }

  function handleIncrementStep() {
    setErrorText(null);
    const newStep = activeStep + 1;
    const isFinish = newStep > steps.length;
    return isFinish
      ? sendCompleteNotification()
      : setActiveStep(activeStep + 1);
  }

  async function handleCreateDescriptions() {
    setErrorText(null);
    setIsCreatingDescriptions(true);
    const action = await dispatch(
      createDescriptions({
        registrationTierId: createdTierId,
        descriptions: descriptionState.filter((d) => d.text !== ""),
      })
    );
    setIsCreatingDescriptions(false);

    if (action.type.includes("fulfilled")) {
      // Only go to the tier split section if there are multiple teachers.
      hasMultipleTeachers ? setActiveStep(3) : sendCompleteNotification();
    } else {
      setErrorText("There was an error creating descriptions");
    }
  }

  function handleFinishSplits() {
    sendCompleteNotification();
  }

  function renderErrorText() {
    if (!errorText) return null;

    return <ErrorText text={errorText} className="mt-2" />;
  }

  function renderContent() {
    /**
     * Main function that will render the content of the modal depending on which
     * section is active.
     */

    if (activeStep === 1) {
      // General registration tier data.
      return (
        <RegistrationTierForm
          tierCreatedAction={handleIncrementStep}
          setCreatedTierId={setCreatedTierId}
          closeModal={props.onHide}
          saveText="Continue"
        />
      );
    } else if (activeStep === 2) {
      // Tier descriptions section.
      return (
        <div>
          <StepSummary>
            Descriptions are shown with the pay button so that users have a
            better idea of what it offers.
          </StepSummary>
          <DescriptionSection
            descriptions={descriptionState}
            setDescriptions={setDescriptionState}
          />
          {renderErrorText()}
          <FormSubmitContainer>
            <FormSecondaryButton variant="link" onClick={handleIncrementStep}>
              Skip for now
            </FormSecondaryButton>
            <FormPrimaryButton
              onClick={handleCreateDescriptions}
              disabled={isCreatingDescriptions}
            >
              {hasMultipleTeachers ? "Continue" : "Finish"}
            </FormPrimaryButton>
          </FormSubmitContainer>
        </div>
      );
    } else if (activeStep === 3) {
      // Payment split section.
      return (
        <div>
          <StepSummary>
            Choose what percent of the profits should go to whom.
          </StepSummary>
          <PaymentSplitSection
            registrationTierId={createdTierId}
            teachers={project.teachers}
            setShowFormSubmitContainer={setShowFormSubmitContainer}
          />
          {renderErrorText()}
          {showFormSubmitContainer && (
            <FormSubmitContainer>
              <FormPrimaryButton onClick={handleFinishSplits}>
                Finish
              </FormPrimaryButton>
            </FormSubmitContainer>
          )}
        </div>
      );
    } else return null;
  }

  return (
    <BaseModal {...props} size="xl">
      <div className="text-center">
        <ModalHeader title={getModalHeader()} />
      </div>
      <StepContainer steps={steps} activeStep={activeStep} />
      {renderContent()}
    </BaseModal>
  );
}

RegistrationTierSetupModal.propTypes = {
  /** The project that hte registration tier belongs to. */
  project: shape(Project).isRequired,
};

export default RegistrationTierSetupModal;
