import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";

import BucketSlot from "features/tiers/components/BucketSlot";
import ButtonContainer from "components/forms/shared/ButtonContainer";
import BucketForm from "features/buckets/forms/Bucket";
import { updateBucket } from "features/buckets/thunks";
import ModalHeader from "../../../../components/modals/Header";
import ModalSubheader from "../../../../components/modals/Subheader";
import BaseModal from "../../../../components/modals/Base";
import Button from "components/buttons/Button";

const StyledModalHeader = styled.div`
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.06em;
  color: ${(props) => props.theme.yellow};
  transform: matrix(1, 0.03, -0.06, 1, 0, 0);
  text-align: start !important;
  padding: 1.5rem 1.5rem 0px 1.5rem;
  margin-top: 1rem;
`;

function PublicBucketsModal({ project, ...props }) {
  /** Allow the user to select which buckets should be public for a project. */

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  // The bucket id that is updating.
  const [bucketIdUpdating, setBucketIdUpdating] = useState(null);
  const [activeSection, setActiveSection] = useState("chooseBuckets");

  async function handleBucketClick(bucket, isPublic) {
    /* Toggle if the bucket is public for the given project.

       :param bucket object: The bucket that is being toggled.
       :param isPublic bool: If the bucket is currently selected.
    */
    setBucketIdUpdating(bucket.id);

    let actionPayload = {
      bucketId: bucket.id,
      payload: { isPublic: !isPublic },
    };

    // If we're setting a bucket to public, it should be removed from the previous registration
    // tiers.
    if (!isPublic) actionPayload.payload.registrationTiers = [];

    const action = await dispatch(updateBucket(actionPayload));
    if (action.type === "UPDATE_BUCKET/rejected")
      addToast("Error updating bucket", { appearance: "error" });

    setBucketIdUpdating(null);
  }

  function renderBuckets() {
    // Renders all the buckets for the project so that hte user can select which to show.
    const publicBuckets = project.buckets
      .filter((bucket) => bucket.isPublic)
      .map((bucket) => bucket.id);

    return project.buckets.map((bucket) => {
      const isPublic = publicBuckets.includes(bucket.id);
      const isLoading = bucketIdUpdating === bucket.id;

      return (
        <BucketSlot
          key={bucket.id}
          bucket={bucket}
          selected={isPublic}
          isLoading={isLoading}
          onClick={() => handleBucketClick(bucket, isPublic)}
        />
      );
    });
  }

  function renderContent() {
    if (activeSection === "chooseBuckets") {
      return (
        <>
          <StyledModalHeader>Choose Public Pages</StyledModalHeader>
          <ModalSubheader>
            Public pages are viewable by anyone in the project
          </ModalSubheader>
          <Modal.Body>
            {renderBuckets()}
            <ButtonContainer className="mt-3 justify-content-between">
              <Button onClick={() => setActiveSection("createBucket")}>
                ADD NEW PAGE
              </Button>
              <Button onClick={props.onHide}>SAVE</Button>
            </ButtonContainer>
          </Modal.Body>
        </>
      );
    } else if (activeSection === "createBucket") {
      return (
        <>
          <ModalHeader title="Create Public Page" />
          <ModalSubheader>
            New bucket will be visible to all users in the project
          </ModalSubheader>
          <Modal.Body>
            <BucketForm
              bucket={{ registrationTiers: [], isPublic: true }}
              backAction={() => setActiveSection("chooseBuckets")}
              closeModal={props.onHide}
            />
          </Modal.Body>
        </>
      );
    }
  }

  return <BaseModal {...props}>{renderContent()}</BaseModal>;
}

PublicBucketsModal.propTypes = {
  // The project for which we're choosing public buckets.
  project: PropTypes.object.isRequired,
};

export default PublicBucketsModal;
