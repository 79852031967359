import { useState } from "react";
import { bool, shape } from "prop-types";
import Moment from "react-moment";
import styled from "styled-components";
import { useModal } from "react-modal-hook";

import TileFooter from "components/tiles/Footer";
import Sticker from "components/icons/Sticker";
import { ContentUpload } from "features/sprint/types";
import LikeContainer from "features/likes/components/Container";
import ActionOverlayFull from "components/containers/ActionOverlayFull";
import ContentUploadModal from "features/sprint/modals/ContentUploadModal";
import ConfirmDeleteContentUploadModal from "features/sprint/modals/ConfirmDelete";

import ContentUploadTileWrapper from "../ContentUploadTileWrapper";
import ContentUploadImage from "../ContentUploadImage";
import DonationContainer from "../DonationContainer";
import useCanModifyContentUpload from "features/sprint/hooks/CanModifyContentUpload";

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledMoment = styled(Moment)`
  color: ${(props) => props.theme.yellow};
`;

const DraftOverlay = styled(Sticker)`
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 40%;
`;

function ContentUploadTile({
  contentUpload,
  allowChooseBucket,
  allowChooseInSprint,
  titleFooter,
  ...props
}) {
  /** Return a tile for an uploaded content item for subscription users. */

  const [hasHover, setHasHover] = useState(false);

  const [showContentUploadModal, hideContentUploadModal] = useModal(
    () => (
      <ContentUploadModal
        contentUpload={contentUpload}
        onHide={hideContentUploadModal}
        chooseBucket={allowChooseBucket}
        chooseInSprint={allowChooseInSprint}
      />
    ),
    [contentUpload]
  );

  const [
    showContentUploadDeleteModal,
    hideContentUploadDeleteModal,
  ] = useModal(
    () => (
      <ConfirmDeleteContentUploadModal
        contentUpload={contentUpload}
        onHide={hideContentUploadDeleteModal}
      />
    ),
    [contentUpload]
  );

  function handleEditClick(e) {
    e.stopPropagation();
    showContentUploadModal();
  }

  function handleDeleteClick(e) {
    e.stopPropagation();
    showContentUploadDeleteModal();
  }

  const canModify = useCanModifyContentUpload(contentUpload);

  return (
    <ContentUploadTileWrapper
      contentUpload={contentUpload}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
      {...props}
    >
      <ContentUploadImage contentUpload={contentUpload} />
      {canModify && (
        <ActionOverlayFull
          hasHover={hasHover}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      )}
      {contentUpload.isVisible === false && <DraftOverlay type="private" />}
      {titleFooter ? (
        <TileFooter top={contentUpload.title} />
      ) : (
        <>
          <ActionBar>
            <LikeContainer
              likeActive={false}
              count={contentUpload.likesCount}
              onClick={() => {}}
            />
            {contentUpload.canReceiveDonation && (
              <DonationContainer contentUpload={contentUpload} />
            )}
            <StyledMoment fromNow>{contentUpload.created}</StyledMoment>
          </ActionBar>
          <TileFooter
            top={contentUpload.title}
            bottom={`by ${contentUpload.createdBy.fullName}`}
          />
        </>
      )}
    </ContentUploadTileWrapper>
  );
}

ContentUploadTile.propTypes = {
  content: shape(ContentUpload),
  allowChooseBucket: bool,
  allowChooseInSprint: bool,

  /** Show only the title in the tile footer. */
  titleFooter: bool,
};

ContentUploadTile.defaultProps = {
  allowChooseBucket: false,
  allowChooseInSprint: false,
  titleFooter: false,
};

export default ContentUploadTile;
