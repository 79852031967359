import styled from "styled-components";
import slugify from "slugify";
import { navigate } from "@reach/router";

import LoadingContainer from "components/loading/Container";
import Caption from "components/text/Caption";
import Body from "components/text/Body";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import Video from "components/videos/Video";
import ProjectCityButton from "components/buttons/Button";
import AssignmentEntryList from "features/assignments/components/EntryList";
import AssignmentEntryUploadIcon from "features/assignments/components/UploadEntryIcon";
import SprintVideo from "features/sprint/components/Video";
import projectCityApi, {
  useGetAssignmentEntryListQuery,
  useGetAssignmentQuery,
  useGetContentUploadDetailQuery,
} from "services/projectCity";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingXl};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingMd};
  }
`;

const HeaderSection = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.vSpacingSm};
  position: relative;

  > * {
    place-self: center;
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingMd};
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
`;

function AssignmentDetail(props) {
  /** Show detail about an assignment, including its uploads. */

  const assignmentId = parseInt(props.id);

  const { data: assignment, isLoading, isError } = useGetAssignmentQuery({
    assignmentId,
  });
  const {
    data: assignmentEntries,
    isLoading: assignmentEntriesLoading,
  } = useGetAssignmentEntryListQuery({ assignment__id: assignmentId });

  const [
    loadNextPage,
  ] = projectCityApi.endpoints.getNextAssignmentEntriesPage.useLazyQuery();

  // If we are using the content upload video, then we'll need to load it.
  const {
    data: contentUpload,
    isLoading: isContentUploadLoading,
  } = useGetContentUploadDetailQuery(
    {
      contentUploadId: assignment?.contentUpload,
    },
    {
      skip: assignment?.useContentUploadVideo !== true,
    }
  );

  // Get extra data about related content upload, if the assignment has one.
  const {
    data: linkedContentUpload,
    isLoading: isLinkedContentUploadLoading,
  } = useGetContentUploadDetailQuery(
    { contentUploadId: assignment?.contentUpload },
    { skip: assignment?.contentUpload === undefined }
  );

  if (isLoading || isContentUploadLoading || isLinkedContentUploadLoading)
    return <LoadingContainer text="Loading assignment" />;

  if (isError)
    return (
      <Wrapper>
        <Caption color="orange" className="text-center">
          There was an error loading the assignment.
        </Caption>
      </Wrapper>
    );

  function renderVideo() {
    /** Either show the content upload video or the associated content upload video. */

    return assignment.useContentUploadVideo ? (
      <SprintVideo
        src={contentUpload?.file?.url}
        contentUploadId={contentUpload.id}
      />
    ) : (
      assignment.video && <Video src={assignment.video} />
    );
  }

  return (
    <Wrapper>
      <HeaderSection>
        <div>
          <SubHeaderText color="purple">{assignment.title}</SubHeaderText>
          {assignment.description && (
            <Body className="mt-2">{assignment.description}</Body>
          )}
          {linkedContentUpload && (
            <ProjectCityButton
              className="mt-3"
              variant="info"
              noTilt
              onClick={() =>
                navigate(
                  `/sprint/content/${linkedContentUpload.id}/${slugify(
                    linkedContentUpload.title
                  )}`
                )
              }
            >
              {linkedContentUpload.title}
            </ProjectCityButton>
          )}
        </div>
        {renderVideo()}
        <AssignmentEntryUploadIcon assignment={assignment} />
      </HeaderSection>
      <div>
        {assignmentEntriesLoading ? (
          <LoadingContainer text="Loading assignment entries..." />
        ) : (
          <AssignmentEntryList
            assignmentEntries={assignmentEntries.results}
            dataLength={assignmentEntries.results.length}
            next={() =>
              loadNextPage({ next: assignmentEntries.next, assignmentId })
            }
            hasMore={assignmentEntries.next !== null}
            loader={<LoadingContainer text="Loading assignment entries..." />}
            className="my-5"
          />
        )}
      </div>
    </Wrapper>
  );
}

export default AssignmentDetail;
