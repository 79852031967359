import React, { useState } from "react";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import BorderContainer from "components/containers/Border";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import Caption from "components/text/Caption";
import MailAlt from "images/buttons/mail-alt.svg";
import theme from "theme";
import NewsletterInput from "../RegisterInput";
import NewsletterRegisterButton from "../RegisterButton";

const Wrapper = styled(BorderContainer)`
  display: flex;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingLg} 0;
    padding: 40px;

    // The mail image has space at the bottom which creates uneven padding.
    padding-bottom: 20px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    padding: 20px;
  }
`;

const Inner = styled.div`
  flex: 1 1 50%;
`;

const StyledSubHeader = styled(SubHeaderText)`
  letter-spacing: 0.06em;
  transform: rotate(-3deg);
`;

const StyledCaption = styled(Caption)`
  color: #c4c4c4;
  font-weight: 200;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-bottom: ${(props) => props.theme.vSpacingSm};
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(2deg);

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-left: 80px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: 20px;

    > img {
      width: 50px;
      height: 50px;
    }
  }
`;

const InputSection = styled(Inner)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: 20px;
  }
`;

const DescriptionSection = styled(Inner)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-right: 20px;
  }
`;

function ClassesNewsletterRegistration() {
  /** Component for a user to register for the newsletter on the classes route. */

  const [email, setEmail] = useState("");

  return (
    <Wrapper borderType="solid" borderColor={theme.purple} borderWidth={8}>
      <DescriptionSection>
        <StyledSubHeader color="lime">Join our mailing list!</StyledSubHeader>
        <Footer>
          <Image src={MailAlt} alt="Project City Mailing List" />
          <StyledCaption size="sm" mono>
            no spam, we promise
          </StyledCaption>
        </Footer>
      </DescriptionSection>
      <InputSection>
        <NewsletterInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-3"
          borderColor="orange"
        />
        <div className="text-center">
          <NewsletterRegisterButton
            email={email}
            clearValue={() => setEmail("")}
          />
        </div>
      </InputSection>
    </Wrapper>
  );
}

export default ClassesNewsletterRegistration;
