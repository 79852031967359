import React from "react";
import DivideSvg from "images/divider_or.svg";
import styled from "styled-components";

const StyledDivider = styled.img`
  width: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
`;

function AlternativeDivider(props) {
  /* Provides division between a form's input fields and the submit area. */
  return (
    <StyledDivider src={DivideSvg} alt="Divide Icon" {...props}></StyledDivider>
  );
}

export default AlternativeDivider;
