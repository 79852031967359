import React from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { bool, oneOf } from "prop-types";

const Wrapper = styled(Container)`
  min-height: 100vh;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) =>
      props.size === "md" ? props.theme.navbarMarginBottom : "120px"};
    padding-bottom: 290px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.mobileNavbarMarginBottom};
    padding-bottom: 130px;
  }

  ${(props) =>
    props.$noMargin &&
    `
    margin-top: 0!important;
  `}
`;

function DashboardContainer({ noMargin, ...props }) {
  /** Outer container component for base dashboard routes. */
  return (
    <Wrapper $noMargin={noMargin} {...props}>
      {props.children}
    </Wrapper>
  );
}

DashboardContainer.propTypes = {
  /** Determine the size of the spacing. */
  size: oneOf(["sm", "md"]),

  /** Remove the default top margin. */
  noMargin: bool,
};

DashboardContainer.defaultProps = {
  size: "md",
  noMargin: false,
};

export default DashboardContainer;
