import { Form } from "react-bootstrap";
import styled from "styled-components";

const StyledFormLabel = styled(Form.Label)`
  font-family: "Basis Grotesque Pro";
  font-weight: 200;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.black};

  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 22px;
  }
`;

function FormLabel(props) {
  return <StyledFormLabel {...props}>{props.children}</StyledFormLabel>;
}

export default FormLabel;
