import React from "react";
import { Link, useLocation } from "@reach/router";
import NavDropdown from "react-bootstrap/NavDropdown";
import styled from "styled-components";
import { arrayOf, shape, string } from "prop-types";

const StyledNavDropdown = styled(NavDropdown)`
  background-color: ${(props) => props.theme.yellow};
  transform: rotate(2deg);
  text-align: right;

  > .dropdown-toggle {
    color: ${(props) => props.theme.blue};
  }
`;

function ManagementMobileDropdown({ links }) {
  /** Display the management navigation to be shown for mobile clients. */

  const location = useLocation();

  function renderLinks() {
    /** Render the link elements for each of the project's management sections. */
    return links.map((link) => {
      return (
        <NavDropdown.Item key={link.to} as={Link} to={link.to}>
          {link.label}
        </NavDropdown.Item>
      );
    });
  }

  function getTitle() {
    const activeLink = links.find((link) => link.to === location.pathname);
    return activeLink?.label || "Manage";
  }

  return (
    <StyledNavDropdown title={getTitle()} className="mr-auto">
      {renderLinks()}
    </StyledNavDropdown>
  );
}

ManagementMobileDropdown.propTypes = {
  /** Links that should be displayed in the navigation. */
  links: arrayOf(
    shape({
      to: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default ManagementMobileDropdown;
