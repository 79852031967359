import React from "react";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "@reach/router";

const TopLevelItem = styled(Nav.Item)`
  flex: 0 1 min-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 20px;
  color: ${(props) =>
    props.$isActive ? props.theme.yellow : props.theme.blue}!important;
`;

const StyledLink = styled(Nav.Link)`
  font-size: 20px;
  color: ${(props) =>
    props.$isActive ? props.theme.yellow : props.theme.blue}!important;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }
`;

function SecondaryLink({ to, label, ...props }) {
  /** Show a link on a secondary navigation bar. */
  const location = useLocation();
  const activePathname = location.pathname;

  function isActiveNav(to) {
    /** Determine if the nav link should be active, there are nested cases that are custom. */
    if (to === "/sprint/content")
      return ["/sprint/content", "/sprint/animatics"].includes(activePathname);

    return activePathname === to;
  }

  return (
    <TopLevelItem className="mr-3" $isActive={activePathname === to}>
      <StyledLink
        to={to}
        as={Link}
        $isActive={to && isActiveNav(to)}
        {...props}
      >
        {label}
      </StyledLink>
    </TopLevelItem>
  );
}

export default SecondaryLink;
