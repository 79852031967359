import React from "react";
import styled from "styled-components";

import UploadImage from "images/buttons/upload.png";

const StyledUploadImage = styled.img`
  width: 200px;
  cursor: pointer;
  z-index: 10;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    position: fixed;
    bottom: 60px;
    left: 10%;
    width: 30%;
    z-index: 301;
  }
`;

function ContentUploadIcon(props) {
  /** Reusable icon for uploading content videos. */
  return <StyledUploadImage src={UploadImage} {...props} />;
}

export default ContentUploadIcon;
