import { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, navigate } from "@reach/router";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { parse as queryStringParse } from "query-string";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";

import Button from "components/buttons/Button";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import LoadingContainer from "components/loading/Container";
import Caption from "components/text/Caption";
import ProjectCityButton from "components/buttons/Button";
import SubscriptionRequiredContainer from "components/containers/SubscriptionRequired";
import CopyHeader from "components/text/CopyHeader";
import AddAssignmentButton from "features/assignments/components/AddAssignmentButton";
import CommentContainer from "features/comments/components/Container";
import SprintVideo from "features/sprint/components/Video";
import {
  useGetContentUploadDetailQuery,
  useGetContentUploadListQuery,
  useToggleContentUploadLikeMutation,
} from "services/projectCity";
import useGetContentTypeObj from "hooks/GetContentTypeObj";
import LikeContainer from "features/likes/components/Container";
import { contentUploadType } from "features/sprint/enums";
import NextContentUploadList from "features/sprint/components/NextContentUploadList";
import DonationContainer from "features/sprint/components/DonationContainer";
import { getContentUploadUrl } from "features/sprint/utils";
import { getProjectUrl } from "utils/projects";
import useIsAuthenticated from "hooks/IsAuthenticated";
import ContentUploadModal from "features/sprint/modals/ContentUploadModal";
import ConfirmDeleteContentUploadModal from "features/sprint/modals/ConfirmDelete";
import useCanModifyContentUpload from "features/sprint/hooks/CanModifyContentUpload";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingLg};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 10px;
  }
`;

const PurchaseContainer = styled.div`
  margin-top: ${(props) => props.theme.vSpacingLg};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingMd};
  }
`;

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const ModifyContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.vSpacingMd};
`;

function SubscriptionContentContainer({ id: contentUploadId, ...props }) {
  /** The main container for displaying a subscription content object. */

  const contentTypeObj = useGetContentTypeObj("contentupload");
  const { user } = useSelector((state) => state.account);
  const isAuthenticated = useIsAuthenticated();
  const { addToast } = useToasts();
  const { autoPlayContentUploads } = useSelector(
    (state) => state.contentUploads
  );
  const {
    data: contentUpload,
    isLoading,
    isError,
    refetch,
  } = useGetContentUploadDetailQuery({
    contentUploadId,
  });
  const [toggleLike] = useToggleContentUploadLikeMutation();
  const queryStrings = queryStringParse(props.location.search);

  useEffect(() => {
    if (queryStrings.tokens) {
      addToast(
        `Thank you! Your donation of ${queryStrings.tokens} tokens has been received.`,
        { appearance: "success" }
      );
    }
  }, []);

  // If there are no next videos then we should show the default sprint videos as next.
  const {
    data: defaultSprintVideos,
    isLoading: defaultSprintVideosLoading,
  } = useGetContentUploadListQuery(
    {
      ordering: "-created",
      in_sprint: true,
    },
    {
      skip: !isAuthenticated,
    }
  );

  const [showContentUploadModal, hideContentUploadModal] = useModal(
    () => (
      <ContentUploadModal
        contentUpload={contentUpload}
        onHide={hideContentUploadModal}
        chooseBucket
        chooseInSprint
        followupAction={refetch}
      />
    ),
    [contentUpload]
  );

  const [
    showContentUploadDeleteModal,
    hideContentUploadDeleteModal,
  ] = useModal(
    () => (
      <ConfirmDeleteContentUploadModal
        contentUpload={contentUpload}
        onHide={hideContentUploadDeleteModal}
        followupAction={() => navigate("/sprint/content")}
      />
    ),
    [contentUpload]
  );

  const canModify = useCanModifyContentUpload(contentUpload || {});

  if (isLoading) return <LoadingContainer text="Loading..." />;

  if (isError)
    return (
      <Wrapper>
        <SubscriptionRequiredContainer body="To view this content upload, you must be a Story Sprint subscriber" />
      </Wrapper>
    );

  const { username } = contentUpload.createdBy;

  const canAddAssignment =
    contentUpload.createdBy.id === user.id &&
    contentUpload.type === contentUploadType.lesson;

  const { bucket, bucketData } = contentUpload;

  const hasProjectLink = bucket !== null;
  const bucketUrl =
    hasProjectLink && `${getProjectUrl(bucketData)}/${bucketData.bucketSlug}`;

  // Some videos should only show a few next videos, whereas others should show a full series.
  const showSeries = contentUpload.series?.length > 1;

  function handleRecordingsClick() {
    navigate(getProjectUrl(contentUpload.recordingsProject));
  }

  function getNextContentUploads() {
    /** Select the content uploads that should be displayed to be played next. */

    if (showSeries) return contentUpload.series;

    const currentIndex = autoPlayContentUploads.findIndex(
      (cu) => cu.id === parseInt(contentUploadId)
    );

    if (autoPlayContentUploads.length > 0)
      return autoPlayContentUploads.slice(currentIndex + 1, currentIndex + 4);
    else if (!defaultSprintVideosLoading) {
      return defaultSprintVideos.results.slice(0, 3);
    } else return [];
  }

  function handleOnEnded() {
    const next = getNextContentUploads();
    if (next.length > 0) {
      navigate(getContentUploadUrl(next[0]));
    }
  }

  const nextContentComponent = autoPlayContentUploads && (
    <NextContentUploadList
      contentUploads={getNextContentUploads()}
      hasSeries={showSeries}
      activeId={parseInt(contentUploadId)}
    />
  );

  return (
    <Wrapper>
      <Row>
        <Col className="text-center d-md-none mb-3">
          <SubHeaderText color="orange">{contentUpload.title}</SubHeaderText>
          <Caption color="pink">
            Uploaded by <Link to={`/users/${username}`}>@{username}</Link>
          </Caption>
        </Col>
        <Col md={8}>
          {contentUpload.file ? (
            <>
              <SprintVideo
                src={contentUpload.file.url}
                contentUploadId={contentUploadId}
                poster={contentUpload.thumbnail}
                onEnded={handleOnEnded}
                autoPlay
              />
              <ActionBar>
                <LikeContainer
                  count={contentUpload.likesCount}
                  likeActive={contentUpload.isLikedByCurrentUser}
                  onClick={() => toggleLike({ contentUploadId })}
                />
                {contentUpload.canReceiveDonation && (
                  <DonationContainer
                    contentUpload={contentUpload}
                    className="ml-3 d-flex align-items-center"
                  />
                )}
              </ActionBar>
              <CommentContainer
                $withBorder
                contentObject={contentUpload}
                contentTypeObj={contentTypeObj}
              />
              <div className="d-block d-md-none p-3 py-5">
                {nextContentComponent}
              </div>
            </>
          ) : (
            <Caption color="orange" className="text-center">
              Sorry, there is no video upload yet.
            </Caption>
          )}
        </Col>
        <Col md={4} className="text-center d-none d-md-block">
          {canModify && (
            <ModifyContainer>
              <Button noTilt onClick={showContentUploadModal}>
                Edit
              </Button>
              <Button noTilt onClick={showContentUploadDeleteModal}>
                Delete
              </Button>
            </ModifyContainer>
          )}
          <SubHeaderText color="orange">{contentUpload.title}</SubHeaderText>
          <Caption color="pink">
            Uploaded by <Link to={`/users/${username}`}>@{username}</Link>
          </Caption>
          {contentUpload.recordingsProject && (
            <PurchaseContainer>
              <CopyHeader color="pink">
                Additional recordings from{" "}
                <b>{contentUpload.recordingsProject.title}</b> are available for
                purchase.
              </CopyHeader>
              <Button
                variant="info"
                startTilt
                isWavy
                className="mt-2"
                onClick={handleRecordingsClick}
              >
                Buy recordings
              </Button>
            </PurchaseContainer>
          )}
          {contentUpload.assignments.length > 0 && (
            <div>
              <SubHeaderText color="orange" className="mt-4">
                Assignments
              </SubHeaderText>
              <div className="mt-2">
                {contentUpload.assignments.map((assignment) => (
                  <div key={assignment.id}>
                    <Button
                      noTilt
                      onClick={() =>
                        navigate(
                          `/sprint/assignments/${assignment.id}/${assignment.slug}`
                        )
                      }
                      className="mb-1"
                      variant="info"
                    >
                      {assignment.title}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {hasProjectLink && (
            <ProjectCityButton
              variant="secondary"
              onClick={() => navigate(bucketUrl)}
            >
              {bucketData.bucketTitle}
            </ProjectCityButton>
          )}
          {canAddAssignment && (
            <AddAssignmentButton contentUploadId={contentUpload.id} />
          )}
          {nextContentComponent}
        </Col>
      </Row>
    </Wrapper>
  );
}

export default SubscriptionContentContainer;
