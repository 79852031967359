import React from "react";
import { shape } from "prop-types";
import { useModal } from "react-modal-hook";

import ActionButton from "components/buttons/Action";
import FormModal from "components/modals/Form";
import ContactProjectForm from "features/projects/forms/ContactProject";
import MailIcon from "images/icons/mail.svg";
import { Project } from "types";

function ProjectContactButton({ project }) {
  /** Button for the user to contact the project teachers. */

  const [showContactModal, hideContactModal] = useModal(() => {
    return (
      <FormModal onHide={hideContactModal} title="Contact">
        <ContactProjectForm project={project} closeModal={hideContactModal} />
      </FormModal>
    );
  });

  return (
    <ActionButton startTilt onClick={showContactModal}>
      <img src={MailIcon} alt="" /> TEACHER
    </ActionButton>
  );
}

ProjectContactButton.propTypes = {
  /** The project that the contact button will contact. */
  project: shape(Project).isRequired,
};

export default ProjectContactButton;
