import React from "react";
import { bool, object, shape, string } from "prop-types";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";

import StyledRadioButton from "components/controls/RadioButton";
import FormGroup from "components/forms/shared/FormGroup";
import theme from "theme";

const StyledCheckFormGroup = styled(FormGroup)`
  svg {
    color: ${(props) => props.theme.blue};
  }
`;

const CheckWrapper = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-between;
  font-size: 14px;
  border: 4px solid ${(props) => props.theme.blue};

  svg {
    color: ${(props) => props.theme.blue};
  }
`;

const StyledCheck = styled(Form.Check)`
  border-right: ${(props) => (props.on ? "" : "2px solid " + props.theme.blue)};
  padding-right: 5px;
  padding-left: 0px;
  background-color: ${(props) =>
    props.active ? props.theme.blue : "transparent"};
`;

function ToggleBox({
  controllerProps,
  label,
  onLabel,
  offLabel,
  toolTipText,
  ...props
}) {
  /** Provide a box that shows both options of a toggle. */
  return (
    <StyledCheckFormGroup
      label={label}
      isCustomControl
      tooltipText={toolTipText}
      {...props}
    >
      <Controller
        render={({ onChange, value }, _) => {
          if (value === true) {
            value = "on";
          } else if (value === false) {
            value = "off";
          }

          return (
            <CheckWrapper>
              <StyledCheck active={value === "off"}>
                <StyledRadioButton
                  label={offLabel}
                  value="off"
                  onClick={(e) => onChange(e.target.value)}
                  checked={value === "off"}
                  color={theme.pink}
                />
              </StyledCheck>
              <StyledCheck active={value === "on"}>
                <StyledRadioButton
                  label={onLabel}
                  value="on"
                  onClick={(e) => onChange(e.target.value)}
                  checked={value === "on"}
                  color={theme.yellow}
                />
              </StyledCheck>
            </CheckWrapper>
          );
        }}
        {...controllerProps}
      />
    </StyledCheckFormGroup>
  );
}

const ControllerProps = {
  /** The name prop needs to be the field name in the parent form. */
  name: string.isRequired,

  /** Control prop from the parent form. */
  control: object.isRequired,

  /** Default value of the bool field. */
  defaultValue: bool,
};

ToggleBox.propTypes = {
  /** Additional props to pass into the `Controller` component. */
  controllerProps: shape(ControllerProps),

  /** Main label to show above the toggle. */
  label: string.isRequired,

  /** Label to show inside control when selected off. */
  offLabel: string,

  /** Label to show inside control when selected on. */
  onLabel: string,

  /** Text to show in the tooltip. */
  toolTipText: string,
};

ToggleBox.defaultProps = {
  controllerProps: {
    defaultValue: true,
  },
  onLabel: "ON",
  offLabel: "OFF",
};

export default ToggleBox;
