import ApiService from "../service";
import { axiosInstance } from "../api";

import { bucketMultipartUploadsUrl } from "./api";

class BucketMultipartUploadService extends ApiService {
  listUrl = bucketMultipartUploadsUrl;

  uploadPart({ uuid, formData }) {
    /** Upload an individual part to an upload instance. */
    const url = `${this._getDetailUrl(uuid)}upload_part/`;
    return axiosInstance.post(url, formData);
  }

  complete({ uuid }) {
    /** Completes the upload and queues it for assembly */
    const url = `${this._getDetailUrl(uuid)}complete/`;
    return axiosInstance.post(url);
  }
}

export default BucketMultipartUploadService;
