import { loadStripe } from "@stripe/stripe-js";
import { stripePublishableKey } from "utils/stripe";

function useRedirectToCheckout() {
  /** After a checkout has been created, redirect the user to that checkout url. */
  const redirectToCheckout = async ({ sessionId }) => {
    const stripeClient = await loadStripe(stripePublishableKey);
    await stripeClient.redirectToCheckout({
      sessionId,
    });
  };

  return redirectToCheckout;
}

export default useRedirectToCheckout;
