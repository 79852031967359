import React from "react";
import { shape } from "prop-types";
import Image from "react-bootstrap/Image";
import { useSelector } from "react-redux";

import { getFullName, getUserImage } from "utils/users";

import ActionButton from "../ActionButton";
import { ModalActionProps, RevenueSplit } from "types";

function RevenueSplitRow({ revenueSplit, modalActionProps }) {
  /** Render a full row in the revenue split table. */

  const currentUser = useSelector((state) => state.account.user);
  const project = useSelector((state) => state.projects.detail);

  const { user, splitPercent } = revenueSplit;
  const isOwner = project.owner === currentUser.id;

  return (
    <tr>
      <td>
        <Image
          roundedCircle
          alt=""
          src={getUserImage(user)}
          style={{ width: 50 }}
          className="mr-2"
        />
        {getFullName(user)}
      </td>
      <td>{splitPercent}</td>
      {isOwner && (
        <td>
          <ActionButton
            revenueSplit={revenueSplit}
            modalActionProps={modalActionProps}
          />
        </td>
      )}
    </tr>
  );
}

RevenueSplitRow.propTypes = {
  /** The revenue split instance that we're rendering a row for. */
  revenueSplit: shape(RevenueSplit).isRequired,

  /** Properties passed down from the parent modal. */
  modalActionProps: ModalActionProps.isRequired,
};

export default RevenueSplitRow;
