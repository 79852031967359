import React from "react";
import { shape } from "prop-types";

import CommentContainer from "features/comments/components/Container";
import CommentModal from "features/comments/modals/Comment";
import useGetContentType from "hooks/GetContentTypeObj";
import { TextOnlyMessage } from "types";

import TextOnlyMessageDisplay from "../../Display";

function TextOnlyMessageCommentModal({ message, ...props }) {
  /** Display a text only message along with all of its comments. */
  const contentTypeObj = useGetContentType("textonlyfeed");

  return (
    <CommentModal {...props}>
      <TextOnlyMessageDisplay message={message} />
      <CommentContainer
        contentObject={message}
        contentTypeObj={contentTypeObj}
      />
    </CommentModal>
  );
}

TextOnlyMessageCommentModal.propTypes = {
  /** The text only message object that we're displaying. */
  message: shape(TextOnlyMessage).isRequired,
};

export default TextOnlyMessageCommentModal;
