import useIsAuthenticated from "hooks/IsAuthenticated";

function useGetClassLinks() {
  /** Get the class secondary navbar links that should be displayed to the user. */

  const isAuthenticated = useIsAuthenticated();

  const classLinks = [
    {
      to: "/classes/live",
      label: "Live classes",
    },
    {
      to: "/classes/recordings",
      label: "Recordings",
    },
    {
      to: "/classes/sprint",
      label: "Sprint classes",
    },
  ];

  if (isAuthenticated)
    classLinks.push({
      to: "/classes/purchased",
      label: "My classes",
    });

  return classLinks;
}

export default useGetClassLinks;
