import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

import BaseCarousel from "components/carousels/Base";
import LoadingContainer from "components/loading/Container";
import useFetchBucketUploadImages from "hooks/FetchBucketUploadImages";

function ImageStackCarousel({ bucketUpload }) {
  /**
   * Carousel that allows us to tap through an image stack.
   *
   * When the carousel would only be a single image, then we should only show that image
   * and not an actual carousel.
   */

  const [isLoading, images] = useFetchBucketUploadImages(bucketUpload);

  if (isLoading) return <LoadingContainer />;

  if (images.length === 1) return <Image fluid src={images[0].image} />;

  function renderImages() {
    return images.map((image) => {
      return (
        <Carousel.Item key={image.id}>
          <Image className="d-block w-100" src={image.image} alt="" />
        </Carousel.Item>
      );
    });
  }

  return <BaseCarousel>{renderImages()}</BaseCarousel>;
}

export default ImageStackCarousel;
