import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import useFeedShare from "hooks/FeedShare";
import { bucketUploadType } from "utils/enums";
import { createBucketUpload } from "features/bucketUploads/thunks";
import { createStackImage } from "features/stackImages/thunks";
import BucketMultipartUploadService from "features/bucketMultipartUploads/service";
import MultipartUploadCompleteModal from "features/bucketMultipartUploads/modals/MultipartUploadComplete";
import {
  createUploadSession,
  incrementUploadCount,
  resetUploadSession,
  createUploadSessionForOneFile,
} from "features/imageUploadSession/slice";
import { getUploadType } from "utils/uploads";
import { useModal } from "react-modal-hook";
import { renameFile } from "utils/general";

function useBucketUploadFile(files) {
  /* Provide functionality for uploading bucket uploads, which is reused for manual selection or
     drag and drop.

     In general we have a few different types of uploads that can happen, although this will change
     as time progresses. We handle these types of uploads a bit differently, which has its
     functionality in this component.

     1) Image stack - multiple image files that will be uploaded together
     2) PDF file
     3) Video file
     4) Audio file
  */
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const currentBucket = useSelector((state) => state.buckets.current);
  const [uploadId, setUploadId] = useState();
  const showConfirmShareModal = useFeedShare(
    "bucketupload",
    "Upload Successful!",
    uploadId
  );
  const [
    showMultipartUploadCompleteModal,
    hideMultipartUploadCompleteModal,
  ] = useModal(() => {
    return (
      <MultipartUploadCompleteModal onHide={hideMultipartUploadCompleteModal} />
    );
  });

  const mb = 1048576;
  const maxMb = 10;
  const partSize = mb * maxMb;

  // If file is above the max size, it will need to be uploaded in chunks. This is generally used
  // for video files.
  const maxFileSize = mb * maxMb;

  function _getBaseFormData(uploadType) {
    // Creates standard form data that is used for all types of uploads.
    let formData = new FormData();
    formData.append("bucket", currentBucket.id);
    formData.append("kind", uploadType);
    return formData;
  }

  async function _dispatchStackImage(formData) {
    // Need to pull this dispatch action out to have async at top level.
    const action = await dispatch(createStackImage(formData));
    if (action.type === "CREATE_STACK_IMAGE/fulfilled") {
      dispatch(incrementUploadCount());
    } else {
      addToast(
        "There was an error uploading an image, please verify all files are valid image files.",
        { autoDismiss: false, appearance: "error" }
      );
      dispatch(resetUploadSession());
    }
  }

  async function _createBucketUpload(formData) {
    // Creates the Base bucket upload object and returns the action.
    const action = await dispatch(createBucketUpload(formData));
    return action;
  }

  async function _uploadImageStack(files) {
    // Perform the upload process for an image stack.
    const filesArray = Array.from(files);

    // Check if any of the files are too big to upload.
    const hasLargeFile = filesArray.some((file) => file.size > maxFileSize);
    if (hasLargeFile) {
      return addToast(
        `Stack contains a file which is over the limit of ${maxMb}MB. Large files may only be uploaded on their own outside of a stack.`,
        {
          appearance: "error",
          autoDismiss: false,
        }
      );
    }

    let formData = _getBaseFormData(bucketUploadType.imageStack);
    const action = await _createBucketUpload(formData);
    if (action.type === "CREATE_BUCKET_UPLOAD/rejected") {
      addToast("Error creating new bucket upload.", { appearance: "error" });
    } else if (action.type === "CREATE_BUCKET_UPLOAD/fulfilled") {
      // The stack was created, now we can upload the images by looping through the files.
      dispatch(createUploadSession({ total: files.length }));

      Object.keys(files).forEach((fileIndex) => {
        let file = files[fileIndex];
        let formData = new FormData();
        formData.append("stack", action.payload.id);
        formData.append("image", file);
        _dispatchStackImage(formData);
      });

      setUploadId(action.payload.id);
    }
  }

  async function uploadMultipart(file) {
    /** Full process for uploading a multipart chunked upload. */
    const bucketMultipartUploadService = new BucketMultipartUploadService();
    const totalParts = Math.ceil(file.size / partSize);
    let startBytes = 0;
    let part;
    let partNumber = 1;

    dispatch(createUploadSession({ total: totalParts }));

    // First we need to create the underlying bucket upload.
    const uploadType = getUploadType([file]);
    const { payload: bucketUpload } = await _createBucketUpload(
      _getBaseFormData(uploadType)
    );

    try {
      const initialResponse = await bucketMultipartUploadService.create({
        bucketUpload: bucketUpload.id,
        filename: renameFile(file.name),
      });
      const { uuid } = initialResponse.data;

      // Now that we've created the multipart instance we can start uploading the chunks.
      while (startBytes < file.size) {
        part = file.slice(
          startBytes,
          Math.min(startBytes + partSize, file.size)
        );
        let formData = new FormData();
        formData.append("part", part);
        formData.append("part_number", partNumber);

        await bucketMultipartUploadService.uploadPart({ uuid, formData });
        dispatch(incrementUploadCount());
        startBytes += partSize;
        partNumber += 1;
      }

      // Finish the multipart upload.
      await bucketMultipartUploadService.complete({ uuid });

      showMultipartUploadCompleteModal();
    } catch (e) {
      addToast(`Error creating chunked upload.`, {
        appearance: "error",
        autoDismiss: true,
      });
      dispatch(resetUploadSession());
    }
  }

  async function _uploadSingleBucketUpload(file, uploadType) {
    // Uploads a single file as a bucket upload.
    let formData = _getBaseFormData(uploadType);

    if (file.size > maxFileSize) {
      uploadMultipart(file);
    } else {
      formData.append("upload_file", file);

      dispatch(createUploadSessionForOneFile());
      const action = await dispatch(createBucketUpload(formData));

      if (action.type === "CREATE_BUCKET_UPLOAD/rejected") {
        addToast("Error creating new upload.", { appearance: "error" });
        dispatch(resetUploadSession());
      } else if (action.type === "CREATE_BUCKET_UPLOAD/fulfilled") {
        addToast("Upload completed successfully.", { appearance: "success" });
        setUploadId(action.payload.id);
        showConfirmShareModal();
        dispatch(resetUploadSession());
      }
    }
  }

  function onFileUpload(files) {
    // Main handler for when we select the images to upload.
    dispatch(resetUploadSession());

    // Set the form data to send in request.
    if (files.length) {
      const uploadType = getUploadType(files);
      if (uploadType === bucketUploadType.imageStack) {
        _uploadImageStack(files);
      } else {
        _uploadSingleBucketUpload(files[0], uploadType);
      }
    }
  }

  return onFileUpload;
}

export default useBucketUploadFile;
