import React, { useState } from "react";
import moment from "moment";
import { bool, shape } from "prop-types";
import styled from "styled-components";
import { navigate } from "@reach/router";
import slugify from "slugify";
import { useToasts } from "react-toast-notifications";

import TileFooter from "components/tiles/Footer";
import TileImageContainer from "components/tiles/ImageContainer";
import { Contest } from "features/contests/types";
import ContestImage from "../Image";

const Wrapper = styled.div`
  cursor: pointer;
`;

function ContestTile({ contest, ...props }) {
  /** Display an individual contest tile that shows summary data of the contest. */
  const [isHover, setIsHover] = useState(false);

  const { addToast } = useToasts();

  function handleClick() {
    if (new Date(contest.startDate) > new Date())
      return addToast(
        `${contest.title} contest starts on ${moment(contest.startDate).format(
          "ll"
        )}`
      );

    navigate(
      `/sprint/contests/${contest.id}/${slugify(contest.title, {
        lower: true,
      })}`
    );
  }

  return (
    <Wrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClick}
    >
      <TileImageContainer hover={isHover} {...props}>
        <ContestImage contest={contest} />
      </TileImageContainer>
      <TileFooter
        hover={isHover}
        top={contest.title}
        bottom={moment(contest.startDate).format("ll")}
      />
    </Wrapper>
  );
}

ContestTile.propTypes = {
  contest: shape(Contest).isRequired,
  isEven: bool,
};

ContestTile.defaultProps = {
  isEven: true,
};

export default ContestTile;
