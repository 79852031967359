import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";

import ButtonContainer from "../ButtonContainer";
import FormErrorText from "../ErrorText";
import FormSubmitDivider from "../SubmitDivider";

const StyledFormErrorText = styled(FormErrorText)`
  margin-top: ${(props) => props.theme.vSpacingMd};
  color: ${(props) => props.theme.red};
`;

function FormSubmitContainer({
  errorText,
  withBottomDivider,
  withTopDivider,
  ...props
}) {
  /** Provide a shared container for submitting forms. */
  return (
    <div {...props}>
      {withTopDivider && <FormSubmitDivider />}
      <StyledFormErrorText text={errorText} />
      <ButtonContainer>{props.children}</ButtonContainer>
      {withBottomDivider && <FormSubmitDivider />}
    </div>
  );
}

FormSubmitContainer.propTypes = {
  /** Text to be shown as an error for the form. */
  errorText: string,

  /** Apply a form divider below the submit buttons. */
  withBottomDivider: bool,

  /** Apply a form divider above the submit buttons. */
  withTopDivider: bool,
};

FormSubmitContainer.defaultProps = {
  errorText: "",
  withTopDivider: false,
  withBottomDivider: false,
};

export default FormSubmitContainer;
