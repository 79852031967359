import React, { useState } from "react";
import { shape } from "prop-types";
import Image from "react-bootstrap/Image";
import { Link } from "@reach/router";
import styled from "styled-components";
import { useModal } from "react-modal-hook";

import TileFooter from "components/tiles/Footer";
import TileStatsContainer from "components/containers/TileStats";
import VideoMaskImage from "components/images/VideoMask";
import ActionOverlayContainer from "components/containers/ActionOverlay";
import EditIcon from "components/icons/Edit";
import DeleteIcon from "components/icons/Delete";
import FormModal from "components/modals/Form";
import ContestEntryForm from "features/contests/forms/ContestEntry";
import { ContestEntry } from "features/contests/types";
import ContestEntryModal from "features/contests/modals/ContestEntry";
import DefaultVideoImage from "images/default-video-thumbnail.jpg";
import { getUserImage } from "utils/users";
import { useSelector } from "react-redux";
import ConfirmActionModal from "components/modals/ConfirmAction";
import { useDeleteContestEntryMutation } from "services/projectCity";
import ModalSummaryText from "components/text/ModalSummaryText";

const Wrapper = styled.div`
  cursor: pointer;
  max-width: 100%;
  position: relative;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingLg};
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled(Image)`
  width: 40px;
`;

const StyledLink = styled(Link)`
  ${(props) =>
    props.$hover &&
    `
    color: ${props.theme.neonPink}!important;
  `}
`;

function ContestEntryTile({ contestEntry }) {
  /** Display a tile of data for a contest entry. */

  const [hasHover, setHasHover] = useState(false);
  const user = useSelector((state) => state.account.user);
  const [deleteEntry, result] = useDeleteContestEntryMutation();

  const [showContestEntryModal, hideContestEntryModal] = useModal(
    () => (
      <ContestEntryModal
        contestEntry={contestEntry}
        onHide={hideContestEntryModal}
      />
    ),
    [contestEntry]
  );

  function renderUser() {
    const { createdBy } = contestEntry;
    return (
      <UserContainer>
        <ProfileImage roundedCircle src={getUserImage(createdBy)} />
        <StyledLink
          $hover={hasHover}
          to={`/users/${createdBy.username}`}
          className="ml-1"
        >
          @{createdBy.username}
        </StyledLink>
      </UserContainer>
    );
  }

  const [showUpdateContestEntryModal, hideUpdateContestEntryModal] = useModal(
    () => (
      <FormModal
        title="Update contest entry"
        onHide={hideUpdateContestEntryModal}
      >
        <ContestEntryForm
          contestEntry={contestEntry}
          closeModal={hideUpdateContestEntryModal}
        />
      </FormModal>
    ),
    [contestEntry]
  );

  const [showConfirmDeleteEntry, hideConfirmDeleteEntry] = useModal(
    () => (
      <ConfirmActionModal
        confirmAction={() => deleteEntry({ contestEntryId: contestEntry.id })}
        title="Remove contest entry"
        isLoading={result.isLoading}
        confirmText="Yes"
        declineText="No, let's keep it"
        isDanger={true}
        onHide={hideConfirmDeleteEntry}
        errorMessage={result.error?.data?.detail || null}
      >
        <ModalSummaryText>
          Are you sure you want to remove this contest entry? You may resubmit
          up until the the contest's end date.
        </ModalSummaryText>
      </ConfirmActionModal>
    ),
    [contestEntry, result]
  );

  function handleEditClick(e) {
    e.stopPropagation();
    showUpdateContestEntryModal();
  }

  function handleDeleteClick(e) {
    e.stopPropagation();
    showConfirmDeleteEntry();
  }

  function renderOverlay() {
    /** Show the action container, if the user is supposed to see it. */
    if (contestEntry.createdBy.id === user.id)
      return (
        <ActionOverlayContainer show={hasHover}>
          <EditIcon onClick={handleEditClick} />
          <DeleteIcon onClick={handleDeleteClick} />
        </ActionOverlayContainer>
      );
  }

  return (
    <Wrapper
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
      onClick={showContestEntryModal}
    >
      <VideoMaskImage src={contestEntry.thumbnail || DefaultVideoImage} />
      <TileStatsContainer object={contestEntry} />
      <TileFooter
        hover={hasHover}
        top={contestEntry.title}
        bottom={renderUser()}
        className="mt-2"
      />
      {renderOverlay()}
    </Wrapper>
  );
}

ContestEntryTile.propTypes = {
  /** The contest entry object that is being rendered. */
  contestEntry: shape(ContestEntry).isRequired,
};

export default ContestEntryTile;
