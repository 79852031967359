import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    top: 35px;
    left: 45px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    top: 23px;
  }
`;

function ContainerHeader(props) {
  /** A header to appear at the top of a container. */

  return <Wrapper {...props}>{props.children}</Wrapper>;
}

export default ContainerHeader;
