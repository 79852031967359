import { useEffect, useRef } from "react";

function useCombinedRefs(...refs) {
  /**
   * Combine refs so that an inner custom checkbox can be used with react-hook-form.
   *
   * https://www.alltimepower.com/blog/react-hook-forms-custom-checkbox/
   */
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

export default useCombinedRefs;
