import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

import DrawerModal from "components/modals/Drawer";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import NotificationButton from "components/buttons/Notifications";
import Notification from "features/notifications/components/Notification";
import {
  useGetNotificationListQuery,
  useSetNotificationsSeenMutation,
} from "services/projectCity";

const ModalHeader = styled(Modal.Header)`
  border: none;
  justify-content: flex-start;
  align-items: center;
`;

const ModalBody = styled(Modal.Body)``;

function NotificationDrawer(props) {
  /** The drawer to show a user their notifications. */

  const { data: notifications, isLoading } = useGetNotificationListQuery();
  const [markAsSeen] = useSetNotificationsSeenMutation();

  if (isLoading) return null;

  const unseenNotifications = notifications
    ? notifications.results.filter((notification) => notification.seen)
    : [];
  const firstUnseen =
    unseenNotifications.length > 0 ? unseenNotifications[0] : {};

  function handleOnHide() {
    if (notifications.results.filter((n) => !n.seen).length) {
      markAsSeen();
    }
    props.onHide();
  }

  return (
    <DrawerModal {...props} onHide={handleOnHide}>
      <ModalHeader>
        <NotificationButton clickable={false} />
        <SubHeaderText
          tilt
          positiveRotate={false}
          color="neonPink"
          className="ml-2"
        >
          notifications
        </SubHeaderText>
      </ModalHeader>
      <ModalBody>
        {notifications?.results.map((notification) => {
          const withDivider = notification.id === firstUnseen.id;
          return (
            <Notification
              notification={notification}
              withDivider={withDivider}
            />
          );
        })}
      </ModalBody>
    </DrawerModal>
  );
}

export default NotificationDrawer;
