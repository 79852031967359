import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import DashboardLoadingContainer from "components/loading/DashboardContainer";
import Header from "components/text/Header";
import CopyHeader from "components/text/CopyHeader";
import useIsAuthenticated from "hooks/IsAuthenticated";
import ClassProjectList from "features/projects/components/ClassList";
import ClassesNewsletterRegistration from "features/newsletter/components/Classes";
import { fetchProjects } from "features/projects/thunks";
import { projectType } from "utils/enums";
import HeaderSection from "components/containers/HeaderSection";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingLg};
  }
`;

const ProjectSection = styled.div`
  margin-left: 10%;
  margin-right: 10%;
`;

function ClassesRoute() {
  /** Route to show classes split up into different categories. */

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const projectEntities = useSelector((state) => state.projects.entities);
  const { classes } = projectEntities;
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    async function _fetchProjects() {
      /** Fetch the projects that should be on the home featured screen.  */
      await dispatch(
        fetchProjects({
          fresh: true,
          params: {
            isFeatured: true,
            type: projectType.defaultClass,
            ordering: "order",
            hasRecordings: false,
          },
        })
      );

      setIsLoading(false);
    }

    setIsLoading(true);
    _fetchProjects();
  }, [dispatch]);

  if (isLoading) return <DashboardLoadingContainer text="Loading classes" />;

  return (
    <Wrapper>
      <HeaderSection $hasExplosionMargin={isMobile}>
        <Header color="green" top="story focused" bottom="animation school" />
        <CopyHeader className="mt-4">
          Watch top industry professionals make their projects{" "}
          {!isMobile && <br />}
          and teach you how to make yours.
        </CopyHeader>
      </HeaderSection>
      <ProjectSection>
        <ClassProjectList
          projects={classes || []}
          title="live classes (sold separately)"
        />
        {!isAuthenticated && (
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 1 }}>
              <ClassesNewsletterRegistration />
            </Col>
          </Row>
        )}
      </ProjectSection>
    </Wrapper>
  );
}

export default ClassesRoute;
