import React from "react";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import FileUploadProgressBar from "../ProgressBar";
import FileUploadInput from "../FileUploadInput";
import UploadIcon from "images/buttons/upload.png";

const ImageWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    width: 150px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: 120px;
  }
`;

const StyledImage = styled(Image)`
  height: 150px;
  width: 150px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    height: 100px !important;
    width: 100px !important;
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  margin-bottom: 0;
`;

function FileUploader() {
  /**
   * Provide a wrapper to upload images for a bucket upload.
   *
   * Most of the actual functionality is in the `FileUploadInput` since that's shared
   * across other components.
   */

  return (
    <StyledLabel htmlFor="file-upload">
      <ImageWrapper>
        <StyledImage fluid src={UploadIcon} alt="Project City Upload Icon" />
        <FileUploadInput />
      </ImageWrapper>
      <FileUploadProgressBar className="mt-2" />
    </StyledLabel>
  );
}

export default FileUploader;
