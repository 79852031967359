import React from "react";
import styled from "styled-components";
import { func, object } from "prop-types";

import FormControl from "react-bootstrap/FormControl";
import Button from "components/buttons/Button";
import FormGroup from "components/forms/shared/FormGroup";
import TrashIcon from "images/icons/trash.svg";
import PlusIcon from "images/icons/plus.svg";

const MetadataGrid = styled.div`
  display: grid;
  grid-template-columns: 45% 45% 10%;
  grid-gap: 10px;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0;
`;

const CreateNewButton = styled(Button)`
  &:hover {
    background-color: ${(props) => props.theme.blue}!important;
  }
`;

function MetadataContainer({ state, setState }) {
  /**
   * Set a metadata dictionary to provide extra context to a form.
   *
   * This is usually used to pass metadata to Stripe.
   */

  function removeMetadataKey(key) {
    /** Remove a key value pair from the state. */
    let newState = { ...state };
    delete newState[key];
    setState(newState);
  }

  function addMetadata() {
    /** Create a new metadata object. */
    let newState = { ...state, "": "" };
    setState(newState);
  }

  function updateKey(key, updatedKey) {
    /** Update the key of an object. */
    let newState = { ...state, [updatedKey]: state[key] };
    delete newState[key];
    setState(newState);
  }

  function updateValue(key, updatedValue) {
    /** Update the value of an object. */
    let newState = { ...state, [key]: updatedValue };
    setState(newState);
  }

  function renderMetadataRows() {
    return Object.entries(state).map((entry) => {
      const key = entry[0];
      const value = entry[1];

      return (
        <>
          <FormControl
            value={key}
            onChange={(e) => updateKey(key, e.target.value)}
          />
          <FormControl
            value={value}
            onChange={(e) => updateValue(key, e.target.value)}
          />
          <Button variant="outline">
            <img
              style={{ height: 25 }}
              src={TrashIcon}
              onClick={() => removeMetadataKey(key)}
            />
          </Button>
        </>
      );
    });
  }

  return (
    <>
      <MetadataGrid>
        <StyledFormGroup label="Extra field" />
        <StyledFormGroup label="Value" />
        <div />
        {renderMetadataRows()}
      </MetadataGrid>
      <CreateNewButton
        block
        noTilt
        className="mt-2"
        variant="primary"
        onClick={addMetadata}
      >
        <img src={PlusIcon} />
      </CreateNewButton>
    </>
  );
}

MetadataContainer.propTypes = {
  /** The parent state object. */
  state: object.isRequired,

  /** Action in parent to set the metadata state. */
  setState: func.isRequired,
};

export default MetadataContainer;
