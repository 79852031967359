import React, { useEffect, useState } from "react";
import MainNavbar from "components/navbars/Main";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useModal } from "react-modal-hook";

import SiteFooter from "components/general/SiteFooter";
import ProjectActAsContext from "context/ProjectActAs";
import useIsAuthenticated from "hooks/IsAuthenticated";
import SubscriptionPastDueModal from "features/subscriptions/modals/PastDue";
import projectCityApi, {
  useGetNotificationListQuery,
} from "services/projectCity";

const DashboardRouteContainer = styled.div`
  height: 100%;
`;

const InnerWrapper = styled.div`
  min-height: 94vh;
  position: relative;

  // Sometimes the title can be too long on modal and cause side scrolling.
  overflow: hidden;
`;

export default function DashboardRoute({ children }) {
  // Route to handle shared layout for our dashboard pages.

  const [paymentUrl, setPaymentUrl] = useState(null);
  const [actAsPublic, setActAsPublic] = useState(false);
  const [actAsRegistrationTier, setActAsRegistrationTier] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { user } = useSelector((state) => state.account);
  const [notificationsError, setNotificationsError] = useState(null);

  const { error } = useGetNotificationListQuery(undefined, {
    pollingInterval: 15000,
    skip: !isAuthenticated || notificationsError,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    /** If the user becomes unauthenticated or has another error, we should stop the polling. */
    if (!notificationsError) setNotificationsError(error);
  }, [error]);

  const [getPaymentUrl] = projectCityApi.endpoints.getPaymentUrl.useLazyQuery();

  const [showPastDueModal, hidePastDueModal] = useModal(() => {
    return (
      paymentUrl && (
        <SubscriptionPastDueModal
          onHide={hidePastDueModal}
          paymentUrl={paymentUrl}
        />
      )
    );
  }, [paymentUrl]);

  async function _getPaymentUrl() {
    // Interal function to get and set the payment url to pass into the modal.
    const response = await getPaymentUrl({
      subscriptionId: user.subscription.subscriptionId,
    });
    setPaymentUrl(response.data.paymentUrl);
  }

  useEffect(() => {
    if (paymentUrl) showPastDueModal();
  }, [paymentUrl]);

  useEffect(() => {
    // Check if we should prompt the user to fix their failed past due subscription.
    if (user.subscription?.status === "past_due") {
      _getPaymentUrl();
    }
  }, []);

  return (
    <DashboardRouteContainer>
      <ProjectActAsContext.Provider
        value={{
          isPublic: actAsPublic,
          registrationTier: actAsRegistrationTier,
          setPublic: (value) => setActAsPublic(value),
          setRegistrationTier: (value) => setActAsRegistrationTier(value),
        }}
      >
        <MainNavbar />
        <InnerWrapper className="dashboard-route-inner">
          {{ ...children }}
        </InnerWrapper>
        <SiteFooter />
      </ProjectActAsContext.Provider>
    </DashboardRouteContainer>
  );
}
