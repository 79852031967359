import React from "react";
import { bool, oneOf, shape } from "prop-types";
import styled from "styled-components";
import { navigate } from "@reach/router";
import Nav from "react-bootstrap/Nav";
import { useSelector } from "react-redux";

import SubHeader from "components/text/SubHeader";
import Sticker from "components/icons/Sticker";
import ProjectTile from "features/projects/components/Tile";
import PrivateBanner from "features/projects/components/banners/Private";
import { Project } from "types";
import {
  getProjectUrl,
  getProjectBaseUrl,
  getProjectManagementUrl,
  isClass,
  isProjectAdmin,
} from "utils/projects";
import { textColors } from "styles/constants";
import TileImage from "components/tiles/Image";

const OwnProfileWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.vSpacingMd};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    ${(props) =>
      props.withSidePadding &&
      `
      padding: 0 20px;
    `}
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    align-items: flex-start;
  }
`;

const ProjectLinkSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  flex-basis: 100%;
  padding-left: 38px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex: 1 0 90%;
  }
`;

const ImageWrapper = styled.div`
  flex-basis: 100%;
  position: relative;
  cursor: pointer;

  transform: rotate(${(props) => (props.positiveRotate ? "1" : "-2")}deg);

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex: 1 1 20%;

    img {
      width: 400% !important;
      transform: translateX(-80px);
      max-width: inherit;
    }
  }
`;

const StyledEditProjectWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: -30px;
  width: 200px;
  z-index: 100;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: 100px;
    right: -20px;
  }
}
`;

const StyledLink = styled(Nav.Link)`
  font-family: Basis Grotesque Mono;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  text-align: start;
  display: block;
  width: 100%;
  padding: 0px;
  color: ${(props) =>
    props.highlight ? props.theme.neonPink : props.theme.yellow};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.neonPink};
    text-decoration: none;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 17px;
    line-height: 40px;
  }
`;

function ProfileProjectTile({
  project,
  isOwnProfile,
  isEven,
  showTeachers,
  color,
}) {
  /** Project tile that is shown on a user's profile page. */

  const user = useSelector((state) => state.account.user);

  function handleNavigate() {
    navigate(getProjectUrl(project));
  }

  function handleNavigateToLink(link) {
    const baseURL = getProjectBaseUrl(project);
    navigate(`${baseURL}${link}`);
  }

  function handleNavigateManagement() {
    navigate(getProjectManagementUrl(project));
  }

  function renderBucketLinks() {
    return project.buckets.slice(0, 6).map(({ id, title, slug }) => (
      <StyledLink key={id} onClick={() => handleNavigateToLink(`/${slug}`)}>
        {title}
      </StyledLink>
    ));
  }

  return !isOwnProfile ? (
    <ProjectTile
      project={project}
      isEven={isEven}
      showTeachers={showTeachers}
    />
  ) : (
    <OwnProfileWrapper>
      <ImageWrapper positiveRotate={isEven}>
        {isClass(project) && isProjectAdmin(user, project) && (
          <StyledEditProjectWrapper onClick={handleNavigateManagement}>
            <Sticker type="draft" />
          </StyledEditProjectWrapper>
        )}
        <TileImage
          src={project.image}
          onClick={handleNavigate}
          className="mb-2"
        />
        {!project.isPublic && !isClass(project) && (
          <PrivateBanner className="private banner" />
        )}
      </ImageWrapper>
      <ProjectLinkSection>
        <SubHeader
          tilt={false}
          positiveRotate={null}
          text={project.title}
          className="mb-2"
          color={color}
        />
        {renderBucketLinks()}
        <StyledLink
          highlight={true}
          onClick={handleNavigateManagement}
          className="mt-2"
        >
          Class Management
        </StyledLink>
      </ProjectLinkSection>
    </OwnProfileWrapper>
  );
}

ProfileProjectTile.propTypes = {
  /** The project that we are displaying. */
  project: shape(Project).isRequired,

  /** Determine if the user is viewing their own profile. */
  isOwnProfile: bool.isRequired,

  /** Alternating styling for the project tiles. */
  isEven: bool.isRequired,

  /** Determine if the teachers should be shown in project tile. */
  showTeachers: bool.isRequired,

  /** The color of the project title heading. */
  color: oneOf(textColors),
};

export default ProfileProjectTile;
