import React from "react";
import { arrayOf, shape } from "prop-types";

import Caption from "components/text/Caption";
import Grid from "components/grids/Grid";
import AssignmentTile from "../Tile";
import { Assignment } from "features/assignments/types";
import { usePrefetch } from "services/projectCity";

function AssignmentList({ assignments }) {
  /** Display a list of contests that the user can navigate into. */

  const prefetchAssignment = usePrefetch("getAssignment");

  if (assignments.length === 0)
    return <Caption>There are no assignments avaialble.</Caption>;

  return (
    <Grid $lgColumns={4}>
      {assignments.map((assignment, index) => (
        <AssignmentTile
          isEven={index % 2 === 0}
          key={assignment.id}
          assignment={assignment}
          onMouseEnter={() =>
            prefetchAssignment({ assignmentId: assignment.id })
          }
        />
      ))}
    </Grid>
  );
}

AssignmentList.propTypes = {
  /** The list of assignments that should be rendered. */
  assignments: arrayOf(shape(Assignment)),
};

export default AssignmentList;
