import React from "react";
import { Link } from "@reach/router";
import { bool, shape } from "prop-types";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import Body from "components/text/Body";
import ProfileImageStar from "images/Star.svg";
import { getUserImage } from "utils/users";
import { User } from "types";

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${(props) => props.theme.vSpacingXs};
`;

const ProfileImageContainer = styled.div`
  position: relative;
`;

const StyledImage = styled(Image)`
  width: 50px;
  height: 50px;
`;

const ImageIcon = styled(Image)`
  position: absolute;
  bottom: -5px;
  left: -5px;
  width: 40%;
`;

function TeacherDisplay({ teacher, isClass, ...props }) {
  /* Teacher display row used in the class info header section. */
  return (
    <StyledLink to={`/users/${teacher.username}`} {...props}>
      <ProfileImageContainer>
        <StyledImage
          roundedCircle
          src={getUserImage(teacher)}
          alt={`Project City ${teacher.username}`}
          className="mr-1"
        />
        {isClass && <ImageIcon src={ProfileImageStar} />}
      </ProfileImageContainer>
      <Body mono inline color="neonPink">
        {teacher.firstName} {teacher.lastName}
      </Body>
      {props.children}
    </StyledLink>
  );
}

TeacherDisplay.propTypes = {
  /** The teacher user that is being displayed */
  teacher: shape(User).isRequired,

  /** Determine if it's a teacher of a class, or a project collaborator. */
  isClass: bool.isRequired,
};

export default TeacherDisplay;
