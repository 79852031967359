import React from "react";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bool, shape } from "prop-types";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

import StripeConnectButton from "components/buttons/StripeConnect";
import Button from "components/buttons/Button";
import SubHeader from "components/text/SubHeader";
import Body from "components/text/Body";
import usePayButtonModal from "hooks/PayButtonModal";
import useCanCreateClass from "hooks/CanCreateClass";
import AddTierSection from "features/tiers/components/AddTierSection";
import RegistrationTierPaymentSection from "features/tiers/components/PaymentSection";
import RegistrationTierSetupModal from "features/tiers/modals/RegistrationTierSetup";
import { Project } from "types";

const StyledRow = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  margin: ${(props) => props.theme.vSpacingMd} 0px;
`;

const StyledTierCol = styled(Col)`
  margin-bottom: ${(props) => props.theme.vSpacingMd};
`;

function RegistrationSection({
  project,
  shouldViewAdminItems,
  actAsRegistrationTier,
}) {
  /* Renders the section on project info page for users to signup for a class. */

  // The registration tier objects the user has an active enrollment for.
  const user = useSelector((state) => state.account.user);
  const canCreateClass = useCanCreateClass();
  const activeRegistrations = user.registrations;
  const activeRegistrationIds = activeRegistrations
    ? activeRegistrations.map((r) => r.id)
    : [];

  // An array of the ids for the registration tiers of this project.
  const classRegistrationIds = project.tiers.map((tier) => tier.id);

  const showSupportModal = usePayButtonModal();

  function renderOwnerNotification() {
    /* Renders a notification alert for the owner to know what needs to be done to activate payment
       buttons.

       This follows a general progression of actions the owner takes to activate their registration
       tiers:

       1) Connect their account to Stripe
       2) Create registration tiers
       3) Ensure that all teachers are connected & payment split is correct
       4) Profit.
    */

    if (!canCreateClass)
      return (
        <Alert
          variant="white"
          className="d-flex justify-content-center align-items-center"
        >
          <Body inline>
            You must be an approved user to create payment buttons.
          </Body>
          <Button onClick={showSupportModal} className="ml-3">
            <FontAwesomeIcon icon={faEnvelope} /> Contact Support
          </Button>
        </Alert>
      );

    if (user.account === null) {
      return (
        <Alert variant="info">
          You must connect your account to create payment buttons.
          <StripeConnectButton
            project={project}
            variant="light"
            block
            className="mt-3"
          >
            Buy now
          </StripeConnectButton>
        </Alert>
      );
    }
  }

  const [showTierModal, hideTierModal] = useModal(() => {
    return (
      <RegistrationTierSetupModal onHide={hideTierModal} project={project} />
    );
  }, [project]);

  // Before doing anything, let's check that the project is available to receive registrations.
  if (
    shouldViewAdminItems &&
    classRegistrationIds.length === 0 &&
    (!canCreateClass || user.account === null)
  ) {
    return user.id !== project.owner ? null : (
      <div className="my-4 text-center">{renderOwnerNotification()}</div>
    );
  }

  function getShownTiers() {
    // Returns an array of the tiers that should be shown to the user.
    let showTiers = [];

    if (shouldViewAdminItems) return project.tiers;

    // First filter the tiers to only the ones that the user should be able to see.
    const visibleTiers = project.tiers.filter(
      (tier) => tier.isVisible === true
    );

    visibleTiers.forEach((tier) => {
      // Check if there are extra restrictions on the tier.
      if (tier.prereqTiers.length === 0) showTiers.push(tier);
      else {
        // Check that the user has the restricted to tiers, or is acting as the required tier.
        if (
          actAsRegistrationTier &&
          tier.prereqTiers.includes(actAsRegistrationTier)
        )
          showTiers.push(tier);
        else if (
          activeRegistrationIds.some((tierId) =>
            tier.prereqTiers.includes(tierId)
          )
        )
          showTiers.push(tier);
      }
    });

    return showTiers;
  }

  function renderTiersSection() {
    /* Renders a list of the tiers that the user can enroll in, or a message that they're already
       enrolled.
    */
    return getShownTiers().map((tier, index) => {
      const variant = index % 2 ? "primary" : "secondary";
      return (
        <StyledTierCol sm={12} md={6} lg={4} key={tier.id}>
          <RegistrationTierPaymentSection
            variant={variant}
            tier={tier}
            isEnrolled={activeRegistrationIds.includes(tier.id)}
            canEdit={shouldViewAdminItems}
          />
        </StyledTierCol>
      );
    });
  }

  return (
    <Container>
      <SubHeader>tiers</SubHeader>
      <StyledRow>
        {renderTiersSection()}
        {shouldViewAdminItems && (
          <Col sm={12} md={6} lg={4}>
            <AddTierSection
              onClick={canCreateClass ? showTierModal : showSupportModal}
            />
          </Col>
        )}
      </StyledRow>
    </Container>
  );
}

RegistrationSection.propTypes = {
  /** The project that we're rending the registration tier section for. */
  project: shape(Project).isRequired,

  /** Determine if the user should be able to view the admin items. */
  shouldViewAdminItems: bool.isRequired,

  /** Fakes viewing as a different registration tier. */
  actAsRegistrationTier: bool,
};

export default RegistrationSection;
