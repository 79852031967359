import { bool } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;

  > * {
    margin: 5px;
  }
`;

function ActionOverlayContainer(props) {
  /** Container to place actions that appear on hover. */
  if (!props.show) return null;

  return <Wrapper {...props}>{props.children}</Wrapper>;
}

ActionOverlayContainer.propTypes = {
  /** Determine if the actions should be shown or not. */
  show: bool,
};

ActionOverlayContainer.defaultProps = {
  show: false,
};

export default ActionOverlayContainer;
