import React from "react";
import Container from "react-bootstrap/Container";
import styled from "styled-components";

const Wrapper = styled(Container)`
  margin-top: ${(props) => props.theme.vSpacingXl};
  margin-bottom: ${(props) => props.theme.vSpacingXl};

  h3 {
    margin-top: ${(props) => props.theme.vSpacingLg};
    margin-bottom: ${(props) => props.theme.vSpacingSm};
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: ${(props) => props.theme.vSpacingSm};
  }
`;

function StaticPageBase(props) {
  /** Provide base container for styling of static pages. */
  return <Wrapper {...props}>{props.children}</Wrapper>;
}

export default StaticPageBase;
