import React, { useState } from "react";
import { func, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import BaseForm from "components/forms/Base";
import Checkbox from "components/controls/Checkbox";
import Quill from "components/controls/Quill";
import FormGroup from "components/forms/shared/FormGroup";
import FormErrorText from "components/forms/shared/ErrorText";
import {
  createTextOnlyMessage,
  updateTextOnlyMessage,
} from "features/textOnly/thunks";
import {
  createPreviewMessage,
  updatePreviewMessage,
} from "features/buckets/slice";
import { getErrorMessage } from "utils/general";
import FormSubmitContainer from "../shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import { TextOnlyMessage } from "types";

function TextOnlyForm({ message, closeModal, ...props }) {
  /** Form to create or modify a text only page message.  */

  const dispatch = useDispatch();
  const currentBucketId = useSelector((state) => state.buckets.current.id);
  const currentUser = useSelector((state) => state.account.user);
  const { addToast } = useToasts();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    getValues,
  } = useForm();
  const [formError, setFormError] = useState();

  const isUpdating = message !== null;
  const isPreview = message?.message && !isUpdating;

  function handleCreatePreview() {
    /** Create a preview message for the user to view and modify before publishing. */
    const values = getValues();
    dispatch(
      createPreviewMessage({
        createdBy: currentUser,
        ...values,
      })
    );
    closeModal();
  }

  async function onSubmit(payload) {
    /** Either create or update a text only message, or we can also update a preview. */
    if (isPreview) {
      dispatch(
        updatePreviewMessage({
          message: payload.message,
          isHidden: payload.isHidden,
          id: message.id,
        })
      );
      closeModal();
    } else {
      payload.bucket = currentBucketId;
      const actionFunction = isUpdating
        ? updateTextOnlyMessage
        : createTextOnlyMessage;
      const action = await dispatch(
        actionFunction({ payload, textOnlyId: message?.id })
      );

      if (action.type.includes("fulfilled")) {
        addToast("your message text has been added successfully!", {
          appearance: "success",
        });
        closeModal();
      } else if (action.type.includes("rejected")) {
        const message = getErrorMessage(
          action.payload.data,
          `Error creating feed message`
        );
        setFormError(message);
      }

      return action;
    }
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Message" errors={errors.message}>
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <Quill
              onBlur={onBlur}
              onChange={onChange}
              inputRef={ref}
              error={errors.message}
              value={value}
            />
          )}
          rows="8"
          defaultValue={message?.message || ""}
          rules={{ required: true }}
          name="message"
          control={control}
          isInvalid={errors.message !== undefined}
        />
      </FormGroup>
      <FormGroup errors={errors.is_hidden}>
        <Controller
          as={<Checkbox label="Hide this message from global feed" />}
          name="isHidden"
          control={control}
        />
      </FormGroup>
      <FormErrorText text={formError} />
      <FormSubmitContainer>
        <FormSecondaryButton onClick={closeModal}>Close</FormSecondaryButton>
        <div>
          {!isPreview && (
            <FormSecondaryButton variant="link" onClick={handleCreatePreview}>
              Preview
            </FormSecondaryButton>
          )}
          <FormPrimaryButton isLoading={isSubmitting}>
            {isUpdating ? "Save" : isPreview ? "Update preview" : "Create"}
          </FormPrimaryButton>
        </div>
      </FormSubmitContainer>
    </BaseForm>
  );
}

TextOnlyForm.propTypes = {
  /** The existing text only message that we're editing. */
  message: shape(TextOnlyMessage),

  /** An action to close the modal the form is in. */
  closeModal: func.isRequired,
};

TextOnlyForm.defaultProps = {
  message: null,
};

export default TextOnlyForm;
