import React from "react";
import { useModal } from "react-modal-hook";

import FormModal from "components/modals/Form";
import EmailForm from "components/forms/Email";
import { supportEmailUrl } from "features/emails/api";

function useSupportModal() {
  /** Bring up a support modal for the users to send contact email to support. */
  const [showContactModal, hideContactModal] = useModal(() => {
    return (
      <FormModal
        title="support"
        onHide={hideContactModal}
        closeModal={hideContactModal}
      >
        <EmailForm
          closeModal={hideContactModal}
          email={{ to: "support@projectcity.tv" }}
          apiUrl={supportEmailUrl}
          toDisabled
          toSupport
        />
      </FormModal>
    );
  });

  return showContactModal;
}

export default useSupportModal;
