import { useState } from "react";
import { bool, shape } from "prop-types";
import styled from "styled-components";
import { navigate } from "@reach/router";
import slugify from "slugify";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import Image from "react-bootstrap/Image";

import Sticker from "components/icons/Sticker";
import TileFooter from "components/tiles/Footer";
import TileImageContainer from "components/tiles/ImageContainer";
import ActionOverlayFull from "components/containers/ActionOverlayFull";
import { Assignment } from "features/assignments/types";
import AssignmentModal from "features/assignments/modals/Assignment";
import ConfirmDeleteAssignmentModal from "features/assignments/modals/ConfirmDelete";
import CheckCircle from "images/icons/check_circle.png";
import { isProjectCityAdmin } from "utils/permissions";
import AssignmentImage from "../Image";
import Moment from "react-moment";
import Caption from "components/text/Caption";

const Wrapper = styled.div`
  cursor: pointer;
`;

const PrivateSticker = styled(Sticker)`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 50%;
`;

const CompletedOverlay = styled(Image)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const DataContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${(props) =>
    props.hover &&
    `
    > * {
      color: ${props.theme.neonPink}!important;
    }
  `}
`;

const StyledMoment = styled(Moment)`
  color: ${(props) => props.theme.pink};
`;

function AssignmentTile({ assignment, ...props }) {
  /** Display an individual assignment tile that shows summary data of the assignment. */
  const [hasHover, setHasHover] = useState(false);
  const user = useSelector((state) => state.account.user);

  function handleClick() {
    navigate(
      `/sprint/assignments/${assignment.id}/${slugify(assignment.title, {
        lower: true,
      })}`
    );
  }

  const [showUpdateAssignmentForm, hideUpdateAssignmentForm] = useModal(
    () => (
      <AssignmentModal
        assignment={assignment}
        onHide={hideUpdateAssignmentForm}
      />
    ),
    [assignment]
  );

  const [showDeleteAssignmentForm, hideDeleteAssignmentForm] = useModal(
    () => (
      <ConfirmDeleteAssignmentModal
        assignment={assignment}
        onHide={hideDeleteAssignmentForm}
      />
    ),
    [assignment]
  );

  function handleEditClick(e) {
    e.stopPropagation();
    showUpdateAssignmentForm();
  }

  function handleDeleteClick(e) {
    e.stopPropagation();
    showDeleteAssignmentForm();
  }

  function renderActionOverlay() {
    /** Show the action container, if the user is supposed to see it. */
    const canModify =
      assignment.createdBy.id === user.id || isProjectCityAdmin(user);

    if (canModify)
      return (
        <ActionOverlayFull
          hasHover={hasHover}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      );
  }

  function renderCompletedOverlay() {
    if (assignment.userHasSubmitted)
      return <CompletedOverlay fluid src={CheckCircle} />;
  }

  return (
    <Wrapper
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
      onClick={handleClick}
    >
      <TileImageContainer hover={hasHover} {...props}>
        <AssignmentImage assignment={assignment} />
        {renderActionOverlay()}
        {renderCompletedOverlay()}
        {!assignment.isPublic && <PrivateSticker type="private" />}
      </TileImageContainer>
      <DataContainer hover={hasHover}>
        <Caption color="pink">by @{assignment.createdBy.username}</Caption>
        <StyledMoment fromNow>{assignment.created}</StyledMoment>
      </DataContainer>
      <TileFooter className="mt-0" hover={hasHover} top={assignment.title} />
    </Wrapper>
  );
}

AssignmentTile.propTypes = {
  assignment: shape(Assignment).isRequired,
  isEven: bool,
};

AssignmentTile.defaultProps = {
  isEven: true,
};

export default AssignmentTile;
