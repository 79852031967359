import React from "react";
import styled from "styled-components";
import { bool, number, string } from "prop-types";

import theme from "theme";

const StyledContainer = styled.div`
  position: relative;

  border: ${(props) => props.$borderWidth}px ${(props) => props.$borderType}
    ${(props) => props.$borderColor};

  ${(props) => props.fill && `background-color: rgba(46, 48, 229, 0.2);`}

  ${(props) =>
    props.padding &&
    `
    @media (min-width: ${props.theme.smBreakpoint}) {
      padding: ${props.paddingSize === "sm" ? "20px" : "50px"};
    }

    @media (max-width: ${props.theme.smBreakpoint}) {
      padding: 23px;
    }
  `}


  ${(props) =>
    props.centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

function BorderContainer({
  children,
  borderColor,
  borderType,
  borderWidth,
  ...props
}) {
  /** Shared container that has a border. */

  return (
    <StyledContainer
      $borderColor={borderColor}
      $borderType={borderType}
      $borderWidth={borderWidth}
      {...props}
    >
      {children}
    </StyledContainer>
  );
}

BorderContainer.propTypes = {
  /** The width to apply to the border. */
  borderWidth: number,

  /** The type of border to apply (dashed, solid, etc). */
  borderType: string,

  /** The color to apply to the border. */
  borderColor: string,

  /** Fill the background of the container. */
  fill: bool,

  /** Apply a standard padding to the container. */
  padding: bool,

  /** Control the size of the container's padding. */
  paddingSize: string,

  /** Have the content of the section be centered in the middle. */
  centered: bool,
};

BorderContainer.defaultProps = {
  borderWidth: 2,
  borderType: "dashed",
  borderColor: theme.blue,
  padding: true,
  fill: false,
  centered: false,
  paddingSize: "md",
};

export default BorderContainer;
