import React from "react";
import PropTypes from "prop-types";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import InfoIcon from "images/icons/info.svg";
import Tooltip from "components/tooltips/Tooltip";

function InfoTooltip({ iconProps, ...props }) {
  /** Display a commonly used info tooltip. */
  return (
    <Tooltip {...props}>
      <img
        src={InfoIcon}
        icon={faInfoCircle}
        style={{
          opacity: 0.8,
        }}
        {...iconProps}
      />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  /** Additional properties to pass to the icon component. */
  iconProps: PropTypes.object,
};

InfoTooltip.defaultProps = {
  iconProps: {},
};

export default InfoTooltip;
