import LoadingContainer from "components/loading/Container";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ClassProjectList from "features/projects/components/ClassList";
import { fetchProjects } from "features/projects/thunks";
import { projectType } from "utils/enums";
import Body from "components/text/Body";
import styled from "styled-components";
import Header from "components/text/Header";
import HeaderSection from "components/containers/HeaderSection";
import CopyHeader from "components/text/CopyHeader";
import { isMobile } from "react-device-detect";
import InfoTooltip from "components/tooltips/Info";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding-left: ${(props) => props.theme.vSpacingLg};
    padding-right: ${(props) => props.theme.vSpacingLg};
    margin-top: ${(props) => props.theme.vSpacingLg};
  }
`;

function ClassRecordingsRoute() {
  /** Highlight featured classes that sell recordings. */
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { recordings } = useSelector((state) => state.projects.entities);

  async function fetchRecordings() {
    await dispatch(
      fetchProjects({
        fresh: true,
        params: {
          isFeatured: true,
          type: projectType.defaultClass,
          ordering: "order",
          hasRecordings: true,
        },
      })
    );

    setIsLoading(false);
  }

  useEffect(() => {
    fetchRecordings();
  }, []);

  if (isLoading) return <LoadingContainer text="Loading recordings..." />;

  if (recordings.length === 0) {
    return (
      <div className="text-center mt-3">
        <Body color="neonPink">
          There are no featured recordings at this time.
        </Body>
      </div>
    );
  }

  return (
    <Wrapper>
      <HeaderSection>
        <Header color="orange" top="live class" bottom="recordings" />
      </HeaderSection>
      <CopyHeader className="text-center mt-4">
        Recordings are sold separately from Sprint subscription
      </CopyHeader>
      <ClassProjectList projects={recordings || []} />
    </Wrapper>
  );
}

export default ClassRecordingsRoute;
