import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";

import DropdownCaretIcon from "images/icons/caret.svg";

const StyledToggle = styled(Dropdown.Toggle)`
  color: ${(props) => props.theme.yellow} !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDropdown = styled(Dropdown)`
  button::after {
    display: none;
  }

  a:hover {
    background-color: yellow;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

function CustomDropdownButton({ title, ...props }) {
  /** Button used to open a dropdown menu. */
  return (
    <StyledDropdown {...props}>
      <StyledToggle>
        {title} <img className="ml-2" src={DropdownCaretIcon} />
      </StyledToggle>
      <Dropdown.Menu>{props.children}</Dropdown.Menu>
    </StyledDropdown>
  );
}

export default CustomDropdownButton;
