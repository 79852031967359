import { useEffect, useRef } from "react";
import styled from "styled-components";

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
`;

function Video(props) {
  /** Standard video component which does not allow download. */
  const videoRef = useRef(null);

  useEffect(() => {
    /** Disable right clicking on the video component, make it harder to download. */
    videoRef.current.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <StyledVideo
      controlsList="nodownload"
      ref={videoRef}
      controls
      playsInline
      type="video/mp4"
      {...props}
    >
      {props.children}
    </StyledVideo>
  );
}

export default Video;
