import React from "react";
import { bool, number, shape } from "prop-types";
import styled from "styled-components";

import { Project } from "types";
import NewImageUpload from "./NewMediaUploader";
import ProjectMediaItem from "./MediaBlockItem";

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingXl} 0;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function ProjectMediaBlockSection({
  project,
  shouldViewAdminItems,
  maxItems,
  ...props
}) {
  /** A full section to upload images or videos to a project's media block. */

  const itemArray = project.imageBlocks[0].items;

  function renderItems() {
    /** Render each of the uploaded images for the project. */
    return itemArray.map((item, index) => (
      <ProjectMediaItem
        item={item}
        key={item.id}
        isEven={index % 2 === 0}
        shouldViewAdminItems={shouldViewAdminItems}
      />
    ));
  }

  return (
    <Wrapper {...props}>
      <ItemContainer>{renderItems()}</ItemContainer>
      {shouldViewAdminItems && itemArray.length < maxItems && (
        <NewImageUpload project={project} className="mt-5" />
      )}
    </Wrapper>
  );
}

ProjectMediaBlockSection.defaultProps = {
  /** The project whose image block we're rendering. */
  project: shape(Project).isRequired,

  /** Determine if the user should see the admin items for editing. */
  shouldViewAdminItems: bool.isRequired,

  /** Only allow a certain number of items in the section. */
  maxItems: number,
};

ProjectMediaBlockSection.defaultProps = {
  maxItems: 5,
};

export default ProjectMediaBlockSection;
