import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  UPDATE_TIER_DESCRIPTION,
  CREATE_TIER_DESCRIPTION,
  DELETE_TIER_DESCRIPTION,
} from "./constants";
import TierDescriptionService from "./service";

const service = new TierDescriptionService();

export const createTierDescription = createAsyncThunk(
  CREATE_TIER_DESCRIPTION,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.create(payload);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const updateTierDescription = createAsyncThunk(
  UPDATE_TIER_DESCRIPTION,
  async ({ descriptionId, payload }, { rejectWithValue }) => {
    try {
      const response = await service.update(descriptionId, payload);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);

export const deleteTierDescription = createAsyncThunk(
  DELETE_TIER_DESCRIPTION,
  async (descriptionId, { rejectWithValue }) => {
    try {
      const response = await service.delete(descriptionId);
      return response.data;
    } catch (err) {
      const { status, data } = err.response;
      return rejectWithValue({ status, data });
    }
  }
);
