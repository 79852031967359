import React from "react";
import { string } from "prop-types";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import SubHeader from "components/text/SubHeader";
import InfoTooltip from "components/tooltips/Info";
import theme from "theme";

const StyledSubHeader = styled(SubHeader)`
  letter-spacing: -0.04em;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-left: 120px;
    margin-bottom: ${(props) => props.theme.vSpacingLg};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    text-align: center;
    width: 100%;
    font-size: 54px;
    line-height: 54px;
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }
`;

function ProjectManagementHeader({ title, subtitle, tooltipText, ...props }) {
  /** A header component to display at the top of project management screens. */

  function renderTooltip() {
    return (
      tooltipText && (
        <InfoTooltip
          id="tier"
          text={tooltipText}
          iconProps={{
            style: {
              color: theme.blue,
              fontSize: theme.fontSizeSm,
              marginBottom: theme.vSpacingSm,
              opacity: 0.8,
            },
          }}
        />
      )
    );
  }

  return (
    <StyledSubHeader
      italic
      size="xl"
      color="lime"
      text={title}
      containerProps={{ className: isMobile ? "w-100 mb-4" : "" }}
      {...props}
    >
      {renderTooltip()}
    </StyledSubHeader>
  );
}

ProjectManagementHeader.propTypes = {
  /** The text to show for the page header. */
  title: string.isRequired,

  /** Text to show under the main header text. */
  subtitle: string,

  /** Text to show in an optional tooltip. */
  tooltipText: string,
};

ProjectManagementHeader.defaultProps = {
  tooltipText: null,
  subtitle: null,
};

export default ProjectManagementHeader;
