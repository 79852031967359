import React from "react";
import Header from "components/text/Header";
import CopyHeader from "components/text/CopyHeader";
import styled from "styled-components";

import Sticker from "components/icons/Sticker";
import LoadingContainer from "components/loading/Container";
import ContestList from "features/contests/components/List";
import { useGetContestListQuery } from "services/projectCity";

const StyledSticker = styled(Sticker)`
  position: absolute;
  top: 100px;
  right: -50px;
`;

function ContestListRoute() {
  const { data, isLoading } = useGetContestListQuery({});

  if (isLoading) return <LoadingContainer text="loading contests..." />;

  return (
    <div className="text-center">
      <Header top="Join our" bottom="monthly contests" />
      <CopyHeader className="mt-3">
        Enter your 1 minute animatic for a chance to win the $1000 monthly
        prize!
      </CopyHeader>
      <ContestList contests={data.results} />
      <StyledSticker />
    </div>
  );
}

export default ContestListRoute;
