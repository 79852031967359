import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { useModal } from "react-modal-hook";

import Body from "components/text/Body";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import ProjectCityButton from "components/buttons/Button";
import { ContentUpload } from "features/sprint/types";
import { getContentUploadUrl } from "features/sprint/utils";
import { secondsToTime } from "utils/general";
import ContentUploadModal from "features/sprint/modals/ContentUploadModal";
import ContentUploadImage from "../ContentUploadImage";
import { contentUploadType } from "features/sprint/enums";
import ConfirmDeleteContentUploadModal from "features/sprint/modals/ConfirmDelete";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  > div {
    flex: 0 0 50%;
    padding: 20px;
  }
`;

const ImageContainer = styled.div`
  > img {
    cursor: pointer;
  }
`;

const DataContainer = styled.div``;

const StatRow = styled(Body)``;

function MyContentUploadTile({ contentUpload }) {
  /** Return a tile for a user to view data of a content upload object that they uploaded.. */

  const [showContentUploadModal, hideContentUploadModal] = useModal(
    () => (
      <ContentUploadModal
        contentUpload={contentUpload}
        onHide={hideContentUploadModal}
      />
    ),
    [contentUpload]
  );

  const [
    showContentUploadDeleteModal,
    hideContentUploadDeleteModal,
  ] = useModal(
    () => (
      <ConfirmDeleteContentUploadModal
        contentUpload={contentUpload}
        onHide={hideContentUploadDeleteModal}
      />
    ),
    [contentUpload]
  );

  const {
    title,
    totalWatchTime,
    monthlyWatchTime,
    totalPlays,
    monthlyPlays,
    type,
  } = contentUpload;

  return (
    <Wrapper>
      <ImageContainer>
        <ContentUploadImage
          contentUpload={contentUpload}
          onClick={() => navigate(getContentUploadUrl(contentUpload))}
        />
        <ProjectCityButton
          block
          variant="secondary"
          className="my-2"
          onClick={showContentUploadModal}
        >
          Update
        </ProjectCityButton>
        {type === contentUploadType.animatic && (
          <ProjectCityButton
            block
            variant="danger"
            className="my-2"
            onClick={showContentUploadDeleteModal}
          >
            Delete
          </ProjectCityButton>
        )}
      </ImageContainer>
      <DataContainer>
        <SubHeaderText color="orange" tilt>
          {title}
        </SubHeaderText>
        <StatRow color="pink">
          Total plays: <b>{totalPlays}</b>
        </StatRow>
        <StatRow color="pink">
          Total watch time: <b>{secondsToTime(totalWatchTime)}</b>
        </StatRow>
        <StatRow color="pink" className="mt-2">
          Past 30 day plays: <b>{monthlyPlays}</b>
        </StatRow>
        <StatRow color="pink">
          Past 30 day watch time: <b>{secondsToTime(monthlyWatchTime)}</b>
        </StatRow>
      </DataContainer>
    </Wrapper>
  );
}

MyContentUploadTile.propTypes = {
  content: shape(ContentUpload),
};

export default MyContentUploadTile;
