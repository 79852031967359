import React from "react";
import styled from "styled-components";
import { bool, shape } from "prop-types";

import FireIcon from "images/fire.svg";
import { Notification as NotificationType } from "types";
import { getUserImage } from "utils/users";
import DividerImage from "../../images/divider.svg";
import NotificationContent from "../Content";

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: flex-start;

  ${(props) =>
    props.$withDivider &&
    `
    margin-top: 10px;
    padding-top: 20px;
    border: 3px solid black;
    border-image: url(${DividerImage}) 30 round;
    border-bottom: 0;
  `}
`;

const ImageWrapper = styled.div`
  margin-right: 10px;
  position: relative;
`;

const LikeFireIcon = styled.img`
  position: absolute;
  width: 25px;
  bottom: -7px;
  right: -3px;
`;

const StyledProfileIcon = styled.img`
  width: 60px;
  border-radius: 50%;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: 40px;
  }
`;

function Notification({ notification, withDivider }) {
  return (
    <Wrapper $withDivider={withDivider}>
      <ImageWrapper>
        {notification.action.verb === "liked" ? (
          <LikeFireIcon src={FireIcon} />
        ) : (
          ""
        )}
        <StyledProfileIcon src={getUserImage(notification.action.actor)} />
      </ImageWrapper>
      <NotificationContent notification={notification} />
    </Wrapper>
  );
}

Notification.propTypes = {
  /** The notification object that is being rendered. */
  notification: shape(NotificationType).isRequired,

  /** Determine if the notification should show a divider above it. */
  withDivider: bool,
};

Notification.defaultProps = {
  withDivider: false,
};

export default Notification;
