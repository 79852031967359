import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import styled from "styled-components";

import FormLabelTooltip from "components/tooltips/FormLabel";
import FieldFeedback from "components/forms/shared/FieldFeedback";
import FormLabel from "../FormLabel";

const LabelText = styled.span`
  flex-basis: 70%;
`;

function FormGroup({
  label,
  tooltip,
  tooltipText,
  errors,
  isCustomControl,
  ...props
}) {
  /** Standard component for rendering a full form group. */

  const tooltipComponent = tooltipText ? (
    <FormLabelTooltip text={tooltipText} iconProps={{ className: "mb-1" }} />
  ) : (
    tooltip
  );

  return (
    <Form.Group {...props}>
      {label && (
        <FormLabel>
          <LabelText className={errors ? "is-invalid" : ""}>
            {label} {tooltipComponent}
          </LabelText>
          <FieldFeedback
            field={errors}
            rules={
              props.children &&
              props.children.props &&
              props.children.props?.rules
            }
            isCustomControl={isCustomControl}
          />
        </FormLabel>
      )}
      {props.children}
    </Form.Group>
  );
}

FormGroup.propTypes = {
  /** The label to be displayed with the form group. */
  label: PropTypes.string,

  /** The error object that should be render if it exists. */
  errors: PropTypes.object,

  /** When we have the standard tooltip, can pass in just the text instead of component. */
  tooltipText: PropTypes.string,

  /** An optional tooltip icon to display by the label. */
  tooltip: PropTypes.object,

  /** Determine if the control is a non-bootstrap control, which requires manual styling. */
  isCustomControl: PropTypes.bool,
};

FormGroup.defaultProps = {
  tooltip: null,
  isCustomControl: false,
};

export default FormGroup;
