import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
`;

function ProjectDetailContent(props) {
  /** Provide a container to display the project detail content.  */
  return <Wrapper {...props}>{props.children}</Wrapper>;
}

export default ProjectDetailContent;
