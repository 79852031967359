import React from "react";
import { isMobile } from "react-device-detect";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";

import SecondaryLink from "components/navigation/SecondaryLink";
import SecondaryFullNavigation from "components/navbars/SecondaryFull";
import TokenIcon from "components/icons/Icon";
import ProjectCityButton from "components/buttons/Button";
import { useCanViewSprintSubscription } from "features/sprint/hooks";
import { contentUploadType } from "features/sprint/enums";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { usePrefetch } from "services/projectCity";
import SprintMobileNavigation from "../Mobile";
import { useModal } from "react-modal-hook";
import DonationHistoryModal from "features/donations/modals/History";
import AlertIcon from "images/icons/exclamation.svg";
import AnnualConversionModal from "features/subscriptions/modals/AnnualConversion";

const TokenButton = styled(ProjectCityButton)`
  padding-top: 0;
  padding-bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;

  > img {
    margin-left: 10px;
  }
`;

const StyledIcon = styled.img`
  width: 25px;
  margin-top: -3px;
`;

function ContentSecondaryNavigation() {
  /** Secondary navigation bar that should be shown for sprint content. */

  const canViewSprintContent = useCanViewSprintSubscription();

  const { user } = useSelector((state) => state.account);
  const isAuthenticated = useIsAuthenticated();
  const canView = useCanViewSprintSubscription();
  const { addToast } = useToasts();

  const [showDonationHistoryModal, hideDonationHistoryModal] = useModal(() => (
    <DonationHistoryModal onHide={hideDonationHistoryModal} />
  ));

  // Prefetch data when user hovers
  const prefetchLessons = usePrefetch("getContentUploadList");
  const prefetchVideos = usePrefetch("getContentUploadList");
  const prefetchSchedule = usePrefetch("getUploadSchedules");
  const prefetchAssignments = usePrefetch("getAssignmentList");
  const prefetchWinners = usePrefetch("getMonthlyWinnerList");

  const [showConversionModal, hideConversionModal] = useModal(() => (
    <AnnualConversionModal onHide={hideConversionModal} />
  ));

  let staticLinks = [
    {
      to: "/sprint/content",
      label: "Content",
      id: "sprint-content-navitem",
      onMouseEnter: canView
        ? () => prefetchLessons({ type: contentUploadType.lesson })
        : () => {},
    },
    {
      to: "/sprint/schedule",
      label: "Schedule",
      id: "sprint-schedule-navitem",
      onMouseEnter: () => prefetchSchedule(),
    },
    {
      to: "/sprint/winners",
      label: "Top 3",
      id: "sprint-winners-navitem",
      onMouseEnter: () => prefetchWinners(),
    },
    {
      to: "/sprint/assignments",
      label: "Assignments",
      id: "sprint-assignments-navitem",
      onMouseEnter: canView ? () => prefetchAssignments({}) : () => {},
    },
  ];

  if (canViewSprintContent) {
    staticLinks.push({
      as: "a",
      to: "",
      href: "https://discord.gg/DVYUH2unVx",
      target: "_blank",
      label: "Discord",
      id: "sprint-discord-navitem",
    });
  }

  const shouldRenderTokenButton = isAuthenticated && user?.tokenCount !== null;

  function renderTopLevelLinks() {
    // Subscribed users should have access to the discord.

    return staticLinks.map((props) => (
      <SecondaryLink key={props.label} {...props} />
    ));
  }

  function handleManageClick() {
    navigate("/sprint-management/videos");
  }

  function handleTokenButtonClick() {
    /** Either show the token management or subscription paywall */
    return canViewSprintContent
      ? showDonationHistoryModal()
      : addToast("You need a subscription to manage your tokens!", {
          appearance: "warning",
        });
  }

  function renderRightSection() {
    /** Show the user the management button if necessary. */
    const manageButton = canViewSprintContent ? (
      <ProjectCityButton
        noTilt
        size="sm"
        className="ml-3"
        onMouseEnter={() => prefetchVideos({ created_by: user.id })}
        onClick={handleManageClick}
      >
        Manage Content
      </ProjectCityButton>
    ) : null;

    return (
      <div className="text-right">
        {shouldRenderTokenButton && (
          <TokenButton noTilt size="sm" onClick={handleTokenButtonClick}>
            {user.tokenCount} <TokenIcon />
          </TokenButton>
        )}
        {manageButton}
      </div>
    );
  }

  function renderLeftButton() {
    /** Render a special button to convert to annual plan if valid for the user. */

    function scrollToSubscriptionInfo() {
      navigate("/sprint/content");
      const section = document.getElementById("subscription-info-section");
      if (section) section.scrollIntoView({ behavior: "smooth" });
    }

    // If the user already has an annual subscription, don't show anything.
    if (user?.subscription?.interval === "year") return null;

    // If the user has an active monthly subscription, show them the upgrade button.
    if (
      user?.subscription?.interval === "month" &&
      user.subscription.status === "active" &&
      !user.subscription.cancelAtPeriodEnd
    ) {
      return (
        <ProjectCityButton
          size="sm"
          noTilt
          variant="secondary"
          onClick={showConversionModal}
        >
          <StyledIcon src={AlertIcon} /> Upgrade to Annual
        </ProjectCityButton>
      );
    }

    // At this point, if the user can view sprint content they have a permission exception and
    // shouldn't see the button.
    return canViewSprintContent ? null : (
      <ProjectCityButton
        size="sm"
        noTilt
        variant="secondary"
        onClick={scrollToSubscriptionInfo}
      >
        <StyledIcon src={AlertIcon} /> New Annual Subscription
      </ProjectCityButton>
    );
  }

  return isMobile ? (
    <SprintMobileNavigation linkData={staticLinks} />
  ) : (
    <SecondaryFullNavigation
      label={renderLeftButton()}
      center={renderTopLevelLinks()}
      right={renderRightSection()}
    />
  );
}

export default ContentSecondaryNavigation;
