import React, { useState } from "react";
import { func, shape } from "prop-types";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Body from "components/text/Body";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import TextOnlyMessageForm from "components/forms/TextOnlyMessage";
import SubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormConfirmDangerButton from "components/buttons/forms/FormConfirmDanger";
import ModalActionsListGroup from "components/listGroups/ModalActions";
import { deleteTextOnlyMessage } from "features/textOnly/thunks";
import { TextOnlyMessage } from "types";

const StyledBaseModal = styled(BaseModal)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0px ${(props) => props.theme.vSpacingXs};
  }
`;

function TextOnlyActions({ message, ...props }) {
  /** Provide actions to take on a text only message object. */

  const [modalSection, setModalSection] = useState("actions");
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  function renderActionsSection() {
    /** The main section that allows the user to choose which action to perform.  */
    return (
      <ModalActionsListGroup>
        <ListGroup.Item onClick={() => setModalSection("editMessage")}>
          <Body inline>
            <FontAwesomeIcon icon={faPencilAlt} /> Edit Message
          </Body>
        </ListGroup.Item>
        <ListGroup.Item onClick={() => setModalSection("removeMessage")}>
          <Body inline>
            <FontAwesomeIcon icon={faTrashAlt} /> Remove Message
          </Body>
        </ListGroup.Item>
        <ListGroup.Item onClick={props.onHide}>
          <Body inline>
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </Body>
        </ListGroup.Item>
      </ModalActionsListGroup>
    );
  }

  function renderEditMessageSection() {
    /** Allow the user to edit the text only message. */
    return <TextOnlyMessageForm message={message} closeModal={props.onHide} />;
  }

  function renderDeleteMessageSection() {
    /**
     * Section to display when the user chooses the delete action.
     *
     * Here we will show the user a confirm section where they can either confirm they
     * want to delete the text only message, or they can cancel their action instead.
     */
    return (
      <div>
        <Body>
          Are you sure you want to <b>permanently</b> delete this message?
        </Body>
        <SubmitContainer withTopDivider>
          <FormSecondaryButton onClick={() => setModalSection("actions")}>
            <FontAwesomeIcon icon={faChevronLeft} /> Back
          </FormSecondaryButton>
          <FormConfirmDangerButton onClick={handleDelete}>
            Yes, delete
          </FormConfirmDangerButton>
        </SubmitContainer>
      </div>
    );
  }

  function getModalTitle() {
    /** Return the modal title that should be displayed in the modal. */
    switch (modalSection) {
      case "editMessage":
        return "Update message";
      case "removeMessage":
        return "Delete message";
      default:
        return null;
    }
  }

  function handleDelete() {
    /** Perform the delete action on the message object. */
    dispatch(deleteTextOnlyMessage({ textOnlyId: message.id }));
    addToast("Message deleted", { appearance: "success" });
    props.onHide();
  }

  function renderSection() {
    /**
     * Choose which section to render based on the component state.
     *
     * There are various sections that can be displayed based on which action we choose.
     * We have these different sections to allow the user to go back to the actions
     * menu without closing and reopening a new modal.
     */
    switch (modalSection) {
      case "actions":
        return renderActionsSection();
      case "editMessage":
        return renderEditMessageSection();
      case "removeMessage":
        return renderDeleteMessageSection();
      default:
        return renderActionsSection();
    }
  }

  return (
    <StyledBaseModal {...props}>
      <ModalHeader title={getModalTitle()} />
      <Modal.Body className="p-0">{renderSection()}</Modal.Body>
    </StyledBaseModal>
  );
}

TextOnlyActions.propTypes = {
  // The message object that is to be rendered to have actions performed.
  message: shape(TextOnlyMessage).isRequired,

  /** An action to close the modal that the actions are displayed in. */
  onHide: func.isRequired,
};

export default TextOnlyActions;
