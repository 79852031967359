import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import FormControl from "react-bootstrap/FormControl";
import Select from "components/controls/Select";
import { contentUploadType } from "features/sprint/enums";
import { setSprintFilterParams } from "features/sprint/slice";
import { useGetCategoryListQuery } from "services/projectCity";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const TitleControl = styled(FormControl)`
  color: ${(props) => props.theme.yellow};
`;

function SprintFilterBar() {
  /** Shared filter bar that goes on all sprint content routes and allows selecting filters. */

  const { data: categories } = useGetCategoryListQuery();
  const { filters } = useSelector((state) => state.contentUploads);
  const dispatch = useDispatch();

  const orderChoices = [
    { value: "-created", label: "Most Recent" },
    { value: "-total_views", label: "Most Viewed" },
    { value: "-like_count", label: "Most Liked" },
    { value: "-tokens_received", label: "Most Donated" },
  ];

  const contentUploadTypeChoices = [
    { value: null, label: "All" },
    { value: contentUploadType.lesson, label: "Lessons" },
    { value: contentUploadType.animatic, label: "Animatics" },
  ];

  const categoryChoices = categories
    ? categories.map((category) => ({
        value: category.id,
        label: category.title,
      }))
    : [];

  function handleOrderChange(choice) {
    dispatch(setSprintFilterParams({ ...filters, ordering: choice.value }));
  }

  function handleCategoryChange(choices) {
    if (!choices.length) {
      // Clear the category filter.
      let newFilters = { ...filters };
      delete newFilters["categories"];
      dispatch(setSprintFilterParams(newFilters));
    } else {
      // Set a new category value.
      dispatch(
        setSprintFilterParams({
          ...filters,
          categories: choices.map((choice) => choice.value),
        })
      );
    }
  }

  const debouncedSearch = debounce(async (value) => {
    let newParams = { ...filters, search: value };
    dispatch(setSprintFilterParams(newParams));
  }, 700);

  function handleTypeChange(choice) {
    // Remove the type param if we're setting to "All".
    let newParams = { ...filters, type: choice.value };
    if (newParams.type === null) delete newParams.type;
    dispatch(setSprintFilterParams(newParams));
  }

  function handleSearchChange(e) {
    /** Debounce the change of title. */
    debouncedSearch(e.target.value);
  }

  return (
    <Wrapper>
      <TitleControl placeholder="Search Videos" onChange={handleSearchChange} />
      <StyledSelect
        options={contentUploadTypeChoices}
        value={
          contentUploadTypeChoices.find(
            (typeObject) => typeObject.value === filters.type
          ) || contentUploadTypeChoices[0]
        }
        onChange={handleTypeChange}
      />
      <StyledSelect
        options={categoryChoices}
        placeholder="Category"
        onChange={handleCategoryChange}
        defaultValue={categoryChoices.find(
          (categoryChoice) => categoryChoice.value === filters.categories
        )}
        isClearable
        isMulti
        closeMenuOnSelect={false}
      />
      <StyledSelect
        options={orderChoices}
        value={orderChoices.find((order) => order.value === filters.ordering)}
        onChange={handleOrderChange}
      />
    </Wrapper>
  );
}

export default SprintFilterBar;
