import React, { useState } from "react";
import { func, shape } from "prop-types";
import { ListGroupItem } from "react-bootstrap";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import ModalActionsListGroup from "components/listGroups/ModalActions";
import BaseModal from "components/modals/Base";
import ModalSummaryText from "components/text/ModalSummaryText";
import ConfirmActionModal from "components/modals/ConfirmAction";
import FormControl from "components/forms/shared/Control";
import FormGroup from "components/forms/shared/FormGroup";
import EnrollmentService from "features/enrollments/service";
import { Enrollment } from "features/enrollments/types";
import { refundEnrollment } from "features/enrollments/thunks";
import UserBlock from "features/projects/components/UserBlock";
import { enrollmentStatus } from "utils/enums";

const StyledListGroupItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFormGroup = styled(FormGroup)`
  span {
    flex-basis: 100%;
    text-align: left;
  }
`;

function EnrollmentActionsModal({ enrollment, ...props }) {
  /** Select an action to take on an enrollment. */
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isRefunding, setIsRefunding] = useState(false);
  const [refundAmount, setRefundAmount] = useState(enrollment.amount / 100);
  const [confirmDeleteError, setConfirmDeleteError] = useState();
  const [isSendingInvoice, setIsSendingInvoice] = useState(false);
  const [sendInvoiceErrorMessage, setSendInvoiceErrorMessage] = useState();

  const [showSendInvoiceModal] = useModal(() => {
    const enrollmentService = new EnrollmentService();

    async function _sendInvoice() {
      setSendInvoiceErrorMessage();
      setIsSendingInvoice(true);

      try {
        await enrollmentService.sendInvoice(enrollment.id);
        props.onHide();
        addToast("Invoice has been sent.", { appearance: "success" });
      } catch (err) {
        setSendInvoiceErrorMessage("Error sending invoice.");
      }

      setIsSendingInvoice(false);
    }

    return (
      <ConfirmActionModal
        onHide={props.onHide}
        title="Send Invoice"
        confirmText="Ok, send"
        confirmIcon={faPaperPlane}
        errorMessage={sendInvoiceErrorMessage}
        confirmAction={_sendInvoice}
        isLoading={isSendingInvoice}
      >
        <ModalSummaryText className="text-center">
          <b>{enrollment.student.name}</b> will receive an invoice for
          <b>&nbsp;${enrollment.registrationTier.price}</b>. Once paid, their
          enrollment will become active.
        </ModalSummaryText>
      </ConfirmActionModal>
    );
  }, [sendInvoiceErrorMessage, isSendingInvoice]);

  const [showRefundConfirmationModal] = useModal(() => {
    // Shows the confirmation modal to confirm refunding a student.

    async function handleConfirmRefund() {
      setIsRefunding(true);
      setConfirmDeleteError(null);

      const action = await dispatch(
        refundEnrollment({
          enrollmentId: enrollment.id,
          payload: {
            amount: refundAmount * 100,
          },
        })
      );
      if (action.type === "REFUND_ENROLLMENT/rejected") {
        setConfirmDeleteError(
          action.payload?.data?.detail || "Error refunding student"
        );
      } else if (action.type === "REFUND_ENROLLMENT/fulfilled") {
        props.onHide();
        addToast("Refund successful", {
          autoDismiss: true,
          appearance: "success",
        });
      }

      setIsRefunding(false);
    }

    return (
      <ConfirmActionModal
        isDanger
        onHide={props.onHide}
        confirmAction={handleConfirmRefund}
        isLoading={isRefunding}
        errorMessage={confirmDeleteError}
        title="Confirm Refund"
        confirmText="Yes, refund"
        confirmIcon={faHandHoldingUsd}
      >
        <div className="text-center">
          <UserBlock user={enrollment.student} className="mb-4" />
          <StyledFormGroup label="Refund amount" style={{ flexBasis: "100%" }}>
            <FormControl
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value)}
              type="number"
              step="0.01"
            />
          </StyledFormGroup>
          <ModalSummaryText className="mt-4">
            Are you sure you want to refund <b>{enrollment.student.name}</b>?
          </ModalSummaryText>
        </div>
      </ConfirmActionModal>
    );
  }, [isRefunding, confirmDeleteError, refundAmount]);

  function refundStudent() {
    if (enrollment.status !== enrollmentStatus.refunded) {
      showRefundConfirmationModal();
    }
  }

  return (
    <BaseModal {...props}>
      <ModalActionsListGroup>
        <StyledListGroupItem onClick={refundStudent}>
          Refund <FontAwesomeIcon icon={faHandHoldingUsd} />
        </StyledListGroupItem>
        <StyledListGroupItem onClick={showSendInvoiceModal}>
          Send Invoice <FontAwesomeIcon icon={faPaperPlane} />
        </StyledListGroupItem>
      </ModalActionsListGroup>
    </BaseModal>
  );
}

EnrollmentActionsModal.propTypes = {
  /** The enrollment that we're selecting an action for. */
  enrollment: shape(Enrollment).isRequired,

  /** Action to close the modal. */
  onHide: func.isRequired,
};

export default EnrollmentActionsModal;
