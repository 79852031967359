import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import styled from "styled-components";

import Loading from "components/loading/Loading";
import BaseModal from "components/modals/Base";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import { deleteProject } from "features/projects/thunks";
import { projectDeleted } from "features/accounts/slice";
import FormConfirmDangerButton from "components/buttons/forms/FormConfirmDanger";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";

const StyledModalBody = styled(Modal.Body)`
  color: ${(props) => props.theme.yellow};
  font-size: 21px;
`;

function DeleteProjectModal({ project, ...props }) {
  // Confirm deletion of a project.
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const { username } = useSelector((state) => state.account.user);
  async function onDeleteConfirm() {
    setIsLoading(true);
    try {
      const action = await dispatch(deleteProject(project.id));
      if (action.type === "DELETE_PROJECT/rejected") {
        if (action.payload.status === 400) {
          addToast(`${action.payload.data}`, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast(
            `Error occurred while deleting a project with status : ${action.payload.status}`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      } else if (action.type === "DELETE_PROJECT/fulfilled") {
        // if the project is deleted successfully delete this project from
        // logged in user projects
        dispatch(projectDeleted());
        navigate(`/users/${username}`);
      }
    } catch (err) {
      addToast(`Error occurred while deleting a project : ${err}`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setIsLoading(false);
    props.onHide();
  }

  return (
    <BaseModal {...props} size="md">
      <StyledModalBody>
        Deleting this project <b>{project.title}</b> is permanent. Are you sure
        you want to delete it?
        <FormSubmitContainer withTopDivider>
          <FormSecondaryButton onClick={props.onHide}>
            Cancel
          </FormSecondaryButton>
          <FormConfirmDangerButton onClick={onDeleteConfirm}>
            {isLoading ? <Loading size="sm" /> : "Delete"}
          </FormConfirmDangerButton>
        </FormSubmitContainer>
      </StyledModalBody>
    </BaseModal>
  );
}

DeleteProjectModal.propTypes = {
  // The project object that we're confirming deletion.
  project: PropTypes.object.isRequired,
};

export default DeleteProjectModal;
