import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";

import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormGroup from "components/forms/shared/FormGroup";
import BaseForm from "components/forms/Base";
import AccountService from "features/accounts/service";

function EmailVerification({ closeModal }) {
  const [formErrorText, setFormErrorText] = useState();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm();
  const { addToast } = useToasts();
  const service = new AccountService();

  const onSubmit = async (data) => {
    try {
      await service.resendVerification(data);
      closeModal();
      addToast("verification link has been sent to your email.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      // Display the error message on the form.
      if (err.response.status === 400) {
        setFormErrorText(err.response.data.message);
      } else if (err.response.status === 400) {
        setFormErrorText(err.response.data.message);
      } else {
        setFormErrorText("there is no user with this email, register please!");
      }
    }
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Email" errors={errors.email}>
        <Controller
          as={FormControl}
          rules={{
            required: {
              value: true,
              message: "Email is required",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          name="email"
          control={control}
          defaultValue=""
          isInvalid={errors.email !== undefined}
        />
      </FormGroup>
      <FormSubmitContainer errorText={formErrorText}>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

EmailVerification.propTypes = {
  closeModal: PropTypes.func,
};

export default EmailVerification;
