import React from "react";
import styled from "styled-components";

import BorderContainer from "../Border";

const StyledBorderContainer = styled(BorderContainer)`
  padding: ${(props) => props.theme.vSpacingSm};

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    max-width: 320px;
    float: right;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin: ${(props) => props.theme.vSpacingMd} 0px;
  }
`;

function SettingsContainer(props) {
  return <StyledBorderContainer>{props.children}</StyledBorderContainer>;
}

export default SettingsContainer;
