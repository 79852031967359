import { ProjectContentUpload } from "features/sprint/types";
import { arrayOf, shape } from "prop-types";
import styled from "styled-components";

import ContentUploadTile from "../ContentUploadTile";

const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  margin: ${(props) => props.theme.vSpacingLg} 0;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

function ContentUploadList({ contentUploads }) {
  /** List of content uploads that are shown on a project page. */

  function renderTiles() {
    return contentUploads.map((contentUpload, index) => (
      <ContentUploadTile
        key={contentUpload.id}
        allowChooseInSprint
        contentUpload={contentUpload}
        isEven={index % 2 === 0}
      />
    ));
  }

  return <Wrapper>{renderTiles()}</Wrapper>;
}

ContentUploadList.propTypes = {
  contentUploads: arrayOf(shape(ProjectContentUpload)),
};

ContentUploadList.defaultProps = {
  contentUploads: [],
};

export default ContentUploadList;
