import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";

import ImageStackCarousel from "components/carousels/ImageStack";
import PdfIcon from "components/posts/PostVisualDisplay/assets/btn_pdf.svg";
import ZipIcon from "images/icons/zip-icon.png";
import { BucketUpload } from "types";
import { bucketUploadType } from "utils/enums";
import Video from "components/videos/Video";

const StyleFileImg = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

function BucketUploadModalDisplay({ bucketUpload }) {
  /**
   * Return the content that should be displayed in the modal, which depends on the
   * upload type.
   */

  function openPdf(e) {
    e.stopPropagation();

    // Opens the pdf file in new tab.
    const newWindow = window.open(
      bucketUpload.uploadFile,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  }

  function downloadFile(e) {
    e.stopPropagation();
    const a = document.createElement("a");
    const chunks = bucketUpload.uploadFile.split(/.\//);
    a.href = bucketUpload.uploadFile;
    a.setAttribute("download", chunks[chunks.length - 1]);
    a.click();
  }

  function renderDisplayContent() {
    switch (bucketUpload.kind) {
      case bucketUploadType.imageStack:
      case bucketUploadType.webComic:
        return <ImageStackCarousel bucketUpload={bucketUpload} />;
      case bucketUploadType.video:
        return (
          <Video autoPlay>
            <source src={bucketUpload.uploadFile} type="video/mp4" />
          </Video>
        );
      case bucketUploadType.pdf:
        return (
          <StyleFileImg
            src={PdfIcon}
            alt=""
            style={{ width: "30%" }}
            onClick={openPdf}
            className="m-5"
          />
        );
      case bucketUploadType.audio:
        return (
          <audio controls className="m-5">
            <source src={bucketUpload.uploadFile} type="audio/mpeg" />
          </audio>
        );
      case bucketUploadType.zip:
        return (
          <StyleFileImg
            src={ZipIcon}
            alt=""
            style={{ width: "30%" }}
            onClick={downloadFile}
            className="m-5"
          />
        );

      default:
        return null;
    }
  }

  return renderDisplayContent();
}

BucketUploadModalDisplay.propTypes = {
  bucketUpload: shape(BucketUpload).isRequired,
};

export default BucketUploadModalDisplay;
