import { navigate } from "@reach/router";
import ProjectCityButton from "components/buttons/Button";
import Body from "components/text/Body";
import Header from "components/text/Header";
import { string } from "prop-types";

function SubscriptionRequiredContainer({ body }) {
  return (
    <div>
      <div className="text-center">
        <Header top="Subscription" bottom="required" />
      </div>
      <div className="mt-5 text-center">
        {body && <Body>{body}</Body>}
        <ProjectCityButton
          variant="info"
          className="mt-3"
          onClick={() => navigate("/sprint/content")}
        >
          Learn More
        </ProjectCityButton>
      </div>
    </div>
  );
}

SubscriptionRequiredContainer.propTypes = {
  body: string,
};

SubscriptionRequiredContainer.defaultProps = {
  body: null,
};

export default SubscriptionRequiredContainer;
