import { useSelector } from "react-redux";

import { isProjectCityAdmin } from "utils/permissions";

function useIsProjectCityAdmin() {
  /** Determine if the user is an admin of ProjectCity. */
  const user = useSelector((state) => state.account.user);

  return isProjectCityAdmin(user);
}

export default useIsProjectCityAdmin;
