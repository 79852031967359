import React from "react";
import styled from "styled-components";

import theme from "theme";
import { TextTypes } from "types";

const StyledCaption = styled.h6`
  font-family: "Basis Grotesque Mono";
  color: ${(props) => theme[props.color]};

  ${(props) => props.inline && "display: inline;"}

  ${(props) =>
    props.size === "sm"
      ? `
    font-size: 15px;
    line-height: 17px;
  `
      : `
    font-size: 18px;
    line-height: 24px;
  `};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    ${(props) =>
      props.size === "sm"
        ? `
      font-size: 14px;
      line-height: 16px;
    `
        : `
      font-size: 15px;
      line-height: 20px;
    `};
  }
`;

function Caption({ children, ...props }) {
  /** A caption of text to display with other items. */

  return <StyledCaption {...props}>{children}</StyledCaption>;
}

Caption.propTypes = TextTypes;

Caption.defaultProps = {
  size: "md",
  color: "dark",
  inline: false,
};

export default Caption;
