import React from "react";
import { oneOf, string } from "prop-types";
import styled from "styled-components";

import EditIconComponent from "components/images/EditIcon";
import DeleteIconComponent from "components/icons/Delete";

const Wrapper = styled.div`
  cursor: pointer;
`;

function ModifyButton({ bottom, type, ...props }) {
  /** General button to modify data, expecting the `onClick` to determine the action. */

  function renderIcon() {
    switch (type) {
      case "create":
      case "update":
      default:
        return <EditIconComponent dimension={56} />;
      case "delete":
        return <DeleteIconComponent dimension={56} />;
    }
  }

  return <Wrapper {...props}>{renderIcon()}</Wrapper>;
}

ModifyButton.propTypes = {
  /** Absolute value for the bottom of the component. */
  bottom: string,

  /** Action type determines the icon that is shown. */
  type: oneOf(["create", "update", "delete"]),
};

ModifyButton.defaultProps = {
  type: "update",
};

export default ModifyButton;
