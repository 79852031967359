import React from "react";

import SecondaryLink from "components/navigation/SecondaryLink";
import SecondaryFullNavigation from "components/navbars/SecondaryFull";
import useGetClassLinks from "features/classes/hooks/ClassLinks";

function ClassSecondaryNavigation() {
  /** Secondary navigation bar that should be shown in the classes section. */

  const classLinks = useGetClassLinks();

  function renderTopLevelLinks() {
    return classLinks.map((props) => (
      <SecondaryLink key={props.label} {...props} />
    ));
  }

  return <SecondaryFullNavigation center={renderTopLevelLinks()} />;
}

export default ClassSecondaryNavigation;
