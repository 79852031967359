import { func } from "prop-types";
import { useState } from "react";
import styled from "styled-components";

import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import BigCheckbox from "components/controls/BigCheckbox";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import Body from "components/text/Body";
import ModalSummaryText from "components/text/ModalSummaryText";
import { useDonationAgreementMutation } from "services/projectCity";
import { useDispatch } from "react-redux";
import { updateUser } from "features/accounts/slice";

const CheckboxWrapper = styled.div`
  cursor: pointer;
`;

function DonationAgreementModal({ followAction, ...props }) {
  /** The user needs to make a slight agreement before being able to make donations to animatics. */

  const [isChecked, setIsChecked] = useState(false);
  const [agree] = useDonationAgreementMutation();
  const dispatch = useDispatch();

  function handleContinue() {
    props.onHide();
    followAction();
    agree();
    dispatch(updateUser({ hasDonationAgreement: true }));
  }

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        closeButton
        closeModal={props.onHide}
        title="Donation Agreement"
      />

      <ModalSummaryText>
        Animatic donations is a beta feature we're implementing to help support
        your favorite animatics. At this time we will not be giving out refunds
        for any token purchases.
      </ModalSummaryText>
      <ModalSummaryText className="mt-2">
        When you donate, 70% will support the animatic creator and 30% will
        support the Project City platform.
      </ModalSummaryText>
      <FormSubmitContainer>
        <CheckboxWrapper onClick={() => setIsChecked(!isChecked)}>
          <BigCheckbox checked={isChecked} />{" "}
          <Body className="ml-1 d-inline">I understand and agree</Body>
        </CheckboxWrapper>
        <FormPrimaryButton disabled={!isChecked} onClick={handleContinue}>
          Continue
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseModal>
  );
}

DonationAgreementModal.propTypes = {
  // Action to trigger after agreeing to the terms.
  followAction: func.isRequired,
};

export default DonationAgreementModal;
