import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { shape } from "prop-types";

import { Assignment } from "features/assignments/types";
import AssignmentEntryModal from "features/assignments/modals/AssignmentEntry";
import UploadImage from "images/buttons/upload.png";
import useFeedShare from "hooks/FeedShare";

const StyledUploadImage = styled.img`
  width: 150px;
  cursor: pointer;
  position: absolute;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    top: -30px;
    left: -120px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    width: 50%;
    position: relative;
    margin-top: 50px;
  }
`;

function AssignmentEntryUploadIcon({ assignment, ...props }) {
  /** Begin the upload process for assignment entries.. */

  const [createdAssignmentEntry, setCreatedAssignmentEntry] = useState(null);
  const showConfirmShareModal = useFeedShare(
    "assignmententry",
    "Assignment entry submitted!",
    createdAssignmentEntry && createdAssignmentEntry.id
  );

  function handleAfterCreate(assignmentEntry, shareToGlobalFeed) {
    setCreatedAssignmentEntry(assignmentEntry);
    if (shareToGlobalFeed) showConfirmShareModal();
  }

  const [showAssignmentEntryModal, hideAssignmentEntryModal] = useModal(() => (
    <AssignmentEntryModal
      onHide={hideAssignmentEntryModal}
      assignmentId={assignment.id}
      afterCreate={handleAfterCreate}
    />
  ));

  return (
    <StyledUploadImage
      src={UploadImage}
      onClick={showAssignmentEntryModal}
      {...props}
    />
  );
}

AssignmentEntryUploadIcon.propTypes = {
  /** The assignment that we will create an entry for. */
  assignment: shape(Assignment).isRequired,
};

export default AssignmentEntryUploadIcon;
