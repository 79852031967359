import React, { useState } from "react";

import FormButton from "../Base";

function FormSecondaryButton({ children, ...props }) {
  /** Primary button that is generally used to submit a form. */

  // Change the variant depending on if the user is hovered over the button.
  const [isHovered, setIsHovered] = useState(false);
  const variant = isHovered ? "outline-secondary" : "outline-primary";

  return (
    <FormButton
      variant={variant}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      {children}
    </FormButton>
  );
}

export default FormSecondaryButton;
