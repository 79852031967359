import BaseTour from "components/tours/Base";
import { tourType } from "features/tours/enums";

function NewUserTour() {
  /** Show a new user some introduction to our main site features. */
  const steps = [
    {
      target: "#classes-navitem",
      disableBeacon: true,
      content: "Join classes and learn from experienced industry pros",
    },
    {
      target: "#projects-navitem",
      content:
        "See what projects community members are working on, and even create your own",
    },
    {
      target: "#sprint-navitem",
      content:
        "Daily live and recorded lessons for subscribers, community shared animatics, and monthly contests",
    },
    {
      target: "#feed-navitem",
      content:
        "Check out your classmates homework and project uploads - and share your own",
    },
  ];

  return <BaseTour steps={steps} tourType={tourType.newUser} />;
}

export default NewUserTour;
