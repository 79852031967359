import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";

import ModalSummaryText from "components/text/ModalSummaryText";
import BaseModal from "../Base";
import ModalHeader from "../Header";
import Checkbox from "components/controls/Checkbox";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import { agreeTos } from "features/users/thunks";

const CheckboxContainer = styled.div`
  margin-top: ${(props) => props.theme.vSpacingMd};
`;

function NewAgreementModal(props) {
  /** Modal for the user to agree to a new modal. */
  const [isChecked, setIsChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  async function handleAgreeTos() {
    setIsLoading(true);
    const action = await dispatch(agreeTos());

    if (action.type.includes("fulfilled")) {
      props.onHide();
      addToast("You're all set!", { appearance: "success" });
    } else {
      addToast("There was an error agreeing to the new Terms", {
        appearance: "error",
      });
    }
    setIsLoading(false);
  }

  return (
    <BaseModal {...props} size="lg">
      <ModalHeader title="Updated User Agreement" />
      <Modal.Body>
        <ModalSummaryText>
          We have updated the{" "}
          <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
          , please take a look and agree to the new terms of service to continue
          using the site.
        </ModalSummaryText>
        <CheckboxContainer>
          <Checkbox
            value={isChecked}
            defaultChecked={false}
            onClick={() => setIsChecked(!isChecked)}
            label="I agree to the updated terms of use"
          />
        </CheckboxContainer>
        <FormSubmitContainer>
          <FormPrimaryButton
            disabled={!isChecked || isLoading}
            onClick={handleAgreeTos}
          >
            I agree
          </FormPrimaryButton>
        </FormSubmitContainer>
      </Modal.Body>
    </BaseModal>
  );
}

export default NewAgreementModal;
