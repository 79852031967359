import { useSelector } from "react-redux";

import { isProjectAdmin } from "utils/projects";

function useIsProjectAdmin() {
  /** Determine if the user is currently authenticated. */
  const project = useSelector((state) => state.projects.detail);
  const user = useSelector((state) => state.account.user);

  return isProjectAdmin(user, project);
}

export default useIsProjectAdmin;
