import React, { useState } from "react";
import { shape } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Select from "components/controls/Select";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";

import StripeConnectButton from "components/buttons/StripeConnect";
import { countriesListChoices } from "utils/constants";
import { Project } from "types";

function ConnectPreviewModal({ project, ...props }) {
  /** Modal to make data selections before beginning the stripe connect process. */
  const [country, setCountry] = useState(null);

  return (
    <BaseModal {...props}>
      <ModalHeader
        title="Choose your country"
        closeButton
        closeModal={props.onHide}
      />
      <ModalSummaryText className="mb-3">
        Please choose your country before continuing with the Connect process.
      </ModalSummaryText>
      <Select
        options={countriesListChoices}
        onChange={(selectedOption) => setCountry(selectedOption)}
        value={country}
      />
      <FormSubmitContainer>
        <StripeConnectButton project={project} country={country}>
          Connect Bank Account <FontAwesomeIcon icon={faExternalLinkAlt} />
        </StripeConnectButton>
      </FormSubmitContainer>
    </BaseModal>
  );
}
ConnectPreviewModal.propTypes = {
  /** The project that is being used to link to. */
  project: shape(Project).isRequired,
};

export default ConnectPreviewModal;
