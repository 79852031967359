import React from "react";
import { shape, string } from "prop-types";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import AutoRegistrationButton from "components/buttons/AutoRegistration";
import Button from "components/buttons/Button";
import StripeCheckout from "components/payments/StripeCheckout";
import Body from "components/text/Body";
import { RegistrationTier } from "types";
import { getProjectBaseUrl, isClass } from "utils/projects";

function RegistrationButton({ tier, variant }) {
  /** Render the registration button based on the tier data. */

  const project = useSelector((state) => state.projects.detail);

  const isDisabled =
    moment(tier.registrationEndDate).isBefore() ||
    moment(tier.registrationStartDate).isAfter() ||
    !tier.hasAvailability;

  // Take the variant passed in unless it's disabled.
  function getButtonText() {
    if (moment(tier.registrationEndDate).isBefore()) return "Closed";
    else if (moment(tier.registrationStartDate).isAfter()) return "Coming soon";
    else if (!tier.hasAvailability) return "Sold out";
    else return isClass(project) ? "BUY NOW" : "Back Now";
  }

  if (tier.price === null || parseFloat(tier.price) === 0.0) {
    // For free tiers, we need to assign the tier directly instead of asking for payment.
    return <AutoRegistrationButton registrationTier={tier} variant={variant} />;
  }

  if (!tier.canReceivePayments) {
    // Only show the admins the button if it can't receive payments.
    const paymentsUrl = `${getProjectBaseUrl(project)}/manage/payments`;
    return (
      <Button onClick={() => navigate(paymentsUrl)}>
        <FontAwesomeIcon icon={faExclamationCircle} /> Invalid payment setup
      </Button>
    );
  }

  return (
    <StripeCheckout
      tier={tier}
      disabled={isDisabled}
      variant={variant === "primary" ? "info" : variant}
      block
      startTilt
      isWavy
    >
      <Body
        bold
        noMargin
        color={variant === "secondary" ? "neonPink" : "yellow"}
      >
        {getButtonText()}
      </Body>
    </StripeCheckout>
  );
}

RegistrationButton.propTypes = {
  /** The tier that we're rending the registration button for. */
  tier: shape(RegistrationTier).isRequired,

  /** The registration button can have different types of style variants. */
  variant: string.isRequired,
};

export default RegistrationButton;
