import { array, shape, string } from "prop-types";
import React from "react";
import styled from "styled-components";
import reactStringReplace from "react-string-replace";
import { Link } from "@reach/router";

import Body from "components/text/Body";
import { User } from "types";

const MentionLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.success};
  }
`;

const StyledAuthor = styled(Body)`
  color: ${(props) => props.theme.pink};
  margin-right: 5px;
  font-weight: bold;
  padding-bottom: 2px;
`;

function CommentTextFull({ author, text, mentions }) {
  /** Render the full comment with author for a comment or a reply. */

  function renderText() {
    /**
     * Replace any mentions with a link to the user's profile, and proper
     * representation.
     */
    let renderedText = text;
    mentions.forEach((mention) => {
      renderedText = reactStringReplace(
        renderedText,
        `<@${mention.id}>`,
        () => (
          <MentionLink to={`/users/${mention.username}`}>
            @{mention.username}
          </MentionLink>
        )
      );
    });

    // Both author and text share some props.
    const textProps = {
      inline: true,
      size: "sm",
      color: "pink",
    };

    return (
      <>
        <StyledAuthor mono {...textProps}>
          {author.username}
        </StyledAuthor>
        <Body {...textProps}>{renderedText}</Body>
      </>
    );
  }

  return renderText();
}

CommentTextFull.propTypes = {
  user: shape(User),
  text: string.isRequired,
  mentions: array,
};

CommentTextFull.defaultProps = {
  mentions: [],
};

export default CommentTextFull;
