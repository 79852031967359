import { shape } from "prop-types";

import SquareMask from "images/masks/square.svg";
import { Assignment } from "features/assignments/types";
import MaskImage from "components/images/Mask";
import Default from "images/turtle-pencil.png";

function AssignmentImage({ assignment, ...props }) {
  /** Thumbnail image for a assignment. */

  return (
    <MaskImage
      mask={SquareMask}
      {...props}
      src={assignment.thumbnail || Default}
    />
  );
}

AssignmentImage.propTypes = {
  /** The assignment whose image is being shown. */
  assignment: shape(Assignment),
};

export default AssignmentImage;
