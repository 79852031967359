import React, { useEffect, useState } from "react";
import { func, shape } from "prop-types";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";

import RevenueSplitForm from "components/forms/RevenueSplit";
import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import RevenueSplitConfirmDelete from "components/revenueSplits/ConfirmDeleteSection";
import RevenueSplitContainer from "components/revenueSplits/Container";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormDangerButton from "components/buttons/forms/FormConfirmDanger";
import Caption from "components/text/Caption";
import RegistrationTierForm from "features/tiers/forms/RegistrationTier";
import TierDescriptionForm from "features/tierDescriptions/forms/TierDescription";
import { deleteRegistrationTier } from "features/tiers/thunks";
import { RegistrationTier } from "types";
import TierDescriptionContainer from "./DescriptionSection";
import projectCityApi from "services/projectCity";
import LoadingContainer from "components/loading/Container";

const StyledModalHeader = styled(ModalHeader)`
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 22px;
  letter-spacing: 0.06em;
  color: ${(props) => props.theme.yellow};
  transform: matrix(1, 0.03, -0.06, 1, 0, 0);

  & .modal-title {
    text-align: start;
    margin-top: 1rem;
  }

  & .close {
    color: ${(props) => props.theme.blue};
  }
`;

const StyledBaseModal = styled(BaseModal)`
  font-size: 21px;

  & .modal-dialog {
    max-width: 1000px;
  }
`;

const RegistrationTierModal = ({ registrationTier, ...props }) => {
  /* The registration modal is a multi-container modal and is the most complicated in the whole
     project. There are many things accomplished in this custom modal including:

    1) Editing the registration tier
    2) Deleting the registration tier
    **
    3) Viewing the revenue splits
    4) Editing a revenue split
    5) Deleting a revenue split
    **
    6) Viewing the tier descriptions
    7) Editing a tier description
    8) Deleting a tier description

    # General component structure within the modal
    ---
    RegistrationTierModal
      RevenueSplitForm
      RevenueSplitsTable
        RevenueSplitRow
        RevenueSplitActionButton
    TierDescriptionForm
      TierDescriptionTable
        TierDescriptionRow
        TierDescriptionActionButton
  */

  // Content section determines the section of the modal that should be shown.
  const [contentSection, setContentSection] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // When a new tier is created, we can activate it.
  const [activeRegistrationTier, setActiveRegistrationTier] = useState(
    registrationTier
  );

  const [selectedRevenueSplit, setSelectedRevenueSplit] = useState();
  const [selectedDescription, setSelectedDescription] = useState();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const project = useSelector((state) => state.projects.detail);
  const user = useSelector((state) => state.account.user);

  const [
    fetchRevenueSplits,
    {
      isLoading: isLoadingSplits,
      isFetching: isFetchingSplits,
      data: revenueSplits,
    },
  ] = projectCityApi.endpoints.getRevenueSplitList.useLazyQuery();

  useEffect(() => {
    /** When the registration tier is updated as prop, update the activated tier. */
    setActiveRegistrationTier(registrationTier);
  }, [registrationTier]);

  const isOwner = project.owner === user.id;

  const tierActionVerb =
    activeRegistrationTier !== undefined
      ? contentSection === "confirmDelete"
        ? "Delete"
        : "Update"
      : "Create";

  function getHeaderTitle() {
    switch (contentSection) {
      case "viewRevenueSplits":
        return "Revenue Splits";
      case "editRevenueSplit":
        return "Update Revenue Split";
      case "createRevenueSplit":
        return "Create Revenue Split";
      case "deleteRevenueSplit":
        return "Delete Revenue Split";
      default:
        return `${tierActionVerb.toLowerCase()} tier`;
    }
  }

  function toggleDeleteConfirmation() {
    const newSection =
      contentSection === "confirmDelete"
        ? "editRegistrationTier"
        : "confirmDelete";
    setContentSection(newSection);
  }

  function handleTierCreated() {
    addToast("New registration tier created.", {
      appearance: "success",
      autoDismiss: true,
    });
    props.onHide();
  }

  async function handleDelete() {
    setIsLoading(true);
    const action = await dispatch(
      deleteRegistrationTier(activeRegistrationTier.id)
    );
    if (action.type === "DELETE_REGISTRATION_TIER/fulfilled") {
      setIsLoading(false);
      addToast("Registration tier deleted", { appearance: "success" });
      props.onHide();
    } else {
      setIsLoading(false);
      addToast(
        action?.payload?.data?.detail || "Error deleting registration tier",
        {
          appearance: "error",
        }
      );
    }
  }

  function activateSplitSection() {
    // When the split section is not active yet, we need to get the data firsts.
    fetchRevenueSplits({ registration_tier: registrationTier.id });
    setContentSection("viewRevenueSplits");
  }

  function renderBody() {
    /* The main body content of the modal, which can be one of many different sections dealing with
       registration tiers and revenue splits of the registration tier.
    */

    if (contentSection === "confirmDelete")
      return (
        <div>
          <Caption>
            Deleting a tier is permanent and can't be undone. Are you sure you
            want to delete &nbsp;<b>{activeRegistrationTier.title}</b>?
          </Caption>
          <FormSubmitContainer className="d-flex justify-content-between">
            <FormSecondaryButton onClick={toggleDeleteConfirmation}>
              Cancel
            </FormSecondaryButton>
            <FormDangerButton onClick={handleDelete} isLoading={isLoading}>
              Yes, delete
            </FormDangerButton>
          </FormSubmitContainer>
        </div>
      );
    else if (contentSection === "viewRevenueSplits") {
      return isFetchingSplits ? (
        <LoadingContainer />
      ) : (
        <RevenueSplitContainer
          revenueSplits={revenueSplits}
          setContentSection={setContentSection}
          canCreate={isOwner}
          modalActionProps={{
            navigateEdit: () => setContentSection("editRevenueSplit"),
            navigateDeleteConfirm: () =>
              setContentSection("deleteRevenueSplit"),
            setSelectedRevenueSplit: setSelectedRevenueSplit,
          }}
        />
      );
    } else if (contentSection === "viewDescriptions")
      return (
        <TierDescriptionContainer
          registrationTier={activeRegistrationTier}
          setContentSection={setContentSection}
          modalActionProps={{
            navigateDeleteConfirm: () => setContentSection("viewDescriptions"),
            navigateEdit: () => setContentSection("editTierDescription"),
            setSelectedDescription: setSelectedDescription,
          }}
        />
      );
    else if (contentSection === "editTierDescription")
      return (
        <TierDescriptionForm
          successAction={() => setContentSection("viewDescriptions")}
          backAction={() => setContentSection("viewDescriptions")}
          description={selectedDescription}
        />
      );
    else if (contentSection === "createTierDescription")
      return (
        <TierDescriptionForm
          successAction={() => setContentSection("viewDescriptions")}
          backAction={() => setContentSection("viewDescriptions")}
          description={{ registrationTier: activeRegistrationTier }}
        />
      );
    else if (contentSection === "editRevenueSplit")
      return (
        <RevenueSplitForm
          successAction={() => setContentSection("viewRevenueSplits")}
          backAction={() => setContentSection("viewRevenueSplits")}
          revenueSplit={selectedRevenueSplit}
        />
      );
    else if (contentSection === "createRevenueSplit")
      return (
        <RevenueSplitForm
          successAction={() => setContentSection("viewRevenueSplits")}
          backAction={() => setContentSection("viewRevenueSplits")}
          revenueSplit={{ registrationTier: activeRegistrationTier }}
        />
      );
    else if (contentSection === "deleteRevenueSplit")
      return (
        <RevenueSplitConfirmDelete
          revenueSplit={selectedRevenueSplit}
          successAction={() => setContentSection("viewRevenueSplits")}
        />
      );

    // Default section is to show the registration tier form.
    return (
      <RegistrationTierForm
        registrationTier={activeRegistrationTier}
        setActiveRegistrationTier={setActiveRegistrationTier}
        closeModal={props.onHide}
        confirmDelete={toggleDeleteConfirmation}
        activateSplitSection={activateSplitSection}
        activateDescriptionSection={() => setContentSection("viewDescriptions")}
        isLoadingSplits={isLoadingSplits}
        tierCreatedAction={handleTierCreated}
      />
    );
  }

  return (
    <StyledBaseModal {...props}>
      <StyledModalHeader
        title={getHeaderTitle()}
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>{renderBody()}</Modal.Body>
    </StyledBaseModal>
  );
};

RegistrationTierModal.propTypes = {
  /** The registration tier that is being passed into the modal. */
  registrationTier: shape(RegistrationTier),

  /** Function to hide the modal. */
  onHide: func,
};

export default RegistrationTierModal;
