import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";

import CommentTextFull from "features/comments/components/CommentTextFull";
import { Reply as ReplyType } from "types";
import CommentTimestamp from "features/comments/components/Timestamp";

const Wrapper = styled.div`
  display: flex;
  background-color: transparent;
  margin-bottom: 2px;
  margin-top: 2px;
  padding: 0px 0px 7px 0px;
  border-radius: 5px;
  position: relative;
  white-space: normal;
  font-size: 14px;
  align-items: center;
  padding-left: 12px;
`;

const ReplyIndent = styled.div`
  width: 40px;
  height: 1px;
  background-color: ${(props) => props.theme.gray2};
  margin-right: 12px;
  margin-left: -22px;
`;

function Reply({ reply, ...props }) {
  /** Display an individual reply. */

  return (
    <Wrapper>
      <ReplyIndent />
      <CommentTextFull author={reply.author} text={reply.text} />
      <CommentTimestamp className="ml-1">{reply.created}</CommentTimestamp>
    </Wrapper>
  );
}

Reply.propTypes = {
  /** The reply which we're rendering. */
  reply: shape(ReplyType).isRequired,
};

export default Reply;
