import React from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

const StyledCardBody = styled(Card.Body)`
  border-top: none;
`;

function TierBucketCardBody(props) {
  return <StyledCardBody>{props.children}</StyledCardBody>;
}

export default TierBucketCardBody;
