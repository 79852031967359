import React, { useState } from "react";
import { func, string } from "prop-types";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";

import ActionButton from "components/buttons/Action";
import NewsletterService from "features/newsletter/service";

const EmailSubmitButton = styled(ActionButton)`
  transform: rotate(4deg);
`;

function NewsletterRegisterButton({ label, email, clearValue, ...props }) {
  /** Button to send the action to register an email for the newsletter. */

  const [isLoading, setIsLoading] = useState("");
  const { addToast } = useToasts();

  async function handleRegister() {
    /** Action to register the email for our newsletter. */
    const service = new NewsletterService();

    try {
      setIsLoading(true);
      await service.register({ email });
      clearValue("");
      addToast("You're all set!", { appearance: "success" });
    } catch (err) {
      addToast("Something went wrong...", { appearance: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EmailSubmitButton onClick={handleRegister} disabled={isLoading} {...props}>
      {label}
    </EmailSubmitButton>
  );
}

NewsletterRegisterButton.propTypes = {
  /** Action to set the value in the parent component. */
  clearValue: func.isRequired,

  /** The email that is being added. */
  email: string.isRequired,

  /** Text to show in the button. */
  label: string,
};

NewsletterRegisterButton.defaultProps = {
  label: "Subscribe",
};

export default NewsletterRegisterButton;
