import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { navigate } from "@reach/router";
import { useDispatch } from "react-redux";
import { verifyEmail } from "features/accounts/thunks";

import LoadingContainer from "components/loading/Container";

function ConfirmEmailRoute({ emailKey }) {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  useEffect(() => {
    async function _verifyEmail() {
      try {
        dispatch(verifyEmail({ key: emailKey }));
        addToast("Welcome to project city!", { appearance: "success" });
        navigate("/");
      } catch (e) {
        addToast("Error while verifying email", { appearance: "error" });
      }
    }

    _verifyEmail();
  }, [addToast, emailKey]);

  return <LoadingContainer />;
}

ConfirmEmailRoute.propTypes = {
  // Key that is received in email to confirm account email.
  key: PropTypes.string,
};

export default ConfirmEmailRoute;
