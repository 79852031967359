import { useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { debounce } from "lodash";

import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import DatePicker from "components/forms/datetime/DatePicker";
import ToggleBox from "components/controls/ToggleBox";
import { UploadScheduleDate } from "features/schedules/types";
import projectCityApi, {
  useCreateUploadScheduleDateMutation,
  useUpdateUploadScheduleDateMutation,
} from "services/projectCity";
import { handleResponseError } from "utils/forms";
import Select from "components/controls/Select";
import FormControl from "components/forms/shared/Control";

function UploadScheduleDateForm({ uploadScheduleDate, closeModal, ...props }) {
  /** Form to create or update an Upload Schedule Section. */

  const [formError, setFormError] = useState(null);

  const [
    updateUploadScheduleSection,
    updateResult,
  ] = useUpdateUploadScheduleDateMutation();
  const [
    createUploadScheduleSection,
    createResult,
  ] = useCreateUploadScheduleDateMutation();

  const isUpdating = uploadScheduleDate.id !== undefined;

  const [
    searchUsers,
    searchResult,
  ] = projectCityApi.endpoints.userSearch.useLazyQuery();

  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: uploadScheduleDate || {},
  });

  const { addToast } = useToasts();

  function onSubmit(data) {
    // Add in initial data...
    const payload = { ...uploadScheduleDate, ...data };

    // Modify user data to be just the id instead of user object.
    if (payload?.user) payload.user = payload.user.value;

    // Either update or create the upload schedule.
    isUpdating
      ? updateUploadScheduleSection({
          uploadScheduleDateId: uploadScheduleDate.id,
          ...payload,
        })
      : createUploadScheduleSection(payload);
  }

  useEffect(() => {
    // Handle result of modifying the upload schedule.
    const result = isUpdating ? updateResult : createResult;
    const verb = isUpdating ? "updated" : "created";

    if (result.isSuccess) {
      closeModal();
      addToast(`Schedule ${verb}`, { appearance: "success" });
    } else if (result.isError) {
      handleResponseError(result, setFormError, setError);
    }
  }, [updateResult, createResult]);

  const debouncedUserSearch = debounce((value) => {
    searchUsers({ username: value });
  }, 700);

  function handleUserInputChange(value) {
    // When we type in a usename we should debounce search for users that start with username.
    if (value.length > 0) debouncedUserSearch(value);
  }

  function getDefaultSelectedUser() {
    // Get the user object if updating an existing upload schedule date record.
    if (uploadScheduleDate.user) {
      const { id, username } = uploadScheduleDate.user;
      return { value: id, label: username };
    }

    return null;
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Datetime" errors={errors.datetime}>
        <Controller
          as={DatePicker}
          timeFormat="h:mm a"
          name="datetime"
          control={control}
          isInvalid={errors.startDate !== undefined}
          initialValue={uploadScheduleDate.datetime}
          defaultValue={uploadScheduleDate.datetime}
          isRequired
        />
      </FormGroup>

      <FormGroup label="User (required)">
        <Controller
          render={({ onChange }) => (
            <Select
              isClearable
              noOptionsMessage={({ inputValue }) => {
                return inputValue === ""
                  ? "Type username..."
                  : searchResult.isLoading
                  ? "Loading"
                  : "No users found starting with username";
              }}
              onInputChange={handleUserInputChange}
              options={
                searchResult?.data
                  ? searchResult.data.map((user) => ({
                      value: user.id,
                      label: user.username,
                    }))
                  : []
              }
              defaultValue={getDefaultSelectedUser()}
              placeholder="Search by username"
              onChange={onChange}
            />
          )}
          name="user"
          control={control}
          isRequired
        />
      </FormGroup>

      <FormGroup label="Description" errors={errors.description}>
        <Controller
          as={FormControl}
          name="description"
          control={control}
          isInvalid={errors.description !== undefined}
        />
      </FormGroup>

      <ToggleBox
        label="Is Public?"
        onLabel="Yes"
        offLabel="No"
        controllerProps={{ name: "isPublic", control, defaultValue: true }}
      />

      <ToggleBox
        label="Is Live?"
        onLabel="Yes"
        offLabel="No"
        controllerProps={{ name: "isLive", control, defaultValue: false }}
      />

      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Submit</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

UploadScheduleDateForm.propTypes = {
  closeModal: func.isRequired,
  uploadScheduleDate: shape(UploadScheduleDate),
};

UploadScheduleDateForm.defaultProps = {
  uploadScheduleDate: {},
};

export default UploadScheduleDateForm;
