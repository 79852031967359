import React from "react";
import Modal from "react-bootstrap/Modal";
import { shape } from "prop-types";

import AuthorContainer from "components/general/AuthorContainer";
import { ContestEntry } from "features/contests/types";
import BaseModal from "components/modals/Base";
import Caption from "components/text/Caption";
import styled from "styled-components";
import CommentContainer from "features/comments/components/Container";
import useGetContentTypeObj from "hooks/GetContentTypeObj";
import LikeContainer from "features/likes/components/Container";
import { useToggleContestEntryLikeMutation } from "services/projectCity";
import ContestEntryVideo from "features/contests/components/Video";
import ModalHeader from "components/modals/Header";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionBar = styled.div`
  display: flex;
  margin: 20px;
`;

function ContestEntryModal({ contestEntry, ...props }) {
  /** Modal to display a contest entry video. */

  const contentTypeObj = useGetContentTypeObj("contestentry");
  const [toggleLike] = useToggleContestEntryLikeMutation();

  return (
    <BaseModal size="xl" {...props}>
      <ModalHeader
        title={contestEntry.title}
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        <Wrapper>
          <AuthorContainer user={contestEntry.createdBy} />
          <Caption color="pink" size="lg">
            {contestEntry.title}
          </Caption>
        </Wrapper>
        <ContestEntryVideo contestEntry={contestEntry} autoPlay />
        <ActionBar>
          <LikeContainer
            count={contestEntry.likesCount}
            likeActive={contestEntry.isLikedByCurrentUser}
            onClick={() =>
              toggleLike({
                contestEntryId: contestEntry.id,
                contestId: contestEntry.contest,
              })
            }
          />
        </ActionBar>
        <CommentContainer
          $withBorder
          className="mt-3"
          contentObject={contestEntry}
          contentTypeObj={contentTypeObj}
        />
      </Modal.Body>
    </BaseModal>
  );
}

ContestEntryModal.propTypes = {
  /** The contest entry object that is being rendered in the modal. */
  contestEntry: shape(ContestEntry).isRequired,
};

export default ContestEntryModal;
