import { number } from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  margin-left: -${(props) => props.gutterSize}px;
  margin-right: -${(props) => props.gutterSize}px;

  > div {
    margin-left: ${(props) => props.gutterSize}px;
    margin-right: ${(props) => props.gutterSize}px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
  }
`;

function HorizontalContainer(props) {
  /** Horizontal form container to have multiple controls on same line. */

  return <Wrapper {...props}>{props.children}</Wrapper>;
}

HorizontalContainer.propTypes = {
  /** Set the gutter size that's in between the columns. */
  gutterSize: number,
};

HorizontalContainer.defaultProps = {
  gutterSize: 15,
};

export default HorizontalContainer;
