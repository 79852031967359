import Caption from "components/text/Caption";
import Thumbnail from "components/text/Thumbnail";
import { bool, object, oneOfType, string } from "prop-types";
import React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 12px;
`;

function TileFooter({ top, bottom, hover, ...props }) {
  /** Generic footer for a tile that shows two data points. */
  return (
    <Wrapper {...props}>
      <Thumbnail className="mb-1" color={hover ? "neonPink" : "yellow"}>
        {top}
      </Thumbnail>
      {bottom && (
        <Caption color={hover ? "neonPink" : "pink"} size="sm">
          {bottom}
        </Caption>
      )}
    </Wrapper>
  );
}

TileFooter.propTypes = {
  top: oneOfType([string, object]).isRequired,
  bottom: oneOfType([string, object]),
  hover: bool,
};

TileFooter.defaultProps = {
  bottom: null,
  hover: false,
};

export default TileFooter;
