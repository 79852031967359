import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "components/buttons/Button";
import BorderContainer from "components/containers/Border";

const StyledBorderContainer = styled(BorderContainer)`
  height: 100%;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 80px;
  }
`;

function AddTierSection({ onClick, props }) {
  /** Section that allows a user to add a tier. */

  return (
    <StyledBorderContainer fill centered onClick={onClick} {...props}>
      <Button className="disable">ADD TIER</Button>
    </StyledBorderContainer>
  );
}

AddTierSection.propTypes = {
  /** Make the section tilted */
  tilted: PropTypes.bool,
};

export default AddTierSection;
