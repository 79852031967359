import React from "react";
import { arrayOf, bool, func, shape } from "prop-types";
import styled from "styled-components";

import LoadingContainer from "components/loading/Container";
import Caption from "components/text/Caption";
import CopyHeader from "components/text/CopyHeader";
import { ContentUpload } from "features/sprint/types";
import ContentUploadTile from "../ContentUploadTile";
import StandardInfiniteScroller from "components/scrollers/Standard";

const Wrapper = styled.div`
  margin: ${(props) => props.theme.vSpacingLg} 0;
`;

function ContentUploadList({
  data,
  isLoading,
  fetchNextPage,
  hasMore,
  isError,
  allowChooseBucket,
}) {
  /** Container to house the content uploads to be shown to subscription users. */

  if (isLoading) return <LoadingContainer text="Getting content uploads..." />;

  if (isError)
    return (
      <CopyHeader color="pink" className="text-center mt-3">
        Oops! There was an error fetching content uploads, try again later.
      </CopyHeader>
    );

  if (!data.length) {
    return (
      <Caption color="neonPink" className="mt-5 text-center">
        There are no content uploads that match your filter.
      </Caption>
    );
  }

  return (
    <Wrapper>
      <StandardInfiniteScroller
        dataLength={data.length}
        next={fetchNextPage}
        hasMore={hasMore}
      >
        {data.map((contentUpload, index) => (
          <ContentUploadTile
            key={contentUpload.id}
            contentUpload={contentUpload}
            isEven={index % 2 === 0}
            allowChooseBucket={allowChooseBucket}
            allowChooseInSprint
          />
        ))}
      </StandardInfiniteScroller>
    </Wrapper>
  );
}

ContentUploadList.propTypes = {
  isLoading: bool.isRequired,
  isError: bool.isRequired,
  data: arrayOf(shape(ContentUpload)),
  fetchNextPage: func,
  hasMore: bool,
  allowChooseBucket: bool,
};

ContentUploadList.defaultProps = {
  data: [],
  hasMore: false,
  allowChooseBucket: false,
};

export default ContentUploadList;
