import { shape } from "prop-types";

import { useToasts } from "react-toast-notifications";
import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";
import { Assignment } from "features/assignments/types";
import { useDeleteAssignmentMutation } from "services/projectCity";

function ConfirmDeleteAssignmentModal({ assignment, ...props }) {
  /** Confirm deletion of an assignment. */

  const { addToast } = useToasts();
  const [performDelete, result] = useDeleteAssignmentMutation();

  async function handleDelete() {
    const response = await performDelete({ assignmentId: assignment.id });
    props.onHide();
    if (response.error)
      addToast("Error deleting assignment.", { appearance: "error" });
    else addToast("Assignment has been deleted.", { appearance: "success" });
  }

  return (
    <ConfirmActionModal
      title="Delete assignment"
      confirmText="Yes, delete"
      isDanger
      confirmAction={handleDelete}
      isLoading={result.isLoading}
      {...props}
    >
      <ModalSummaryText>
        Are you sure you want to delete the "<b>{assignment.title}</b>"
        assignment? The action is permanent and can't be undone.
      </ModalSummaryText>
    </ConfirmActionModal>
  );
}

ConfirmDeleteAssignmentModal.propTypes = {
  assignment: shape(Assignment).isRequired,
};

export default ConfirmDeleteAssignmentModal;
