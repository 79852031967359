import { shape } from "prop-types";
import styled from "styled-components";

import Video from "components/videos/Video";
import VideoMaskImage from "components/images/VideoMask";
import { assignmentEntryType } from "features/assignments/enums";
import { AssignmentEntry } from "features/assignments/types";
import VideoMask from "images/masks/video.svg";

const PlaceholderDiv = styled.div`
  background-image: linear-gradient(
    ${(props) => props.theme.neonPink},
    ${(props) => props.theme.yellow}
  );
  width: 100%;
  aspect-ratio: 16 / 9;

  mask-image: url(${VideoMask});
  mask-size: 100%;
  mask-repeat: round;
  max-width: 100%;
`;

function AssignmentEntryTileImage({ assignmentEntry, ...props }) {
  /** Render the image that should be shown for an assignment entry. */
  if (assignmentEntry.thumbnail) {
    return <VideoMaskImage fluid src={assignmentEntry.thumbnail} {...props} />;
  } else if (assignmentEntry.type === assignmentEntryType.video) {
    return (
      <Video
        src={assignmentEntry.files[assignmentEntry.files.length - 1]?.file}
        controls={false}
      />
    );
  } else return <PlaceholderDiv></PlaceholderDiv>;
}

AssignmentEntryTileImage.propTypes = {
  assignmentEntry: shape(AssignmentEntry).isRequired,
};

export default AssignmentEntryTileImage;
