import React from "react";
import styled from "styled-components";
import { oneOf } from "prop-types";

const Wrapper = styled.div`
  position: absolute;
  pointer-events: none;

  ${(props) => {
    switch (props.position) {
      case "upper-right":
      case "bottom":
      case "center":
      default:
        return `
          top: 50%;
          left: -25px;
          transform: translateY(-50%);
          width: 95%;
        `;
    }
  }}
`;

function BaseProjectTileBanner({ position, ...props }) {
  /** A base component that all project tile banners should inherit from. */
  return <Wrapper>{props.children}</Wrapper>;
}

BaseProjectTileBanner.propTypes = {
  /** The position of the banner on the project tile. */
  position: oneOf(["center", "upper-right", "bottom"]).isRequired,
};

export default BaseProjectTileBanner;
