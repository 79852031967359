import React from "react";
import styled from "styled-components";

import PosterMask from "images/masks/project.svg";
import Rainbow from "images/backgrounds/rainbow_full.png";

const ImageContainer = styled.div`
  position: relative;
  transition: transform ease ${(props) => props.theme.transitionSuperFast};

  ${(props) =>
    props.$withRainbowBorder &&
    `
    padding: 5px;
    padding-top: 2px;
    background-image: url(${Rainbow});
    border-radius: 12px;
    mask-image: url(${PosterMask});
    mask-repeat: round;
  `}

  ${(props) =>
    props.$isEven
      ? `
    ${props.$hover ? "transform: rotate(2deg)" : "transform: rotate(-1deg)"}
  `
      : `
    ${props.$hover ? "transform: rotate(-1deg)" : "transform: rotate(2deg)"}
  `}
`;

function TileImageContainer({ withRainbowBorder, hover, isEven, ...props }) {
  /** A special container that should go around a tile image. */
  return (
    <ImageContainer
      $withRainbowBorder={withRainbowBorder}
      $hover={hover}
      $isEven={isEven}
      {...props}
    />
  );
}

export default TileImageContainer;
