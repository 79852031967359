import React from "react";
import { func } from "prop-types";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { useToasts } from "react-toast-notifications";
import GoogleLoginButton from "images/login_btn_google.png";
import { loginGoogleUser } from "features/accounts/thunks";
import styled from "styled-components";
import { googleClientId } from "utils/constants";

const CustomButton = styled.button`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0;
`;

function GoogleAuthButton({ closeModal }) {
  /** Button to initiate oauth with Google. */

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => responseGoogleSuccess(codeResponse),
    onError: (codeResponse) => responseGoogleFailure(codeResponse),
    flow: "auth-code",
  });

  async function responseGoogleSuccess(response) {
    const action = await dispatch(loginGoogleUser({ code: response.code }));
    if (action.type === "LOGIN_GOOGLE/fulfilled") {
      closeModal();
      addToast("You're now logged in.", { appearance: "success" });
    } else {
      let errorMessage =
        "Error authenticating with google. Please check your internet connection.";

      if (action?.payload?.data?.nonFieldErrors)
        errorMessage = action.payload.data.nonFieldErrors[0];

      addToast(errorMessage, {
        appearance: "error",
        autoDismissTimeout: 5000,
      });
    }
  }

  function responseGoogleFailure(response) {
    addToast("Error authenticating with google", {
      appearance: "error",
      autoDismissTimeout: 5000,
    });
  }

  return (
    <CustomButton
      clientId={googleClientId}
      className="px-5"
      onSuccess={responseGoogleSuccess}
      onFailure={responseGoogleFailure}
      onClick={login}
    >
      <img alt="Google button" src={GoogleLoginButton}></img>
    </CustomButton>
  );
}

GoogleAuthButton.propTypes = {
  /** Function to close the parent modal. */
  closeModal: func.isRequired,
};

export default GoogleAuthButton;
