import React from "react";
import { bool, number } from "prop-types";

import theme from "theme";

export function EditIconComponent({ dimension, hover, ...props }) {
  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M54.172 27.891c0 .729-.035 1.457-.1 2.182l.995.09c-.22 2.424-.78 4.806-1.592 7.084l-.942-.335a33.24 33.24 0 01-1.759 4.048l.89.457a36.94 36.94 0 01-3.95 6.102l-.781-.623a34.227 34.227 0 01-1.432 1.682c-.438.482-.896.93-1.372 1.346l.657.753c-1.664 1.453-3.54 2.54-5.562 3.34l-.367-.93a24.129 24.129 0 01-3.744 1.125l.213.977c-2.079.453-4.253.688-6.466.773l-.038-1c-.663.026-1.33.038-2 .038-.674 0-1.348-.01-2.02-.031l-.031 1c-2.218-.071-4.417-.27-6.527-.68l.191-.982a24.944 24.944 0 01-3.803-1.038l-.343.94c-2.069-.756-3.986-1.806-5.67-3.246l.65-.76a15.31 15.31 0 01-1.381-1.341 20.525 20.525 0 01-1.35-1.654l-.806.592c-1.376-1.873-2.496-4.004-3.37-6.3l.934-.356a33.022 33.022 0 01-1.266-4.156l-.974.23A40.073 40.073 0 01.06 30.13l.999-.052A41.846 41.846 0 011 27.89a35 35 0 01.066-2.147l-.998-.062a34.76 34.76 0 011.14-6.968l.965.262c.379-1.396.847-2.749 1.398-4.044l-.92-.392a28.49 28.49 0 013.527-6.11l.795.607c.432-.567.885-1.111 1.358-1.631.436-.48.89-.925 1.36-1.338l-.66-.751c1.655-1.454 3.506-2.54 5.5-3.34l.371.929a23.494 23.494 0 013.705-1.124l-.215-.977c2.054-.45 4.207-.684 6.41-.768l.039 1A52.09 52.09 0 0126.823 1c.68 0 1.359.006 2.037.02l.022-1c2.217.049 4.432.191 6.562.534l-.158.987c1.336.215 2.627.509 3.854.908l.309-.952c2.111.686 4.068 1.675 5.772 3.094l-.64.769c.485.404.948.845 1.387 1.327A26.87 26.87 0 0147.39 8.39l.793-.61c1.435 1.867 2.724 4.003 3.792 6.305l-.907.42a34.86 34.86 0 011.62 4.172l.953-.3a32.852 32.852 0 011.437 7.213l-.997.08c.06.743.09 1.484.09 2.221z"
        stroke={theme.primary}
        strokeWidth={2}
        stroke-dasharray="5 8"
      />
      <path
        d="M47.408 27.922c0 5.812-2.916 11.448-6.379 15.257C37.566 46.99 32.443 48 27.16 48c-5.283 0-10.593-.81-14.054-4.618C9.645 39.572 8 33.732 8 27.922c0-5.809 1.96-11.307 5.423-15.113C16.886 9.003 21.878 8 27.159 8c5.281 0 10.74.49 14.203 4.296 3.463 3.806 6.046 9.814 6.046 15.626z"
        fill={hover ? theme.secondary : theme.primary}
      />
      <path
        d="M34.176 17l-2.429 2.43 4.824 4.823 2.43-2.429L34.175 17zm-3.238 3.238L20.11 31.068l4.824 4.823 10.83-10.829-4.825-4.824zM19.374 31.944L18.001 38l6.055-1.373-4.682-4.683z"
        fill={hover ? theme.primary : theme.secondary}
      />
    </svg>
  );
}

EditIconComponent.propTypes = {
  /** Determine if the image is being hovered. */
  hover: bool,

  /** The height/width of the image. */
  dimension: number,
};

EditIconComponent.defaultProps = {
  hover: false,
  dimension: 45,
};

export default EditIconComponent;
