import React from "react";
import DivideSvg from "images/divide.svg";
import styled from "styled-components";

const StyledDivider = styled.img`
  width: 100%;
  margin-top: ${(props) => props.theme.vSpacingMd};
`;

function FormSubmitDivider(props) {
  /* Provide division between a form's input fields and the submit area. */

  return (
    <StyledDivider src={DivideSvg} alt="Divide Icon" {...props}></StyledDivider>
  );
}

export default FormSubmitDivider;
