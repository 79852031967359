import React, { useState, useRef } from "react";
import { func, number, oneOf } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";

import FormControl from "components/forms/shared/Control";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import { createProject } from "features/projects/thunks";
import ImageAdd from "images/image_add.png";
import { projectTemplate, projectType } from "utils/enums";
import { getProjectUrl } from "utils/projects";

const StyledLabel = styled.label`
  display: block;
  width: 250px;
  margin: auto;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

function CreateProjectForm({ template, type, ...props }) {
  /** Provide a simple form for initial project creation.
   *
   * We want to keep this to be as minimal as possible, and have them edit mostly on the
   * project info page. The form is used slightly differently for a class vs project.
   */
  const dispatch = useDispatch();
  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm();
  const user = useSelector((state) => state.account.user);
  const projectImage = useRef(null);

  const isClassTemplate = template === projectTemplate.liveClass;
  const descriptor = isClassTemplate ? "Class" : "Project";

  function onProjectImageChange(e, onChange) {
    const newImage = e.target.files[0];
    onChange(newImage);
    projectImage.current.src = URL.createObjectURL(newImage);
  }

  async function onSubmit(data) {
    /** When we create a project, there are some default values that need to be set. */
    setFormError(null);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    formData.append("template", template);
    formData.append("type", type);
    formData.append("teachers", [user.id]);

    const action = await dispatch(createProject(formData));
    if (action.type === "CREATE_PROJECT/fulfilled") {
      navigate(getProjectUrl(action.payload));
    } else setFormError("Error creating project");
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label={`${descriptor} Title*`} errors={errors.title}>
        <Controller
          as={FormControl}
          name="title"
          placeholder="Ideally short and exciting!"
          rules={{ required: true }}
          control={control}
          isInvalid={errors.title !== undefined}
        />
      </FormGroup>
      {!isClassTemplate && (
        <FormGroup label="Logline*" errors={errors.description}>
          <Controller
            as={<FormControl as="textarea" />}
            rows={3}
            placeholder="A logline is one to two sentences that sums up your entire project."
            rules={{ required: true }}
            name="description"
            control={control}
            defaultValue=""
            isInvalid={errors.description !== undefined}
          />
        </FormGroup>
      )}
      <FormGroup label={`${descriptor} Thumbnail`} errors={errors.image}>
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <StyledLabel
              htmlFor="project-image"
              className={errors.image ? "is-invalid" : null}
            >
              <input
                onBlur={onBlur}
                checked={value}
                ref={ref}
                error={errors.class_details}
                type="file"
                className="d-none"
                id="project-image"
                onChange={(e) => onProjectImageChange(e, onChange)}
              />
              <img src={ImageAdd} ref={projectImage} alt="" />
            </StyledLabel>
          )}
          name="image"
          control={control}
          defaultValue=""
          isInvalid={errors.image !== undefined}
        />
      </FormGroup>
      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={props.closeModal}>
          Cancel
        </FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Create</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

CreateProjectForm.propTypes = {
  /** The template value, if there was one selected.  */
  template: number,

  /** Function to close the parent modal. */
  closeModal: func.isRequired,

  /** Type of the project that is being created. */
  type: oneOf(Object.keys(projectType)).isRequired,
};

CreateProjectForm.defaultProps = {
  template: null,
};

export default CreateProjectForm;
