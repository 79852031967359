import React from "react";
import { Controller } from "react-hook-form";

import FormControl from "components/forms/shared/Control";
import FormGroup from "components/forms/shared/FormGroup";
import { object, string } from "prop-types";
import UploadedVideoLabel from "components/text/UploadedVideoLabel";

function FileUploadFormGroup({
  label,
  errors,
  control,
  field,
  controlProps,
  controllerProps,
  current,
  ...props
}) {
  /** Reusable field group for file uploads. */

  return (
    <FormGroup label={label} errors={errors[field]} {...props}>
      <Controller
        render={({ onChange }) => (
          <FormControl
            type="file"
            error={errors[field]}
            onChange={(e) =>
              onChange(
                controlProps.multiple ? e.target.files : e.target.files[0]
              )
            }
            {...controlProps}
          />
        )}
        defaultValue={null}
        name={field}
        control={control}
        isInvalid={errors[field] !== undefined}
        {...controllerProps}
      />
      {current && <UploadedVideoLabel>Current - {current}</UploadedVideoLabel>}
    </FormGroup>
  );
}

FileUploadFormGroup.propTypes = {
  /** The field name on the form. */
  field: string.isRequired,

  /** Text label to show with the field. */
  label: string.isRequired,

  /** The error object that goes with the form. */
  errors: object.isRequired,

  /** The control object for the form. */
  control: object.isRequired,

  /** Extra props to pass into the form control component. */
  controlProps: object,

  /** Extra props to pass into the controller component. */
  controllerProps: object,

  /** Display current file name uploaded. */
  current: string,
};

FileUploadFormGroup.defaultProps = {
  controlProps: {},
  controllerProps: {},
  current: null,
};

export default FileUploadFormGroup;
