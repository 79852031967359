import React from "react";
import { func, string } from "prop-types";
import styled from "styled-components";

import Button from "components/buttons/Button";
import InfoTooltip from "components/tooltips/Info";

const Wrapper = styled.div`
  border: 2px solid ${(props) => props.theme.lime};
  padding: 2rem;
  text-align: center;
  color: ${(props) => props.theme.lime};
`;

function EmptyActionSection({
  text,
  buttonText,
  buttonAction,
  tooltip,
  ...props
}) {
  /** Display a section with empty data for the user to to perform some action. */

  return (
    <Wrapper {...props}>
      {text && <p>{text}</p>}
      <Button onClick={buttonAction}>{buttonText}</Button>
      {tooltip && <InfoTooltip text={tooltip} />}
    </Wrapper>
  );
}

EmptyActionSection.propTypes = {
  /** The text to be shown for the action button. */
  buttonText: string.isRequired,

  /** The action to perform when the user clicks the action button. */
  buttonAction: func.isRequired,

  /** Tooltip text to be displayed with the empty action button. */
  tooltip: string,

  /** The text to be displayed in the section. */
  text: string,
};

export default EmptyActionSection;
