import React from "react";
import Modal from "react-bootstrap/Modal";
import { shape } from "prop-types";
import Image from "react-bootstrap/Image";

import CommentContainer from "features/comments/components/Container";
import CommentModal from "features/comments/modals/Comment";
import useGetContentTypeObj from "hooks/GetContentTypeObj";
import { Project } from "types";

function ProjectModal({ project, ...props }) {
  /** Display a project with its comments in a modal. */

  const contentTypeObj = useGetContentTypeObj("project");

  return (
    <CommentModal {...props}>
      <Modal.Body>
        <Image src={project.image} fluid />
        <CommentContainer
          contentObject={project}
          contentTypeObj={contentTypeObj}
        />
      </Modal.Body>
    </CommentModal>
  );
}

ProjectModal.propTypes = {
  // The project that is being rendered in the modal.
  project: shape(Project).isRequired,
};

export default ProjectModal;
