import React from "react";
import styled from "styled-components";

import Caption from "components/text/Caption";

const StyledCaption = styled(Caption)`
  margin-bottom: 40px;
`;

function FormHelpText(props) {
  /** Helper text that is generally displayed at the top of a form. */

  return <StyledCaption {...props}>{props.children}</StyledCaption>;
}

export default FormHelpText;
