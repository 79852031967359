import React from "react";
import styled from "styled-components";

import BaseModal from "components/modals/Base";
import CloseIcon from "images/icons/close.png";

const StyledModal = styled(BaseModal)`
  padding-top: ${(props) => props.theme.vSpacingSm};

  .modal-content {
    background-color: ${(props) => props.theme.black};
    border: 4px solid ${(props) => props.theme.gray2};
    padding: 0;
  }

  .modal-body {
    padding: 0;
  }

  .modal-dialog {
    position: relative;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding-top: ${(props) => props.theme.vSpacingMd};
  }
`;

const CloseIconWrapper = styled.div`
  width: 25px;
  position: absolute;
  top: -40px;
  right: 0px;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

function CommentModal(props) {
  /** A base modal style that is generally used for commenting. */
  return (
    <StyledModal size="lg" {...props}>
      <CloseIconWrapper onClick={props.onHide}>
        <img src={CloseIcon} alt="" />
      </CloseIconWrapper>
      {props.children}
    </StyledModal>
  );
}

export default CommentModal;
