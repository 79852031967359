import { string } from "prop-types";
import { Modal } from "react-bootstrap";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";

function SubscriptionPastDueModal({ paymentUrl, ...props }) {
  // Prompt the user to fix their past due subscription.

  function renderPaymentUrl() {
    return (
      <a href={paymentUrl} target="_blank" rel="noreferrer">
        retry payment
      </a>
    );
  }

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title="Subscription past due"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        <ModalSummaryText>
          Your Project City subscription has failed payment and is now past due.
          Please fix your payment method to avoid interruption of your
          subscription. You can {renderPaymentUrl()} now, and please don't
          hesitate to contact support if you have any questions!
        </ModalSummaryText>
      </Modal.Body>
    </BaseModal>
  );
}

SubscriptionPastDueModal.propTypes = {
  /** The link that has the payable invoice. */
  paymentUrl: string.isRequired,
};

export default SubscriptionPastDueModal;
