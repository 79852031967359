import React from "react";
import styled from "styled-components";

import Button from "components/buttons/Button";

const StyledButton = styled(Button)`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    height: 50px;
  }
`;

function FormButton({ children, ...props }) {
  /** Provide common functionality for all form buttons. */

  return (
    <StyledButton uppercase size="lg" {...props}>
      {children}
    </StyledButton>
  );
}

export default FormButton;
