import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "features/api";
import { fetchBucket } from "./thunks";
import {
  createBucketUpload,
  updateBucketUpload,
} from "features/bucketUploads/thunks";
import {
  createTextOnlyMessage,
  deleteTextOnlyMessage,
  updateTextOnlyMessage,
} from "features/textOnly/thunks";
import { createStackImage } from "features/stackImages/thunks";
import {
  createFeedMessage,
  updateFeedMessage,
  deleteFeedMessage,
} from "features/feedMessages/thunks";

const bucketInitialState = {
  ...initialState,

  // Set the currently selected bucket object.
  current: {},
};

const bucketsSlice = createSlice({
  name: "buckets",
  initialState: bucketInitialState,
  reducers: {
    clearBuckets: (state) => {
      return bucketInitialState;
    },
    addContentUpload: (state, { payload }) => {
      // Add a content upload into a given bucket.

      const { bucketId, contentUpload } = payload;

      let bucket = state.entities.find((bucket) => bucket.id === bucketId);

      bucket.contentUploads.push(contentUpload);
    },
    updateContentUpload: (state, { payload }) => {
      // Update the data for a content upload in bucket data.
      const { bucketId, contentUpload } = payload;

      let bucket = state.entities.find((bucket) => bucket.id === bucketId);

      if (bucket) {
        bucket.contentUploads = bucket.contentUploads.map(
          (bucketContentUpload) => {
            if (bucketContentUpload.id === contentUpload.id) {
              return contentUpload;
            } else {
              return bucketContentUpload;
            }
          }
        );
      }
    },
    removeContentUpload: (state, { payload }) => {
      // Remove the data for a content upload in bucket data.
      const { bucketId, contentUploadId } = payload;
      let bucket = state.entities.find((bucket) => bucket.id === bucketId);

      if (bucket) {
        bucket.contentUploads = bucket.contentUploads.filter(
          (bucketContentUpload) => bucketContentUpload.id !== contentUploadId
        );
      }
    },
    setBucketUpload: (state, action) => {
      // Take in a bucket upload data and replace the corresponding bucket upload in the redux state.
      let bucket = state.entities.find(
        (bucket) => bucket.id === action.payload.bucket
      );
      bucket.stacks = bucket.stacks.map((stack) =>
        stack.id === action.payload.id ? action.payload : stack
      );
    },
    removeBucketUpload: (state, action) => {
      // Removes a stack from our bucket state.
      const { bucketUploadId } = action.payload;
      let bucket = state.entities.find((bucket) => {
        return (
          bucket.stacks &&
          bucket.stacks.some((stack) => stack.id === bucketUploadId)
        );
      });

      if (bucket && bucket.stacks)
        bucket.stacks = bucket.stacks.filter(
          (stack) => stack.id !== bucketUploadId
        );
    },
    setCurrentBucket: (state, action) => {
      state.current = action.payload;
    },
    createPreviewMessage: (state, action) => {
      /** Create a preview text only message, without saving it to the database. */
      let randomId = -parseInt(Math.random() * 1000000);
      let bucket = state.entities.find(
        (bucket) => bucket.id === state.current.id
      );
      bucket.messages.unshift({
        ...action.payload,
        isPreview: true,
        id: randomId,
        displayComment: null,
        commentCount: 0,
      });
    },
    updatePreviewMessage: (state, action) => {
      /** Update a preview text only message, allowing user to update their preview. */
      let bucket = state.entities.find(
        (bucket) => bucket.id === state.current.id
      );
      bucket.messages = bucket.messages.map((message) => {
        return message.id === action.payload.id
          ? {
              ...message,
              ...action.payload,
            }
          : message;
      });
    },
    removePreviewMessage: (state, action) => {
      /** Remove the preview message from the redux state. */
      let bucket = state.entities.find(
        (bucket) => bucket.id === state.current.id
      );
      bucket.messages = bucket.messages.filter(
        (message) => message.id !== action.payload.id
      );
    },
    reorderContentUpload: (state, action) => {
      // Synchronous reorder of content uploads in a bucket.
      const { contentUploadId, newOrder } = action.payload;

      let bucket = state.entities.find(
        (bucket) => bucket.id === state.current.id
      );

      // Save the original item to be injected
      const original = bucket.contentUploads.find(
        (cu) => cu.id === contentUploadId
      );

      // Remove the reordered item from the array.
      let unordered = bucket.contentUploads.filter(
        (cu) => cu.id !== contentUploadId
      );

      // Inject the item in the desired position
      unordered.splice(newOrder, 0, original);

      // Update the order field to reflect new order.
      const ordered = unordered.map((contentUpload, index) => ({
        ...contentUpload,
        order: index,
      }));

      // We also need to update the series for the content upload objects.
      // const withUpdatedSeries = bucket.contentUploads.map(cu => ({ ...cu, series: ordered }))

      bucket.contentUploads = ordered;
    },
  },
  extraReducers: {
    [fetchBucket.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchBucket.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [fetchBucket.fulfilled]: (state, action) => {
      state.entities = state.entities.map((bucket) => {
        if (bucket.id === action.payload.id) return action.payload;
        else return bucket;
      });
      let existingBucket = state.entities.find(
        (bucket) => bucket.id === action.payload.id
      );
      if (!existingBucket) state.entities.push(action.payload);

      state.isLoading = false;
    },
    [createBucketUpload.fulfilled]: (state, action) => {
      /** Create a new bucket upload and add it to the bucket data. */
      const bucket = state.entities.find(
        (bucket) => bucket.id === action.payload.bucket
      );
      bucket.stacks.unshift(action.payload);
    },
    [createTextOnlyMessage.fulfilled]: (state, action) => {
      /** Create a new text only message and add it to the bucket message data. */
      const bucket = state.entities.find(
        (bucket) => bucket.id === action.payload.id
      );
      bucket.messages = action.payload.messages;
    },
    [deleteTextOnlyMessage.fulfilled]: (state, action) => {
      /** Remove the deleted text only message from the bucket state. */
      state.entities = state.entities.map((bucket) => {
        const messages = bucket.messages.filter(
          (message) => message.id !== action.meta.arg.textOnlyId
        );
        return { ...bucket, messages };
      });
    },
    [updateTextOnlyMessage.fulfilled]: (state, action) => {
      /** Find the text only message and update it. */
      const bucket = state.entities.find(
        (bucket) => bucket.id === action.payload.bucket
      );
      bucket.messages = bucket.messages.map((message) =>
        message.id === action.payload.id ? action.payload : message
      );
    },

    [createStackImage.fulfilled]: (state, action) => {
      /** Add the new bucket upload image to its parent bucket upload (stack) data. */
      const bucket = state.entities.find((bucket) => {
        return (
          bucket.stacks &&
          bucket.stacks.some((stack) => stack.id === action.payload.stack)
        );
      });
      let stack = bucket.stacks.find(
        (stack) => stack.id === action.payload.stack
      );
      if (stack.coverImage === null) stack.coverImage = action.payload;
    },

    [updateBucketUpload.fulfilled]: (state, action) => {
      const bucket = state.entities.find(
        (bucket) => bucket.id === action.payload.bucket
      );

      bucket.stacks = bucket.stacks.map((stack) => {
        if (stack.id !== action.payload.id) return stack;

        // Only certain data fields should be updated.
        const updateData = {
          isAnonymous: action.payload.isAnonymous,
          kind: action.payload.kind,
          thumbnail: action.payload.thumbnail,
        };

        return { ...stack, ...updateData };
      });
    },

    [createFeedMessage.fulfilled]: (state, action) => {
      /** Add the new feed message into its bucket data. */
      const { bucket } = action.payload;
      state.entities = state.entities.map((bucketObj) => {
        if (bucketObj.id === bucket) bucketObj.feed.unshift(action.payload);
        return bucketObj;
      });
    },
    [updateFeedMessage.fulfilled]: (state, action) => {
      /** Find the feed message and update its data in the bucket data. */
      const feedMessageId = action.payload.id;
      const bucketId = action.payload.bucket;
      state.entities = state.entities.map((bucketObj) => {
        if (bucketObj.id === bucketId) {
          bucketObj.feed = bucketObj.feed.map((feedMessage) => {
            if (feedMessage.id === feedMessageId) return action.payload;
            else return feedMessage;
          });
        }
        return bucketObj;
      });
    },
    [deleteFeedMessage.pending]: (state, action) => {
      /** Remove the feed message from the bucket feed data. */
      const { feedMessageId } = action.meta.arg;
      state.entities = state.entities.map((bucketObj) => {
        if (bucketObj.feed === undefined) return bucketObj;
        bucketObj.feed = bucketObj.feed.filter(
          (message) => message.id !== feedMessageId
        );
        return bucketObj;
      });
    },
  },
});

export const {
  clearBuckets,
  removeBucketUpload,
  setCurrentBucket,
  createPreviewMessage,
  updatePreviewMessage,
  removePreviewMessage,
  addContentUpload,
  updateContentUpload,
  removeContentUpload,
  setBucketUpload,
  reorderContentUpload,
} = bucketsSlice.actions;

export default bucketsSlice.reducer;
