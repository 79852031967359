import React from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { array } from "prop-types";

import DropdownButton from "components/buttons/Dropdown";
import Select from "components/controls/Select";
import FormGroup from "components/forms/shared/FormGroup";
import FormControl from "components/forms/shared/Control";
import { convertEnumToArray } from "utils/general";
import { enrollmentStatus } from "utils/enums";

const StyledDropdownButton = styled(DropdownButton)`
  > button {
    font-family: Basis Grotesque Mono;
    font-weight: bold;
    font-size: 21px;
    line-height: 21px;

    @media (max-width: ${(props) => props.theme.smBreakpoint}) {
      font-size: 17px;
      line-height: 18px;
    }
  }
`;

const StudentFilterForm = styled(Form)`
  padding: 1rem;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    width: 400px;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  input.form-control {
    color: ${(props) => props.theme.blue}!important;
  }
`;

function EnrollmentFilterDropdown({ filterState, tierOptions }) {
  /** Dropdown to filter students in the student management table. */

  const [filters, setFilters] = filterState;

  return (
    <StyledDropdownButton title="Filter" className="mr-2">
      <StudentFilterForm>
        <FormGroup label="Registration tier">
          <Select
            isClearable
            isMulti
            closeMenuOnSelect={false}
            options={tierOptions}
            onChange={(tiers) => {
              setFilters({
                ...filters,
                tiers: tiers.map((tier) => tier.value),
              });
            }}
          />
        </FormGroup>
        <FormGroup label="Status">
          <Select
            isClearable
            isMulti
            closeMenuOnSelect={false}
            options={convertEnumToArray(enrollmentStatus)}
            onChange={(statuses) =>
              setFilters({
                ...filters,
                statuses: statuses.map((status) => status.value),
              })
            }
          />
        </FormGroup>
        <StyledFormGroup label="Name">
          <FormControl
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
          />
        </StyledFormGroup>
      </StudentFilterForm>
    </StyledDropdownButton>
  );
}

EnrollmentFilterDropdown.propTypes = {
  /** State to manage which filters are selected. */
  filterState: array.isRequired,

  /** An array of available tiers. */
  tierOptions: array.isRequired,
};

export default EnrollmentFilterDropdown;
