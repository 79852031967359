import { arrayOf, object } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

import Caption from "components/text/Caption";
import AssignmentEntryTile from "../EntryTile";

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: grid;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1vw;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    grid-template-columns: 1fr;
    grid-gap: 3vw;
  }
`;

function AssignmentEntryList({ assignmentEntries, ...props }) {
  /** Show a list of the assignment entries that members have submitted. */

  if (assignmentEntries.length === 0)
    return (
      <Caption color="orange" className="text-center mt-3">
        There are no assignment entries yet.
      </Caption>
    );

  return (
    <StyledInfiniteScroll {...props}>
      {assignmentEntries.map((entry, index) => (
        <AssignmentEntryTile
          assignmentEntry={entry}
          $isEven={index % 2 === 0}
          key={index}
        />
      ))}
    </StyledInfiniteScroll>
  );
}

AssignmentEntryList.propTypes = {
  /** Array of the assignment entries to render. */
  assignmentEntries: arrayOf(object).isRequired,
};

export default AssignmentEntryList;
