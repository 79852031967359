import React from "react";
import { bool, func, shape } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Button from "components/buttons/Button";
import ProjectContactButton from "features/projects/components/ContactButton";
import ProjectActAsContext from "context/ProjectActAs";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { enrollmentStatus } from "utils/enums";
import { Project } from "types";
import { isClass } from "utils/projects";

import TeacherSection from "../TeacherSection";
import { useModal } from "react-modal-hook";
import ProjectModal from "features/projects/modals/Project";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

function ClassInfoHeader({
  project,
  shouldViewAdminItems,
  scrollToTierSection,
}) {
  /**
   * Display a section of information for classes specifically.
   *
   * Only rendered for classes but not general projects.
   */

  const isAuthenticated = useIsAuthenticated();
  const enrollments =
    useSelector((state) => state.account.user.enrollments) || [];

  const user = useSelector((state) => state.account.user);
  const isOwner = user && user.id === project.owner.id;

  const [showProjectModal, hideProjectModal] = useModal(() => (
    <ProjectModal project={project} onHide={hideProjectModal} />
  ));

  function renderProjectActionButtons() {
    /** We need to check which project action button the user should see. */
    if (!isAuthenticated) return null;

    const activeEnrollments = enrollments.filter(
      (enrollment) => enrollment.status === enrollmentStatus.active
    );
    const activeRegistrationTiers = activeEnrollments.map(
      (enrollment) => enrollment.registrationTier
    );
    const hasActiveRegistration = activeRegistrationTiers
      .map((registrationTier) => {
        return registrationTier.project.id;
      })
      .includes(project.id);

    return (
      <ProjectActAsContext>
        {({ registrationTier }) => {
          if (hasActiveRegistration && !registrationTier)
            return <ProjectContactButton project={project} />;

          return (
            <>
              <Button
                isWavy
                variant="secondary"
                onClick={showProjectModal}
                className="mr-3"
              >
                Comments
              </Button>
              {activeRegistrationTiers && isClass(project) && (
                <Button isWavy variant="info" onClick={scrollToTierSection}>
                  BUY CLASS
                </Button>
              )}
            </>
          );
        }}
      </ProjectActAsContext>
    );
  }

  return (
    <Wrapper>
      <div>
        <TeacherSection
          project={project}
          shouldViewAdminItems={isOwner && shouldViewAdminItems}
        />
      </div>
      <div>{renderProjectActionButtons()}</div>
    </Wrapper>
  );
}

ClassInfoHeader.propTypes = {
  /** The project that we're rendering the header for. */
  project: shape(Project).isRequired,

  /** Determine if the user should see the admin items for editing. */
  shouldViewAdminItems: bool.isRequired,

  /** Scroll the user to the tiers section of project info page. */
  scrollToTierSection: func.isRequired,
};

export default ClassInfoHeader;
