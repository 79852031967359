import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  /** Array of content uploads that we currently have loaded to play. */
  autoPlayContentUploads: [],

  /** Toggle if the user is reordering content uploads */
  isReordering: false,

  /** The filters that are active on the sprint list page. */
  filters: {
    ordering: "-created",
    in_sprint: true,
  },
};

const contentUploadsSlice = createSlice({
  name: "contentUploads",
  initialState,
  reducers: {
    setContentUploads: (state, action) => {
      state.autoPlayContentUploads = action.payload;
    },
    setIsReordering: (state, action) => {
      state.isReordering = action.payload;
    },
    setSprintFilterParams: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const {
  setContentUploads,
  setIsReordering,
  setSprintFilterParams,
} = contentUploadsSlice.actions;

export default contentUploadsSlice.reducer;
