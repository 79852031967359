import React from "react";
import { object } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

import Comment from "features/comments/components/Comment";
import LoadingContainer from "components/loading/Container";
import projectCityApi, { useGetCommentListQuery } from "services/projectCity";

const StyledInfiniteScroll = styled(InfiniteScroll)`
  color: ${(props) => props.theme.yellow};

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

function CommentContainer({ contentObject, contentTypeObj }) {
  /**
   * Show the full set of comments for a content object.
   *
   * Comments can be applied to a variety of different parent objects, so we need to have the data
   * about the parent's content object type.
   */

  const params = {
    object_id: contentObject.id,
    content_type: contentTypeObj.id,
  };
  const { data, isLoading, isFetching } = useGetCommentListQuery(params);

  const [loadNextPage] = projectCityApi.useLazyGetNextCommentPageQuery();

  if (isLoading || isFetching || data === undefined)
    return (
      <div className="d-flex justify-content-center h-100">
        <LoadingContainer text="Loading comments" />
      </div>
    );

  const { results: comments, next } = data;

  function renderComments() {
    return comments.map((comment) => (
      <Comment key={comment.id} comment={comment} className="py-1" />
    ));
  }

  if (comments.length === 0)
    return (
      <p className="text-muted text-center mt-4">Be the first to comment...</p>
    );

  return (
    <StyledInfiniteScroll
      dataLength={comments.length}
      next={() => loadNextPage({ next, ...params })}
      hasMore={next !== null}
      loader={<LoadingContainer text="Loading more..." />}
      height={300}
    >
      {renderComments()}
    </StyledInfiniteScroll>
  );
}

CommentContainer.propTypes = {
  /** The content object for which we're showing the comments. */
  contentObject: object.isRequired,

  /** The content type object of the object we're rendering. */
  contentTypeObj: object.isRequired,
};

export default CommentContainer;
