import styled from "styled-components";
import moment from "moment";
import { bool, shape } from "prop-types";

import Body from "components/text/Body";
import { getFullName } from "utils/users";
import { UploadScheduleDate as UploadScheduleDateType } from "features/schedules/types";
import UploadScheduleDateForm from "features/schedules/forms/UploadScheduleDate";
import EditIcon from "components/icons/Edit";
import DeleteIcon from "components/icons/Delete";
import ConfirmDeleteUploadScheduleDateModal from "features/schedules/modals/UploadScheduleDateConfirmDelete";
import { useModal } from "react-modal-hook";
import FormModal from "components/modals/Form";

const DateBody = styled(Body)`
  ${(props) =>
    props.$isPrivate &&
    `
    opacity: 0.6;
  `}
`;

function UploadScheduleDate({ allowEdit, uploadScheduleDate }) {
  /** Render an individual schedule date for sprint uploads. */
  const dateDisplay = moment
    .tz(uploadScheduleDate.datetime, "America/Los_Angeles")
    .format("ddd Do");
  const time = moment
    .tz(uploadScheduleDate.datetime, "America/Los_Angeles")
    .format("h:mma z");
  const userDisplay = getFullName(uploadScheduleDate.user);

  const [showUploadScheduleDateModal, hideUploadScheduleDateModal] = useModal(
    () => (
      <FormModal
        onHide={hideUploadScheduleDateModal}
        title="Update Upload Schedule Date"
      >
        <UploadScheduleDateForm
          closeModal={hideUploadScheduleDateModal}
          uploadScheduleDate={uploadScheduleDate}
        />
      </FormModal>
    ),
    [uploadScheduleDate]
  );

  const [
    showDeleteUploadScheduleDateModal,
    hideDeleteUploadScheduleDateModal,
  ] = useModal(
    () => (
      <ConfirmDeleteUploadScheduleDateModal
        uploadScheduleDate={uploadScheduleDate}
        onHide={hideDeleteUploadScheduleDateModal}
      />
    ),
    [uploadScheduleDate]
  );

  if (!uploadScheduleDate.isPublic && !allowEdit) return null;

  return (
    <li key={uploadScheduleDate.id}>
      <DateBody $isPrivate={!uploadScheduleDate.isPublic}>
        {dateDisplay} - {userDisplay}{" "}
        {uploadScheduleDate.isLive && `- ${time} LIVE`}
        {uploadScheduleDate.description
          ? ` - ${uploadScheduleDate.description}`
          : ""}
        {allowEdit && (
          <span className="ml-2">
            <EditIcon
              dimension="35"
              role="button"
              onClick={showUploadScheduleDateModal}
            />
            <DeleteIcon
              dimension="35"
              className="ml-1"
              role="button"
              onClick={showDeleteUploadScheduleDateModal}
            />
          </span>
        )}
      </DateBody>
    </li>
  );
}

UploadScheduleDate.propTypes = {
  uploadScheduleDate: shape(UploadScheduleDateType).isRequired,
  allowEdit: bool.isRequired,
};

export default UploadScheduleDate;
