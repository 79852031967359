import React from "react";

import LoadingContainer from "../Container";
import DashboardContainer from "components/containers/Dashboard";

function DashboardLoadingContainer(props) {
  /**
   * Loading container to be used with the main dashboard for when the whole route is
   * loading.
   */

  return (
    <DashboardContainer size="sm">
      <LoadingContainer {...props} />
    </DashboardContainer>
  );
}

export default DashboardLoadingContainer;
