import { combineReducers } from "redux";
import projectSlice from "features/projects/slice";
import accountSlice from "features/accounts/slice";
import bucketUploadSlice from "features/bucketUploads/slice";
import bucketSlice from "features/buckets/slice";
import imageUploadSessionSlice from "features/imageUploadSession/slice";
import contentUploadsSlice from "features/sprint/slice";
import projectCityApi from "services/projectCity";

export default combineReducers({
  account: accountSlice,
  buckets: bucketSlice,
  projects: projectSlice,
  bucketUploads: bucketUploadSlice,
  imageUploadSession: imageUploadSessionSlice,
  contentUploads: contentUploadsSlice,
  [projectCityApi.reducerPath]: projectCityApi.reducer,
});
