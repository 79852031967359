import { number, string, bool, arrayOf, shape } from "prop-types";

export const UploadScheduleDate = {
  id: number,
  user: shape({
    id: number,
    firstName: string,
    lastName: string,
  }),
  datetime: string,
  description: string,
  isLive: bool,
  isPublic: bool,
};

export const UploadScheduleSection = {
  id: number,
  uploadSchedule: number,
  dates: arrayOf(shape(UploadScheduleDate)),
  endDate: string,
  startDate: string,
  isPublic: bool,
};

export const UploadSchedule = {
  id: number,
  title: string,
  contest: string,
  theme: string,
  description: string,
  endDate: string,
  isPublic: bool,
  sections: arrayOf(shape(UploadScheduleSection)),
};
