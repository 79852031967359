import React from "react";
import { bool, func, object, string } from "prop-types";
import { Link } from "@reach/router";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";

import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import BaseForm from "components/forms/Base";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormGroup from "components/forms/shared/FormGroup";
import Quill from "components/controls/Quill";
import FormHelpText from "components/forms/shared/HelpText";
import FormSubmitDivider from "components/forms/shared/SubmitDivider";
import { axiosInstance } from "features/api";

const StyledFormGroup = styled(FormGroup)`
  input.form-control {
    color: ${(props) => props.theme.blue};
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.lime};
  text-decoration: underline !important;
  text-underline-offset: 3px;

  &:hover {
    color: ${(props) => props.theme.neonPink};
  }
`;

function EmailForm({
  email,
  toDisabled,
  isBcc,
  apiUrl,
  closeModal,
  toSupport,
  helpText,
  messagePlaceholder,
  ...props
}) {
  // Generic form to send an email.

  const { addToast } = useToasts();
  const {
    handleSubmit,
    control,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: email,
  });

  async function onSubmit(payload) {
    // Send the data to the server to send the email.
    if (toSupport) payload.to = email.to;

    try {
      await axiosInstance.post(apiUrl, payload);
      addToast("Email sent", { appearance: "success" });
      closeModal();
    } catch (e) {
      setError("form", { message: "Error sending email" });
    }
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      {toSupport ? (
        <div className="text-center">
          <FormHelpText className="mb-0">
            Check out our <StyledLink to="/faq">FAQ</StyledLink>
          </FormHelpText>
        </div>
      ) : (
        helpText && <FormHelpText>{helpText}</FormHelpText>
      )}
      {!isBcc && !toSupport && (
        <StyledFormGroup label="To" errors={errors.to}>
          <Controller
            as={FormControl}
            disabled={toDisabled}
            name="to"
            defaultValue={email.to || []}
            control={control}
            isInvalid={errors.to !== undefined}
          />
        </StyledFormGroup>
      )}

      {isBcc && !toSupport && (
        <StyledFormGroup label="Bcc" errors={errors.bcc}>
          <Controller
            as={FormControl}
            disabled={toDisabled}
            name="bcc"
            defaultValue={email.bcc || []}
            control={control}
            isInvalid={errors.bcc !== undefined}
          />
        </StyledFormGroup>
      )}

      {toSupport && (
        <>
          <FormSubmitDivider />
          <FormHelpText className="my-3 text-center">
            Send us a note
          </FormHelpText>
        </>
      )}
      <FormGroup label="Subject" errors={errors.subject}>
        <Controller
          as={FormControl}
          rules={{ required: true }}
          name="subject"
          defaultValue={email.subject || ""}
          control={control}
          isInvalid={errors.subject !== undefined}
        />
      </FormGroup>

      <FormGroup label="Message" errors={errors.emailBody}>
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <Quill
              onBlur={onBlur}
              onChange={onChange}
              inputRef={ref}
              error={errors.emailBody}
              modules={{ toolbar: false }}
              placeholder={messagePlaceholder}
            />
          )}
          rules={{ required: true }}
          name="emailBody"
          defaultValue={email.emailBody}
          control={control}
          isInvalid={errors.emailBody !== undefined}
        />
      </FormGroup>

      <FormSubmitContainer errorText={errors.form && errors.form.message}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Send</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

EmailForm.propTypes = {
  /** Email data that is passed in to begin the form. */
  email: object,

  /** Function to close the modal the form is in. */
  closeModal: func.isRequired,

  /**
   * The url that we'll send the email data to.
   *
   * By passing in the url like this we maintain the form to be generic sending emails.
   */
  apiUrl: string.isRequired,

  /** Choose whether or not the to field should be disabled */
  toDisabled: bool,

  /** Determine if the email recipients should be on the bcc list */
  isBcc: bool,

  /** Help text to show on the support form. */
  helpText: string,

  /** Placeholder text to show in the message field. */
  messagePlaceholder: string,
};

EmailForm.defaultProps = {
  toDisabled: false,
  isBcc: false,
  email: {},
  helpText: null,
  messagePlaceholder: null,
};

export default EmailForm;
