import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";

import Button from "components/buttons/Button";
import BorderContainer from "components/containers/Border";
import ContainerHeader from "components/containers/Header";
import LoadingContainer from "components/loading/Container";
import SubheaderText from "components/text/SubHeader/TextOnly";
import useSupportModal from "hooks/SupportModal";
import DeleteProjectModal from "features/projects/modals/DeleteProject";
import ProjectManagementHeader from "features/projects/components/ManagementHeader";
import { updateProjectImage } from "features/projects/thunks";
import theme from "theme";
import { getProjectDescriptor, isClass } from "utils/projects";
import ProjectConfigurationForm from "features/projects/forms/Configuration";

const Wrapper = styled.div`
  min-height: 85vh;

  .col-12 {
    margin-top: ${(props) => props.theme.vSpacingSm};
    margin-bottom: ${(props) => props.theme.vSpacingSm};
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingLg};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0 ${(props) => props.theme.vSpacingSm};
  }
`;

const StyledBorderContainer = styled(BorderContainer)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingXl} 0;
  }
`;

const Note = styled.span`
  font-family: Basis Grotesque Mono;
  font-weight: 200;
`;

const StyledActionContainer = styled(BorderContainer)`
  min-height: 80vh;

  > button {
    // Only apply to the top-section buttons.
    margin: 14px 0;
  }

  button {
    text-transform: uppercase;
    width: 100%;
    padding: 14px;

    &:hover {
      transform: none;
    }
  }
`;

const DeleteButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 50px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 23px;
  }
`;

const ThumbnailImage = styled(Image)`
  cursor: pointer;
`;

function ManageProject() {
  /** Manage general properties of the project and perform project-wide actions.*/

  const project = useSelector((state) => state.projects.detail);
  const showSupportModal = useSupportModal();
  const thumbnailInput = useRef(null);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const _isClass = isClass(project);

  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <DeleteProjectModal project={project} onHide={hideDeleteModal} />
  ));

  const headerTitle = isClass(project) ? "class" : "project";

  async function handleImageChange(event) {
    // Event handler for when we update the project image.
    setThumbnailLoading(true);
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("image", file);

    const action = await dispatch(
      updateProjectImage({
        projectId: project.id,
        formData,
      })
    );
    setThumbnailLoading(false);
    if (action.type === "UPDATE_PROJECT/rejected")
      addToast("Error uploading file, please verify that it's a valid image.", {
        appearance: "error",
      });
  }

  function renderThumbnailUploader() {
    /** Render a section for the user to upload a project thumbnail. */

    if (thumbnailLoading) return <LoadingContainer text="Uploading image..." />;

    return (
      <>
        <ContainerHeader className="mt-2">
          <SubheaderText size="sm" tilt color={theme.lime}>
            class thumbnail
            <br />
            <Note>
              (1000<small>&#10005;</small>1440)
            </Note>
          </SubheaderText>
        </ContainerHeader>
        <Button
          noTilt
          className="p-2"
          onClick={() => thumbnailInput.current.click()}
        >
          Upload Image
        </Button>
      </>
    );
  }

  return (
    <Wrapper>
      <input
        id="thumbnail-image"
        style={{ display: "none" }}
        type="file"
        onChange={handleImageChange}
        ref={thumbnailInput}
      />
      <ProjectManagementHeader title={`${headerTitle} management`} />
      <Row>
        <Col xs={12} sm={4}>
          {project.image && !thumbnailLoading ? (
            <ThumbnailImage
              fluid
              src={project.image}
              alt={`Project City - ${project.title}`}
              onClick={() => thumbnailInput.current.click()}
            />
          ) : (
            <StyledBorderContainer fill padding={false}>
              {renderThumbnailUploader()}
            </StyledBorderContainer>
          )}
        </Col>
        <Col xs={12} sm={4}>
          <StyledActionContainer borderType="solid" className="h-100">
            <SubheaderText size="sm" tilt color={theme.orange} className="mb-2">
              configuration
            </SubheaderText>
            <ProjectConfigurationForm project={project} />
          </StyledActionContainer>
        </Col>
        <Col xs={12} sm={4}>
          <StyledActionContainer borderType="solid" className="h-100">
            <SubheaderText size="sm" tilt color={theme.lime} className="mb-2">
              {_isClass ? "class" : "project"} actions
            </SubheaderText>
            <Button onClick={showSupportModal}>Need support?</Button>
            <DeleteButtonContainer>
              <Button onClick={showDeleteModal} variant="danger">
                Delete {getProjectDescriptor(project)}
              </Button>
            </DeleteButtonContainer>
          </StyledActionContainer>
        </Col>
        <Col></Col>
      </Row>
    </Wrapper>
  );
}

export default ManageProject;
