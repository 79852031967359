import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { navigate } from "@reach/router";

import BaseModal from "components/modals/Base";
import ModalSummaryText from "components/text/ModalSummaryText";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import { getProjectManagementBaseUrl } from "utils/projects";

const Link = styled.span`
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.neonPink};
  }
`;

function UploadPosterImageModal({ project, ...props }) {
  /** Show the user that they need approval before they can crate a class. */

  function navigateProjectSettings() {
    navigate(`${getProjectManagementBaseUrl(project)}/project`);
  }

  return (
    <BaseModal {...props}>
      <Modal.Body>
        <ModalSummaryText>
          You must first upload a project poster in your{" "}
          <Link onClick={navigateProjectSettings}>project settings</Link> before
          going public.
        </ModalSummaryText>
        <FormSubmitContainer>
          <FormSecondaryButton onClick={props.onHide}>
            Cancel
          </FormSecondaryButton>
          <FormPrimaryButton onClick={navigateProjectSettings}>
            Take me there
          </FormPrimaryButton>
        </FormSubmitContainer>
      </Modal.Body>
    </BaseModal>
  );
}

export default UploadPosterImageModal;
