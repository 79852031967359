import React, { useState, useEffect } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Button from "components/buttons/Button";
import EnrollmentFilterDropdown from "components/dropdowns/EnrollmentFilter";
import FormModal from "components/modals/Form";
import EnrollmentForm from "features/enrollments/forms/Enrollment";
import EnrollmentsTable from "components/tables/Enrollments";
import EmailForm from "components/forms/Email";
import ManageProjectHeader from "features/projects/components/ManagementHeader";
import { baseUrl } from "features/api";

const Wrapper = styled.div`
  min-height: 90vh;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ActionContainer = styled.div`
  display: flex;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    flex-basis: 33%;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    width: 100%;

    > div {
      width: 100%;
      margin-right: 0 !important;
    }

    button {
      width: 100%;
    }
  }
`;

const SubText = styled.h3`
  font-family: SuisseIntl;
  font-size: 34px;
  line-height: 34px;
  color: ${(props) => props.theme.pink};
  position: absolute;
  bottom: 35px;
  width: 100%;
  text-align: center;
  transform: rotate(-3deg);
`;

const RightButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > button {
    text-transform: uppercase;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: ${(props) => props.theme.vSpacingXs} 0;

    > button {
      margin: ${(props) => props.theme.vSpacingXs} 0 !important;
    }
  }
`;

function ManageEnrollmentsRoute() {
  /** Route for a project admin to manage their students. */

  const enrollments = useSelector((state) => state.projects.detail.enrollments);
  const [filteredEnrollments, setFilteredEnrollments] = useState(enrollments);
  const [filters, setFilters] = useState({
    tiers: [],
    statuses: [],
    name: "",
  });

  const [showEnrollmentModal, hideEnrollmentModal] = useModal(() => {
    return (
      <FormModal title="Add student" onHide={hideEnrollmentModal}>
        <EnrollmentForm closeModal={hideEnrollmentModal} />
      </FormModal>
    );
  });

  const [showBulkEmailModal, hideBulkEmailModal] = useModal(() => {
    // Opens a modal to send an email to multiple enrollments.
    const bcc = [
      ...new Set(
        filteredEnrollments.map((enrollment) => enrollment.student.email)
      ),
    ];

    return (
      <FormModal title="Send bulk email" onHide={hideBulkEmailModal}>
        <EmailForm
          toDisabled
          isBcc
          apiUrl={`${baseUrl}email/`}
          email={{ bcc }}
          closeModal={hideBulkEmailModal}
        />
      </FormModal>
    );
  }, [filteredEnrollments]);

  useEffect(() => {
    /** When the filters change, we should change the filtered enrollments. */
    const filtered = enrollments
      .filter((enrollment) => {
        if (filters.statuses.length === 0) return true;
        return filters.statuses.includes(enrollment.status);
      })
      .filter((enrollment) => {
        if (filters.tiers.length === 0) return true;
        return filters.tiers.includes(enrollment.registrationTier.id);
      })
      .filter((enrollment) => {
        if (filters.name === "") return true;

        // Filter on name or username
        const { name, username } = enrollment.student;
        return (
          name.toLowerCase().includes(filters.name.toLowerCase()) ||
          username.toLowerCase().includes(filters.name.toLowerCase())
        );
      });
    setFilteredEnrollments(filtered);
  }, [enrollments, filters]);

  function getTierOptions() {
    // Returns an array of distinct tier objects to pass into the select component.
    let distinctArray = [];
    enrollments.forEach((enrollment) => {
      const { id, title } = enrollment.registrationTier;
      const exists = distinctArray.filter(
        (obj) => obj.value === enrollment.registrationTier.id
      );
      if (exists.length === 0) distinctArray.push({ value: id, label: title });
    });

    return distinctArray;
  }

  function renderActions() {
    // Return the bulk select and table-level actions.
    return (
      <ActionContainer>
        <EnrollmentFilterDropdown
          filterState={[filters, setFilters]}
          tierOptions={getTierOptions()}
        />
      </ActionContainer>
    );
  }

  return (
    <Wrapper className="my-4">
      <HeaderContainer>
        <div className="position-relative">
          <ManageProjectHeader
            title="Students"
            tooltipText="View and manage the enrollments for your classes."
            subtitle={`(${filteredEnrollments.length})`}
          />
          <SubText>({filteredEnrollments.length})</SubText>
        </div>
        {renderActions()}
        <RightButtonContainer>
          <Button className="mr-1" onClick={showBulkEmailModal}>
            Bulk Email
          </Button>
          <Button className="d-block ml-auto" onClick={showEnrollmentModal}>
            Add Student
          </Button>
        </RightButtonContainer>
      </HeaderContainer>
      <EnrollmentsTable enrollments={filteredEnrollments} />
    </Wrapper>
  );
}

export default ManageEnrollmentsRoute;
