import React, { useState } from "react";
import { bool, number } from "prop-types";
import styled from "styled-components";
import Button from "components/buttons/Button";

import MentionsDropDown from "components/dropdowns/mentions";
import FormControl from "components/forms/shared/Control";
import useAuthenticationModal from "hooks/AuthModal";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { useCreateCommentMutation } from "services/projectCity";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacingLg};
  border-top: 1px solid ${(props) => props.theme.gray300};
  padding: 0px;
  background-color: ${(props) => props.theme.gray2};
  border: 2px solid ${(props) => props.theme.gray2};
`;

const StyledFormControl = styled(FormControl)`
  background-color: rgba(30, 16, 16, 0.3) !important;
  margin-top: 3px;
  font-size: 18px;
  line-height: 26px;
  font-family: Basis Grotesque !important;
  color: ${(props) => props.theme.lime} !important;
  font-weight: 200 !important;

  // Set the text more in middle
  padding-top: 3px;

  &::placeholder {
    color: ${(props) => props.theme.lime};
  }
`;

function NewCommentContainer({
  contentTypeId,
  objectId,
  postId,
  inModal,
  ...props
}) {
  /** Container to create a new comment on a post object. */

  const isAuthenticated = useIsAuthenticated();
  const showAuthModal = useAuthenticationModal();
  const [createComment, { isLoading }] = useCreateCommentMutation();

  const [newComment, setNewComment] = useState("");
  const [mentionWord, setMentionWord] = useState("");
  const [mentions, setMentions] = useState([]);

  async function handleCreateComment() {
    /** Handle action when we create a new comment. */

    let text = newComment;
    mentions.forEach((mention) => {
      text = text.replace(`@${mention.username}`, `<@${mention.id}>`);
    });

    createComment({
      objectId,
      contentType: contentTypeId,
      text,
      addToPreview: !inModal,
    });

    setNewComment("");
  }

  function handleChange(e) {
    /** Update the local state and set the mention data. */
    setNewComment(e.target.value);
    const matches = [...e.target.value.matchAll(/@[^\s]*[^\s]$/g)];
    if (matches.length) {
      const word = matches[matches.length - 1][0].slice(1);
      setMentionWord(word);
    } else {
      setMentionWord("");
    }
  }

  function handleKeyDown(e) {
    /** Submit the comment if the user hits enter.  */
    if (e.key === "Enter")
      isAuthenticated ? handleCreateComment() : showAuthModal();
  }

  return (
    <>
      <Wrapper {...props}>
        <StyledFormControl
          variant="secondary"
          placeholder="Write feedback ..."
          value={newComment}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="clear-primary"
          className="px-3"
          onClick={isAuthenticated ? handleCreateComment : showAuthModal}
          disabled={newComment === "" && isLoading}
        >
          Submit
        </Button>
      </Wrapper>
      {mentionWord && (
        <MentionsDropDown
          isShown={true}
          username={mentionWord}
          setMentionWord={setMentionWord}
          setNewComment={setNewComment}
          newComment={newComment}
          setMentions={setMentions}
          mentions={mentions}
        />
      )}
    </>
  );
}

NewCommentContainer.propTypes = {
  /** The content type id of the related object. */
  contentTypeId: number.isRequired,

  /** The related object's id. */
  objectId: number.isRequired,

  /** Optionally pass in a post id to add the comment to a post's display. */
  postId: number,

  /** Show if the comment container is in modal or in main app section. */
  inModal: bool,
};

NewCommentContainer.defaultProps = {
  postId: null,
};

export default NewCommentContainer;
