import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import { bool, func, shape, string } from "prop-types";
import { faChartPie, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "components/buttons/Button";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormDangerButton from "components/buttons/forms/FormConfirmDanger";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import Select from "components/controls/Select";
import ToggleBox from "components/controls/ToggleBox";
import FormControl from "components/forms/shared/Control";
import BaseForm from "components/forms/Base";
import ErrorText from "components/forms/shared/ErrorText";
import FormGroup from "components/forms/shared/FormGroup";
import DateTimePicker from "components/forms/datetime/DateTimePicker";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import useRegistrationTierChoices from "hooks/RegistrationTierChoices";
import useSetFieldErrors from "hooks/SetFieldErrors";
import {
  createRegistrationTier,
  updateRegistrationTier,
} from "features/tiers/thunks";
import { RegistrationTier } from "types";

const ColRight = styled(Col)`
  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    padding-left: 35px !important;
  }
`;

const ColLeft = styled(Col)`
  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    padding-right: 35px !important;
  }
`;
function RegistrationTierForm({
  registrationTier,
  closeModal,
  confirmDelete,
  activateSplitSection,
  activateDescriptionSection,
  isLoadingSplits,
  setActiveRegistrationTier,
  tierCreatedAction,
  saveText,
  setCreatedTierId,
  ...props
}) {
  // Form to handle various crud actions on the registration tier resource.

  const { handleSubmit, control, errors, setError } = useForm({
    defaultValues: registrationTier || {},
  });
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [formErrorText, setFormErrorText] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const project = useSelector((state) => state.projects.detail);
  const user = useSelector((state) => state.account.user);
  const setFieldErrors = useSetFieldErrors(setError);
  const tierChoices = useRegistrationTierChoices();

  // Determine if we're creating or updating a tier.
  const isUpdating = registrationTier && registrationTier.id !== undefined;

  const onSubmit = async (data) => {
    // Send action to either create or update a registration tier.
    setFormErrorText(null);
    setIsSubmitting(true);

    // prepare related descriptions to be uploaded
    if (data.descriptions) {
      data.descriptions = data.descriptions.map((description) => {
        return { desc: description.desc };
      });
    }

    // For certain fields, if they are a blank string they should be converted to null;
    const convertToNullFields = [
      "registrationStartDate",
      "registrationEndDate",
      "price",
      "maximumEnrollments",
    ];

    convertToNullFields.forEach((fieldName) => {
      if (data[fieldName] === "") data[fieldName] = null;
    });

    let action = new Promise(() => {});

    if (registrationTier) {
      // If there is time passed in for date fields, we need to clean them.
      action = await dispatch(
        updateRegistrationTier({
          registrationTierId: registrationTier.id,
          payload: data,
        })
      );
    } else {
      data.project = project.id;
      action = await dispatch(createRegistrationTier(data));
    }

    // Now we can handle the action.
    if (action.type === "UPDATE_REGISTRATION_TIER/fulfilled") {
      addToast("Registration tier updated successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      closeModal();
    } else if (action.type === "UPDATE_REGISTRATION_TIER/rejected") {
      setFieldErrors(action.payload.data);

      const errorText =
        action.payload.data &&
        (action.payload.data.detail || "Error updating registration tier.");
      setFormErrorText(errorText);
    } else if (action.type === "CREATE_REGISTRATION_TIER/fulfilled") {
      setActiveRegistrationTier(action.payload);
      tierCreatedAction();
      setCreatedTierId(action.payload.id);
    } else if (action.type === "CREATE_REGISTRATION_TIER/rejected") {
      setFieldErrors(action.payload.data);
      const errorText =
        action.payload.data &&
        (action.payload.data.detail || "Error creating registration tier.");
      setFormErrorText(errorText);
    }

    setIsSubmitting(false);
    return action;
  };

  function renderSubmitFooter() {
    /** Depending on if we are editing a tier and/or if there are enrollments, the
     * submit footer will change depending on what's available for that tier.
     */

    if (registrationTier) {
      // Only should render here if editing a registration tier.

      const deleteButton = isUpdating ? (
        <FormDangerButton type="button" onClick={confirmDelete}>
          Delete Tier
        </FormDangerButton>
      ) : null;

      // The button text for viewing/manage splits changes depending on if owner.
      const splitVerb = project.owner === user.id ? "MANAGE" : "VIEW";

      return (
        <FormSubmitContainer className="hello">
          {deleteButton}
          <Button onClick={activateDescriptionSection} width={150}>
            <FontAwesomeIcon icon={faList} /> DESCRIPTIONS
          </Button>
          {project.teachers.length > 1 && (
            <Button
              isLoading={isLoadingSplits}
              onClick={activateSplitSection}
              width={150}
            >
              <FontAwesomeIcon icon={faChartPie} /> {splitVerb} SPLIT
            </Button>
          )}
          <FormPrimaryButton type="submit" isLoading={isSubmitting}>
            {saveText}
          </FormPrimaryButton>
        </FormSubmitContainer>
      );
    }

    return (
      <FormSubmitContainer>
        <FormSecondaryButton onClick={closeModal}>CANCEL</FormSecondaryButton>
        <FormPrimaryButton type="submit" isLoading={isSubmitting}>
          {saveText}
        </FormPrimaryButton>
      </FormSubmitContainer>
    );
  }

  function renderDefaultPrereqs() {
    return !registrationTier
      ? []
      : registrationTier.prereqTiers.map((tierId) => {
          const fullTier = project.tiers.find((tier) => tier.id === tierId);
          return { value: fullTier.id, label: fullTier.title };
        });
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <Form.Row>
        <ColLeft sm={6}>
          <FormGroup label="Title*" errors={errors.title}>
            <Controller
              as={FormControl}
              rules={{ required: true }}
              isInvalid={errors.title !== undefined}
              name="title"
              control={control}
              defaultValue=""
            />
          </FormGroup>
        </ColLeft>
        <ColRight sm={6}>
          <FormGroup label="Price" errors={errors.price}>
            <Controller
              as={FormControl}
              name="price"
              control={control}
              defaultValue=""
              isInvalid={errors.price !== undefined}
            />
          </FormGroup>
        </ColRight>
      </Form.Row>

      <Form.Row>
        <ColLeft sm={6}>
          <FormGroup
            label="Registration Start On"
            errors={errors.registrationStartDate}
            isCustomControl
          >
            <Controller
              as={DateTimePicker}
              isInvalid={errors.registrationStartDate !== undefined}
              name="registrationStartDate"
              control={control}
              defaultValue=""
            />
          </FormGroup>
        </ColLeft>
        <ColRight sm={6}>
          <FormGroup
            label="Registration Ends On"
            errors={errors.registrationEndDate}
            isCustomControl
          >
            <Controller
              as={DateTimePicker}
              isInvalid={errors.registrationEndDate !== undefined}
              name="registrationEndDate"
              control={control}
              defaultValue=""
            />
          </FormGroup>
        </ColRight>
      </Form.Row>

      <Form.Row>
        <ColLeft sm={6}>
          <FormGroup
            label="Maximum Enrollments"
            errors={errors.maximumEnrollments}
            tooltipText={`
              Specify the total number of students that can be enrolled in this tier.
              If you later want to increase the number of available enrollments, simply
              add to this number. If you want unlimited enrollments, leave this box
              blank.
            `}
          >
            <Controller
              as={<FormControl />}
              isInvalid={errors.maximumEnrollments !== undefined}
              name="maximumEnrollments"
              control={control}
              defaultValue=""
            />
          </FormGroup>
        </ColLeft>
      </Form.Row>

      <Form.Row>
        <ColLeft sm={6}>
          <FormGroup
            label="Who can see this tier"
            isCustomControl
            tooltipText="Choose who this payment tier option is available to on the class info page."
          >
            <Controller
              render={({ onChange, ref }) => (
                <Select
                  options={tierChoices}
                  isMulti
                  ref={ref}
                  closeMenuOnSelect={false}
                  onChange={(objects) =>
                    onChange(objects.map((obj) => obj.value))
                  }
                  defaultValue={renderDefaultPrereqs()}
                  placeholder="Everyone"
                />
              )}
              control={control}
              name="prereqTiers"
            />
          </FormGroup>
        </ColLeft>
        <ColRight sm={6}>
          <ToggleBox
            label="IS VISIBLE"
            controllerProps={{ name: "isVisible", control }}
            tooltipText="Determine if payment tier button is visible on the class info page."
          />
        </ColRight>
      </Form.Row>
      <ErrorText text={formErrorText} />
      {renderSubmitFooter()}
    </BaseForm>
  );
}

RegistrationTierForm.propTypes = {
  /** The registration tier that we're modifying. When creating new tier, this will be undefined. */
  registrationTier: shape(RegistrationTier),

  /** A function to run when the user wants to delete the registration tier. */
  confirmDelete: func.isRequired,

  /** Custom action to trigger after the registration tier is created. */
  tierCreatedAction: func.isRequired,

  /** A function to close the modal if the form is inside of one. */
  closeModal: func,

  /** Change the modal to display the revenue split section. */
  activateSplitSection: func,

  /** Determine if the revenue split data is loading. */
  isLoadingSplits: bool,

  /** Function to activate the registration tier in parent modal. */
  setActiveRegistrationTier: func,

  /** Text to show on the save button. */
  saveText: string,

  /** Optional action to set the created registration tier id in the parent component. */
  setCreatedTierId: func,
};

RegistrationTierForm.defaultProps = {
  registrationTier: null,
  closeModal: () => {},
  setActiveRegistrationTier: () => {},
  isLoadingSplits: false,
  saveText: "Save",
  setCreatedTierId: () => {},
};

export default RegistrationTierForm;
