import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";

import FormControl from "components/forms/shared/Control";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import Quill from "components/controls/Quill";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";

import Select from "components/controls/Select";
import ProjectService from "features/projects/services/project";
import { getFullName } from "utils/users";

import "react-quill/dist/quill.snow.css";

function ProjectContactForm({ project, closeModal }) {
  /** Send a contact email to a project. */

  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const { addToast } = useToasts();

  async function onSubmit(data) {
    setErrorMessage("");
    const service = new ProjectService();
    data.users = data.users.map((obj) => obj.value);

    try {
      const response = await service.sendContactEmail(project.id, data);
      addToast("Your message has been sent successfully!", {
        appearance: "success",
      });
      closeModal();
      return response;
    } catch (err) {
      const message =
        err.response.data && err.response.data.detail
          ? err.response.data.detail
          : "Error sending contact email.";
      setErrorMessage(message);
    }
  }

  const teacherOptions = project.teachers.map((teacher) => ({
    value: teacher.id,
    label: getFullName(teacher),
  }));

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="To" errors={errors.users}>
        <Controller
          as={Select}
          options={teacherOptions}
          isMulti
          name="users"
          control={control}
          rules={{
            required: true,
            minLength: 1,
          }}
          defaultValue={teacherOptions}
          isInvalid={errors.users !== undefined}
          isDisabled={project.teachers.length === 1}
        />
      </FormGroup>
      <FormGroup label="Subject" errors={errors.subject}>
        <Controller
          as={FormControl}
          isInvalid={errors.subject !== undefined}
          rules={{ required: true, maxLength: 200 }}
          name="subject"
          control={control}
          defaultValue=""
        />
      </FormGroup>
      <FormGroup label="Email body" errors={errors.body} isCustomControl>
        <Controller
          as={Quill}
          isInvalid={errors.body !== undefined}
          rules={{ required: true, maxLength: 10000 }}
          name="body"
          control={control}
          defaultValue=""
        />
      </FormGroup>
      <FormSubmitContainer errorText={errorMessage}>
        <FormPrimaryButton isLoading={isSubmitting}>Send</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

ProjectContactForm.propTypes = {
  // The project that we'll be contacting.
  project: PropTypes.object.isRequired,

  closeModal: PropTypes.func.isRequired,
};

export default ProjectContactForm;
