import React from "react";
import { arrayOf, shape } from "prop-types";
import moment from "moment";

import BaseTable from "components/tables/Base";
import ColumnHeader from "components/tables/ColumnHeader";
import { DonationTransfer } from "features/donations/types";
import { toCurrency } from "utils/general";
import DonationTransferDetailButton from "features/payments/components/DonationTransferDetailButton";

function DonationTransferTable({ donationTransfers }) {
  /** Show the transfers from donations that a user has received. */

  function renderRows() {
    return donationTransfers.map((donationTransfer) => (
      <tr>
        <td>
          <DonationTransferDetailButton
            donationTransferId={donationTransfer.id}
          />
        </td>
        <td>{moment(donationTransfer.created).format("ll")}</td>
        <td>{toCurrency(donationTransfer.amount / 100)}</td>
        <td>{donationTransfer.transferId}</td>
      </tr>
    ));
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <ColumnHeader></ColumnHeader>
          <ColumnHeader>Date</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Transfer Id</ColumnHeader>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </BaseTable>
  );
}

DonationTransferTable.propTypes = {
  donationTransfers: arrayOf(shape(DonationTransfer)).isRequired,
};

export default DonationTransferTable;
