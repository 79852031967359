import React from "react";
import { bool } from "prop-types";

import theme from "theme";

function StepDivider({ isComplete }) {
  return (
    <svg
      style={{ marginLeft: 10 }}
      width="440"
      height="2"
      viewBox="0 0 440 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1"
        x2="440"
        y2="1"
        stroke={isComplete ? theme.yellow : theme.blue}
        stroke-opacity="1"
        stroke-width="2"
        stroke-dasharray="20 20"
      />
    </svg>
  );
}

StepDivider.propTypes = {
  /** Determine if the step has been complete, changing its style. */
  isComplete: bool,
};

StepDivider.defaultProps = {
  isComplete: false,
};

export default StepDivider;
