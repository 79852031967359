import styled from "styled-components";
import { object } from "prop-types";

import Caption from "components/text/Caption";
import LikeContainer from "features/likes/components/Container";

const SummaryStats = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function TileStatsContainer({ object }) {
  /** Show comments and like stats of an object tile (i.e. contest entry, assignment entry, etc). */
  return (
    <SummaryStats>
      <LikeContainer count={object.likesCount} actionable={false} />
      <Caption className="mb-0" size="sm" color="neonPink">
        {object.commentCount} comments
      </Caption>
    </SummaryStats>
  );
}

TileStatsContainer.propTypes = {
  /** A generic object that has likes and comments. */
  object: object.isRequired,
};

export default TileStatsContainer;
