import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import Image from "react-bootstrap/Image";
import { openPdf } from "utils/general";

import Body from "components/text/Body";
import BucketUploadModal from "components/modals/BucketUpload";
import PdfIcon from "images/icons/pdf.png";
import ZipIcon from "images/icons/zip-icon.png";
import VideoIcon from "images/icons/video.png";
import { bucketUploadType } from "utils/enums";
import Video from "components/videos/Video";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  padding: 50px 0;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 70px 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

const StyleFileImg = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

function FileUploadDisplay({ bucketUpload }) {
  /** Render the content that should be shown for the bucket upload. */

  const [showUploadModal, hideUploadModal] = useModal(() => {
    function getModalContent() {
      /**
       * Return the content that should be displayed in the modal, which depends on the
       * upload type.
       */

      switch (bucketUpload.kind) {
        case bucketUploadType.video:
          return (
            <Video autoPlay src={bucketUpload.uploadFile} type="video/mp4" />
          );
        case bucketUploadType.pdf:
          return (
            <StyleFileImg
              src={PdfIcon}
              alt=""
              style={{ width: "30%" }}
              onClick={(e) => openPdf(e, bucketUpload.uploadFile)}
              className="m-5"
            />
          );
        case bucketUploadType.audio:
          return (
            <audio controls className="m-5">
              <source src={bucketUpload.uploadFile} type="audio/mpeg" />
            </audio>
          );
        case bucketUploadType.zip:
          return (
            <StyleFileImg
              src={ZipIcon}
              alt=""
              style={{ width: "30%" }}
              onClick={downloadFile}
              className="m-5"
            />
          );

        default:
          return null;
      }
    }

    return (
      <BucketUploadModal onHide={hideUploadModal} bucketUpload={bucketUpload}>
        {getModalContent()}
      </BucketUploadModal>
    );
  }, [bucketUpload]);

  function getFilename() {
    // Returns the filename, given the pdf's path.
    const filenameStart = bucketUpload.uploadFile.lastIndexOf("/") + 1;
    return bucketUpload.uploadFile.slice(filenameStart);
  }

  function downloadFile(e) {
    e.stopPropagation();

    const a = document.createElement("a");
    const chunks = bucketUpload.uploadFile.split(/.\//);
    a.href = bucketUpload.uploadFile;
    a.setAttribute("download", chunks[chunks.length - 1]);
    a.click();
  }

  function getContent() {
    // Return the content to show in the container, based on the upload type.
    switch (bucketUpload.kind) {
      case bucketUploadType.video:
        return <img src={VideoIcon} alt="" style={{ width: "50%" }} />;
      case bucketUploadType.pdf:
        return (
          <img
            src={PdfIcon}
            alt=""
            style={{ width: "50%" }}
            onClick={showUploadModal}
          />
        );
      case bucketUploadType.zip:
        return (
          <img
            src={ZipIcon}
            alt=""
            style={{ width: "50%" }}
            onClick={downloadFile}
          />
        );
      case bucketUploadType.audio:
        return (
          <audio controls onClick={(e) => e.stopPropagation()}>
            <source src={bucketUpload.uploadFile} type="audio/mpeg" />
          </audio>
        );
      default:
        return null;
    }
  }

  // TODO: Temp fix for not showing uploads without a file.
  if (!bucketUpload.uploadFile) return null;

  if (bucketUpload.thumbnail) {
    return (
      <Image fluid src={bucketUpload.thumbnail} onClick={showUploadModal} />
    );
  }

  return (
    <Wrapper onClick={showUploadModal} createdBy={bucketUpload.createdBy}>
      <InnerWrapper>
        {bucketUpload.thumbnail ? (
          <Image fluid src={bucketUpload.thumbnail} onClick={showUploadModal} />
        ) : (
          <>
            <div>{getContent()}</div>
            <Body inline color="neonPink" className="mt-3">
              {getFilename()}
            </Body>
          </>
        )}
      </InnerWrapper>
    </Wrapper>
  );
}

FileUploadDisplay.propTypes = {
  /** The bucket upload object that we're rendering the display for. */
  bucketUpload: PropTypes.object,
};

export default FileUploadDisplay;
