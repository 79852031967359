import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { bool, shape } from "prop-types";

import FormHelpText from "components/forms/shared/HelpText";
import ConfirmActionModal from "components/modals/ConfirmAction";
import FormModal from "components/modals/Form";
import BucketForm from "features/buckets/forms/Bucket";
import { setCurrentBucket } from "features/buckets/slice";
import { deleteBucket } from "features/buckets/thunks";
import { bucketType } from "utils/enums";
import { getProjectUrl } from "utils/projects";
import Button from "components/buttons/Button";
import Body from "components/text/Body";
import { Bucket } from "types";
import { setIsReordering } from "features/sprint/slice";

const BucketActionsSection = styled.div`
  width: 250px;
  padding: 20px;
  border: 2px dashed ${(props) => props.theme.blue};
  color: ${(props) => props.theme.yellow};
  ${(props) => props.$pushRight && `margin-left: auto;`}

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    button {
      font-size: 17px !important;
      line-height: 18px !important;
    }
  }
`;

function BucketActions({ pushRight, bucket, ...props }) {
  /** Provide actions to perform on a bucket page. */

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const project = useSelector((state) => state.projects.detail);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const { isReordering } = useSelector((state) => state.contentUploads);

  const [showEditBucketModal, hideEditBucketModal] = useModal(() => {
    // Handle the bucket modal for creating a new bucket..
    return (
      <FormModal onHide={hideEditBucketModal}>
        <BucketForm
          bucket={bucket}
          closeModal={hideEditBucketModal}
          showAllFields
        />
      </FormModal>
    );
  }, [project, bucket]);

  const [
    showConfirmDeleteBucketModal,
    hideConfirmDeleteBucketModal,
  ] = useModal(() => {
    // Modal for confirming the deletion of a bucket.

    function handleDelete() {
      // Handles the delete action of the confirmation modal.
      setIsDeleteLoading(true);
      dispatch(deleteBucket(bucket.id));
      navigate(getProjectUrl(project));
      dispatch(setCurrentBucket({}));
      hideConfirmDeleteBucketModal();
      addToast("Page deleted successfully", { appearance: "success" });
      setIsDeleteLoading(false);
    }

    if (!bucket) return null;

    let bucketObjectsLabel = "items";
    if (bucket.kind === bucketType.images) {
      bucketObjectsLabel = "uploads";
    } else if (bucket.kind === bucketType.feed) {
      bucketObjectsLabel = "messages";
    }

    return (
      <ConfirmActionModal
        confirmAction={handleDelete}
        onHide={hideConfirmDeleteBucketModal}
        errorMessage={deleteErrorMessage}
        isLoading={isDeleteLoading}
        title="Delete page"
        isDanger
      >
        <FormHelpText className="mb-0">
          Are you sure you want to delete the <b>"{bucket.title}"</b> bucket? By
          deleting this bucket you will irreversibly delete its associated{" "}
          {bucketObjectsLabel} too.
        </FormHelpText>
      </ConfirmActionModal>
    );
  }, [bucket, isDeleteLoading, deleteErrorMessage]);

  const showReorderButton = [bucketType.animatics, bucketType.lessons].includes(
    bucket.kind
  );

  return (
    <BucketActionsSection $pushRight={pushRight} {...props}>
      <Body className="mb-2">Page Actions:</Body>
      <Button block className="mb-2" onClick={showEditBucketModal}>
        Edit Page
      </Button>
      <Button
        block
        className="mb-2"
        onClick={() => {
          setDeleteErrorMessage("");
          showConfirmDeleteBucketModal();
        }}
      >
        Delete Page
      </Button>
      {isReordering
        ? showReorderButton && (
            <Button
              block
              variant="info"
              className="mb-2 d-none d-md-block"
              onClick={() => dispatch(setIsReordering(false))}
            >
              Deactivate Reorder
            </Button>
          )
        : showReorderButton && (
            <Button
              block
              className="mb-2 d-none d-md-block"
              onClick={() => dispatch(setIsReordering(true))}
            >
              Activate Reorder
            </Button>
          )}
    </BucketActionsSection>
  );
}

BucketActions.propTypes = {
  /** The bucket that will have actions performed on it. */
  bucket: shape(Bucket).isRequired,

  /** Determine if the settings section should be pushed to the right. */
  pushRight: bool,
};

BucketActions.defaultProps = {
  pushRight: true,
};

export default BucketActions;
