import React, { useState } from "react";
import { func } from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import BaseForm from "components/forms/Base";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FileUploadFormGroup from "components/forms/fields/FileUploadFormGroup";
import ToggleBox from "components/controls/ToggleBox";
import { updateBucketUpload } from "features/bucketUploads/thunks";
import { generateFormData } from "utils/forms";

function BucketUploadForm({ bucketUpload, closeModal, ...props }) {
  /** Form for updating a bucket upload instance. */

  const [formError, setFormError] = useState();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      isAnonymous: bucketUpload.isAnonymous,
    },
  });
  const { addToast } = useToasts();

  async function onSubmit(data) {
    /** When we create a project, there are some default values that need to be set. */

    // Clean the anonymous data.
    data.isAnonymous === "off"
      ? (data.is_anonymous = false)
      : (data.is_anonymous = true);

    if (data.thumbnail === null) delete data["thumbnail"];

    const formData = generateFormData(data);

    const action = await dispatch(
      updateBucketUpload({
        bucketUploadId: bucketUpload.id,
        payload: formData,
      })
    );

    if (action.type.includes("fulfilled")) {
      closeModal();
      addToast("Page upload updated.", { appearance: "success" });
    } else {
      setFormError("Error updating page upload.");
    }
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <ToggleBox
        label="Anonymous"
        offLabel="NO"
        onLabel="YES"
        controllerProps={{
          name: "isAnonymous",
          control,
        }}
        toolTipText="Choose whether or not to show your username with the bucket upload."
      />
      <FileUploadFormGroup
        label="Thumbnail"
        field="thumbnail"
        errors={errors}
        control={control}
      />
      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>Update</FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

BucketUploadForm.propTypes = {
  /** Function to close the parent modal. */
  closeModal: func.isRequired,
};

export default BucketUploadForm;
