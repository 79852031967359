import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import FooterNewsletter from "features/newsletter/components/Footer";
import useSupportModal from "hooks/SupportModal";
import FooterBackground from "images/backgrounds/footer_nav.svg";
import Instagram from "images/icons/social/v2/instagram.svg";
import TikTok from "images/icons/social/v2/tiktok.svg";
import Twitter from "images/icons/social/v2/twitter.svg";
import Discord from "images/icons/social/v2/discord.svg";

const StyledContainer = styled(Container)`
  background-image: url("${FooterBackground}");
  background-size: cover;
  background-color: transparent;
`;

const Wrapper = styled(Row)`
  font-family: "Basis Grotesque Mono";

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingSm} 50px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: ${(props) => props.theme.vSpacingSm};
  }

  // Cover the wavy background
  margin-top: -3px;
  align-items: center;
`;

const LeftCol = styled(Col)`
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.lime};

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingXs};
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.yellow};
  padding: 0px ${(props) => props.theme.vSpacingSm};
  font-weight: bold;
  font-size: 18px;
  font-weight: 22px;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0px ${(props) => props.theme.vSpacingXs};
  }
`;

const StyledAnchor = styled.a`
  // Same styling as the StyledLink, but this one has a click event and is different...

  color: ${(props) => props.theme.yellow};
  padding: 0px ${(props) => props.theme.vSpacingSm};
  font-weight: bold;
  font-size: 18px;
  font-weight: 22px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    padding: 0px ${(props) => props.theme.vSpacingXs};
  }
`;

const RightCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    text-align: right;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    text-align: center;
    margin-top: ${(props) => props.theme.vSpacingXs};
    justify-content: center;
  }

  img {
    transition: transform ${(props) => props.theme.transitionSuperFast} ease;
    padding: 0 10px;
    cursor: pointer;

    &:hover {
      transform: scale(1.2) rotate(20deg);
    }
  }
`;

const ProjectCityCopyright = styled.span`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-right: 20px;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    display: block;
    text-align: center;
    margin-bottom: ${(props) => props.theme.vSpacingSm};
  }
`;

const LinksContainer = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    display: inline;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    text-align: center;
  }
`;

const FooterNewsletterWrapper = styled.div`
  margin: -9px 0;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    display: none;
  }
`;

function SiteFooter() {
  /** Provide a footer with links to general information about the site/product. */
  const isAuthenticated = useSelector((state) => state.account.token !== "");
  const showContactModal = useSupportModal();

  return (
    <StyledContainer fluid>
      <Wrapper>
        <LeftCol xs={12} sm={6}>
          <ProjectCityCopyright>Project City &#169; 2023</ProjectCityCopyright>
          <LinksContainer>
            <StyledLink to="/privacy-policy">Privacy</StyledLink>
            <StyledLink to="/terms-of-use">Terms</StyledLink>
            <StyledLink to="/faq">FAQ</StyledLink>
            {isAuthenticated && (
              <StyledAnchor onClick={showContactModal}>Support</StyledAnchor>
            )}
          </LinksContainer>
        </LeftCol>
        <RightCol xs={12} sm={6}>
          <FooterNewsletterWrapper>
            <FooterNewsletter />
          </FooterNewsletterWrapper>
          <a
            href="https://discord.gg/SK8y3Z2bqR"
            target="_blank"
            rel="noreferrer"
          >
            <Image fluid src={Discord} />
          </a>
          <a
            href="https://www.instagram.com/projectcitytv/"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={Instagram} />
          </a>
          <a
            href="https://www.tiktok.com/@projectcitytv"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={TikTok} />
          </a>
          <a
            href="https://twitter.com/ProjectCityTV"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={Twitter} />
          </a>
        </RightCol>
      </Wrapper>
    </StyledContainer>
  );
}

export default SiteFooter;
