import React from "react";
import Form from "react-bootstrap/Form";
import { number, oneOf, string } from "prop-types";
import styled from "styled-components";

const StyledControl = styled(Form.Control)`
  background-color: transparent !important;
  font-family: "Basis Grotesque";
  color: ${(props) => props.theme.yellow}!important;

  &:focus {
    border-color: ${(props) => props.theme.dark};
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.variant === "secondary" &&
    `
    border: none;
    color: ${(props) => props.theme.yellow};

    &::placeholder {
      color: ${(props) => props.theme.yellow};
    }

    &:focus {
      border: 3px solid black!important;
      background-color: rgba(30, 16, 16, 0.3);
    }
  `}

  &::placeholder {
    color: ${({ variant, theme }) =>
      variant === "secondary" ? theme.yellow : theme.blue};
  }

  ${(props) =>
    props.as === "textarea" &&
    `
    {
      width: 100% !important;
      outline: none;
      border: 5px solid ${
        props.isInvalid ? props.theme.orange : props.theme.blue
      };
      color: ${props.theme.yellow};
      padding: 10px;
      font-family: font-family: "Basis Grotesque Mono";
      font-size: 21px;
      line-height: 32px;
    }
  `}
`;

function FormControl({ variant, opacity, ...props }) {
  /** Base form control that should be used throughout the project. */

  return (
    <StyledControl variant={variant} {...props}>
      {props.children}
    </StyledControl>
  );
}

FormControl.propTypes = {
  /** Set a dark theme for the form control. */
  variant: oneOf(["primary", "secondary"]),

  /** Set the opacity of the control */
  opacity: number,

  /** Render the control as a certain type. */
  as: string,
};

FormControl.defaultProps = {
  variant: "primary",
  opacity: 0,
  as: null,
};

export default FormControl;
