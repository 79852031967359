import React from "react";
import { shape } from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import ActionButton from "components/buttons/ActionDropdownButton";
import { deleteTierDescription } from "features/tierDescriptions/thunks";
import { RegistrationTierDescription } from "features/tierDescriptions/types";
import { ModalActionProps } from "types";

function TierDescriptionActionButton({ description, modalActionProps }) {
  /** Return the actions that user can perform on a tier description instance. */

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { navigateEdit, setSelectedDescription } = modalActionProps;

  function handleEditClick() {
    setSelectedDescription(description);
    navigateEdit();
  }

  async function handleDelete() {
    dispatch(deleteTierDescription(description.id));
    modalActionProps.navigateDeleteConfirm();
    addToast("Description removed", { appearance: "success" });
  }

  return (
    <ActionButton>
      <Dropdown.Item onClick={handleEditClick}>Edit</Dropdown.Item>
      <Dropdown.Item onClick={handleDelete}>Remove</Dropdown.Item>
    </ActionButton>
  );
}

TierDescriptionActionButton.propTypes = {
  /** The revenue split object that an action will be performed on. */
  description: shape(RegistrationTierDescription).isRequired,

  /** Properties passed down from the parent modal. */
  modalActionProps: ModalActionProps,
};

export default TierDescriptionActionButton;
