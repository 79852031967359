import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

import BaseCarousel from "components/carousels/Base";
import { arrayOf, string } from "prop-types";

function ImageStackCarousel({ images }) {
  /** General carousel of images. */

  function renderImages() {
    return images.map((image, index) => {
      return (
        <Carousel.Item key={index}>
          <Image className="d-block w-100" src={image} alt="" />
        </Carousel.Item>
      );
    });
  }

  return <BaseCarousel>{renderImages()}</BaseCarousel>;
}

ImageStackCarousel.propTypes = {
  /** Array of image sources. */
  images: arrayOf(string).isRequired,
};

export default ImageStackCarousel;
