import { shape } from "prop-types";
import { useToasts } from "react-toast-notifications";

import ConfirmActionModal from "components/modals/ConfirmAction";
import ModalSummaryText from "components/text/ModalSummaryText";
import { useDeleteUploadScheduleMutation } from "services/projectCity";
import { UploadSchedule } from "features/schedules/types";

function ConfirmDeleteUploadScheduleModal({ uploadSchedule, ...props }) {
  /** Allow the user to confirm deletion of an upload schedule. */

  const [deleteUploadSchedule, result] = useDeleteUploadScheduleMutation();
  const { addToast } = useToasts();

  async function handleDelete() {
    addToast("Upload Schedule deleted.", { appearance: "success" });
    deleteUploadSchedule({ uploadScheduleId: uploadSchedule.id });
    props.onHide();
  }

  return (
    <ConfirmActionModal
      title="Delete upload schedule?"
      confirmAction={handleDelete}
      isLoading={result.isLoading}
      isDanger
      {...props}
    >
      <ModalSummaryText>
        Are you sure you want to delete the "{uploadSchedule.title}" upload
        schedule? The action is irreversible.
      </ModalSummaryText>
    </ConfirmActionModal>
  );
}

ConfirmDeleteUploadScheduleModal.propTypes = {
  /** The content upload object that is being deleted. */
  uploadSchedule: shape(UploadSchedule).isRequired,
};

export default ConfirmDeleteUploadScheduleModal;
