import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import { useModal } from "react-modal-hook";
import { useDispatch } from "react-redux";

import BucketUploadModal from "components/modals/BucketUpload";
import WebComicModal from "components/modals/WebComic";
import ExternalLinkModal from "components/modals/ExternalLinkRedirect";
import ImageStackCarousel from "components/carousels/ImageStack";
import TextOnlyDisplay from "components/textOnlyMessages/Display";
import Video from "components/videos/Video";
import ZipIcon from "images/icons/zip-icon.png";
import GenericProjectImage from "images/thumbnail_class.png";
import { fetchBucketUpload } from "features/bucketUploads/thunks";
import ContestEntryVideo from "features/contests/components/Video";
import AssignmentEntryTileImage from "features/assignments/components/EntryTileImage";
import AssignmentEntryOverlay from "features/assignments/components/EntryTileOverlay";
import { assignmentEntryType } from "features/assignments/enums";
import { bucketUploadType } from "utils/enums";
import PdfBtnIcon from "./assets/btn_pdf.svg";
import AssignmentEntryDisplayModal from "features/assignments/modals/AssignmentEntryDisplay";

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  position: relative;
  > img:hover {
    cursor: pointer;
  }
`;

const VideoContainer = styled.div`
  position: relative;
`;

const ClickablePostImage = styled(Image)`
  &:hover {
    cursor: pointer;
  }
`;

const AssignmentEntryWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

function PostVisualDisplay({ contentType, contentObject, showGenericProject }) {
  /** Provide the visual portion of a shared post in public feed or in the post modal. */

  const dispatch = useDispatch();
  const postContent = useRef(null);
  const [externalUrl, setExternalUrl] = useState("");

  const [showWebComicModal, hideWebComicModal] = useModal(() => {
    return (
      <WebComicModal onHide={hideWebComicModal} bucketUpload={contentObject} />
    );
  });

  const [showExternalLinkModal, hideExternalLinkModal] = useModal(() => {
    return (
      <ExternalLinkModal onHide={hideExternalLinkModal} url={externalUrl} />
    );
  }, [externalUrl]);

  const [showAssignmentEntryModal, hideAssignmentEntryModal] = useModal(() => (
    <AssignmentEntryDisplayModal
      assignmentEntry={contentObject}
      onHide={hideAssignmentEntryModal}
    />
  ));

  const [showBucketUploadModal, hideBucketUploadModal] = useModal(() => {
    return (
      <BucketUploadModal
        bucketUpload={contentObject}
        onHide={hideBucketUploadModal}
      >
        <ImageStackCarousel bucketUpload={contentObject} />
      </BucketUploadModal>
    );
  });

  function isExternalURL(url) {
    try {
      return new URL(url).origin !== window.location.origin;
    } catch {
      return false;
    }
  }

  function handelAssignmentEntryClick() {
    if (contentObject.type === assignmentEntryType.pdf)
      openPdf(contentObject.files[0].file);
    else showAssignmentEntryModal();
  }

  useEffect(() => {
    /**
     * Detect if there is a redirect for external link and show warning in such a case.
     */

    if (postContent.current) {
      const aTags = postContent.current.querySelectorAll("a");

      const detectExternal = (event) => {
        const url = event.target.href;
        if (isExternalURL(url)) {
          event.preventDefault();
          setExternalUrl(url);
          showExternalLinkModal();
        }
      };

      aTags.forEach((tag) => {
        tag.addEventListener("click", detectExternal);
      });

      const removeListeners = () => {
        // clear up function.
        aTags.forEach((tag) => {
          tag.removeEventListener("click", detectExternal);
        });
      };

      return removeListeners;
    }
  }, [showExternalLinkModal]);

  function openPdf(pdfUrl) {
    // Opens the pdf file in new tab.
    const newWindow = window.open(pdfUrl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  function downloadFile(fileUrl) {
    const a = document.createElement("a");
    const chunks = fileUrl.split(/.\//);
    a.href = fileUrl;
    a.setAttribute("download", chunks[chunks.length - 1]);
    a.click();
  }

  function launchImageStackCarousel() {
    // Opens the carousel to view the sorted images in the bucket upload.
    dispatch(fetchBucketUpload(contentObject.id));
    showBucketUploadModal(contentObject);
  }

  if (contentType.model === "project") {
    return (
      <Image
        src={
          contentObject.image ||
          (showGenericProject ? GenericProjectImage : null)
        }
        fluid
        alt=""
      />
    );
  } else if (contentType.model === "contestentry") {
    return <ContestEntryVideo contestEntry={contentObject} />;
  } else if (contentType.model === "assignmententry") {
    return (
      <AssignmentEntryWrapper
        className="position-relative"
        onClick={handelAssignmentEntryClick}
      >
        <AssignmentEntryTileImage assignmentEntry={contentObject} />
        <AssignmentEntryOverlay type={contentObject.type} />
      </AssignmentEntryWrapper>
    );
  } else if (contentType.model === "bucketupload") {
    // For each type of bucket upload, we have a different type of render.
    if (contentObject.kind === bucketUploadType.imageStack) {
      const imageSrc = contentObject.coverImage
        ? contentObject.coverImage.image
        : contentObject.images.length > 0
        ? contentObject.images[0].image
        : null;

      return (
        <ClickablePostImage
          fluid
          src={imageSrc}
          alt=""
          onClick={launchImageStackCarousel}
        />
      );
    } else if (contentObject.kind === bucketUploadType.comic) {
      const imageSrc = contentObject.coverImage
        ? contentObject.coverImage.image
        : contentObject.images[0].image;
      return (
        <ClickablePostImage
          fluid
          src={imageSrc}
          alt=""
          onClick={showWebComicModal}
        />
      );
    } else if (contentObject.kind === bucketUploadType.pdf)
      return (
        <FileContainer>
          <Image
            src={PdfBtnIcon}
            alt=""
            className="w-25"
            onClick={() => openPdf(contentObject.uploadFile)}
          />
        </FileContainer>
      );
    else if (contentObject.kind === bucketUploadType.video)
      return (
        <VideoContainer>
          <Video>
            <source
              src={`${contentObject.uploadFile}#t=0.001`}
              type="video/mp4"
            />
          </Video>
        </VideoContainer>
      );
    else if (contentObject.kind === bucketUploadType.audio)
      return (
        <FileContainer>
          <audio controls>
            <source src={contentObject.uploadFile} type="audio/mpeg" />
          </audio>
        </FileContainer>
      );
    else if (contentObject.kind === bucketUploadType.zip)
      return (
        <FileContainer>
          <Image
            src={ZipIcon}
            alt=""
            className="w-50"
            onClick={() => downloadFile(contentObject.uploadFile)}
          />
        </FileContainer>
      );
    else return null;
  } else if (contentType.model === "textonlyfeed") {
    return (
      <TextOnlyDisplay
        message={{ id: contentObject.id, text: contentObject.message }}
      />
    );
  } else return null;
}

PostVisualDisplay.propTypes = {
  // The content object of the post that we're rendering.
  contentObject: PropTypes.object.isRequired,

  // The content type object of the post that we're rendering.
  contentType: PropTypes.object.isRequired,

  // Determines if we should show the generic poster image.
  showGenericProject: PropTypes.bool,
};

PostVisualDisplay.defaultProps = {
  showGenericProject: false,
};

export default PostVisualDisplay;
