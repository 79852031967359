import { useModal } from "react-modal-hook";
import { number } from "prop-types";

import ProjectCityButton from "components/buttons/Button";
import DonationTransferDetailModal from "features/payments/modals/DonationTransferDetail";

function DonationTransferDetailButton({ donationTransferId }) {
  /** Button to initiate the detail modal for a donation transfer. */

  const [
    showDonationTransferModal,
    hideDonationTransferModal,
  ] = useModal(() => (
    <DonationTransferDetailModal
      onHide={hideDonationTransferModal}
      donationTransferId={donationTransferId}
    />
  ));

  return (
    <ProjectCityButton
      variant="outline-success"
      noTilt
      onClick={showDonationTransferModal}
    >
      Details
    </ProjectCityButton>
  );
}

DonationTransferDetailButton.propTypes = {
  donationTransferId: number.isRequired,
};

export default DonationTransferDetailButton;
