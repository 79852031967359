import React from "react";
import Modal from "react-bootstrap/Modal";
import { shape } from "prop-types";

import BucketUploadHeader from "features/buckets/components/Uploads/Header";
import BucketUploadModalDisplay from "features/buckets/components/Uploads/ModalDisplay";
import CommentContainer from "features/comments/components/Container";
import CommentModal from "features/comments/modals/Comment";
import useGetContentTypeObj from "hooks/GetContentTypeObj";
import { BucketUpload } from "types";

function BucketUploadModal({ bucketUpload, ...props }) {
  /**
   * Provide a general modal to open when a bucket upload is clicked, which shows meta
   * data as well as comments. Generally just the content image section will be
   * different for the various types of uploads.
   */

  const contentTypeObj = useGetContentTypeObj("bucketupload");

  return (
    <CommentModal {...props}>
      <Modal.Body>
        {!bucketUpload.isAnonymous && (
          <BucketUploadHeader user={bucketUpload.createdBy} />
        )}
        <div className="d-flex align-items-center justify-content-center">
          {props.children || (
            <BucketUploadModalDisplay bucketUpload={bucketUpload} />
          )}
        </div>
        <CommentContainer
          contentObject={bucketUpload}
          contentTypeObj={contentTypeObj}
        />
      </Modal.Body>
    </CommentModal>
  );
}

BucketUploadModal.propTypes = {
  /** The bucket upload object that is being rendered in the modal. */
  bucketUpload: shape(BucketUpload).isRequired,
};

export default BucketUploadModal;
