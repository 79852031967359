import React from "react";
import { shape } from "prop-types";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

import TransparentCard from "components/cards/Transparent";
import CommentsPreview from "features/comments/components/Preview";
import TextOnlyMessageActionsModal from "components/textOnlyMessages/modals/TextOnlyMessageActions";
import PostHeader from "components/posts/Header";
import Caption from "components/text/Caption";
import useGetContentType from "hooks/GetContentTypeObj";
import { TextOnlyMessage as TextOnlyMessageType } from "types";

import TextOnlyMessageCommentsModal from "../modals/TextOnlyMessageComments";
import TextOnlyMessageDisplay from "../Display";
import PreviewSubmitContainer from "../PreviewSubmitContainer";

const PreviewContainer = styled.div`
  background-color: ${(props) => props.theme.neonPink};
`;

function TextOnlyMessage({ message }) {
  /** Display a text message object, which is in a text only bucket feed. */

  const contentTypeObj = useGetContentType("textonlyfeed");
  const { isPreview } = message;

  const [showTextOnlyMessageModal, hideTextOnlyActionsModal] = useModal(() => {
    return (
      <TextOnlyMessageActionsModal
        message={message}
        onHide={hideTextOnlyActionsModal}
      />
    );
  }, [message]);

  const [
    showTextOnlyMessageCommentModal,
    hideTextOnlyMessageCommentModal,
  ] = useModal(() => {
    return (
      <TextOnlyMessageCommentsModal
        message={message}
        onHide={hideTextOnlyMessageCommentModal}
      />
    );
  });

  return (
    <TransparentCard className="mb-3">
      {isPreview && (
        <PreviewContainer>
          <Caption className="text-center mb-0 p-2" color="yellow">
            PREVIEW MODE - NOT SAVED OR VISIBLE TO OTHERS
          </Caption>
        </PreviewContainer>
      )}
      <PostHeader
        user={message.createdBy}
        action={isPreview ? null : showTextOnlyMessageModal}
        className="p-1"
      />
      <TextOnlyMessageDisplay message={message} />
      {isPreview ? (
        <PreviewSubmitContainer message={message} />
      ) : (
        <CommentsPreview
          comments={message?.displayComment ? [message.displayComment] : []}
          contentObjectId={message.id}
          commentCount={message.commentCount}
          contentType={contentTypeObj}
          handleExpandComments={showTextOnlyMessageCommentModal}
        />
      )}
    </TransparentCard>
  );
}

TextOnlyMessage.propTypes = {
  message: shape(TextOnlyMessageType),
};

export default TextOnlyMessage;
