import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import Image from "react-bootstrap/Image";

import UsersService from "features/users/service";
import { getUserImage } from "utils/users";

const Wrapper = styled.div`
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.gray100};
    border-radius: 3px;

    &:hover {
      background: ${(props) => props.theme.gray300};
    }
  }
`;

const StyledDropDownItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.gray2};
  cursor: pointer;
  padding: ${(props) => props.theme.vSpacingXs};

  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.gray200};
    color: black !important;
  }

  &.active {
    color: black;
  }
`;

const ImageWrapper = styled.div`
  width: 50px;
  margin-right: 10px;
`;

function MentionsDropDown({
  isShown,
  username,
  setNewComment,
  newComment,
  setMentionWord,
  setMentions,
  mentions,
}) {
  // Provides the a dropdown for the list of mentions when user want to mention another user in a comment
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUserList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const { addToast } = useToasts();

  const service = new UsersService();

  useEffect(() => {
    /** Perform the search for users with a given term. */
    async function fetchUserList() {
      try {
        const response = await service.list();
        setUserList(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast("Error occurred while searching for a user", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    setIsLoading(true);
    fetchUserList();
  }, []);

  useEffect(() => {
    const filteredArray = usersList.filter((user) => {
      return user.username.includes(username);
    });
    setSearchList(filteredArray);
  }, [username, usersList]);

  function handleUsernameClick(user) {
    setNewComment(newComment.replace(/@[^\s]*[^\s]$/g, `@${user.username} `));
    setMentions([...mentions, user]);
    setMentionWord("");
  }

  return (
    isShown && (
      <Wrapper>
        {isLoading ? (
          <div> Loading... </div>
        ) : (
          searchList.map((user) => {
            return (
              <StyledDropDownItem
                key={user.id}
                onClick={() => handleUsernameClick(user)}
              >
                <ImageWrapper>
                  <Image fluid roundedCircle src={getUserImage(user)} />
                </ImageWrapper>
                <div>{user.username}</div>
              </StyledDropDownItem>
            );
          })
        )}
      </Wrapper>
    )
  );
}

MentionsDropDown.propTypes = {
  isShown: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  setNewComment: PropTypes.func.isRequired,
  newComment: PropTypes.string.isRequired,
  setMentionWord: PropTypes.func.isRequired,
  setMentions: PropTypes.func.isRequired,
  mentions: PropTypes.array.isRequired,
};

export default MentionsDropDown;
